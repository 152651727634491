import React, { Fragment, Component } from "react";
import "./style.scss";

import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import { Locations } from "src/data/Locations";

import Heading from "src/components/ui/Heading";

import CustomMapPin from "src/icons/custom-map-pin.svg";
import { ReactComponent as SpidIco } from "src/icons/spid.svg";
import { ReactComponent as TicketIco } from "src/icons/ticket.svg";
import { ReactComponent as ProfileCardIco } from "src/icons/profile.svg";
import { ReactComponent as TransitIco } from "src/icons/transit.svg";
import { ReactComponent as AppIco } from "src/icons/app.svg";
import { ReactComponent as DocIco } from "src/icons/doc.svg";
import { ReactComponent as F24Ico } from "src/icons/flat-small.svg";
import { ReactComponent as StarIco } from "src/icons/star.svg";
import { ReactComponent as CreditCardIcoSmall } from "src/icons/credit-card-small.svg";
import { ReactComponent as PayCash } from "src/icons/pay-per-use-round.svg";

const GOOGLE_MAPS_API_KEY = "AIzaSyArOd3_tsRm_tT-THYUp5eUcaSeCx7nAbw";
const Map = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=" +
      GOOGLE_MAPS_API_KEY +
      "&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: 41.9097306, lng: 12.2558141 }}
  >
    {props.markers &&
      props.markers.map(function (marker) {
        return (
          <Marker
            key={
              marker.lat +
              "-" +
              marker.lng +
              "-" +
              marker.address +
              "-" +
              (Math.random() * (9999999 - 100000) + 100000)
            }
            icon={{
              url: CustomMapPin,
            }}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() =>
              window.open("https://www.google.com/maps/place/" + marker.address)
            }
          />
        );
      })}
  </GoogleMap>
));

class MapWithMarkers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentServiceType: this.props.serviceType,
      locations: this.getLocationsDataset(),
    };
  }

  getLocationsDataset() {
    const locations = {
      promo130: [],
      offerteEE: [],
      bollettini: [],
      spedizioni: [],
      ricariche: [],
      ricaricaCash: [],
      visure: [],
      ricaricaQui: [],
      f24: [],
      spid: [],
    };

    for (const location of Locations) {
      const data = {
        name: location.title,
        address: location.address,
        lat: Number(location.lat),
        lng: Number(location.lng),
      };

      if (location.description.includes("PROMO130")) {
        locations.promo130.push(data);
      }
      // location.description.includes("VENDITACTREE") ||
      if (location.description.includes("ENEL ENERGIA")) {
        locations.offerteEE.push(data);
      }
      if (location.description.includes("BOLLETTINI")) {
        locations.bollettini.push(data);
      }
      if (location.description.includes("SPEDIZIONI")) {
        locations.spedizioni.push(data);
      }
      if (location.description.includes("RICARICHE")) {
        locations.ricariche.push(data);
      }
      if (location.description.includes("RICARICACASH")) {
        locations.ricaricaCash.push(data);
      }
      if (location.description.includes("VISURE")) {
        locations.visure.push(data);
      }
      if (location.description.includes("RICARICAQUI")) {
        locations.ricaricaQui.push(data);
      }
      if (location.description.includes("F24")) {
        locations.f24.push(data);
      }
      if (location.description.includes("SPID")) {
        locations.spid.push(data);
      }
    }

    return locations;
  }

  setServiceType(type) {
    this.setState({ currentServiceType: type });
  }

  render() {
    const SERVICE_TYPES = [
      {
        id: "promo130",
        name: "pagoPA 1.30€",
        icon: TicketIco,
      },
      {
        id: "ricaricaQui",
        name: "RicaricaQui",
        icon: CreditCardIcoSmall,
      },
      {
        id: "ricaricaCash",
        name: "Ricarica contanti",
        icon: PayCash,
      },
      {
        id: "offerteEE",
        name: "Contratti Energia",
        icon: StarIco,
      },
      {
        id: "bollettini",
        name: "Bollettini",
        icon: ProfileCardIco,
      },
      {
        id: "spid",
        name: "SPID",
        icon: SpidIco,
      },
      {
        id: "spedizioni",
        name: "Spedizioni",
        icon: TransitIco,
      },
      {
        id: "f24",
        name: "F24",
        icon: F24Ico,
      },
      {
        id: "ricariche",
        name: "Ricariche",
        icon: AppIco,
      },
      {
        id: "visure",
        name: "Visure",
        icon: DocIco,
      },
    ];

    return (
      <Fragment>
        <section id="map-ct">
          <Heading
            title={this.props.title || "Trova il servizio più vicino a te"}
          />
          <div className="center">
            <div className="map-ct mb-5">
              <div className="types">
                {SERVICE_TYPES.map((type) => {
                  const Icon = type.icon;

                  return (
                    <div
                      key={type.name}
                      className={
                        "type bg-light-grey clickable" +
                        (this.state.currentServiceType === type.id
                          ? " current"
                          : "")
                      }
                      onClick={() => this.setServiceType(type.id)}
                    >
                      <div className="icon">
                        <Icon />
                      </div>
                      <p className="label">{type.name}</p>
                    </div>
                  );
                })}
              </div>
              <div className="map bg-light-grey">
                <Map
                  markers={this.state.locations[this.state.currentServiceType]}
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default MapWithMarkers;
