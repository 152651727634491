export const Locations = [
  {
    id: "58411",
    map_id: "1",
    address: "VICO AL PIANO 20, Foggia (FG)",
    description: "ENEL ENERGIA",
    lat: "41.464547449370556",
    lng: "15.546644030142163",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58410",
    map_id: "1",
    address: "VIA MONTEVIDEO 25, Genova (GE)",
    description: "ENEL ENERGIA",
    lat: "44.40338227297034",
    lng: "8.955594986582208",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58409",
    map_id: "1",
    address: "via agostino landolfi 8, Solofra (AV)",
    description: "ENEL ENERGIA",
    lat: "40.83687359150394",
    lng: "14.837835527023971",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58408",
    map_id: "1",
    address: "CORSO ROMA 138, Manfredonia (FG)",
    description: "ENEL ENERGIA",
    lat: "41.62947613533506",
    lng: "15.917679457161826",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58407",
    map_id: "1",
    address: "PIAZZA ALDO MORO 10, Mattinata (FG)",
    description: "ENEL ENERGIA",
    lat: "41.709140867830484",
    lng: "16.05139882648457",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58406",
    map_id: "1",
    address: "Via delle Nebbiaie, 4/b, Monte San Savino (AR)",
    description: "ENEL ENERGIA",
    lat: "43.35806843435842",
    lng: "11.807625423407234",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58405",
    map_id: "1",
    address: "VIA S. GIOVANNI BOSCO C/O LE TORRI SN, Nuoro (NU)",
    description: "ENEL ENERGIA",
    lat: "40.322840108730716",
    lng: "9.305964170400307",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58404",
    map_id: "1",
    address: "Via San Simpliciano, 3, Brivio (LC)",
    description: "ENEL ENERGIA",
    lat: "45.738306737632406",
    lng: "9.427994224156297",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58403",
    map_id: "1",
    address: "Piazza Padre Pio, 19, San Paolo di Civitate (FG)",
    description: "ENEL ENERGIA",
    lat: "41.73921408622201",
    lng: "15.261217622116629",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58402",
    map_id: "1",
    address: "Via Fontane, 2, Ascoli Satriano",
    description: "ENEL ENERGIA",
    lat: "41.208184610009475",
    lng: "15.560989139960892",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58401",
    map_id: "1",
    address: "Via di Grottarossa, 96, Roma (RM)",
    description: "ENEL ENERGIA",
    lat: "41.972320579841096",
    lng: "12.438912522985957",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58400",
    map_id: "1",
    address: "Via Roma, 2, Calascibetta (EN)",
    description: "ENEL ENERGIA",
    lat: "37.58973571804976",
    lng: "14.27090319809803",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58399",
    map_id: "1",
    address: "Piazza San Rocco, 29, Linguaglossa (CT)",
    description: "ENEL ENERGIA",
    lat: "37.84085791575054",
    lng: "15.14022335589248",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58398",
    map_id: "1",
    address: "Via Provinciale, 23, Montalbano Elicona (ME)",
    description: "ENEL ENERGIA",
    lat: "38.023912872031964",
    lng: "15.013550711715732",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58397",
    map_id: "1",
    address: "Via Marcantonio Bragadin, 107, Roma (RM)",
    description: "ENEL ENERGIA",
    lat: "41.90699498911043",
    lng: "12.444724898321747",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58396",
    map_id: "1",
    address: "Viale Leonardo Sciascia, 83/85, 92100 Agrigento (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2752087",
    lng: "13.6132243",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16383",
  },
  {
    id: "58412",
    map_id: "1",
    address: "Via di Casalotti, 59, Roma (RM)",
    description: "ENEL ENERGIA",
    lat: "41.91620943286709",
    lng: "12.374178840649957",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "",
  },
  {
    id: "58395",
    map_id: "1",
    address: "Via Chiesa della Salute, 16, 10147 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0934916",
    lng: "7.6782317",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16366",
  },
  {
    id: "58394",
    map_id: "1",
    address: "Via Fermana Sud, 8/a, 63812 Montegranaro (FM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.2301082",
    lng: "13.6284711",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16365",
  },
  {
    id: "58393",
    map_id: "1",
    address: "Piazza Camillo Benso Conte di Cavour, 6, 50058 Signa (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.78071389999999",
    lng: "11.0966871",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16364",
  },
  {
    id: "58392",
    map_id: "1",
    address: "Via Giuseppe Mazzini, 41, 24023 Clusone (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8888008",
    lng: "9.942075899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16363",
  },
  {
    id: "58391",
    map_id: "1",
    address: "Via Carlo Alberto, 148/146, 10040 Leini (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.1835657",
    lng: "7.7176383",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16316",
  },
  {
    id: "58390",
    map_id: "1",
    address: "Via Tolmino, 2, 94016 Pietraperzia (EN)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.4203469",
    lng: "14.136517",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16132",
  },
  {
    id: "58389",
    map_id: "1",
    address: "Via Fonte Regina, 5/9, 64100 Teramo (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.6602736",
    lng: "13.7136516",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16111",
  },
  {
    id: "58388",
    map_id: "1",
    address: "Via del Trabocchetto, 11, 61034 Fossombrone (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.68738949999999",
    lng: "12.8077028",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16109",
  },
  {
    id: "58387",
    map_id: "1",
    address: "Via Telesforo, 100/102, 71122 Foggia (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.4541041",
    lng: "15.5311346",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16108",
  },
  {
    id: "58386",
    map_id: "1",
    address: "Corso Nazionale, 555, 88842 Cutro (KR)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.0362983",
    lng: "16.9919391",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16107",
  },
  {
    id: "58385",
    map_id: "1",
    address: "Strada Statale 18 C/O Ipercoop, 89812 Pizzo (VV)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.7377692",
    lng: "16.1766985",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16106",
  },
  {
    id: "58384",
    map_id: "1",
    address: "Via delle Viole, 7, 20147 Milano (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4509643",
    lng: "9.1315651",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16079",
  },
  {
    id: "58383",
    map_id: "1",
    address: "Via Trieste, 5, 60124 Ancona (AN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.61470389999999",
    lng: "13.5239452",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15586",
  },
  {
    id: "58382",
    map_id: "1",
    address: "Via M.L. King, 9, 62017 Porto Recanati (MC)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.427673",
    lng: "13.667652",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15585",
  },
  {
    id: "58381",
    map_id: "1",
    address: "Piazza Amedo D'Osta, 4, 14053 Canelli (AT)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7197345",
    lng: "8.2864076",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15584",
  },
  {
    id: "58380",
    map_id: "1",
    address: "Via Monginevro, 40B, 10121 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0651148",
    lng: "7.650488",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15583",
  },
  {
    id: "58379",
    map_id: "1",
    address: "Via Monte Santo, 10, 1037 Ronciglione (VT)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.287883",
    lng: "12.215391",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13643",
  },
  {
    id: "58378",
    map_id: "1",
    address: "Via D'Annunzio, 2, 62012 Civitanova Marche (MC)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.30498679999999",
    lng: "13.7214913",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13642",
  },
  {
    id: "58377",
    map_id: "1",
    address: "Corso Pietro Giannone, 89, 71121 Foggia (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.4588446",
    lng: "15.5502974",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13641",
  },
  {
    id: "58376",
    map_id: "1",
    address: "Via Monte Grappa, 3, 20064 Gorgonzola (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.530855",
    lng: "9.405237",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13535",
  },
  {
    id: "58375",
    map_id: "1",
    address: "Via Filippo Corridoni, 14, 24124 Bergamo (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7064836",
    lng: "9.688285800000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13534",
  },
  {
    id: "58374",
    map_id: "1",
    address: "Via Tadini, 51, 24058 Romano di Lombardia (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5188015",
    lng: "9.755459199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13533",
  },
  {
    id: "58373",
    map_id: "1",
    address: "Via Sempione, 124, 21029 Vergiate (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7219185",
    lng: "8.6845163",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13463",
  },
  {
    id: "58372",
    map_id: "1",
    address: "Corso Unione Sovietica, 548/a, 10135 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0157045",
    lng: "7.626565999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13231",
  },
  {
    id: "58371",
    map_id: "1",
    address: "Via Saffi, 42, 47042 Cesenatico (FC)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.19809",
    lng: "12.3962864",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13221",
  },
  {
    id: "58370",
    map_id: "1",
    address: "Via Volta, 123, 18038 Sanremo (IM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8209285",
    lng: "7.781226",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13215",
  },
  {
    id: "58369",
    map_id: "1",
    address: "Piazza Martiri Pennesi, 7, 64100 Teramo (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.6598722",
    lng: "13.7042837",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13122",
  },
  {
    id: "58368",
    map_id: "1",
    address: "Via Antonino Pio, 105, 80126 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8442231",
    lng: "14.2014436",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13119",
  },
  {
    id: "58367",
    map_id: "1",
    address: "Via Andrea Costa, 31/a, 40134 Bologna (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4949917",
    lng: "11.3267644",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13118",
  },
  {
    id: "58366",
    map_id: "1",
    address: "Via San Giacomo Della Vittoria, 28, 15121 Alessandria (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.9119943",
    lng: "8.6146288",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13095",
  },
  {
    id: "58365",
    map_id: "1",
    address: "Via Varesina, 14, 22070 Montano Lucino (CO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.786834",
    lng: "9.0432712",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13073",
  },
  {
    id: "58364",
    map_id: "1",
    address: "Corso Mazzini, 54, 47521 Cesena (FC)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.1375336",
    lng: "12.2447896",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13060",
  },
  {
    id: "58363",
    map_id: "1",
    address: "Via Provinciale, 49h, 87064 Corigliano-Rossano (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.6302575",
    lng: "16.5204568",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12982",
  },
  {
    id: "58362",
    map_id: "1",
    address: "Via G. Leopardi, 129, 60019 Senigallia (AN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7121147",
    lng: "13.2188531",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12971",
  },
  {
    id: "58361",
    map_id: "1",
    address: "Via Commandino, 41, 61122 Pesaro (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8986695",
    lng: "12.9218619",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12970",
  },
  {
    id: "58360",
    map_id: "1",
    address: "Piazza XXIV Maggio, 25, 58022 Follonica (GR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.9225576",
    lng: "10.7585321",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12959",
  },
  {
    id: "58359",
    map_id: "1",
    address: "Piazza Risorgimento, 77, 51039 Quarrata (PT)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.847991",
    lng: "10.977938",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12958",
  },
  {
    id: "58358",
    map_id: "1",
    address: "Via Emilia Est, 2/c, 43121 Parma (PR)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7987694",
    lng: "10.3400339",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12957",
  },
  {
    id: "58357",
    map_id: "1",
    address: "Via Istria, 12, 9047 Selargius (CA)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.2543655",
    lng: "9.166602",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12952",
  },
  {
    id: "58356",
    map_id: "1",
    address: "Via Per Agnona, 5, 13011 Borgosesia (VC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7377494",
    lng: "8.2588407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12948",
  },
  {
    id: "58355",
    map_id: "1",
    address: "Via Pacini, 6/a, 55016 Porcari (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.840657",
    lng: "10.617311",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12889",
  },
  {
    id: "58354",
    map_id: "1",
    address: "Via Cavour, 44, 55011 Altopascio (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8144634",
    lng: "10.675427",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12888",
  },
  {
    id: "58353",
    map_id: "1",
    address: "Corso Matteotti, 44, 84015 Nocera Superiore (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7416928",
    lng: "14.6716675",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12887",
  },
  {
    id: "58352",
    map_id: "1",
    address: "Viale Europa, 9, 90039 Villabate (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0800684",
    lng: "13.4360502",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12783",
  },
  {
    id: "58351",
    map_id: "1",
    address: "Via Francesco Bentivegna, 63, 90034 Corleone (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.8139753",
    lng: "13.2985629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12782",
  },
  {
    id: "58350",
    map_id: "1",
    address: "Via Del Progresso, 8, 90018 Termini Imerese (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.9804643",
    lng: "13.7019426",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12781",
  },
  {
    id: "58349",
    map_id: "1",
    address: "Via Vitaliano Brancati, 2, 90015 Cefalu' (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.032297",
    lng: "14.0260585",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12780",
  },
  {
    id: "58348",
    map_id: "1",
    address: "Via Colombini, 53/a, 55012 Capannori (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8458437",
    lng: "10.574289",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12769",
  },
  {
    id: "58347",
    map_id: "1",
    address: "Via Magenta, 99, 57125 Livorno (LI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.5462454",
    lng: "10.3155667",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12744",
  },
  {
    id: "58346",
    map_id: "1",
    address: "Viale Puccini, 133, 55100 Lucca (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8433293",
    lng: "10.4895318",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12743",
  },
  {
    id: "58345",
    map_id: "1",
    address: "Via Carducci, Snc, 57037 Portoferraio (LI)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.8143092",
    lng: "10.321967",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12742",
  },
  {
    id: "58344",
    map_id: "1",
    address: "Largo Brambilla, 3, 50134 Firenze (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8040342",
    lng: "11.2459308",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12741",
  },
  {
    id: "58343",
    map_id: "1",
    address: "Piazza Giuseppe Mazzini, 51, 53036 Poggibonsi (SI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.4671464",
    lng: "11.1483321",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12740",
  },
  {
    id: "58342",
    map_id: "1",
    address: "Via Valenzano, 7/e, 70010 Capurso (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0480547",
    lng: "16.917254",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12727",
  },
  {
    id: "58341",
    map_id: "1",
    address: "Largo Paolo Mettei Gentili, 35/36, 157 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.9118928",
    lng: "12.537854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12689",
  },
  {
    id: "58340",
    map_id: "1",
    address: "Via Sardegna, 25, 9032 Assemini (CA)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.2876822",
    lng: "9.009262099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12509",
  },
  {
    id: "58339",
    map_id: "1",
    address: "Corso S.Aldisio, 180, 93012 Gela (CL)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.0696576",
    lng: "14.2403502",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12376",
  },
  {
    id: "58338",
    map_id: "1",
    address: "Via Generale Cascino, 43, 94015 Piazza Armerina (EN)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.385776",
    lng: "14.3704997",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12204",
  },
  {
    id: "58337",
    map_id: "1",
    address: "Viale Marconi, Snc, 63100 Ascoli Piceno (AP)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.8531472",
    lng: "13.5881043",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12175",
  },
  {
    id: "58336",
    map_id: "1",
    address: "Piazza Dante, 18, 33043 Cividale del Friuli (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.0801447",
    lng: "13.433127",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12138",
  },
  {
    id: "58335",
    map_id: "1",
    address: "Via Giacomo Bresadola, 118, 171 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.8911954",
    lng: "12.5618721",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12068",
  },
  {
    id: "58334",
    map_id: "1",
    address: "Via Giuseppe Di Vittorio, 17/a, 70015 Noci (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7905006",
    lng: "17.1339952",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11889",
  },
  {
    id: "58333",
    map_id: "1",
    address: "Piazza Gramsci, 58, 50032 Borgo San Lorenzo (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9540677",
    lng: "11.388958",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11859",
  },
  {
    id: "58332",
    map_id: "1",
    address: "Piazza Livio Zannoni, 12, 50026 San Casciano in Val di Pesa (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.6587129",
    lng: "11.1888106",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11857",
  },
  {
    id: "58331",
    map_id: "1",
    address: "Via Antonio Gramsci, 5, 52044 Cortona (AR)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.2614705",
    lng: "11.976378",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11855",
  },
  {
    id: "58330",
    map_id: "1",
    address: "Via Diaz, 4, 53034 Colle di Val d'Elsa (SI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.4197323",
    lng: "11.1276995",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11852",
  },
  {
    id: "58329",
    map_id: "1",
    address: "Via Donatori del Sangue, 33/d, 58015 Orbetello (GR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.44253519999999",
    lng: "11.2214764",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11851",
  },
  {
    id: "58328",
    map_id: "1",
    address: "Via Vittorio Veneto, 77, 52100 Arezzo (AR)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.45861319999999",
    lng: "11.8752939",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11850",
  },
  {
    id: "58327",
    map_id: "1",
    address: "Via Giuseppe Mario Bosco, 232, 81100 Caserta (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0801622",
    lng: "14.3391037",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11847",
  },
  {
    id: "58326",
    map_id: "1",
    address: "Via Nazionale Appia, 131, 81020 Casapulla (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0740144",
    lng: "14.2914994",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11846",
  },
  {
    id: "58325",
    map_id: "1",
    address: "Via della Repubblica, 33, 54027 Pontremoli (MS)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.3770597",
    lng: "9.8832393",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11830",
  },
  {
    id: "58324",
    map_id: "1",
    address: "Via Vanchiglia, 9, 10124 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0674583",
    lng: "7.697744900000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11649",
  },
  {
    id: "58323",
    map_id: "1",
    address: "Via Roma, 134, 33080 Prata di Pordenone (PN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8970626",
    lng: "12.6034615",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11596",
  },
  {
    id: "58322",
    map_id: "1",
    address: "Via Martiri Sfriso, 19/h, 33077 Sacile (PN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.9535359",
    lng: "12.4952679",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11595",
  },
  {
    id: "58321",
    map_id: "1",
    address: "Via Largo Chiese, 29, 46041 Asola (MN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.2208434",
    lng: "10.4063879",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11594",
  },
  {
    id: "58320",
    map_id: "1",
    address: "Via Iasolino, 64, 80077 Ischia (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.743877",
    lng: "13.9383499",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11365",
  },
  {
    id: "58319",
    map_id: "1",
    address: "Via Vittorio Veneto, 38, 36016 Thiene (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6916623",
    lng: "11.4366571",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11342",
  },
  {
    id: "58318",
    map_id: "1",
    address: "Piazza SS Redentore, 30, 24067 Sarnico (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6678546",
    lng: "9.9570768",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11206",
  },
  {
    id: "58317",
    map_id: "1",
    address: "Via Roma 12/a, 24011 Alme' (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7433974",
    lng: "9.6159001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11205",
  },
  {
    id: "58316",
    map_id: "1",
    address: "Via Vittorio Emanuele, 500, 95033 Biancavilla (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.6455005",
    lng: "14.862644",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11110",
  },
  {
    id: "58315",
    map_id: "1",
    address: "Vaile Roma, 24/16 Fraz.Montecchio, 61022 Vallefoglia (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8555555",
    lng: "12.761651",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11109",
  },
  {
    id: "58314",
    map_id: "1",
    address: "Via Repubblica, 51, 20026 Novate Milanese (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.53145989999999",
    lng: "9.136976899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11006",
  },
  {
    id: "58313",
    map_id: "1",
    address: "Via Principi D'Acaja, 33, 10138 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0745478",
    lng: "7.6635512",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10820",
  },
  {
    id: "58312",
    map_id: "1",
    address: "Via Ferrante D'Aragona, 9, 80126 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8589728",
    lng: "14.1699049",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10819",
  },
  {
    id: "58311",
    map_id: "1",
    address: "Via Roma, 18, 89047 Roccella Ionica (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.3224295",
    lng: "16.4070252",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10818",
  },
  {
    id: "58310",
    map_id: "1",
    address: "Via Torre Pisani, Snc, 87064 Corigliano-Rossano (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.5963412",
    lng: "16.6315222",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10791",
  },
  {
    id: "58309",
    map_id: "1",
    address: "Via Salvatore Scervini, 5, 87041 Acri (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.4947524",
    lng: "16.3802992",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10494",
  },
  {
    id: "58308",
    map_id: "1",
    address: "Via Don Lorenzo Milani, 3, 47814 Bellaria-Igea Marina (RN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.1473485",
    lng: "12.4601244",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10479",
  },
  {
    id: "58307",
    map_id: "1",
    address: "Via Cipriano Facchinetti, 60, 159 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.9066777",
    lng: "12.5484847",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10478",
  },
  {
    id: "58306",
    map_id: "1",
    address: "Via Filippo Pecoraino, Snc, 90124 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0939074",
    lng: "13.4024854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10443",
  },
  {
    id: "58305",
    map_id: "1",
    address: "Viale XX Settembre, 229, 54033 Carrara (MS)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.0484737",
    lng: "10.0553289",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10430",
  },
  {
    id: "58304",
    map_id: "1",
    address: "Via Marco Polo, 133, 55049 Viareggio (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8857351",
    lng: "10.2495934",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10429",
  },
  {
    id: "58303",
    map_id: "1",
    address: "Via Dante Alighieri, 14, 70122 Bari (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.121948",
    lng: "16.8710716",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10411",
  },
  {
    id: "58302",
    map_id: "1",
    address: "Via Nino Bixio, 2, 33097 Spilimbergo (PN)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.1114755",
    lng: "12.8993946",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10410",
  },
  {
    id: "58301",
    map_id: "1",
    address: "Via Papa Giovanni XXIII, 90011 Bagheria (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.08381",
    lng: "13.5156546",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10389",
  },
  {
    id: "58300",
    map_id: "1",
    address: "Via Gebbione, 17, 89129 Reggio Calabria (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0838941",
    lng: "15.6430397",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10388",
  },
  {
    id: "58299",
    map_id: "1",
    address: "Piazza Martiri Pennesi, 7, 64100 Teramo (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.6598722",
    lng: "13.7042837",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10350",
  },
  {
    id: "58298",
    map_id: "1",
    address: "Via Venezia, 74, 88900 Crotone (KR)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.0777036",
    lng: "17.1273828",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10322",
  },
  {
    id: "58297",
    map_id: "1",
    address: "Piazza Del Lavoro, 9, 87062 Cariati (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.4937142",
    lng: "16.9547166",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10321",
  },
  {
    id: "58296",
    map_id: "1",
    address: "Viale Dante Alighieri, 8, 15048 Valenza (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.012215",
    lng: "8.642177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10263",
  },
  {
    id: "58295",
    map_id: "1",
    address: "Via Alcide De Gasperi, 40, 20861 Brugherio (MB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5515422",
    lng: "9.297551499999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10248",
  },
  {
    id: "58294",
    map_id: "1",
    address: "Via Romagna, 22/b, 20900 Monza (MB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5738851",
    lng: "9.253890199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10247",
  },
  {
    id: "58293",
    map_id: "1",
    address: "Via Martiri 1799, 117, 70022 Altamura (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.823637",
    lng: "16.559344",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10233",
  },
  {
    id: "58292",
    map_id: "1",
    address: "Via Roma, 87, 20091 Bresso (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5407139",
    lng: "9.1866757",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10224",
  },
  {
    id: "58291",
    map_id: "1",
    address: "Via Vito Bascio, 32, 74015 Martina Franca (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7031032",
    lng: "17.3418456",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10223",
  },
  {
    id: "58290",
    map_id: "1",
    address: "Corso Novara, 60, 80142 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8557665",
    lng: "14.2714274",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10220",
  },
  {
    id: "58289",
    map_id: "1",
    address: "Via Ca' Nave, 29, 35013 Cittadella (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6405605",
    lng: "11.7847852",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10219",
  },
  {
    id: "58288",
    map_id: "1",
    address: "Via San Carlo, 7, 24016 San Pellegrino Terme (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8371921",
    lng: "9.663922699999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10191",
  },
  {
    id: "58287",
    map_id: "1",
    address: "Via Betelli, 64, 24044 Dalmine (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6516059",
    lng: "9.603274299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10189",
  },
  {
    id: "58286",
    map_id: "1",
    address: "Via Roma, 13, 24036 Ponte San Pietro (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6966347",
    lng: "9.5887286",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10188",
  },
  {
    id: "58285",
    map_id: "1",
    address: "Via E. Baschenis, 100, 24122 Bergamo (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6884683",
    lng: "9.663454699999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10181",
  },
  {
    id: "58284",
    map_id: "1",
    address: "Via Sibari, Snc, 87011 Cassano all'Ionio (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.7779926",
    lng: "16.3416326",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10172",
  },
  {
    id: "58283",
    map_id: "1",
    address: "Corso di Porta Romana, 80, 20122 Milano (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4563238",
    lng: "9.1949776",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10171",
  },
  {
    id: "58282",
    map_id: "1",
    address: "Via Risorgimento, 1, 37019 Peschiera del Garda (VR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.44023480000001",
    lng: "10.6893972",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10152",
  },
  {
    id: "58281",
    map_id: "1",
    address: "Via D'Adda, 16, 20019 Settimo Milanese (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.47966719999999",
    lng: "9.0550233",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10147",
  },
  {
    id: "58280",
    map_id: "1",
    address: "Corso Dante, 25/d, 23801 Calolziocorte (LC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.802406",
    lng: "9.4292034",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10146",
  },
  {
    id: "58279",
    map_id: "1",
    address: "Piazza Garibaldi, 23, 41037 Mirandola (MO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.8885033",
    lng: "11.0676345",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10144",
  },
  {
    id: "58278",
    map_id: "1",
    address: "Via Giampaolo Orsini, 95, 50126 Firenze (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7630118",
    lng: "11.2730595",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10143",
  },
  {
    id: "58277",
    map_id: "1",
    address: "Via II Giugno, 35, 50052 Certaldo (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.5474829",
    lng: "11.0388366",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10120",
  },
  {
    id: "58276",
    map_id: "1",
    address: "Via Giacomo Matteotti, 25, 18100 Imperia (IM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8780551",
    lng: "8.015711099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10099",
  },
  {
    id: "58275",
    map_id: "1",
    address: "Via Giuseppe Garibaldi, 16, 47863 Novafeltria (RN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8965978",
    lng: "12.2903011",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10098",
  },
  {
    id: "58274",
    map_id: "1",
    address: "Corso Europa, 90, 7039 Valledoria (SS)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.927442",
    lng: "8.8203961",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10096",
  },
  {
    id: "58273",
    map_id: "1",
    address: "Via Ducco, 44, 25123 Brescia (BS)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.55443460000001",
    lng: "10.2311394",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9993",
  },
  {
    id: "58272",
    map_id: "1",
    address: "Via Marconi, 110/112, 25048 Edolo (BS)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.1792997",
    lng: "10.3349836",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9992",
  },
  {
    id: "58271",
    map_id: "1",
    address: "IV Novembre, 33, 25064 Gussago (BS)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5917906",
    lng: "10.1565002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9991",
  },
  {
    id: "58270",
    map_id: "1",
    address: "Piazza Maria SS. Ausiliatrice, 5/6, 95031 Adrano (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.66331",
    lng: "14.83145",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9985",
  },
  {
    id: "58269",
    map_id: "1",
    address: "Via Livorno, 47, 59013 Montemurlo (PO)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9222561",
    lng: "11.0321436",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9979",
  },
  {
    id: "58268",
    map_id: "1",
    address: "Via Aldo Moro, 6, 20096 Pioltello (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.501216",
    lng: "9.3264974",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9934",
  },
  {
    id: "58267",
    map_id: "1",
    address: "Via Nazionale, 116, 92015 Raffadali (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.40515140000001",
    lng: "13.5313903",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9933",
  },
  {
    id: "58266",
    map_id: "1",
    address: "Via XX Settembre 9/11, 67051 Avezzano (AQ)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.0283987",
    lng: "13.4255204",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9928",
  },
  {
    id: "58265",
    map_id: "1",
    address: "Via Don Minzoni, 68, 87036 Rende (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.3342102",
    lng: "16.2414906",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9920",
  },
  {
    id: "58264",
    map_id: "1",
    address: "Piazza O. Malaspina, 14, 15057 Tortona (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.8943298",
    lng: "8.8629913",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9908",
  },
  {
    id: "58263",
    map_id: "1",
    address: "S.S. Via Gelso Bianco, Sn, 95121 Catania (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.4732554",
    lng: "15.0564579",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9904",
  },
  {
    id: "58262",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 73, 93017 San Cataldo (CL)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.4850277",
    lng: "13.9897941",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9903",
  },
  {
    id: "58261",
    map_id: "1",
    address: "Via Giovan Battista Fardella, 22, 91100 Trapani (TP)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0208004",
    lng: "12.5330598",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9902",
  },
  {
    id: "58260",
    map_id: "1",
    address: "Via Torino, 43, 10028 Trofarello (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.9843481",
    lng: "7.7388853",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9894",
  },
  {
    id: "58259",
    map_id: "1",
    address: "Via Vittoria, 28, 98077 Santo Stefano di Camastra (ME)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0140483",
    lng: "14.3501543",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9888",
  },
  {
    id: "58258",
    map_id: "1",
    address: "Via Marconi, 59/ab, 12045 Fossano (CN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.5453604",
    lng: "7.7208484",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9876",
  },
  {
    id: "58257",
    map_id: "1",
    address: "Contrada Sant'Antonio, 228, 63848 Petritoli (FM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.04906219999999",
    lng: "13.6787731",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9840",
  },
  {
    id: "58256",
    map_id: "1",
    address: "Via del Santuario, 23/3, 65125 Pescara (PE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.4717542",
    lng: "14.1997478",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9818",
  },
  {
    id: "58255",
    map_id: "1",
    address: "Via Nicola Fabrizi, 71, 65122 Pescara (PE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.46915449999999",
    lng: "14.2131993",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9817",
  },
  {
    id: "58254",
    map_id: "1",
    address: "Via Badino, 140, 4019 Terracina (LT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.2896364",
    lng: "13.2350809",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9816",
  },
  {
    id: "58253",
    map_id: "1",
    address: "Via Allo', 28, 90014 Casteldaccia (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0538315",
    lng: "13.5294279",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9815",
  },
  {
    id: "58252",
    map_id: "1",
    address: "Via Buonriposo, 81, 90124 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0999865",
    lng: "13.3728837",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9805",
  },
  {
    id: "58251",
    map_id: "1",
    address: "Via Madonna del Ponte, 38, 90041 Balestrate (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0509861",
    lng: "13.0062972",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9804",
  },
  {
    id: "58250",
    map_id: "1",
    address: "Via Serracavallo, 122, 90151 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1931906",
    lng: "13.2826399",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9803",
  },
  {
    id: "58249",
    map_id: "1",
    address: "Via Francesco Pasinetti, 18/20, 139 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.9620406",
    lng: "12.5370419",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9773",
  },
  {
    id: "58248",
    map_id: "1",
    address: "Piazza Leonardo Da Vinci, 42, 25087 Salo' (BS)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6059114",
    lng: "10.5221302",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9752",
  },
  {
    id: "58247",
    map_id: "1",
    address: "Viale Capppuccini, 35, 66034 Lanciano (CH)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.2262649",
    lng: "14.3876732",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9725",
  },
  {
    id: "58246",
    map_id: "1",
    address: "Piazza Garibaldi, 104, 70026 Modugno (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0831538",
    lng: "16.7791193",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9724",
  },
  {
    id: "58245",
    map_id: "1",
    address: "Via Cisternino, 165, 70010 Locorotondo (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7544341",
    lng: "17.3313864",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9723",
  },
  {
    id: "58244",
    map_id: "1",
    address: "Via Domenico Cirillo, 200, 72014 Cisternino (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7429023",
    lng: "17.4266807",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9722",
  },
  {
    id: "58243",
    map_id: "1",
    address: "Via Gaspero Barbera, 3, 10136 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0196149",
    lng: "7.632586300000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9709",
  },
  {
    id: "58242",
    map_id: "1",
    address: "Via Medici, 143, 98076 Sant'Agata di Militello (ME)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0686395",
    lng: "14.6371743",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9699",
  },
  {
    id: "58241",
    map_id: "1",
    address: "Via Delle Croci, 45/47, 1016 Tarquinia (VT)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.2508609",
    lng: "11.7602396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9698",
  },
  {
    id: "58240",
    map_id: "1",
    address:
      "Piazza Mercantoni C/O CC Mercantoni, Snc, 1033 Civita Castellana (VT)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.294032",
    lng: "12.4124983",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9697",
  },
  {
    id: "58239",
    map_id: "1",
    address: "Via Del Castello, 45, 44 Frascati (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.8102616",
    lng: "12.6801213",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9696",
  },
  {
    id: "58238",
    map_id: "1",
    address: "SS Cassia Km 67.300 C/O CC Coop, snc, 1019 Vetralla (VT)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.318861",
    lng: "12.058817",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9694",
  },
  {
    id: "58237",
    map_id: "1",
    address: "P.za Arduino Angelucci C/O CC Futura, 2, 2100 Rieti (RI)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.4078513",
    lng: "12.8498407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9693",
  },
  {
    id: "58236",
    map_id: "1",
    address: "Via Curiel, 30, 61122 Pesaro (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9070571",
    lng: "12.9106769",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9692",
  },
  {
    id: "58235",
    map_id: "1",
    address: "Via San Michele, 21, 20015 Parabiago (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5588031",
    lng: "8.946735",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9691",
  },
  {
    id: "58234",
    map_id: "1",
    address: "Corso Giacomo Matteotti, 199, 57023 Cecina (LI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.3098763",
    lng: "10.5183739",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9687",
  },
  {
    id: "58233",
    map_id: "1",
    address: "Via Indipendenza, 148, 57021 Campiglia Marittima (LI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.02855599999999",
    lng: "10.6022973",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9686",
  },
  {
    id: "58232",
    map_id: "1",
    address: "Via G. Garibaldi, 40, 50065 Pontassieve (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7747653",
    lng: "11.4395692",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9685",
  },
  {
    id: "58231",
    map_id: "1",
    address: "Via G.Pascoli, 12, 50018 Scandicci (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7567926",
    lng: "11.1840989",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9684",
  },
  {
    id: "58230",
    map_id: "1",
    address: "Via Masini, 17, 50051 Castelfiorentino (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.60686099999999",
    lng: "10.9675879",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9683",
  },
  {
    id: "58229",
    map_id: "1",
    address: "Piazza G.Marconi, 7, 35010 Villafranca Padovana (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4949773",
    lng: "11.7973393",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9667",
  },
  {
    id: "58228",
    map_id: "1",
    address: "Piazza G. Marconi, 41, 30039 Stra (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4106741",
    lng: "12.0044558",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9666",
  },
  {
    id: "58227",
    map_id: "1",
    address: "Via Piemonte, 6, 35020 Albignasego (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3525549",
    lng: "11.8683995",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9665",
  },
  {
    id: "58226",
    map_id: "1",
    address: "Via Alfieri, 1, 30038 Spinea (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4897418",
    lng: "12.1698029",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9664",
  },
  {
    id: "58225",
    map_id: "1",
    address: "Viale Amendola, 38, 84025 Eboli (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6168686",
    lng: "15.0550493",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9663",
  },
  {
    id: "58224",
    map_id: "1",
    address: "Via Roma, 28, 20021 Bollate (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5439436",
    lng: "9.116444099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9653",
  },
  {
    id: "58223",
    map_id: "1",
    address: "Viale Armando Diaz, 7, 52037 Sansepolcro (AR)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.572984",
    lng: "12.138051",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9652",
  },
  {
    id: "58222",
    map_id: "1",
    address: "Strada Statale 114 Km 6200, Snc, 98125 Messina (ME)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1568404",
    lng: "15.5334763",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9651",
  },
  {
    id: "58221",
    map_id: "1",
    address: "Via Emanuele Bellia, 318, 95047 Paterno' (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.571377",
    lng: "14.896869",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9642",
  },
  {
    id: "58220",
    map_id: "1",
    address: "Via Tancredi, 40, 71037 Monte Sant'Angelo (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.7063037",
    lng: "15.9613455",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9631",
  },
  {
    id: "58219",
    map_id: "1",
    address: "Corso Matteotti, 58, 29015 Castel San Giovanni (PC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0590389",
    lng: "9.435779799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9630",
  },
  {
    id: "58218",
    map_id: "1",
    address: "Via Catania, 1, 92014 Porto Empedocle (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2899095",
    lng: "13.5246549",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9620",
  },
  {
    id: "58217",
    map_id: "1",
    address: "Via Palermo, 4/a, 95100 Catania (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.4993632",
    lng: "15.0719072",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9617",
  },
  {
    id: "58216",
    map_id: "1",
    address: "Str S. Caterina c/o CC S.Caterina, 19, 70124 Bari (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0979647",
    lng: "16.8287154",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9598",
  },
  {
    id: "58215",
    map_id: "1",
    address: "Via XX Settembre, 155, 93015 Niscemi (CL)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.1476514",
    lng: "14.3864492",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9593",
  },
  {
    id: "58214",
    map_id: "1",
    address: "Via Palma, 56/58, 92027 Licata (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.1077214",
    lng: "13.9320224",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9592",
  },
  {
    id: "58213",
    map_id: "1",
    address: "Via Napoli, 247, 81024 Maddaloni (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0314156",
    lng: "14.3772595",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9578",
  },
  {
    id: "58212",
    map_id: "1",
    address: "Via Nibbi, 13, 63822 Porto San Giorgio (FM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.1930483",
    lng: "13.7874048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9576",
  },
  {
    id: "58211",
    map_id: "1",
    address: "Via Fontanella, 4, 63821 Porto Sant'Elpidio (FM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.2563149",
    lng: "13.7590028",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9575",
  },
  {
    id: "58210",
    map_id: "1",
    address: "Via Spanna, 1/5, 10095 Grugliasco (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0630552",
    lng: "7.5761407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9566",
  },
  {
    id: "58209",
    map_id: "1",
    address: "Corso Italia, 48, 52027 San Giovanni Valdarno (AR)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.5640172",
    lng: "11.5309467",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9563",
  },
  {
    id: "58208",
    map_id: "1",
    address: "Via Gramsci, 29, 20037 Paderno Dugnano (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.565684",
    lng: "9.1664056",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9562",
  },
  {
    id: "58207",
    map_id: "1",
    address: "Via Clanio, 16, 80023 Caivano (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.95411910000001",
    lng: "14.3073288",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9561",
  },
  {
    id: "58206",
    map_id: "1",
    address: "Piazza Liberta', 5, 21012 Cassano Magnago (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6707983",
    lng: "8.8204604",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9557",
  },
  {
    id: "58205",
    map_id: "1",
    address: "Contra' dei Nodari, 2, 35012 Camposampiero (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5706904",
    lng: "11.932519",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9556",
  },
  {
    id: "58204",
    map_id: "1",
    address: "Via Trieste,187, 87046 Montalto Uffugo (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.3877398",
    lng: "16.2372314",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9551",
  },
  {
    id: "58203",
    map_id: "1",
    address: "Via Magenta, 9, 21100 Varese (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8144102",
    lng: "8.8311545",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9550",
  },
  {
    id: "58202",
    map_id: "1",
    address: "Via Scipione ammirato, 68, 50136 Firenze (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7707453",
    lng: "11.2774658",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9548",
  },
  {
    id: "58201",
    map_id: "1",
    address: "Via Roma, 100, 61032 Fano (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8386705",
    lng: "13.008974",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9541",
  },
  {
    id: "58200",
    map_id: "1",
    address: "Via Cesare Battisti, 56, 31036 Istrana (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6781428",
    lng: "12.1082925",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9533",
  },
  {
    id: "58199",
    map_id: "1",
    address: "Via Silvio Marcuzzi, 6, 33052 Cervignano del Friuli (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.82333",
    lng: "13.3358451",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9532",
  },
  {
    id: "58198",
    map_id: "1",
    address: "Corso Giustino Fortunato, 52, 71016 San Severo (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.68302569999999",
    lng: "15.3877026",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9529",
  },
  {
    id: "58197",
    map_id: "1",
    address: "Via Leon Battista Alberti, 2, 71013 San Giovanni Rotondo (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.702874",
    lng: "15.7255494",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9528",
  },
  {
    id: "58196",
    map_id: "1",
    address: "Via Rossetti, 2, 76123 Andria (BT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.2250137",
    lng: "16.2996846",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9527",
  },
  {
    id: "58195",
    map_id: "1",
    address: "Viale Dante, 28, 85100 Potenza (PZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6367061",
    lng: "15.8035293",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9526",
  },
  {
    id: "58194",
    map_id: "1",
    address: "Via Agostino Gioia, 70, 70054 Giovinazzo (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.18503",
    lng: "16.6725799",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9525",
  },
  {
    id: "58193",
    map_id: "1",
    address: "Via Giuseppe Verdi, 9, 70032 Bitonto (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.1116058",
    lng: "16.6936714",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9524",
  },
  {
    id: "58192",
    map_id: "1",
    address: "Via Pala di Carru, 153, 7100 Sassari (SS)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7474573",
    lng: "8.5213232",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9519",
  },
  {
    id: "58191",
    map_id: "1",
    address: "Viale della Repubblica, 146/a, 59100 Prato (PO)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8671083",
    lng: "11.1125687",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9507",
  },
  {
    id: "58190",
    map_id: "1",
    address: "Piazza Mazzini, 11, 42017 Novellara (RE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.8437434",
    lng: "10.7309605",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9505",
  },
  {
    id: "58189",
    map_id: "1",
    address: "Via XX Settembre, 50, 42027 Montecchio Emilia (RE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7003592",
    lng: "10.4503697",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9504",
  },
  {
    id: "58188",
    map_id: "1",
    address: "Via Pisacane, 49, 40026 Imola (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.3488473",
    lng: "11.7241108",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9497",
  },
  {
    id: "58187",
    map_id: "1",
    address: "Via G. Garibaldi, 204, 95046 Palagonia (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2976862",
    lng: "14.8390854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9495",
  },
  {
    id: "58186",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 128, 93013 Mazzarino (CL)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.3045583",
    lng: "14.2124345",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9494",
  },
  {
    id: "58185",
    map_id: "1",
    address: "Via Galermo, 334, 95123 Catania (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.5441737",
    lng: "15.0539378",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9493",
  },
  {
    id: "58184",
    map_id: "1",
    address: "Via Roma, 118, 82037 Telese Terme (BN)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.2169155",
    lng: "14.5285842",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9492",
  },
  {
    id: "58183",
    map_id: "1",
    address: "Via Cervinara, 28, 82016 Montesarchio (BN)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.06160089999999",
    lng: "14.63815",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9491",
  },
  {
    id: "58182",
    map_id: "1",
    address: "Via Mauro Venegoni, 54/b, 20025 Legnano (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5929907",
    lng: "8.9066104",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9473",
  },
  {
    id: "58181",
    map_id: "1",
    address: "Via Garibaldi, 13, 28 Subiaco (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.924649",
    lng: "13.096178",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9463",
  },
  {
    id: "58180",
    map_id: "1",
    address: "Via Eridania, 76, 45030 Occhiobello (RO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.9119389",
    lng: "11.6005933",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9454",
  },
  {
    id: "58179",
    map_id: "1",
    address: "Via Marconi, 102, 44122 Ferrara (FE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.8495158",
    lng: "11.5996751",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9453",
  },
  {
    id: "58178",
    map_id: "1",
    address: "Via Monsignor Menegazzi, 25/27, 44022 Comacchio (FE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.6965459",
    lng: "12.1827142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9452",
  },
  {
    id: "58177",
    map_id: "1",
    address: "Via dei Volsci, 69/a, 49 Velletri (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.6894647",
    lng: "12.7843126",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9451",
  },
  {
    id: "58176",
    map_id: "1",
    address: "Via Nazionale, 78, 89063 Melito di Porto Salvo (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.9206834",
    lng: "15.7836793",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9450",
  },
  {
    id: "58175",
    map_id: "1",
    address: "Via Vittorio Emanuele, 7, 31055 Quinto di Treviso (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.64297699999999",
    lng: "12.163624",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9447",
  },
  {
    id: "58174",
    map_id: "1",
    address: "Via Carlo Marx, 85, 41012 Carpi (MO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7734718",
    lng: "10.8825406",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9437",
  },
  {
    id: "58173",
    map_id: "1",
    address: "Via Venezia, 12, 12084 Mondovi' (CN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.406854",
    lng: "7.8208615",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9426",
  },
  {
    id: "58172",
    map_id: "1",
    address: "Via Porrino, 125, 3025 Monte San Giovanni Campano (FR)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.6736028",
    lng: "13.5149881",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9425",
  },
  {
    id: "58171",
    map_id: "1",
    address: "Viale della Vittoria, 21, 6055 Marsciano (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.9095448",
    lng: "12.3382743",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9424",
  },
  {
    id: "58170",
    map_id: "1",
    address: "Piazza Risorgimento,42, 82018 San Giorgio del Sannio (BN)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0616711",
    lng: "14.853689",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9422",
  },
  {
    id: "58169",
    map_id: "1",
    address: "Viale Ionio, 80/82, 95129 Catania (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.514424",
    lng: "15.1013569",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9421",
  },
  {
    id: "58168",
    map_id: "1",
    address: "Via Sammartino, 40/b, 90141 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1251832",
    lng: "13.3495463",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9419",
  },
  {
    id: "58167",
    map_id: "1",
    address: "Via Nomentana, 647, 13 Fonte Nuova (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.9943827",
    lng: "12.6191853",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9418",
  },
  {
    id: "58166",
    map_id: "1",
    address: "Via Nazionale, 333, 64026 Roseto degli Abruzzi (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.6746221",
    lng: "14.0167305",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9417",
  },
  {
    id: "58165",
    map_id: "1",
    address: "Via Gramsci, 5, 6012 Citta' di Castello (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.4575806",
    lng: "12.2417394",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9416",
  },
  {
    id: "58164",
    map_id: "1",
    address: "Via Giuseppe di Vittorio, 1/a, 65015 Montesilvano (PE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.5122632",
    lng: "14.1450432",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9410",
  },
  {
    id: "58163",
    map_id: "1",
    address: "Via Nazionale Adriatica Nord, 58, 66023 Francavilla al Mare (CH)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.42908509999999",
    lng: "14.2712553",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9409",
  },
  {
    id: "58162",
    map_id: "1",
    address: "Via Raffaele Musone, 4, 81020 Capodrise (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0372922",
    lng: "14.3119167",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9406",
  },
  {
    id: "58161",
    map_id: "1",
    address: "Via Pitteri, 2, 33081 Aviano (PN)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.0699695",
    lng: "12.5857309",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9404",
  },
  {
    id: "58160",
    map_id: "1",
    address: "Via Carlo Cattaneo, 34, 1100 Viterbo (VT)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.4133202",
    lng: "12.1160975",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9393",
  },
  {
    id: "58159",
    map_id: "1",
    address: "Via Pollaci, 40, 90135 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1042144",
    lng: "13.3292757",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9392",
  },
  {
    id: "58158",
    map_id: "1",
    address: "Piazza Umberto, 60, 88900 Crotone (KR)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.0626149",
    lng: "17.1103383",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9391",
  },
  {
    id: "58157",
    map_id: "1",
    address: "Via Gramsci, 15, 20068 Peschiera Borromeo (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4262379",
    lng: "9.2969865",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9379",
  },
  {
    id: "58156",
    map_id: "1",
    address: "Via Tevere, 153, 93012 Gela (CL)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.0708251",
    lng: "14.2514152",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9375",
  },
  {
    id: "58155",
    map_id: "1",
    address: "Via Ginevrina da Fossano, 30, 22063 Cantu' (CO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7362552",
    lng: "9.1430407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9374",
  },
  {
    id: "58154",
    map_id: "1",
    address: "Via Brigata Liguria, 4, 36012 Asiago (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.87634329999999",
    lng: "11.5091175",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9358",
  },
  {
    id: "58153",
    map_id: "1",
    address: "Piazza Kennedy, 11, 63078 Spinetoli (AP)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.8690294",
    lng: "13.768677",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9357",
  },
  {
    id: "58152",
    map_id: "1",
    address: "Viale Matteotti, 90/92, 20864 Agrate Brianza (MB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.572772",
    lng: "9.3507269",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9356",
  },
  {
    id: "58151",
    map_id: "1",
    address: "Borgo Paolo Danti, 19, 62011 Cingoli (MC)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.3766348",
    lng: "13.2126751",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9352",
  },
  {
    id: "58150",
    map_id: "1",
    address: "Viale Primo Maggio, 1, 61032 Fano (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8468206",
    lng: "13.0120991",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9351",
  },
  {
    id: "58149",
    map_id: "1",
    address: "Via Sant'Andrea, 19, 12038 Savigliano (CN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.645237",
    lng: "7.6559122",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9350",
  },
  {
    id: "58148",
    map_id: "1",
    address: "Piazza Marginesu, 17, 7037 Sorso (SS)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.79766619999999",
    lng: "8.5768387",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9334",
  },
  {
    id: "58147",
    map_id: "1",
    address: "Via Einaudi, 11a, 46047 Porto Mantovano (MN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.1882341",
    lng: "10.7913289",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9327",
  },
  {
    id: "58146",
    map_id: "1",
    address: "Via Varatella, 6, 17052 Borghetto Santo Spirito (SV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.1134988",
    lng: "8.2448792",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9326",
  },
  {
    id: "58145",
    map_id: "1",
    address: "Via Dante Alighieri, 167, 33013 Gemona del Friuli (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.2753644",
    lng: "13.1317833",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9325",
  },
  {
    id: "58144",
    map_id: "1",
    address: "Via C. Colombo, 33, 72013 Ceglie Messapica (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.645418",
    lng: "17.5108964",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9324",
  },
  {
    id: "58143",
    map_id: "1",
    address: "Via Papa Giovanni XXIII, 35, 67039 Sulmona (AQ)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.0504261",
    lng: "13.9285363",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9323",
  },
  {
    id: "58142",
    map_id: "1",
    address: "Corso Roma, 63, 73014 Gallipoli (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.0559071",
    lng: "17.9891553",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9322",
  },
  {
    id: "58141",
    map_id: "1",
    address: "Via Bergamo, 13, 24036 Ponte San Pietro (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6994224",
    lng: "9.5811044",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9319",
  },
  {
    id: "58140",
    map_id: "1",
    address: "Via Pian Due Torri, 41/b, 146 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.8497422",
    lng: "12.4619077",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9311",
  },
  {
    id: "58139",
    map_id: "1",
    address: "Via Roma, 229/231, 80017 Melito di Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9202404",
    lng: "14.2319491",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9310",
  },
  {
    id: "58138",
    map_id: "1",
    address: "Via Pessina, 58, 80138 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8522245",
    lng: "14.2499797",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9308",
  },
  {
    id: "58137",
    map_id: "1",
    address: "Corso San Giovanni Taddeuccio, 839/a, 80146 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8305934",
    lng: "14.3105745",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9307",
  },
  {
    id: "58136",
    map_id: "1",
    address: "Via Liberta', 190, 80055 Portici (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.816868",
    lng: "14.3446032",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9306",
  },
  {
    id: "58135",
    map_id: "1",
    address: "Via Don Morosini, 38, 80046 San Giorgio a Cremano (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8312141",
    lng: "14.3412484",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9305",
  },
  {
    id: "58134",
    map_id: "1",
    address: "Via Fratelli Vaschetto, 1/a, 12100 Cuneo (CN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.3929587",
    lng: "7.550799199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9304",
  },
  {
    id: "58133",
    map_id: "1",
    address: "Largo Giovanni Falcone, 1, 12 Guidonia Montecelio (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.9893997",
    lng: "12.7188151",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9303",
  },
  {
    id: "58132",
    map_id: "1",
    address: "Corso Gran Priorato di Malta, 55, 81043 Capua (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.1092989",
    lng: "14.2119871",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9302",
  },
  {
    id: "58131",
    map_id: "1",
    address: "Via Umberto, 60/d, 8022 Dorgali (NU)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.2894733",
    lng: "9.5875253",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9301",
  },
  {
    id: "58130",
    map_id: "1",
    address: "Via Naviglio, 22/c, 48018 Faenza (RA)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.2895348",
    lng: "11.8841573",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9298",
  },
  {
    id: "58129",
    map_id: "1",
    address: "Piazzale Rudiae, 15/17, 73100 Lecce (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3486792",
    lng: "18.1595218",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9291",
  },
  {
    id: "58128",
    map_id: "1",
    address: "Viale Europa, 51, 72019 San Vito dei Normanni (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6566745",
    lng: "17.6995501",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9290",
  },
  {
    id: "58127",
    map_id: "1",
    address: "Via S.S. Lecce - Brindisi km 1.900, 73010 Surbo (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3763846",
    lng: "18.1444072",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9289",
  },
  {
    id: "58126",
    map_id: "1",
    address: "Viale Francesco Lilla, 71, 72100 Brindisi (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6327278",
    lng: "17.9417616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9288",
  },
  {
    id: "58125",
    map_id: "1",
    address: "Via Natale Loiacono, 20, 70126 Bari (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.10544",
    lng: "16.911895",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9287",
  },
  {
    id: "58124",
    map_id: "1",
    address: "Via Mazzini, 9, 40046 Alto Reno Terme (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.1561146",
    lng: "10.9757191",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9286",
  },
  {
    id: "58123",
    map_id: "1",
    address: "Via Gramsci, 41, 40013 Castel Maggiore (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.5714282",
    lng: "11.3610786",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9285",
  },
  {
    id: "58122",
    map_id: "1",
    address: "Via Massimo D'Azeglio, 96, 40123 Bologna (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4869406",
    lng: "11.3398254",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9284",
  },
  {
    id: "58121",
    map_id: "1",
    address: "Via Birmania, 32, 58100 Grosseto (GR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.7824357",
    lng: "11.1039336",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9283",
  },
  {
    id: "58120",
    map_id: "1",
    address: "Via Venanzo Varano, 2, 62032 Camerino (MC)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.1279414",
    lng: "13.0657102",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9282",
  },
  {
    id: "58119",
    map_id: "1",
    address: "Vicolo Enzo Ferrari, 5, 35028 Piove di Sacco (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.29877949999999",
    lng: "12.035089",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9278",
  },
  {
    id: "58118",
    map_id: "1",
    address: "Via Nazionale, 126, 30034 Mira (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4336828",
    lng: "12.1222944",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9277",
  },
  {
    id: "58117",
    map_id: "1",
    address: "Via Dal Verme, 80, 36100 Vicenza (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5650856",
    lng: "11.540385",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9276",
  },
  {
    id: "58116",
    map_id: "1",
    address: "Viale Amerigo Vespucci, 38, 47122 Forli' (FC)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.2276914",
    lng: "12.0531788",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9266",
  },
  {
    id: "58115",
    map_id: "1",
    address: "Via Emilia Est, 577- 581, 41121 Modena (MO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.63817400000001",
    lng: "10.9460597",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9265",
  },
  {
    id: "58114",
    map_id: "1",
    address: "Via Largo De Calboli, 2/3, 47121 Forli' (FC)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.2229507",
    lng: "12.0424582",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9264",
  },
  {
    id: "58113",
    map_id: "1",
    address: "Via Diodato Lioy, 20, 85029 Venosa (PZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9654981",
    lng: "15.8114669",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9262",
  },
  {
    id: "58112",
    map_id: "1",
    address: "Corso Sicilia, 93, 95131 Catania (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.5078643",
    lng: "15.090272",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9261",
  },
  {
    id: "58111",
    map_id: "1",
    address: "Via Roma, 4, 50063 Figline e Incisa Valdarno (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.6213552",
    lng: "11.4716955",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9247",
  },
  {
    id: "58110",
    map_id: "1",
    address: "Via Roma, 10, 50053 Empoli (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.719271",
    lng: "10.948823",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9246",
  },
  {
    id: "58109",
    map_id: "1",
    address: "Via Principe di Scordia, 6, 90139 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1224754",
    lng: "13.3623495",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9245",
  },
  {
    id: "58108",
    map_id: "1",
    address: "Via Provinciale, 8/a, 21030 Mesenzana (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.96310279999999",
    lng: "8.754679099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9243",
  },
  {
    id: "58107",
    map_id: "1",
    address: "Via Principessa Margherita, snc, 80074 Casamicciola Terme (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7469286",
    lng: "13.9061791",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9239",
  },
  {
    id: "58106",
    map_id: "1",
    address: "Via Monte Pasubio, 220, 36010 Zane' (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.724742",
    lng: "11.42667",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9237",
  },
  {
    id: "58105",
    map_id: "1",
    address: "Corso Matteotti, 86, 36071 Arzignano (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.521527",
    lng: "11.328882",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9236",
  },
  {
    id: "58104",
    map_id: "1",
    address: "Corso Novara, 161/f, 27029 Vigevano (PV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3297821",
    lng: "8.839329",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9235",
  },
  {
    id: "58103",
    map_id: "1",
    address: "Piazza Padre Giuliani, 32, 20063 Cernusco sul Naviglio (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5252324",
    lng: "9.3322706",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9233",
  },
  {
    id: "58102",
    map_id: "1",
    address: "Via Roma, 64, 64014 Martinsicuro (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.88801",
    lng: "13.911295",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9232",
  },
  {
    id: "58101",
    map_id: "1",
    address: "Piazza Brignole, 18, 16100 Genova (GE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4086192",
    lng: "8.944069899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9228",
  },
  {
    id: "58100",
    map_id: "1",
    address: "Via Carlo Rolando, 150, 16100 Genova (GE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.417643",
    lng: "8.8859519",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9227",
  },
  {
    id: "58099",
    map_id: "1",
    address: "Piazza Duomo, 81, 27058 Voghera (PV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.9933619",
    lng: "9.0092508",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9226",
  },
  {
    id: "58098",
    map_id: "1",
    address: "Via Pietro Mazza, 66, 27057 Varzi (PV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.8214889",
    lng: "9.2013301",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9225",
  },
  {
    id: "58097",
    map_id: "1",
    address: "Via Ausonia, 126, 90144 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1506141",
    lng: "13.3336445",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9224",
  },
  {
    id: "58096",
    map_id: "1",
    address: "Via Rosso Di San Secondo, 85/87, 93100 Caltanissetta (CL)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.4936493",
    lng: "14.0531608",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9223",
  },
  {
    id: "58095",
    map_id: "1",
    address: "Via Mario Nuccio, 90, 91025 Marsala (TP)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.7948789",
    lng: "12.436558",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9222",
  },
  {
    id: "58094",
    map_id: "1",
    address: "Via Aldo Moro, 25/27, 92026 Favara (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.3065178",
    lng: "13.6524669",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9221",
  },
  {
    id: "58093",
    map_id: "1",
    address: "Viale Mario Rapisardi, 222, 95123 Catania (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.5101792",
    lng: "15.0650793",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9220",
  },
  {
    id: "58092",
    map_id: "1",
    address: "Piazza Indirizzo, 22/23/24, 95024 Acireale (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.6198377",
    lng: "15.1676834",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9219",
  },
  {
    id: "58091",
    map_id: "1",
    address: "Via Etnea, 193, 96016 Lentini (SR)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2967468",
    lng: "15.001936",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9218",
  },
  {
    id: "58090",
    map_id: "1",
    address: "Via Giudice Livatino, 10/12, 92024 Canicatti' (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.3521308",
    lng: "13.8366345",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9217",
  },
  {
    id: "58089",
    map_id: "1",
    address: "Piazza Cavour, 5, 31046 Oderzo (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7809148",
    lng: "12.4954762",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9216",
  },
  {
    id: "58088",
    map_id: "1",
    address: "Via Nazionale, 5, 31027 Spresiano (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7806998",
    lng: "12.2591598",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9215",
  },
  {
    id: "58087",
    map_id: "1",
    address: "Corso Garibaldi, 181, 84081 Baronissi (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7473308",
    lng: "14.770306",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9212",
  },
  {
    id: "58086",
    map_id: "1",
    address: "Via Farnesiana, 6/a, 29122 Piacenza (PC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0444484",
    lng: "9.7057796",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9210",
  },
  {
    id: "58085",
    map_id: "1",
    address: "Via Gramsci, 72/74, 64021 Giulianova (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.7431497",
    lng: "13.9649192",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9208",
  },
  {
    id: "58084",
    map_id: "1",
    address: "Viale Avv. Vincenzo Scarano, 27, 71036 Lucera (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.5013167",
    lng: "15.3410037",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9199",
  },
  {
    id: "58083",
    map_id: "1",
    address: "Via Strada Nuova, 17, 37024 Negrar (VR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5024893",
    lng: "10.9326675",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9198",
  },
  {
    id: "58082",
    map_id: "1",
    address: "Via Adua, 39, 42124 Reggio Emilia (RE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.70521770000001",
    lng: "10.646929",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9190",
  },
  {
    id: "58081",
    map_id: "1",
    address: "Via Flaminia, 562, 60015 Falconara Marittima (AN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.6299676",
    lng: "13.3974155",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9173",
  },
  {
    id: "58080",
    map_id: "1",
    address: "Via San Martino, 34/b, 43014 Medesano (PR)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.69695919999999",
    lng: "10.0873144",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9161",
  },
  {
    id: "58079",
    map_id: "1",
    address: "Via delle Pleiadi, 1/b, 17024 Finale Ligure (SV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.1706493",
    lng: "8.3380732",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9160",
  },
  {
    id: "58078",
    map_id: "1",
    address: "Via Santa Caterina, 20, 17019 Varazze (SV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.3597765",
    lng: "8.5802855",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9159",
  },
  {
    id: "58077",
    map_id: "1",
    address: "Via Colla, 13, 17014 Cairo Montenotte (SV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.397195",
    lng: "8.278101999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9157",
  },
  {
    id: "58076",
    map_id: "1",
    address: "Via Provinciale Vallecchia, 19, 55045 Pietrasanta (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9608961",
    lng: "10.226991",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9156",
  },
  {
    id: "58075",
    map_id: "1",
    address: "Via della Repubblica, 156/a, 55051 Barga (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.0476801",
    lng: "10.4753772",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9155",
  },
  {
    id: "58074",
    map_id: "1",
    address: "Via Vittorio Emanuele, 76, 55041 Camaiore (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9385992",
    lng: "10.3030377",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9154",
  },
  {
    id: "58073",
    map_id: "1",
    address: "Via Novara, 11, 28024 Gozzano (NO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.73887000000001",
    lng: "8.439079999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9152",
  },
  {
    id: "58072",
    map_id: "1",
    address: "Piazza G. Garibaldi, 11, 20062 Cassano d'Adda (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5255274",
    lng: "9.5221468",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9147",
  },
  {
    id: "58071",
    map_id: "1",
    address: "Via Roma, 84, 70029 Santeramo in Colle (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7939348",
    lng: "16.7551046",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9142",
  },
  {
    id: "58070",
    map_id: "1",
    address: "Via Vittorio Veneto, 54, 24038 Sant'Omobono Terme (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.80943569999999",
    lng: "9.537538099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9141",
  },
  {
    id: "58069",
    map_id: "1",
    address:
      "Viale Europa C/O Centro Comm. Europa, 25036 Palazzolo sull'Oglio (BS)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5893974",
    lng: "9.894237700000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9134",
  },
  {
    id: "58068",
    map_id: "1",
    address: "Piazza Della Maddalena, 15, 40 Lanuvio (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.6759142",
    lng: "12.6976233",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9133",
  },
  {
    id: "58067",
    map_id: "1",
    address: "Via Campana, 222, 80019 Qualiano (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8781189",
    lng: "14.1211608",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9128",
  },
  {
    id: "58066",
    map_id: "1",
    address: "Via Roberto Bello, 15, 83012 Cervinara (AV)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0205925",
    lng: "14.6128192",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9127",
  },
  {
    id: "58065",
    map_id: "1",
    address: "Via Angelo Costanzi, 9, 5018 Orvieto (TR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.7222118",
    lng: "12.1308994",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9126",
  },
  {
    id: "58064",
    map_id: "1",
    address: "Via Napoli, 92, 4014 Pontinia (LT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.4121524",
    lng: "13.0419947",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9116",
  },
  {
    id: "58063",
    map_id: "1",
    address: "Via Confalonieri, 9, 21016 Luino (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.00202669999999",
    lng: "8.7454073",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9115",
  },
  {
    id: "58062",
    map_id: "1",
    address: "Via Torino, 97, 15076 Ovada (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.6376041",
    lng: "8.647153399999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9114",
  },
  {
    id: "58061",
    map_id: "1",
    address: "Via Cardinal Raimondi, 24, 15011 Acqui Terme (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.6739993",
    lng: "8.469938599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9113",
  },
  {
    id: "58060",
    map_id: "1",
    address: "Via Campo Sportivo, 1/a, 5015 Fabro (TR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.8744725",
    lng: "12.0434494",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9111",
  },
  {
    id: "58059",
    map_id: "1",
    address: "Via Francesco Sofia Alessio, 178, 89029 Taurianova (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.3543573",
    lng: "16.0193276",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9107",
  },
  {
    id: "58058",
    map_id: "1",
    address: "Via Posidonia, 179, 84128 Salerno (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6669924",
    lng: "14.7907301",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9106",
  },
  {
    id: "58057",
    map_id: "1",
    address: "Via Novara, 168, 28078 Romagnano Sesia (NO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6301933",
    lng: "8.3922022",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9105",
  },
  {
    id: "58056",
    map_id: "1",
    address: "Corso Vercelli, 21, 28100 Novara (NO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4355907",
    lng: "8.602391299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9102",
  },
  {
    id: "58055",
    map_id: "1",
    address: "Via Ceciliana, 5, 36 Palestrina (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.83824449999999",
    lng: "12.887485",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9101",
  },
  {
    id: "58054",
    map_id: "1",
    address: "Piazza G. Sacconi, 6, 52011 Bibbiena (AR)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.6944647",
    lng: "11.8091523",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9099",
  },
  {
    id: "58053",
    map_id: "1",
    address: "Corso Umberto I, 57, 92016 Ribera (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.502737",
    lng: "13.265446",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9097",
  },
  {
    id: "58052",
    map_id: "1",
    address: "Via Brindisi, 302, 72027 San Pietro Vernotico (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.4967823",
    lng: "17.9947094",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9090",
  },
  {
    id: "58051",
    map_id: "1",
    address: "Via Boemondo Normanno, 51, 72023 Mesagne (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.5614642",
    lng: "17.8051286",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9089",
  },
  {
    id: "58050",
    map_id: "1",
    address: "Piazza Vittoria, 16, 73018 Squinzano (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.438544",
    lng: "18.0397675",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9088",
  },
  {
    id: "58049",
    map_id: "1",
    address: "Via Roma, 39, 52025 Montevarchi (AR)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.5238647",
    lng: "11.5666057",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9087",
  },
  {
    id: "58048",
    map_id: "1",
    address: "Via Giotto, 72, 97013 Comiso (RG)",
    description: "BOLLETTINI - PROMO130",
    lat: "36.9484775",
    lng: "14.609257",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9083",
  },
  {
    id: "58047",
    map_id: "1",
    address: "Via Francavilla, 309 Fraz.Trappitello, 98039 Taormina (ME)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.834321",
    lng: "15.2441768",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9079",
  },
  {
    id: "58046",
    map_id: "1",
    address: "Via Jacopone da Todi, 8, 95024 Acireale (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.6142059",
    lng: "15.1556749",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9078",
  },
  {
    id: "58045",
    map_id: "1",
    address: "Via Vittorio Emanuele III, 461, 95032 Belpasso (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.6011522",
    lng: "14.9850435",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9077",
  },
  {
    id: "58044",
    map_id: "1",
    address: "Piazza delle Rimembranze, 55, 97016 Pozzallo (RG)",
    description: "BOLLETTINI - PROMO130",
    lat: "36.7273629",
    lng: "14.8468492",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9076",
  },
  {
    id: "58043",
    map_id: "1",
    address: "Via Carlo Marx, 68, 59100 Prato (PO)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.870836",
    lng: "11.0921596",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9072",
  },
  {
    id: "58042",
    map_id: "1",
    address: "Viale Garibaldi, 61, 21014 Laveno-Mombello (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.9078051",
    lng: "8.6212527",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9071",
  },
  {
    id: "58041",
    map_id: "1",
    address: "Corso Mazzini, 104, 70018 Rutigliano (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0093205",
    lng: "17.0063673",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9070",
  },
  {
    id: "58040",
    map_id: "1",
    address: "Corso Umberto I, sn, 89822 Serra San Bruno (VV)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.5758472",
    lng: "16.3293266",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9068",
  },
  {
    id: "58039",
    map_id: "1",
    address: "Viale Lincoln, 127, 81100 Caserta (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0654441",
    lng: "14.3345566",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9066",
  },
  {
    id: "58038",
    map_id: "1",
    address: "Via Torino, 88, 66054 Vasto (CH)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.1144696",
    lng: "14.7086605",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9064",
  },
  {
    id: "58037",
    map_id: "1",
    address: "Corso Umberto I, 86, 66050 San Salvo (CH)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.0468043",
    lng: "14.7322307",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9063",
  },
  {
    id: "58036",
    map_id: "1",
    address: "Via Tenente M.Fiorino, 13, 70056 Molfetta (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.2025227",
    lng: "16.6030542",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9062",
  },
  {
    id: "58035",
    map_id: "1",
    address: "Via Svizzera, 251, 58100 Grosseto (GR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.7819217",
    lng: "11.1091953",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9060",
  },
  {
    id: "58034",
    map_id: "1",
    address: "Piazza Vittorio Veneto, 52, 50019 Sesto Fiorentino (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.83158510000001",
    lng: "11.1985827",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9059",
  },
  {
    id: "58033",
    map_id: "1",
    address: "Via Roma, 42, 6019 Umbertide (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.3066056",
    lng: "12.3306794",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9058",
  },
  {
    id: "58032",
    map_id: "1",
    address: "Via Amendola, 27/a, 46028 Sermide e Felonica (MN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0009096",
    lng: "11.2950353",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9040",
  },
  {
    id: "58031",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 212, 76125 Trani (BT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.2755448",
    lng: "16.4188076",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9037",
  },
  {
    id: "58030",
    map_id: "1",
    address: "Piazzale Barzaghi, 9, 26900 Lodi (LO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.31760370000001",
    lng: "9.507168700000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9035",
  },
  {
    id: "58029",
    map_id: "1",
    address: "Corso Domenico Riccardi, 110, 80040 Cercola (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8586394",
    lng: "14.3564726",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9032",
  },
  {
    id: "58028",
    map_id: "1",
    address: "Via Genova, 98/e, 80143 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8550234",
    lng: "14.2739311",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9031",
  },
  {
    id: "58027",
    map_id: "1",
    address: "Via Trieste, 64/c, 23100 Sondrio (SO)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.1694906",
    lng: "9.874465899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9029",
  },
  {
    id: "58026",
    map_id: "1",
    address: "Via Vincenzo Madonia, 39, 90049 Terrasini (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.154061",
    lng: "13.0818916",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9028",
  },
  {
    id: "58025",
    map_id: "1",
    address: "Via dei Pioppi, 37, 6063 Magione (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.1438255",
    lng: "12.2145662",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9027",
  },
  {
    id: "58024",
    map_id: "1",
    address: "Via Lione, Sn, 3042 Atina (FR)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.6334843",
    lng: "13.7846236",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9026",
  },
  {
    id: "58023",
    map_id: "1",
    address: "Via Nazionale, 188, 98048 Spadafora (ME)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.2189969",
    lng: "15.3675281",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9025",
  },
  {
    id: "58022",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 174/b, 70019 Triggiano (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0647846",
    lng: "16.9194901",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9023",
  },
  {
    id: "58021",
    map_id: "1",
    address: "Corso Umberto I, 2/b, 9074 Ghilarza (OR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.1253563",
    lng: "8.839592",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9018",
  },
  {
    id: "58020",
    map_id: "1",
    address: "Via Manardi, snc, 63900 Fermo (FM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.1588734",
    lng: "13.7200884",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9016",
  },
  {
    id: "58019",
    map_id: "1",
    address: "Via IV Novembre, 1, 43056 Torrile (PR)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.9004368",
    lng: "10.3594784",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9011",
  },
  {
    id: "58018",
    map_id: "1",
    address: "Via Roma, 55, 21047 Saronno (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6249139",
    lng: "9.0417221",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9010",
  },
  {
    id: "58017",
    map_id: "1",
    address: "Via Marmolada, 20/22, 37012 Bussolengo (VR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.46728179999999",
    lng: "10.8551407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9009",
  },
  {
    id: "58016",
    map_id: "1",
    address: "Via Pistoia, 21, 51039 Quarrata (PT)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8485574",
    lng: "10.9761949",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9007",
  },
  {
    id: "58015",
    map_id: "1",
    address: "Via Sarino Pugliese, 21/23, 89013 Gioia Tauro (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.4258852",
    lng: "15.898232",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9006",
  },
  {
    id: "58014",
    map_id: "1",
    address: "Via Giuseppe Verdi, 1, 20842 Besana in Brianza (MB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7018668",
    lng: "9.2867408",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9001",
  },
  {
    id: "58013",
    map_id: "1",
    address: "Via Coazze, 16, 10094 Giaveno (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.04165039999999",
    lng: "7.349811599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8993",
  },
  {
    id: "58012",
    map_id: "1",
    address: "Via Aldo Moro, 31, 80035 Nola (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9210561",
    lng: "14.5331934",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8992",
  },
  {
    id: "58011",
    map_id: "1",
    address: "Via Martin, 4, 31053 Pieve di Soligo (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8999531",
    lng: "12.170205",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8990",
  },
  {
    id: "58010",
    map_id: "1",
    address: "Via Peluso, 13, 74023 Grottaglie (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.535664",
    lng: "17.435717",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8985",
  },
  {
    id: "58009",
    map_id: "1",
    address: "Via Principe di Piemonte, 128, 74027 San Giorgio Ionico (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.4551007",
    lng: "17.3823819",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8984",
  },
  {
    id: "58008",
    map_id: "1",
    address: "Via San Giuliano, 177, 97015 Modica (RG)",
    description: "BOLLETTINI - PROMO130",
    lat: "36.8422801",
    lng: "14.7667886",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8983",
  },
  {
    id: "58007",
    map_id: "1",
    address: "Via Umberto I, 83, 33038 San Daniele del Friuli (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.1578982",
    lng: "13.0099377",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8982",
  },
  {
    id: "58006",
    map_id: "1",
    address: "Viale Risorgimento, 74, 91021 Campobello di Mazara (TP)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.6360972",
    lng: "12.7385402",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8981",
  },
  {
    id: "58005",
    map_id: "1",
    address: "Piazza Madama Cristina, 2, 10125 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.05921619999999",
    lng: "7.6834191",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8980",
  },
  {
    id: "58004",
    map_id: "1",
    address: "Via Valle del Liri, 24, 3024 Ceprano (FR)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.5499106",
    lng: "13.522447",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8979",
  },
  {
    id: "58003",
    map_id: "1",
    address: "Viale Farra, 37, 32032 Feltre (BL)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.0243916",
    lng: "11.8966154",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8976",
  },
  {
    id: "58002",
    map_id: "1",
    address: "Via Cesare Battisti, Sn, 89048 Siderno (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.2701368",
    lng: "16.2986149",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8963",
  },
  {
    id: "58001",
    map_id: "1",
    address: "Via Isaia Gabola, 35, 84014 Nocera Inferiore (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.744081",
    lng: "14.627855",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8962",
  },
  {
    id: "58000",
    map_id: "1",
    address: "Via G.Piani, 29, 84087 Sarno (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8086702",
    lng: "14.6227633",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8954",
  },
  {
    id: "57999",
    map_id: "1",
    address: "Via Umberto I, 17, 26866 Sant'Angelo Lodigiano (LO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.2378502",
    lng: "9.4094322",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8951",
  },
  {
    id: "57998",
    map_id: "1",
    address: "Via Enrico Fermi, 89, 80010 Villaricca (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9190517",
    lng: "14.1961567",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8946",
  },
  {
    id: "57997",
    map_id: "1",
    address: "Via Giulio Cesare, 75/77, 80125 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8250723",
    lng: "14.2029931",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8945",
  },
  {
    id: "57996",
    map_id: "1",
    address: "Corso Europa, 154, 80016 Marano di Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8980414",
    lng: "14.2011987",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8944",
  },
  {
    id: "57995",
    map_id: "1",
    address: "Via Cimabue, 9, 30027 San Dona' di Piave (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6193552",
    lng: "12.5764812",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8943",
  },
  {
    id: "57994",
    map_id: "1",
    address: "Calle delle Liburniche, 24, 30021 Caorle (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.59995379999999",
    lng: "12.8877713",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8942",
  },
  {
    id: "57993",
    map_id: "1",
    address: "Via Mulino, 1, 87050 Casali del Manco (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.28412369999999",
    lng: "16.3383081",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8940",
  },
  {
    id: "57992",
    map_id: "1",
    address: "Corso del Popolo, 19, 95047 Paterno' (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.5747598",
    lng: "14.9031547",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8928",
  },
  {
    id: "57991",
    map_id: "1",
    address: "Via Arduino, 2, 10082 Cuorgne' (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.39140039999999",
    lng: "7.650104000000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8924",
  },
  {
    id: "57990",
    map_id: "1",
    address: "Via Brandizzo, 22, 10088 Volpiano (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.2004001",
    lng: "7.7813412",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8923",
  },
  {
    id: "57989",
    map_id: "1",
    address: "Via Lamarmora, 21/b, 13900 Biella (BI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5612986",
    lng: "8.053212799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8922",
  },
  {
    id: "57988",
    map_id: "1",
    address: "Piazza Chioratti, 3, 10086 Rivarolo Canavese (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3316324",
    lng: "7.722877800000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8921",
  },
  {
    id: "57987",
    map_id: "1",
    address: "Via della Cerquiglia, 162, 6049 Spoleto (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.7467485",
    lng: "12.7360934",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8920",
  },
  {
    id: "57986",
    map_id: "1",
    address: "Via Roma, 1, 76121 Barletta (BT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.31881",
    lng: "16.27837",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8919",
  },
  {
    id: "57985",
    map_id: "1",
    address: "Via Cavour, 44, 55011 Altopascio (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8144634",
    lng: "10.675427",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8915",
  },
  {
    id: "57984",
    map_id: "1",
    address: "Via Francesco Crispi, 1/18, 84126 Salerno (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.68617769999999",
    lng: "14.771625",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8912",
  },
  {
    id: "57983",
    map_id: "1",
    address: "Viale Goito, 13, 76123 Andria (BT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.232808",
    lng: "16.2938829",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8908",
  },
  {
    id: "57982",
    map_id: "1",
    address: "Via Verdi, 5, 34077 Ronchi dei Legionari (GO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.82334900000001",
    lng: "13.501017",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8906",
  },
  {
    id: "57981",
    map_id: "1",
    address: "Via Giuseppe Minottini, 9, 6129 Perugia (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.08529679999999",
    lng: "12.3661529",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8903",
  },
  {
    id: "57980",
    map_id: "1",
    address: "Via Tirso, 8, 5018 Orvieto (TR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.7261847",
    lng: "12.0924944",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8902",
  },
  {
    id: "57979",
    map_id: "1",
    address: "Corso Cavour, 48, 27036 Mortara (PV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.2509148",
    lng: "8.7391673",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8888",
  },
  {
    id: "57978",
    map_id: "1",
    address: "Viale Michelangelo, snc, 80040 Volla (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8557761",
    lng: "14.3573517",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8887",
  },
  {
    id: "57977",
    map_id: "1",
    address: "Via Roma, 7, 27026 Garlasco (PV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.1956149",
    lng: "8.925093",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8873",
  },
  {
    id: "57976",
    map_id: "1",
    address: "Via Liberta', 45, 20092 Cinisello Balsamo (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5555721",
    lng: "9.2157211",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8871",
  },
  {
    id: "57975",
    map_id: "1",
    address: "Viale J.F. Kennedy, snc, 87070 Plataci (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.90113849999999",
    lng: "16.4298138",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8865",
  },
  {
    id: "57974",
    map_id: "1",
    address: "Via Caduti del Lavoro, 30, 43043 Borgo Val di Taro (PR)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4896247",
    lng: "9.779589099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8864",
  },
  {
    id: "57973",
    map_id: "1",
    address: "Viale Trento, 93, 36078 Valdagno (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.64483310000001",
    lng: "11.3024773",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8863",
  },
  {
    id: "57972",
    map_id: "1",
    address: "Corso Matteotti, 48, 50054 Fucecchio (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7275385",
    lng: "10.8096859",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8861",
  },
  {
    id: "57971",
    map_id: "1",
    address: "Via Pasquale Pastorino, 92/r, 16100 Genova (GE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4605347",
    lng: "8.9020077",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8860",
  },
  {
    id: "57970",
    map_id: "1",
    address: "Via Tiberina, 224, 6053 Deruta (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.9822685",
    lng: "12.4164094",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8855",
  },
  {
    id: "57969",
    map_id: "1",
    address: "Viale Vallone, 12, 173 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.8344488",
    lng: "12.5966022",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8854",
  },
  {
    id: "57968",
    map_id: "1",
    address: "Via Olindo Malagoli, 41, 44042 Cento (FE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7284438",
    lng: "11.2935603",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8853",
  },
  {
    id: "57967",
    map_id: "1",
    address: "Via V.V. Lenoci, 33, 71045 Orta Nova (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.3279502",
    lng: "15.709782",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8842",
  },
  {
    id: "57966",
    map_id: "1",
    address: "Via Garibaldi, 39, 21026 Gavirate (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8439354",
    lng: "8.7159396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8840",
  },
  {
    id: "57965",
    map_id: "1",
    address: "Via Carpenedolo, 2, 46043 Castiglione delle Stiviere (MN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3878819",
    lng: "10.4816547",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8828",
  },
  {
    id: "57964",
    map_id: "1",
    address: "Via Vallestrona, 2, 28887 Omegna (VB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8808805",
    lng: "8.4041315",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8824",
  },
  {
    id: "57963",
    map_id: "1",
    address: "Via Emilia Ponente, 86/e, 40133 Bologna (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.5069553",
    lng: "11.304007",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8821",
  },
  {
    id: "57962",
    map_id: "1",
    address: "Via Mazzini, 85, 13836 Cossato (BI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5691999",
    lng: "8.1828338",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8817",
  },
  {
    id: "57961",
    map_id: "1",
    address: "Via della Cooperativa, 11/g, 33028 Tolmezzo (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.4060758",
    lng: "13.0126473",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8811",
  },
  {
    id: "57960",
    map_id: "1",
    address: "Viale della Vittoria, 32/h, 60035 Jesi (AN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.5243261",
    lng: "13.2434774",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8808",
  },
  {
    id: "57959",
    map_id: "1",
    address: "Via Santo Stefano, 104, 50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8212199",
    lng: "11.1368359",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8805",
  },
  {
    id: "57958",
    map_id: "1",
    address: "Via Armando Casalini, 38, 90031 Belmonte Mezzagno (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.0480876",
    lng: "13.3911866",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8801",
  },
  {
    id: "57957",
    map_id: "1",
    address: "Via Emilia, 56/5, 27043 Broni (PV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.061181",
    lng: "9.258536",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8799",
  },
  {
    id: "57956",
    map_id: "1",
    address: "Zona Pip Strada Statale 16 Km 962.700 Snc, 73020 Cavallino (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.29348840000001",
    lng: "18.1980975",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8793",
  },
  {
    id: "57955",
    map_id: "1",
    address: "Via Risorgimento, 111/b, 40069 Zola Predosa (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4905304",
    lng: "11.2213766",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8791",
  },
  {
    id: "57954",
    map_id: "1",
    address: "Via Baldo degli Ubaldi, 178, 167 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.8994177",
    lng: "12.4359124",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8787",
  },
  {
    id: "57953",
    map_id: "1",
    address: "Via C.Cattaneo, 86, 3039 Sora (FR)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.72143579999999",
    lng: "13.6071199",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8785",
  },
  {
    id: "57952",
    map_id: "1",
    address: "Via Pellipario, 5, 61029 Urbino (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7289389",
    lng: "12.6315499",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8783",
  },
  {
    id: "57951",
    map_id: "1",
    address: "Viale Trieste, 84, 22077 Olgiate Comasco (CO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7768989",
    lng: "8.9769277",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8782",
  },
  {
    id: "57950",
    map_id: "1",
    address: "Piazzale Candiani, 32, 36051 Creazzo (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5334778",
    lng: "11.4779283",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8781",
  },
  {
    id: "57949",
    map_id: "1",
    address: "Via Badia, 237, 90145 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1360025",
    lng: "13.3192538",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8779",
  },
  {
    id: "57948",
    map_id: "1",
    address: "Via Mazzini, 57, 25043 Breno (BS)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.95555510000001",
    lng: "10.3006477",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8777",
  },
  {
    id: "57947",
    map_id: "1",
    address: "Via Manzoni Alessandro, 332, 6087 Perugia (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.0916056",
    lng: "12.4449319",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8765",
  },
  {
    id: "57946",
    map_id: "1",
    address: "Via Provinciale, 57, 81030 Villa di Briano (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0007622",
    lng: "14.1486769",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8764",
  },
  {
    id: "57945",
    map_id: "1",
    address: "Via Trento, 45/b, 62100 Macerata (MC)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.3020959",
    lng: "13.4430037",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8763",
  },
  {
    id: "57944",
    map_id: "1",
    address: "Via Marco Polo, 7, 20098 San Giuliano Milanese (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3975468",
    lng: "9.2831405",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8762",
  },
  {
    id: "57943",
    map_id: "1",
    address: "Via Garibaldi, 58, 64028 Silvi (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.5576983",
    lng: "14.1132363",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8758",
  },
  {
    id: "57942",
    map_id: "1",
    address: "Via Corace, 17, 88100 Catanzaro (CZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.8201119",
    lng: "16.6111039",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8754",
  },
  {
    id: "57941",
    map_id: "1",
    address: "Via Cavalli, 19, 26013 Crema (CR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.366595",
    lng: "9.684071699999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8750",
  },
  {
    id: "57940",
    map_id: "1",
    address: "Via Vittoria Veneto, 82, 70010 Adelfia (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0038578",
    lng: "16.8747014",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8748",
  },
  {
    id: "57939",
    map_id: "1",
    address: "Corso Savona, 309, 14100 Asti (AT)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.88821309999999",
    lng: "8.2112563",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8747",
  },
  {
    id: "57938",
    map_id: "1",
    address: "Corso Umberto I, 489, 65015 Montesilvano (PE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.496704",
    lng: "14.1741732",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8745",
  },
  {
    id: "57937",
    map_id: "1",
    address: "Piazza Mazzini,12, 46035 Ostiglia (MN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0686077",
    lng: "11.1328086",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8744",
  },
  {
    id: "57936",
    map_id: "1",
    address: "Via Emilia, 141, 40068 San Lazzaro di Savena (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.471",
    lng: "11.4077951",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8723",
  },
  {
    id: "57935",
    map_id: "1",
    address: "Via Novara, 114, 28047 Oleggio (NO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.580442",
    lng: "8.6388267",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8722",
  },
  {
    id: "57934",
    map_id: "1",
    address: "Via della Repubblica, 118, 57016 Rosignano Marittimo (LI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.395706",
    lng: "10.4527397",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8720",
  },
  {
    id: "57933",
    map_id: "1",
    address:
      "Corso Valentino - Angolo Via Rosselli, 5, 15033 Casale Monferrato (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.1239687",
    lng: "8.4499279",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8717",
  },
  {
    id: "57932",
    map_id: "1",
    address: "Via M.Tacca, 119, 14049 Nizza Monferrato (AT)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.782229",
    lng: "8.370512999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8713",
  },
  {
    id: "57931",
    map_id: "1",
    address: "Via dei Colombini, 53/a, 55012 Capannori (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8458437",
    lng: "10.574289",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8711",
  },
  {
    id: "57930",
    map_id: "1",
    address: "Via Valenti, 2, 60035 Ancona (AN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.5725296",
    lng: "13.5083132",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8695",
  },
  {
    id: "57929",
    map_id: "1",
    address: "Via Elena, 113, 89025 Rosarno (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.4873199",
    lng: "15.9778524",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8692",
  },
  {
    id: "57928",
    map_id: "1",
    address: "Via Mazzini, 50/50, 64011 Alba Adriatica (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.8323277",
    lng: "13.9241799",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8690",
  },
  {
    id: "57927",
    map_id: "1",
    address: "Corso Roma, 44/46, 95042 Grammichele (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2145132",
    lng: "14.6349122",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8686",
  },
  {
    id: "57926",
    map_id: "1",
    address: "Via Antonio Bruno, 89/a, 74024 Manduria (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.4022183",
    lng: "17.6327458",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8680",
  },
  {
    id: "57925",
    map_id: "1",
    address: "Via Venezia, 237, 47521 Cesena (FC)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.1532877",
    lng: "12.2396823",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8679",
  },
  {
    id: "57924",
    map_id: "1",
    address: "Via Corte, 14/b, 32041 Auronzo di Cadore (BL)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.5523025",
    lng: "12.4403434",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8678",
  },
  {
    id: "57923",
    map_id: "1",
    address: "Via Paolo Borsellino, 59, 65020 Alanno (PE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.289925",
    lng: "14.0056704",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8677",
  },
  {
    id: "57922",
    map_id: "1",
    address: "Via Martiri della Liberta', 12 Bis, 10024 Moncalieri (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.9996227",
    lng: "7.6783848",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8647",
  },
  {
    id: "57921",
    map_id: "1",
    address: "Via Mario Milano, 2l, 86039 Termoli (CB)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.0017931",
    lng: "14.9931103",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8644",
  },
  {
    id: "57920",
    map_id: "1",
    address: "Via Galileo Galilei, 7, 73100 Lecce (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3467605",
    lng: "18.1570538",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8642",
  },
  {
    id: "57919",
    map_id: "1",
    address: "Via Roma, 56, 74011 Castellaneta (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6310864",
    lng: "16.9332515",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8640",
  },
  {
    id: "57918",
    map_id: "1",
    address: "Via G. Matteotti, 107, 3023 Ceccano (FR)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.5677767",
    lng: "13.3275743",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8639",
  },
  {
    id: "57917",
    map_id: "1",
    address: "Via Gramsci, 51, 19121 La Spezia (SP)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.1044547",
    lng: "9.817964199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8638",
  },
  {
    id: "57916",
    map_id: "1",
    address: "Via Polveriera, 110, 80050 Santa Maria la Carita (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7198818",
    lng: "14.5162814",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8627",
  },
  {
    id: "57915",
    map_id: "1",
    address: "Viale Mario Milazzo, 186, 95041 Caltagirone (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2209482",
    lng: "14.5257848",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8602",
  },
  {
    id: "57914",
    map_id: "1",
    address: "Via Roma, 15, 81058 Vairano Patenora (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.33253560000001",
    lng: "14.1314496",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8594",
  },
  {
    id: "57913",
    map_id: "1",
    address: "Corso dei Mille, 6, 91011 Alcamo (TP)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.97980560000001",
    lng: "12.9553171",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8592",
  },
  {
    id: "57912",
    map_id: "1",
    address: "Via Piave, 15, 30033 Noale (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5523335",
    lng: "12.0744572",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8588",
  },
  {
    id: "57911",
    map_id: "1",
    address: "Via Torino, 17, 10036 Settimo Torinese (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.1348447",
    lng: "7.764563099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8584",
  },
  {
    id: "57910",
    map_id: "1",
    address: "Via Garibaldi, 83, 95048 Scordia (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2954035",
    lng: "14.8416044",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8582",
  },
  {
    id: "57909",
    map_id: "1",
    address: "Corso Garibaldi, 585, 92029 Ravanusa (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2653047",
    lng: "13.9706561",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8581",
  },
  {
    id: "57908",
    map_id: "1",
    address: "Via Pollino, 24, 87012 Castrovillari (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.81444",
    lng: "16.2054931",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8577",
  },
  {
    id: "57907",
    map_id: "1",
    address: "Via Per Busto, 4/6, 21054 Fagnano Olona (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6632876",
    lng: "8.8679832",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8576",
  },
  {
    id: "57906",
    map_id: "1",
    address: "Via XXIX Aprile, 33, 36015 Schio (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7169027",
    lng: "11.3616339",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8562",
  },
  {
    id: "57905",
    map_id: "1",
    address: "Via Guido Reni, 77/c, 35134 Padova (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4357901",
    lng: "11.8913224",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8561",
  },
  {
    id: "57904",
    map_id: "1",
    address: "Via Zanoni, 40, 21037 Lavena Ponte Tresa (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.9660683",
    lng: "8.857672599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8559",
  },
  {
    id: "57903",
    map_id: "1",
    address: "Via Nazionale, 554, 89018 Villa San Giovanni (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.2188907",
    lng: "15.637952",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8558",
  },
  {
    id: "57902",
    map_id: "1",
    address: "Via Giuseppe Verdi, 5, 80014 Giugliano in Campania (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9336351",
    lng: "14.2031267",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8556",
  },
  {
    id: "57901",
    map_id: "1",
    address: "Sottocolle Verzan, 12, 33038 San Daniele del Friuli (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.1841132",
    lng: "13.0503668",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8555",
  },
  {
    id: "57900",
    map_id: "1",
    address: "Via Matteotti, 3c, 22066 Mariano Comense (CO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6983816",
    lng: "9.1764534",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8535",
  },
  {
    id: "57899",
    map_id: "1",
    address: "Viale Rizzera, 480, 31029 Vittorio Veneto (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.9735526",
    lng: "12.3080993",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8518",
  },
  {
    id: "57898",
    map_id: "1",
    address: "Via Guglielmo Marconi, 7/e, 67100 L'Aquila (AQ)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.3632651",
    lng: "13.3968327",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8512",
  },
  {
    id: "57897",
    map_id: "1",
    address: "Via A.Migliaccio, 25, 88024 Girifalco (CZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.824006",
    lng: "16.4248723",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8511",
  },
  {
    id: "57896",
    map_id: "1",
    address: "Via Dario Campana, 8, 47922 Rimini (RN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.0586109",
    lng: "12.5609536",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8501",
  },
  {
    id: "57895",
    map_id: "1",
    address: "Via Aurelia, 91, 17025 Loano (SV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.130792",
    lng: "8.260010399999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8497",
  },
  {
    id: "57894",
    map_id: "1",
    address: "Via Sant'Agostino, 24, 7041 Alghero (SS)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.55795",
    lng: "8.3202157",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8496",
  },
  {
    id: "57893",
    map_id: "1",
    address: "Piazza Europa, 9, 10023 Chieri (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0075097",
    lng: "7.8269996",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8495",
  },
  {
    id: "57892",
    map_id: "1",
    address: "Via Lord Baden Powell, 25, 36045 Lonigo (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3694471",
    lng: "11.3855497",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8493",
  },
  {
    id: "57891",
    map_id: "1",
    address: "Via San Donato, 21, 40127 Bologna (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4991776",
    lng: "11.3584399",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8464",
  },
  {
    id: "57890",
    map_id: "1",
    address: "Via Palmiro Togliatti, 35, 88811 Ciro' Marina (KR)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.3655969",
    lng: "17.1264137",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8463",
  },
  {
    id: "57889",
    map_id: "1",
    address: "Via Sottopovolo, 87, 33053 Latisana (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7731099",
    lng: "12.9974832",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8462",
  },
  {
    id: "57888",
    map_id: "1",
    address: "Corso Antonio Jatta, 47, 70037 Ruvo di Puglia (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.118952",
    lng: "16.4884884",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8452",
  },
  {
    id: "57887",
    map_id: "1",
    address: "Via del Porto, 2/5, 10099 San Mauro Torinese (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.1032987",
    lng: "7.7600764",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8450",
  },
  {
    id: "57886",
    map_id: "1",
    address: "Via Imera, 173/175, 92100 Agrigento (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.3155051",
    lng: "13.5820363",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8447",
  },
  {
    id: "57885",
    map_id: "1",
    address: "Via Giacomo Matteotti, 151, 45018 Porto Tolle (RO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.95010680000001",
    lng: "12.3204364",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8427",
  },
  {
    id: "57884",
    map_id: "1",
    address: "Via Ugo Braschi, 37, 47822 Santarcangelo di Romagna (RN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.0648391",
    lng: "12.4497684",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8423",
  },
  {
    id: "57883",
    map_id: "1",
    address: "Piazza Lamarmora, 7, 10015 Ivrea (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4626698",
    lng: "7.873057200000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8376",
  },
  {
    id: "57882",
    map_id: "1",
    address: "Via Edilio Raggio, 41, 15067 Novi Ligure (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7591865",
    lng: "8.7928871",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8344",
  },
  {
    id: "57881",
    map_id: "1",
    address: "C.so Umberto I, 179, 8015 Macomer (NU)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.2665341",
    lng: "8.777177400000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8339",
  },
  {
    id: "57880",
    map_id: "1",
    address: "Via Manzoni, 10, 96012 Avola (SR)",
    description: "BOLLETTINI - PROMO130",
    lat: "36.9094337",
    lng: "15.1321583",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8023",
  },
  {
    id: "57879",
    map_id: "1",
    address: "Circonvallazione Ovest, 11/13, 33033 Codroipo (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.9608615",
    lng: "12.9729545",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7712",
  },
  {
    id: "57878",
    map_id: "1",
    address: "Via Regina Margherita, 284 b, 76121 Barletta (BT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.320054",
    lng: "16.2641972",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7704",
  },
  {
    id: "57877",
    map_id: "1",
    address: "Via F.Bianchi, 28, 20066 Melzo (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.50033699999999",
    lng: "9.421382",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7544",
  },
  {
    id: "57876",
    map_id: "1",
    address: "Via di Novoli, 75 s, 50127 Firenze (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7925482",
    lng: "11.2218751",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7539",
  },
  {
    id: "57875",
    map_id: "1",
    address: "Via Tor Pisana, 86, 72100 Brindisi (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.632963",
    lng: "17.9385316",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7532",
  },
  {
    id: "57874",
    map_id: "1",
    address: "Via Colombo, 18, 97018 Scicli (RG)",
    description: "BOLLETTINI - PROMO130",
    lat: "36.7915672",
    lng: "14.7011238",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7530",
  },
  {
    id: "57873",
    map_id: "1",
    address: "Via Bruno Buozzi, 112, 95045 Misterbianco (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.5223643",
    lng: "15.0033055",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7529",
  },
  {
    id: "57872",
    map_id: "1",
    address: "Corso Tukori, 198, 90143 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1085753",
    lng: "13.3587844",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7528",
  },
  {
    id: "57871",
    map_id: "1",
    address: "Via Nuova, 7, 80136 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8694341",
    lng: "14.3515459",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7527",
  },
  {
    id: "57870",
    map_id: "1",
    address: "Piazzetta Monsignor G. Lorenzon, 34, 36100 Vicenza (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5498757",
    lng: "11.5242984",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7526",
  },
  {
    id: "57869",
    map_id: "1",
    address: "Via Roma, 121, 29011 Borgonovo Val Tidone (PC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0146844",
    lng: "9.4469756",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7524",
  },
  {
    id: "57868",
    map_id: "1",
    address: "Via XX Settembre, 17, 13100 Vercelli (VC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3223011",
    lng: "8.4192237",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7521",
  },
  {
    id: "57867",
    map_id: "1",
    address: "Via Gramsci, 70, 43036 Fidenza (PR)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.8647392",
    lng: "10.0614902",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7519",
  },
  {
    id: "57866",
    map_id: "1",
    address: "Via Santa Maria, 66, 48 Nettuno (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.4587936",
    lng: "12.6599336",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7507",
  },
  {
    id: "57865",
    map_id: "1",
    address: "Piazza Imperatore Tito, 8, 20137 Milano (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4543044",
    lng: "9.2189823",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7502",
  },
  {
    id: "57864",
    map_id: "1",
    address: "Via Bachelet, 5, 73057 Taviano (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.9874418",
    lng: "18.0778312",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7501",
  },
  {
    id: "57863",
    map_id: "1",
    address: "Via Alessandro Manzoni, 82, 80026 Casoria (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.90433850000001",
    lng: "14.2830334",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7500",
  },
  {
    id: "57862",
    map_id: "1",
    address: "Via Colonnetta, 157, 66100 Chieti (CH)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.357385",
    lng: "14.1455606",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7495",
  },
  {
    id: "57861",
    map_id: "1",
    address: "Via XXIV Maggio, 46, 31015 Conegliano (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8920773",
    lng: "12.31303",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7491",
  },
  {
    id: "57860",
    map_id: "1",
    address: "Via Soleto, 7, 73013 Galatina (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.1759891",
    lng: "18.1734188",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7488",
  },
  {
    id: "57859",
    map_id: "1",
    address: "Via S. Allende, 16, 47841 Cattolica (RN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9631236",
    lng: "12.7339543",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7482",
  },
  {
    id: "57858",
    map_id: "1",
    address: "Via Vanvitelli, 47, 84085 Mercato San Severino (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7855279",
    lng: "14.7554363",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7476",
  },
  {
    id: "57857",
    map_id: "1",
    address: "Via Principe Umberto, 52, 70016 Noicattaro (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0357395",
    lng: "16.9882547",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7455",
  },
  {
    id: "57856",
    map_id: "1",
    address: "Via Giuseppe Manfredi, 98/b, 29122 Piacenza (PC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0349593",
    lng: "9.684792999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7453",
  },
  {
    id: "57855",
    map_id: "1",
    address: "Via Giacomo Leopardi, 24/c, 22036 Erba (CO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8144075",
    lng: "9.2303037",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7446",
  },
  {
    id: "57854",
    map_id: "1",
    address: "Bortolo Sacchi, 5/7 a, 36061 Bassano del Grappa (VI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7526371",
    lng: "11.7226332",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7409",
  },
  {
    id: "57853",
    map_id: "1",
    address: "Via Monte Grappa, 49, 48100 Ravenna (RA)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4130858",
    lng: "12.1915844",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7381",
  },
  {
    id: "57852",
    map_id: "1",
    address: "Viale Trieste, 17, 45100 Rovigo (RO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.07256330000001",
    lng: "11.7878496",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7380",
  },
  {
    id: "57851",
    map_id: "1",
    address: "Via Monticano, 40, 30016 Jesolo (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5305123",
    lng: "12.641586",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7371",
  },
  {
    id: "57850",
    map_id: "1",
    address: "Via Legnago, 39, 37134 Verona (VR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4125862",
    lng: "10.9971127",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7370",
  },
  {
    id: "57849",
    map_id: "1",
    address: "Corso Mazzini, 192, 66054 Vasto (CH)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.1205634",
    lng: "14.7065263",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7349",
  },
  {
    id: "57848",
    map_id: "1",
    address: "Passo di Napoleone, 642/e, 37020 Dolce' (VR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5305974",
    lng: "10.8179401",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7330",
  },
  {
    id: "57847",
    map_id: "1",
    address: "Via Giuseppe Di Vittorio, 37, 71043 Manfredonia (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.62129669999999",
    lng: "15.9050909",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7329",
  },
  {
    id: "57846",
    map_id: "1",
    address: "Giordano Bruno, 6/b, 48015 Cervia (RA)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.2611119",
    lng: "12.348708",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7325",
  },
  {
    id: "57845",
    map_id: "1",
    address: "Via Cristoforo Colombo, snc, 84043 Agropoli (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3489669",
    lng: "14.9918681",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7318",
  },
  {
    id: "57844",
    map_id: "1",
    address: "Via Baccio da Montelupo, 32 p, 50142 Firenze (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7699095",
    lng: "11.204971",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7311",
  },
  {
    id: "57843",
    map_id: "1",
    address: "Largo di Porta di Pradella, 12, 46100 Mantova (MN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.157498",
    lng: "10.7822163",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7310",
  },
  {
    id: "57842",
    map_id: "1",
    address: "Via Marconi, 120, 40033 Casalecchio di Reno (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.474676",
    lng: "11.2748847",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7309",
  },
  {
    id: "57841",
    map_id: "1",
    address: "Via Roma, 107, 6083 Bastia Umbra (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.0645645",
    lng: "12.5619398",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7308",
  },
  {
    id: "57840",
    map_id: "1",
    address: "Via Falaise, 28, 3043 Cassino (FR)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.4907152",
    lng: "13.8391341",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7290",
  },
  {
    id: "57839",
    map_id: "1",
    address: "Viale Stazione, 12/a, 54100 Massa (MS)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.0327012",
    lng: "10.1376442",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7284",
  },
  {
    id: "57838",
    map_id: "1",
    address: "Vittorio Veneto, 28, 10034 Chivasso (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.188591",
    lng: "7.8936075",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7280",
  },
  {
    id: "57837",
    map_id: "1",
    address: "Via dei Fiori, 11, 74015 Martina Franca (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6997548",
    lng: "17.3347484",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7275",
  },
  {
    id: "57836",
    map_id: "1",
    address: "Via Roma, 9, 71 Pomezia (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.6711002",
    lng: "12.5012616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7260",
  },
  {
    id: "57835",
    map_id: "1",
    address: "Via Albertino Mussato, 2, 35031 Abano Terme (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.3623602",
    lng: "11.7901934",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7259",
  },
  {
    id: "57834",
    map_id: "1",
    address: "Via Quintino Sella, 4, 21052 Busto Arsizio (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6142581",
    lng: "8.8448876",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7257",
  },
  {
    id: "57833",
    map_id: "1",
    address: "Via dei Cavamonti, 10, 73020 Cutrofiano (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.1414005",
    lng: "18.2119167",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7246",
  },
  {
    id: "57832",
    map_id: "1",
    address: "Viale della Vittoria, 5, 33100 Udine (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.0694395",
    lng: "13.2359757",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7245",
  },
  {
    id: "57831",
    map_id: "1",
    address: "Via Fratelli Sprovieri, 45, 87100 Cosenza (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.3038739",
    lng: "16.2607186",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7244",
  },
  {
    id: "57830",
    map_id: "1",
    address: "Via Callipoli, 84/a, 95014 Giarre (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.7299276",
    lng: "15.1850428",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7242",
  },
  {
    id: "57829",
    map_id: "1",
    address: "Via Fucilari, 39, 84014 Nocera Inferiore (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.742453",
    lng: "14.6430252",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7239",
  },
  {
    id: "57828",
    map_id: "1",
    address: "Via Giovanni XXIII, 58, 80035 Nola (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9212734",
    lng: "14.5311858",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7233",
  },
  {
    id: "57827",
    map_id: "1",
    address: "Via San Domenico Savio, 5, 95041 Caltagirone (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.2257049",
    lng: "14.5227895",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7169",
  },
  {
    id: "57826",
    map_id: "1",
    address: "Via Santa Colomba, 52, 82100 Benevento (BN)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.1180783",
    lng: "14.7782764",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7168",
  },
  {
    id: "57825",
    map_id: "1",
    address: "Via Mezzacapo, 27, 84036 Sala Consilina (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3992431",
    lng: "15.5947254",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7167",
  },
  {
    id: "57824",
    map_id: "1",
    address: "Via Volta, 2, 87064 Corigliano-Rossano (CS)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.6014845",
    lng: "16.6424396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7166",
  },
  {
    id: "57823",
    map_id: "1",
    address: "Via Buccarelli, 53, 88100 Catanzaro (CZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.9148931",
    lng: "16.5877871",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7165",
  },
  {
    id: "57822",
    map_id: "1",
    address: "Viale Ofanto, 367, 71122 Foggia (FG)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.4526118",
    lng: "15.5626881",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7164",
  },
  {
    id: "57821",
    map_id: "1",
    address: "Via Enrico Fermi, 2, 76121 Barletta (BT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.31134489999999",
    lng: "16.2797598",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7163",
  },
  {
    id: "57820",
    map_id: "1",
    address: "Via A.Decurtis, 19, 80078 Pozzuoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8765554",
    lng: "14.096117",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7162",
  },
  {
    id: "57819",
    map_id: "1",
    address: "Via F. A. Cappone, 31, 83100 Avellino (AV)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.920612",
    lng: "14.7811287",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7161",
  },
  {
    id: "57818",
    map_id: "1",
    address: "Viale Stefano Candura, 33, 93100 Caltanissetta (CL)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.4783286",
    lng: "14.0529166",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7147",
  },
  {
    id: "57817",
    map_id: "1",
    address: "Piazza Marconi, 92100 Agrigento (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.3092216",
    lng: "13.5851227",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7146",
  },
  {
    id: "57816",
    map_id: "1",
    address: "Via della Tecnica, 4, 85100 Potenza (PZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6251567",
    lng: "15.7961457",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7145",
  },
  {
    id: "57815",
    map_id: "1",
    address: "Viale Italia, 1, 75100 Matera (MT)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6731517",
    lng: "16.5871817",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7144",
  },
  {
    id: "57814",
    map_id: "1",
    address: "Via F.lli Bellanca, snc, 92019 Sciacca (AG)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.509551",
    lng: "13.0645551",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7143",
  },
  {
    id: "57813",
    map_id: "1",
    address: "Contrada San Pietro Catania, 85044 Lauria (PZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.0521399",
    lng: "15.833488",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7142",
  },
  {
    id: "57812",
    map_id: "1",
    address: "Viale Teracati, 156, 96100 Siracusa (SR)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.08268940000001",
    lng: "15.2812448",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7140",
  },
  {
    id: "57811",
    map_id: "1",
    address:
      "Piazzetta A.Furlan, 3 accesso Viale Dante, 27, 33170 Pordenone (PN)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.9588721",
    lng: "12.6645201",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7134",
  },
  {
    id: "57810",
    map_id: "1",
    address: "Via Calciati, 11, 29122 Piacenza (PC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0437357",
    lng: "9.7035584",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7133",
  },
  {
    id: "57809",
    map_id: "1",
    address: "Via Sirolo, 20, 61122 Pesaro (PU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9049071",
    lng: "12.8903876",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7132",
  },
  {
    id: "57808",
    map_id: "1",
    address: "Via Trento, 45/b, 62100 Macerata (MC)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.3020959",
    lng: "13.4430037",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7131",
  },
  {
    id: "57807",
    map_id: "1",
    address: "Via Longhin, 9, 35100 Padova (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.40856460000001",
    lng: "11.9106585",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7130",
  },
  {
    id: "57806",
    map_id: "1",
    address: "Via G. Bonali, 44, 47122 Forli' (FC)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.2235289",
    lng: "12.0506651",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7129",
  },
  {
    id: "57805",
    map_id: "1",
    address: "Via Canna, 5, 28900 Verbania (VB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.9362097",
    lng: "8.5710843",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7128",
  },
  {
    id: "57804",
    map_id: "1",
    address: "Via Lago di Albano, 3/5, 19 Tivoli (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.9506942",
    lng: "12.7635261",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7127",
  },
  {
    id: "57803",
    map_id: "1",
    address: "Via Brignoni, 23/r, 17100 Savona (SV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.3107312",
    lng: "8.4783121",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7126",
  },
  {
    id: "57802",
    map_id: "1",
    address: "Via Filippo Turati, 22h, 5100 Terni (TR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.5540488",
    lng: "12.6448562",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7125",
  },
  {
    id: "57801",
    map_id: "1",
    address: "Largo Luigi Colarieti, 4, 2100 Rieti (RI)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.4163599",
    lng: "12.8671287",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7124",
  },
  {
    id: "57800",
    map_id: "1",
    address: "Viale Repubblica, 62, 57025 Piombino (LI)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.9279407",
    lng: "10.5257005",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7123",
  },
  {
    id: "57799",
    map_id: "1",
    address: "Via Borgazzi, 16, 20900 Monza (MB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5730019",
    lng: "9.26769",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7122",
  },
  {
    id: "57798",
    map_id: "1",
    address: "Via Tirso, 144, 9170 Oristano (OR)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.90882819999999",
    lng: "8.589179999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7121",
  },
  {
    id: "57797",
    map_id: "1",
    address: "Via della Pianta, 286, 19126 La Spezia (SP)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.116567",
    lng: "9.851166899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7120",
  },
  {
    id: "57796",
    map_id: "1",
    address: "Via Maroncelli, 9, 7026 Olbia (SS)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9399952",
    lng: "9.4990943",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7119",
  },
  {
    id: "57795",
    map_id: "1",
    address: "Angolo V.Spontone V.Argine sinistro V.Nobel, 18100 Imperia (IM)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8924732",
    lng: "8.0352413",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7118",
  },
  {
    id: "57794",
    map_id: "1",
    address: "Via Ciusa Romagna, 6, 8100 Nuoro (NU)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3208368",
    lng: "9.3239982",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7117",
  },
  {
    id: "57793",
    map_id: "1",
    address: "Viale Castruccio Castracani, 194, 55100 Lucca (LU)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8444593",
    lng: "10.5178333",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7116",
  },
  {
    id: "57792",
    map_id: "1",
    address: "Largo Garibaldi, 14, 41 Albano Laziale (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.7261203",
    lng: "12.6607153",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7115",
  },
  {
    id: "57791",
    map_id: "1",
    address: "Strada Porini, 5/i, 12050 Guarene (CN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7234246",
    lng: "8.0360777",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7112",
  },
  {
    id: "57790",
    map_id: "1",
    address: "Via Fiume 51/55, 57123 Livorno (LI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.5506579",
    lng: "10.3070466",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7111",
  },
  {
    id: "57789",
    map_id: "1",
    address: "Via Brescia, 54, 26100 Cremona (CR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.1402149",
    lng: "10.0326354",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7110",
  },
  {
    id: "57788",
    map_id: "1",
    address: "Corso Della Repubblica, 89, 4100 Latina (LT)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.4690783",
    lng: "12.903617",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7109",
  },
  {
    id: "57787",
    map_id: "1",
    address: "Via Piacenza, 149, 16043 Chiavari (GE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.3178873",
    lng: "9.3370064",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7108",
  },
  {
    id: "57786",
    map_id: "1",
    address: "Via R. Bonghi, 13/b, 58100 Grosseto (GR)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.7631394",
    lng: "11.1098125",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7107",
  },
  {
    id: "57785",
    map_id: "1",
    address: "Via Sofia De Filippi Mariani, 53 Civitavecchia (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.0898109",
    lng: "11.7974274",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7106",
  },
  {
    id: "57784",
    map_id: "1",
    address: "Cda S.Giovanni in Golfo, snc, 86100 Campobasso (CB)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.5835722",
    lng: "14.6757745",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7105",
  },
  {
    id: "57783",
    map_id: "1",
    address: "Via Garibaldi, 97, 52100 Arezzo (AR)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.4629148",
    lng: "11.8795708",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7104",
  },
  {
    id: "57782",
    map_id: "1",
    address: "Via Pansa, 47/i, 42124 Reggio Emilia (RE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.7050351",
    lng: "10.6328377",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7040",
  },
  {
    id: "57781",
    map_id: "1",
    address: "Viale Mentana, 109/a, 43121 Parma (PR)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.80658649999999",
    lng: "10.33427",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7039",
  },
  {
    id: "57780",
    map_id: "1",
    address: "Viale Sicilia, 1-9, 97100 Ragusa (RG)",
    description: "BOLLETTINI - PROMO130",
    lat: "36.9184276",
    lng: "14.7276814",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7038",
  },
  {
    id: "57779",
    map_id: "1",
    address: "Via Fusco, 4, 80058 Torre Annunziata (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7582358",
    lng: "14.4380362",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7037",
  },
  {
    id: "57778",
    map_id: "1",
    address: "Via Andrea Costa, 22, 28100 Novara (NO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4469831",
    lng: "8.6098073",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7036",
  },
  {
    id: "57777",
    map_id: "1",
    address: "Corso Carlo Brunet, 13, 12100 Cuneo (CN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.383658",
    lng: "7.544407799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7035",
  },
  {
    id: "57776",
    map_id: "1",
    address: "Via Leonardo Da Vinci, 13, 17031 Albenga (SV)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.0508102",
    lng: "8.2094436",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7034",
  },
  {
    id: "57775",
    map_id: "1",
    address: "Piazza Dante, 1, 27100 Pavia (PV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.19048979999999",
    lng: "9.150081499999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7028",
  },
  {
    id: "57774",
    map_id: "1",
    address: "Viale Vittorio Emanuele, 2, 24121 Bergamo (BG)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.7017951",
    lng: "9.6671266",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7027",
  },
  {
    id: "57773",
    map_id: "1",
    address: "Via Don Orione, 11, 15121 Alessandria (AL)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.9080368",
    lng: "8.627655899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7026",
  },
  {
    id: "57772",
    map_id: "1",
    address: "Via Cagliari, 118, 74121 Taranto (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.4615759",
    lng: "17.2636005",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7025",
  },
  {
    id: "57771",
    map_id: "1",
    address: "Corso Regina Margherita, 267, 10143 Torino (TO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0860961",
    lng: "7.654002800000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7024",
  },
  {
    id: "57770",
    map_id: "1",
    address: "Corso Piersanti Mattarella, 1, 91100 Trapani (TP)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.02085350000001",
    lng: "12.5345309",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7023",
  },
  {
    id: "57769",
    map_id: "1",
    address: "Via Silvestro Sanvito, 60, 21100 Varese (VA)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8235448",
    lng: "8.8146843",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7022",
  },
  {
    id: "57768",
    map_id: "1",
    address: "Via Torrione, 74, 84127 Salerno (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6727727",
    lng: "14.7773915",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7021",
  },
  {
    id: "57767",
    map_id: "1",
    address: "Via Orange, 5, 89125 Reggio Calabria (RC)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1074871",
    lng: "15.6468848",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7020",
  },
  {
    id: "57766",
    map_id: "1",
    address: "Via A. De Gasperi, 23/d, 80133 Napoli (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8416071",
    lng: "14.2551421",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7019",
  },
  {
    id: "57765",
    map_id: "1",
    address: "Via I Settembre 53/55, 98122 Messina (ME)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1884237",
    lng: "15.5577793",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7018",
  },
  {
    id: "57764",
    map_id: "1",
    address: "Via Amendola, 11, 23900 Lecco (LC)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8494186",
    lng: "9.394110099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7017",
  },
  {
    id: "57763",
    map_id: "1",
    address: "Via Potenza, 8, 73100 Lecce (LE)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3558343",
    lng: "18.2002671",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7016",
  },
  {
    id: "57762",
    map_id: "1",
    address: "Via delle Clarisse, 34, 89900 Vibo Valentia (VV)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.6727065",
    lng: "16.1018403",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7014",
  },
  {
    id: "57761",
    map_id: "1",
    address: "Via Monsignor Domenico Orlando, 23, 95126 Catania (CT)",
    description: "BOLLETTINI - PROMO130",
    lat: "37.5335266",
    lng: "15.1057405",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7013",
  },
  {
    id: "57760",
    map_id: "1",
    address: "Via Lamberti, 25, 81100 Caserta (CE)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.0584994",
    lng: "14.3423633",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7012",
  },
  {
    id: "57759",
    map_id: "1",
    address: "Viale Commenda, 28, 72100 Brindisi (BR)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6322584",
    lng: "17.937652",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7011",
  },
  {
    id: "57758",
    map_id: "1",
    address: "Via Giuseppe Capruzzi, 74, 70126 Bari (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.1171115",
    lng: "16.8742136",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7010",
  },
  {
    id: "57757",
    map_id: "1",
    address: "Via Brignole De Ferrari, 22, 16125 Genova (GE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4149617",
    lng: "8.928561799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7009",
  },
  {
    id: "57756",
    map_id: "1",
    address: "Via Innocenzo XI, 89, 22100 Como (CO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8046896",
    lng: "9.081119",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7007",
  },
  {
    id: "57755",
    map_id: "1",
    address: "Via Cassala, 13, 25126 Brescia (BS)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5365068",
    lng: "10.2036841",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7006",
  },
  {
    id: "57754",
    map_id: "1",
    address: "Via G. Saragat, 26, 1100 Viterbo (VT)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.4273434",
    lng: "12.1008351",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7003",
  },
  {
    id: "57753",
    map_id: "1",
    address: "Viale Bovio, 40, 64100 Teramo (TE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.6639012",
    lng: "13.6938505",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7001",
  },
  {
    id: "57752",
    map_id: "1",
    address: "Via Carlo Felice, 32, 7100 Sassari (SS)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.7155789",
    lng: "8.579307",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7000",
  },
  {
    id: "57751",
    map_id: "1",
    address: "Via G. Valentini, 7, 59100 Prato (PO)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.8738246",
    lng: "11.1021194",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6999",
  },
  {
    id: "57750",
    map_id: "1",
    address: "Via Prov. Le Pratese, 39, 51100 Pistoia (PT)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.9282518",
    lng: "10.9273585",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6998",
  },
  {
    id: "57749",
    map_id: "1",
    address: "Via C.Battisti, 59, 56125 Pisa (PI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7103893",
    lng: "10.3953331",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6996",
  },
  {
    id: "57748",
    map_id: "1",
    address: "Via Conte di Ruvo, 5, 65127 Pescara (PE)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.4613664",
    lng: "14.2159324",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6995",
  },
  {
    id: "57747",
    map_id: "1",
    address: "Via del Tabacchificio, 26, 6127 Perugia (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.1074493",
    lng: "12.3636596",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6994",
  },
  {
    id: "57746",
    map_id: "1",
    address: "Via A.Volta, 1, 67100 L'Aquila (AQ)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.3605317",
    lng: "13.4087815",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6992",
  },
  {
    id: "57745",
    map_id: "1",
    address: "Via Giacomo De Matthaeis, 4, 3100 Frosinone (FR)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.646482",
    lng: "13.3468616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6990",
  },
  {
    id: "57744",
    map_id: "1",
    address: "Via Palombaro, 13/a, 6034 Foligno (PG)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.9490281",
    lng: "12.7026052",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6989",
  },
  {
    id: "57743",
    map_id: "1",
    address: "Via Corridoni, 35/a, 50134 Firenze (FI)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.7919741",
    lng: "11.2424605",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6988",
  },
  {
    id: "57742",
    map_id: "1",
    address: "Piazza Deffenu, 1, 9125 Cagliari (CA)",
    description: "BOLLETTINI - PROMO130",
    lat: "39.2111904",
    lng: "9.1160643",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6987",
  },
  {
    id: "57741",
    map_id: "1",
    address: "Piazza San Nicolo', 4, 37121 Verona (VR)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.44007089999999",
    lng: "10.9961767",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6985",
  },
  {
    id: "57740",
    map_id: "1",
    address: "Via Savorgnana, 15, 33100 Udine (UD)",
    description: "BOLLETTINI - PROMO130",
    lat: "46.0614747",
    lng: "13.2363599",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6984",
  },
  {
    id: "57739",
    map_id: "1",
    address: "Via Della Quercia, 2, 31100 Treviso (TV)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.66977869999999",
    lng: "12.2185774",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6983",
  },
  {
    id: "57738",
    map_id: "1",
    address: "Via Sacro Cuore, 5, 45100 Rovigo (RO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.0679125",
    lng: "11.7858597",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6982",
  },
  {
    id: "57737",
    map_id: "1",
    address: "Via Rosaspina, 7, 47923 Rimini (RN)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.0524497",
    lng: "12.5660917",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6981",
  },
  {
    id: "57736",
    map_id: "1",
    address: "Piazza Cavour, 24, 34074 Monfalcone (GO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.8108178",
    lng: "13.5318715",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6980",
  },
  {
    id: "57735",
    map_id: "1",
    address: "Via Giusti, 13/17, 44121 Ferrara (FE)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.8153788",
    lng: "11.6087896",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6979",
  },
  {
    id: "57734",
    map_id: "1",
    address: "Viale Treviri, 192, 63100 Ascoli Piceno (AP)",
    description: "BOLLETTINI - PROMO130",
    lat: "42.8537618",
    lng: "13.5613615",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6976",
  },
  {
    id: "57733",
    map_id: "1",
    address: "Piazza Liber Paradisus, 16, 40129 Bologna (BO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.5099481",
    lng: "11.3400721",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6974",
  },
  {
    id: "57732",
    map_id: "1",
    address: "Corso Garibaldi, 4, 60121 Ancona (AN)",
    description: "BOLLETTINI - PROMO130",
    lat: "43.6185701",
    lng: "13.5100509",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6973",
  },
  {
    id: "57731",
    map_id: "1",
    address: "Via Gabriel Bella, 3, 30174 Venezia (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5076346",
    lng: "12.2272321",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6935",
  },
  {
    id: "57730",
    map_id: "1",
    address: "Via Marchese di Villabianca,121, 90143 Palermo (PA)",
    description: "BOLLETTINI - PROMO130",
    lat: "38.1396996",
    lng: "13.3497494",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6917",
  },
  {
    id: "57729",
    map_id: "1",
    address: "Via Broletto, 44/a, 20121 Milano (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4679953",
    lng: "9.1856888",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6807",
  },
  {
    id: "57728",
    map_id: "1",
    address: "Via Nizza, 146, 198 Roma (RM)",
    description: "BOLLETTINI - PROMO130",
    lat: "41.9137589",
    lng: "12.5034555",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6806",
  },
  {
    id: "57727",
    map_id: "1",
    address: "Via Milano, 405,97019 Vittoria (RG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "36.94536",
    lng: "14.5214037",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "46",
  },
  {
    id: "57726",
    map_id: "1",
    address: "Via Salemi 105,91026 Mazara del Vallo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6556105",
    lng: "12.5967362",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "64",
  },
  {
    id: "57725",
    map_id: "1",
    address: "Via Galileo Galilei  13,92023 Campobello di Licata (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "37.253347",
    lng: "13.919085",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "66",
  },
  {
    id: "57724",
    map_id: "1",
    address: "Via Muros 47,07045 Ossi (SS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.6763324",
    lng: "8.5969003",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "70",
  },
  {
    id: "57723",
    map_id: "1",
    address: "Via Roma 19/21,80055 Portici (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8184286",
    lng: "14.3335458",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "101",
  },
  {
    id: "57722",
    map_id: "1",
    address: "Via Corridoni 26,56025 Pontedera (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6657441",
    lng: "10.6383434",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "124",
  },
  {
    id: "57721",
    map_id: "1",
    address: "Via Caprera 12/14,07039 Valledoria (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9290875",
    lng: "8.825702399999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "132",
  },
  {
    id: "57720",
    map_id: "1",
    address: "Via Orsomarso 10,87020 Santa Maria del Cedro (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.7687465",
    lng: "15.8271029",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "165",
  },
  {
    id: "57719",
    map_id: "1",
    address: "Contrada Sivirga Compl. Albachiara pal.,98145 Bordonaro (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1796523",
    lng: "15.5108606",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "167",
  },
  {
    id: "57718",
    map_id: "1",
    address: "Via Garibaldi 81,80028 Grumo Nevano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.935205",
    lng: "14.260881",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "170",
  },
  {
    id: "57717",
    map_id: "1",
    address: "C.so Umberto I 660,81033 Casal di Principe (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.0080472",
    lng: "14.1140041",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "196",
  },
  {
    id: "57716",
    map_id: "1",
    address: "Via Antica Arischia II 8,67100 L'Aquila (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.3665863",
    lng: "13.365945",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "225",
  },
  {
    id: "57715",
    map_id: "1",
    address: "Via Selva 9/11,80030 Castello di Cisterna (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9152108",
    lng: "14.4086145",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "268",
  },
  {
    id: "57714",
    map_id: "1",
    address: "Via S. Leonardo 34,91018 Salemi (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.8261698",
    lng: "12.8055052",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "271",
  },
  {
    id: "57713",
    map_id: "1",
    address: "Via Circonvallazione 54,80035 Nola (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9199766",
    lng: "14.5345586",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "274",
  },
  {
    id: "57712",
    map_id: "1",
    address: "Via Tenente Militello, 54,90020 Montemaggiore Belsito (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.8473294",
    lng: "13.7619144",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "304",
  },
  {
    id: "57711",
    map_id: "1",
    address: "Via S. Francesco 12,07044 Ittiri (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5943596",
    lng: "8.5681769",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "307",
  },
  {
    id: "57710",
    map_id: "1",
    address: "Via XXVIII Aprile 16/17,30027 San Dona di Piave (VE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6281382",
    lng: "12.5708378",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "324",
  },
  {
    id: "57709",
    map_id: "1",
    address: "C.so Vittorio Emanuele 138,92012 Cianciana (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5214174",
    lng: "13.4315215",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "325",
  },
  {
    id: "57708",
    map_id: "1",
    address: "Via Roma 101,98051 Barcellona Pozzo di Gotto (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1468228",
    lng: "15.2144221",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "328",
  },
  {
    id: "57707",
    map_id: "1",
    address: "P.za delle Istituzioni 35,31100 Treviso (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6676867",
    lng: "12.2288685",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "338",
  },
  {
    id: "57706",
    map_id: "1",
    address: "Via Piemonte, 11,08048 TortolÃ¬ (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9259673",
    lng: "9.665291700000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "372",
  },
  {
    id: "57705",
    map_id: "1",
    address: "P.za Municipio 23,98050 Terme Vigliatore (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.136701",
    lng: "15.1623882",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "377",
  },
  {
    id: "57704",
    map_id: "1",
    address: "C.so Umberto I 172,96010 Sortino (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.1559757",
    lng: "15.0278545",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "394",
  },
  {
    id: "57703",
    map_id: "1",
    address: "Via Vittoria 30,98077 S. Stefano di Camastra (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.0140462",
    lng: "14.350088",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "468",
  },
  {
    id: "57702",
    map_id: "1",
    address: "Via G. Marconi 63,92016 Ribera (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.502259",
    lng: "13.270018",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "476",
  },
  {
    id: "57701",
    map_id: "1",
    address: "Via G. Marconi 15/f,09048 Sinnai (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.30155999999999",
    lng: "9.195048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "477",
  },
  {
    id: "57700",
    map_id: "1",
    address: "VIA LUIGI DE LUCA 86,98051 Barcellona Pozzo di Gotto (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1508735",
    lng: "15.2190674",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "478",
  },
  {
    id: "57699",
    map_id: "1",
    address: "Via Angelo Papio, 33,84100 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6777823",
    lng: "14.7670005",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "572",
  },
  {
    id: "57698",
    map_id: "1",
    address: "Via F.lli Bandiera 32,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4686392",
    lng: "12.9057503",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "575",
  },
  {
    id: "57697",
    map_id: "1",
    address: "V.le Rimembranza 44,10064 Pinerolo (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.891105",
    lng: "7.333442000000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "578",
  },
  {
    id: "57696",
    map_id: "1",
    address: "Via Mazzini 16,21013 Gallarate (VA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6605636",
    lng: "8.789849000000002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "639",
  },
  {
    id: "57695",
    map_id: "1",
    address: "Via A. Diaz snc,81052 Pignataro Maggiore (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1922055",
    lng: "14.1730857",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "642",
  },
  {
    id: "57694",
    map_id: "1",
    address: "Via Palmerino 54/e,90129 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0951773",
    lng: "13.3279916",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "682",
  },
  {
    id: "57693",
    map_id: "1",
    address: "Via Starza 80,81024 Maddaloni (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0343676",
    lng: "14.3899217",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "697",
  },
  {
    id: "57692",
    map_id: "1",
    address: "Via Fontane 2,71022 Ascoli Satriano (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.208017",
    lng: "15.561108",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "727",
  },
  {
    id: "57691",
    map_id: "1",
    address: "Corso Italia 289,80010 Villaricca (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9185572",
    lng: "14.1749265",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "732",
  },
  {
    id: "57690",
    map_id: "1",
    address: "Via Quarto 55,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4595455",
    lng: "12.8994028",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "734",
  },
  {
    id: "57689",
    map_id: "1",
    address: "Via Oasi Sacro Cuore 111/a,80014 Giugliano in Campania (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9376664",
    lng: "14.208059",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "745",
  },
  {
    id: "57688",
    map_id: "1",
    address: "Via Umberto I 24/26,73040 Neviano (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1069792",
    lng: "18.1162228",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "753",
  },
  {
    id: "57687",
    map_id: "1",
    address: "Piazza Lavinia 15,00042 Anzio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.49872",
    lng: "12.586372",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "795",
  },
  {
    id: "57686",
    map_id: "1",
    address: "Via Aldo Moro 79,73058 Tuglie (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.071078",
    lng: "18.1003459",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "799",
  },
  {
    id: "57685",
    map_id: "1",
    address: "Viale Leonardo Gallucci 10,88900 Crotone (KR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.0741488",
    lng: "17.127653",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "810",
  },
  {
    id: "57684",
    map_id: "1",
    address: "Via M. da Caravaggio 78,80126 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9071221",
    lng: "14.2747239",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "825",
  },
  {
    id: "57683",
    map_id: "1",
    address: "Viale Manzoni 92,81030 Lusciano (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.96522",
    lng: "14.191145",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "826",
  },
  {
    id: "57682",
    map_id: "1",
    address: "Corso Garibaldi 59,80033 Cicciano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9634683",
    lng: "14.5378392",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "834",
  },
  {
    id: "57681",
    map_id: "1",
    address: "Via Bagno 35,85025 Melfi (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9949804",
    lng: "15.652227",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "849",
  },
  {
    id: "57680",
    map_id: "1",
    address: "Via Nomentana 226,00013 Mentana (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0233595",
    lng: "12.6483213",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "853",
  },
  {
    id: "57679",
    map_id: "1",
    address: "Via Romagnoli 13,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.47039609999999",
    lng: "12.894318",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "860",
  },
  {
    id: "57678",
    map_id: "1",
    address: "Via Rapisardi 8,96019 Rosolini (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.8211564",
    lng: "14.9482715",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "876",
  },
  {
    id: "57677",
    map_id: "1",
    address: "Via Lungomare delle Sirene 390,00040 Pomezia (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6407083",
    lng: "12.4387026",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "884",
  },
  {
    id: "57676",
    map_id: "1",
    address: "Via Vittorio Veneto 39,80133 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.901975",
    lng: "14.332644",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "891",
  },
  {
    id: "57675",
    map_id: "1",
    address: "Via A. Cardarelli 9 c/o Cral Antonio Car,80131 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8631622",
    lng: "14.2251525",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "892",
  },
  {
    id: "57674",
    map_id: "1",
    address: "Corso Alba 24,14100 Asti (AT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.89148",
    lng: "8.19563",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "913",
  },
  {
    id: "57673",
    map_id: "1",
    address: "Via Damiano Ciancilla 3,07100 Sassari (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.751897",
    lng: "8.509138499999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "916",
  },
  {
    id: "57672",
    map_id: "1",
    address: "I Traversa Strada S. Lucia 2,85059 Viggiano (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3365623",
    lng: "15.9019502",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "924",
  },
  {
    id: "57671",
    map_id: "1",
    address: "Via Genova 6,07045 Ossi (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6741749",
    lng: "8.5892962",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "935",
  },
  {
    id: "57670",
    map_id: "1",
    address: "Via Amendola 15,82016 Montesarchio (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.06244359999999",
    lng: "14.6400537",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "947",
  },
  {
    id: "57669",
    map_id: "1",
    address: "Via M. Torcia 12/e,88100 Catanzaro (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.912685",
    lng: "16.5684129",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "949",
  },
  {
    id: "57668",
    map_id: "1",
    address: "Via Liberta 258/b,80055 Portici (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8183449",
    lng: "14.3474003",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "960",
  },
  {
    id: "57667",
    map_id: "1",
    address: "Via Regina Margherita 10,97018 Scicli (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.7635949",
    lng: "14.6380253",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "962",
  },
  {
    id: "57666",
    map_id: "1",
    address: "Viale Cimitero 81,71042 Cerignola (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2574421",
    lng: "15.8988073",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "998",
  },
  {
    id: "57665",
    map_id: "1",
    address: "Via Monticello 4,84081 Baronissi (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.743372",
    lng: "14.771606",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1001",
  },
  {
    id: "57664",
    map_id: "1",
    address: "Via Bernini 32/c,00053 Civitavecchia (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0923014",
    lng: "11.798784",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1037",
  },
  {
    id: "57663",
    map_id: "1",
    address: "Via Pergolesi 81,80078 Pozzuoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.827673",
    lng: "14.1207865",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1039",
  },
  {
    id: "57662",
    map_id: "1",
    address: "Viale Europa 1,09041 Dolianova (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3771486",
    lng: "9.1770912",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1046",
  },
  {
    id: "57661",
    map_id: "1",
    address: "Via Cannizzo 14,96100 Siracusa (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0947657",
    lng: "15.283632",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1059",
  },
  {
    id: "57660",
    map_id: "1",
    address: "Via Annunziata 9,85020 Atella (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8791181",
    lng: "15.653313",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1062",
  },
  {
    id: "57659",
    map_id: "1",
    address: "Via San Martino 69/69ar,16131 Genova (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.4055689",
    lng: "8.9749191",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1076",
  },
  {
    id: "57658",
    map_id: "1",
    address: "via roma 5,80040 San Sebastiano al Vesuvio (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.843381",
    lng: "14.369878",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1098",
  },
  {
    id: "57657",
    map_id: "1",
    address: "Via Dante, 87,70032 Bitonto (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1134448",
    lng: "16.6922761",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1164",
  },
  {
    id: "57656",
    map_id: "1",
    address: "Via Aldo Moro 31,85050 Marsicovetere (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3559452",
    lng: "15.8250533",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1206",
  },
  {
    id: "57655",
    map_id: "1",
    address: "Via Crivelli 72,38127 Trento (TN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "46.1089377",
    lng: "11.1104482",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1291",
  },
  {
    id: "57654",
    map_id: "1",
    address: "Via Esperia 28,89024 Polistena (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.4055398",
    lng: "16.0721248",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1293",
  },
  {
    id: "57653",
    map_id: "1",
    address: "Piazza Bernini 9,10100 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0754955",
    lng: "7.655691699999998",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1300",
  },
  {
    id: "57652",
    map_id: "1",
    address: "Via Salette, 32,80057 Sant'Antonio Abate (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.709211",
    lng: "14.522352",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1303",
  },
  {
    id: "57651",
    map_id: "1",
    address: "Via Martiri delle Ardeatine 19,96010 Solarino (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.1009246",
    lng: "15.1211091",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1304",
  },
  {
    id: "57650",
    map_id: "1",
    address: "Via Pietro Nenni 76,94010 Assoro (EN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.6234861",
    lng: "14.4223271",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1392",
  },
  {
    id: "57649",
    map_id: "1",
    address: "VIA FERROVIA 42,84081 Baronissi (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7459006",
    lng: "14.7719573",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1401",
  },
  {
    id: "57648",
    map_id: "1",
    address: "Via Niccolo Tommaseo 17,95041 Caltagirone (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.2257627",
    lng: "14.5243563",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1410",
  },
  {
    id: "57647",
    map_id: "1",
    address: "Via Aurelio Saffi 19,56025 Pontedera (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.664328",
    lng: "10.634563",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1436",
  },
  {
    id: "57646",
    map_id: "1",
    address: "Via Berlinguer snc,89063 Melito di Porto Salvo (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.9193558",
    lng: "15.7762814",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1500",
  },
  {
    id: "57645",
    map_id: "1",
    address: "Via Sant Antonio Dell Orto 34,87100 Cosenza (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.2969349",
    lng: "16.2611484",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1519",
  },
  {
    id: "57644",
    map_id: "1",
    address: "Via Brigata Regina 64,70123 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.12579789999999",
    lng: "16.8515012",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1533",
  },
  {
    id: "57643",
    map_id: "1",
    address: "Via Roma 31,92010 Bivona (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6178166",
    lng: "13.4403284",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1543",
  },
  {
    id: "57642",
    map_id: "1",
    address: "Via G. Garibaldi 66,90021 Alia (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.7820261",
    lng: "13.7136622",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1548",
  },
  {
    id: "57641",
    map_id: "1",
    address: "Via Filippo Turati 91,87028 Praia a Mare (CS)",
    description: "AMAZON HUB SERVICE - BOLLETTINI - PROMO130",
    lat: "39.893237",
    lng: "15.7834202",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1550",
  },
  {
    id: "57640",
    map_id: "1",
    address: "Via Raffaello 25,81031 Aversa (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9679967",
    lng: "14.2064859",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1555",
  },
  {
    id: "57639",
    map_id: "1",
    address: "Piazza Garibaldi 51,80047 San Giuseppe Vesuviano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8361868",
    lng: "14.5044381",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1557",
  },
  {
    id: "57638",
    map_id: "1",
    address: "Via Nazionale San Biagio 228,98050 Terme Vigliatore (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1283727",
    lng: "15.142904",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1564",
  },
  {
    id: "57637",
    map_id: "1",
    address: "Piazza Della Posta 12,95047 PaternÃ² (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.5675019",
    lng: "14.8977093",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1591",
  },
  {
    id: "57636",
    map_id: "1",
    address: "Via Della Repubblica 6,80035 Nola (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9239588",
    lng: "14.541847",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1610",
  },
  {
    id: "57635",
    map_id: "1",
    address: "Via Monte Bianco 15,00012 Guidonia Montecelio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.9816264",
    lng: "12.6179509",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1655",
  },
  {
    id: "57634",
    map_id: "1",
    address: "VIA GORIZIA 27,64021 Giulianova (TE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.753907",
    lng: "13.9647889",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1687",
  },
  {
    id: "57633",
    map_id: "1",
    address: "PIAZZA MUNICIPIO 1,86073 Colli a Volturno (IS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5990131",
    lng: "14.1037854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1717",
  },
  {
    id: "57632",
    map_id: "1",
    address: "VIA DON AGOSTINO COZZOLINO 51/53,80147 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8551144",
    lng: "14.3313898",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1719",
  },
  {
    id: "57631",
    map_id: "1",
    address: "VIA GENERALI LIPPI 22,71017 Torremaggiore (FG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.6865963",
    lng: "15.299952",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1732",
  },
  {
    id: "57630",
    map_id: "1",
    address: "CORSO MICHELANGELO 197,80029 Sant'Antimo (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9521636",
    lng: "14.2338532",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1741",
  },
  {
    id: "57629",
    map_id: "1",
    address: "CORSO UMBERTO I 182,66043 Casoli (CH)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.112661",
    lng: "14.292192",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1763",
  },
  {
    id: "57628",
    map_id: "1",
    address: "VIA LARGO DELLA CITTADINANZA 6,80144 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8838132",
    lng: "14.2802961",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1784",
  },
  {
    id: "57627",
    map_id: "1",
    address: "VIA ROMA 51,90010 Cerda (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.9077426",
    lng: "13.8123432",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1816",
  },
  {
    id: "57626",
    map_id: "1",
    address: "VIA BOX 8 M.TO VIGNA MURATA VIA A.MELDOL,00143 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8262467",
    lng: "12.4987483",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1855",
  },
  {
    id: "57625",
    map_id: "1",
    address: "VIA NUOVA SARNO 111,80036 Palma Campania (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8595043",
    lng: "14.5533965",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1856",
  },
  {
    id: "57624",
    map_id: "1",
    address: "PIAZZA VITTORIO EMANUELE 24,81033 Casal di Principe (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0066265",
    lng: "14.1227379",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1864",
  },
  {
    id: "57623",
    map_id: "1",
    address: "VIA J.F. KENNEDY 53/55,95045 Misterbianco (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.520432",
    lng: "15.0036258",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1884",
  },
  {
    id: "57622",
    map_id: "1",
    address: "VIA GIOVANNI XXIII 36,74022 Fragagnano (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4324274",
    lng: "17.4774203",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1886",
  },
  {
    id: "57621",
    map_id: "1",
    address: "VIA G. ZANARDELLI 113,80022 Arzano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.90907139999999",
    lng: "14.2730414",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1928",
  },
  {
    id: "57620",
    map_id: "1",
    address: "VIA NAZIONALE 5,76017 San Ferdinando di Puglia (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.3028736",
    lng: "16.0676281",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1973",
  },
  {
    id: "57619",
    map_id: "1",
    address: "VIA SAN FILIPPO BIANCHI 35,98122 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.190315",
    lng: "15.5536428",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "1993",
  },
  {
    id: "57618",
    map_id: "1",
    address: "VIA UMBERTO I 127,95043 Militello in Val di Catania (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.2750427",
    lng: "14.793324",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2017",
  },
  {
    id: "57617",
    map_id: "1",
    address: "VIA GUIDO ROSSA SNC,65017 Penne (PE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.4519404",
    lng: "13.9349487",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2028",
  },
  {
    id: "57616",
    map_id: "1",
    address: "VIALE SAN FRANCESCO SNC,65017 Penne (PE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.45590199999999",
    lng: "13.931853",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2029",
  },
  {
    id: "57615",
    map_id: "1",
    address: "VIA PILLA 72,56121 Pisa (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7015625",
    lng: "10.4070485",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2107",
  },
  {
    id: "57614",
    map_id: "1",
    address: "PIAZZA RODI 2,66054 Vasto (CH)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1023056",
    lng: "14.7187911",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2114",
  },
  {
    id: "57613",
    map_id: "1",
    address: "PIAZZA CAVOUR 4/A,50050 Montaione (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.5529271",
    lng: "10.9139849",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2134",
  },
  {
    id: "57612",
    map_id: "1",
    address: "VIA MARTIRI UNGHERESI 19/21,84129 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6652359",
    lng: "14.794382",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2191",
  },
  {
    id: "57611",
    map_id: "1",
    address: "VIA STAZIONE FRAZIONE PIEVE SAN LORENZO,55034 Minucciano (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.1941222",
    lng: "10.193539",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2200",
  },
  {
    id: "57610",
    map_id: "1",
    address: "Via Napoli 108,80147 Ponticelli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.8567004",
    lng: "14.3277003",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2218",
  },
  {
    id: "57609",
    map_id: "1",
    address: "VIA TRENTO 54,74028 Sava (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.402088",
    lng: "17.562333",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2236",
  },
  {
    id: "57608",
    map_id: "1",
    address: "VIA VITTORIO VENETO 16,21016 Luino (VA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "46.0008144",
    lng: "8.7408356",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2265",
  },
  {
    id: "57607",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI 10/B,80022 Arzano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9101003",
    lng: "14.2609925",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2269",
  },
  {
    id: "57606",
    map_id: "1",
    address: "VIA MARCHESE DI VILLABIANCA 82/B,90143 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1366507",
    lng: "13.3505164",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2292",
  },
  {
    id: "57605",
    map_id: "1",
    address: "VIA NAZIONALE 53,80059 Torre del Greco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.7821086",
    lng: "14.3798325",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2325",
  },
  {
    id: "57604",
    map_id: "1",
    address: "VIA ROMA 53,03040 Sant'Andrea del Garigliano (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.367605",
    lng: "13.841198",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2354",
  },
  {
    id: "57603",
    map_id: "1",
    address: "VIA PIETRO NENNI 69,92026 Favara (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3215331",
    lng: "13.6675494",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2499",
  },
  {
    id: "57602",
    map_id: "1",
    address: "VIA NAZIONALE ADRIATICA NORD 157,65124 Pescara (PE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.4851743",
    lng: "14.1875979",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2500",
  },
  {
    id: "57601",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE II 95/97,80011 Acerra (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9473207",
    lng: "14.3778047",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2635",
  },
  {
    id: "57600",
    map_id: "1",
    address: "VIALE GUIDO BACCELLI 105,00053 Civitavecchia (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.087832",
    lng: "11.804509",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2636",
  },
  {
    id: "57599",
    map_id: "1",
    address: "VIA CARMELO MAESTRINI 372/374,00128 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7962699",
    lng: "12.4443244",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2646",
  },
  {
    id: "57598",
    map_id: "1",
    address: "VIA CAPPUCCINI 79/81,95031 Adrano (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6613616",
    lng: "14.8368659",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2657",
  },
  {
    id: "57597",
    map_id: "1",
    address: "VIA DE LITIO 18,64025 Pineto (TE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.6137667",
    lng: "14.061774",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2696",
  },
  {
    id: "57596",
    map_id: "1",
    address: "PIAZZA CAMPIDOGLIO 46,10036 Settimo Torinese (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.136493",
    lng: "7.766205999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2709",
  },
  {
    id: "57595",
    map_id: "1",
    address: "VIA SANDRO PERTINI 104,56012 Calcinaia (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.693405",
    lng: "10.62218",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2768",
  },
  {
    id: "57594",
    map_id: "1",
    address: "VIA DI MEZZO 355,55054 Piano di Conca (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.893337",
    lng: "10.294824",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2799",
  },
  {
    id: "57593",
    map_id: "1",
    address: "VIA DELLA BUFALOTTA 831,00139 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9680356",
    lng: "12.5497708",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2804",
  },
  {
    id: "57592",
    map_id: "1",
    address: "VIA TOMMASO CAMPANELLA 68,88100 Catanzaro (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9135774",
    lng: "16.570607",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2841",
  },
  {
    id: "57591",
    map_id: "1",
    address: "VIA COLAJANNI 19,92026 Favara (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.31061820000001",
    lng: "13.6579455",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2908",
  },
  {
    id: "57590",
    map_id: "1",
    address: "VIA ROMA 25,81058 Vairano Patenora (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.3327951",
    lng: "14.1315288",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2949",
  },
  {
    id: "57589",
    map_id: "1",
    address: "Via Don Luigi Sturzo, 120,95014 Giarre (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.71703",
    lng: "15.17863",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "2952",
  },
  {
    id: "57588",
    map_id: "1",
    address: "Corso Bernacchi, 78/A,21049 Tradate (VA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.7121722",
    lng: "8.9062883",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3049",
  },
  {
    id: "57587",
    map_id: "1",
    address: "LOCALITA' BRUNCU TEULA SNC,09010 San Giovanni Suergiu (CI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.0914955",
    lng: "8.53831",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3068",
  },
  {
    id: "57586",
    map_id: "1",
    address: "VIA DON ANDREA MASTALLI 6,21013 Gallarate (VA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.65158479999999",
    lng: "8.7993538",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3095",
  },
  {
    id: "57585",
    map_id: "1",
    address: "VIA SAN CARLO 31,20023 Cerro Maggiore (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5940223",
    lng: "8.9498801",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3107",
  },
  {
    id: "57584",
    map_id: "1",
    address: "VIA CAPRIO MADDALONI 2/4,81100 Caserta (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.06682869999999",
    lng: "14.3676354",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3140",
  },
  {
    id: "57583",
    map_id: "1",
    address: "VIA NICCOLINI 2,56033 Capannoli (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.5890025",
    lng: "10.6724287",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3153",
  },
  {
    id: "57582",
    map_id: "1",
    address: "VIA G.M.BOSCO ANGOLO VIA SETTEMBRINI SNC,81100 Caserta (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.08031889999999",
    lng: "14.3368715",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3174",
  },
  {
    id: "57581",
    map_id: "1",
    address: "VIALE NARDINI 1,01012 Capranica (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "42.257025",
    lng: "12.176517",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3185",
  },
  {
    id: "57580",
    map_id: "1",
    address: "PIAZZA DE GASPERI SNC,20010 Arluno (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5057322",
    lng: "8.947641599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3204",
  },
  {
    id: "57579",
    map_id: "1",
    address: "VIA GIUSEPPE PARINI 15,20094 Corsico (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4298006",
    lng: "9.1121252",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3211",
  },
  {
    id: "57578",
    map_id: "1",
    address: "VIA ALESSANDRO VOLTA 20,20900 Monza (MB)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.5888679",
    lng: "9.2691539",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3224",
  },
  {
    id: "57577",
    map_id: "1",
    address: "VIA NAZARIO SAURO 40,20851 Lissone (MB)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.618815",
    lng: "9.239944999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3225",
  },
  {
    id: "57576",
    map_id: "1",
    address: "VIA XX SETTEMBRE 19,71042 Cerignola (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2632295",
    lng: "15.9055295",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3242",
  },
  {
    id: "57575",
    map_id: "1",
    address: "VIA G.PETRONI 106,70124 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1000111",
    lng: "16.8675513",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3252",
  },
  {
    id: "57574",
    map_id: "1",
    address: "VIA ROMA 99/101,01036 Nepi (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.2404051",
    lng: "12.336657",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3276",
  },
  {
    id: "57573",
    map_id: "1",
    address: "VIA BADINO 171,04019 Terracina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2888507",
    lng: "13.2333904",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3325",
  },
  {
    id: "57572",
    map_id: "1",
    address: "VIA SPANNA 1/6,10095 Grugliasco (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.06426829999999",
    lng: "7.577934599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3347",
  },
  {
    id: "57571",
    map_id: "1",
    address: "CORSO FRANCIA 18,74028 Sava (TA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.3982637",
    lng: "17.5572628",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3356",
  },
  {
    id: "57570",
    map_id: "1",
    address: "VIA ROMA 216,92020 Racalmuto (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.40437120000001",
    lng: "13.7340403",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3389",
  },
  {
    id: "57569",
    map_id: "1",
    address: "VIA PRINCIPE UMBERTO 58,86096 Macchiagodena (IS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5600991",
    lng: "14.4096697",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3392",
  },
  {
    id: "57568",
    map_id: "1",
    address: "VIA VANONI 14,92024 CANICATTI' (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3503154",
    lng: "13.8417762",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3411",
  },
  {
    id: "57567",
    map_id: "1",
    address: "VIA RICCARDO MISASI 1/A,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2956081",
    lng: "16.2500744",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3458",
  },
  {
    id: "57566",
    map_id: "1",
    address: "CORSO CAVOUR 92,13039 Trino (VC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.1938523",
    lng: "8.2992502",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3465",
  },
  {
    id: "57565",
    map_id: "1",
    address: "VIA TORRE 38/40,84016 PAGANI (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7408019",
    lng: "14.6144566",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3471",
  },
  {
    id: "57564",
    map_id: "1",
    address: "VIA FRANCESCO D'ASSISI 12,00062 BRACCIANO (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1033669",
    lng: "12.1741765",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3486",
  },
  {
    id: "57563",
    map_id: "1",
    address: "VIA FABIANI 1/3,50053 Empoli (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.71775100000001",
    lng: "10.947974",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3530",
  },
  {
    id: "57562",
    map_id: "1",
    address: "VIA ATELLANA 137,81031 Aversa (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.971127",
    lng: "14.2216226",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3578",
  },
  {
    id: "57561",
    map_id: "1",
    address: "VIALE I MAGGIO 5,87029 Scalea (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.8079551",
    lng: "15.7981932",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3579",
  },
  {
    id: "57560",
    map_id: "1",
    address: "P.ZZA S.AGNESE 11,80038 Pomigliano d'Arco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.912172",
    lng: "14.395198",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3580",
  },
  {
    id: "57559",
    map_id: "1",
    address: "VIA CASAMASSIMA 79/E,70010 Turi (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9190658",
    lng: "17.0151677",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3629",
  },
  {
    id: "57558",
    map_id: "1",
    address: "VIA GALILEO FERRARIS 43,80142 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8497508",
    lng: "14.2803231",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3641",
  },
  {
    id: "57557",
    map_id: "1",
    address: "VIA INGHILTERRA 29,09045 Quartu Sant'Elena (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2412918",
    lng: "9.1986666",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3642",
  },
  {
    id: "57556",
    map_id: "1",
    address: "VIA S.MARIA LA SCALA 415,80047 San Giuseppe Vesuviano (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.82636",
    lng: "14.4886679",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3643",
  },
  {
    id: "57555",
    map_id: "1",
    address: "VIA L.CADORNA 49,70024 Gravina in Puglia (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.82047499999999",
    lng: "16.42106",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3652",
  },
  {
    id: "57554",
    map_id: "1",
    address: "Viale della liberta' 148,74015 Martina Franca (TA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.701577",
    lng: "17.334125",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3705",
  },
  {
    id: "57553",
    map_id: "1",
    address: "VIALE VAL PADANA 45,00141 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.941972",
    lng: "12.5239197",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3729",
  },
  {
    id: "57552",
    map_id: "1",
    address: "PIAZZA XXV APRILE 3,98067 Raccuja (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0552466",
    lng: "14.908574",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3740",
  },
  {
    id: "57551",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 17,10023 Chieri (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.01464",
    lng: "7.821160000000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3741",
  },
  {
    id: "57550",
    map_id: "1",
    address: "CORSO SICILIA 12,95014 Giarre (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.7259086",
    lng: "15.1879793",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3778",
  },
  {
    id: "57549",
    map_id: "1",
    address: "VIA GIACOMO MATTEOTTI 167,04015 Priverno (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.4709324",
    lng: "13.1809649",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3814",
  },
  {
    id: "57548",
    map_id: "1",
    address: "VIA CESARONI 33,01015 Sutri (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.24906",
    lng: "12.21554",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3819",
  },
  {
    id: "57547",
    map_id: "1",
    address: "VIA ROMA 5,67030 Barrea (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7566278",
    lng: "13.9927629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3823",
  },
  {
    id: "57546",
    map_id: "1",
    address: "VIA G.MELI 87,90022 Caltavuturo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.8192527",
    lng: "13.891988",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3884",
  },
  {
    id: "57545",
    map_id: "1",
    address: "VIA DON PRIMO MAZZOLARI SNC,64100 Teramo (TE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.6740593",
    lng: "13.7367907",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3886",
  },
  {
    id: "57544",
    map_id: "1",
    address: "VIA VINCENZO SCALISE SNC,88054 Sersale (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.0101137",
    lng: "16.7286833",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3898",
  },
  {
    id: "57543",
    map_id: "1",
    address: "VIA DEL RIVO 309,05100 Terni (TR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.5870791",
    lng: "12.6089171",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3913",
  },
  {
    id: "57542",
    map_id: "1",
    address: "PIAZZA ALCIDE DE GASPERI SNC,85021 Avigliano (PZ)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.7301163",
    lng: "15.7194619",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3915",
  },
  {
    id: "57541",
    map_id: "1",
    address: "VIA MATTEOTTI 18,20098 San Giuliano Milanese (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.39431099999999",
    lng: "9.2853923",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3922",
  },
  {
    id: "57540",
    map_id: "1",
    address: "VIALE VERDI 19,74016 Massafra (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5939554",
    lng: "17.1124373",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3939",
  },
  {
    id: "57539",
    map_id: "1",
    address: "VIA CESARE BATTISTI 32,92020 San Biagio Platani (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5102516",
    lng: "13.5261365",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "3975",
  },
  {
    id: "57538",
    map_id: "1",
    address: "VIA ROMA - LOCALITA' MARITTIMA 11,73030 Diso (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9976962",
    lng: "18.3966674",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4035",
  },
  {
    id: "57537",
    map_id: "1",
    address: "VIA DUCEZIO ANG.VIA PACI 13,93012 Gela (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0708027",
    lng: "14.2392947",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4043",
  },
  {
    id: "57536",
    map_id: "1",
    address: "VIA NICOLA SALA 33,82100 Benevento (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.12875",
    lng: "14.7920058",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4044",
  },
  {
    id: "57535",
    map_id: "1",
    address: "VIA DEI LONGOBARDI 19,82100 Benevento (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.139442",
    lng: "14.779306",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4050",
  },
  {
    id: "57534",
    map_id: "1",
    address: "VIA MONTESSORI, 2,08022 Dorgali (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2926541",
    lng: "9.58778",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4071",
  },
  {
    id: "57533",
    map_id: "1",
    address: "VIA RAFFAELLO SANZIO 177/A,73018 Squinzano (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4291293",
    lng: "18.0339262",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4080",
  },
  {
    id: "57532",
    map_id: "1",
    address: "Via Mannelli, 47/rosso,50132 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7806477",
    lng: "11.2730773",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4081",
  },
  {
    id: "57531",
    map_id: "1",
    address: "CORSO TRENTO 72,87034 Grimaldi (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.1422313",
    lng: "16.2323653",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4096",
  },
  {
    id: "57530",
    map_id: "1",
    address:
      "Via Nuova, 42/A Frazione Segromigno In M,55018 SEGROMIGNO IN MONTE (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8802292",
    lng: "10.5933819",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4133",
  },
  {
    id: "57529",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 68/A,08030 Austis (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.070955",
    lng: "9.088182999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4135",
  },
  {
    id: "57528",
    map_id: "1",
    address: "VIA GIOVANE ITALIA 53/A,76123 Andria (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2340685",
    lng: "16.2969929",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4137",
  },
  {
    id: "57527",
    map_id: "1",
    address: "VIA SAVONAROLA SNC,75020 Nova Siri (MT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.13251890000001",
    lng: "16.6386122",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4141",
  },
  {
    id: "57526",
    map_id: "1",
    address: "VIA DON STURZO 8,89013 Gioia Tauro (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.42549229999999",
    lng: "15.8913633",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4157",
  },
  {
    id: "57525",
    map_id: "1",
    address: "VIALE TIRRENO 117,00141 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.93900259999999",
    lng: "12.5295338",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4213",
  },
  {
    id: "57524",
    map_id: "1",
    address: "VIA GEMIGNANI 13,56127 Pisa (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6984214",
    lng: "10.447987",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4221",
  },
  {
    id: "57523",
    map_id: "1",
    address: "VIA TENENTE FIORINO 41,70056 Molfetta (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2019729",
    lng: "16.6042244",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4233",
  },
  {
    id: "57522",
    map_id: "1",
    address: "VIA FIGARI 1,07037 Sorso (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.80412889999999",
    lng: "8.5778048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4245",
  },
  {
    id: "57521",
    map_id: "1",
    address: "VIA COLONNELLO MONTANARO 67,72022 Latiano (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5526662",
    lng: "17.7201858",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4259",
  },
  {
    id: "57520",
    map_id: "1",
    address: "LARGO CONCEZIONE 7,86093 Carpinone (IS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5892905",
    lng: "14.3273038",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4274",
  },
  {
    id: "57519",
    map_id: "1",
    address: "VIA CROCI  DI RIARDO SNC,81053 Riardo (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2668",
    lng: "14.164092",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4287",
  },
  {
    id: "57518",
    map_id: "1",
    address: "VIA CONSOLARE CAMPANA 371,80010 Villaricca (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9066499",
    lng: "14.1268849",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4289",
  },
  {
    id: "57517",
    map_id: "1",
    address: "VIA PIETRO CASTELLI 36,98122 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.192634",
    lng: "15.54457",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4317",
  },
  {
    id: "57516",
    map_id: "1",
    address: "Via Teodosio 3,73039 Tricase (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.9261034",
    lng: "18.3553079",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4339",
  },
  {
    id: "57515",
    map_id: "1",
    address: "VIA ISTRIA 58,09047 Selargius (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.25352",
    lng: "9.1655833",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4340",
  },
  {
    id: "57514",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE III 83,98061 Brolo (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1564337",
    lng: "14.827472",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4343",
  },
  {
    id: "57513",
    map_id: "1",
    address: "VIA ROMA 15,73054 PRESICCE (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.9047111",
    lng: "18.2565854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4421",
  },
  {
    id: "57512",
    map_id: "1",
    address: "VIA ROMA 113,92020 Santo Stefano Quisquina (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6249494",
    lng: "13.4901439",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4424",
  },
  {
    id: "57511",
    map_id: "1",
    address: "VIA ISONZO 17,70125 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1093",
    lng: "16.87165",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4442",
  },
  {
    id: "57510",
    map_id: "1",
    address: "VIA CITTA' DI PALERMO 167/F,90011 Bagheria (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.082134",
    lng: "13.5013767",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4452",
  },
  {
    id: "57509",
    map_id: "1",
    address: "VIALE NETTUNO 155,00054 FREGENE (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.84466159999999",
    lng: "12.2031035",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4453",
  },
  {
    id: "57508",
    map_id: "1",
    address: "PIAZZA SCALO TRAPANESE SN,97010 MARINA DI RAGUSA (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "36.7830926",
    lng: "14.5480029",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4477",
  },
  {
    id: "57507",
    map_id: "1",
    address: "VIA E.MAIORANA SN,67035 Pratola Peligna (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0978434",
    lng: "13.8711247",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4506",
  },
  {
    id: "57506",
    map_id: "1",
    address: "VIA DE MARTINO 3,80040 Terzigno (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8097357",
    lng: "14.4950328",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4514",
  },
  {
    id: "57505",
    map_id: "1",
    address: "VIA ROMA 101,97013 Comiso (RG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "36.9434929",
    lng: "14.6076012",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4524",
  },
  {
    id: "57504",
    map_id: "1",
    address: "PIAZZA NATALE D'AGOSTINO SN,76125 Trani (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2782266",
    lng: "16.4132401",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4528",
  },
  {
    id: "57503",
    map_id: "1",
    address: "VIA RAFFELE DIBARI 25/27,76121 Barletta (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.3054066",
    lng: "16.2811935",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4573",
  },
  {
    id: "57502",
    map_id: "1",
    address: "CORSO A.GRAMSCI, 196/B,91025 Marsala (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.8068479",
    lng: "12.4423032",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4579",
  },
  {
    id: "57501",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 93,67031 Castel di Sangro (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.78546850000001",
    lng: "14.1073201",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4589",
  },
  {
    id: "57500",
    map_id: "1",
    address: "CORSO ITALIA SNC,87046 Montalto Uffugo (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.43076",
    lng: "16.23243",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4596",
  },
  {
    id: "57499",
    map_id: "1",
    address: "VIA ROMA 28,97017 Santa Croce Camerina (RG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "36.827309",
    lng: "14.5246895",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4607",
  },
  {
    id: "57498",
    map_id: "1",
    address: "VIA G.MARCONI 7,85010 Pignola (PZ)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.573716",
    lng: "15.7851725",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4615",
  },
  {
    id: "57497",
    map_id: "1",
    address: "VIA BIANCHERI GIUSEPPE 62/R,16154 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4229622",
    lng: "8.8481572",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4625",
  },
  {
    id: "57496",
    map_id: "1",
    address: "VIA G. OCONE 27,82030 PONTE (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2137252",
    lng: "14.6985242",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4628",
  },
  {
    id: "57495",
    map_id: "1",
    address: "VIALE MANZONI 25,00052 Cerveteri (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9953097",
    lng: "12.0927629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4696",
  },
  {
    id: "57494",
    map_id: "1",
    address: "VIA CAMOZZINI CARLO 68/R,16158 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4285443",
    lng: "8.7479335",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4697",
  },
  {
    id: "57493",
    map_id: "1",
    address: "VIA SALVATORE DENARO 1,97016 Pozzallo (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "36.7324397",
    lng: "14.857",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4699",
  },
  {
    id: "57492",
    map_id: "1",
    address: "VIA VOLVERA 3,10045 Piossasco (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.9891914",
    lng: "7.4684674",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4705",
  },
  {
    id: "57491",
    map_id: "1",
    address: "VIA AGRIGENTO 3,63040 FOLIGNANO (AP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.83217",
    lng: "13.63662",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4734",
  },
  {
    id: "57490",
    map_id: "1",
    address: "CONTRADA PIETRARA SNC,65020 Lettomanoppello (PE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.2492872",
    lng: "14.0352436",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4745",
  },
  {
    id: "57489",
    map_id: "1",
    address: "VIA GRASSI 31,14100 Asti (AT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.8953643",
    lng: "8.1997333",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4764",
  },
  {
    id: "57488",
    map_id: "1",
    address: "VIA FRACCHIA 23,15057 TORTONA (AL)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.8962489",
    lng: "8.8653564",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4770",
  },
  {
    id: "57487",
    map_id: "1",
    address: "VIA DELLE ROSE 78,81033 Casal di Principe (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.0044003",
    lng: "14.1263564",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4773",
  },
  {
    id: "57486",
    map_id: "1",
    address: "VIA DEI MILLE 1,70013 Castellana Grotte (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8843486",
    lng: "17.1664313",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4785",
  },
  {
    id: "57485",
    map_id: "1",
    address: "P.ZZA GIOVANNI FALCONE C/O C.C. LA PALMA,00055 Ladispoli (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.955839",
    lng: "12.0710215",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4786",
  },
  {
    id: "57484",
    map_id: "1",
    address: "VIA IV NOVEMBRE 55,91021 Campobello di Mazara (TP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.63808470000001",
    lng: "12.7551002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4790",
  },
  {
    id: "57483",
    map_id: "1",
    address: "VIA IPPOLITO NIEVO 1/B,06012 CITTA' DI CASTELLO (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.4486786",
    lng: "12.2478627",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4813",
  },
  {
    id: "57482",
    map_id: "1",
    address: "Corso Meridionale, 51,80140 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.8549373",
    lng: "14.2756166",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4821",
  },
  {
    id: "57481",
    map_id: "1",
    address: "VIA GIOVANNI AMENDOLA 2,80020 CRISPANO (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9510387",
    lng: "14.2925387",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4824",
  },
  {
    id: "57480",
    map_id: "1",
    address: "VIA STATI UNITI D'AMERICA 57,84025 Eboli (SA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.618448",
    lng: "15.0606783",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4861",
  },
  {
    id: "57479",
    map_id: "1",
    address: "VIA PISANA 54,50018 Scandicci (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7639319",
    lng: "11.175031",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4867",
  },
  {
    id: "57478",
    map_id: "1",
    address: "VIA SORSO 32,07100 Sassari (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7319664",
    lng: "8.5613288",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4923",
  },
  {
    id: "57477",
    map_id: "1",
    address: "VIA MARCHE 22,73013 Galatina (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1726643",
    lng: "18.1635974",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4924",
  },
  {
    id: "57476",
    map_id: "1",
    address: "VIA GARIBALDI 252,98121 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.20443",
    lng: "15.558204",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4926",
  },
  {
    id: "57475",
    map_id: "1",
    address: "Via E. Capizzano 62,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3063638",
    lng: "16.2449235",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4929",
  },
  {
    id: "57474",
    map_id: "1",
    address: "VIA GARIBALDI 48/B,40061 MINERBIO (BO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.6255849",
    lng: "11.4911974",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4934",
  },
  {
    id: "57473",
    map_id: "1",
    address: "VIA DEL BOTTEGHINO 199,50018 Scandicci (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7787421",
    lng: "11.1475026",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4935",
  },
  {
    id: "57472",
    map_id: "1",
    address: "VIA CARSO 77,67035 Pratola Peligna (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0961033",
    lng: "13.8745862",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4956",
  },
  {
    id: "57471",
    map_id: "1",
    address: "VIA DELL'AQUILONE 121,73010 Surbo (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.3874974",
    lng: "18.1493685",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4965",
  },
  {
    id: "57470",
    map_id: "1",
    address: "CORSO EUROPA 1,80016 Marano di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.898273",
    lng: "14.195254",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "4974",
  },
  {
    id: "57469",
    map_id: "1",
    address: "VIA VECCHIA ROMANA 50,51013 Chiesina Uzzanese (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8503361",
    lng: "10.7085521",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5026",
  },
  {
    id: "57468",
    map_id: "1",
    address: "PIAZZA MANZONI 5,10022 Carmagnola (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.8463599",
    lng: "7.7187199",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5040",
  },
  {
    id: "57467",
    map_id: "1",
    address: "PIAZZA DE GASPERI 8,09017 Sant'Antioco (CI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.0697974",
    lng: "8.4509681",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5059",
  },
  {
    id: "57466",
    map_id: "1",
    address: "VIA CALABRIA 7,80059 Torre del Greco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7799757",
    lng: "14.3808575",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5063",
  },
  {
    id: "57465",
    map_id: "1",
    address: "VIA MILANO 247,97019 Vittoria (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "36.9490136",
    lng: "14.5249453",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5079",
  },
  {
    id: "57464",
    map_id: "1",
    address: "VIALE PIO VII 104/R,16148 Genova (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.3922404",
    lng: "8.999402",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5082",
  },
  {
    id: "57463",
    map_id: "1",
    address: "VIA PIERO GOBETTI 143,89046 MARINA DI GIOIOSA JONICA (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "38.3029503",
    lng: "16.3252907",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5092",
  },
  {
    id: "57462",
    map_id: "1",
    address: "VIA GIACOMO LEOPARDI 11,52100 Arezzo (AR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.4501408",
    lng: "11.865849",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5105",
  },
  {
    id: "57461",
    map_id: "1",
    address: "VIA BIGLIARDO 14/16,80011 Acerra (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9403362",
    lng: "14.3838852",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5114",
  },
  {
    id: "57460",
    map_id: "1",
    address: "Via San Francesco 16,20096 Pioltello (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.497789",
    lng: "9.3234136",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5121",
  },
  {
    id: "57459",
    map_id: "1",
    address: "VIA ETTORE FIERAMOSCA 230,97100 Ragusa (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "36.9095361",
    lng: "14.6967115",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5123",
  },
  {
    id: "57458",
    map_id: "1",
    address: "VIA BACCIO DA MONTELUPO 68,50018 Scandicci (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.7649878",
    lng: "11.1728047",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5151",
  },
  {
    id: "57457",
    map_id: "1",
    address: "VIA TRIESTE 34,98066 Patti (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1401563",
    lng: "14.9698344",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5166",
  },
  {
    id: "57456",
    map_id: "1",
    address: "VIA SOLETO 49,73013 Galatina (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1763539",
    lng: "18.1738543",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5170",
  },
  {
    id: "57455",
    map_id: "1",
    address: "VIA DEGLI ORTI 61/A,07041 Alghero (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5654314",
    lng: "8.3194942",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5171",
  },
  {
    id: "57454",
    map_id: "1",
    address: "Via Regina Elena, 38,95040 Motta Sant'Anastasia (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5117569",
    lng: "14.9677235",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5180",
  },
  {
    id: "57453",
    map_id: "1",
    address: "Via Bernardo Cavallino, 39,80128 Napoli (NA)",
    description: "RICARICHE - SPEDIZIONI",
    lat: "40.8597718",
    lng: "14.230419",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5182",
  },
  {
    id: "57452",
    map_id: "1",
    address: "VIA SILIO ITALICO 56,80124 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8186397",
    lng: "14.1658135",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5190",
  },
  {
    id: "57451",
    map_id: "1",
    address: "VIA NUORO SNC,08020 Budoni (SS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.7037172",
    lng: "9.701454499999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5204",
  },
  {
    id: "57450",
    map_id: "1",
    address: "VIA BERNARDO MATTARELLA 169/171,90011 Bagheria (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0852035",
    lng: "13.5129829",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5211",
  },
  {
    id: "57449",
    map_id: "1",
    address: "PIAZZA GALILEO SC,82015 Durazzano (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0595139",
    lng: "14.4548621",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5235",
  },
  {
    id: "57448",
    map_id: "1",
    address: "VIA DEI CADUTI 33,20020 Arese (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5486426",
    lng: "9.078363",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5239",
  },
  {
    id: "57447",
    map_id: "1",
    address: "CORSO SECONDIGLIANO 113/115,80144 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8844444",
    lng: "14.2691667",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5248",
  },
  {
    id: "57446",
    map_id: "1",
    address: "PIAZZA DEVITO FRANCESCO 9,70025 Grumo Appula (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0095743",
    lng: "16.706685",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5274",
  },
  {
    id: "57445",
    map_id: "1",
    address: "VIA CASILINA 334,03013 Ferentino (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6614212",
    lng: "13.3093879",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5277",
  },
  {
    id: "57444",
    map_id: "1",
    address: "VIA PROVINCIALE PER S.ALESSIO 1449,55100 Lucca (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8611065",
    lng: "10.4894758",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5279",
  },
  {
    id: "57443",
    map_id: "1",
    address: "VIA CAP.CULTRERA, 105,96019 Rosolini (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.820285",
    lng: "14.944309",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5280",
  },
  {
    id: "57442",
    map_id: "1",
    address: "VIA SS 19 45/A,87050 Mangone (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.1699118",
    lng: "16.3058382",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5286",
  },
  {
    id: "57441",
    map_id: "1",
    address: "PIAZZA PAPA GIOVANNI XXIII SNC,95042 Grammichele (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.2154184",
    lng: "14.6315831",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5294",
  },
  {
    id: "57440",
    map_id: "1",
    address: "VIA F.LLI CERVI 29,42020 Quattro Castella (RE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.6326554",
    lng: "10.5367388",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5305",
  },
  {
    id: "57439",
    map_id: "1",
    address: "CORSO GIUSEPPE DI VITTORIO 60/B,70024 Gravina in Puglia (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.819302",
    lng: "16.41966",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5307",
  },
  {
    id: "57438",
    map_id: "1",
    address: "VIA GIUSEPPE VANNI 24,00166 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8820345",
    lng: "12.3593413",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5308",
  },
  {
    id: "57437",
    map_id: "1",
    address: "VIA BORGO UMBERTO 55/A,01030 Corchiano (VT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.3473099",
    lng: "12.3569795",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5309",
  },
  {
    id: "57436",
    map_id: "1",
    address: "PIAZZA ALDO MORO SNC,57127 Livorno (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.5359902",
    lng: "10.3185394",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5324",
  },
  {
    id: "57435",
    map_id: "1",
    address: "LOCALITA' GIULZI SN,07032 NULVI (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.78924980000001",
    lng: "8.7429717",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5340",
  },
  {
    id: "57434",
    map_id: "1",
    address: "VIA GAETANO DI BIASIO 99-101,03043 Cassino (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.4815889",
    lng: "13.8242863",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5357",
  },
  {
    id: "57433",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI 86,80059 Torre del Greco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7781787",
    lng: "14.3807825",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5369",
  },
  {
    id: "57432",
    map_id: "1",
    address: "VIA FRATELLI CAIROLI 8,27011 Belgioioso (PV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.160841",
    lng: "9.316322999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5372",
  },
  {
    id: "57431",
    map_id: "1",
    address: "VIA SETTEVENE PALO 228,00052 Cerveteri (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9958839",
    lng: "12.0964584",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5411",
  },
  {
    id: "57430",
    map_id: "1",
    address: "PIAZZA ANGELO MAGLIO 15,83010 Grottolella (AV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.972923",
    lng: "14.786985",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5422",
  },
  {
    id: "57429",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI 62,84018 Scafati (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7538695",
    lng: "14.5276828",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5432",
  },
  {
    id: "57428",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE III 80,96016 Lentini (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.2905624",
    lng: "14.9995401",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5458",
  },
  {
    id: "57427",
    map_id: "1",
    address: "VIA PER UGGIANO 106,74024 Manduria (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3955646",
    lng: "17.6292975",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5461",
  },
  {
    id: "57426",
    map_id: "1",
    address: "VIA PASQUALE REVOLTELLA 70,00152 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8738851",
    lng: "12.4534077",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5465",
  },
  {
    id: "57425",
    map_id: "1",
    address: "VIALE SAN MARTINO 238,98123 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.18137",
    lng: "15.55197",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5471",
  },
  {
    id: "57424",
    map_id: "1",
    address: "VIA CESARE BATTISTI 4,09039 Villacidro (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.4553349",
    lng: "8.7460302",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5491",
  },
  {
    id: "57423",
    map_id: "1",
    address: "VIA TARQUINIA 96,01017 Tuscania (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.41795399999999",
    lng: "11.8650754",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5495",
  },
  {
    id: "57422",
    map_id: "1",
    address: "VIA MARIO ANGELONI 10/G,06012 CITTA' DI CASTELLO (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.4590836",
    lng: "12.2389447",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5507",
  },
  {
    id: "57421",
    map_id: "1",
    address: "VIA LEANZA 39,72028 Torre Santa Susanna (BR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.4658866",
    lng: "17.7402138",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5509",
  },
  {
    id: "57420",
    map_id: "1",
    address: "VIA PORTA CASSEBBA 5,09014 Carloforte (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.144985",
    lng: "8.303602",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5511",
  },
  {
    id: "57419",
    map_id: "1",
    address: "VIA VANVITELLI 11/13,84085 Mercato San Severino (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7844295",
    lng: "14.7534384",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5515",
  },
  {
    id: "57418",
    map_id: "1",
    address: "VIA LENZI 8/10,98122 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1904079",
    lng: "15.549979",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5526",
  },
  {
    id: "57417",
    map_id: "1",
    address: "VIA PANTANELLE 8,04011 Aprilia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5367223",
    lng: "12.6375072",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5531",
  },
  {
    id: "57416",
    map_id: "1",
    address: "VIA CAMPO DELLE VETTOVAGLIE 92,98122 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1870651",
    lng: "15.5610138",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5539",
  },
  {
    id: "57415",
    map_id: "1",
    address: "CORSO EUROPA 108,07039 Valledoria (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9271923",
    lng: "8.8190939",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5555",
  },
  {
    id: "57414",
    map_id: "1",
    address: "VIA ORIANI 43,07100 Sassari (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7205",
    lng: "8.5762383",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5558",
  },
  {
    id: "57413",
    map_id: "1",
    address: "VIA S.ANDREA, S.S. 267 SNC,84048 Castellabate (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.266625",
    lng: "14.9449",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5589",
  },
  {
    id: "57412",
    map_id: "1",
    address: "VIA NAZIONALE DELLE PUGLIE 5,80021 Afragola (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.89819809999999",
    lng: "14.332254",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5592",
  },
  {
    id: "57411",
    map_id: "1",
    address: "VIA DI VITTORIO 95,80019 Qualiano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9180341",
    lng: "14.1473038",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5594",
  },
  {
    id: "57410",
    map_id: "1",
    address: "VIA CONSOLAZIONE 16/R,16121 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.40608049999999",
    lng: "8.941249899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5598",
  },
  {
    id: "57409",
    map_id: "1",
    address: "VIA G.M. ANGIOY 10,08040 Cardedu (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.7967666",
    lng: "9.6279247",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5616",
  },
  {
    id: "57408",
    map_id: "1",
    address: "VIA NAZIONALE 333,81028 Santa Maria a Vico (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0263256",
    lng: "14.4772436",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5626",
  },
  {
    id: "57407",
    map_id: "1",
    address: "VIALE VINCENZO MARTINI 92,51015 Monsummano Terme (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8748771",
    lng: "10.8068831",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5635",
  },
  {
    id: "57406",
    map_id: "1",
    address: "PIAZZA LIBERTA' 4,97011 Acate (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0263477",
    lng: "14.4943044",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5636",
  },
  {
    id: "57405",
    map_id: "1",
    address: "VIA B. BUOZZI 59,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.8207648",
    lng: "11.1400387",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5639",
  },
  {
    id: "57404",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI 18/A,70023 Gioia del Colle (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.798087",
    lng: "16.9183971",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5654",
  },
  {
    id: "57403",
    map_id: "1",
    address: "VIA CONCORDIA PIANO T 33,89023 Laureana di Borrello (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.487834",
    lng: "16.0814888",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5655",
  },
  {
    id: "57402",
    map_id: "1",
    address: "VIA VOLTA 13,70015 Noci (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.7977643",
    lng: "17.118864",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5661",
  },
  {
    id: "57401",
    map_id: "1",
    address: "VIA DUCA DELLA VITTORIA 31,04016 Sabaudia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2988044",
    lng: "13.0313167",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5700",
  },
  {
    id: "57400",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 48,85010 Armento (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2387568",
    lng: "16.0507016",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5723",
  },
  {
    id: "57399",
    map_id: "1",
    address: "VIA EUROPA 29,74017 Mottola (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.635698",
    lng: "17.0333757",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5724",
  },
  {
    id: "57398",
    map_id: "1",
    address: "PONTE VALENTINO SNC,82100 Benevento (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.146713",
    lng: "14.8381655",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5727",
  },
  {
    id: "57397",
    map_id: "1",
    address: "PIANO CAPPELLE 294,82100 Benevento (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1023384",
    lng: "14.8256804",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5728",
  },
  {
    id: "57396",
    map_id: "1",
    address: "VIA ROMA, 106 STRADARIO 02928,09043 Muravera (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.420521",
    lng: "9.578083699999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5740",
  },
  {
    id: "57395",
    map_id: "1",
    address: "VIA DELL'OLMO 96,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.82953",
    lng: "11.1326627",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5742",
  },
  {
    id: "57394",
    map_id: "1",
    address: "Corso Europa 90,80017 Melito di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9260099",
    lng: "14.2238343",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5744",
  },
  {
    id: "57393",
    map_id: "1",
    address: "VIA GRAMSCI 43,56035 Casciana Terme Lari (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.60483079999999",
    lng: "10.5922433",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5745",
  },
  {
    id: "57392",
    map_id: "1",
    address: "VIA TALLI BOX 30 - MERCATO SERPENTARA 2,00139 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.965355",
    lng: "12.5206048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5748",
  },
  {
    id: "57391",
    map_id: "1",
    address: "VIA DOMENICO PURIFICATO 185/187,00125 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7708788",
    lng: "12.3588929",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5757",
  },
  {
    id: "57390",
    map_id: "1",
    address: "PIAZZA AMEDEO 55,81031 Aversa (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9734494",
    lng: "14.2049672",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5762",
  },
  {
    id: "57389",
    map_id: "1",
    address: "VIA MARTIRI ATELLANI 143,81030 Sant'Arpino (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.961944",
    lng: "14.2545923",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5763",
  },
  {
    id: "57388",
    map_id: "1",
    address: "Via Vittorio Veneto 45,81052 Pignataro Maggiore (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.188818",
    lng: "14.171188",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5764",
  },
  {
    id: "57387",
    map_id: "1",
    address: "VIALE DELLA REPUBBLICA 1,70017 Putignano (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8546349",
    lng: "17.1195253",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5772",
  },
  {
    id: "57386",
    map_id: "1",
    address: "Via Roma 125 , fraz Longobardi,89900 Vibo Valentia (VV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.70248429999999",
    lng: "16.1215184",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5774",
  },
  {
    id: "57385",
    map_id: "1",
    address: "Via Santa Vittoria 1,07036 Sennori (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.791521",
    lng: "8.597871",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5803",
  },
  {
    id: "57384",
    map_id: "1",
    address: "Via Trento 9/11,95046 Palagonia (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.32905",
    lng: "14.747863",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5804",
  },
  {
    id: "57383",
    map_id: "1",
    address: "Via Vittorio Emanuele III 171,98061 Brolo (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1562303",
    lng: "14.83069",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5805",
  },
  {
    id: "57382",
    map_id: "1",
    address: "Via San Carlo 163,81100 Caserta (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0735953",
    lng: "14.339336",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5807",
  },
  {
    id: "57381",
    map_id: "1",
    address: "Via Vincenzo Loria 35,84129 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.662847",
    lng: "14.7995177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5810",
  },
  {
    id: "57380",
    map_id: "1",
    address: "Via Imperatore Adriano 28/B,73100 Lecce (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3555719",
    lng: "18.1786963",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5811",
  },
  {
    id: "57379",
    map_id: "1",
    address: "Via Campo Sportivo snc,87042 Altomonte (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.69848169999999",
    lng: "16.1289488",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5831",
  },
  {
    id: "57378",
    map_id: "1",
    address: "Via Garibaldi snc,88070 Botricello (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9348349",
    lng: "16.8578287",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5841",
  },
  {
    id: "57377",
    map_id: "1",
    address: "Via Grimaldi 81,89022 Cittanova (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.3524499",
    lng: "16.0837618",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5848",
  },
  {
    id: "57376",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI 80,80059 Torre del Greco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7792009",
    lng: "14.3805293",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5861",
  },
  {
    id: "57375",
    map_id: "1",
    address: "Corso Umberto I 156,09074 Ghilarza (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1208745",
    lng: "8.835905799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5868",
  },
  {
    id: "57374",
    map_id: "1",
    address: "VIA MIRANDA 57,83016 Roccabascerana (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0171739",
    lng: "14.7183544",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5869",
  },
  {
    id: "57373",
    map_id: "1",
    address: "Corso Vittorio Emanuele 199/A,90036 Misilmeri (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.031721",
    lng: "13.44838",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5873",
  },
  {
    id: "57372",
    map_id: "1",
    address: "Via Mazzini 8,57022 Castagneto Carducci (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.1688194",
    lng: "10.567396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5889",
  },
  {
    id: "57371",
    map_id: "1",
    address: "Via Cassano 28,80144 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8834959",
    lng: "14.2716322",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5890",
  },
  {
    id: "57370",
    map_id: "1",
    address: "VIA PALA DI CARRU 155/A,07100 Sassari (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.74750710000001",
    lng: "8.5212655",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5898",
  },
  {
    id: "57369",
    map_id: "1",
    address: "Corso Umberto I 36,80046 San Giorgio a Cremano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8284134",
    lng: "14.3329621",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5902",
  },
  {
    id: "57368",
    map_id: "1",
    address: "Via Nazionale 306,87060 Crosia (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6040664",
    lng: "16.7788108",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5913",
  },
  {
    id: "57367",
    map_id: "1",
    address: "Via Vincenzo Brunacci 22,00146 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8616821",
    lng: "12.4677301",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5920",
  },
  {
    id: "57366",
    map_id: "1",
    address: "Via Lamaccio Sn,67039 Sulmona (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.06408769999999",
    lng: "13.9256853",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5921",
  },
  {
    id: "57365",
    map_id: "1",
    address: "Via Monte Rosa 95,80144 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8999748",
    lng: "14.2509761",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5939",
  },
  {
    id: "57364",
    map_id: "1",
    address: "Via Benedetto Croce 180/1,65126 Pescara (PE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.4577111",
    lng: "14.2228734",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5945",
  },
  {
    id: "57363",
    map_id: "1",
    address: "Via Carlo Alberto 9,09076 Sedilo (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1735725",
    lng: "8.9192585",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5951",
  },
  {
    id: "57362",
    map_id: "1",
    address: "Piazza Stefano Jacini Snc,00167 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9496798",
    lng: "12.4656691",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5952",
  },
  {
    id: "57361",
    map_id: "1",
    address: "Via delle Camelie 90,04100 Latina (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.5269449",
    lng: "12.9527972",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5963",
  },
  {
    id: "57360",
    map_id: "1",
    address: "Piazza Garibaldi 14,50053 Empoli (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7198042",
    lng: "10.9440543",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5975",
  },
  {
    id: "57359",
    map_id: "1",
    address: "Via Umberto, 106,95035 Maletto (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.82768069999999",
    lng: "14.8660535",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5982",
  },
  {
    id: "57358",
    map_id: "1",
    address: "Via Michele Conforti 19/21,84124 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6792173",
    lng: "14.7726702",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "5997",
  },
  {
    id: "57357",
    map_id: "1",
    address: "Via degli Elleni 34,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4641633",
    lng: "12.9191866",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6004",
  },
  {
    id: "57356",
    map_id: "1",
    address: "Piazza Vittorio Emanuele 16,74016 Massafra (TA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.5870206",
    lng: "17.1153121",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6011",
  },
  {
    id: "57355",
    map_id: "1",
    address: "Via Servio Tullio 84,80126 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8450857",
    lng: "14.1991828",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6021",
  },
  {
    id: "57354",
    map_id: "1",
    address: "Corso Nazioni Unite 42,10073 CIRIE' (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.236644",
    lng: "7.605785300000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6030",
  },
  {
    id: "57353",
    map_id: "1",
    address: "Via Rocco di Cillo 6/C,70131 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0733385",
    lng: "16.8587475",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6045",
  },
  {
    id: "57352",
    map_id: "1",
    address: "Via S.Marco 81/83,80013 Casalnuovo di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.910512",
    lng: "14.3363408",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6047",
  },
  {
    id: "57351",
    map_id: "1",
    address: "Via Salvatore Allende 122,74017 Mottola (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.63804289999999",
    lng: "17.0404499",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6048",
  },
  {
    id: "57350",
    map_id: "1",
    address: "Via Giunti 5,80040 Terzigno (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7935303",
    lng: "14.4839765",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6054",
  },
  {
    id: "57349",
    map_id: "1",
    address: "Corso Italia 27,95018 Riposto (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.7272408",
    lng: "15.1854543",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6057",
  },
  {
    id: "57348",
    map_id: "1",
    address: "Via Regina Margherita 66,80144 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8902626",
    lng: "14.2587619",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6061",
  },
  {
    id: "57347",
    map_id: "1",
    address: "Corso Garibaldi 46,08034 Laconi (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.8543812",
    lng: "9.05196",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6062",
  },
  {
    id: "57346",
    map_id: "1",
    address: "Via Capitano Callea 118,92026 Favara (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3241742",
    lng: "13.6631639",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6068",
  },
  {
    id: "57345",
    map_id: "1",
    address: "Piazzale Giacomo Matteotti, 7,00030 Genazzano (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.829003",
    lng: "12.97272",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6080",
  },
  {
    id: "57344",
    map_id: "1",
    address: "Piazza Sardegna Snc,09039 Villacidro (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.462863",
    lng: "8.7411909",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6082",
  },
  {
    id: "57343",
    map_id: "1",
    address: "Via Mazzini 1,09124 Cagliari (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2157379",
    lng: "9.116174299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6091",
  },
  {
    id: "57342",
    map_id: "1",
    address: "Piazza G. Marconi 18,03030 Santopadre (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.6033032",
    lng: "13.6346485",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6092",
  },
  {
    id: "57341",
    map_id: "1",
    address: "Via Pontinia 29,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4555129",
    lng: "12.9071836",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6102",
  },
  {
    id: "57340",
    map_id: "1",
    address: "Via Gesuino Manca 10,09098 Terralba (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.7181307",
    lng: "8.6337854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6122",
  },
  {
    id: "57339",
    map_id: "1",
    address: "Via Aldo Moro 8/A,76125 Trani (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.27521489999999",
    lng: "16.4219671",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6125",
  },
  {
    id: "57338",
    map_id: "1",
    address: "Via Aldo Moro 86,71011 APRICENA (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.785132",
    lng: "15.445758",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6135",
  },
  {
    id: "57337",
    map_id: "1",
    address: "Via Gino Severini 8/B,80078 Pozzuoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8633323",
    lng: "14.082029",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6160",
  },
  {
    id: "57336",
    map_id: "1",
    address: "Via Buccari 44,70125 Bari (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.1110376",
    lng: "16.8700609",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6170",
  },
  {
    id: "57335",
    map_id: "1",
    address: "Via Arezzo 39,54100 Massa (MS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.00841",
    lng: "10.11549",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6176",
  },
  {
    id: "57334",
    map_id: "1",
    address: "Via Croce 68,86021 Bojano (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4846694",
    lng: "14.4759806",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6376",
  },
  {
    id: "57333",
    map_id: "1",
    address: "Corso Risorgimento 71,86170 Isernia (IS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5956436",
    lng: "14.2366787",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6391",
  },
  {
    id: "57332",
    map_id: "1",
    address: "Via Brodolini 12 C,09040 SENORBI' (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.5379476",
    lng: "9.1318229",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6394",
  },
  {
    id: "57331",
    map_id: "1",
    address: "Via Montegrappa 83,66050 San Salvo (CH)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0441554",
    lng: "14.7231622",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6401",
  },
  {
    id: "57330",
    map_id: "1",
    address: "Via Gianfrotta 33,81054 San Prisco (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0879444",
    lng: "14.2705899",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6412",
  },
  {
    id: "57329",
    map_id: "1",
    address: "Via Vittorio Veneto Snc,08020 Posada (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6303176",
    lng: "9.714884999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6413",
  },
  {
    id: "57328",
    map_id: "1",
    address: "Via Caudio 59/61,80013 Casalnuovo di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9221164",
    lng: "14.3554568",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6414",
  },
  {
    id: "57327",
    map_id: "1",
    address: "Via Luigi Guercio 16,84134 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6786438",
    lng: "14.7760511",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6430",
  },
  {
    id: "57326",
    map_id: "1",
    address: "Corso Umberto I 141,93100 Caltanissetta (CL)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.490498",
    lng: "14.0627975",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6431",
  },
  {
    id: "57325",
    map_id: "1",
    address: "Via Italia 4,89822 Simbario (VV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.6127836",
    lng: "16.3379163",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6442",
  },
  {
    id: "57324",
    map_id: "1",
    address: "Via XXV Luglio 2/A,73100 Lecce (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.354026",
    lng: "18.1740568",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6448",
  },
  {
    id: "57323",
    map_id: "1",
    address: "Viale Marconi 19/25,51017 Pescia (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8888275",
    lng: "10.687882",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6449",
  },
  {
    id: "57322",
    map_id: "1",
    address: "Piazzale Flaminio, 7 - Fraz. San Giacomo,06049 Spoleto (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.79442",
    lng: "12.7496069",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6450",
  },
  {
    id: "57321",
    map_id: "1",
    address: "Via Tuscania 48,01011 Canino (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.463379",
    lng: "11.751101",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6463",
  },
  {
    id: "57320",
    map_id: "1",
    address: "Via Roma 106/G,50063 FIGLINE E INCISA VALDARNO (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6246205",
    lng: "11.4664853",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6467",
  },
  {
    id: "57319",
    map_id: "1",
    address: "Via Recanati 23/25,80046 San Giorgio a Cremano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.83262209999999",
    lng: "14.3367143",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6483",
  },
  {
    id: "57318",
    map_id: "1",
    address: "Via Degli Strauss 82,00124 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.75191239999999",
    lng: "12.3719721",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6490",
  },
  {
    id: "57317",
    map_id: "1",
    address: "Corso Italia 20,73014 Gallipoli (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.0568125",
    lng: "17.9976815",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6492",
  },
  {
    id: "57316",
    map_id: "1",
    address: "Via Giuseppe di Prisco, 298,80040 Ottaviano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.8566238",
    lng: "14.5118908",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6493",
  },
  {
    id: "57315",
    map_id: "1",
    address: "Via A. De Gasperi 171,76012 Canosa di Puglia (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2273631",
    lng: "16.061248",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6555",
  },
  {
    id: "57314",
    map_id: "1",
    address: "Via Allegrezza 2,91011 Alcamo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.976585",
    lng: "12.969853",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6556",
  },
  {
    id: "57313",
    map_id: "1",
    address: "Corso Umberto I 74,70056 Molfetta (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2000421",
    lng: "16.5988767",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6557",
  },
  {
    id: "57312",
    map_id: "1",
    address: "Via Eleonora D'Arborea 17,07040 Olmedo (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6534576",
    lng: "8.3798431",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6573",
  },
  {
    id: "57311",
    map_id: "1",
    address: "Via G. Marconi 88,71016 San Severo (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.682213",
    lng: "15.3896",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6578",
  },
  {
    id: "57310",
    map_id: "1",
    address: "Via Del Popolo 35,02032 Fara in Sabina (RI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.2096017",
    lng: "12.7278449",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6579",
  },
  {
    id: "57309",
    map_id: "1",
    address: "Via E. Curiel 17,95040 Mirabella Imbaccari (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.324997",
    lng: "14.44328",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6583",
  },
  {
    id: "57308",
    map_id: "1",
    address: "Via Conceria 15/16,92016 Ribera (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5052607",
    lng: "13.2635902",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6585",
  },
  {
    id: "57307",
    map_id: "1",
    address: "Via Maremmana 1,00017 NEROLA (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1635291",
    lng: "12.7435455",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6588",
  },
  {
    id: "57306",
    map_id: "1",
    address: "Via Risorgimento 229,98123 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1826163",
    lng: "15.5513582",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6596",
  },
  {
    id: "57305",
    map_id: "1",
    address: "Via Francesco Cocco Ortu 32,00139 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9587424",
    lng: "12.5222909",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6602",
  },
  {
    id: "57304",
    map_id: "1",
    address: "Via Fereggiano 209 A/R,16144 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4180331",
    lng: "8.9652996",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6611",
  },
  {
    id: "57303",
    map_id: "1",
    address: "Via Nizza 40,71042 Cerignola (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2657899",
    lng: "15.9078757",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6612",
  },
  {
    id: "57302",
    map_id: "1",
    address: "Via Segesta 172,91014 Castellammare del Golfo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.01957",
    lng: "12.8862356",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6619",
  },
  {
    id: "57301",
    map_id: "1",
    address: "Via Donizetti 2/A,24044 Dalmine (BG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6534671",
    lng: "9.607147",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6622",
  },
  {
    id: "57300",
    map_id: "1",
    address: "Via Di Vittorio 63,81030 Sant'Arpino (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9584502",
    lng: "14.2475982",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6625",
  },
  {
    id: "57299",
    map_id: "1",
    address: "Strada Capo Scardicchio 2/F,70132 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1207401",
    lng: "16.7856747",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6636",
  },
  {
    id: "57298",
    map_id: "1",
    address: "Viale Della Rimembranza 95,15067 Novi Ligure (AL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.766407",
    lng: "8.793842999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6643",
  },
  {
    id: "57297",
    map_id: "1",
    address: "Via Giardini 4,09070 Bonarcado (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.098865",
    lng: "8.6550499",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6645",
  },
  {
    id: "57296",
    map_id: "1",
    address: "Via Della Fittaccia 5,50036 BIVIGLIANO (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.896212",
    lng: "11.321173",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6651",
  },
  {
    id: "57295",
    map_id: "1",
    address: "Via Anselmo Pecci 42,85057 Tramutola (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3190806",
    lng: "15.7889416",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6668",
  },
  {
    id: "57294",
    map_id: "1",
    address: "Corso Cavour 11,07026 Modugno (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.081599",
    lng: "16.78091",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6678",
  },
  {
    id: "57293",
    map_id: "1",
    address: "Via Santisi Snc Parco Flora,82019 Sant'Agata de' Goti (BN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.0881786",
    lng: "14.505343",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6692",
  },
  {
    id: "57292",
    map_id: "1",
    address: "Via Antonietta Chigi 44,00040 Ariccia (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7228289",
    lng: "12.6747592",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6693",
  },
  {
    id: "57291",
    map_id: "1",
    address: "Via Appia 135,72100 Brindisi (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6322158",
    lng: "17.9307491",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6704",
  },
  {
    id: "57290",
    map_id: "1",
    address: "Via dei Salici 20,76121 Barletta (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.3078648",
    lng: "16.2637441",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6712",
  },
  {
    id: "57289",
    map_id: "1",
    address: "Via Eusebio Possenti 4/6,00018 Palombara Sabina (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0686711",
    lng: "12.7642176",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6715",
  },
  {
    id: "57288",
    map_id: "1",
    address: "Via Ruffilli 31,82100 Benevento (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.117306",
    lng: "14.796448",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6721",
  },
  {
    id: "57287",
    map_id: "1",
    address: "Via Bovio 59/61,76123 Andria (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2271977",
    lng: "16.2969396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6735",
  },
  {
    id: "57286",
    map_id: "1",
    address: "Piazza Marconi 23,98066 Patti (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1385191",
    lng: "14.9665554",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6738",
  },
  {
    id: "57285",
    map_id: "1",
    address: "Via Santa Lucia 2,88040 Gizzeria (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9822038",
    lng: "16.2096504",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6742",
  },
  {
    id: "57284",
    map_id: "1",
    address: "Via Roma 01,82019 Sant'Agata de' Goti (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0890394",
    lng: "14.5040359",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6743",
  },
  {
    id: "57283",
    map_id: "1",
    address: "Via Simone Martini 50,80129 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.851071",
    lng: "14.2229405",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6745",
  },
  {
    id: "57282",
    map_id: "1",
    address: "via Colamonico, 5,70020 Cassano delle Murge (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8872848",
    lng: "16.7691108",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6755",
  },
  {
    id: "57281",
    map_id: "1",
    address: "Via Amburgo 8/10,04011 Aprilia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.58063689999999",
    lng: "12.6668817",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6765",
  },
  {
    id: "57280",
    map_id: "1",
    address: "PIAZZA VITTORIA 13,83035 Grottaminarda (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0708789",
    lng: "15.0602157",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6778",
  },
  {
    id: "57279",
    map_id: "1",
    address: "Via Giuseppe Garibaldi 74,00018 Palombara Sabina (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0656094",
    lng: "12.7674488",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6790",
  },
  {
    id: "57278",
    map_id: "1",
    address: "Via Del Chianti, 94 Frazione Soiana,56030 Terricciola (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.55211509999999",
    lng: "10.6503099",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6794",
  },
  {
    id: "57277",
    map_id: "1",
    address: "Piazza Matteoti 5,72100 Brindisi (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6384087",
    lng: "17.9454591",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6795",
  },
  {
    id: "57276",
    map_id: "1",
    address: "Via Salerno 111,75025 Policoro (MT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2127869",
    lng: "16.6716157",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6799",
  },
  {
    id: "57275",
    map_id: "1",
    address: "Via Del Casale Agostinelli 158/B,00118 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8164787",
    lng: "12.5991198",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6826",
  },
  {
    id: "57274",
    map_id: "1",
    address: "Via Silio Italico 60/62,80124 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8189878",
    lng: "14.1658559",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6838",
  },
  {
    id: "57273",
    map_id: "1",
    address: "Via Mazzini 130,86100 Campobasso (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5606143",
    lng: "14.6662295",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6845",
  },
  {
    id: "57272",
    map_id: "1",
    address: "Via Nanni 80,07026 Olbia (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9260629",
    lng: "9.5041326",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6850",
  },
  {
    id: "57271",
    map_id: "1",
    address: "Via Cadorna 47,21054 Fagnano Olona (VA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.678102",
    lng: "8.8670805",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6882",
  },
  {
    id: "57270",
    map_id: "1",
    address: "Via Salvatore Manna 98,80126 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8572887",
    lng: "14.1756573",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6888",
  },
  {
    id: "57269",
    map_id: "1",
    address: "Corso D'Andrea 7,82032 Cerreto Sannita (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.284888",
    lng: "14.5599087",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6894",
  },
  {
    id: "57268",
    map_id: "1",
    address: "Via Tito Omboni 3,00147 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8626852",
    lng: "12.4987545",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6907",
  },
  {
    id: "57267",
    map_id: "1",
    address: "Via Quintilio Varo, 54,00174 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8548062",
    lng: "12.5629002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6916",
  },
  {
    id: "57266",
    map_id: "1",
    address: "Via Corrado Mantoni 34,00139 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9689619",
    lng: "12.5352938",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6937",
  },
  {
    id: "57265",
    map_id: "1",
    address: "Via Cecchi 41/R,16121 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.3981144",
    lng: "8.9482317",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6941",
  },
  {
    id: "57264",
    map_id: "1",
    address: "Via Feltrina 264/A,31100 Treviso (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.692325",
    lng: "12.194866",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6942",
  },
  {
    id: "57263",
    map_id: "1",
    address: "Viale Delle Industrie 318,80011 Acerra (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9808591",
    lng: "14.3687549",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "6970",
  },
  {
    id: "57262",
    map_id: "1",
    address: "Viale S. Nilo 97/B,00046 Grottaferrata (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.792387",
    lng: "12.6676024",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7033",
  },
  {
    id: "57261",
    map_id: "1",
    address: "Via Grazia Deledda 14,09095 Mogoro (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6846635",
    lng: "8.7732656",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7059",
  },
  {
    id: "57260",
    map_id: "1",
    address: "Via Della Liberta' 656/B,80010 Villaricca (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9214885",
    lng: "14.1624013",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7070",
  },
  {
    id: "57259",
    map_id: "1",
    address: "Viale Aldo Moro 136,96018 Pachino (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.7197006",
    lng: "15.0933963",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7076",
  },
  {
    id: "57258",
    map_id: "1",
    address: "Viale della Repubblica 74,87067 Rossano (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.599103",
    lng: "16.629048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7079",
  },
  {
    id: "57257",
    map_id: "1",
    address: "Via Nuova Agnano 43,80125 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8198383",
    lng: "14.1747958",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7087",
  },
  {
    id: "57256",
    map_id: "1",
    address: "Via Enrico Ferri 21,00034 Colleferro (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7261917",
    lng: "13.0044438",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7091",
  },
  {
    id: "57255",
    map_id: "1",
    address: "Via Castellinese 160,56034 Chianni (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.478979",
    lng: "10.6404289",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7094",
  },
  {
    id: "57254",
    map_id: "1",
    address: "Via Valdinievole Nord 26,56031 BIENTINA (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7100191",
    lng: "10.6597377",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7095",
  },
  {
    id: "57253",
    map_id: "1",
    address: "Piazza Umberto I 2,86046 San Martino in Pensilis (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8695213",
    lng: "15.0116552",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7103",
  },
  {
    id: "57252",
    map_id: "1",
    address: "Via Monte Castello 2,04010 Sonnino (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.41424",
    lng: "13.241713",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7174",
  },
  {
    id: "57251",
    map_id: "1",
    address: "Vicolo Serracollo snc,94015 Piazza Armerina (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3813398",
    lng: "14.3704394",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7175",
  },
  {
    id: "57250",
    map_id: "1",
    address: "Via Zotone snc,03042 Atina (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.6349721",
    lng: "13.7848343",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7184",
  },
  {
    id: "57249",
    map_id: "1",
    address: "Via De Benedictis 5,81016 PIEDIMONTE MATESE (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.3564563",
    lng: "14.3746971",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7188",
  },
  {
    id: "57248",
    map_id: "1",
    address: "Via Rodi 38,73021 Calimera (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2540802",
    lng: "18.2822897",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7192",
  },
  {
    id: "57247",
    map_id: "1",
    address: "Via P. Borsellino 10,90010 Cerda (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.9010411",
    lng: "13.8166345",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7195",
  },
  {
    id: "57246",
    map_id: "1",
    address: "Via dello Stadio 159,00060 Sacrofano (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1034425",
    lng: "12.4511147",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7200",
  },
  {
    id: "57245",
    map_id: "1",
    address: "Viale Trieste 7,81037 Sessa Aurunca (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.232706",
    lng: "13.9312521",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7202",
  },
  {
    id: "57244",
    map_id: "1",
    address: "Via Cilea 95,80127 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8431533",
    lng: "14.2239667",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7203",
  },
  {
    id: "57243",
    map_id: "1",
    address: "Via Maddalena 34/A,03011 Alatri (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.72108670000001",
    lng: "13.3506705",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7209",
  },
  {
    id: "57242",
    map_id: "1",
    address: "Via Principe Amedeo 25,70054 Giovinazzo (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.18662",
    lng: "16.6706949",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7234",
  },
  {
    id: "57241",
    map_id: "1",
    address: "Via J. Kennedy, 18,90047 Partinico (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.04464919999999",
    lng: "13.1205029",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7256",
  },
  {
    id: "57240",
    map_id: "1",
    address: "Via Borsellino 31,80025 Casandrino (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.93362399999999",
    lng: "14.2464235",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7265",
  },
  {
    id: "57239",
    map_id: "1",
    address: "Corso Mazzini 36,84013 Cava de' Tirreni (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7050898",
    lng: "14.7043394",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7271",
  },
  {
    id: "57238",
    map_id: "1",
    address: "Corso Italia 136,80029 Sant'Antimo (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.932856",
    lng: "14.2335422",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7277",
  },
  {
    id: "57237",
    map_id: "1",
    address: "Via Ficucella 2,81024 Maddaloni (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.02822",
    lng: "14.3749",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7282",
  },
  {
    id: "57236",
    map_id: "1",
    address: "Via Victor Hugo 1/3 A,70056 Molfetta (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1993411",
    lng: "16.585694",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7283",
  },
  {
    id: "57235",
    map_id: "1",
    address: "Via XXIV maggio 80,86170 Isernia (IS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.5984337",
    lng: "14.2349681",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7288",
  },
  {
    id: "57234",
    map_id: "1",
    address: "Via San Gaetano alla Grotta 8,95131 Catania (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.507908",
    lng: "15.0871106",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7315",
  },
  {
    id: "57233",
    map_id: "1",
    address: "Viale Filippo Giampaolo 103,70018 RUTIGLIANO (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0039762",
    lng: "17.0074776",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7316",
  },
  {
    id: "57232",
    map_id: "1",
    address: "Via Giacomo Matteotti 9,51015 Monsummano Terme (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.869243",
    lng: "10.814626",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7317",
  },
  {
    id: "57231",
    map_id: "1",
    address: "Via Popilia 84/D,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3030342",
    lng: "16.2572174",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7343",
  },
  {
    id: "57230",
    map_id: "1",
    address: "Via Consolare, 44,04015 Priverno (La)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.4741294",
    lng: "13.1806264",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7345",
  },
  {
    id: "57229",
    map_id: "1",
    address: "Via Doganella 1,00013 Fonte Nuova (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9980914",
    lng: "12.6224732",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7354",
  },
  {
    id: "57228",
    map_id: "1",
    address: "Corso Ho Chi Min, 35,97013 Comiso (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.95197960000001",
    lng: "14.603201",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7360",
  },
  {
    id: "57227",
    map_id: "1",
    address: "Via Ignazio Lodato 65,84025 Eboli (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.61727",
    lng: "15.054087",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7363",
  },
  {
    id: "57226",
    map_id: "1",
    address: "Via Dei Lauri .,04011 Aprilia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5965372",
    lng: "12.6502416",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7382",
  },
  {
    id: "57225",
    map_id: "1",
    address: "Via Di Mezzo 120,04020 Monte San Biagio (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.3559643",
    lng: "13.2960898",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7383",
  },
  {
    id: "57224",
    map_id: "1",
    address: "Via Cutigliano 16/18,00146 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8491448",
    lng: "12.4590021",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7407",
  },
  {
    id: "57223",
    map_id: "1",
    address: "Via Giacinto Vicinanza, 32,84123 Salerno (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6748837",
    lng: "14.770839",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7457",
  },
  {
    id: "57222",
    map_id: "1",
    address: "Via San F.sco D'assisi, 98,70056 Molfetta (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2019499",
    lng: "16.588599",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7467",
  },
  {
    id: "57221",
    map_id: "1",
    address: "Via Oberdan, 2,80027 Frattamaggiore (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9464449",
    lng: "14.2637149",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7468",
  },
  {
    id: "57220",
    map_id: "1",
    address: "Via Asciano Vitozzi, 72/A,00128 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8387982",
    lng: "12.4527142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7480",
  },
  {
    id: "57219",
    map_id: "1",
    address: "Via Amedeo, 32,88836 Cotronei (CR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.1595532",
    lng: "16.7771433",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7508",
  },
  {
    id: "57218",
    map_id: "1",
    address: "Via Giangiorgio Trissino, 25,00137 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9446934",
    lng: "12.5590583",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7543",
  },
  {
    id: "57217",
    map_id: "1",
    address: "Via Carpani 43 - 45 - 49,57037 Portoferraio (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.8055955",
    lng: "10.3078588",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "7687",
  },
  {
    id: "57216",
    map_id: "1",
    address: "Via Ugo Foscolo, 47,73100 Lecce (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.36251",
    lng: "18.1761",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8039",
  },
  {
    id: "57215",
    map_id: "1",
    address: "Piazza Duomo, 1,70022 Altamura (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8276633",
    lng: "16.5529514",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8332",
  },
  {
    id: "57214",
    map_id: "1",
    address: "Via Roma, 142,03040 Villa Latina (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6201966",
    lng: "13.8364624",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8333",
  },
  {
    id: "57213",
    map_id: "1",
    address: "Via Santa Maria, 283,80010 Quarto (NA)",
    description: "AMAZON HUB SERVICE",
    lat: "40.8789681",
    lng: "14.1503225",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8433",
  },
  {
    id: "57212",
    map_id: "1",
    address: "CNT Ciavolotto, 47/E,91025 Marsala (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.7769524",
    lng: "12.5406662",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8443",
  },
  {
    id: "57211",
    map_id: "1",
    address: "Via Filippo Saporito, 44,81031 Aversa (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9793121",
    lng: "14.2078146",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8456",
  },
  {
    id: "57210",
    map_id: "1",
    address: "Via M. Bianchi 75,89030 Samo (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.07340670000001",
    lng: "16.0629705",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8477",
  },
  {
    id: "57209",
    map_id: "1",
    address: "VIA SANT'ELENA, 11,09048 Sinnai (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.304797",
    lng: "9.2146897",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8492",
  },
  {
    id: "57208",
    map_id: "1",
    address: "Via San Pietro, 64,91026 Mazara del Vallo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6578523",
    lng: "12.5848129",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8499",
  },
  {
    id: "57207",
    map_id: "1",
    address: "Via Trento 59,89013 Gioia Tauro (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.4309692",
    lng: "15.8966442",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8634",
  },
  {
    id: "57206",
    map_id: "1",
    address: "Via Antonio Gramsci, 147,00040 Lanuvio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6769414",
    lng: "12.6908825",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8718",
  },
  {
    id: "57205",
    map_id: "1",
    address: "Via Marconi snc,80053 Castellammare di Stabia (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7002072",
    lng: "14.4858987",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8770",
  },
  {
    id: "57204",
    map_id: "1",
    address: "Viale Fabio Filzi, 49,41049 SASSUOLO (MO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.5374803",
    lng: "10.7913183",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8788",
  },
  {
    id: "57203",
    map_id: "1",
    address: "Via Sant' Egidio, 60,06039 TREVI (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.8766997",
    lng: "12.7376562",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8794",
  },
  {
    id: "57202",
    map_id: "1",
    address: "Via Nazionale, 224,88060 Badolato (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.5773528",
    lng: "16.5656703",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8806",
  },
  {
    id: "57201",
    map_id: "1",
    address: "VIA CILEA, 129/AB,80127 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8429759",
    lng: "14.2228352",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8877",
  },
  {
    id: "57200",
    map_id: "1",
    address: "Via Roma, 101,89853 San Gregorio d'Ippona (VV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.6432413",
    lng: "16.1006884",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8894",
  },
  {
    id: "57199",
    map_id: "1",
    address: "Via Germania Snc,00196 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9341844",
    lng: "12.4734521",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8973",
  },
  {
    id: "57198",
    map_id: "1",
    address: "Piazza Cavour, 2,00060 Castelnuovo di Porto (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1259299",
    lng: "12.50039",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8974",
  },
  {
    id: "57197",
    map_id: "1",
    address: "Via Francesca Ang. P. 23 Agosto, 86,50050 Cerreto Guidi (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7956829",
    lng: "10.8414101",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8988",
  },
  {
    id: "57196",
    map_id: "1",
    address: "Via MolÃ¨, 6,97012 Chiaramonte Gulfi (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0322091",
    lng: "14.703487",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "8999",
  },
  {
    id: "57195",
    map_id: "1",
    address: "Via Circonvallazione Aldo Moro, 42,65017 Penne (PE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.4542679",
    lng: "13.9247475",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9000",
  },
  {
    id: "57194",
    map_id: "1",
    address: "Via Ariodante Fabretti, 83,06123 Perugia (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.1159093",
    lng: "12.3880048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9081",
  },
  {
    id: "57193",
    map_id: "1",
    address: "Via Gaetano Salvemini, 8,70056 Molfetta (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1992572",
    lng: "16.6042101",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9153",
  },
  {
    id: "57192",
    map_id: "1",
    address: "Via Archi, 74,91100 Trapani (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0233131",
    lng: "12.5293966",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9162",
  },
  {
    id: "57191",
    map_id: "1",
    address: "Via Giuseppe Benedetto Dusmet, 7,00168 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.92305",
    lng: "12.418",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9166",
  },
  {
    id: "57190",
    map_id: "1",
    address: "Piazza San Matteo, 6,91025 Marsala (TP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.7997213",
    lng: "12.4382397",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9167",
  },
  {
    id: "57189",
    map_id: "1",
    address: "Via Appia, 1047,04026 Minturno (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.2538837",
    lng: "13.7196883",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9168",
  },
  {
    id: "57188",
    map_id: "1",
    address: "Via Del Ponte a Greve, 12,50018 Scandicci (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7670783",
    lng: "11.191022",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9170",
  },
  {
    id: "57187",
    map_id: "1",
    address: "Via Aosta, 27,10152 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0837549",
    lng: "7.6903497",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9180",
  },
  {
    id: "57186",
    map_id: "1",
    address: "Piazza Umberto I , 21,89014 Oppido Mamertina (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.2888113",
    lng: "15.9845364",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9204",
  },
  {
    id: "57185",
    map_id: "1",
    address: "Via Attilio Calabrese, 37,72028 Torre Santa Susanna (BR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.4642341",
    lng: "17.7426308",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9244",
  },
  {
    id: "57184",
    map_id: "1",
    address: "Via Lucarelli, Snc,81038 Trentola-Ducenta (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9726947",
    lng: "14.1744125",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9269",
  },
  {
    id: "57183",
    map_id: "1",
    address: "Frazione Bagnara, snc,06025 Nocera Umbra (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.1107092",
    lng: "12.8540477",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9313",
  },
  {
    id: "57182",
    map_id: "1",
    address: "VIA DEI VOLSCI 49,00048 Nettuno (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4575578",
    lng: "12.6585517",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9321",
  },
  {
    id: "57181",
    map_id: "1",
    address: "Via IV Novembre, 25,13046 Livorno Ferraris (VC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.2829155",
    lng: "8.0748464",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9329",
  },
  {
    id: "57180",
    map_id: "1",
    address: "Via dell'Aeroporto, 74,00175 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8638242",
    lng: "12.5573675",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9332",
  },
  {
    id: "57179",
    map_id: "1",
    address: "VIA APOLLO XI, 56/58,84025 Eboli (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.616548",
    lng: "15.060735",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9336",
  },
  {
    id: "57178",
    map_id: "1",
    address: "Piazza duomo 13,84096 Montecorvino Rovella (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.699816",
    lng: "14.974813",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9338",
  },
  {
    id: "57177",
    map_id: "1",
    address: "Via Donato Iaia, 5,70014 CONVERSANO (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.963973",
    lng: "17.112627",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9339",
  },
  {
    id: "57176",
    map_id: "1",
    address: "VIA GIUSEPPE MAZZINI, 22,80040 Pollena Trocchia (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8672502",
    lng: "14.3801403",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9341",
  },
  {
    id: "57175",
    map_id: "1",
    address: "Via Roma, 149,73054 PRESICCE (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9017009",
    lng: "18.2624603",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9347",
  },
  {
    id: "57174",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 125,76125 Trani (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.27748769999999",
    lng: "16.41618",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9349",
  },
  {
    id: "57173",
    map_id: "1",
    address: "VIALE SALVO D'ACQUISTO 11,84133 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6618682",
    lng: "14.8118847",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9354",
  },
  {
    id: "57172",
    map_id: "1",
    address: "Via San Leonardo, snc,84020 Colliano (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5110856",
    lng: "15.2067793",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9368",
  },
  {
    id: "57171",
    map_id: "1",
    address: "Via Veglie, 21,73041 Carmiano (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.344394",
    lng: "18.0446234",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9380",
  },
  {
    id: "57170",
    map_id: "1",
    address: "VIA TOSCO ROMAGNOLA, 1442,56021 Cascina (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.684465",
    lng: "10.503749",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9387",
  },
  {
    id: "57169",
    map_id: "1",
    address: "Corso Garibaldi, 183,84091 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6759469",
    lng: "14.7659764",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9405",
  },
  {
    id: "57168",
    map_id: "1",
    address: "Via XXIV Maggio, 35,89034 Bovalino (RC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.147578",
    lng: "16.175182",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9408",
  },
  {
    id: "57167",
    map_id: "1",
    address: "VIA AMEDEO MONTANARO, 2,81042 Rocchetta e Croce (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.20470650000001",
    lng: "14.0767286",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9431",
  },
  {
    id: "57166",
    map_id: "1",
    address: "Viale Cremona, 185,27100 Pavia (PV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.1774874",
    lng: "9.200824299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9433",
  },
  {
    id: "57165",
    map_id: "1",
    address: "Via Il Cerro, 120/A,52033 Caprese Michelangelo (AR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.638492",
    lng: "11.987467",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9434",
  },
  {
    id: "57164",
    map_id: "1",
    address: "Via Aniello Falcone, 104,80121 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8406019",
    lng: "14.2273995",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9438",
  },
  {
    id: "57163",
    map_id: "1",
    address: "Via San Giovanni, sn,73028 Otranto (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.14941570000001",
    lng: "18.484815",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9442",
  },
  {
    id: "57162",
    map_id: "1",
    address: "Corso Mazzini, 146,90020 Castellana Sicula (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.7823373",
    lng: "14.0415322",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9445",
  },
  {
    id: "57161",
    map_id: "1",
    address: "Piazza dei Tigli, 1,83020 FORINO (AV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8595544",
    lng: "14.7404269",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9446",
  },
  {
    id: "57160",
    map_id: "1",
    address: "Via Ottone Fattiboni, 126,00126 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.78720130000001",
    lng: "12.3471499",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9456",
  },
  {
    id: "57159",
    map_id: "1",
    address: "Via Catta Bene, snc,81013 Caiazzo (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.17840830000001",
    lng: "14.3646988",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9457",
  },
  {
    id: "57158",
    map_id: "1",
    address: "VIA GIULIO PALERMO, 41,80131 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8614862",
    lng: "14.2201407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9464",
  },
  {
    id: "57157",
    map_id: "1",
    address: "Via Papa Giovanni XXIII, 51,55022 Bagni di Lucca (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.0055364",
    lng: "10.5573265",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9465",
  },
  {
    id: "57156",
    map_id: "1",
    address: "Via Del Carmine, 23,80142 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8471301",
    lng: "14.2683667",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9466",
  },
  {
    id: "57155",
    map_id: "1",
    address: "Via stazione, 2B,84030 Atena Lucana (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4552249",
    lng: "15.5375637",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9472",
  },
  {
    id: "57154",
    map_id: "1",
    address: "Via G. Boccaccio nÂ° 74,95047 PaternÃ² (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5714302",
    lng: "14.9054161",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9500",
  },
  {
    id: "57153",
    map_id: "1",
    address: "Via Onofrio Tortora, 39,84087 Sarno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.810064",
    lng: "14.6182007",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9535",
  },
  {
    id: "57152",
    map_id: "1",
    address: "Via Pisani, 21,20080 Besate (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.3117486",
    lng: "8.9673327",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9538",
  },
  {
    id: "57151",
    map_id: "1",
    address: "PIAZZA ENRICO DI LEVA, 23,80136 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8554442",
    lng: "14.2372614",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9544",
  },
  {
    id: "57150",
    map_id: "1",
    address: "Via Madre Teresa di Calcutta, 35,87018 San Marco Argentano (CO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6052818",
    lng: "16.2079748",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9545",
  },
  {
    id: "57149",
    map_id: "1",
    address: "Viale Leonardo Da Vinci, 24,80055 Portici (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.8164405",
    lng: "14.3425162",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9560",
  },
  {
    id: "57148",
    map_id: "1",
    address: "VIA BATTISTI, 42,89029 TAURIANUOVA (RC)",
    description: "AMAZON HUB SERVICE",
    lat: "38.35254390000001",
    lng: "16.0093457",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9573",
  },
  {
    id: "57147",
    map_id: "1",
    address: "Via dei garofani, 56,95033 Biancavilla (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.6489049",
    lng: "14.855347",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9582",
  },
  {
    id: "57146",
    map_id: "1",
    address: "VIA Roi, 26,36010 Monticello Conte Otto (VI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6001369",
    lng: "11.56993",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9583",
  },
  {
    id: "57145",
    map_id: "1",
    address: "Via XIX Traversa, 58,95032 Belpasso (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.58995",
    lng: "14.978827",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9584",
  },
  {
    id: "57144",
    map_id: "1",
    address: "VIA GALILEO GALILEI 76,95045 Misterbianco (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5145128",
    lng: "15.0138663",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9586",
  },
  {
    id: "57143",
    map_id: "1",
    address: "Via Rianese, 132,00060 Riano (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1004954",
    lng: "12.5039864",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9611",
  },
  {
    id: "57142",
    map_id: "1",
    address:
      "Via San Giorgio Vecchio, 50 - 52 - 54,80046 San Giorgio a Cremano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.831745",
    lng: "14.343333",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9613",
  },
  {
    id: "57141",
    map_id: "1",
    address: "Via Garibaldi, 21,71034 Castelnuovo della Daunia (FG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.5833997",
    lng: "15.1196142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9624",
  },
  {
    id: "57140",
    map_id: "1",
    address: "Piazza Roma, 131,86021 Bojano (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4826808",
    lng: "14.4727824",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9625",
  },
  {
    id: "57139",
    map_id: "1",
    address: "Via Ferrini, 33/35,20862 Arcore (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6354011",
    lng: "9.334186899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9626",
  },
  {
    id: "57138",
    map_id: "1",
    address: "LARGO CASTELLO, 1,73010 Lequile (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3095297",
    lng: "18.1418439",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9627",
  },
  {
    id: "57137",
    map_id: "1",
    address: "Via Ludovica Albertoni, 80,00152 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8780379",
    lng: "12.444213",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9635",
  },
  {
    id: "57136",
    map_id: "1",
    address: "Via Fiume, 26,70043 Monopoli (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.956369",
    lng: "17.296435",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9636",
  },
  {
    id: "57135",
    map_id: "1",
    address: "Via Decano di Vincenti, 47,90032 Bisacquino (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.701538",
    lng: "13.259757",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9638",
  },
  {
    id: "57134",
    map_id: "1",
    address: "Via Giacomo Matteotti, 18,90015 CefalÃ¹ (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0362868",
    lng: "14.0217081",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9644",
  },
  {
    id: "57133",
    map_id: "1",
    address: "Via Roma, 231,88811 Ciro Marina (KR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.36755429999999",
    lng: "17.1223193",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9645",
  },
  {
    id: "57132",
    map_id: "1",
    address: "Via della LibertÃ , 656/B,80010 Villaricca (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9218295",
    lng: "14.1643311",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9648",
  },
  {
    id: "57131",
    map_id: "1",
    address: "Corso Mediterraneo, 52,97015 Modica (RA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "36.713187",
    lng: "14.7770087",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9649",
  },
  {
    id: "57130",
    map_id: "1",
    address: "Via Roma, 115,83042 Atripalda (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9191242",
    lng: "14.8308051",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9658",
  },
  {
    id: "57129",
    map_id: "1",
    address: "Via Fabio Filzi, 3,84082 Bracigliano (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8231269",
    lng: "14.7092605",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9662",
  },
  {
    id: "57128",
    map_id: "1",
    address: "Via Filippo Mancuso, snc,98055 Lipari (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.4692554",
    lng: "14.9534755",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9668",
  },
  {
    id: "57127",
    map_id: "1",
    address: "Via S.Antonio, 20,80040 Terzigno (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.8098462",
    lng: "14.4884823",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9673",
  },
  {
    id: "57126",
    map_id: "1",
    address: "Via Montebello, 10,15028 Quattordio (AL)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.8987606",
    lng: "8.4063901",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9674",
  },
  {
    id: "57125",
    map_id: "1",
    address: "Via Mazzella, 10,80070 ISCHIA (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.736387",
    lng: "13.94757",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9677",
  },
  {
    id: "57124",
    map_id: "1",
    address: "Via IV Novembre, 2A,80055 Portici (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.82053000000001",
    lng: "14.3443",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9678",
  },
  {
    id: "57123",
    map_id: "1",
    address: "Piazza Matteotti, 3,52043 Castiglion Fiorentino (AR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.3436753",
    lng: "11.9213752",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9679",
  },
  {
    id: "57122",
    map_id: "1",
    address: "Via Matteo Ripa, 83,84091 Battipaglia (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6185054",
    lng: "15.0538535",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9700",
  },
  {
    id: "57121",
    map_id: "1",
    address: "Via Galletti, 139,90121 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0946202",
    lng: "13.4298258",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9702",
  },
  {
    id: "57120",
    map_id: "1",
    address: "Via Madonna di Fatma, 6/8,80057 Sant'Antonio Abate (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.7242868",
    lng: "14.5490442",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9703",
  },
  {
    id: "57119",
    map_id: "1",
    address: "VIA NAZIONALE ANGOLO LAVATROIA 387,80059 Torre del Greco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7757621",
    lng: "14.39324",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9704",
  },
  {
    id: "57118",
    map_id: "1",
    address: "Via Ravanusa, 12,95037 San Giovanni la Punta (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5706149",
    lng: "15.085779",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9705",
  },
  {
    id: "57117",
    map_id: "1",
    address: "Corso Giuseppe Garibaldi, 83,91014 Castellammare del Golfo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0262594",
    lng: "12.8810771",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9714",
  },
  {
    id: "57116",
    map_id: "1",
    address: "Via Andrea Costa, 204,40121 Bologna (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4930397",
    lng: "11.3055629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9717",
  },
  {
    id: "57115",
    map_id: "1",
    address: "Via Nazionale, 499,89025 Rosarno (RC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.479878",
    lng: "15.9668782",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9720",
  },
  {
    id: "57114",
    map_id: "1",
    address: "Via Lucarelli, 28,70124 Bari (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.0931301",
    lng: "16.8611121",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9737",
  },
  {
    id: "57113",
    map_id: "1",
    address: "Via Romaniello, 134,81038 TRENTOLA DUCENTA (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9703865",
    lng: "14.1714544",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9739",
  },
  {
    id: "57112",
    map_id: "1",
    address: "Via Carlo D'Adda, 7,20143 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4475485",
    lng: "9.1606215",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9740",
  },
  {
    id: "57111",
    map_id: "1",
    address: "Via Nazionale, 110,84030 Casaletto Spartano (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1514385",
    lng: "15.6208639",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9748",
  },
  {
    id: "57110",
    map_id: "1",
    address: "Via Fosso del Poggio, 51/B,00189 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9749433",
    lng: "12.4404374",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9754",
  },
  {
    id: "57109",
    map_id: "1",
    address: "Via Taranto,74010 Statte (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5497866",
    lng: "17.2071646",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9755",
  },
  {
    id: "57108",
    map_id: "1",
    address: "Via Lucio Orofino, 29,84127 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6718857",
    lng: "14.7836775",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9757",
  },
  {
    id: "57107",
    map_id: "1",
    address: "Via Cella, 88,48124 Madonna dell'Albero- Ravenna (RA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.3805887",
    lng: "12.2018484",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9763",
  },
  {
    id: "57106",
    map_id: "1",
    address: "Via Gerardo Corrado, 9,84078 VALLO DELLA LUCANIA (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.2206679",
    lng: "15.2721624",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9764",
  },
  {
    id: "57105",
    map_id: "1",
    address: "Via Regina Margherita, 217/A,98028 Santa Teresa di Riva (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.948182",
    lng: "15.37021",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9768",
  },
  {
    id: "57104",
    map_id: "1",
    address: "Via Pozzillo 26,80053 Castellammare di Stabia (NA)",
    description: "AMAZON HUB SERVICE",
    lat: "40.7210267",
    lng: "14.4903414",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9769",
  },
  {
    id: "57103",
    map_id: "1",
    address: "via Santa Caterina d Alessandria 126,89122 Reggio Calabria (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.12244990000001",
    lng: "15.6532343",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9774",
  },
  {
    id: "57102",
    map_id: "1",
    address: "Via degli Ulivi, 185,87020 Santa Maria del Cedro (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.754742",
    lng: "15.811803",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9791",
  },
  {
    id: "57101",
    map_id: "1",
    address: "Via Oliveto Scammacca, 21/B,95121 Catania (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5175575",
    lng: "15.0955564",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9792",
  },
  {
    id: "57100",
    map_id: "1",
    address: "Via Marchiafava, 3,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4694313",
    lng: "12.8955062",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9800",
  },
  {
    id: "57099",
    map_id: "1",
    address: "Via Martiri della Liberazione, 9,20060 Pozzuolo Martesana (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.5135994",
    lng: "9.4537278",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9801",
  },
  {
    id: "57098",
    map_id: "1",
    address: "Via Pinnone, 128/B,96014 Floridia (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.08503839999999",
    lng: "15.1496816",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9807",
  },
  {
    id: "57097",
    map_id: "1",
    address: "Via Leuca, 42 fraz. Castromediano,73020 Cavallino (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3356173",
    lng: "18.178457",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9812",
  },
  {
    id: "57096",
    map_id: "1",
    address: "VIA DELLE CROCI, 9/11,01016 Tarquinia (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.2508615",
    lng: "11.7602725",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9814",
  },
  {
    id: "57095",
    map_id: "1",
    address: "Via Traversa, 1,55045 Pietrasanta (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.96361479999999",
    lng: "10.2053666",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9824",
  },
  {
    id: "57094",
    map_id: "1",
    address: "Via Elena, 12,80020 Casavatore (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8995421",
    lng: "14.271788",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9825",
  },
  {
    id: "57093",
    map_id: "1",
    address: "VIA DELLA REPUBBLICA 24,87060 Crosia (CS)",
    description: "AMAZON HUB SERVICE",
    lat: "39.6023652",
    lng: "16.7708374",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9830",
  },
  {
    id: "57092",
    map_id: "1",
    address: "Via Matteotti, 1G,27010 Borgarello (PV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.2394775",
    lng: "9.142551800000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9831",
  },
  {
    id: "57091",
    map_id: "1",
    address: "Viale Eroi di Rodi, 236,00128 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7838121",
    lng: "12.4356084",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9834",
  },
  {
    id: "57090",
    map_id: "1",
    address: "Largo Pitagora, 6,72015 Fasano (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.83268",
    lng: "17.36199",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9838",
  },
  {
    id: "57089",
    map_id: "1",
    address: "VIA POGGIOMARINO, 52,80040 Striano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8134665",
    lng: "14.5688003",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9844",
  },
  {
    id: "57088",
    map_id: "1",
    address: "Viale Stefano Candura 18g,93100 Caltanissetta (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.4778198",
    lng: "14.0528342",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9845",
  },
  {
    id: "57087",
    map_id: "1",
    address: "Via San Faustino, 3,20134 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4715261",
    lng: "9.2416997",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9846",
  },
  {
    id: "57086",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 198,85058 Vietri di Potenza (PZ)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.5997605",
    lng: "15.5072714",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9852",
  },
  {
    id: "57085",
    map_id: "1",
    address: "Via Ticino, 7,87032 Amantea (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.1255661",
    lng: "16.0789154",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9855",
  },
  {
    id: "57084",
    map_id: "1",
    address: "Corso Magenta, 64,25121 Brescia (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5356552",
    lng: "10.2287368",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9858",
  },
  {
    id: "57083",
    map_id: "1",
    address: "Via Berlinguer, snc,88814 Melissa (KR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.3108228",
    lng: "17.1067149",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9862",
  },
  {
    id: "57082",
    map_id: "1",
    address: "Via Dalmazia, 43,76125 Trani (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.27278",
    lng: "16.42504",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9865",
  },
  {
    id: "57081",
    map_id: "1",
    address: "Viale Della Croce Rossa, 185,67100 L'Aquila (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.3570728",
    lng: "13.3888446",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9867",
  },
  {
    id: "57080",
    map_id: "1",
    address: "Via Meridionale, 11,75014 Grassano (MT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.632494",
    lng: "16.2836294",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9871",
  },
  {
    id: "57079",
    map_id: "1",
    address: "Contrada Piana, 8,82026 Morcone (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.3343646",
    lng: "14.6966435",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9873",
  },
  {
    id: "57078",
    map_id: "1",
    address: "VIA RUDONE, 30,25038 Rovato (BS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.5626651",
    lng: "9.9946615",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9877",
  },
  {
    id: "57077",
    map_id: "1",
    address: "Via Vittorio Emanuele, 18,80013 Casalnuovo di Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.916052",
    lng: "14.352517",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9878",
  },
  {
    id: "57076",
    map_id: "1",
    address: "Via Luigi Cadorna, 41,00028 SUBIACO (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9239095",
    lng: "13.0943083",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9880",
  },
  {
    id: "57075",
    map_id: "1",
    address: "Via della Pace, 1,19038 Sarzana (SP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.1113031",
    lng: "9.957144099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9886",
  },
  {
    id: "57074",
    map_id: "1",
    address: "VIA CARMINE AMATO, 170,84085 Mercato San Severino (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.774762",
    lng: "14.731498",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9890",
  },
  {
    id: "57073",
    map_id: "1",
    address: "Via delle Nespole, 33,00172 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.87838319999999",
    lng: "12.5808681",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9897",
  },
  {
    id: "57072",
    map_id: "1",
    address: "Via Roma, 150/152,95045 Misterbianco (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5179405",
    lng: "15.0086953",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9909",
  },
  {
    id: "57071",
    map_id: "1",
    address: "VIA NICOLA SERRA, 123/L,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3084662",
    lng: "16.2518006",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9913",
  },
  {
    id: "57070",
    map_id: "1",
    address: "Via A. Bafile, 3,67100 L'Aquila (AQ)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.3514869",
    lng: "13.3982618",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9915",
  },
  {
    id: "57069",
    map_id: "1",
    address: "VIA NAZIONALE TERME KM 51.2 SNC,98050 Terme Vigliatore (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1336765",
    lng: "15.1651564",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9916",
  },
  {
    id: "57068",
    map_id: "1",
    address: "Via Umberto I , 11,01030 Castel Sant'Elia (VI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.249682",
    lng: "12.3689949",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9917",
  },
  {
    id: "57067",
    map_id: "1",
    address: "Via Roma, 3,10080 Cuceglio (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.3610882",
    lng: "7.816094299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9922",
  },
  {
    id: "57066",
    map_id: "1",
    address: "Via Grimaldi, 5,89022 Cittanova (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.3528959",
    lng: "16.0820378",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9930",
  },
  {
    id: "57065",
    map_id: "1",
    address: "PIAZZA GAVINANA, 8,51100 Pistoia (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.9319519",
    lng: "10.915297",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9935",
  },
  {
    id: "57064",
    map_id: "1",
    address: "Via Guido De Ruggiero, 41,80031 Brusciano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9165936",
    lng: "14.4260779",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9937",
  },
  {
    id: "57063",
    map_id: "1",
    address: "Via G.Maselli Campagna, 29,70021 Acquaviva delle Fonti (BA)",
    description: "RICARICHE - SPEDIZIONI",
    lat: "40.8969214",
    lng: "16.8455076",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9940",
  },
  {
    id: "57062",
    map_id: "1",
    address: "Via Giuseppe Candiani, 15,00155 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.893109",
    lng: "12.5847146",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9941",
  },
  {
    id: "57061",
    map_id: "1",
    address: "Corso Europa, 38,24020 Scanzorosciate (BG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.7076747",
    lng: "9.730428500000002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9942",
  },
  {
    id: "57060",
    map_id: "1",
    address: "Corso Novara, 58,80143 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8556971",
    lng: "14.2713991",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9945",
  },
  {
    id: "57059",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 27,84010 Minori (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.650572",
    lng: "14.626651",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9951",
  },
  {
    id: "57058",
    map_id: "1",
    address: "Viale Cavour, 9,10034 Chivasso (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.1891638",
    lng: "7.894206199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9952",
  },
  {
    id: "57057",
    map_id: "1",
    address: "Via Duca D'Aosta, snc,64011 Alba Adriatica (TE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.8321974",
    lng: "13.9237837",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9953",
  },
  {
    id: "57056",
    map_id: "1",
    address: "Via Sant Antonino, 7/R,50123 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.77571409999999",
    lng: "11.2509512",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9962",
  },
  {
    id: "57055",
    map_id: "1",
    address: "Viale Pier Luigi Nervi, snc,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.46539629999999",
    lng: "12.8906697",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9968",
  },
  {
    id: "57054",
    map_id: "1",
    address: "Via Sabaudia, 7,04017 San Felice Circeo (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.243143",
    lng: "13.0921299",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9969",
  },
  {
    id: "57053",
    map_id: "1",
    address: "Corso della Repubblica, 317,04012 Cisterna Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5894372",
    lng: "12.8293752",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9970",
  },
  {
    id: "57052",
    map_id: "1",
    address: "Via Cimarosa, 166,80100 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.8427003",
    lng: "14.2287392",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9971",
  },
  {
    id: "57051",
    map_id: "1",
    address: "Via San Gimignano, 90/a b,53036 Poggibonsi (SI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.4678759",
    lng: "11.1388079",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9973",
  },
  {
    id: "57050",
    map_id: "1",
    address: "Via Ugo Foscolo 18,80046 San Giorgio a Cremano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8384501",
    lng: "14.344177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9974",
  },
  {
    id: "57049",
    map_id: "1",
    address: "Via Monte Rosa, 95,10154 Torino (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.0937235",
    lng: "7.697094000000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9975",
  },
  {
    id: "57048",
    map_id: "1",
    address: "STS 106 KM 281, Snc,88811 CIRO'  MARINA (KR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.3676141",
    lng: "17.1277751",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9977",
  },
  {
    id: "57047",
    map_id: "1",
    address: "Piazza Aldo Moro, 10,71030 Mattinata (FG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.70896399999999",
    lng: "16.0513211",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9982",
  },
  {
    id: "57046",
    map_id: "1",
    address: "Via di Casalotti, 59,00166 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.91608970000001",
    lng: "12.3742003",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9986",
  },
  {
    id: "57045",
    map_id: "1",
    address: "Via Gerard Rohlfs, snc,85050 Tito (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.507304",
    lng: "15.6647142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9997",
  },
  {
    id: "57044",
    map_id: "1",
    address: "Via Grigna, snc,20155 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4936664",
    lng: "9.156138",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "9998",
  },
  {
    id: "57043",
    map_id: "1",
    address: "Via Giustiniano Imperatore, 256,00145 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.855055",
    lng: "12.486981",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10012",
  },
  {
    id: "57042",
    map_id: "1",
    address: "Via Giovanni XXIII, 7-11,50059 Vinci (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7849976",
    lng: "10.9253498",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10018",
  },
  {
    id: "57041",
    map_id: "1",
    address: "Via Roma, 28,29010 Pontenure (PC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.9981832",
    lng: "9.7908832",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10024",
  },
  {
    id: "57040",
    map_id: "1",
    address: "Via Enrico Ferri, 8,00173 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.8509698",
    lng: "12.5991978",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10025",
  },
  {
    id: "57039",
    map_id: "1",
    address: "Via Montalbano, 11,51039 Quarrata (PT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.84826109999999",
    lng: "10.9780877",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10026",
  },
  {
    id: "57038",
    map_id: "1",
    address: "prima trav di via res part, 8,97015 Modica (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.8588355",
    lng: "14.7608152",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10029",
  },
  {
    id: "57037",
    map_id: "1",
    address: "Piazza De Ferrari 32a/r,16121 Genova (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.40716580000001",
    lng: "8.9340262",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10031",
  },
  {
    id: "57036",
    map_id: "1",
    address: "Via Sandro Penna, 10,00063 Campagnano di Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.124396",
    lng: "12.369213",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10032",
  },
  {
    id: "57035",
    map_id: "1",
    address: "Viale della Pace 44,94016 Pietraperzia (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.4167335",
    lng: "14.1349519",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10036",
  },
  {
    id: "57034",
    map_id: "1",
    address: "Lgo nella Mortara 3/4,00168 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9247792",
    lng: "12.4134036",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10040",
  },
  {
    id: "57033",
    map_id: "1",
    address: "Via Nazionale, 99/101- Frazione Rocca,98070 Capri Leone (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.11064220000001",
    lng: "14.7117048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10042",
  },
  {
    id: "57032",
    map_id: "1",
    address: "VIA ROMANA, 42/44,00048 Nettuno (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4582111",
    lng: "12.6578569",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10045",
  },
  {
    id: "57031",
    map_id: "1",
    address: "Piazza Italia, 8,27010 Vellezzo Bellini (PV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.2730041",
    lng: "9.1165448",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10046",
  },
  {
    id: "57030",
    map_id: "1",
    address: "Via Principale, 23,27010 Borgarello (PV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.2414366",
    lng: "9.1432545",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10048",
  },
  {
    id: "57029",
    map_id: "1",
    address: "Via Ponti Della Valle 8,81024 Maddaloni (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0553325",
    lng: "14.3988472",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10055",
  },
  {
    id: "57028",
    map_id: "1",
    address: "Via Bonifacio Festaz, 65,11100 Aosta (AO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.7348584",
    lng: "7.317240300000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10059",
  },
  {
    id: "57027",
    map_id: "1",
    address: "CORSO ITALIA, 203/R,17100 Savona (SV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.3059186",
    lng: "8.481558099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10060",
  },
  {
    id: "57026",
    map_id: "1",
    address: "Via Terra, 10,20090 Rodano (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.4765874",
    lng: "9.3544059",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10062",
  },
  {
    id: "57025",
    map_id: "1",
    address: "Viale Monza MM Turro, snc,20856 Correzzana (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.5051727",
    lng: "9.2224968",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10065",
  },
  {
    id: "57024",
    map_id: "1",
    address: "Via Risorgimento, 50,20048 Pantigliate (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.43334600000001",
    lng: "9.352077999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10067",
  },
  {
    id: "57023",
    map_id: "1",
    address: "Corso cavour 27,30029 San Stino di Livenza (VE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6580464",
    lng: "12.8015122",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10068",
  },
  {
    id: "57022",
    map_id: "1",
    address: "Via Marconi, 7,30029 Santo Stino di Livenza (VE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.7313892",
    lng: "12.6807063",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10072",
  },
  {
    id: "57021",
    map_id: "1",
    address: "Piazza Dante, 32,91022 Castalvetrano (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6733895",
    lng: "12.7917328",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10077",
  },
  {
    id: "57020",
    map_id: "1",
    address: "Via Novara, 4,20832 Desio (MB)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.62892189999999",
    lng: "9.204970699999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10081",
  },
  {
    id: "57019",
    map_id: "1",
    address: "Corso Vittorio Emanuele III, 106,90049 Terrasini (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1515141",
    lng: "13.0829177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10083",
  },
  {
    id: "57018",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 45,80122 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8342726",
    lng: "14.219718",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10084",
  },
  {
    id: "57017",
    map_id: "1",
    address: "VIA CALATAFIMI, 8,71016 San Severo (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6897198",
    lng: "15.3818823",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10085",
  },
  {
    id: "57016",
    map_id: "1",
    address: "Via Bicocchi, 107/b,58022 Follonica (GR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.924538",
    lng: "10.75724",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10088",
  },
  {
    id: "57015",
    map_id: "1",
    address: "Via Argine Lupo, 325/h,44012 Stellata di Bondeno (FE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.94837",
    lng: "11.41859",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10093",
  },
  {
    id: "57014",
    map_id: "1",
    address: "Via Roma 61,83020 San Michele di Serino (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.87673849999999",
    lng: "14.855248",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10102",
  },
  {
    id: "57013",
    map_id: "1",
    address: "Cnt San Francesco, snc,87064 Corigliano Calabro (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6276125",
    lng: "16.5112712",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10109",
  },
  {
    id: "57012",
    map_id: "1",
    address: "Via Papa Giovanni XXIII, 32,25013 Carpenedolo (BS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.3584855",
    lng: "10.4266741",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10111",
  },
  {
    id: "57011",
    map_id: "1",
    address: "Via Largo Casiola, 1,84035 Polla (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.5171745",
    lng: "15.4935872",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10114",
  },
  {
    id: "57010",
    map_id: "1",
    address: "Via Bobbio, 264r,16137 Genova (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.4251612",
    lng: "8.9471552",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10117",
  },
  {
    id: "57009",
    map_id: "1",
    address: "Via Operai, 10,98051 BARCELLONA POZZO DIGOTTO (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.145595",
    lng: "15.211587",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10132",
  },
  {
    id: "57008",
    map_id: "1",
    address: "Via S.Aleramo, 35,88900 Crotone (KR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.0618784",
    lng: "17.1157603",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10136",
  },
  {
    id: "57007",
    map_id: "1",
    address: "Corso Savona, 295,14100 Asti (AT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.8884992",
    lng: "8.2112205",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10138",
  },
  {
    id: "57006",
    map_id: "1",
    address: "VIA ASPREA, 7,89131 Reggio Calabria (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.08591",
    lng: "15.67751",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10141",
  },
  {
    id: "57005",
    map_id: "1",
    address: "VIA DEI VESPRI, 6,96100 Siracusa (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0929942",
    lng: "15.2099244",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10150",
  },
  {
    id: "57004",
    map_id: "1",
    address: "Via San Giorgio, 42,10014 Caluso (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.3081253",
    lng: "7.8857781",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10151",
  },
  {
    id: "57003",
    map_id: "1",
    address: "Via Isaac Newton, 100,50018 Scandicci (FI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.76318209999999",
    lng: "11.1610105",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10158",
  },
  {
    id: "57002",
    map_id: "1",
    address: "VIA PIOMBINO, 1 R,16159 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4293672",
    lng: "8.892884",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10161",
  },
  {
    id: "57001",
    map_id: "1",
    address: "Via Comorga, 17,16042 Carasco (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.3440208",
    lng: "9.3496695",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10162",
  },
  {
    id: "57000",
    map_id: "1",
    address: "Via Perugia, 41,76123 Andria (BT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.231523",
    lng: "16.2977988",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10168",
  },
  {
    id: "56999",
    map_id: "1",
    address: "Via nobile, 41,86100 Campobasso (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5572023",
    lng: "14.660786",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10180",
  },
  {
    id: "56998",
    map_id: "1",
    address: "Viale Romagna, 9,20092 Cinisello Balsamo (MI)",
    description: "RICARICHE - SPEDIZIONI",
    lat: "45.5480273",
    lng: "9.2308586",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10194",
  },
  {
    id: "56997",
    map_id: "1",
    address: "Viale Ungheria, 90/d,00039 Zagarolo (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8314139",
    lng: "12.8372142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10195",
  },
  {
    id: "56996",
    map_id: "1",
    address: "Corso XXVII Marzo, 97,27058 Voghera (PV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.9930955",
    lng: "8.9990203",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10198",
  },
  {
    id: "56995",
    map_id: "1",
    address: "Via Stadera, 138,80143 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8747265",
    lng: "14.3058542",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10199",
  },
  {
    id: "56994",
    map_id: "1",
    address: "VIA NAZIONALE 97,87020 Tortora (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9230852",
    lng: "15.7723273",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10200",
  },
  {
    id: "56993",
    map_id: "1",
    address: "Via Roma, 3,72022 Latiano (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.55355369999999",
    lng: "17.71729",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10205",
  },
  {
    id: "56992",
    map_id: "1",
    address: "Piazza Principe di Camporeale, 51,90138 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.11965540000001",
    lng: "13.3394807",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10207",
  },
  {
    id: "56991",
    map_id: "1",
    address: "Via XXV APRILE, 1,90026 Petralia Soprana (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.80962480000001",
    lng: "14.0903133",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10209",
  },
  {
    id: "56990",
    map_id: "1",
    address: "Via Aristide Carabelli, 110,00121 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.7393766",
    lng: "12.2691713",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10215",
  },
  {
    id: "56989",
    map_id: "1",
    address: "Via A.Canova, 65/B,50142 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7800529",
    lng: "11.1925191",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10221",
  },
  {
    id: "56988",
    map_id: "1",
    address: "Via Luccoli, 57 R,16121 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4098525",
    lng: "8.9342317",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10229",
  },
  {
    id: "56987",
    map_id: "1",
    address: "Piazza Regina Margherita, 5,00198 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9140497",
    lng: "12.5056017",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10231",
  },
  {
    id: "56986",
    map_id: "1",
    address: "Via Alessio Nardone, 19,90138 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1213537",
    lng: "13.3440253",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10239",
  },
  {
    id: "56985",
    map_id: "1",
    address: "VIA ALDO MORO, 4,72021 Francavilla Fontana (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5346502",
    lng: "17.5835991",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10244",
  },
  {
    id: "56984",
    map_id: "1",
    address: "Via Rubens, 14,20155 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4672713",
    lng: "9.1438123",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10245",
  },
  {
    id: "56983",
    map_id: "1",
    address: "Via Sarzana, 487,19136 La Spezia (SP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.1174402",
    lng: "9.8673638",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10246",
  },
  {
    id: "56982",
    map_id: "1",
    address: "Via Gramsci, 42,57016 Rosignano Marittimo (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.406807",
    lng: "10.4731089",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10250",
  },
  {
    id: "56981",
    map_id: "1",
    address: "Piazza Indipendenza, 20,33053 Latisana (UD)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.7761777",
    lng: "12.9949217",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10251",
  },
  {
    id: "56980",
    map_id: "1",
    address: "Via Palestro, 92/91,10045 Piossasco (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.98870960000001",
    lng: "7.4614319",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10255",
  },
  {
    id: "56979",
    map_id: "1",
    address: "Via A.Moro, 3,24050 Bariano (BG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.518691",
    lng: "9.705094599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10257",
  },
  {
    id: "56978",
    map_id: "1",
    address: "Viale F.Meda, 30,20017 Rho (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.52513889999999",
    lng: "9.0424233",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10258",
  },
  {
    id: "56977",
    map_id: "1",
    address: "Via Sangiacomo, 204,00048 Nettuno (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.46460990000001",
    lng: "12.6538391",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10259",
  },
  {
    id: "56976",
    map_id: "1",
    address: "VIA GALVANI, 38,15121 Alessandria (AL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.9006",
    lng: "8.62285",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10260",
  },
  {
    id: "56975",
    map_id: "1",
    address: "Piazza schiavone 6,20121 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.5000246",
    lng: "9.164989199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10266",
  },
  {
    id: "56974",
    map_id: "1",
    address: "Via Nuova Poggioreale, 56,80143 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8636467",
    lng: "14.2843418",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10270",
  },
  {
    id: "56973",
    map_id: "1",
    address: "PIAZZA SAN FRANCESCO, 63,51100 Pistoia (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.9357645",
    lng: "10.9129195",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10274",
  },
  {
    id: "56972",
    map_id: "1",
    address: "VIA FELICE CASCIONE, 87,18100 Imperia (IM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.875057",
    lng: "8.012830899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10280",
  },
  {
    id: "56971",
    map_id: "1",
    address: "Via Appia Km. 254,82010 Ceppaloni (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0778211",
    lng: "14.9343562",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10281",
  },
  {
    id: "56970",
    map_id: "1",
    address: "Via Calispera, 42,98125 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1497101",
    lng: "15.5309821",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10283",
  },
  {
    id: "56969",
    map_id: "1",
    address: "Viale europa 92,63854 Santa Vittoria in Matenano (FE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.9919641",
    lng: "13.5105585",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10286",
  },
  {
    id: "56968",
    map_id: "1",
    address: "Via Tommaso Littardi, 242,18100 Imperia (IM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8789349",
    lng: "7.986804999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10287",
  },
  {
    id: "56967",
    map_id: "1",
    address: "Via Donatori del Sangue, 4,58015 Orbetello (GR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.4415536",
    lng: "11.217749",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10288",
  },
  {
    id: "56966",
    map_id: "1",
    address: "Viale XXIV Maggio, 50,10093 Collegno (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0766382",
    lng: "7.5744174",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10292",
  },
  {
    id: "56965",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 34/36,00074 Nemi (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7192038",
    lng: "12.7146006",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10297",
  },
  {
    id: "56964",
    map_id: "1",
    address: "Via Monte della Farina, 58,00186 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.8953758",
    lng: "12.4750236",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10299",
  },
  {
    id: "56963",
    map_id: "1",
    address:
      "VIA DEGLI STUDI ANG. FOSSE ARDEATINE, 2,98051 Barcellona Pozzo di Gotto (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1421956",
    lng: "15.2190063",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10302",
  },
  {
    id: "56962",
    map_id: "1",
    address: "Via Isalle, 75,08029 Siniscola (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.570917",
    lng: "9.6858887",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10308",
  },
  {
    id: "56961",
    map_id: "1",
    address: "Corso Mazzini, snc,90020 Castellana Sicula (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.7829233",
    lng: "14.0407114",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10310",
  },
  {
    id: "56960",
    map_id: "1",
    address: "Via Aldo Moro, 28/30,04015 Priverno (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.475125",
    lng: "13.1730098",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10314",
  },
  {
    id: "56959",
    map_id: "1",
    address: "Via IV Novembre, 3,56040 Monteverdi Marittimo (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.177164",
    lng: "10.7146082",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10316",
  },
  {
    id: "56958",
    map_id: "1",
    address: "Corso Vecchio, 229,05100 Terni (TR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.5647448",
    lng: "12.6516915",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10320",
  },
  {
    id: "56957",
    map_id: "1",
    address: "Via Galileo Ferraris, 30,05100 Terni (TR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.5683017",
    lng: "12.6480223",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10323",
  },
  {
    id: "56956",
    map_id: "1",
    address: "Via XXIV Maggio, 57,89015 Palmi (RC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.3610734",
    lng: "15.8482415",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10324",
  },
  {
    id: "56955",
    map_id: "1",
    address: "Via Pausula, 11,62014 Corridonia (MC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.26071",
    lng: "13.495455",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10335",
  },
  {
    id: "56954",
    map_id: "1",
    address: "Piazza Maestri del Lavoro, 9,20063 Cernusco (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5196041",
    lng: "9.3458492",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10347",
  },
  {
    id: "56953",
    map_id: "1",
    address: "Via Badia, 58,20060 Gessate (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5538",
    lng: "9.435177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10348",
  },
  {
    id: "56952",
    map_id: "1",
    address: "Via Vinzaglio, 17,13100 Vercelli (VC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.3230672",
    lng: "8.431815799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10349",
  },
  {
    id: "56951",
    map_id: "1",
    address: "VIA DE' GRACCHI, 41,72100 Brindisi (BR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.6339012",
    lng: "17.9354796",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10357",
  },
  {
    id: "56950",
    map_id: "1",
    address: "Via XX Settembre, 5,87012 Castrovillari (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.8153201",
    lng: "16.2000671",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10359",
  },
  {
    id: "56949",
    map_id: "1",
    address: "Via Braganelli, 13,82038 Vitulano (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.173958",
    lng: "14.642373",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10360",
  },
  {
    id: "56948",
    map_id: "1",
    address: "Via Garibaldi, 73,98124 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1898017",
    lng: "15.5562964",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10367",
  },
  {
    id: "56947",
    map_id: "1",
    address: "VIA ROMA, 204,92021 Aragona (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3998629",
    lng: "13.6179609",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10368",
  },
  {
    id: "56946",
    map_id: "1",
    address: "VIA TRIESTE, 178,20812 Limbiate (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5927432",
    lng: "9.1412836",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10371",
  },
  {
    id: "56945",
    map_id: "1",
    address: "Via Lazio, 11,80016 Marano di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9007414",
    lng: "14.1943404",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10372",
  },
  {
    id: "56944",
    map_id: "1",
    address: "Via Michelangelo, 1 c/o C. C.le Ginestre,80040 Volla (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8750891",
    lng: "14.3507687",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10373",
  },
  {
    id: "56943",
    map_id: "1",
    address: "Via Roma, 41/A,18039 Ventimiglia (IM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.7895378",
    lng: "7.61163",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10374",
  },
  {
    id: "56942",
    map_id: "1",
    address: "Via dello Stadio, 22,97016 Pozzallo (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.7315419",
    lng: "14.8367186",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10376",
  },
  {
    id: "56941",
    map_id: "1",
    address: "Via Albert Einstein, 20,73040 Aradeo (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1304045",
    lng: "18.1347445",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10377",
  },
  {
    id: "56940",
    map_id: "1",
    address: "Viale IV Novembre, 260,88045 GIMIGLIANO (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9733481",
    lng: "16.5313524",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10384",
  },
  {
    id: "56939",
    map_id: "1",
    address: "VIA PISACANE, 28/R,16129 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4006067",
    lng: "8.9510129",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10392",
  },
  {
    id: "56938",
    map_id: "1",
    address: "Via Giovanni Fiordellisi, 16,83020 Sirignano (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.9477632",
    lng: "14.6312795",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10396",
  },
  {
    id: "56937",
    map_id: "1",
    address: "VIA FAGNANI, 12,21040 Gerenzano (VA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.6391683",
    lng: "9.0001894",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10397",
  },
  {
    id: "56936",
    map_id: "1",
    address: "Via Della Cellulosa, 48,00166 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9210922",
    lng: "12.3651587",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10403",
  },
  {
    id: "56935",
    map_id: "1",
    address: "Via Dante, 9,96012 Avola (SR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "36.9106524",
    lng: "15.1375868",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10404",
  },
  {
    id: "56934",
    map_id: "1",
    address: "Via Giuseppe Grassa, 19,91026 Mazara del Vallo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.663347",
    lng: "12.589165",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10406",
  },
  {
    id: "56933",
    map_id: "1",
    address: "Via Emanuele De Deo, 13,80121 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8412357",
    lng: "14.2477804",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10409",
  },
  {
    id: "56932",
    map_id: "1",
    address: "Via Galvani, 20,73039 Tricase (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9362983",
    lng: "18.3532954",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10418",
  },
  {
    id: "56931",
    map_id: "1",
    address: "Via Ciurini, 107,50051 Castelfiorentino (FI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.582038",
    lng: "11.002073",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10420",
  },
  {
    id: "56930",
    map_id: "1",
    address: "Via Kennedy, 12,73019 Trepuzzi (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.404782",
    lng: "18.071396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10421",
  },
  {
    id: "56929",
    map_id: "1",
    address: "Via Nazareth, 30,35128 Padova (PD)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.40000879999999",
    lng: "11.8952343",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10427",
  },
  {
    id: "56928",
    map_id: "1",
    address: "Piazza Vittorio Veneto 3,89042 Gioiosa Ionica (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.3362453",
    lng: "16.3026417",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10435",
  },
  {
    id: "56927",
    map_id: "1",
    address: "Via Giulio Antamoro, 47,00139 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9589872",
    lng: "12.5445558",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10436",
  },
  {
    id: "56926",
    map_id: "1",
    address: "Piazza Garibaldi, 13-14,02046 Magliano Sabina (RI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.3612617",
    lng: "12.4814039",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10438",
  },
  {
    id: "56925",
    map_id: "1",
    address: "Corso Carlo Alberto, 2,23900 LECCO (LC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.8452255",
    lng: "9.3994089",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10440",
  },
  {
    id: "56924",
    map_id: "1",
    address: "Via Offanengo, 21/23,00188 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9974909",
    lng: "12.4849885",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10451",
  },
  {
    id: "56923",
    map_id: "1",
    address: "Via CiriÃ¨ 45,10071 Borgaro Torinese (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.154177",
    lng: "7.656670399999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10452",
  },
  {
    id: "56922",
    map_id: "1",
    address: "Via Porcella, 85,09098 Terralba (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.720494",
    lng: "8.6368353",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10453",
  },
  {
    id: "56921",
    map_id: "1",
    address: "VIALE  MARIO RAPISARDI, 599,95123 Catania (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.5080581",
    lng: "15.0540339",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10454",
  },
  {
    id: "56920",
    map_id: "1",
    address: "Via Stabia, 71,80057 Castellammare di Stabia (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7193298",
    lng: "14.5397521",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10459",
  },
  {
    id: "56919",
    map_id: "1",
    address: "Largo Roma, 10,56031 BIENTINA (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.71096850000001",
    lng: "10.6196463",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10463",
  },
  {
    id: "56918",
    map_id: "1",
    address: "Via Dei Babbi, 4,55012 Capannori (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8404606",
    lng: "10.5732296",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10467",
  },
  {
    id: "56917",
    map_id: "1",
    address: "VIA ANTONELLO DA MESSINA, 38,98049 Villafranca Tirrena (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.2429559",
    lng: "15.4473363",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10471",
  },
  {
    id: "56916",
    map_id: "1",
    address: "Piazza San Rocco, 29,95015 Linguaglossa (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.8406716",
    lng: "15.1402341",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10477",
  },
  {
    id: "56915",
    map_id: "1",
    address: "Viale Eugenio IV, 79,01019 Vetralla (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "42.32284569999999",
    lng: "12.0521906",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10483",
  },
  {
    id: "56914",
    map_id: "1",
    address: "Via Asilo Infantile, 8,82010 Moiano (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0682212",
    lng: "14.5331315",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10485",
  },
  {
    id: "56913",
    map_id: "1",
    address: "Via Patrioti, 27,21058 Solbiate Olona (VA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.65132149999999",
    lng: "8.884571099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10490",
  },
  {
    id: "56912",
    map_id: "1",
    address: "Via Nazionale Torrette, snc,83013 Mercogliano (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9074087",
    lng: "14.7549354",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10493",
  },
  {
    id: "56911",
    map_id: "1",
    address: "PIAZZA CENTOFONTANE, 2/4,87060 Crosia (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6085658",
    lng: "16.7787689",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10496",
  },
  {
    id: "56910",
    map_id: "1",
    address: "Via di Palo Laziale, snc,00055 Ladispoli (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9451653",
    lng: "12.0878951",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10497",
  },
  {
    id: "56909",
    map_id: "1",
    address: "Corso Romita, 14,15121 Alessandria (AL)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.902186",
    lng: "8.6178852",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10504",
  },
  {
    id: "56908",
    map_id: "1",
    address: "Via Aldo Moro 42,00065 FIANO ROMANO (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1687742",
    lng: "12.5875659",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10505",
  },
  {
    id: "56907",
    map_id: "1",
    address: "Via Pietrarse, 1,80078 Pozzuoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8551369",
    lng: "14.1261351",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10507",
  },
  {
    id: "56906",
    map_id: "1",
    address: "Piazza Marconi 3,01019 Vetralla (VT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.3188861",
    lng: "12.0587267",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10509",
  },
  {
    id: "56905",
    map_id: "1",
    address: "Via Pienza, 66,85100 Potenza (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.64474999999999",
    lng: "15.79557",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10510",
  },
  {
    id: "56904",
    map_id: "1",
    address: "Via Garibaldi, 58,84015 Nocera Superiore (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7501447",
    lng: "14.6800451",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10513",
  },
  {
    id: "56903",
    map_id: "1",
    address: "Via Giambattista Vico, 1,86100 Campobasso (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.55638",
    lng: "14.66403",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10516",
  },
  {
    id: "56902",
    map_id: "1",
    address: "VIA MESAGNE, 5,72026 San Pancrazio Salentino (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4183871",
    lng: "17.8405412",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10517",
  },
  {
    id: "56901",
    map_id: "1",
    address: "Via Gorizia 85,36013 Piovene Rocchette (VI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.753929",
    lng: "11.432136",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10518",
  },
  {
    id: "56900",
    map_id: "1",
    address: "Piazza Vittorio Emanuele II, 29,01038 SORIANO NEL CIMINO (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.4183658",
    lng: "12.2341076",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10522",
  },
  {
    id: "56899",
    map_id: "1",
    address: "VIA ROMA, 27,24060 TELGATE (BG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.626537",
    lng: "9.8552565",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10524",
  },
  {
    id: "56898",
    map_id: "1",
    address: "Via Piave, 23,56035 Casciana Terme Lari (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.5733419",
    lng: "10.614705",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10525",
  },
  {
    id: "56897",
    map_id: "1",
    address: "Via Giorgio Iannicelli, 84,00135 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.9773308",
    lng: "12.4125154",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10670",
  },
  {
    id: "56896",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI 4,14019 VILLANOVA D'ASTI (AT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.9418289",
    lng: "7.9404244",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10671",
  },
  {
    id: "56895",
    map_id: "1",
    address: "Piazza Fellini, 12,00065 FIANO ROMANO (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.16863499999999",
    lng: "12.59393",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10672",
  },
  {
    id: "56894",
    map_id: "1",
    address: "Corso Vercelli, 68/b,10155 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0904441",
    lng: "7.689138400000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10675",
  },
  {
    id: "56893",
    map_id: "1",
    address: "Via Paolo Borsellino, 54,80025 Casandrino (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9330339",
    lng: "14.2472093",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10680",
  },
  {
    id: "56892",
    map_id: "1",
    address: "Via Vincenzo Scala, 15,80128 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8498721",
    lng: "14.2237618",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10682",
  },
  {
    id: "56891",
    map_id: "1",
    address: "Via S.Michele, 1,00067 Morlupo (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.1449474",
    lng: "12.4918506",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10683",
  },
  {
    id: "56890",
    map_id: "1",
    address: "Localita' Acquari SS 398,57028 Suvereto (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.0646667",
    lng: "10.6652364",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10686",
  },
  {
    id: "56889",
    map_id: "1",
    address: "Via Panoramica dello Stretto, 1020,98168 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.22707690000001",
    lng: "15.5665134",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10691",
  },
  {
    id: "56888",
    map_id: "1",
    address: "Via L. M. Greco, 5/c,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3015295",
    lng: "16.2533351",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10693",
  },
  {
    id: "56887",
    map_id: "1",
    address: "VIA GUERRIERI, 9,72026 San Pancrazio Salentino (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4178384",
    lng: "17.8391639",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10697",
  },
  {
    id: "56886",
    map_id: "1",
    address: "Via della Consolazione, 58,73045 Leverano (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.29237",
    lng: "17.998447",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10698",
  },
  {
    id: "56885",
    map_id: "1",
    address: "VIA GIULIO TOGNI, 18,25122 Brescia (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.53354299999999",
    lng: "10.2091629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10781",
  },
  {
    id: "56884",
    map_id: "1",
    address: "Via Discesa Poerio, 8,88100 Catanzaro (CZ)",
    description: "AMAZON HUB SERVICE",
    lat: "38.9091625",
    lng: "16.5890053",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10785",
  },
  {
    id: "56883",
    map_id: "1",
    address: "VIA CARROCCIO, 33,20020 Busto Garolfo (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.5500669",
    lng: "8.8821578",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10828",
  },
  {
    id: "56882",
    map_id: "1",
    address: "Corso Vittorio Veneto, 22,07026 Olbia (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.9236542",
    lng: "9.4957357",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10830",
  },
  {
    id: "56881",
    map_id: "1",
    address: "Via della Vittoria, 45,92100 Agrigento (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3057612",
    lng: "13.5940464",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10834",
  },
  {
    id: "56880",
    map_id: "1",
    address: "Via Fiume, 100/A,95032 Belpasso (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5976042",
    lng: "14.9812359",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10835",
  },
  {
    id: "56879",
    map_id: "1",
    address: "Via Antonio Bembo, 34,36024 Nanto (VI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.42675",
    lng: "11.59458",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10836",
  },
  {
    id: "56878",
    map_id: "1",
    address: "VIA DEI COLLI, 5,19121 La Spezia (SP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.1067954",
    lng: "9.825425",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10837",
  },
  {
    id: "56877",
    map_id: "1",
    address: "Via Gianturco, 50,19121 La Spezia (SP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.1133392",
    lng: "9.840831",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10838",
  },
  {
    id: "56876",
    map_id: "1",
    address: "Via Mar Ionio, 35,84098 Litoranea di Pontecagnano (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.61322320000001",
    lng: "14.8538506",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10839",
  },
  {
    id: "56875",
    map_id: "1",
    address: "Piazza Cornelia, 5,00166 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.9061743",
    lng: "12.3941362",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10844",
  },
  {
    id: "56874",
    map_id: "1",
    address: "VIA OMODEO, 40,80128 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.84859549999999",
    lng: "14.2206256",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10890",
  },
  {
    id: "56873",
    map_id: "1",
    address: "VIA STRADA NAZIONALE 112 H,61022 Vallefoglia (PU)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.8313544",
    lng: "12.7454142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10893",
  },
  {
    id: "56872",
    map_id: "1",
    address: "VIA GUASCO, 19/A,15060 FRANCAVILLA BISIO (AL)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.73264",
    lng: "8.73396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10896",
  },
  {
    id: "56871",
    map_id: "1",
    address: "Via V. Emanuele III Secondigliano, 72,80144 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8935546",
    lng: "14.2626043",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10897",
  },
  {
    id: "56870",
    map_id: "1",
    address: "Via Spotorno, 59/c,10126 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0295219",
    lng: "7.667050199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10899",
  },
  {
    id: "56869",
    map_id: "1",
    address: "VIA CESARE BATTISTI, 31,46037 Roncoferraro (MN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.1351191",
    lng: "10.9523075",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10900",
  },
  {
    id: "56868",
    map_id: "1",
    address: "Via Casilina Km 62.975, 75,03012 Anagni (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.535323",
    lng: "13.6496581",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10901",
  },
  {
    id: "56867",
    map_id: "1",
    address: "Viale Umberto I, 7,92028 Naro (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.50232099999999",
    lng: "13.26419",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10906",
  },
  {
    id: "56866",
    map_id: "1",
    address: "VIA GIOVANNI FALCONE 2,00012 Guidonia Montecelio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.989568",
    lng: "12.718899",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10908",
  },
  {
    id: "56865",
    map_id: "1",
    address: "VIA LIBERTA', 8/A,24050 Zanica (BG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6392526",
    lng: "9.6842977",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10914",
  },
  {
    id: "56864",
    map_id: "1",
    address: "VIA EUGENIO SICILIANO 22,84014 Nocera Inferiore (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7420721",
    lng: "14.6466284",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10916",
  },
  {
    id: "56863",
    map_id: "1",
    address: "CRV GIANICOLENSE, 128,00152 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.8707199",
    lng: "12.4550419",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10919",
  },
  {
    id: "56862",
    map_id: "1",
    address: "VIA LUIGI GRECO, 47,00018 Palombara Sabina (RO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.0751058",
    lng: "12.7034068",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10920",
  },
  {
    id: "56861",
    map_id: "1",
    address: "Via G.B. Vico, 20,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4688826",
    lng: "12.9097903",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10983",
  },
  {
    id: "56860",
    map_id: "1",
    address: "VIA MICELI, 39,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3007172",
    lng: "16.2511486",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10986",
  },
  {
    id: "56859",
    map_id: "1",
    address: "Via Vivirito, 45,90010 Cerda (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.9080802",
    lng: "13.8105379",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10990",
  },
  {
    id: "56858",
    map_id: "1",
    address: "Viale umberto I , 48,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4639461",
    lng: "12.9052809",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10991",
  },
  {
    id: "56857",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE III 219,80027 Frattamaggiore (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9457113",
    lng: "14.271973",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10992",
  },
  {
    id: "56856",
    map_id: "1",
    address: "Via Modena, 12,51039 Quarrata (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.8580723",
    lng: "10.9712268",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10995",
  },
  {
    id: "56855",
    map_id: "1",
    address: "Strada Statale 80 Km. 2300,67100 L'Aquila (AQ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.3850877",
    lng: "13.3378665",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "10997",
  },
  {
    id: "56854",
    map_id: "1",
    address: "Via Luigi Praino, 3,87011 CASSANO ALLO IONIO (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.7845628",
    lng: "16.3191786",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11007",
  },
  {
    id: "56853",
    map_id: "1",
    address: "Via Filippo Strozzi, 139,59100 Prato (PO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8878067",
    lng: "11.0907956",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11008",
  },
  {
    id: "56852",
    map_id: "1",
    address: "VIA GARIBALDI, 58/T,25080 Nuvolento (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5447497",
    lng: "10.3893691",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11009",
  },
  {
    id: "56851",
    map_id: "1",
    address: "Via Risorgimento, 89/A,92013 Menfi (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.604144",
    lng: "12.9669106",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11011",
  },
  {
    id: "56850",
    map_id: "1",
    address: "Via Gramsci 54,08029 Siniscola (NU)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.5744019",
    lng: "9.6919746",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11028",
  },
  {
    id: "56849",
    map_id: "1",
    address: "Via Telesini, 76,82036 Solopaca (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.194225",
    lng: "14.554668",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11034",
  },
  {
    id: "56848",
    map_id: "1",
    address: "Piazza Barone di Donato, 4,83020 Cesinali (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8992863",
    lng: "14.8253961",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11036",
  },
  {
    id: "56847",
    map_id: "1",
    address: "Via Provinciale, 23,98065 Montalbano Elicona (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0236707",
    lng: "15.0136128",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11044",
  },
  {
    id: "56846",
    map_id: "1",
    address: "Via San Pio X 95 R.,16147 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.39924999999999",
    lng: "8.975679999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11063",
  },
  {
    id: "56845",
    map_id: "1",
    address: "Via esterna limiti 9,85050 Baragiano (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.6947322",
    lng: "15.5721347",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11067",
  },
  {
    id: "56844",
    map_id: "1",
    address: "Corso Sardegna, 267/R,16142 Genova (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.412038",
    lng: "8.9556445",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11068",
  },
  {
    id: "56843",
    map_id: "1",
    address: "Via Giovanni Amendola, 40/i,07100 Sassari (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.720701",
    lng: "8.556951",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11091",
  },
  {
    id: "56842",
    map_id: "1",
    address: "VIA GLI ORTI, 1,56035 Casciana Terme Lari (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.56543749999999",
    lng: "10.591666",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11093",
  },
  {
    id: "56841",
    map_id: "1",
    address: "Via Montrone, 33,70010 Capurso (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0416673",
    lng: "16.8833651",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11096",
  },
  {
    id: "56840",
    map_id: "1",
    address: "VIA STAZIONE, 15,10019 STRAMBINO (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.3826294",
    lng: "7.8872152",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11097",
  },
  {
    id: "56839",
    map_id: "1",
    address: "Via Piemonte 9,15057 TORTONA (AL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.90593699999999",
    lng: "8.869183",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11099",
  },
  {
    id: "56838",
    map_id: "1",
    address: "VIA ZANARINI, 33,40010 Sala Bolognese (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.5793904",
    lng: "11.2379134",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11101",
  },
  {
    id: "56837",
    map_id: "1",
    address: "Mercato a 1 zona 2,71013 San Giovanni Rotondo (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.70659",
    lng: "15.7291788",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11105",
  },
  {
    id: "56836",
    map_id: "1",
    address: "VIALE MARCONI, 104,51016 Montecatini-Terme (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8819354",
    lng: "10.7857924",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11115",
  },
  {
    id: "56835",
    map_id: "1",
    address: "Corso Paolo Agliata, 72,90027 Petralia Sottana (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.8066874",
    lng: "14.0927432",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11121",
  },
  {
    id: "56834",
    map_id: "1",
    address: "CORSO ALBISIO, 121,93012 Gela (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0690122",
    lng: "14.2422573",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11123",
  },
  {
    id: "56833",
    map_id: "1",
    address: "Via Nazionale Solicchiata, 62,95012 Castiglione di Sicilia (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.8649971",
    lng: "15.0732086",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11138",
  },
  {
    id: "56832",
    map_id: "1",
    address: "Piazza Matteotti, snc (Atrio Stazione),17031 Albenga (SV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.0473777",
    lng: "8.2214001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11141",
  },
  {
    id: "56831",
    map_id: "1",
    address: "VIA CESARE BATTISTI, 2,22070 Locate Varesino (CO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.693908",
    lng: "8.9271592",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11142",
  },
  {
    id: "56830",
    map_id: "1",
    address: "Via G.Marconi, 90/Q,87036 Rende (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.354025",
    lng: "16.23391",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11144",
  },
  {
    id: "56829",
    map_id: "1",
    address: "Viale delle porcellane, 10,80131 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8679958",
    lng: "14.235095",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11146",
  },
  {
    id: "56828",
    map_id: "1",
    address: "Via De Gasperi, 63,70020 Toritto (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9980233",
    lng: "16.6782164",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11147",
  },
  {
    id: "56827",
    map_id: "1",
    address: "Cassia Cimina Km 22.800,01037 Ronciglione (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.2840695",
    lng: "12.2211533",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11149",
  },
  {
    id: "56826",
    map_id: "1",
    address: "Via Aldo Moro, 13,89040 Sant'Ilario dello Ionio (RC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.19833240000001",
    lng: "16.2252057",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11150",
  },
  {
    id: "56825",
    map_id: "1",
    address: "CORSO FRANCIA, 123/G,10093 Collegno (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.0726292",
    lng: "7.579867699999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11158",
  },
  {
    id: "56824",
    map_id: "1",
    address: "Via aversa 2,00177 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.89100670000001",
    lng: "12.542142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11159",
  },
  {
    id: "56823",
    map_id: "1",
    address: "CORSO UMBERTO I, 34,00068 Rignano Flaminio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.2088932",
    lng: "12.4819096",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11162",
  },
  {
    id: "56822",
    map_id: "1",
    address: "VIA FOGGIA, 31,70121 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.113925",
    lng: "16.8623554",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11168",
  },
  {
    id: "56821",
    map_id: "1",
    address: "VIA MATTEOTTI, 26,31021 Mogliano Veneto (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.561289",
    lng: "12.2335784",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11170",
  },
  {
    id: "56820",
    map_id: "1",
    address: "Via Tuscolana, 269/A,00181 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.8772994",
    lng: "12.52541",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11174",
  },
  {
    id: "56819",
    map_id: "1",
    address: "VIA CASILINA SUD, 10,03038 Roccasecca (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5340271",
    lng: "13.6522856",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11176",
  },
  {
    id: "56818",
    map_id: "1",
    address: "VIA ALFREDO CASELLA, 15,90121 Palermo (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1279183",
    lng: "13.3406372",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11180",
  },
  {
    id: "56817",
    map_id: "1",
    address: "Via della Chiesa, 3 Frazione Contignano,53040 Radicofani (SI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.97663",
    lng: "11.742117",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11181",
  },
  {
    id: "56816",
    map_id: "1",
    address: "CONTRADA GIOVANNARIA, SNC,94011 Agira (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6005279",
    lng: "14.5422225",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11182",
  },
  {
    id: "56815",
    map_id: "1",
    address: "VIA NOTAR GANCI, 4,92017 Sambuca di Sicilia (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6481901",
    lng: "13.1113506",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11188",
  },
  {
    id: "56814",
    map_id: "1",
    address: "Piazza Cesare Beccaria, 2,50121 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7702445",
    lng: "11.2714067",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11191",
  },
  {
    id: "56813",
    map_id: "1",
    address: "VIA MARIO CAUDANA 22,10090 Castiglione Torinese (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.1203482",
    lng: "7.8157017",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11201",
  },
  {
    id: "56812",
    map_id: "1",
    address: "PIAZZA MARTIRI DELLA LIBERTA' 1,13043 CIGLIANO (VC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.3100094",
    lng: "8.021065799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11202",
  },
  {
    id: "56811",
    map_id: "1",
    address: "VIA SEBASTIANO BIANCO 36,10080 SAN BENIGNO CANAVESE (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.2247417",
    lng: "7.789912299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11203",
  },
  {
    id: "56810",
    map_id: "1",
    address: "VIA CORNIGLIANO 113,16152 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4158811",
    lng: "8.8706616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11204",
  },
  {
    id: "56809",
    map_id: "1",
    address: "VIA CECCANO, 6,81100 Caserta (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.0748778",
    lng: "14.3379616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11211",
  },
  {
    id: "56808",
    map_id: "1",
    address: "VIALE ANTONIO GRAMSCI,41122 Modena (MO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.6587051",
    lng: "10.9380657",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11212",
  },
  {
    id: "56807",
    map_id: "1",
    address: "Corso Garibaldi, 177,91014 Castellammare del Golfo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "38.0239787",
    lng: "12.881089",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11213",
  },
  {
    id: "56806",
    map_id: "1",
    address: "Via Antonio Manganario, 76,84135 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.68552",
    lng: "14.77003",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11216",
  },
  {
    id: "56805",
    map_id: "1",
    address: "Via Ugo Bassi, 65,98122 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1845095",
    lng: "15.5569778",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11219",
  },
  {
    id: "56804",
    map_id: "1",
    address: "VIA GENOVA, SNC,92018 Santa Margherita di Belice (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.690109",
    lng: "13.018742",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11220",
  },
  {
    id: "56803",
    map_id: "1",
    address: "VIA DELLA RESISTENZA, 11/D,87100 Castrolibero (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.3061588",
    lng: "16.2236463",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11222",
  },
  {
    id: "56802",
    map_id: "1",
    address: "Via XXIX Aprile, 33,36015 Schio (VI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.7169027",
    lng: "11.3616339",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11239",
  },
  {
    id: "56801",
    map_id: "1",
    address: "Contrada Targia Ex S.S. 114, snc,96100 Siracusa (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.1041767",
    lng: "15.2560427",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11242",
  },
  {
    id: "56800",
    map_id: "1",
    address: "PIAZZA GIRANI, 8/9,21010 Montegrino Valtravaglia (VA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.97322",
    lng: "8.76888",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11249",
  },
  {
    id: "56799",
    map_id: "1",
    address: "Via San Rocco, 32,66050 San Salvo (CH)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0540064",
    lng: "14.7342845",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11251",
  },
  {
    id: "56798",
    map_id: "1",
    address: "LARGO EMILIO LUSSI SNC,08020 SAN TEODORO (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.7711558",
    lng: "9.672078599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11263",
  },
  {
    id: "56797",
    map_id: "1",
    address: "VIA TRIESTE 238,87046 Montalto Uffugo (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.3851752",
    lng: "16.2351373",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11271",
  },
  {
    id: "56796",
    map_id: "1",
    address: "Via Galeno, 28,87067 Corigliano-Rossano (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.5948201",
    lng: "16.6378227",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11273",
  },
  {
    id: "56795",
    map_id: "1",
    address: "Via acquaviva 63,81100 Caserta (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.0564158",
    lng: "14.3331078",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11276",
  },
  {
    id: "56794",
    map_id: "1",
    address: "VIA RAVE GROSSE, 4,03045 Esperia (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.3841211",
    lng: "13.6841823",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11277",
  },
  {
    id: "56793",
    map_id: "1",
    address: "VIA ANTONIO GRAMSCI, 120,56035 Perignano (PI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.605118",
    lng: "10.590294",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11281",
  },
  {
    id: "56792",
    map_id: "1",
    address: "Via Vittorio Veneto, 12,73010 Guagnano (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.400735",
    lng: "17.952828",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11282",
  },
  {
    id: "56791",
    map_id: "1",
    address: "VIA CAVOUR, 66,96013 Carlentini (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.27352",
    lng: "15.016529",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11285",
  },
  {
    id: "56790",
    map_id: "1",
    address: "VIA VITTORIO VENETO, 17,20024 Garbagnate Milanese (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.5728738",
    lng: "9.077986899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11296",
  },
  {
    id: "56789",
    map_id: "1",
    address: "Corso Umberto I, 47,71017 Orta Nova (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.3292208",
    lng: "15.7061091",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11299",
  },
  {
    id: "56788",
    map_id: "1",
    address: "VIA UMBERTO I, 45,90013 Castelbuono (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.9313552",
    lng: "14.0878039",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11300",
  },
  {
    id: "56787",
    map_id: "1",
    address: "VIA DEI MERCANTI, 39,87028 Praia a Mare (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9038321",
    lng: "15.7811096",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11302",
  },
  {
    id: "56786",
    map_id: "1",
    address: "VIA MUNICIPIO, 125,81020 Recale (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.0547802",
    lng: "14.2993464",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11303",
  },
  {
    id: "56785",
    map_id: "1",
    address: "Via Mario Pagano, 25,86035 Termoli (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9996527",
    lng: "14.9944448",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11305",
  },
  {
    id: "56784",
    map_id: "1",
    address: "via Appia Nuova, 594,00017 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.86962339999999",
    lng: "12.5287515",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11307",
  },
  {
    id: "56783",
    map_id: "1",
    address: "Via Napoli, 11,81024 Maddaloni (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0354984",
    lng: "14.3804123",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11308",
  },
  {
    id: "56782",
    map_id: "1",
    address: "Via Vittorio Veneto, 73/A,87134 Reggio Calabria (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1159637",
    lng: "15.6515705",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11310",
  },
  {
    id: "56781",
    map_id: "1",
    address: "VIA ANAGNI, 45,00171 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8901431",
    lng: "12.5568513",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11312",
  },
  {
    id: "56780",
    map_id: "1",
    address: "VIA ROMA, 57,56025 Pontedera (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.66267089999999",
    lng: "10.6346564",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11314",
  },
  {
    id: "56779",
    map_id: "1",
    address: "VIA MILITE IGNOTO, 6,17021 Alassio (SV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.0058882",
    lng: "8.1705193",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11322",
  },
  {
    id: "56778",
    map_id: "1",
    address: "VIA ROMA, 2,94010 CALASCIBETTA (EN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.5895073",
    lng: "14.2708661",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11324",
  },
  {
    id: "56777",
    map_id: "1",
    address: "Via Terminio, 4/a,83028 Serino (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8607527",
    lng: "14.8600794",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11325",
  },
  {
    id: "56776",
    map_id: "1",
    address: "Piazzale della Stazione del Lido, 24/a,00122 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.73296999999999",
    lng: "12.283",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11328",
  },
  {
    id: "56775",
    map_id: "1",
    address: "Via Principe Amedeo, 46,24040 Verdellino (BG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.6049661",
    lng: "9.6152353",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11329",
  },
  {
    id: "56774",
    map_id: "1",
    address: "VIA CONCILIO VATICANO II, 84,70026 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0868616",
    lng: "16.7809487",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11330",
  },
  {
    id: "56773",
    map_id: "1",
    address: "VLE TOGLIATTI, 78,50019 Sesto Fiorentino (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8354431",
    lng: "11.1853956",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11331",
  },
  {
    id: "56772",
    map_id: "1",
    address: "VIA NUOVA, 7,81030 Castel Volturno (CE)",
    description: "AMAZON HUB SERVICE - BOLLETTINI - PROMO130",
    lat: "41.034849",
    lng: "13.9428327",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11332",
  },
  {
    id: "56771",
    map_id: "1",
    address: "LARGO CAPPUCCINI, SNC,81037 Sessa Aurunca (CE)",
    description: "AMAZON HUB SERVICE - BOLLETTINI - PROMO130",
    lat: "41.2383257",
    lng: "13.9325868",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11333",
  },
  {
    id: "56770",
    map_id: "1",
    address: "Via Giuseppe Orsi, 57,80128 Napoli (NA)",
    description: "AMAZON HUB SERVICE - BOLLETTINI - PROMO130",
    lat: "40.8515402",
    lng: "14.2322338",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11334",
  },
  {
    id: "56769",
    map_id: "1",
    address: "Piazza Trento n. 33,38016 MEZZOCORONA (TN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "46.2069153",
    lng: "11.1275746",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11335",
  },
  {
    id: "56768",
    map_id: "1",
    address: "Strada provinciale, 25,25057 Sale Marasino (BS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.7069126",
    lng: "10.109789",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11336",
  },
  {
    id: "56767",
    map_id: "1",
    address: "Via Olanda, 41,93012 Gela (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0727464",
    lng: "14.2315551",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11345",
  },
  {
    id: "56766",
    map_id: "1",
    address: "VIA LORETO 1,32100 Belluno (BL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "46.1394133",
    lng: "12.213325",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11347",
  },
  {
    id: "56765",
    map_id: "1",
    address: "VIA SARZANESE SUD, 1451,55054 Massarosa (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8572909",
    lng: "10.3514854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11348",
  },
  {
    id: "56764",
    map_id: "1",
    address: "Via Domiziana, 240,81034 Mondragone (CE)",
    description: "AMAZON HUB SERVICE - BOLLETTINI - PROMO130",
    lat: "41.11216530000001",
    lng: "13.8843814",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11349",
  },
  {
    id: "56763",
    map_id: "1",
    address: "VIA PAPA GIOVANNI PAOLO II, 48,90044 Carini (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1583989",
    lng: "13.1772339",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11350",
  },
  {
    id: "56762",
    map_id: "1",
    address: "Via dei Sanniti, 42,82018 San Giorgio del Sannio (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0747188",
    lng: "14.8546262",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11352",
  },
  {
    id: "56761",
    map_id: "1",
    address: "VIALE BOCCETTA, 67/68,98121 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1971812",
    lng: "15.5565862",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11355",
  },
  {
    id: "56760",
    map_id: "1",
    address: "Viale Capitan Casella, 5/a,00122 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.7298531",
    lng: "12.2897553",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11356",
  },
  {
    id: "56759",
    map_id: "1",
    address: "VIA MILANO, 4,76123 Andria (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.2302656",
    lng: "16.2988923",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11366",
  },
  {
    id: "56758",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI, SNC,01030 Vallerano (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.3816036",
    lng: "12.2572762",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11379",
  },
  {
    id: "56757",
    map_id: "1",
    address: "Via Concordato snc.  SNC,89015 Palmi (RE)",
    description: "AMAZON HUB SERVICE",
    lat: "38.3630367",
    lng: "15.853518",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11381",
  },
  {
    id: "56756",
    map_id: "1",
    address: "Via Michele Berrino, 33/B,10078 Venaria Reale (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.117774",
    lng: "7.652677700000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11383",
  },
  {
    id: "56755",
    map_id: "1",
    address: "Via Umberto I, 4,94014 Nicosia (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.7468132",
    lng: "14.3943751",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11384",
  },
  {
    id: "56754",
    map_id: "1",
    address:
      "VIA IV NOVEMBRE PARCO QUADRIFOGLIO SNC,81038 TRENTOLA DUCENTA (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9759506",
    lng: "14.1634989",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11385",
  },
  {
    id: "56753",
    map_id: "1",
    address: "MARCONI, 93,75100 Matera (MT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6752096",
    lng: "16.5995357",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11387",
  },
  {
    id: "56752",
    map_id: "1",
    address: "CORSO LAZIO 6/D,03029 Veroli (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.6719718",
    lng: "13.4737399",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11388",
  },
  {
    id: "56751",
    map_id: "1",
    address: "PIAZZA SEI MARTIRI DELLA LIBERTA', 28,97019 Vittoria (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.9449402",
    lng: "14.5334686",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11389",
  },
  {
    id: "56750",
    map_id: "1",
    address: "VIA ROMA, 58,97017 Santa Croce Camerina (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.8266089",
    lng: "14.5244586",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11391",
  },
  {
    id: "56749",
    map_id: "1",
    address: "PIAZZA DUOMO, 10,97012 Chiaramonte Gulfi (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0310945",
    lng: "14.7030506",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11392",
  },
  {
    id: "56748",
    map_id: "1",
    address: "CROCIFISSO, 41,84012 Angri (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7416648",
    lng: "14.5675459",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11413",
  },
  {
    id: "56747",
    map_id: "1",
    address: "VIA A. ROSSI, 14,80045 Pompei (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7505497",
    lng: "14.5021305",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11414",
  },
  {
    id: "56746",
    map_id: "1",
    address: "VIA DOSSETTI, SNC,84018 Scafati (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.756355",
    lng: "14.533501",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11416",
  },
  {
    id: "56745",
    map_id: "1",
    address: "PIAZZA MAZZINI, 1,83031 Ariano Irpino (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1574",
    lng: "15.0945143",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11417",
  },
  {
    id: "56744",
    map_id: "1",
    address: "Corso della Resistenza, 12,80011 Acerra (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9436316",
    lng: "14.3694258",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11418",
  },
  {
    id: "56743",
    map_id: "1",
    address: "VIA G. DELLA ROCCA,80041 BOSCOREALE (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7713927",
    lng: "14.4757526",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11419",
  },
  {
    id: "56742",
    map_id: "1",
    address: "Piazza Garibaldi, 4,80047 San Giuseppe Vesuviano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8347746",
    lng: "14.5046787",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11420",
  },
  {
    id: "56741",
    map_id: "1",
    address: "VIA BENEVENTO, 112,80013 Casalnuovo di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.92187879999999",
    lng: "14.3546429",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11421",
  },
  {
    id: "56740",
    map_id: "1",
    address: "Via Roma, 127,80044 Ottaviano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8511587",
    lng: "14.4805122",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11422",
  },
  {
    id: "56739",
    map_id: "1",
    address: "Corso Vittorio Veneto, 276,83035 Grottaminarda (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.070739",
    lng: "15.0600697",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11423",
  },
  {
    id: "56738",
    map_id: "1",
    address: "Via Manzoni, 27,80040 Poggiomarino (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7978274",
    lng: "14.5401595",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11425",
  },
  {
    id: "56737",
    map_id: "1",
    address: "Corso Ettore Padovano, 120,84016 PAGANI (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.741463",
    lng: "14.615273",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11426",
  },
  {
    id: "56736",
    map_id: "1",
    address: "Via Michelangelo Schipa, 168/170,80122 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8352549",
    lng: "14.2204069",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11427",
  },
  {
    id: "56735",
    map_id: "1",
    address: "Via Roma, 134,61032 Fano (PU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8351766",
    lng: "13.0045728",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11431",
  },
  {
    id: "56734",
    map_id: "1",
    address: "PIAZZA PARROCO NATALE, 15,81033 Casal di Principe (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.01072360000001",
    lng: "14.1277305",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11432",
  },
  {
    id: "56733",
    map_id: "1",
    address: "PIAZZA PADRE PIO 19,71010 San Paolo di Civitate (FG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8161154",
    lng: "15.1643407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11434",
  },
  {
    id: "56732",
    map_id: "1",
    address: "Via Umbria, 4/6,58015 Orbetello (GR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "42.5058617",
    lng: "11.2077304",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11437",
  },
  {
    id: "56731",
    map_id: "1",
    address: "Via Roma, 82,20024 Garbagnate Milanese (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.577092",
    lng: "9.072728",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11442",
  },
  {
    id: "56730",
    map_id: "1",
    address: "VIA ROMA 59,45010 Pettorazza Grimani (RO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.1360848",
    lng: "11.9868479",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11449",
  },
  {
    id: "56729",
    map_id: "1",
    address: "Via Luigi Capuana, 13,72013 CEGLIE MESSAPICA (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6457139",
    lng: "17.5116015",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11450",
  },
  {
    id: "56728",
    map_id: "1",
    address: "Via Anfiteatro, 100,81011 Alife (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.3285173",
    lng: "14.3294127",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11455",
  },
  {
    id: "56727",
    map_id: "1",
    address: "VIA DEI CASTAGNI, 52/60,01039 VIGNANELLO (VT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.3805768",
    lng: "12.2771785",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11456",
  },
  {
    id: "56726",
    map_id: "1",
    address: "Piazza Roma, 10,82030 Melizzano (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1603833",
    lng: "14.5053564",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11458",
  },
  {
    id: "56725",
    map_id: "1",
    address: "Via Mattine, 150,84043 Agropoli (SA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.3892631",
    lng: "15.0266254",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11460",
  },
  {
    id: "56724",
    map_id: "1",
    address: "via Giuseppe Mazzini, 93,92019 Sciacca (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.5142449",
    lng: "13.0820624",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11461",
  },
  {
    id: "56723",
    map_id: "1",
    address: "VIA GIOVANNI XXIII, 5,84025 Eboli (SA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.6171503",
    lng: "15.0507804",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11470",
  },
  {
    id: "56722",
    map_id: "1",
    address: "FONTANELLE, 161,87064 Corigliano-Rossano (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6276429",
    lng: "16.5071519",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11471",
  },
  {
    id: "56721",
    map_id: "1",
    address: "PIAZZA EUROPA, 2,25030 MACLODIO (BS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.4765848",
    lng: "10.0441983",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11475",
  },
  {
    id: "56720",
    map_id: "1",
    address: "Piazza dei Sofisti, 2,96016 Lentini (SR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.2890435",
    lng: "14.9997187",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11476",
  },
  {
    id: "56719",
    map_id: "1",
    address: "CORSO GARIBALDI, 607,89127 Reggio Calabria (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.10251",
    lng: "15.637",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11478",
  },
  {
    id: "56718",
    map_id: "1",
    address: "VIALE BOCCIONI, 16C,89123 Reggio Calabria (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1199147",
    lng: "15.651887",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11479",
  },
  {
    id: "56717",
    map_id: "1",
    address: "Via Badia, 32/34,20060 Gessate (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5558196",
    lng: "9.43487",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11485",
  },
  {
    id: "56716",
    map_id: "1",
    address: "Via Ettore Spalenza, 9,25038 Rovato (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5643047",
    lng: "10.0004011",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11486",
  },
  {
    id: "56715",
    map_id: "1",
    address: "Piazza Santa Croce, 3 -  Fraz. Rodallo,10014 Caluso (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.2809129",
    lng: "7.875675799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11487",
  },
  {
    id: "56714",
    map_id: "1",
    address: "VIA DELLA REGIONE , 382,95037 San Giovanni la Punta (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5836734",
    lng: "15.0955125",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11489",
  },
  {
    id: "56713",
    map_id: "1",
    address: "CORSO ARA DI GIOVE, 104,95030 Pedara (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6170795",
    lng: "15.0594202",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11490",
  },
  {
    id: "56712",
    map_id: "1",
    address: "Via Antonio Gramsci, 114,95030 Gravina di Catania (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5614761",
    lng: "15.068376",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11491",
  },
  {
    id: "56711",
    map_id: "1",
    address: "VIA CAPPELLA, 1900,55047 Seravezza (LU)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.018327",
    lng: "10.2295137",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11492",
  },
  {
    id: "56710",
    map_id: "1",
    address: "VIA MARRA, 102,80041 BOSCOREALE (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.7846528",
    lng: "14.5476431",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11493",
  },
  {
    id: "56709",
    map_id: "1",
    address: "Via Adamoli, 521 H,16165 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.44873750000001",
    lng: "8.9926804",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11495",
  },
  {
    id: "56708",
    map_id: "1",
    address: "VIA SAN GIOVANNI BOSCO, 91,88068 Soverato (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "38.687012",
    lng: "16.552674",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11496",
  },
  {
    id: "56707",
    map_id: "1",
    address: "VIA LUIGI TESTORE 15,15121 Alessandria (AL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.9087488",
    lng: "8.623745999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11503",
  },
  {
    id: "56706",
    map_id: "1",
    address: "G. MATTEOTTI, 107,03023 Ceccano (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5677767",
    lng: "13.3275743",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11510",
  },
  {
    id: "56705",
    map_id: "1",
    address: "Via Lando Conti, 11,50027 Greve in Chianti (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.637423",
    lng: "11.3138144",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11512",
  },
  {
    id: "56704",
    map_id: "1",
    address: "VIA PIO LA TORRE, 17,70056 Molfetta (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.1973609",
    lng: "16.59551",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11514",
  },
  {
    id: "56703",
    map_id: "1",
    address: "VIA MILANO, 68,16126 Genova (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.4098259",
    lng: "8.907492699999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11515",
  },
  {
    id: "56702",
    map_id: "1",
    address: "PIAZZA ANTONIO BELLU SNC,09129 Cagliari (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2156673",
    lng: "9.126693099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11516",
  },
  {
    id: "56701",
    map_id: "1",
    address: "Via Ferrovia, 53,83020 Sperone (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9585684",
    lng: "14.6013793",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11518",
  },
  {
    id: "56700",
    map_id: "1",
    address: "VIA G. DI VITTORIO , 62,70033 Corato (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.152152",
    lng: "16.415606",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11522",
  },
  {
    id: "56699",
    map_id: "1",
    address: "Via G.Bovio, 37,70038 Terlizzi (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1301402",
    lng: "16.5389149",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11523",
  },
  {
    id: "56698",
    map_id: "1",
    address: "VIA ALESSANDRO MANZONI, 244,06135 Perugia (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.0917175",
    lng: "12.4423631",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11526",
  },
  {
    id: "56697",
    map_id: "1",
    address: "Via Appia, 89,85056 Ruoti (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7190214",
    lng: "15.679597",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11527",
  },
  {
    id: "56696",
    map_id: "1",
    address: "VIA CIRCONVALLAZIONE SERA, 9,25023 Gottolengo (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.2912933",
    lng: "10.2679492",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11529",
  },
  {
    id: "56695",
    map_id: "1",
    address: "VIA DETTORI, 3,07026 Olbia (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9221008",
    lng: "9.4997378",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11530",
  },
  {
    id: "56694",
    map_id: "1",
    address: "Via Sterpulino 1,56121 Pisa (PI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.6889867",
    lng: "10.4293464",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11531",
  },
  {
    id: "56693",
    map_id: "1",
    address: "Via Lega Lombarda, 33,00166 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9092914",
    lng: "12.522289",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11532",
  },
  {
    id: "56692",
    map_id: "1",
    address: "Piazza Galileo Galilei, 21,00031 ARTENA (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.7417696",
    lng: "12.9129606",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11533",
  },
  {
    id: "56691",
    map_id: "1",
    address: "Corso Garibaldi, 8,50056 Montelupo Fiorentino (FI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.7333821",
    lng: "11.0209149",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11534",
  },
  {
    id: "56690",
    map_id: "1",
    address: "Via del Torraccio di Torrenova, 214,00133 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8732596",
    lng: "12.6197607",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11536",
  },
  {
    id: "56689",
    map_id: "1",
    address: "VIA FERDINANDO I D'ARAGONA, 138/140,76121 Barletta (BT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.3175627",
    lng: "16.2894035",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11540",
  },
  {
    id: "56688",
    map_id: "1",
    address: "Via Dante Alighieri, 1,02032 Fara in Sabina (RI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.1642395",
    lng: "12.7198512",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11541",
  },
  {
    id: "56687",
    map_id: "1",
    address: "VIA VALDIRIVO, 42/C,34132 Trieste (TS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6527701",
    lng: "13.7760776",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11543",
  },
  {
    id: "56686",
    map_id: "1",
    address: "Via Dei Rabatta, 2/c,34170 Gorizia (GO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.9419636",
    lng: "13.6253045",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11544",
  },
  {
    id: "56685",
    map_id: "1",
    address: "CORSO EUROPA, 5,80016 Marano di Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.898785",
    lng: "14.1967059",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11547",
  },
  {
    id: "56684",
    map_id: "1",
    address: "VIA LUCREZIA DELLA VALLE, 19,88100 Catanzaro (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.8851933",
    lng: "16.5977131",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11552",
  },
  {
    id: "56683",
    map_id: "1",
    address: "PIAZZALE STAZIONE GENOVA, 2,20144 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4538318",
    lng: "9.170162",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11553",
  },
  {
    id: "56682",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI SNC,87036 Rende (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3502552",
    lng: "16.2424696",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11554",
  },
  {
    id: "56681",
    map_id: "1",
    address: "Via cicerone 92,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.4694476",
    lng: "12.9136824",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11555",
  },
  {
    id: "56680",
    map_id: "1",
    address: "Via Annella di Massimo, 46,80128 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.846634",
    lng: "14.2264765",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11558",
  },
  {
    id: "56679",
    map_id: "1",
    address: "Via Messina, 103/ Via Trieste, 4,94100 Enna (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5655185",
    lng: "14.272066",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11563",
  },
  {
    id: "56678",
    map_id: "1",
    address: "Corso Carducci, 99,89048 Siderno (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.2693662",
    lng: "16.2983085",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11567",
  },
  {
    id: "56677",
    map_id: "1",
    address: "CORSO MATTEOTTI, 23,81025 Marcianise (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.03583070000001",
    lng: "14.290088",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11569",
  },
  {
    id: "56676",
    map_id: "1",
    address: "VIA CARRARA SANTA MARIA, 4,76011 Bisceglie (BT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.2312842",
    lng: "16.4970103",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11570",
  },
  {
    id: "56675",
    map_id: "1",
    address: "PIAZZA GIOVANNI BOVIO, 32,76013 Minervino Murge (BT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.086853",
    lng: "16.079235",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11571",
  },
  {
    id: "56674",
    map_id: "1",
    address: "VIA DUCA DEGLI ABRUZZI, 12,76016 Margherita di Savoia (BT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.3740949",
    lng: "16.150661",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11572",
  },
  {
    id: "56673",
    map_id: "1",
    address: "PIAZZA GIUSEPPE GARIBALDI, 63,70020 Cassano delle Murge (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8886642",
    lng: "16.7690567",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11573",
  },
  {
    id: "56672",
    map_id: "1",
    address: "Via John Fitzgerald Kennedy, 75,76012 Canosa di Puglia (BT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.2206735",
    lng: "16.068511",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11574",
  },
  {
    id: "56671",
    map_id: "1",
    address: "Via Bari, 42/44,70024 Gravina di Puglia (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8175106",
    lng: "16.425641",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11576",
  },
  {
    id: "56670",
    map_id: "1",
    address: "Via A. Ricciardi, 50,01030 Vallerano (VT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.38373190000001",
    lng: "12.2669136",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11579",
  },
  {
    id: "56669",
    map_id: "1",
    address: "PIAZZA ITALIA, 24,00071 Pomezia (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.621826",
    lng: "12.4632492",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11584",
  },
  {
    id: "56668",
    map_id: "1",
    address: "VIA FULVIO TESTI, 1/D,55032 CASTELNUOVO DI GARFAGNANA (LU)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.109824",
    lng: "10.411028",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11586",
  },
  {
    id: "56667",
    map_id: "1",
    address: "VIA CURZIO RUFO, 22/24,00174 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8572806",
    lng: "12.5601862",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11587",
  },
  {
    id: "56666",
    map_id: "1",
    address: "VIA CONIUGI DANIELE ROMASI, 5,73034 GAGLIANO DEL CAPO (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.8442661",
    lng: "18.3725359",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11588",
  },
  {
    id: "56665",
    map_id: "1",
    address: "VIA TUSCOLANA, 695,00173 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8328579",
    lng: "12.612163",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11589",
  },
  {
    id: "56664",
    map_id: "1",
    address: "Via San Severo, 10,71121 Foggia (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.4670032",
    lng: "15.5417131",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11600",
  },
  {
    id: "56663",
    map_id: "1",
    address: "Strada Genova, 216/B,10024 Moncalieri (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.986774",
    lng: "7.727924",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11601",
  },
  {
    id: "56662",
    map_id: "1",
    address: "VIA CONCORDATO 113/A,89015 Palmi (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "38.3642855",
    lng: "15.8551286",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11603",
  },
  {
    id: "56661",
    map_id: "1",
    address: "VIA IMPERO, 163,73052 Parabita (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.047663",
    lng: "18.12487",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11604",
  },
  {
    id: "56660",
    map_id: "1",
    address: "E.ROTA, 11C,31100 Treviso (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.674639",
    lng: "12.2560572",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11605",
  },
  {
    id: "56659",
    map_id: "1",
    address: "CORSO UMBERTO I, 219/C,75012 Bernalda (MT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.41177",
    lng: "16.690183",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11607",
  },
  {
    id: "56658",
    map_id: "1",
    address: "Viale Sabotino, 2,20141 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4508204",
    lng: "9.1972209",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11608",
  },
  {
    id: "56657",
    map_id: "1",
    address: "Via Franco Ferrini, 4,53035 Monteriggioni (SI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.34438919999999",
    lng: "11.2857555",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11610",
  },
  {
    id: "56656",
    map_id: "1",
    address: "Via Monte Sabotino, 5/a,71121 Foggia (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4638826",
    lng: "15.555371",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11611",
  },
  {
    id: "56655",
    map_id: "1",
    address: "Via Giacomo Carissimi, 55,50145 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.7918397",
    lng: "11.2223177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11612",
  },
  {
    id: "56654",
    map_id: "1",
    address: "VIA PRATESE, 64,50145 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.7997735",
    lng: "11.1936373",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11613",
  },
  {
    id: "56653",
    map_id: "1",
    address: "Via Santo Stefano, 68,50145 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.8215758",
    lng: "11.1360858",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11614",
  },
  {
    id: "56652",
    map_id: "1",
    address: "Via Matteotti, 46,40018 SAN PIETRO IN CASALE (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.69989409999999",
    lng: "11.403674",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11616",
  },
  {
    id: "56651",
    map_id: "1",
    address: "VIA ROMA 23,06030 GIANO DELL'UMBRIA (PG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.8703207",
    lng: "12.558901",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11617",
  },
  {
    id: "56650",
    map_id: "1",
    address: "Via Giuseppe Tropeano, 37,80131 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8626957",
    lng: "14.2191728",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11618",
  },
  {
    id: "56649",
    map_id: "1",
    address: "Via Nazzareno Cosentini, 19,82100 Benevento (BN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.1225716",
    lng: "14.7734196",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11619",
  },
  {
    id: "56648",
    map_id: "1",
    address: "PIAZZA IPPOLITO SAN GIORGIO, 14,10090 SAN GIORGIO CANAVESE (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.335938",
    lng: "7.7953859",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11621",
  },
  {
    id: "56647",
    map_id: "1",
    address: "VIA CONTI, 16,34121 Trieste (TS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6456467",
    lng: "13.786021",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11624",
  },
  {
    id: "56646",
    map_id: "1",
    address: "CORSO INDIPENDENZA, 72,97011 Acate (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0255697",
    lng: "14.4942354",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11626",
  },
  {
    id: "56645",
    map_id: "1",
    address: "Giuseppe Massarenti, 458/B,40138 Bologna (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.49328939999999",
    lng: "11.3864041",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11629",
  },
  {
    id: "56644",
    map_id: "1",
    address: "Viale XX Settembre, 43,95128 Catania (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5142946",
    lng: "15.0903311",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11631",
  },
  {
    id: "56643",
    map_id: "1",
    address: "Piazza Luigi Sodo, 3,82032 Cerreto Sannita (BN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.28181",
    lng: "14.5560156",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11635",
  },
  {
    id: "56642",
    map_id: "1",
    address: "C.SO ATELLANO 105,81030 Sant'Arpino (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.952873",
    lng: "14.2486107",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11636",
  },
  {
    id: "56641",
    map_id: "1",
    address: "P.LE DELLA CONCORDIA, 2,30175 Venezia (VE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4729115",
    lng: "12.2248154",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11637",
  },
  {
    id: "56640",
    map_id: "1",
    address: "Via Anguillarese, 69,00123 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.0384144",
    lng: "12.3093618",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11639",
  },
  {
    id: "56639",
    map_id: "1",
    address: "Via Regina, 43,22015 Gravedona ed Uniti (CO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "46.145236",
    lng: "9.3011134",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11640",
  },
  {
    id: "56638",
    map_id: "1",
    address: "Via Nazionale, snc,87019 Spezzano Albanese (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6671313",
    lng: "16.3110543",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11642",
  },
  {
    id: "56637",
    map_id: "1",
    address: "VIA ARCANGELO SEPE, SNC,83054 Sant'Angelo dei Lombardi (AV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9300418",
    lng: "15.1798484",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11643",
  },
  {
    id: "56636",
    map_id: "1",
    address: "Via S.Francesco D'Assisi, 54,84081 Baronissi (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7470134",
    lng: "14.772335",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11644",
  },
  {
    id: "56635",
    map_id: "1",
    address: "VIA BRUNO MADERNA 2,20138 Milano (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.4554823",
    lng: "9.243968299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11645",
  },
  {
    id: "56634",
    map_id: "1",
    address: "CORSO GIULIO CESARE, 13,10152 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.07974129999999",
    lng: "7.685251900000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11646",
  },
  {
    id: "56633",
    map_id: "1",
    address: "VIA GIULIO CESARE, 41,74028 Sava (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4041763",
    lng: "17.556252",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11650",
  },
  {
    id: "56632",
    map_id: "1",
    address: "VIA TUDERTE, 282,05035 Narni (TR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.5361875",
    lng: "12.5204554",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11651",
  },
  {
    id: "56631",
    map_id: "1",
    address: "Via Nazionale, 65,83013 Mercogliano (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.906809",
    lng: "14.7606704",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11658",
  },
  {
    id: "56630",
    map_id: "1",
    address: "Piazza Ippolito Nievo, 22,00153 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.880518",
    lng: "12.466602",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11660",
  },
  {
    id: "56629",
    map_id: "1",
    address: "VIA GRANDE UFFICIALE DOMENICO OCONE, 13,82030 PONTE (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2147513",
    lng: "14.6950234",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11661",
  },
  {
    id: "56628",
    map_id: "1",
    address: "Via Dei Colombi, 55,00169 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.869471",
    lng: "12.5932648",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11663",
  },
  {
    id: "56627",
    map_id: "1",
    address: "Via Pistoiese, 800,59100 Prato (PO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.89846379999999",
    lng: "11.053772",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11665",
  },
  {
    id: "56626",
    map_id: "1",
    address: "VIA XXV APRILE, 41,26029 SONCINO (CR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.3998133",
    lng: "9.8663242",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11666",
  },
  {
    id: "56625",
    map_id: "1",
    address: "VIALE NINO BIXIO 69,31100 Treviso (TV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6666945",
    lng: "12.2553264",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11667",
  },
  {
    id: "56624",
    map_id: "1",
    address: "Via Del Pino, 3,47923 Rimini (RN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.0491012",
    lng: "12.5565539",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11668",
  },
  {
    id: "56623",
    map_id: "1",
    address: "VIA ROMA, 1/3,12020 VOTTIGNASCO (CN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.5634939",
    lng: "7.5788726",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11680",
  },
  {
    id: "56622",
    map_id: "1",
    address: "VIA VINCENZO GIUFFRIDA, 40,95128 Catania (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5182632",
    lng: "15.0917007",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11681",
  },
  {
    id: "56621",
    map_id: "1",
    address: "CORSO EUROPA, 359/R.,16132 Genova (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.403652",
    lng: "8.982588000000002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11682",
  },
  {
    id: "56620",
    map_id: "1",
    address: "via Silvio Morando, 17,14100 Asti (AT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.9049148",
    lng: "8.1904989",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11683",
  },
  {
    id: "56619",
    map_id: "1",
    address: "Via Casale, snc,82020 Pesco Sannita (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2157325",
    lng: "14.8056745",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11685",
  },
  {
    id: "56618",
    map_id: "1",
    address: "STRADA STATALE 10 -  KM.14,400 , snc,10023 Chieri (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.008885",
    lng: "7.827555100000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11687",
  },
  {
    id: "56617",
    map_id: "1",
    address: "VIA DEI FAGGI, 104,00172 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8840571",
    lng: "12.5680094",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11689",
  },
  {
    id: "56616",
    map_id: "1",
    address: "Corso Ferraris, 47,28845 Domodossola (VB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "46.1156018",
    lng: "8.295318",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11690",
  },
  {
    id: "56615",
    map_id: "1",
    address: "Corso Umberto I, 8,83051 Nusco (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8870718",
    lng: "15.0844432",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11691",
  },
  {
    id: "56614",
    map_id: "1",
    address: "Corso Giulio Aristide Sartorio, 117,90025 Lercara Friddi (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.747059",
    lng: "13.6024156",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11692",
  },
  {
    id: "56613",
    map_id: "1",
    address: "CORSO GOFFREDO MAMELI, 99,16035 Rapallo (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.3506442",
    lng: "9.2272108",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11694",
  },
  {
    id: "56612",
    map_id: "1",
    address: "VIA RAMIRO GINOCCHIO, 6R,16154 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4236631",
    lng: "8.8523041",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11695",
  },
  {
    id: "56611",
    map_id: "1",
    address: "Via Archimede, 159/r,16142 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4067586",
    lng: "8.9522193",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11696",
  },
  {
    id: "56610",
    map_id: "1",
    address: "Via Don Giovanni Verita', 121/r,16158 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4283805",
    lng: "8.7541063",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11697",
  },
  {
    id: "56609",
    map_id: "1",
    address: "Via Emilia, 46/r,16138 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.44772529999999",
    lng: "8.970120099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11720",
  },
  {
    id: "56608",
    map_id: "1",
    address: "Via Vittori Veneto, 50/r,17100 Savona (SV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.3004703",
    lng: "8.4745118",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11721",
  },
  {
    id: "56607",
    map_id: "1",
    address: "Via C.Colombo, 22,16030 Lavagna (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.3083372",
    lng: "9.3437612",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11722",
  },
  {
    id: "56606",
    map_id: "1",
    address: "Via Stella, snc,84070 Valle dell'Angelo (SA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.3453901",
    lng: "15.3723898",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11735",
  },
  {
    id: "56605",
    map_id: "1",
    address: "Pza Giuseppe Montanelli, 18,50054 Fucecchio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.727563",
    lng: "10.808973",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11736",
  },
  {
    id: "56604",
    map_id: "1",
    address: "VIA ROMA, 82,00069 TREVIGNANO ROMANO (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.1583121",
    lng: "12.2403528",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11737",
  },
  {
    id: "56603",
    map_id: "1",
    address: "Via Antonio Gramsci, snc,40010 Sala Bolognese (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.6087054",
    lng: "11.2543392",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11745",
  },
  {
    id: "56602",
    map_id: "1",
    address: "Milano, 96,20093 COLOGNO MONZESE (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5227846",
    lng: "9.272198",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11746",
  },
  {
    id: "56601",
    map_id: "1",
    address: "CORSO DELLA REPUBBLICA, 11,00060 CANALE MONTERANO (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.1366703",
    lng: "12.1022041",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11748",
  },
  {
    id: "56600",
    map_id: "1",
    address: "VIA ROMA, 23,95049 Vizzini (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.1617599",
    lng: "14.749784",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11751",
  },
  {
    id: "56599",
    map_id: "1",
    address: "Via Trieste, 44,08100 Nuoro (NU)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.3218156",
    lng: "9.3269063",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11764",
  },
  {
    id: "56598",
    map_id: "1",
    address: "VIA SANT'ANDREA, 141,76011 Bisceglie (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2320815",
    lng: "16.4971505",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11767",
  },
  {
    id: "56597",
    map_id: "1",
    address: "Piazza Mazzini, 22,35137 Padova (PD)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4149021",
    lng: "11.8736002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11768",
  },
  {
    id: "56596",
    map_id: "1",
    address: "Via valle Viola, 220,00141 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9359791",
    lng: "12.5275312",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11769",
  },
  {
    id: "56595",
    map_id: "1",
    address: "Via Vittorio Veneto, 134,80054 Gragnano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6914527",
    lng: "14.5204186",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11770",
  },
  {
    id: "56594",
    map_id: "1",
    address: "Via Buozzi 24,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.820564",
    lng: "11.1390754",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11771",
  },
  {
    id: "56593",
    map_id: "1",
    address: "Via Della Molina, 56,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.793432",
    lng: "11.1281348",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11772",
  },
  {
    id: "56592",
    map_id: "1",
    address: "Via Confini, 43,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.8351772",
    lng: "11.1318172",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11773",
  },
  {
    id: "56591",
    map_id: "1",
    address: "Via Botticelli, 50/56,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.8164022",
    lng: "11.1410351",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11774",
  },
  {
    id: "56590",
    map_id: "1",
    address: "Via San Quirico, 165,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.846878",
    lng: "11.1436877",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11775",
  },
  {
    id: "56589",
    map_id: "1",
    address: "VIA PAOLO BARATTA, 194,84091 Battipaglia (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6055806",
    lng: "14.993403",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11776",
  },
  {
    id: "56588",
    map_id: "1",
    address: "VIA DUE TORRI, 29,85100 Potenza (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.6402772",
    lng: "15.8054958",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11781",
  },
  {
    id: "56587",
    map_id: "1",
    address: "VIA MAURIZIO FARINA 38,10086 RIVAROLO C.SE (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.3318695",
    lng: "7.725512",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11784",
  },
  {
    id: "56586",
    map_id: "1",
    address: "VIA MICHELE GARZILLI, 17,70025 Grumo Appula (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0116986",
    lng: "16.7071212",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11790",
  },
  {
    id: "56585",
    map_id: "1",
    address: "Via Diego Colamarino, 81,80059 Torre del Greco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7873544",
    lng: "14.3679438",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11798",
  },
  {
    id: "56584",
    map_id: "1",
    address: "Via Antonio D'Auria, 38,80048 Sant' Anastasia (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8692521",
    lng: "14.4005744",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11799",
  },
  {
    id: "56583",
    map_id: "1",
    address: "Corso Resina, 230,80056 Ercolano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8061136",
    lng: "14.3496963",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11800",
  },
  {
    id: "56582",
    map_id: "1",
    address: "Corso Mazzini, 166,84013 Cava de' Tirreni (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7088448",
    lng: "14.7017177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11801",
  },
  {
    id: "56581",
    map_id: "1",
    address: "VIA XXIV MAGGIO, 33,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2964197",
    lng: "16.2559732",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11805",
  },
  {
    id: "56580",
    map_id: "1",
    address: "Via Appia, 1233,04026 Minturno (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.2526632",
    lng: "13.724708",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11807",
  },
  {
    id: "56579",
    map_id: "1",
    address: "Via roma, 7,87012 Castrovillari (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.8123215",
    lng: "16.2027012",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11813",
  },
  {
    id: "56578",
    map_id: "1",
    address: "Via Servio Tullio, 22,00012 Guidonia Montecelio (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.021551",
    lng: "12.743014",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11818",
  },
  {
    id: "56577",
    map_id: "1",
    address: "VIA ALFREDO BACCARINI, 114,70056 Molfetta (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.1991574",
    lng: "16.6013517",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11828",
  },
  {
    id: "56576",
    map_id: "1",
    address: "CORSO CALABRIA, 134,87012 Castrovillari (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.8205533",
    lng: "16.1957932",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11829",
  },
  {
    id: "56575",
    map_id: "1",
    address: "VIA NAZIONALE, 68,98049 Villafranca Tirrena (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.2357888",
    lng: "15.4317828",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11832",
  },
  {
    id: "56574",
    map_id: "1",
    address: "VIA XX SETTEMBRE, 144,52037 Sansepolcro (AR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.5702799",
    lng: "12.142167",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11833",
  },
  {
    id: "56573",
    map_id: "1",
    address: "ROBERTO MIRABELLI , 36,87032 Amantea (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.130491",
    lng: "16.0781172",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11836",
  },
  {
    id: "56572",
    map_id: "1",
    address: "VIA PALMIRO TOGLIATTI, 35,88811 CIRO' MARINA (KR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3655423",
    lng: "17.1264637",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11837",
  },
  {
    id: "56571",
    map_id: "1",
    address: "Piazza Padre Pio Manna, 134,81038 TRENTOLA DUCENTA (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9742729",
    lng: "14.176804",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11838",
  },
  {
    id: "56570",
    map_id: "1",
    address: "Corso Umberto I, 13,81030 Gricignano D'Aversa (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9808023",
    lng: "14.2331132",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11839",
  },
  {
    id: "56569",
    map_id: "1",
    address: "CORSO VITTORIO VENETO, 141/143,91026 Mazara del Vallo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.654714",
    lng: "12.5906695",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11841",
  },
  {
    id: "56568",
    map_id: "1",
    address: "CONTRADA ANZOLICONTE,85040 VIGGIANELLO (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9755834",
    lng: "16.0283839",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11843",
  },
  {
    id: "56567",
    map_id: "1",
    address: "Via Francesca Vergine dei Pini, 1236,51015 Monsummano Terme (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.855383",
    lng: "10.822518",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11844",
  },
  {
    id: "56566",
    map_id: "1",
    address: "VIA RUBIANA, 12/D,10139 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0741182",
    lng: "7.6390859",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11845",
  },
  {
    id: "56565",
    map_id: "1",
    address: "Viale G.Amendola, 47/49,88050 AMARONI (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.7930149",
    lng: "16.4513",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11854",
  },
  {
    id: "56564",
    map_id: "1",
    address: "Via Roma, 23,50053 Empoli (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.718723",
    lng: "10.94908",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11858",
  },
  {
    id: "56563",
    map_id: "1",
    address: "Via P. Castelli Ang.Via Palermo, 13,97019 Vittoria (RG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "36.8928836",
    lng: "14.4319303",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11860",
  },
  {
    id: "56562",
    map_id: "1",
    address: "VIA FIRENZE, 94,95021 Aci Castello (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.5447377",
    lng: "15.1278316",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11861",
  },
  {
    id: "56561",
    map_id: "1",
    address: "VLE BURIDANI, 47/D,10078 Venaria Reale (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.1285996",
    lng: "7.630647000000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11862",
  },
  {
    id: "56560",
    map_id: "1",
    address: "Piazza Garibaldi, 8/C,34134 Trieste (TS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6456475",
    lng: "13.7808599",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11865",
  },
  {
    id: "56559",
    map_id: "1",
    address: "VIA CASSIODORO, 27,80126 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8379003",
    lng: "14.2005553",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11870",
  },
  {
    id: "56558",
    map_id: "1",
    address: "VIA XX SETTEMBRE, 157/A,44121 Ferrara (FE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.8270692",
    lng: "11.6286351",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11871",
  },
  {
    id: "56557",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI, 5,50052 Certaldo (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.5469792",
    lng: "11.0388404",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11872",
  },
  {
    id: "56556",
    map_id: "1",
    address: "Corso Unione Sovietica, 385,10134 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0246362",
    lng: "7.638634100000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11874",
  },
  {
    id: "56555",
    map_id: "1",
    address: "Via nazionale 16,83024 Monteforte Irpino (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.89006699999999",
    lng: "14.7149332",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11875",
  },
  {
    id: "56554",
    map_id: "1",
    address: "Piazza Oberdan, 19,01021 Acquapendente (VT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.7443055",
    lng: "11.8653178",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11876",
  },
  {
    id: "56553",
    map_id: "1",
    address: "VIA GIOVANNI GENTILE, 10,71042 Cerignola (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.261124",
    lng: "15.9108919",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11877",
  },
  {
    id: "56552",
    map_id: "1",
    address: "LOCALITA' VAUDA DI GROSSO, 26,10070 GROSSO (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.2664721",
    lng: "7.570137099999998",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11890",
  },
  {
    id: "56551",
    map_id: "1",
    address: "Via Delle Nebbiaie , 4/B,52048 Monte San Savino (AR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.357928",
    lng: "11.808248",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11891",
  },
  {
    id: "56550",
    map_id: "1",
    address: "PZA MARTIRI, 11,10018 Pavone Canavese (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.438193",
    lng: "7.853407400000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11892",
  },
  {
    id: "56549",
    map_id: "1",
    address: "VIA RISORGIMENTO, 77/A,40069 PREDOSA (BO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.4889",
    lng: "11.2243",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11895",
  },
  {
    id: "56548",
    map_id: "1",
    address: "VIA GIUSEPPE PAVONCELLI, 90,70125 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1041601",
    lng: "16.8777067",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11896",
  },
  {
    id: "56547",
    map_id: "1",
    address: "Cataldantonio Mannarini, 56,74123 Taranto (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4896519",
    lng: "17.227556",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11899",
  },
  {
    id: "56546",
    map_id: "1",
    address: "VIA MORETTO, 1/B,25122 Brescia (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5360863",
    lng: "10.2139267",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11903",
  },
  {
    id: "56545",
    map_id: "1",
    address: "Via Matteotti, 37,08029 Siniscola (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5772165",
    lng: "9.6947055",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11905",
  },
  {
    id: "56544",
    map_id: "1",
    address: "Via Alessandro Volta, 44,98071 Capo d'Orlando (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1603649",
    lng: "14.7465565",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11909",
  },
  {
    id: "56543",
    map_id: "1",
    address: "Viale San Martino, 405,98100 Messina (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.171753",
    lng: "15.5435603",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11910",
  },
  {
    id: "56542",
    map_id: "1",
    address: "Via Trieste, 46,98063 Patti (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.14070840000001",
    lng: "14.9709667",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11911",
  },
  {
    id: "56541",
    map_id: "1",
    address: "Via Liberta', 5,98061 Brolo (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1576109",
    lng: "14.828983",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11912",
  },
  {
    id: "56540",
    map_id: "1",
    address: "Via Umberto I, 241,98063 Gioiosa Marea (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1750423",
    lng: "14.8966943",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11913",
  },
  {
    id: "56539",
    map_id: "1",
    address: "VIALE  EINAUDI, 8,15011 Acqui Terme (AL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.66504459999999",
    lng: "8.4727459",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11918",
  },
  {
    id: "56538",
    map_id: "1",
    address: "VIA BARLAAM, 1,89125 Reggio Calabria (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1029872",
    lng: "15.6358741",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11925",
  },
  {
    id: "56537",
    map_id: "1",
    address: "VIALE  DE GASPERI, 123,81055 Santa Maria Capua Vetere (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0838829",
    lng: "14.2569359",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11927",
  },
  {
    id: "56536",
    map_id: "1",
    address: "VIA MILANO, 26,80021 Afragola (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9182565",
    lng: "14.3102885",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11930",
  },
  {
    id: "56535",
    map_id: "1",
    address: "Via Vittorio Veneto, 122,70131 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.069997",
    lng: "16.8709505",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11931",
  },
  {
    id: "56534",
    map_id: "1",
    address: "Via Nazario Sauro, 31,85028 Rionero in Vulture (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9232012",
    lng: "15.6729287",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11932",
  },
  {
    id: "56533",
    map_id: "1",
    address: "VIA VENEZIA, 64,81034 Mondragone (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1126444",
    lng: "13.8913863",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11933",
  },
  {
    id: "56532",
    map_id: "1",
    address: "Via Raffaele Leonetti, 29,81100 Caserta (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0764627",
    lng: "14.3328319",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11934",
  },
  {
    id: "56531",
    map_id: "1",
    address: "VIA DOLCEDORME, 23,87064 Corigliano Rossano (CS)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.57633089999999",
    lng: "16.6342882",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11935",
  },
  {
    id: "56530",
    map_id: "1",
    address: "VIA BITETTO, 4,70027 Palo del Colle (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.055665",
    lng: "16.707178",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11937",
  },
  {
    id: "56529",
    map_id: "1",
    address: "Via Lungomare, 22,56011 Calci (PI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.7219135",
    lng: "10.5235271",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11938",
  },
  {
    id: "56528",
    map_id: "1",
    address: "VIA CUSTOZA, 59,46048 ROVERBELLA (MN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.265946",
    lng: "10.7688633",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11940",
  },
  {
    id: "56527",
    map_id: "1",
    address: "Via Emporium, 20,92100 Agrigento (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.2765933",
    lng: "13.5855412",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11941",
  },
  {
    id: "56526",
    map_id: "1",
    address: "Piazza Falcone e Borsellino, 3,70026 Modugno (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.1148242",
    lng: "16.7843578",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11942",
  },
  {
    id: "56525",
    map_id: "1",
    address: "VIA LIMITONE, 14,81030 Frignano (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9901208",
    lng: "14.1488836",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11943",
  },
  {
    id: "56524",
    map_id: "1",
    address: "VIA LUIGI RIZZO, 66,00136 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9077513",
    lng: "12.4444537",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11944",
  },
  {
    id: "56523",
    map_id: "1",
    address: "Piazza dei Bruzi, 4,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2932745",
    lng: "16.2564467",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11946",
  },
  {
    id: "56522",
    map_id: "1",
    address: "VIA ROMA, 8,19020 SESTA GODANO (SP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.2936282",
    lng: "9.6747943",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11951",
  },
  {
    id: "56521",
    map_id: "1",
    address: "VIALE DEGLI ARTISTI, 36,81031 Aversa (CE)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "40.9661956",
    lng: "14.2067093",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11952",
  },
  {
    id: "56520",
    map_id: "1",
    address: "CORSO ALDO MORO, 137,81055 SANTA MARIA CAPUA A VETERE (CE)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "41.0817021",
    lng: "14.2583357",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11953",
  },
  {
    id: "56519",
    map_id: "1",
    address: "VIA GENERALE CASCINO, 45,83100 Avellino (AV)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "40.9134959",
    lng: "14.7932722",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11954",
  },
  {
    id: "56518",
    map_id: "1",
    address: "STRADA PROVINCIALE PER PIANURA, 6,80078 Pozzuoli (NA)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "40.8618229",
    lng: "14.1245083",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11955",
  },
  {
    id: "56517",
    map_id: "1",
    address: "VIA CAMILLO BENSO CONTE DI CAVOUR, 63,81030 Teverola (CE)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "40.9935272",
    lng: "14.2098417",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11956",
  },
  {
    id: "56516",
    map_id: "1",
    address: "Via Nazionale Torrette, 181,83013 Mercogliano (AV)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "40.907383",
    lng: "14.7557857",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11957",
  },
  {
    id: "56515",
    map_id: "1",
    address: "VIA SALVO D'ACQUISTO, 30,81030 PARETE (CE)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "40.9652791",
    lng: "14.169943",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11958",
  },
  {
    id: "56514",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI, 25-27,56022 Castelfranco di Sotto (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6980087",
    lng: "10.7421292",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11960",
  },
  {
    id: "56513",
    map_id: "1",
    address: "Via Orazio Flacco, 25,85100 Potenza (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6365175",
    lng: "15.7998854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11962",
  },
  {
    id: "56512",
    map_id: "1",
    address: "VIA ROMA, 3,24036 PONTE SAN PIETRO (BG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6972359",
    lng: "9.5899679",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11963",
  },
  {
    id: "56511",
    map_id: "1",
    address: "VIA ALDO MORO, 1,82033 Cusano Mutri (BN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.3400269",
    lng: "14.5062023",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11964",
  },
  {
    id: "56510",
    map_id: "1",
    address: "Viale Europa, 89,97100 Ragusa (RG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "36.9290216",
    lng: "14.7170379",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11965",
  },
  {
    id: "56509",
    map_id: "1",
    address: "VIA REGINA MARGHERITA 86/90,81034 Mondragone (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1145157",
    lng: "13.8885467",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11969",
  },
  {
    id: "56508",
    map_id: "1",
    address: "VIA ROMA, 130/A,06030 BASTARDO - GIANO DELL'UMBRIA (PG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.8674708",
    lng: "12.5540412",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11974",
  },
  {
    id: "56507",
    map_id: "1",
    address: "Viale De Gasperi, 120,63076 Monteprandone (AP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.8982976",
    lng: "13.8515425",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11975",
  },
  {
    id: "56506",
    map_id: "1",
    address: "Via Roma, 25,81041 Vitulazio (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1638277",
    lng: "14.2168489",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11988",
  },
  {
    id: "56505",
    map_id: "1",
    address: "Largo Antonio Amato Fraz. Marina, 3,98045 SAN PIER NICETO (ME)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.15694999999999",
    lng: "15.35258",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11989",
  },
  {
    id: "56504",
    map_id: "1",
    address: "PIAZZA ENRICO RISI, 28,03049 SANT'ELIA FIUMERAPIDO (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.53935999999999",
    lng: "13.865355",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11990",
  },
  {
    id: "56503",
    map_id: "1",
    address: "VIA MACALLE' 2A,00000 Trecate (NO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4324619",
    lng: "8.7384576",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11995",
  },
  {
    id: "56502",
    map_id: "1",
    address: "PIAZZA 23 NOVEMBRE, SNC,83040 Caposele (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8149261",
    lng: "15.2231674",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11998",
  },
  {
    id: "56501",
    map_id: "1",
    address: "STRADA STATALE 17 -Fraz. Sasso Scalo,67100 L'Aquila (AQ)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.3602634",
    lng: "13.2925171",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "11999",
  },
  {
    id: "56500",
    map_id: "1",
    address: "Via Porta Palermo, 99,90048 San Giuseppe Jato (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.9726428",
    lng: "13.1864237",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12001",
  },
  {
    id: "56499",
    map_id: "1",
    address: "VIA DEI MILLE, SN,74015 Martina Franca (TA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.7029411",
    lng: "17.3335721",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12002",
  },
  {
    id: "56498",
    map_id: "1",
    address: "VIA XXIV MAGGIO, 121/G,89034 Bovalino (RC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.151102",
    lng: "16.17959",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12003",
  },
  {
    id: "56497",
    map_id: "1",
    address: "VIA DEI MILLE, 1,40121 Bologna (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.5013479",
    lng: "11.344219",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12006",
  },
  {
    id: "56496",
    map_id: "1",
    address: "Via Appia Antica, 18,04019 Terracina (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.2984582",
    lng: "13.2390156",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12007",
  },
  {
    id: "56495",
    map_id: "1",
    address: "Via Galvani, 13,88046 Lamezia Terme (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9698837",
    lng: "16.3145508",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12008",
  },
  {
    id: "56494",
    map_id: "1",
    address: "VIA DELLA REGIONE, 44,93013 Mazzarino (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3003119",
    lng: "14.2095568",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12009",
  },
  {
    id: "56493",
    map_id: "1",
    address: "VIA TITO MINNITI, 25,87029 Scalea (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.8117539",
    lng: "15.7928456",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12012",
  },
  {
    id: "56492",
    map_id: "1",
    address: "Via O di Giordano della Cava, 63-65,84013 Cava de' Tirreni (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.69903619999999",
    lng: "14.7116817",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12013",
  },
  {
    id: "56491",
    map_id: "1",
    address: "A 26 - Turchino est, snc,16010 Mele (GE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.4535127",
    lng: "8.7446782",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12016",
  },
  {
    id: "56490",
    map_id: "1",
    address: "Via Marzano, 19,80023 Caivano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9773491",
    lng: "14.3051709",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12018",
  },
  {
    id: "56489",
    map_id: "1",
    address: "Via Matteotti, 107,70014 CONVERSANO (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.968168",
    lng: "17.111341",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12025",
  },
  {
    id: "56488",
    map_id: "1",
    address: "DELL'INDUSTRIA, 82,72015 Fasano (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.848728",
    lng: "17.3515629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12026",
  },
  {
    id: "56487",
    map_id: "1",
    address: "Via Cappuccini, 136,72015 Monopoli (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9458609",
    lng: "17.3002702",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12027",
  },
  {
    id: "56486",
    map_id: "1",
    address: "Via Van Westerhout, 67,70042 Mola di Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0619711",
    lng: "17.0885806",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12028",
  },
  {
    id: "56485",
    map_id: "1",
    address: "Via Villafranca, 33,72017 Ostuni (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7263734",
    lng: "17.5736475",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12029",
  },
  {
    id: "56484",
    map_id: "1",
    address: "Viale Donato Giannini, 11,70010 Turi (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9176008",
    lng: "17.0198431",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12030",
  },
  {
    id: "56483",
    map_id: "1",
    address: "Via Pompeo Sarnelli, 77,70044 Polignano a Mare (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9936252",
    lng: "17.2224476",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12031",
  },
  {
    id: "56482",
    map_id: "1",
    address: "Via Conversano, 29,70013 Castellana Grotte (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8841052",
    lng: "17.1652755",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12033",
  },
  {
    id: "56481",
    map_id: "1",
    address: "VIA NAZIONALE, 34 Q,94018 Troina (EN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.7817685",
    lng: "14.5943251",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12035",
  },
  {
    id: "56480",
    map_id: "1",
    address: "CORSO MARCONI, 6,08020 TORPE' (NU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6880467",
    lng: "9.6361973",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12037",
  },
  {
    id: "56479",
    map_id: "1",
    address: "VIA L. VACCARA, 62/64,91026 Mazara del Vallo (TP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.6561393",
    lng: "12.5846893",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12040",
  },
  {
    id: "56478",
    map_id: "1",
    address: "Via Cupa della Vedova, 182,80145 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8939715",
    lng: "14.2577887",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12043",
  },
  {
    id: "56477",
    map_id: "1",
    address: "Via Casilina sud, 209,07013 Ferentino (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.684441",
    lng: "13.2748954",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12044",
  },
  {
    id: "56476",
    map_id: "1",
    address: "Via Casilina, 4799,03012 Anagni (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.535323",
    lng: "13.6496581",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12045",
  },
  {
    id: "56475",
    map_id: "1",
    address: "VIA IV NOVEMBRE, SNC,18013 DIANO ARENTINO (IM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.9477693",
    lng: "8.0448523",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12047",
  },
  {
    id: "56474",
    map_id: "1",
    address: "PIAZZA MARSILIO FICINO 66,50063 FIGLINE E INCISA VALDARNO (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.6182543",
    lng: "11.4717132",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12048",
  },
  {
    id: "56473",
    map_id: "1",
    address: "Via Nazionale, 652,80059 Torre del Greco (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.7686793",
    lng: "14.4087658",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12051",
  },
  {
    id: "56472",
    map_id: "1",
    address: "Via Aurelia, 395/B,00165 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8972562",
    lng: "12.4319398",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12052",
  },
  {
    id: "56471",
    map_id: "1",
    address: "LARGO ANDREA SAVINI, 7,48026 RUSSI (RA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.3720066",
    lng: "12.0413616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12054",
  },
  {
    id: "56470",
    map_id: "1",
    address: "VIA DELLE AZALEE, 76,92019 Sciacca (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.509623",
    lng: "13.0632999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12055",
  },
  {
    id: "56469",
    map_id: "1",
    address: "VIA STORNARELLA 12,71045 Orta Nova (FG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.3254364",
    lng: "15.7093217",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12058",
  },
  {
    id: "56468",
    map_id: "1",
    address: "Via Lago Patria, 4,80014 Giuliano in Campania (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.91822",
    lng: "14.03081",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12059",
  },
  {
    id: "56467",
    map_id: "1",
    address: "Via Toscana, 33/B,74020 MONTEMESOLA (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.392391",
    lng: "17.441825",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12061",
  },
  {
    id: "56466",
    map_id: "1",
    address: "Via Kennedy, 60/62,81020 Casapulla (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0794868",
    lng: "14.2828283",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12062",
  },
  {
    id: "56465",
    map_id: "1",
    address: "Via Mazzini, 21,58015 Orbetello (GR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "42.4377384",
    lng: "11.2114272",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12063",
  },
  {
    id: "56464",
    map_id: "1",
    address: "Via Serrone, 11,85030 Atena Lucana (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4500345",
    lng: "15.5499442",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12065",
  },
  {
    id: "56463",
    map_id: "1",
    address: "VIA BARLETTA, 12,71043 Manfredonia (FG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.6362448",
    lng: "15.920469",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12066",
  },
  {
    id: "56462",
    map_id: "1",
    address: "VIA FALCONE E BORSELLINO, 111,90018 Termini Imerese (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.9782934",
    lng: "13.6898627",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12067",
  },
  {
    id: "56461",
    map_id: "1",
    address: "VIA CAVONE, 55/57,80020 Frattaminore (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9591427",
    lng: "14.2653851",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12070",
  },
  {
    id: "56460",
    map_id: "1",
    address: "Via XXXI Maggio, 72,80027 Frattamaggiore (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.942696",
    lng: "14.2795464",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12071",
  },
  {
    id: "56459",
    map_id: "1",
    address: "Via Dante Alighieri, 49,28066 Galliate (NO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.47542199999999",
    lng: "8.694201",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12072",
  },
  {
    id: "56458",
    map_id: "1",
    address: "Via Casalano, 93/A,80010 Quarto (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8884363",
    lng: "14.1531188",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12073",
  },
  {
    id: "56457",
    map_id: "1",
    address: "VIA IL BORGO, 14,50022 Greve in Chianti (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.6712962",
    lng: "11.3582656",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12074",
  },
  {
    id: "56456",
    map_id: "1",
    address: "VIA DELL'ALBERO, 21/R,50123 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7750323",
    lng: "11.2459098",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12077",
  },
  {
    id: "56455",
    map_id: "1",
    address: "Pza Serra, 43,84070 San Mauro Cilento (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2261238",
    lng: "15.0450142",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12079",
  },
  {
    id: "56454",
    map_id: "1",
    address: "CORSO GIUSEPPE GARIBALDI, 109,15048 VALENZA (AL)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.0117836",
    lng: "8.6440617",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12081",
  },
  {
    id: "56453",
    map_id: "1",
    address: "CORSO GIUSEPPE GARIBALDI, 164,87012 Castrovillari (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.8109098",
    lng: "16.2041552",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12083",
  },
  {
    id: "56451",
    map_id: "1",
    address: "VIA MADONNA DELLA MISERICORDIA, 23/25,64010 ANCARANO (TE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.8353668",
    lng: "13.739443",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12085",
  },
  {
    id: "56450",
    map_id: "1",
    address: "Viale Postumia, 27,37069 Villafranca di Verona (VR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.36073529999999",
    lng: "10.8555935",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12091",
  },
  {
    id: "56449",
    map_id: "1",
    address: "Via Garibaldi, 43,80040 Striano (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.814994",
    lng: "14.5763759",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12103",
  },
  {
    id: "56448",
    map_id: "1",
    address: "VIA CAGLIARI, 17/19,09026 San Sperate (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3577832",
    lng: "9.006419",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12104",
  },
  {
    id: "56447",
    map_id: "1",
    address: "Via Biagio Petrocelli, 240,00133 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8358526",
    lng: "12.6048629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12108",
  },
  {
    id: "56446",
    map_id: "1",
    address: "Via G. Caprin, 55,34134 Grado (TS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6772835",
    lng: "13.3887173",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12109",
  },
  {
    id: "56445",
    map_id: "1",
    address: "VIA TERENZIO, 30 C,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.46774569999999",
    lng: "12.9153723",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12110",
  },
  {
    id: "56444",
    map_id: "1",
    address: "Piazza Italia, 7/ Frazione Ripescia,58100 Grosseto (GR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "42.7069403",
    lng: "11.1354925",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12117",
  },
  {
    id: "56443",
    map_id: "1",
    address: "VIA GRAMSCI, 28,73040 Melissano (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "39.96653999999999",
    lng: "18.124163",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12120",
  },
  {
    id: "56442",
    map_id: "1",
    address: "Via Anguillarese, 6/a,00061 Anguillara Sabazia (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.089909",
    lng: "12.2756604",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12121",
  },
  {
    id: "56441",
    map_id: "1",
    address: "VIA PRINCIPE DI NAPOLI, 72,81052 Pignataro Maggiore (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.19216300000001",
    lng: "14.171614",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12125",
  },
  {
    id: "56440",
    map_id: "1",
    address: "PIAZZA ANTONIO GRAMSCI, 55,50032 Borgo San Lorenzo (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.9540235",
    lng: "11.3888325",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12134",
  },
  {
    id: "56439",
    map_id: "1",
    address: "VIA VITTORIO ALFIERI, 25,72027 San Pietro Vernotico (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4898557",
    lng: "17.9942093",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12137",
  },
  {
    id: "56438",
    map_id: "1",
    address: "Via San Giuseppe di Vittorio, 16/18,80029 Sant'Antimo (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9396935",
    lng: "14.237689",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12139",
  },
  {
    id: "56437",
    map_id: "1",
    address: "Piazza Enrico Martini, 22/23,00127 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.7964529",
    lng: "12.4309118",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12144",
  },
  {
    id: "56436",
    map_id: "1",
    address: "O.FLACCO ANGOLO VIA ROMOLO, 13/15,72100 Brindisi (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.629979",
    lng: "17.9311811",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12150",
  },
  {
    id: "56435",
    map_id: "1",
    address: "LARGO DE GASPERI, 8,84010 San Marzano sul Sarno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7731057",
    lng: "14.5912331",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12151",
  },
  {
    id: "56434",
    map_id: "1",
    address: "Via Enea, 43/45,00181 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8736622",
    lng: "12.5266382",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12153",
  },
  {
    id: "56433",
    map_id: "1",
    address: "VIALE GABRIELE D'ANNUNZIO 24,85025 Melfi (PZ)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9926319",
    lng: "15.653762",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12154",
  },
  {
    id: "56432",
    map_id: "1",
    address: "Corso Matteotti, 105,51016 Montecatini Terme (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8809271",
    lng: "10.7759593",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12158",
  },
  {
    id: "56431",
    map_id: "1",
    address: "Via Gramsci, 21,51035 Lamporecchio (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.815995",
    lng: "10.89616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12159",
  },
  {
    id: "56430",
    map_id: "1",
    address: "Piazza Ferdinando Martini, 165,51015 Monsummano Terme (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8712721",
    lng: "10.8143035",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12160",
  },
  {
    id: "56429",
    map_id: "1",
    address: "Piazza XX Settembre, 6,51017 Pescia (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.9007441",
    lng: "10.6889088",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12161",
  },
  {
    id: "56428",
    map_id: "1",
    address: "Viale Adua, 283,51100 Pistoia (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.9441937",
    lng: "10.9005747",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12162",
  },
  {
    id: "56427",
    map_id: "1",
    address: "VIA TRENTO, 124/126,84098 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6595637",
    lng: "14.799832",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12164",
  },
  {
    id: "56426",
    map_id: "1",
    address: "Via Fausto Gullo, 8,87019 Spezzano Albanese (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6687079",
    lng: "16.3066546",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12165",
  },
  {
    id: "56425",
    map_id: "1",
    address: "Via S. D'Acquisto, 8,84043 Agropoli (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3457392",
    lng: "14.9972873",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12167",
  },
  {
    id: "56424",
    map_id: "1",
    address: "Via dei Cavalletti, 767,55100 Lucca (LU)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.84787",
    lng: "10.48716",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12168",
  },
  {
    id: "56423",
    map_id: "1",
    address: "Via Monte Grappa, 15,70011 Alberobello (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.786628",
    lng: "17.2378923",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12169",
  },
  {
    id: "56422",
    map_id: "1",
    address: "VIA NAPOLI, 127,80018 Mugnano di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9029919",
    lng: "14.2086666",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12170",
  },
  {
    id: "56421",
    map_id: "1",
    address: "VIA GUGLIELMO MARCONI, 30,94015 Piazza Armerina (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.384704",
    lng: "14.3665481",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12176",
  },
  {
    id: "56420",
    map_id: "1",
    address: "Via Della Ferrovia 40,01100 Viterbo (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.4219072",
    lng: "12.110405",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12177",
  },
  {
    id: "56419",
    map_id: "1",
    address: "Via Pomino, 60,50068 Rufina (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.814857",
    lng: "11.551029",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12179",
  },
  {
    id: "56418",
    map_id: "1",
    address: "Via Kennedy, 68,90040 Capaci (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.17551",
    lng: "13.23846",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12180",
  },
  {
    id: "56417",
    map_id: "1",
    address: "VIA SIDNEY SONNINO, 30/A,09125 Cagliari (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2120444",
    lng: "9.118793199999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12181",
  },
  {
    id: "56416",
    map_id: "1",
    address: "VIALE XV GENNAIO, 7,92010 Montevago (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6985921",
    lng: "12.9880587",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12183",
  },
  {
    id: "56415",
    map_id: "1",
    address: "VIA EUSEBIO GARIZIO, 2/1,10139 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0746255",
    lng: "7.6436183",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12186",
  },
  {
    id: "56414",
    map_id: "1",
    address: "Via Filippo Ugolini, 2/4,61049 Urbania (PU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6684227",
    lng: "12.5239352",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12188",
  },
  {
    id: "56413",
    map_id: "1",
    address: "VICOLO PUNTA BIANCA, 27,92100 Agrigento (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.299748",
    lng: "13.5522957",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12189",
  },
  {
    id: "56412",
    map_id: "1",
    address: "VIA CASSANO, 137,80144 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8874025",
    lng: "14.2701556",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12191",
  },
  {
    id: "56411",
    map_id: "1",
    address: "CORSO G. MATTEOTTI 230,71032 Torremaggiore (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6908085",
    lng: "15.297239",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12193",
  },
  {
    id: "56410",
    map_id: "1",
    address: "Via Cesare Battisti, 55,71011 APRICENA (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.78488979999999",
    lng: "15.4468956",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12194",
  },
  {
    id: "56409",
    map_id: "1",
    address: "GIAMBATTISTA BONAZZI, 47,70124 Bari (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.12744989999999",
    lng: "16.8626395",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12195",
  },
  {
    id: "56408",
    map_id: "1",
    address: "VIA PADRE SAVARESE, 77,76123 Andria (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2167773",
    lng: "16.2935376",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12196",
  },
  {
    id: "56407",
    map_id: "1",
    address: "VIA UNITA' D'ITALIA, 13,84081 Baronissi (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7448732",
    lng: "14.769392",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12197",
  },
  {
    id: "56406",
    map_id: "1",
    address: "VIA ETTORE VITALE, 182,88100 Catanzaro (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.93356530000001",
    lng: "16.5803284",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12198",
  },
  {
    id: "56405",
    map_id: "1",
    address: "VIALE MADONNA DI ROSA, 19,33078 SAN VITO AL TAGLIAMENTO (PN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.9186183",
    lng: "12.8662506",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12200",
  },
  {
    id: "56404",
    map_id: "1",
    address: "Via San Paolo, 2,33075 MORSANO AL TAGLIAMENTO (PN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.8596113",
    lng: "12.9313952",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12201",
  },
  {
    id: "56403",
    map_id: "1",
    address: "VIA NAZIONALE, 1,82020 PIETRELCINA (BN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "46.311582",
    lng: "10.760571",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12206",
  },
  {
    id: "56402",
    map_id: "1",
    address: "VIA ENRICO FERMI, 54,37136 Verona (VR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4096895",
    lng: "10.9540075",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12207",
  },
  {
    id: "56401",
    map_id: "1",
    address: "VIA PROVINCIALE, 21,00010 MONTEFLAVIO (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.1090747",
    lng: "12.8323637",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12210",
  },
  {
    id: "56400",
    map_id: "1",
    address: "Via Risorgimento, 43,57013 Rosignano Marittimo (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.3947825",
    lng: "10.4374934",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12211",
  },
  {
    id: "56399",
    map_id: "1",
    address: "Corso Europa, 138,84050 Albanella (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5029528",
    lng: "15.0656482",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12212",
  },
  {
    id: "56398",
    map_id: "1",
    address: "VIA BENIGNO CRESPI, 26/A,00133 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "41.8596263",
    lng: "12.6175459",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12213",
  },
  {
    id: "56397",
    map_id: "1",
    address: "VIA BIXIO, 172,70043 Monopoli (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.951491",
    lng: "17.2979173",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12214",
  },
  {
    id: "56396",
    map_id: "1",
    address: "Corso Giacomo Matteotti, 225,20831 Seregno (MB)",
    description: "AMAZON HUB SERVICE",
    lat: "45.6558466",
    lng: "9.2088457",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12223",
  },
  {
    id: "56395",
    map_id: "1",
    address: "S.S. 7 APPIA, SNC,82100 Benevento (BN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.118409",
    lng: "14.7566329",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12225",
  },
  {
    id: "56394",
    map_id: "1",
    address: "Via Pablo Neruda, 11,81100 Caserta (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0820038",
    lng: "14.3539139",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12227",
  },
  {
    id: "56393",
    map_id: "1",
    address: "VIALE DEGLI OLEANDRI, 164,87030 FALCONARA ALBANESE (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2626773",
    lng: "16.0576345",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12228",
  },
  {
    id: "56392",
    map_id: "1",
    address: "Via dei Lauri, 77,04011 Aprilia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5964915",
    lng: "12.6502136",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12230",
  },
  {
    id: "56391",
    map_id: "1",
    address: "Via Mazzini, 60,94019 VALGUARNERA CAROPEPE (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.4944595",
    lng: "14.3907568",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12232",
  },
  {
    id: "56390",
    map_id: "1",
    address: "VIA SUL VINCIO, 2/B,50050 Cerreto Guidi (FI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.778661",
    lng: "10.858394",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12234",
  },
  {
    id: "56389",
    map_id: "1",
    address: "VIA GIOVANNI GUIDICCIONI, 85,55100 Lucca (LU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.8363573",
    lng: "10.5013287",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12235",
  },
  {
    id: "56388",
    map_id: "1",
    address: "Via Fontanelle, snc,87064 Corigliano Calabro (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.6245185",
    lng: "16.5134795",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12236",
  },
  {
    id: "56387",
    map_id: "1",
    address: "VIA SAN GIORGETTO, 12,03012 Anagni (FR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7405402",
    lng: "13.1654789",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12241",
  },
  {
    id: "56386",
    map_id: "1",
    address: "Via Stefano Passero, 74/76,84078 VALLO DELLA LUCANIA (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2303285",
    lng: "15.2698183",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12242",
  },
  {
    id: "56385",
    map_id: "1",
    address: "Via Pietro Carrese, 15,80053 Castellammare di Stabia (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.70213409999999",
    lng: "14.4859206",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12243",
  },
  {
    id: "56384",
    map_id: "1",
    address: "Corso Garibaldi, 19,84073 Sapri (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.0745346",
    lng: "15.6293966",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12244",
  },
  {
    id: "56383",
    map_id: "1",
    address: "Piazza Garibaldi, 72,70026 Modugno (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0820534",
    lng: "16.7790704",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12246",
  },
  {
    id: "56382",
    map_id: "1",
    address: "Via Nepesina Km 3.320,01036 Nepi (VT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.2144589",
    lng: "12.3041828",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12249",
  },
  {
    id: "56381",
    map_id: "1",
    address: "VIA UGO FOSCOLO ANG.VIA UMBRIA, SNC,04011 Aprilia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6013251",
    lng: "12.6520979",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12256",
  },
  {
    id: "56380",
    map_id: "1",
    address: "Via Gallarana, 4,20900 Monza (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.58403999999999",
    lng: "9.2973",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12257",
  },
  {
    id: "56379",
    map_id: "1",
    address: "Via Pellegrino Rossi, 38,20161 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5101386",
    lng: "9.1765191",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12258",
  },
  {
    id: "56378",
    map_id: "1",
    address: "VIA MURGE, 87,76123 Andria (BT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.2173725",
    lng: "16.2958228",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12263",
  },
  {
    id: "56377",
    map_id: "1",
    address: "VIALE EUROPA, 34,80053 Castellammare di Stabia (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7042408",
    lng: "14.4887088",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12265",
  },
  {
    id: "56376",
    map_id: "1",
    address: "Via Nizza, 12,80067 Sorrento (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6272722",
    lng: "14.3830557",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12266",
  },
  {
    id: "56375",
    map_id: "1",
    address: "Lungomare C.Colombo, 209/301,84129 Salerno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.66066379999999",
    lng: "14.7972808",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12268",
  },
  {
    id: "56374",
    map_id: "1",
    address: "Via Decembrio, 17/19,27029 Vigevano (PV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.3179664",
    lng: "8.860717600000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12269",
  },
  {
    id: "56373",
    map_id: "1",
    address: "Via Garibaldi - Angolo via Bengasi, 182,20532 Desio (MB)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6119346",
    lng: "9.2049465",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12270",
  },
  {
    id: "56372",
    map_id: "1",
    address: "Viale Nusco, 157,00039 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8917071",
    lng: "12.6334022",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12272",
  },
  {
    id: "56371",
    map_id: "1",
    address: "VIA FLORIDIANA, 6/A,00135 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9398921",
    lng: "12.4247588",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12274",
  },
  {
    id: "56370",
    map_id: "1",
    address: "VIA LITORANEA, 27,58022 Follonica (GR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.9263229",
    lng: "10.750997",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12275",
  },
  {
    id: "56369",
    map_id: "1",
    address: "Via Regina Margherita, snc,86012 Cercemaggiore (CB)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.4621679",
    lng: "14.7259937",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12277",
  },
  {
    id: "56368",
    map_id: "1",
    address: "VIA SCRIMA, 8,60126 Ancona (AN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6037644",
    lng: "13.506373",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12278",
  },
  {
    id: "56367",
    map_id: "1",
    address: "Via di Grottarossa, 96,00189 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9720992",
    lng: "12.4389288",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12282",
  },
  {
    id: "56366",
    map_id: "1",
    address: "VIA NAZIONALE, 385,88070 Botricello (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.934269",
    lng: "16.8540218",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12283",
  },
  {
    id: "56365",
    map_id: "1",
    address: "Via Casal del Marmo, 181-181/a,00135 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.9588145",
    lng: "12.4054442",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12284",
  },
  {
    id: "56364",
    map_id: "1",
    address: "VIA CHIESA DELLA SALUTE, 18D,10147 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.09375319999999",
    lng: "7.678312299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12285",
  },
  {
    id: "56363",
    map_id: "1",
    address: "Via Falcone Borsellino, 74,90035 Marineo (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.9525237",
    lng: "13.4157592",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12286",
  },
  {
    id: "56362",
    map_id: "1",
    address: "Via Pietro Bonfante, box nÂ° 24,00175 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8590503",
    lng: "12.5664136",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12290",
  },
  {
    id: "56361",
    map_id: "1",
    address: "VIA STATALE, 140,51039 Quarrata (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.87824",
    lng: "10.975666",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12292",
  },
  {
    id: "56360",
    map_id: "1",
    address: "Via Cappella dei Bisi, 112,80050 Santa Maria La CaritÃ  (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.71143199999999",
    lng: "14.5175282",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12294",
  },
  {
    id: "56359",
    map_id: "1",
    address: "Corso Umberto I, 75,76011 Bisceglie (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2399049",
    lng: "16.5055871",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12295",
  },
  {
    id: "56358",
    map_id: "1",
    address: "Via Gustavo Roccella, 55/b,90128 Palermo (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1006287",
    lng: "13.351253",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12298",
  },
  {
    id: "56357",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI, 18,09018 Sarroch (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.0678306",
    lng: "9.0095096",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12301",
  },
  {
    id: "56356",
    map_id: "1",
    address: "Via San Nullo, 168,80014 Giugliano in Campania (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.885857",
    lng: "14.080725",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12302",
  },
  {
    id: "56355",
    map_id: "1",
    address: "Via Dell'Omo, 93/95,00155 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.889907",
    lng: "12.6096458",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12304",
  },
  {
    id: "56354",
    map_id: "1",
    address: "VIA MONFALCONE, 3,86100 Campobasso (CB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5619669",
    lng: "14.6728917",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12305",
  },
  {
    id: "56353",
    map_id: "1",
    address: "Via della Pisana, 183/185,00163 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8799199",
    lng: "12.4170298",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12313",
  },
  {
    id: "56352",
    map_id: "1",
    address: "Piazza Risorgimento, snc,67051 Avezzano (AQ)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.0349289",
    lng: "13.4264815",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12315",
  },
  {
    id: "56351",
    map_id: "1",
    address: "Via Cassino, 18,89129 Reggio Calabria (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0847603",
    lng: "15.6405596",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12317",
  },
  {
    id: "56350",
    map_id: "1",
    address: "Via Filibeck, 27,00042 Anzio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.44794599999999",
    lng: "12.6281839",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12326",
  },
  {
    id: "56349",
    map_id: "1",
    address: "VIA NAZIONALE DELLE PUGLIE, 195/D,80026 Casoria (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8908113",
    lng: "14.3221736",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12327",
  },
  {
    id: "56348",
    map_id: "1",
    address: "Via Antonio Campofreda, 44,80025 Casandrino (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9373477",
    lng: "14.2459537",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12329",
  },
  {
    id: "56347",
    map_id: "1",
    address: "VIA MARIO ORSINI, 2/R,50125 Firenze (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7485656",
    lng: "11.2335905",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12330",
  },
  {
    id: "56346",
    map_id: "1",
    address: "Via Colonello BertÃ¨, 95,98051 Barcellona Pozzo di Gotto (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.2162204",
    lng: "15.2395903",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12336",
  },
  {
    id: "56345",
    map_id: "1",
    address: "Viale regina Elena, 201,98121 Messina (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.21270639999999",
    lng: "15.5587079",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12337",
  },
  {
    id: "56344",
    map_id: "1",
    address: "Via Roma, 192,98051 Barcellona Pozzo di Gotto (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.143723",
    lng: "15.2165526",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12338",
  },
  {
    id: "56343",
    map_id: "1",
    address: "Via Giacomo Medici, 85,98057 Milazzo (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.2227431",
    lng: "15.2427656",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12339",
  },
  {
    id: "56342",
    map_id: "1",
    address: "VIALE FERROVIA, 11,71036 Lucera (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5048064",
    lng: "15.333624",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12340",
  },
  {
    id: "56341",
    map_id: "1",
    address:
      "Via Chiantigiana, 227 Frazione Grassina,50012 Bagno a Ripoli (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7399655",
    lng: "11.2963857",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12343",
  },
  {
    id: "56340",
    map_id: "1",
    address: "Corso Vittorio Emanuele II, 195,45014 Adria (RO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.05177",
    lng: "12.05734",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12346",
  },
  {
    id: "56339",
    map_id: "1",
    address: "Via Giuseppe Mazzini, 90/B,45014 Porto Viro (RO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.03449819999999",
    lng: "12.2190094",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12347",
  },
  {
    id: "56338",
    map_id: "1",
    address: "CORSO ITALIA, 100,74015 Martina Franca (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.70202829999999",
    lng: "17.3389533",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12349",
  },
  {
    id: "56337",
    map_id: "1",
    address: "LocalitÃ  Brunara, 3/A,88100 ZAGARISE (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.0004753",
    lng: "16.6637093",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12350",
  },
  {
    id: "56336",
    map_id: "1",
    address: "VIA ALBERTO ALTIERI, 77,81020 CASTEL MORRONE (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1219501",
    lng: "14.3508471",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12351",
  },
  {
    id: "56335",
    map_id: "1",
    address: "VIA IMPERATORE ANTONINO, 14,70032 Bitonto (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1069273",
    lng: "16.6835175",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12355",
  },
  {
    id: "56334",
    map_id: "1",
    address: "VIA DELLA FOCE MICINA, SNC,00054 Fiumicino (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.7750373",
    lng: "12.236082",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12357",
  },
  {
    id: "56333",
    map_id: "1",
    address: "Viale Fabio Filzi, 19,60033 Chiaravalle (AN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.60122639999999",
    lng: "13.3225638",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12359",
  },
  {
    id: "56332",
    map_id: "1",
    address: "Frazione Condemine, 33,11010 SARRE (AO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.7273502",
    lng: "7.2773177",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12364",
  },
  {
    id: "56331",
    map_id: "1",
    address: "TRAVERSA MARIO GRECO, 5,88100 Catanzaro (CZ)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.9172405",
    lng: "16.5868918",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12365",
  },
  {
    id: "56330",
    map_id: "1",
    address: "Via San Faustino, 58,25122 Brescia (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5429576",
    lng: "10.220232",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12366",
  },
  {
    id: "56329",
    map_id: "1",
    address: "VICOLO 2 LE MURA, SNC,88025 Maida (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.8592024",
    lng: "16.3645578",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12367",
  },
  {
    id: "56328",
    map_id: "1",
    address: "Via Costante Coter, 15 Fraz.Loc.Sermonte,24029 Vertova (BG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.8041414",
    lng: "9.8463707",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12370",
  },
  {
    id: "56327",
    map_id: "1",
    address: "VIA GARIBALDI, 22,85100 Potenza (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6376742",
    lng: "15.8045382",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12372",
  },
  {
    id: "56326",
    map_id: "1",
    address: "Corso Umberto I, 278,80034 Marigliano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9245694",
    lng: "14.4596555",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12375",
  },
  {
    id: "56325",
    map_id: "1",
    address: "Via Ruggero Settimo, 41,91022 Castelvetrano (TP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.679787",
    lng: "12.789292",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12377",
  },
  {
    id: "56324",
    map_id: "1",
    address: "VIA SAN DONATO, SN,75015 Pisticci (MT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3890118",
    lng: "16.5641339",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12380",
  },
  {
    id: "56323",
    map_id: "1",
    address: "VIA GIUSEPPE MASSARENTI, 240/A,40138 Bologna (BO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.49353550000001",
    lng: "11.373732",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12382",
  },
  {
    id: "56322",
    map_id: "1",
    address: "VIA VITTORIO VENETO, 3A,97014 Ispica (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.7869216",
    lng: "14.9080037",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12383",
  },
  {
    id: "56321",
    map_id: "1",
    address: "VIA NAZIONALE, 55,97015 Modica (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.8359102",
    lng: "14.7892185",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12385",
  },
  {
    id: "56320",
    map_id: "1",
    address: "STRADA SANDOLO GAMBULAGA, 26,44015 Portomaggiore (FE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.72510030000001",
    lng: "11.8152398",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12386",
  },
  {
    id: "56319",
    map_id: "1",
    address: "Via Livilla, 20,00175 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8632327",
    lng: "12.552894",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12387",
  },
  {
    id: "56318",
    map_id: "1",
    address: "VIA SOLIMENE, 82/B,08012 Napoli (NA)",
    description: "AMAZON HUB SERVICE - BOLLETTINI - PROMO130",
    lat: "40.8446257",
    lng: "14.2289616",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12388",
  },
  {
    id: "56317",
    map_id: "1",
    address: "VIA MARTIRI, 99/A,83031 Ariano Irpino (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1615323",
    lng: "15.1091554",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12393",
  },
  {
    id: "56316",
    map_id: "1",
    address: "VIALE ALCIDE DE GASPERI, 14/B,00053 Civitavecchia (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.08176390000001",
    lng: "11.8099679",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12399",
  },
  {
    id: "56315",
    map_id: "1",
    address: "CORSO CARAFA, 69,82032 Cerreto Sannita (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2834574",
    lng: "14.5577032",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12400",
  },
  {
    id: "56314",
    map_id: "1",
    address: "VIA ROMA, 53,50053 Empoli (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.717754",
    lng: "10.949179",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12401",
  },
  {
    id: "56313",
    map_id: "1",
    address: "Via Madonna della Neve, 45,03100 Frosinone (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.6505918",
    lng: "13.3402802",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12402",
  },
  {
    id: "56312",
    map_id: "1",
    address: "Via Sbarre Centrali, 17,89133 Reggio Calabria (RC)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1009846",
    lng: "15.6422596",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12406",
  },
  {
    id: "56311",
    map_id: "1",
    address: "LGO CAPITELLONI, 1/C,50058 Signa (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.7852214",
    lng: "11.0967729",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12407",
  },
  {
    id: "56310",
    map_id: "1",
    address: "Ferrarese n 16/D,40128 Bologna (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.5278776",
    lng: "11.3649362",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12409",
  },
  {
    id: "56309",
    map_id: "1",
    address: "Via Washington, 76,20146 Milano (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.4593142",
    lng: "9.154497",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12410",
  },
  {
    id: "56308",
    map_id: "1",
    address: "VIA AVOLA - ANG VIA STROMBOLI, 25,74013 Ginosa (TA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.5812722",
    lng: "16.7546624",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12413",
  },
  {
    id: "56307",
    map_id: "1",
    address: "Corso Roma, 286,74016 Massafra (TA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.5848448",
    lng: "17.1242684",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12419",
  },
  {
    id: "56306",
    map_id: "1",
    address: "Via Domenico delle Site, 9,73100 Lecce (LE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.34734280000001",
    lng: "18.1949256",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12423",
  },
  {
    id: "56305",
    map_id: "1",
    address: "Via Ferrovia, 56,80040 San Gennaro Vesuviano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.862083",
    lng: "14.53191",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12425",
  },
  {
    id: "56304",
    map_id: "1",
    address: "Strada Villa, 37 - Fraz. Fontanelle,43010 Roccabianca (PR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.918936",
    lng: "10.2295932",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12426",
  },
  {
    id: "56303",
    map_id: "1",
    address: "PIAZZA XXVI APRILE, 6,27020 Valle Lomellina (PV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.1502912",
    lng: "8.6632772",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12428",
  },
  {
    id: "56302",
    map_id: "1",
    address: "VIA SAMPIERDARENA, 212/R,16149 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.41079879999999",
    lng: "8.8861764",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12429",
  },
  {
    id: "56301",
    map_id: "1",
    address: "VIA ROBERTO MARCOLONGO, 41,00146 Aprilia (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8652761",
    lng: "12.4700272",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12430",
  },
  {
    id: "56300",
    map_id: "1",
    address: "VIA ADUA, 283,21042 Caronno Pertusella (VA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5974858",
    lng: "9.0415359",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12432",
  },
  {
    id: "56299",
    map_id: "1",
    address: "Via Magellano, 4,84098 Pontecagnano Faiano (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6275842",
    lng: "14.9087266",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12433",
  },
  {
    id: "56298",
    map_id: "1",
    address: "CONTRADA ROTTA CANNAVA',SNC,89016 RIZZICONI (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.3867049",
    lng: "15.9428794",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12434",
  },
  {
    id: "56297",
    map_id: "1",
    address: "Viale Mangini, 383,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3104668",
    lng: "16.2538617",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12435",
  },
  {
    id: "56296",
    map_id: "1",
    address: "Via Baiesi, 19/H-I,40011 ANZOLA DELL'EMILIA (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.54483949999999",
    lng: "11.1930904",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12437",
  },
  {
    id: "56295",
    map_id: "1",
    address: "Via Cacciatori delle Alpi, 300,97019 Vittoria (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.9471756",
    lng: "14.5285008",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12438",
  },
  {
    id: "56294",
    map_id: "1",
    address: "VIA AUTOSTRADA, 14,24126 Bergamo (BG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6865494",
    lng: "9.6678403",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12440",
  },
  {
    id: "56293",
    map_id: "1",
    address: "Piazza Generale Finiti, 10,02040 Cottanello (RI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.4079187",
    lng: "12.6871849",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12443",
  },
  {
    id: "56292",
    map_id: "1",
    address: "Via Vaticale, 119,81033 Casal di Principe (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0092542",
    lng: "14.1317741",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12444",
  },
  {
    id: "56291",
    map_id: "1",
    address: "PIAZZA ATEO CASALINI, 1,58025 MONTEROTONDO MARITTIMO (GR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.145492",
    lng: "10.855752",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12445",
  },
  {
    id: "56290",
    map_id: "1",
    address: "Via F. Sofia Alessio, 231/233,89029 Taurianova (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.35823269999999",
    lng: "16.0240455",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12447",
  },
  {
    id: "56289",
    map_id: "1",
    address: "Corso della Giovecca, 134/a,44121 Ferrara (FE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.83536",
    lng: "11.6257",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12449",
  },
  {
    id: "56288",
    map_id: "1",
    address: "PIAZZA DELLA PACE 26,00042 Anzio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4473336",
    lng: "12.6277309",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12452",
  },
  {
    id: "56287",
    map_id: "1",
    address: "VIA GARIBALDI, 23,40054 Budrio (BO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.5380166",
    lng: "11.5363414",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12455",
  },
  {
    id: "56286",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE, 119/121,80030 Carbonara di Nola (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8762887",
    lng: "14.5776676",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12456",
  },
  {
    id: "56285",
    map_id: "1",
    address: "VIA DELLA CONOCCHIA, SNC,86170 Isernia (IS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6147901",
    lng: "14.217173",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12458",
  },
  {
    id: "56284",
    map_id: "1",
    address: "Via Anticoli Corrado, 35,00012 Guidonia Montecelio (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.95850590000001",
    lng: "12.6516751",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12461",
  },
  {
    id: "56283",
    map_id: "1",
    address: "CSO INDIPENDENZA, 5,97011 Acate (RG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0245118",
    lng: "14.4875004",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12462",
  },
  {
    id: "56282",
    map_id: "1",
    address: "Via Cristoforo Colombo, 224,30015 Chioggia (VE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.20174859999999",
    lng: "12.2985143",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12465",
  },
  {
    id: "56281",
    map_id: "1",
    address: "VIA MAIELLA, 37/F,65010 Spoltore (PE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.4229031",
    lng: "14.1580537",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12466",
  },
  {
    id: "56280",
    map_id: "1",
    address: "MARINA DEL MONDO , SN,70043 Monopoli (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9591891",
    lng: "17.2923117",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12467",
  },
  {
    id: "56279",
    map_id: "1",
    address: "Viale Pierluigi Nervi c/o Latinafiori,04100 Latina (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.4651172",
    lng: "12.8902873",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12471",
  },
  {
    id: "56278",
    map_id: "1",
    address: "Piazza Don Luigi Sturzo, 33,04011 Aprilia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5952379",
    lng: "12.6448508",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12472",
  },
  {
    id: "56277",
    map_id: "1",
    address: "VIA FRANCESCO BARACCA, 51,80016 Marano di Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.900427",
    lng: "14.189588",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12473",
  },
  {
    id: "56276",
    map_id: "1",
    address: "VIA SIMILI, 26,95129 Catania (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.5147374",
    lng: "15.0964812",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12474",
  },
  {
    id: "56275",
    map_id: "1",
    address: "Via Liverpool, 2/d,04011 Aprilia (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.57783269999999",
    lng: "12.6687251",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12475",
  },
  {
    id: "56274",
    map_id: "1",
    address: "Via Vespri, 177,93016 Riesi (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.2772161",
    lng: "14.0809175",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12476",
  },
  {
    id: "56273",
    map_id: "1",
    address: "Via Catania, 44,96100 Siracusa (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0677467",
    lng: "15.2848627",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12477",
  },
  {
    id: "56272",
    map_id: "1",
    address: "PIAZZA GIOVANNI LATERZA, 14,70017 Putignano (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8515299",
    lng: "17.12316",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12485",
  },
  {
    id: "56271",
    map_id: "1",
    address: "VIA ARMANDO DIAZ, 32,97019 Vittoria (RG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "36.9571927",
    lng: "14.5280225",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12486",
  },
  {
    id: "56270",
    map_id: "1",
    address: "Via Nazionale, 30,16121 Sestri Levante (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.2731387",
    lng: "9.398152999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12489",
  },
  {
    id: "56269",
    map_id: "1",
    address: "VIA S. ANTONIO, 47,80021 Afragola (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9173579",
    lng: "14.3066473",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12491",
  },
  {
    id: "56268",
    map_id: "1",
    address: "VIA REMBRANDT, 60,20147 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4680219",
    lng: "9.132939",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12492",
  },
  {
    id: "56267",
    map_id: "1",
    address: "Via Spluga, 15,23870 Cernosco Lombardone (LC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6968656",
    lng: "9.4051703",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12493",
  },
  {
    id: "56266",
    map_id: "1",
    address: "PIANO SAN ROCCO 14,71042 Cerignola (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2623252",
    lng: "15.898376",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12496",
  },
  {
    id: "56265",
    map_id: "1",
    address: "Piazza della Costituzione, 2,76017 San Ferdinando in Puglia (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.30189",
    lng: "16.0675226",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12497",
  },
  {
    id: "56264",
    map_id: "1",
    address: "Via Balda, 3,85024 Lavello (PZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0447202",
    lng: "15.7899766",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12498",
  },
  {
    id: "56263",
    map_id: "1",
    address: "PIAZZA DELLA SERENISSIMA, 20/12,31033 Castelfranco Veneto (TV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.6827027",
    lng: "11.9411435",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12499",
  },
  {
    id: "56262",
    map_id: "1",
    address: "Corso Umberto I, 410,90036 Ficarazzi (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.0881391",
    lng: "13.4629845",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12500",
  },
  {
    id: "56261",
    map_id: "1",
    address: "Corso Nazionale, 24,84018 Scafati (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7500467",
    lng: "14.5258237",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12502",
  },
  {
    id: "56260",
    map_id: "1",
    address: "Via Passanti, 41,84018 Scafati (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7560126",
    lng: "14.5241328",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12503",
  },
  {
    id: "56259",
    map_id: "1",
    address: "SS. 652 Km.11.400,86087 Rionero Sannitico (IS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.72160119999999",
    lng: "14.1230015",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12504",
  },
  {
    id: "56258",
    map_id: "1",
    address: "VIALE CALABRIA, 20,20092 Cinisello Balsamo (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5478193",
    lng: "9.2269186",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12507",
  },
  {
    id: "56257",
    map_id: "1",
    address: "VIA MARCANTONIO BRAGADIN, 107,00136 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9068294",
    lng: "12.4447321",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12508",
  },
  {
    id: "56256",
    map_id: "1",
    address: "VIA MARTIRI DELLA LIBERTA', 12 BIS,10024 Moncalieri (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.9996227",
    lng: "7.6783848",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12510",
  },
  {
    id: "56255",
    map_id: "1",
    address: "Piazza G. Marconi, 51,10048 Vinovo (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.948844",
    lng: "7.6337774",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12511",
  },
  {
    id: "56254",
    map_id: "1",
    address: "VIA SPANNA, 1/5,10095 Grugliasco (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0630552",
    lng: "7.5761407",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12512",
  },
  {
    id: "56253",
    map_id: "1",
    address: "PIAZZA PIO XII, 10,72025 San Donaci (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4464923",
    lng: "17.9223408",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12513",
  },
  {
    id: "56252",
    map_id: "1",
    address: "Via Prenestina Km 7,800,00155 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8941121",
    lng: "12.5784015",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12514",
  },
  {
    id: "56251",
    map_id: "1",
    address: "CSO MARCELLI, 291,86170 Isernia (IS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.5907295",
    lng: "14.2271042",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12523",
  },
  {
    id: "56250",
    map_id: "1",
    address: "VIA LAGARIA, 127,87071 AMENDOLARA (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9402695",
    lng: "16.608311",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12527",
  },
  {
    id: "56249",
    map_id: "1",
    address: "Via di Faltugnano, 6,59021 Vaiano (PO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.9214152",
    lng: "11.1264483",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12528",
  },
  {
    id: "56248",
    map_id: "1",
    address: "Via Salvatore Benenati, 4PR,91011 Alcamo (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.9736621",
    lng: "12.9608469",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12530",
  },
  {
    id: "56247",
    map_id: "1",
    address: "VIA ROMA, 86,80054 Gragnano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6903607",
    lng: "14.517681",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12531",
  },
  {
    id: "56246",
    map_id: "1",
    address: "VIA TORINO, 48,10042 Nichelino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.00191239999999",
    lng: "7.655252799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12533",
  },
  {
    id: "56245",
    map_id: "1",
    address: "VIA CARLO DEL BALZO, 17,83012 Cervinara (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0211322",
    lng: "14.6178222",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12534",
  },
  {
    id: "56244",
    map_id: "1",
    address: "Via David Livingstone, 9,09126 Cagliari (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.1934481",
    lng: "9.1400268",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12535",
  },
  {
    id: "56243",
    map_id: "1",
    address: "Piazza Marconi, 7/d,20871 Vimercate (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6101329",
    lng: "9.3710145",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12537",
  },
  {
    id: "56242",
    map_id: "1",
    address: "Via De Gasperi, 145,23807 Merate (LC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.69569670000001",
    lng: "9.4278929",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12539",
  },
  {
    id: "56241",
    map_id: "1",
    address: "Via Roma, snc,84087 Sarno (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.81093250000001",
    lng: "14.6168708",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12541",
  },
  {
    id: "56240",
    map_id: "1",
    address: "Piazza Mercato, 11,10024 Moncalieri (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0013055",
    lng: "7.6726293",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12545",
  },
  {
    id: "56239",
    map_id: "1",
    address: "Via Chieri, 15,10020 Andezeno (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.03484880000001",
    lng: "7.865722300000001",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12546",
  },
  {
    id: "56238",
    map_id: "1",
    address: "CORSO EUROPA, 364,81030 San Marcellino (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.99719169999999",
    lng: "14.1596678",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12549",
  },
  {
    id: "56237",
    map_id: "1",
    address: "Via Antonio Gramsci, 12,84091 Battipaglia (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.607781",
    lng: "14.9838735",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12551",
  },
  {
    id: "56236",
    map_id: "1",
    address: "VIA GALILEO GALILEI 62,80022 Arzano (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9095199",
    lng: "14.2649462",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12552",
  },
  {
    id: "56235",
    map_id: "1",
    address: "VIA LIBERTA' 22,80038 Pomigliano d'Arco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9101303",
    lng: "14.3829123",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12553",
  },
  {
    id: "56234",
    map_id: "1",
    address: "VIA DEL CASSANO, 9,80020 Casavatore (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9005039",
    lng: "14.2737699",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12554",
  },
  {
    id: "56233",
    map_id: "1",
    address: "PIAZZA SAN MAURO, 22,84014 Nocera Inferiore (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7653448",
    lng: "14.6328476",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12556",
  },
  {
    id: "56232",
    map_id: "1",
    address: "VIA G. STANISLAO, 19,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3196976",
    lng: "16.2469589",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12558",
  },
  {
    id: "56231",
    map_id: "1",
    address: "Corso Italia, snc,87046 Montalto Uffugo (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.43076",
    lng: "16.23243",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12559",
  },
  {
    id: "56230",
    map_id: "1",
    address: "Via Mare, 15,91021 Campobello di Mazara (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.634285",
    lng: "12.744394",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12560",
  },
  {
    id: "56229",
    map_id: "1",
    address: "Via Redentore, 229/a,93100 Caltanissetta (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.4933796",
    lng: "14.0625114",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12563",
  },
  {
    id: "56228",
    map_id: "1",
    address: "PIAZZA EUROPA, 16,84020 OLIVETO CITRA (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6885315",
    lng: "15.2318661",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12565",
  },
  {
    id: "56227",
    map_id: "1",
    address: "Via De Nicola, 13,50121 Reggello (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6758856",
    lng: "11.527514",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12566",
  },
  {
    id: "56226",
    map_id: "1",
    address: "Piazza Mazzini, 31,50027 Strada in Chianti (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6576521",
    lng: "11.2999472",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12568",
  },
  {
    id: "56225",
    map_id: "1",
    address: "Via Dottor Giuliano, 10,71019 Vieste (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8824764",
    lng: "16.1767489",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12571",
  },
  {
    id: "56224",
    map_id: "1",
    address: "VIA ROMA, 136/138,80030 Cimitile (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.942621",
    lng: "14.529828",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12573",
  },
  {
    id: "56223",
    map_id: "1",
    address: "Corso Garibaldi, 183,91010 Vita (TP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.8713844",
    lng: "12.8186492",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12574",
  },
  {
    id: "56222",
    map_id: "1",
    address: "Via Vittorio Veneto, 24,07026 Olbia (SS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9236282",
    lng: "9.495700099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12576",
  },
  {
    id: "56221",
    map_id: "1",
    address: "Piazza della Regione, 7,93017 San Cataldo (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.4881979",
    lng: "13.9906428",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12578",
  },
  {
    id: "56220",
    map_id: "1",
    address: "VIA MONTE BIANCO, 9,96010 PORTOPALO DI CAPO PASSERO (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.6848286",
    lng: "15.1330167",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12579",
  },
  {
    id: "56219",
    map_id: "1",
    address: "Via Torino, 43,10028 Trofarello (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.984334",
    lng: "7.7388838",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12581",
  },
  {
    id: "56218",
    map_id: "1",
    address: "Via Cellino, 18/a,72027 San Pietro Vernotico (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.4869302",
    lng: "17.9951362",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12583",
  },
  {
    id: "56217",
    map_id: "1",
    address: "VIA BARTOLOMEO PERESTRELLO, 31,00176 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8853687",
    lng: "12.5419917",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12585",
  },
  {
    id: "56216",
    map_id: "1",
    address: "VIALE EUROPA, 122,04020 Monte San Biagio (LT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.3527072",
    lng: "13.3473381",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12586",
  },
  {
    id: "56215",
    map_id: "1",
    address: "VIA PROVINCIALE , 57,81030 VILLA DI BRIANO (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0007622",
    lng: "14.1486662",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12587",
  },
  {
    id: "56214",
    map_id: "1",
    address: "Via Armando Diaz, sn,73024 Maglie (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.117602",
    lng: "18.2963509",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12589",
  },
  {
    id: "56213",
    map_id: "1",
    address: "Via Aldo Moro, 27,73100 Lecce (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.3560741",
    lng: "18.1947491",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12590",
  },
  {
    id: "56212",
    map_id: "1",
    address: "Via Alto Adige, 8,73042 Casarano (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.0173726",
    lng: "18.1621795",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12591",
  },
  {
    id: "56211",
    map_id: "1",
    address: "Via Pirandello, Sn,73039 Tricase (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.9270007",
    lng: "18.359892",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12592",
  },
  {
    id: "56210",
    map_id: "1",
    address: "Via Nicola De Giosa, 78,70121 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1205727",
    lng: "16.8739971",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12593",
  },
  {
    id: "56209",
    map_id: "1",
    address: "Via Soleto, 54,73025 Martano (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2018536",
    lng: "18.2998808",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12594",
  },
  {
    id: "56208",
    map_id: "1",
    address: "Via Aldo Moro, 79,72100 Brindisi (BR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6279387",
    lng: "17.935058",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12595",
  },
  {
    id: "56207",
    map_id: "1",
    address: "Corso Mazzini, 282/280,57126 Livorno (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.5425019",
    lng: "10.3052971",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12597",
  },
  {
    id: "56206",
    map_id: "1",
    address: "Via Casilina, 543,00177 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8792563",
    lng: "12.5446717",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12598",
  },
  {
    id: "56205",
    map_id: "1",
    address: "Strada Bixio, 83,43125 Parma (PR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.7984648",
    lng: "10.3200614",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12599",
  },
  {
    id: "56204",
    map_id: "1",
    address: "Via Leuciana, snc,03020 Pico (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.4544368",
    lng: "13.6617108",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12602",
  },
  {
    id: "56203",
    map_id: "1",
    address: "PIAZZA DELLA REPUBBLICA, 17,87019 Spezzano Albanese (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.6671695",
    lng: "16.3101418",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12609",
  },
  {
    id: "56202",
    map_id: "1",
    address: "VIA EROI DI SAPRI, snc,88046 Lamezia Terme (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9631951",
    lng: "16.2839716",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12613",
  },
  {
    id: "56201",
    map_id: "1",
    address: "Via per Erba, 39,22030 Orsenigo (CO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.7852992",
    lng: "9.1930347",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12615",
  },
  {
    id: "56200",
    map_id: "1",
    address: "Via Tisia, 24,96100 Siracusa (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.0837578",
    lng: "15.2899328",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12617",
  },
  {
    id: "56199",
    map_id: "1",
    address: "Via Filippo Turati, 415,80040 Poggiomarino (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.813784",
    lng: "14.5300854",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12621",
  },
  {
    id: "56198",
    map_id: "1",
    address: "VIA CIAIA, 3,20141 Milano (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.5021334",
    lng: "9.175759099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12623",
  },
  {
    id: "56197",
    map_id: "1",
    address: "Via Volvinio, 46,20141 Milano (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.434097",
    lng: "9.1835015",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12624",
  },
  {
    id: "56196",
    map_id: "1",
    address: "Via della Liberazione, 30,20094 Corsico (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.4402992",
    lng: "9.1039783",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12625",
  },
  {
    id: "56195",
    map_id: "1",
    address: "Via Matteotti, 4,20067 PAULLO (MI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.4179219",
    lng: "9.400145",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12626",
  },
  {
    id: "56194",
    map_id: "1",
    address: "VIA TRIESTE, 84,33052 Cervignano del Friuli (UD)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.8203186",
    lng: "13.3404683",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12629",
  },
  {
    id: "56193",
    map_id: "1",
    address: "VIA NOCE, 35,90100 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1203531",
    lng: "13.338252",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12631",
  },
  {
    id: "56192",
    map_id: "1",
    address: "Via A. Diaz, 14/c,90123 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1012377",
    lng: "13.3939191",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12632",
  },
  {
    id: "56191",
    map_id: "1",
    address: "VIA ASSUNZIONE, 76/78,57032 Capraia Isola (LI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.0506441",
    lng: "9.8352939",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12634",
  },
  {
    id: "56190",
    map_id: "1",
    address: "VIALE DELLA REGIONE, 23/27,93100 Caltanissetta (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.4933528",
    lng: "14.0499464",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12638",
  },
  {
    id: "56189",
    map_id: "1",
    address: "Via della Farnesina, 19/A,00194 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9384363",
    lng: "12.4644376",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12639",
  },
  {
    id: "56188",
    map_id: "1",
    address: "Via Dante Alighieri, 41,84018 Scafati (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7500198",
    lng: "14.5350316",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12640",
  },
  {
    id: "56187",
    map_id: "1",
    address: "Via Risorgimento, 34/a,31044 Montebelluna (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.77528210000001",
    lng: "12.0528341",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12641",
  },
  {
    id: "56186",
    map_id: "1",
    address: "Via S.Pio X, 17/3,31033 Castelfranco Veneto (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6744207",
    lng: "11.9271669",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12642",
  },
  {
    id: "56185",
    map_id: "1",
    address: "Piazza San Marco, 60,31017 Pieve Del Grappa (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.82923",
    lng: "11.841324",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12643",
  },
  {
    id: "56184",
    map_id: "1",
    address: "Viale Venezia, 40,36022 Cassola (VI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.7700886",
    lng: "11.7511599",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12644",
  },
  {
    id: "56183",
    map_id: "1",
    address: "VIA ENRICO FERMI, 56,24035 CURNO (BG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6906286",
    lng: "9.624216599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12645",
  },
  {
    id: "56182",
    map_id: "1",
    address: "Via Sileno, 85,00155 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9113015",
    lng: "12.6073462",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12647",
  },
  {
    id: "56181",
    map_id: "1",
    address: "Via Filippo Corridoni 152/a,00030 San Cesareo (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8198271",
    lng: "12.8000248",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12649",
  },
  {
    id: "56180",
    map_id: "1",
    address: "Corso Europa, 30,28922 Verbania (VB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.92820649999999",
    lng: "8.554171799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12650",
  },
  {
    id: "56179",
    map_id: "1",
    address: "Via Etnea, 36,95030 Nicolosi (CT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.613762",
    lng: "15.02654",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12664",
  },
  {
    id: "56178",
    map_id: "1",
    address: "Corso Italia, 175,95018 Riposto (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.7308266",
    lng: "15.2001242",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12665",
  },
  {
    id: "56177",
    map_id: "1",
    address: "Corso Torino, 46/C,28100 Novara (NO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4391116",
    lng: "8.6085118",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12666",
  },
  {
    id: "56176",
    map_id: "1",
    address: "VIA PORTA PALERMO, 308,92015 Raffadali (AG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.4068227",
    lng: "13.5309353",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12667",
  },
  {
    id: "56175",
    map_id: "1",
    address: "Via Grassi, 32,73048 NARDO' (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.1792787",
    lng: "18.0345645",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12668",
  },
  {
    id: "56174",
    map_id: "1",
    address: "Via Regalantuomo, 51,73043 Copertino (LE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.2740546",
    lng: "18.0465055",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12669",
  },
  {
    id: "56173",
    map_id: "1",
    address: "Via Stanislao Manna, 2,80126 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8481284",
    lng: "14.1985632",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12670",
  },
  {
    id: "56172",
    map_id: "1",
    address: "Via Circonvallazione, 34,11029 Verres (AO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6633827",
    lng: "7.6904275",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12682",
  },
  {
    id: "56171",
    map_id: "1",
    address: "Via Sant'Ambrogio, 61,10040 Villar Dora (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.109194",
    lng: "7.3831945",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12686",
  },
  {
    id: "56170",
    map_id: "1",
    address: "Via Cruto, 26,10091 Alpignano (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0955124",
    lng: "7.523619099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12687",
  },
  {
    id: "56169",
    map_id: "1",
    address: "VIALE EUROPA, 73/U2,70132 Bari (BA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.1234253",
    lng: "16.8022596",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12688",
  },
  {
    id: "56168",
    map_id: "1",
    address: "Via Enrico Onufrio, 26,90144 Palermo (PA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "38.1345153",
    lng: "13.342635",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12693",
  },
  {
    id: "56167",
    map_id: "1",
    address: "Via Calli 197 Frazione Quadrivio,84022 Campagna (SA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.62875630000001",
    lng: "15.0979774",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12694",
  },
  {
    id: "56166",
    map_id: "1",
    address: "Via Redipuglia, 8,88046 Lamezia Terme (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.962912",
    lng: "16.3092719",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12696",
  },
  {
    id: "56165",
    map_id: "1",
    address: "Via Boccioni, 41,88046 Lamezia Terme (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9167569",
    lng: "16.2519842",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12697",
  },
  {
    id: "56164",
    map_id: "1",
    address: "Corso Chiaiano, 8,80145 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8923994",
    lng: "14.2193691",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12702",
  },
  {
    id: "56163",
    map_id: "1",
    address: "Largo Sicilia, 1,96012 Avola (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.903275",
    lng: "15.136882",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12703",
  },
  {
    id: "56162",
    map_id: "1",
    address: "VIA IGNAZIO E MANFREDI LANZA 9,90129 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1213182",
    lng: "13.3449717",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12711",
  },
  {
    id: "56161",
    map_id: "1",
    address: "SS 113 KM 327,91011 Trapani (TP)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.9869111",
    lng: "12.9746075",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12712",
  },
  {
    id: "56160",
    map_id: "1",
    address: "Via Michele Pietravalle, 49,80131 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8687575",
    lng: "14.2237475",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12723",
  },
  {
    id: "56159",
    map_id: "1",
    address: "Via Cavour, 79/D,20030 Senago (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.58241109999999",
    lng: "9.132210599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12725",
  },
  {
    id: "56158",
    map_id: "1",
    address: "Via Materdei, 35,80136 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8558342",
    lng: "14.245904",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12731",
  },
  {
    id: "56157",
    map_id: "1",
    address: "Via Gramsci, 22,81020 S. Marco Evangelsita (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.037635",
    lng: "14.3408135",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12732",
  },
  {
    id: "56156",
    map_id: "1",
    address: "Via Quintino Sella, 106/108,91022 Castelvetrano (TP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.6852469",
    lng: "12.7936178",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12751",
  },
  {
    id: "56155",
    map_id: "1",
    address: "Via Cantone, 2,81010 Prata Sannita (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.432159",
    lng: "14.2037118",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12753",
  },
  {
    id: "56154",
    map_id: "1",
    address: "Via XXV Aprile 96,25038 Rovato (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.54645",
    lng: "10.03189",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12754",
  },
  {
    id: "56153",
    map_id: "1",
    address: "Via San Antonino, 27,25068 Sarezzo (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6521677",
    lng: "10.20055",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12755",
  },
  {
    id: "56152",
    map_id: "1",
    address: "Via Cesare Battisti, 23/25,25032 Chiari (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5387165",
    lng: "9.9297688",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12756",
  },
  {
    id: "56151",
    map_id: "1",
    address: "Via Roncadelle, 43,25030 CASTEL MELLA (BS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.52189",
    lng: "10.1500073",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12757",
  },
  {
    id: "56150",
    map_id: "1",
    address: "Viale Monza, 173,20126 Milano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.50303050000001",
    lng: "9.2214376",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12758",
  },
  {
    id: "56149",
    map_id: "1",
    address: "Via Salvatore Scervini, 5/19,87041 Acri (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.4947524",
    lng: "16.3802992",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12759",
  },
  {
    id: "56148",
    map_id: "1",
    address: "Via Monte Circeo, snc,04017 San Felice Circeo (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2454528",
    lng: "13.0924702",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12760",
  },
  {
    id: "56147",
    map_id: "1",
    address: "Via Arnale Rosso, 26,04022 Fondi (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.3554026",
    lng: "13.4333042",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12761",
  },
  {
    id: "56146",
    map_id: "1",
    address: "Via Torino, 16,04016 Sabaudia (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.29924250000001",
    lng: "13.0323007",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12762",
  },
  {
    id: "56145",
    map_id: "1",
    address: "Via Piagge Marine, 259,04018 Sezze (LT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.494777",
    lng: "13.068547",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12763",
  },
  {
    id: "56144",
    map_id: "1",
    address: "VIA BEATO FRANCESCO MARINO, 59,87040 ZUMPANO (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3292442",
    lng: "16.2611409",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12764",
  },
  {
    id: "56143",
    map_id: "1",
    address: "Largo Annunziata, 24,12089 Villanova MondovÃ¬ (CN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "44.34883250000001",
    lng: "7.772569299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12772",
  },
  {
    id: "56142",
    map_id: "1",
    address: "Via Cinquevie, 46,80021 Afragola (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.9299116",
    lng: "14.3148296",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12776",
  },
  {
    id: "56141",
    map_id: "1",
    address: "VIA PIAVE, 88,03038 Roccasecca (FR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.5288099",
    lng: "13.6473318",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12784",
  },
  {
    id: "56140",
    map_id: "1",
    address: "VIA SANT'ANGELO, 247,83036 Mirabella Eclano (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.0456551",
    lng: "15.0051422",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12786",
  },
  {
    id: "56139",
    map_id: "1",
    address: "VIA MONGOLFIER, 16,09028 Sestu (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2973488",
    lng: "9.0934826",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12794",
  },
  {
    id: "56138",
    map_id: "1",
    address: "VIA GARIBALDI 10,09025 SANLURI (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.5614404",
    lng: "8.898904",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12795",
  },
  {
    id: "56137",
    map_id: "1",
    address: "Via Carlo sanna, 223,09016 SenorbÃ¬ (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.5374588",
    lng: "9.130344299999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12796",
  },
  {
    id: "56136",
    map_id: "1",
    address: "Via Gramsci, 197,09013 Carbonia (SU)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.1699722",
    lng: "8.521606799999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12797",
  },
  {
    id: "56135",
    map_id: "1",
    address: "Via Maroncelli, 18/F,35127 Padova (PD)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.4145176",
    lng: "11.9047941",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12836",
  },
  {
    id: "56134",
    map_id: "1",
    address: "Via Venezia, 64,81034 Mondragone (CE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1126444",
    lng: "13.8913863",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12837",
  },
  {
    id: "56133",
    map_id: "1",
    address: "Via Ciccarone, 10,66054 Vasto (CH)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.1174799",
    lng: "14.7089713",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12839",
  },
  {
    id: "56132",
    map_id: "1",
    address: "Via Alcide De Gasperi, 59,06031 Bevagna (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "42.94042580000001",
    lng: "12.6085004",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12857",
  },
  {
    id: "56131",
    map_id: "1",
    address: "Corso Italia, 228,80063 Piano di Sorrento (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.6303397",
    lng: "14.4020354",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12859",
  },
  {
    id: "56130",
    map_id: "1",
    address: "Via Nazionale, 21,23823 Colico (LC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "46.13593340000001",
    lng: "9.3716706",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12860",
  },
  {
    id: "56129",
    map_id: "1",
    address: "VIA GIUSEPPE PESOLA, 41/E-F,71121 Foggia (FG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.4570072",
    lng: "15.5525666",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12876",
  },
  {
    id: "56128",
    map_id: "1",
    address: "Via Renato Martorelli, 73,10155 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.09767040000001",
    lng: "7.693795099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12877",
  },
  {
    id: "56127",
    map_id: "1",
    address: "Corso Corsica, 30,10137 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0315537",
    lng: "7.6517344",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12878",
  },
  {
    id: "56126",
    map_id: "1",
    address: "Corso Sebastopoli, 145/d,10137 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.0438043",
    lng: "7.648495899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12879",
  },
  {
    id: "56125",
    map_id: "1",
    address: "Via Crotone, 25,88841 Isola di Capo Rizzuto (KR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.95983409999999",
    lng: "17.0931343",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12885",
  },
  {
    id: "56124",
    map_id: "1",
    address: "VIA ROMA, 271,57014 COLLESALVETTI (LI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.5895058",
    lng: "10.4745279",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12894",
  },
  {
    id: "56123",
    map_id: "1",
    address: "VIA CIRCONVALLAZIONE SUD, 49,58014 MANCIANO (GR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "42.587572",
    lng: "11.518194",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12895",
  },
  {
    id: "56122",
    map_id: "1",
    address: "Via D.Chiesa, 15,36016 Thiene (VI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.7089644",
    lng: "11.4810946",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12897",
  },
  {
    id: "56121",
    map_id: "1",
    address: "Corte Nocera, 7,82030 San Salvatore Telesino (BN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.235954",
    lng: "14.4929754",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12899",
  },
  {
    id: "56120",
    map_id: "1",
    address: "Via Ottaviano, 196,80040 San Gennaro Vesuviano (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.8592208",
    lng: "14.5188076",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12906",
  },
  {
    id: "56119",
    map_id: "1",
    address: "Via della Stazione di Palidoro, 4,00054 Fiumicino (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.9252604",
    lng: "12.1837768",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12907",
  },
  {
    id: "56118",
    map_id: "1",
    address: "Via Cavour, 16,56041 Castelnuovo Val Di Cecina (GR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.2076753",
    lng: "10.9079044",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12919",
  },
  {
    id: "56117",
    map_id: "1",
    address: "Via San Martino, 208,50013 Campi Bisenzio (FI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.8058218",
    lng: "11.1181919",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12920",
  },
  {
    id: "56116",
    map_id: "1",
    address: "Via Generale Ciancio, 40,94015 Piazza Armerina (EN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.3878035",
    lng: "14.3714175",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12921",
  },
  {
    id: "56115",
    map_id: "1",
    address: "Piazza Felice Cavallotti , 32,71016 San Severo (FG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.6910394",
    lng: "15.3817442",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12922",
  },
  {
    id: "56114",
    map_id: "1",
    address: "Via Cavour, 5,89030 Bruzzano Zeffirio (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "38.0115904",
    lng: "16.082687",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12947",
  },
  {
    id: "56113",
    map_id: "1",
    address: "PIAZZA DEL TEATRO, 2,56025 Pontedera (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6644079",
    lng: "10.6388581",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12974",
  },
  {
    id: "56112",
    map_id: "1",
    address: "VIA TOSCO ROMAGNOLA, 160,56021 Cascina (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6762548",
    lng: "10.5539362",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12976",
  },
  {
    id: "56111",
    map_id: "1",
    address: "VIA VITTORIO VENETO, 1/C,56121 Pisa (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.72030600000001",
    lng: "10.41124",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12977",
  },
  {
    id: "56110",
    map_id: "1",
    address: "VIA GARIBALDI, 103,81030 Orta di Atella (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9681501",
    lng: "14.2709591",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12978",
  },
  {
    id: "56109",
    map_id: "1",
    address: "Borgo Cividale, 3,34134 Palmanova (UD)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.9056952",
    lng: "13.3112526",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12988",
  },
  {
    id: "56108",
    map_id: "1",
    address: "Via Roma, 22,34134 San Giorgio di Nogaro (UD)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.83208010000001",
    lng: "13.2087653",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12989",
  },
  {
    id: "56107",
    map_id: "1",
    address: "VIA TOSCO ROMAGNOLA EST, 473,56028 San Miniato (PI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.6932308",
    lng: "10.8400396",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12991",
  },
  {
    id: "56106",
    map_id: "1",
    address: "Via Panebianco, 498,87100 Cosenza (CS)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.3146896",
    lng: "16.2463272",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12992",
  },
  {
    id: "56105",
    map_id: "1",
    address: "Via G. Falcone, 85-87,76012 Canosa di Puglia (BT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.2156979",
    lng: "16.0784678",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "12993",
  },
  {
    id: "56104",
    map_id: "1",
    address: "VIA XXV APRILE, 7,89047 ROCCELLA IONICA (RC)",
    description: "RICARICHE - SPEDIZIONI",
    lat: "38.3222761",
    lng: "16.4047525",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13007",
  },
  {
    id: "56103",
    map_id: "1",
    address: "VIA NIZZA, 113,96012 Avola (SR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "36.9087305",
    lng: "15.1387313",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13014",
  },
  {
    id: "56102",
    map_id: "1",
    address: "CORSO GIULIO CESARE, 268,10154 Torino (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.10382",
    lng: "7.7031182",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13015",
  },
  {
    id: "56101",
    map_id: "1",
    address: "VIA ROMA, 234,80017 Melito di Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.92153830000001",
    lng: "14.2306711",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13019",
  },
  {
    id: "56100",
    map_id: "1",
    address: "VIA MOLINO PREPOSITURALE, 114,20017 Rho (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.52731720000001",
    lng: "9.028392600000002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13021",
  },
  {
    id: "56099",
    map_id: "1",
    address: "Viale Tondo, Snc,89861 Tropea (VV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.67487570000001",
    lng: "15.8961674",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13027",
  },
  {
    id: "56098",
    map_id: "1",
    address: "VIALE MICHELANGELO, SNC,80040 Volla (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8753471",
    lng: "14.3486892",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13034",
  },
  {
    id: "56097",
    map_id: "1",
    address: "Via 26 Ottobre 1860, 6,80049 Somma Vesuviana (NA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8727128",
    lng: "14.4406644",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13035",
  },
  {
    id: "56096",
    map_id: "1",
    address: "Via Giovanni De Falco, 201,80041 BOSCOREALE (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7745341",
    lng: "14.483781",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13057",
  },
  {
    id: "56095",
    map_id: "1",
    address: "Via Giorgio La Pira, 5,98051 Terme Vigliatore (ME)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1373173",
    lng: "15.1629369",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13063",
  },
  {
    id: "56094",
    map_id: "1",
    address: "VIA TRIVULZIO GIACOMO, 140,27029 Vigevano (PV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.3182807",
    lng: "8.8502485",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13064",
  },
  {
    id: "56093",
    map_id: "1",
    address: "VIA ALESSANDRO MANZONI, 332,06135 Perugia (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.0916056",
    lng: "12.4449319",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13068",
  },
  {
    id: "56092",
    map_id: "1",
    address: "Via Donizetti, 37,06132 Perugia (PG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "43.0838186",
    lng: "12.3477686",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13069",
  },
  {
    id: "56091",
    map_id: "1",
    address: "CORSO UMBERTO I, 210,81039 VILLA LITERNO (CE)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.0081133",
    lng: "14.080305",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13070",
  },
  {
    id: "56090",
    map_id: "1",
    address: "VIA DIETRO LA PARROCCHIA, 53,90146 Palermo (PA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.1748008",
    lng: "13.3335075",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13072",
  },
  {
    id: "56089",
    map_id: "1",
    address: "VIA ANTONIO SILVANI, 12,00139 Roma (RM)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "41.9503244",
    lng: "12.5181922",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13081",
  },
  {
    id: "56088",
    map_id: "1",
    address: "VIA GRAMSCI, 54,42024 CASTELNOVO DI SOTTO (RE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.8113525",
    lng: "10.561967",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13083",
  },
  {
    id: "56087",
    map_id: "1",
    address: "VIA P. NITTOLI, 22,83047 Lioni (AV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.8800474",
    lng: "15.1790334",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13085",
  },
  {
    id: "56086",
    map_id: "1",
    address: "Via Latina, 64,00179 Roma (RM)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.8739279",
    lng: "12.5102638",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13096",
  },
  {
    id: "56085",
    map_id: "1",
    address: "Corso Milano, 147,20813 Bovisio Masciago (MB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5993133",
    lng: "9.1458406",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13097",
  },
  {
    id: "56084",
    map_id: "1",
    address: "Via Sormani, 51,20095 Cusano Milanino (MI)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.5516173",
    lng: "9.1858735",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13098",
  },
  {
    id: "56083",
    map_id: "1",
    address: "Via Fratelli Cervi, 10,20812 Limbiate (MB)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.6033957",
    lng: "9.1216116",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13099",
  },
  {
    id: "56082",
    map_id: "1",
    address: "Corso Umberto I, 147,84098 Pontecagnano Faiano (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6450174",
    lng: "14.8752533",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13101",
  },
  {
    id: "56081",
    map_id: "1",
    address: "Via Italia ' 61, 27,84047 Capaccio Paestum (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.4248153",
    lng: "15.0769699",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13102",
  },
  {
    id: "56080",
    map_id: "1",
    address: "Via G. Marconi, 71,51028 San Marcello Pistoiese (PT)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.0993554",
    lng: "10.7551683",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13106",
  },
  {
    id: "56079",
    map_id: "1",
    address: "Via Piave, 69,84043 Agropoli (SA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.3515401",
    lng: "14.992369",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13109",
  },
  {
    id: "56078",
    map_id: "1",
    address: "Piazza Mazzini, 19,33019 Tricesimo (UD)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "46.1619414",
    lng: "13.2109576",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13114",
  },
  {
    id: "56077",
    map_id: "1",
    address: "Corso Roma, 35,58036 Grosseto (GR)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.0111584",
    lng: "11.167111",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13127",
  },
  {
    id: "56076",
    map_id: "1",
    address: "VIA FIRENZE 8,89044 Locri (RC)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.2344967",
    lng: "16.2641359",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13128",
  },
  {
    id: "56075",
    map_id: "1",
    address: "VIA LIBERTA' , 45,20092 Cinisello Balsamo (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5555721",
    lng: "9.2157211",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13129",
  },
  {
    id: "56074",
    map_id: "1",
    address: "Via Roma, 28,20021 Bollate (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5439436",
    lng: "9.116444099999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13131",
  },
  {
    id: "56073",
    map_id: "1",
    address: "Via Roma, 87,20091 Bresso (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5407126",
    lng: "9.1866712",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13132",
  },
  {
    id: "56072",
    map_id: "1",
    address: "Via Repubblica, 51,23807 Novate Milanese (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.53145989999999",
    lng: "9.136976899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13133",
  },
  {
    id: "56071",
    map_id: "1",
    address: "Corso Milano, 52,20825 Barlassina (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6568297",
    lng: "9.128791399999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13134",
  },
  {
    id: "56070",
    map_id: "1",
    address: "Via Nazionale dei Giovi, 13,20811 CESANO MADERNO (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6338651",
    lng: "9.1384309",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13135",
  },
  {
    id: "56069",
    map_id: "1",
    address: "PIAZZETTA SINISI, 3,74019 Palagiano (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.57925700000001",
    lng: "17.038078",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13136",
  },
  {
    id: "56068",
    map_id: "1",
    address: "VIALE MARCONI, 83,74016 Massafra (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.59191819999999",
    lng: "17.1152337",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13137",
  },
  {
    id: "56067",
    map_id: "1",
    address: "Piazza Garibaldi, 92,70021 Acquaviva delle Fonti (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.8961483",
    lng: "16.8444661",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13139",
  },
  {
    id: "56066",
    map_id: "1",
    address: "Via Puccini, 39,74014 Laterza (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.629696",
    lng: "16.7963041",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13140",
  },
  {
    id: "56065",
    map_id: "1",
    address: "Via Mazzini, 48,75025 Policoro (MT)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.2125901",
    lng: "16.6761829",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13141",
  },
  {
    id: "56064",
    map_id: "1",
    address: "Via Europa, 4,74017 Mottola (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.6358092",
    lng: "17.0342602",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13142",
  },
  {
    id: "56063",
    map_id: "1",
    address: "Via Isola di Caprera, 41,74013 Ginosa (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.578575",
    lng: "16.757197",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13143",
  },
  {
    id: "56062",
    map_id: "1",
    address: "Piazza Aldo Moro, 12,70010 Casamassima (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.9551828",
    lng: "16.9188172",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13144",
  },
  {
    id: "56061",
    map_id: "1",
    address: "Via Campania, 131,74121 Taranto (TA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.455785",
    lng: "17.2583503",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13145",
  },
  {
    id: "56060",
    map_id: "1",
    address: "VIA MAZZINI, 9,75013 Ferrandina (MT)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.4988108",
    lng: "16.4555105",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13153",
  },
  {
    id: "56059",
    map_id: "1",
    address: "Corso Giuseppe di Vittorio, 114,70024 Gravina in Puglia (BA)",
    description: "BOLLETTINI - PROMO130",
    lat: "40.820828",
    lng: "16.41973",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13156",
  },
  {
    id: "56058",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 59,83021 Avella (AV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.9613302",
    lng: "14.6017884",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13164",
  },
  {
    id: "56057",
    map_id: "1",
    address: "Salita dell'oro, Angolo Via Cairoli 23R,16124 Genova (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.4127462",
    lng: "8.9300375",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13169",
  },
  {
    id: "56056",
    map_id: "1",
    address: "Via Guido Reni, 77/C,35134 Padova (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.4357827",
    lng: "11.8913176",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13172",
  },
  {
    id: "56055",
    map_id: "1",
    address: "Corso Italia, 18,41058 VIGNOLA (MO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.4782963",
    lng: "11.0082798",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13173",
  },
  {
    id: "56054",
    map_id: "1",
    address: "Via Galleria San Giacomo, 35,41013 Castelfranco Emilia (MO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.594712",
    lng: "11.052291",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13174",
  },
  {
    id: "56053",
    map_id: "1",
    address: "Piazza Risorgimento, 6,41049 SASSUOLO (MO)",
    description: "BOLLETTINI - PROMO130",
    lat: "44.5404712",
    lng: "10.7871599",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13175",
  },
  {
    id: "56052",
    map_id: "1",
    address: "Via Claudia, 199,41053 Maranello (MO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.5267748",
    lng: "10.8639629",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13176",
  },
  {
    id: "56051",
    map_id: "1",
    address: "Piazza Vittorio Emanuele II, 22,45021 Badia Polesine (RO)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.094361",
    lng: "11.49137",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13177",
  },
  {
    id: "56050",
    map_id: "1",
    address: "Via Chiesanuova, 104/D,35136 Padova (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.41465090000001",
    lng: "11.8354526",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13178",
  },
  {
    id: "56049",
    map_id: "1",
    address: "Via Vigo di torre, 17,35042 Este (PD)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.2287502",
    lng: "11.6589836",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13179",
  },
  {
    id: "56048",
    map_id: "1",
    address: "Via S. Marco, 72,30015 Chioggia (VE)",
    description: "BOLLETTINI - PROMO130",
    lat: "45.2133108",
    lng: "12.2902497",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13180",
  },
  {
    id: "56047",
    map_id: "1",
    address: "Via Nazionale, 33/b Frazione Piano Lago,87050 Mangone (CS)",
    description: "AMAZON HUB SERVICE - BOLLETTINI - PROMO130",
    lat: "38.2084538",
    lng: "15.3424138",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13196",
  },
  {
    id: "56046",
    map_id: "1",
    address: "Via Santi Giovanni e Paolo, 18,80137 Napoli (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.86741",
    lng: "14.2682609",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13199",
  },
  {
    id: "56045",
    map_id: "1",
    address: "Via Porta Agrigento, 189,92015 Raffadali (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.3982565",
    lng: "13.5332188",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13207",
  },
  {
    id: "56044",
    map_id: "1",
    address: "VIA ROMA, 18,09048 Sinnai (CA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.2995957",
    lng: "9.198060900000002",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13208",
  },
  {
    id: "56043",
    map_id: "1",
    address: "Viale Giovanni Montini, 26,92029 Ravanusa (AG)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.2679192",
    lng: "13.9733159",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13232",
  },
  {
    id: "56042",
    map_id: "1",
    address: "Via Fucilari, 26,84014 Nocera Inferiore (SA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.742384",
    lng: "14.6426767",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13233",
  },
  {
    id: "56041",
    map_id: "1",
    address: "VIA NAZIONALE SS 106, SNC,88050 Cropani (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "39.018858",
    lng: "16.7419139",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13284",
  },
  {
    id: "56040",
    map_id: "1",
    address: "VIA LUDOVICO BREA, 3,18100 Imperia (IM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.8902016",
    lng: "8.0374926",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13285",
  },
  {
    id: "56039",
    map_id: "1",
    address: "Via Livio Salinatore, 24,00175 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8597833",
    lng: "12.5588271",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13286",
  },
  {
    id: "56038",
    map_id: "1",
    address: "Via Dietro le mura, 29/31/33,95021 Aci Castello (CT)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.556116",
    lng: "15.1461114",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13287",
  },
  {
    id: "56037",
    map_id: "1",
    address: "Via Don Bosco, 19,70123 Bari (BA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "41.1198788",
    lng: "16.8551512",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13296",
  },
  {
    id: "56036",
    map_id: "1",
    address: "Via delle Albizzie, 33,00172 Roma (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.8841923",
    lng: "12.5617825",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13430",
  },
  {
    id: "56035",
    map_id: "1",
    address: "Via Palermo, 66,00055 Ladispoli (RM)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "41.94988619999999",
    lng: "12.0793312",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13464",
  },
  {
    id: "56034",
    map_id: "1",
    address: "Via Orbassano, 83,10090 Bruino (TO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.0187541",
    lng: "7.4815647",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13465",
  },
  {
    id: "56033",
    map_id: "1",
    address: "VIA PRALONGO 42,31050 MONASTER (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.6493083",
    lng: "12.4380618",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13720",
  },
  {
    id: "56032",
    map_id: "1",
    address: "VIA SANTA NARDINI 2,31045 Motta di Livenza (TV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.7752275",
    lng: "12.6106669",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13721",
  },
  {
    id: "56031",
    map_id: "1",
    address: "Via Renato Imbriani, snc,93016 Riesi (CL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "37.2816952",
    lng: "14.0836462",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "13722",
  },
  {
    id: "56030",
    map_id: "1",
    address: "Via Madonna, 1,20037 Paderno Dugnano (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.569153",
    lng: "9.164788999999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15752",
  },
  {
    id: "56029",
    map_id: "1",
    address: "Via Provinciale, 49,24021 Albino (BG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.7624706",
    lng: "9.8043379",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15753",
  },
  {
    id: "56028",
    map_id: "1",
    address: "Via Borgo Palazzo, 219/A,24125 Bergamo (BG)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.6875895",
    lng: "9.711161899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15754",
  },
  {
    id: "56027",
    map_id: "1",
    address: "VIA  PINEROLO, 66,10045 Piossasco (TO)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.987027",
    lng: "7.461315",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15755",
  },
  {
    id: "56026",
    map_id: "1",
    address: "VIA ROMA, 3,20834 Nova Milanese (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.5897802",
    lng: "9.2000175",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15759",
  },
  {
    id: "56025",
    map_id: "1",
    address: "Corso 3 Novembre 1918, 72,38122 Trento (TN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "46.0612955",
    lng: "11.1241549",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15762",
  },
  {
    id: "56024",
    map_id: "1",
    address: "cso NICOTERA, 15/A,88046 Lamezia Terme (CZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "38.9676484",
    lng: "16.3199519",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15763",
  },
  {
    id: "56023",
    map_id: "1",
    address: "VIA ANTONIO  D'ANTONA, 10 D,80131 Napoli (NA)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.861322",
    lng: "14.2229379",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15767",
  },
  {
    id: "56022",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI, 9C,94100 Enna (EN)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "37.56254",
    lng: "14.296661",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "15768",
  },
  {
    id: "56021",
    map_id: "1",
    address: "Via Romaniello, snc,81031 Aversa (CE)",
    description: "AMAZON HUB SERVICE - RICARICHE - SPEDIZIONI",
    lat: "40.9717153",
    lng: "14.172571",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16042",
  },
  {
    id: "56020",
    map_id: "1",
    address: "Sobborgo Emiliano, 64,19038 Sarzana (SP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.1110651",
    lng: "9.9613965",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16139",
  },
  {
    id: "56019",
    map_id: "1",
    address: "Via Giuseppe Mazzini, 14,20067 PAULLO (MI)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.4171372",
    lng: "9.3998183",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16140",
  },
  {
    id: "56018",
    map_id: "1",
    address: "Via Alfonso Casati, 64,20862 Arcore (MB)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.6256886",
    lng: "9.323835599999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16141",
  },
  {
    id: "56017",
    map_id: "1",
    address: "Corso Acqui, 80,15121 Alessandria (AL)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.9007478",
    lng: "8.6008775",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16142",
  },
  {
    id: "56016",
    map_id: "1",
    address: "Via Aurelia, 300bis,19034 Luni (SP)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "43.4653842",
    lng: "10.342424",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16143",
  },
  {
    id: "56015",
    map_id: "1",
    address: "Via Largo Italia, 18,16012 Busalla (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.5705487",
    lng: "8.945588899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16144",
  },
  {
    id: "56014",
    map_id: "1",
    address: "Corso Strada Nuova, 35,27100 Pavia (PV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "45.1842972",
    lng: "9.154877899999999",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16145",
  },
  {
    id: "56013",
    map_id: "1",
    address: "Piazza Duomo, 81,27058 Voghera (PV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.9933619",
    lng: "9.0092508",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16146",
  },
  {
    id: "56012",
    map_id: "1",
    address: "Via Pietro Mazza, 60,27057 Varzi (PV)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.8215133",
    lng: "9.2009578",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16147",
  },
  {
    id: "56011",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE, 57,83027 Mugnano del Cardinale (AV)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "40.940563",
    lng: "14.6389717",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16285",
  },
  {
    id: "56010",
    map_id: "1",
    address: "Via Vittorio Veneto, 15,44012 Bondeno (FE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.8870709",
    lng: "11.4135111",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16298",
  },
  {
    id: "56009",
    map_id: "1",
    address: "Via Martiri di Belfiore, 15,46026 Quistello (MN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.0092556",
    lng: "10.9801159",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16333",
  },
  {
    id: "56008",
    map_id: "1",
    address: "VIA ABETONE BRENNERO, 139,46025 Poggio Rusco (MN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "44.9739983",
    lng: "11.1135441",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16334",
  },
  {
    id: "56007",
    map_id: "1",
    address: "VIA COLOMBO, 26 A,16047 Moconesi (GE)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "44.4214904",
    lng: "9.2079699",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16337",
  },
  {
    id: "56006",
    map_id: "1",
    address: "Via Santoni, 7,38062 Arco di Trento (TN)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "45.9210343",
    lng: "10.8910048",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16339",
  },
  {
    id: "56005",
    map_id: "1",
    address: "Corso della Liberta', 53/G,39100 Bolzano (BZ)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "46.5018518",
    lng: "11.3381701",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16340",
  },
  {
    id: "56004",
    map_id: "1",
    address: "VIA MILANO, 52,22100 Como (CO)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "45.8047771",
    lng: "9.0857421",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16341",
  },
  {
    id: "56003",
    map_id: "1",
    address: "via Polveriera, 110,80138 SANTA MARIA LA CARITA' (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "40.7198818",
    lng: "14.5162814",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16343",
  },
  {
    id: "56002",
    map_id: "1",
    address: "PIAZZA DEL POPOLO, 2,05002 Montespertoli (FI)",
    description:
      "AMAZON HUB SERVICE - BOLLETTINI - RICARICHE - SPEDIZIONI - PROMO130",
    lat: "43.6431791",
    lng: "11.074778",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16344",
  },
  {
    id: "56001",
    map_id: "1",
    address: "VIA Â  NAZIONALE, 624,80059 Torre del Greco (NA)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI - VISURE",
    lat: "40.7694166",
    lng: "14.4075469",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16345",
  },
  {
    id: "56000",
    map_id: "1",
    address: "Via Marceddi', 32,09098 Terralba (OR)",
    description: "BOLLETTINI - RICARICHE - SPEDIZIONI",
    lat: "39.72363259999999",
    lng: "8.6358689",
    title: "Punto Puoi",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "16442",
  },
  {
    id: "55953",
    map_id: "1",
    address: "CORSO DEL POPOLO 87/2,45100 ROVIGO (RO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0723017",
    lng: "11.7854093",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021331",
  },
  {
    id: "55948",
    map_id: "1",
    address: "VIA APPIA 72,85056 RUOTI (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7189837",
    lng: "15.6789121",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021281",
  },
  {
    id: "55946",
    map_id: "1",
    address: "CORSO ITALIA 128,80018 MUGNANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9130301",
    lng: "14.1963021",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021282",
  },
  {
    id: "55941",
    map_id: "1",
    address: "VIA XXVIII APRILE, 16,30027 SAN DONA' DI PIAVE (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6279976",
    lng: "12.5707514",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000324",
  },
  {
    id: "55938",
    map_id: "1",
    address: "VIA FILIPPO TURATI, 177,00015 MONTEROTONDO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.0621557",
    lng: "12.5959156",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021337",
  },
  {
    id: "55830",
    map_id: "1",
    address: "Via Marconi, 54,80036 PALMA CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8653466",
    lng: "14.5544648",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021339",
  },
  {
    id: "55721",
    map_id: "1",
    address: "VIA CONSORTILE VITULANESE 50/F,82030 FOGLIANISE (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1567707",
    lng: "14.6700924",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021334",
  },
  {
    id: "55720",
    map_id: "1",
    address: "VIA POPPEA SABINA 69,00131 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9488382",
    lng: "12.6018217",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021338",
  },
  {
    id: "55718",
    map_id: "1",
    address: "VIA DEI CASTELLI ROMANI 22,00071 POMEZIA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6807468",
    lng: "12.5067701",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021260",
  },
  {
    id: "55595",
    map_id: "1",
    address: "VIA MONTALBANO 11,51039 QUARRATA (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8482611",
    lng: "10.9780877",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021174",
  },
  {
    id: "55579",
    map_id: "1",
    address: "VIA VIGENTINA SNC,27100 PAVIA (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.2037831",
    lng: "9.1709554",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021273",
  },
  {
    id: "55577",
    map_id: "1",
    address: "VIA GARIBALDI 107,90021 ALIA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.7796238",
    lng: "13.714044",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021327",
  },
  {
    id: "55576",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI, 34,95030 MASCALUCIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5731894",
    lng: "15.0581616",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021323",
  },
  {
    id: "55511",
    map_id: "1",
    address: "CORSO ANTONIO GRAMSCI 92 92,45038 POLESELLA (RO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.9614199",
    lng: "11.75203",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021053",
  },
  {
    id: "55507",
    map_id: "1",
    address: "VIA PARTIGIANI D'ITALIA 36A,73040 MELISSANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.965762",
    lng: "18.128206",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021330",
  },
  {
    id: "55506",
    map_id: "1",
    address: "SS 106 KM 227+99, SNC,88841 ISOLA DI CAPO RIZZUTO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9809661",
    lng: "17.0774701",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021298",
  },
  {
    id: "55503",
    map_id: "1",
    address: "CONTRADA FONTANELLE, 112,00030 GAVIGNANO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6920694",
    lng: "13.0505738",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021236",
  },
  {
    id: "55489",
    map_id: "1",
    address: "VIA TRESSANTI, 87,84036 SALA CONSILINA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3831346",
    lng: "15.6039023",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021301",
  },
  {
    id: "55481",
    map_id: "1",
    address: "VIA RISORGIMENTO 59,00041 ALBANO LAZIALE (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7256934",
    lng: "12.6628022",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021173",
  },
  {
    id: "55415",
    map_id: "1",
    address: "VIA LOMORO, 29,89013 GIOIA TAURO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.4236592",
    lng: "15.9001609",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021296",
  },
  {
    id: "55413",
    map_id: "1",
    address: "Via Carolina, 4,58037 SANTA FIORA (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.8312322",
    lng: "11.5858024",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021074",
  },
  {
    id: "55406",
    map_id: "1",
    address: "VIA ROMA 17,07027 OSCHIRI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7339074",
    lng: "8.9860379",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021336",
  },
  {
    id: "55398",
    map_id: "1",
    address: "VIA BECCADELLI 72/74,80125 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8208725",
    lng: "14.1727847",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021307",
  },
  {
    id: "55310",
    map_id: "1",
    address: "CONTRADA GIDORA, SNC,87040 LUZZI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.4382702",
    lng: "16.2651048",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021303",
  },
  {
    id: "55285",
    map_id: "1",
    address: "VIA ROMA 2,89047 ROCCELLA JONICA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.3223974",
    lng: "16.4068575",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021299",
  },
  {
    id: "55264",
    map_id: "1",
    address: "VIA C.BATTISTI, 30,24121 BERGAMO (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.7014345",
    lng: "9.6779523",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021297",
  },
  {
    id: "55244",
    map_id: "1",
    address: "Via B. Abenante, 9-11-13,87064 CORIGLIANO-ROSSANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.5981826",
    lng: "16.5197266",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021311",
  },
  {
    id: "55241",
    map_id: "1",
    address: "Piazza Steri, snc,87023 ROSSANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.5742951",
    lng: "16.6345979",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021312",
  },
  {
    id: "55177",
    map_id: "1",
    address: "VIALE AMEDEO DI SAVOIA 25,09013 CARBONIA (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.212583",
    lng: "8.4641947",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020756",
  },
  {
    id: "55175",
    map_id: "1",
    address: "VIA DEL LAZZARETTO, 25,95024 ACIREALE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6163655",
    lng: "15.1508403",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021309",
  },
  {
    id: "55173",
    map_id: "1",
    address: "VIA SUPPORTICO LOPEZ 5/A,80137 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8569707",
    lng: "14.2554663",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021305",
  },
  {
    id: "55039",
    map_id: "1",
    address: "VIA ROMA 4,26012 CASTELLEONE (CR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.2946918",
    lng: "9.7650818",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021245",
  },
  {
    id: "55030",
    map_id: "1",
    address: "CORSO GARIBALDI 91,94010 VILLAROSA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.586109",
    lng: "14.1749585",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021290",
  },
  {
    id: "54964",
    map_id: "1",
    address: "PIAZZA MERCATOCENTRALE, 1,50123 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.7765692",
    lng: "11.2541621",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021242",
  },
  {
    id: "54961",
    map_id: "1",
    address: "VIA XX SETTEMBRE, 53,09125 CAGLIARI (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.2130596",
    lng: "9.118865",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021295",
  },
  {
    id: "54959",
    map_id: "1",
    address: "VIA ALESSANDRINA 127,27010 ALBUZZANO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.1876948",
    lng: "9.2714391",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021209",
  },
  {
    id: "54878",
    map_id: "1",
    address: "VIALE GIOVANNI BOVIO, 215/217,65124 PESCARA (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.475947",
    lng: "14.1987963",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021266",
  },
  {
    id: "54669",
    map_id: "1",
    address: "VIA GUGLIELMO MARCONI 9,89040 MARTONE (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.0975764",
    lng: "15.6461585",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021288",
  },
  {
    id: "54647",
    map_id: "1",
    address: "Via pitagora, 1/A,88060 SAN SOSTENE (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.6677192",
    lng: "16.5434988",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021241",
  },
  {
    id: "54577",
    map_id: "1",
    address: "VIA BARCHE 16,30035 MIRANO (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4924992",
    lng: "12.1101797",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021286",
  },
  {
    id: "54566",
    map_id: "1",
    address: "PIAZZALE BERTACCHI, 29,23100 SONDRIO (SO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "46.1689098",
    lng: "9.871751",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021123",
  },
  {
    id: "54564",
    map_id: "1",
    address: "VIA SANTA CRESCENZIA 40,20013 MAGENTA (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4638548",
    lng: "8.8820033",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021294",
  },
  {
    id: "54563",
    map_id: "1",
    address: "VIA A. LINCOLN, 2,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.49221",
    lng: "14.06079",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021249",
  },
  {
    id: "54502",
    map_id: "1",
    address: "VIA NAPOLI 6 TRAVERSA BINGO,84014 NOCERA INFERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7480867",
    lng: "14.6360137",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020745",
  },
  {
    id: "54486",
    map_id: "1",
    address: "VIA OLIVETO SCAMMACCA, 101,95127 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5215786",
    lng: "15.0948561",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021259",
  },
  {
    id: "54485",
    map_id: "1",
    address: "VIA ROMA 92,95015 LINGUAGLOSSA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.8423836",
    lng: "15.1421082",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021258",
  },
  {
    id: "54484",
    map_id: "1",
    address: "VIA FARENSE 348,02032 FARA IN SABINA (RI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.2004644",
    lng: "12.7207468",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010334",
  },
  {
    id: "54483",
    map_id: "1",
    address: "corso Amedeo de Rege, 63,13100 VERCELLI (VC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.3187017",
    lng: "8.4296221",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021238",
  },
  {
    id: "54475",
    map_id: "1",
    address: "VIA ROMA 20,80040 SAN SEBASTIANO AL VESUVIO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8438396",
    lng: "14.3703955",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021291",
  },
  {
    id: "54412",
    map_id: "1",
    address: "CORSO UMBERTO I 65,80034 MARIGLIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9254569",
    lng: "14.4456436",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021257",
  },
  {
    id: "54386",
    map_id: "1",
    address: "VICOLO REAME DI NAPOLI, 10,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.067026",
    lng: "14.3414769",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001818",
  },
  {
    id: "54322",
    map_id: "1",
    address:
      "VIA ESTRAMURALE G. B. MOLIGNANI, 60, 60,70021 ACQUAVIVA DELLE FONTI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.897634",
    lng: "16.8398125",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021283",
  },
  {
    id: "54318",
    map_id: "1",
    address: "VIA PONTE 3/A,37060 MOZZECANE (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.32093",
    lng: "10.82978",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021285",
  },
  {
    id: "54315",
    map_id: "1",
    address: "VIA GAMBARDELLA 32,80058 TORRE ANNUNZIATA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7578233",
    lng: "14.4355368",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021269",
  },
  {
    id: "54311",
    map_id: "1",
    address: "VIA NICCOLO' TOMMASEO 17,35131 PADOVA (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4158117",
    lng: "11.8813913",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021254",
  },
  {
    id: "54310",
    map_id: "1",
    address: "VIA GRAMSCI, 267,09095 MOGORO (OR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.68534",
    lng: "8.778926",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021225",
  },
  {
    id: "54295",
    map_id: "1",
    address: "VIA SAN FRANCESCO 108,92018 SANTA MARGHERITA DI BELICE (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.6926971",
    lng: "13.0219591",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021279",
  },
  {
    id: "54269",
    map_id: "1",
    address: "LARGO SAN FRANCESCO 1/BIS,00034 COLLEFERRO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.7253475",
    lng: "13.006637",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021280",
  },
  {
    id: "54268",
    map_id: "1",
    address: "VIA ROMOLO AMASEO 3D,40127 BOLOGNA (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "44.502106",
    lng: "11.363021",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021140",
  },
  {
    id: "54259",
    map_id: "1",
    address: "VIA SAN SIMPLICIANO 3,23883 BRIVIO (LC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.7381346",
    lng: "9.4280157",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011297",
  },
  {
    id: "54256",
    map_id: "1",
    address: "VIA DUCA D'AOSTA 34,95037 SAN GIOVANNI LA PUNTA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5814974",
    lng: "15.096062",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021263",
  },
  {
    id: "54254",
    map_id: "1",
    address: "VIA DELLA BADIA DI CAVA VIA DELLA,00142 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8478275",
    lng: "12.4839886",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021261",
  },
  {
    id: "54226",
    map_id: "1",
    address: "VIA G. SANTACROCE, 79,80129 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8490691",
    lng: "14.2366419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021267",
  },
  {
    id: "54214",
    map_id: "1",
    address: "VIA PASSANTI, 217,84018 SCAFATI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7642475",
    lng: "14.5200215",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021218",
  },
  {
    id: "54207",
    map_id: "1",
    address: "VIA CROTONE, 60,88841 ISOLA DI CAPO RIZZUTO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.961154",
    lng: "17.0913026",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021227",
  },
  {
    id: "54206",
    map_id: "1",
    address: "VIA DELLE SALINE 20,00119 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7625534",
    lng: "12.3013018",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021274",
  },
  {
    id: "54205",
    map_id: "1",
    address: "VIA ASCOLI, 3,63087 COMUNANZA (AP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.9563842",
    lng: "13.4133578",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021252",
  },
  {
    id: "54080",
    map_id: "1",
    address: "CIRCONVALLAZIONE TRIONFALE 53B,00195 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9123147",
    lng: "12.4496822",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021240",
  },
  {
    id: "54040",
    map_id: "1",
    address: "Via Aldo Moro, 30,80028 SANT'ANASTASIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8707353",
    lng: "14.4029599",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021070",
  },
  {
    id: "54039",
    map_id: "1",
    address: "VIA RIPUARIA 203,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9049475",
    lng: "14.061247",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021276",
  },
  {
    id: "54038",
    map_id: "1",
    address: "VIA GUIDO RENI BOX 51 BAR FLAMINIO 2 31A,00196 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9277062",
    lng: "12.4654051",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021234",
  },
  {
    id: "54037",
    map_id: "1",
    address: "VIA VAL DELLA TORRE 22,10091 ALPIGNANO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.1018156",
    lng: "7.5221756",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021239",
  },
  {
    id: "54030",
    map_id: "1",
    address: "VIA SALVATORE ROSA 32,80135 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.853947",
    lng: "14.247934",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021272",
  },
  {
    id: "53962",
    map_id: "1",
    address: "VIA KENNEDY 10,90040 CAPACI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1735793",
    lng: "13.2388268",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021251",
  },
  {
    id: "53961",
    map_id: "1",
    address: "PIAZZA 7 MARTIRI, 11,24030 TERNO D'ISOLA (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6858045",
    lng: "9.5290827",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021237",
  },
  {
    id: "53960",
    map_id: "1",
    address: "VIA GIORDANO BRUNO 34,72100 BRINDISI (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6361512",
    lng: "17.9409388",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021244",
  },
  {
    id: "53875",
    map_id: "1",
    address: "VIALE DANTE ALIGHIERI 22,23900 LECCO (LC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.8517669",
    lng: "9.3937121",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020974",
  },
  {
    id: "53870",
    map_id: "1",
    address: "VIA MARTIRI DI VIA FANI 4,01015 SUTRI (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.2453021",
    lng: "12.2155996",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021250",
  },
  {
    id: "53860",
    map_id: "1",
    address: "VIA G.B. GRASSI 14,22069 ROVELLASCA (CO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.6672055",
    lng: "9.0544168",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021262",
  },
  {
    id: "53859",
    map_id: "1",
    address: "VIA DOTTORINA 52,46043 CASTIGLIONE DELLE STIVIERE (MN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.3600718",
    lng: "10.4904231",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021271",
  },
  {
    id: "53858",
    map_id: "1",
    address: "VIA NETTUNENSE KM.19 300,252/254,00075 LANUVIO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.7098685",
    lng: "12.6374227",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021275",
  },
  {
    id: "53780",
    map_id: "1",
    address: "Via Palmiro Togliatti, 9,71043 MANFREDONIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.6369748",
    lng: "15.9291426",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021216",
  },
  {
    id: "53742",
    map_id: "1",
    address: "VIA II STRADA 24,67069 TAGLIACOZZO (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.0440459",
    lng: "13.3045781",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021152",
  },
  {
    id: "53739",
    map_id: "1",
    address: "VIA ANTONIO GRAMSCI 23,06010 CITERNA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.5194548",
    lng: "12.1105305",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021204",
  },
  {
    id: "53730",
    map_id: "1",
    address: "VIA CASALINO ANGOLO VIA GIOBERTI 1,70019 TRIGGIANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.0631409",
    lng: "16.9204858",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021255",
  },
  {
    id: "53624",
    map_id: "1",
    address: "Via Principe Amedeo, 159/161,74013 TARANO (RI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.4688574",
    lng: "17.2468745",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021243",
  },
  {
    id: "53610",
    map_id: "1",
    address: "G. MATTEOTTI 12,00061 ANGUILLARA SABAZIA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.0723726",
    lng: "12.2918413",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009537",
  },
  {
    id: "53597",
    map_id: "1",
    address: "PIAZZA VERZA 84,22036 CANZO (CO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE - ENEL ENERGIA",
    lat: "45.855447",
    lng: "9.2693813",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020870",
  },
  {
    id: "53577",
    map_id: "1",
    address: "VIA ROMA 181,81036 SAN CIPRIANO D'AVERSA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9981944",
    lng: "14.1319116",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021232",
  },
  {
    id: "53576",
    map_id: "1",
    address: "vIA SANTELIA, 32,88824 BELVEDERE DI SPINELLO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.2131134",
    lng: "16.8882814",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021230",
  },
  {
    id: "53525",
    map_id: "1",
    address: "VIA SALVO D'ACQUISTO 14,74017 MOTTOLA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.6338229",
    lng: "17.0377971",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021222",
  },
  {
    id: "53513",
    map_id: "1",
    address:
      "STRADA PROVINCIALE 10 PADANA INFERIOR 11 SNC,26039 VESCOVATO (CR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.2289309",
    lng: "11.4757875",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021099",
  },
  {
    id: "53463",
    map_id: "1",
    address: "Via Arno, 28,40139 BOLOGNA (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.4733911",
    lng: "11.3880993",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021142",
  },
  {
    id: "53444",
    map_id: "1",
    address: "Via Della Repubblica, 8,71041 CARAPELLE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.3642918",
    lng: "15.6928114",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021248",
  },
  {
    id: "53435",
    map_id: "1",
    address: "Viale Tripoli, 246,47921 RIMINI (RN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "44.0623312",
    lng: "12.5835829",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021229",
  },
  {
    id: "53434",
    map_id: "1",
    address: "VIA TRENTO, 46,27049 STRADELLA (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0755712",
    lng: "9.3002744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021175",
  },
  {
    id: "53425",
    map_id: "1",
    address: "VIA PAOLO BARATTA 37,84091 BATTIPAGLIA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6075168",
    lng: "14.9876405",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021235",
  },
  {
    id: "53423",
    map_id: "1",
    address: "VIA KENNEDY 3,87010 TERRANOVA DA SIBARI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "39.6577933",
    lng: "16.3408175",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021228",
  },
  {
    id: "53362",
    map_id: "1",
    address: "VIA RAFFAELLO SANZIO 1,50053 EMPOLI (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7163825",
    lng: "10.9427002",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021203",
  },
  {
    id: "53359",
    map_id: "1",
    address: "VIA GIOVANNI CARNOVALI 80L,24126 BERGAMO (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6848688",
    lng: "9.6661119",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021220",
  },
  {
    id: "53348",
    map_id: "1",
    address: "VIALE PIO XI, 94/E,89133 REGGIO CALABRIA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "38.1000401",
    lng: "15.6465974",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021224",
  },
  {
    id: "53346",
    map_id: "1",
    address: "VIA CHIESA 18,30039 STRA (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4072149",
    lng: "12.013965",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011298",
  },
  {
    id: "53320",
    map_id: "1",
    address: "VIA ROMA 113,95032 BELPASSO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.5835595",
    lng: "14.9773221",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021154",
  },
  {
    id: "53298",
    map_id: "1",
    address: "Via Montebello, 21,21052 BUSTO ARSIZIO (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6140095",
    lng: "8.8501671",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021089",
  },
  {
    id: "53295",
    map_id: "1",
    address: "C.SO DELLA REPUBBLICA, 4,80078 POZZUOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8219341",
    lng: "14.122101",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021201",
  },
  {
    id: "53216",
    map_id: "1",
    address: "VIA ABRUZZO, 33,65015 MONTESILVANO (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.5124728",
    lng: "14.1608351",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020977",
  },
  {
    id: "53209",
    map_id: "1",
    address: "VIA MONTE GRAPPA 3,21050 LONATE CEPPINO (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.704995",
    lng: "8.8773192",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021213",
  },
  {
    id: "53165",
    map_id: "1",
    address: "VIA RAMPA USCITA SS 162 SNC,80143 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8664438",
    lng: "14.3141916",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021163",
  },
  {
    id: "53163",
    map_id: "1",
    address: "VIA GIUSEPPE GARIBALDI ISOLATO 314 121,98122 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.1972286",
    lng: "15.5571605",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021187",
  },
  {
    id: "53001",
    map_id: "1",
    address: "Via Carducci, 8,50121 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7719911",
    lng: "11.2667431",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021205",
  },
  {
    id: "52997",
    map_id: "1",
    address: "PIAZZA MUNICIPIO 8,13040 SALUGGIA (VC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.2355674",
    lng: "8.0118642",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009919",
  },
  {
    id: "52936",
    map_id: "1",
    address: "CORSO UMBERTO, 229-231,94013 LEONFORTE (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.639115",
    lng: "14.3921778",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020965",
  },
  {
    id: "52881",
    map_id: "1",
    address:
      "VIA CASETTE- FRAZIONE SANTA MARIA 25A,37024 NEGRAR DI VALPOLICELLA (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.50248",
    lng: "10.9345423",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020885",
  },
  {
    id: "52880",
    map_id: "1",
    address: "VIA GIUSEPPE MEZZOFANTI, 30/32,00168 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9149096",
    lng: "12.4158476",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021149",
  },
  {
    id: "52879",
    map_id: "1",
    address: "VIA F. TRIPODI 265,89013 GIOIA TAURO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.4324985",
    lng: "15.8881318",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021217",
  },
  {
    id: "52878",
    map_id: "1",
    address:
      "VIA GUGLIELMO PEPE, 41/43,84010 SANT'EGIDIO DEL MONTE ALBINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.750945",
    lng: "14.5868112",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021179",
  },
  {
    id: "52852",
    map_id: "1",
    address: "Via Di Franco, 31,57123 LIVORNO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.5497914",
    lng: "10.3107554",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021063",
  },
  {
    id: "52829",
    map_id: "1",
    address: "Piazza Vittorio Emanuele II, 29,80049 SOMMA VESUVIANA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8715893",
    lng: "14.438093",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021078",
  },
  {
    id: "52826",
    map_id: "1",
    address: "VIA DEFFENU 68,08100 NUORO (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.3211098",
    lng: "9.3284671",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021176",
  },
  {
    id: "52824",
    map_id: "1",
    address: "VIA CELLINI, 16,57025 PIOMBINO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.9245159",
    lng: "10.52769",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021050",
  },
  {
    id: "52766",
    map_id: "1",
    address: "PIAZZALE DELLA REPUBBLICA 22,80059 TORRE DEL GRECO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.792736",
    lng: "14.3697105",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013713",
  },
  {
    id: "52765",
    map_id: "1",
    address: "VIA CARIFE 91,00134 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7221191",
    lng: "12.5230801",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021171",
  },
  {
    id: "52764",
    map_id: "1",
    address: "VIA S.FAMIANO, 20,01035 GALLESE (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.3741142",
    lng: "12.4112503",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020762",
  },
  {
    id: "52763",
    map_id: "1",
    address: "VIA CAROLI LUIGI, 8,20128 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.5083554",
    lng: "9.2381218",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021202",
  },
  {
    id: "52762",
    map_id: "1",
    address: "VIA ANTONIO DE CURTIS 17,80070 BACOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8000667",
    lng: "14.079061",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021194",
  },
  {
    id: "52760",
    map_id: "1",
    address: "VLE DELLA REPUBBLICA, 74,84090 MONTECORVINO PUGLIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "40.6237629",
    lng: "14.928602",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006909",
  },
  {
    id: "52734",
    map_id: "1",
    address: "VIA COLOMBO 96/98,83100 AVELLINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9143953",
    lng: "14.7779555",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021192",
  },
  {
    id: "52614",
    map_id: "1",
    address: "Piazza Sant'alfio, 4,95039 TRECASTAGNI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.6216072",
    lng: "15.0800093",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021215",
  },
  {
    id: "52605",
    map_id: "1",
    address: "VIA I MAGGIO, 8,89831 SORIANO CALABRO (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.6013767",
    lng: "16.2214867",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021206",
  },
  {
    id: "52599",
    map_id: "1",
    address: "VIA GIUSEPPE SPADA, 1,00043 CIAMPINO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8093915",
    lng: "12.60007",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021150",
  },
  {
    id: "52595",
    map_id: "1",
    address: "VIA COL DI LANA 118/120,00043 CIAMPINO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.7985664",
    lng: "12.6041318",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021169",
  },
  {
    id: "52592",
    map_id: "1",
    address: "VIA RIPA INFERIORE 83,41013 CASTELFRANCO EMILIA (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "44.5960443",
    lng: "11.0527627",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020905",
  },
  {
    id: "52359",
    map_id: "1",
    address: "Via Carmine, 35,70033 CORATO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.1539402",
    lng: "16.4081018",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020377",
  },
  {
    id: "52352",
    map_id: "1",
    address: "VIA UMBERTO I 100,94010 CENTURIPE (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.6928746",
    lng: "14.2649974",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021016",
  },
  {
    id: "52326",
    map_id: "1",
    address: "VIA SAN CLEMENTE 202,84015 NOCERA SUPERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.7416607",
    lng: "14.6726638",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021196",
  },
  {
    id: "52321",
    map_id: "1",
    address: "CORSO MAZZINI, 31/C,66054 VASTO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.1165794",
    lng: "14.7086648",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021208",
  },
  {
    id: "52310",
    map_id: "1",
    address: "CORSO MAZZINI, 261,88100 CATANZARO (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.9034255",
    lng: "16.5947301",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021199",
  },
  {
    id: "52309",
    map_id: "1",
    address: "VIA DELLA MOSCOVA 13,20121 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.4762721",
    lng: "9.1914094",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021193",
  },
  {
    id: "52307",
    map_id: "1",
    address: "VIA GALLIANO 189,95048 SCORDIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.2939859",
    lng: "14.8405938",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021195",
  },
  {
    id: "52268",
    map_id: "1",
    address: "VIA BUONTALENTI, 132,57121 LIVORNO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "43.5494112",
    lng: "10.3114059",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021166",
  },
  {
    id: "52264",
    map_id: "1",
    address: "VIA DEL PETTIROSSO 3,00040 ARDEA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.5702274",
    lng: "12.5319492",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021133",
  },
  {
    id: "52258",
    map_id: "1",
    address: "Via vittorio Veneto, 29,20845 SOVICO (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.6433269",
    lng: "9.2644361",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021075",
  },
  {
    id: "52245",
    map_id: "1",
    address: "VIALE PIAVE 3B,42121 REGGIO NELL'EMILIA (RE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.6963773",
    lng: "10.6408641",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021191",
  },
  {
    id: "52126",
    map_id: "1",
    address: "PIAZZA SAN MICHELE, 1,89864 SPILINGA (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.628403",
    lng: "15.9041003",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021188",
  },
  {
    id: "52120",
    map_id: "1",
    address: "Via Nuova Poggioreale, 464,80143 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8647749",
    lng: "14.2870134",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021200",
  },
  {
    id: "52119",
    map_id: "1",
    address: "VIA CALLIPOLI 308,95014 GIARRE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.7246278",
    lng: "15.1839917",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020826",
  },
  {
    id: "52109",
    map_id: "1",
    address: "VIA RISORGIMENTO 17,80040 STRIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8126003",
    lng: "14.5759632",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021161",
  },
  {
    id: "52085",
    map_id: "1",
    address: "VIA MIRANESE 7,30035 MIRANO (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.4981289",
    lng: "12.1156015",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021198",
  },
  {
    id: "52051",
    map_id: "1",
    address: "PIAZZA GARIBALDI 2,00042 ANZIO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "41.4472356",
    lng: "12.6295724",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021172",
  },
  {
    id: "52049",
    map_id: "1",
    address: "CONTRADA GROTTICELLE, SNC,94010 GAGLIANO CASTELFERRATO (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.7238082",
    lng: "14.5392288",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021183",
  },
  {
    id: "52048",
    map_id: "1",
    address: "CORSO GARIBALDI, 214B,13045 GATTINARA (VC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.6128791",
    lng: "8.3538886",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021091",
  },
  {
    id: "51959",
    map_id: "1",
    address: "VIA ROMA, 4,20020 ROBECCHETTO CON INDUNO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5205759",
    lng: "8.7805133",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021168",
  },
  {
    id: "51957",
    map_id: "1",
    address: "VIA GIACOMO MATTEOTTI, 161,00048 NETTUNO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4578663",
    lng: "12.6678591",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021186",
  },
  {
    id: "51955",
    map_id: "1",
    address: "ViaF. Palasciano, 3,82037 TELESE TERME (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.2168819",
    lng: "14.5163332",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021139",
  },
  {
    id: "51954",
    map_id: "1",
    address: "VIA MATERA 20/64,70029 SANTERAMO IN COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.7912329",
    lng: "16.7522355",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021184",
  },
  {
    id: "51952",
    map_id: "1",
    address: "VIA DUCEZIO 10,98124 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.1900872",
    lng: "15.5443218",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021159",
  },
  {
    id: "51950",
    map_id: "1",
    address: "PIANO SAN ROCCO, 6A,71042 CERIGNOLA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.2616161",
    lng: "15.8954997",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021035",
  },
  {
    id: "51949",
    map_id: "1",
    address: "VIA CESARE PAVESE 1/A 1/B,71045 ORTA NOVA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.33009",
    lng: "15.702887",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021185",
  },
  {
    id: "51858",
    map_id: "1",
    address: "VIA VITTORIO VENETO 1,20835 MUGGIO' (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.5990321",
    lng: "9.2231935",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021116",
  },
  {
    id: "51845",
    map_id: "1",
    address: "VIA BRACCIANESE CLAUDIA 62,00062 BRACCIANO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.0795168",
    lng: "12.202126",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021122",
  },
  {
    id: "51841",
    map_id: "1",
    address: "VIA OXILIA, 4D,10155 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.1050211",
    lng: "7.7011893",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021107",
  },
  {
    id: "51837",
    map_id: "1",
    address: "VIA DE AMICIS 18,28062 CAMERI (NO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5026773",
    lng: "8.6635486",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010296",
  },
  {
    id: "51834",
    map_id: "1",
    address: "VLE DI VILLA PANPHILI, 17/A,00152 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8832703",
    lng: "12.4570202",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009910",
  },
  {
    id: "51832",
    map_id: "1",
    address: "VIA TAVOLETO 1815,47832 SAN CLEMENTE (RN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.9363016",
    lng: "12.6579022",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020917",
  },
  {
    id: "51830",
    map_id: "1",
    address: "VIA SAN GIOACCHINO, 54/56,80011 ACERRA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9494942",
    lng: "14.3742414",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021146",
  },
  {
    id: "51715",
    map_id: "1",
    address: "VIA G.M. FABRICIO 17/C,33090 CLAUZETTO (PN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "46.2298269",
    lng: "12.9177581",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003084",
  },
  {
    id: "51707",
    map_id: "1",
    address: "VIA MONTIANO 2380,47521 CESENA (FC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "44.1015592",
    lng: "12.3016098",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021170",
  },
  {
    id: "51706",
    map_id: "1",
    address: "PIAZZA CASTELREALE, 17,90020 SCIARA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.9146388",
    lng: "13.7622935",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021167",
  },
  {
    id: "51683",
    map_id: "1",
    address: "Via Antonio Amato, 62,84099 SAN CIPRIANO PICENTINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.6828542",
    lng: "14.8737096",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021138",
  },
  {
    id: "51641",
    map_id: "1",
    address: "LARGO BIANTE 5,00124 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.7401693",
    lng: "12.3493561",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021010",
  },
  {
    id: "51639",
    map_id: "1",
    address: "VIA CARLO CARRA' 44/46,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8581306",
    lng: "14.167744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021131",
  },
  {
    id: "51638",
    map_id: "1",
    address: "VIA DON MINZONI 2,4,6,84081 BARONISSI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.7504768",
    lng: "14.7755049",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021137",
  },
  {
    id: "51637",
    map_id: "1",
    address: "VIA GIOVANNI ZAPPALÀ, 20,90144 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "38.140371",
    lng: "13.3342968",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021023",
  },
  {
    id: "51636",
    map_id: "1",
    address: "VIA SANTA MARCELLINA, 43,20061 CARUGATE (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.5492472",
    lng: "9.3392139",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021135",
  },
  {
    id: "51543",
    map_id: "1",
    address: "VIA F.SCO M. ALIAS 2B/C,90145 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.1337902",
    lng: "13.3201002",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011116",
  },
  {
    id: "51538",
    map_id: "1",
    address: "Via Re Martino, 134-136-138,95121 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5250701",
    lng: "15.1074645",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021141",
  },
  {
    id: "51537",
    map_id: "1",
    address: "VIA ROMA, 89,87012 CASTROVILLARI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.8136244",
    lng: "16.2046199",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021145",
  },
  {
    id: "51535",
    map_id: "1",
    address: "Via generale Cascino, 43/a,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.9579492",
    lng: "14.5236923",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021030",
  },
  {
    id: "51533",
    map_id: "1",
    address: "VIA NUOVO TEMPIO 36,80144 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8893116",
    lng: "14.2824314",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021041",
  },
  {
    id: "51492",
    map_id: "1",
    address: "VIA GIOVANNI STEFANO BONACOSSA, 23/A,00118 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9304317",
    lng: "12.4170362",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009467",
  },
  {
    id: "51490",
    map_id: "1",
    address: "VIA DEI NORMANNI 310-312,87064 CORIGLIANO-ROSSANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.6030919",
    lng: "16.6366777",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021157",
  },
  {
    id: "51489",
    map_id: "1",
    address: "VIA ADDA 3,80016 MARANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9026519",
    lng: "14.1829075",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021132",
  },
  {
    id: "51487",
    map_id: "1",
    address: "VIA GALLIANO, 7,85028 RIONERO IN VULTURE (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9218195",
    lng: "15.670603",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021109",
  },
  {
    id: "51483",
    map_id: "1",
    address: "VIA DON LUIGI STURZO 37/C,95014 GIARRE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.720081",
    lng: "15.181176",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021012",
  },
  {
    id: "51482",
    map_id: "1",
    address: "Via L'aquila, 1,67049 TORNIMPARTE (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.3234087",
    lng: "13.2822419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020997",
  },
  {
    id: "51478",
    map_id: "1",
    address: "Via Garibaldi, 10,89014 OPPIDO MAMERTINA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.2897115",
    lng: "15.9843669",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021076",
  },
  {
    id: "51476",
    map_id: "1",
    address: "Via Gorizia, 52,20021 BARANZATE (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.5220337",
    lng: "9.1199374",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021049",
  },
  {
    id: "51472",
    map_id: "1",
    address: "VIA ROMA 43,74028 SAVA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.4022833",
    lng: "17.5568256",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021156",
  },
  {
    id: "51470",
    map_id: "1",
    address: "PIAZZA ITALIA 16,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "36.9554988",
    lng: "14.5287236",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021162",
  },
  {
    id: "51375",
    map_id: "1",
    address: "VIA DELLA REPUBBLICA 179,55051 BARGA (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.0477389",
    lng: "10.4749999",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021086",
  },
  {
    id: "51372",
    map_id: "1",
    address: "VIA GALILEO GALILEI, 37,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9095399",
    lng: "14.2644153",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021130",
  },
  {
    id: "51371",
    map_id: "1",
    address: "CORSO SAN VITO 114,95030 MASCALUCIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.5741387",
    lng: "15.0556481",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021155",
  },
  {
    id: "51370",
    map_id: "1",
    address: "VIA ATELLANA 22,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9073329",
    lng: "14.2589929",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-008728",
  },
  {
    id: "51367",
    map_id: "1",
    address: "VIALE DELLA REPPUBBLICA, 118,80010 VILLARICCA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9198433",
    lng: "14.1901301",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021093",
  },
  {
    id: "51366",
    map_id: "1",
    address: "VIA DELLE LETTERE 42,53045 MONTEPULCIANO (SI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.0959585",
    lng: "11.7862103",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020964",
  },
  {
    id: "51365",
    map_id: "1",
    address: "VIA GIOVANNI FALCONE 27,76012 CANOSA DI PUGLIA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2171367",
    lng: "16.0776698",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021097",
  },
  {
    id: "51311",
    map_id: "1",
    address: "VIA FILIPPO CHIAPPINI 10,00153 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.8746977",
    lng: "12.466264",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021100",
  },
  {
    id: "51308",
    map_id: "1",
    address: "CORSO GARIBALDI, 130/D,01028 ORTE (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4381373",
    lng: "12.4077487",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021118",
  },
  {
    id: "51305",
    map_id: "1",
    address: "VIALE MAGNA GRECIA, 195,74121 TARANTO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.4584271",
    lng: "17.266985",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021068",
  },
  {
    id: "51304",
    map_id: "1",
    address: "VIA LAURA DE BOSIS, 54/56/58,53100 SIENA (SI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.3323357",
    lng: "11.3243904",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020887",
  },
  {
    id: "50987",
    map_id: "1",
    address: "VIA ANTONIO DE CURTIS 1,80070 BACOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID - ENEL ENERGIA",
    lat: "40.8003637",
    lng: "14.0794953",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021113",
  },
  {
    id: "50962",
    map_id: "1",
    address: "VIA GIUSTINIANO 146-148,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.83964",
    lng: "14.2048",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003552",
  },
  {
    id: "50890",
    map_id: "1",
    address: "Via Servio Tullio, 3,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8439397",
    lng: "14.1987784",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021056",
  },
  {
    id: "50888",
    map_id: "1",
    address: "VIA NAZIONALE 16,97018 SCICLI (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.7930969",
    lng: "14.707094",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009346",
  },
  {
    id: "50866",
    map_id: "1",
    address: "VIALE ORAZIO FLACCO-FRAZ. METAPONTO, 5C,75012 BERNALDA (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.3749625",
    lng: "16.8090756",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021121",
  },
  {
    id: "50853",
    map_id: "1",
    address: "LARGO CAVALLOZZI 4,28100 NOVARA (NO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.4470663",
    lng: "8.6227821",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020973",
  },
  {
    id: "50849",
    map_id: "1",
    address: "Via Luigi Sturzo n. 6,86100 CAMPOBASSO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.5650502",
    lng: "14.6731538",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003704",
  },
  {
    id: "50845",
    map_id: "1",
    address: "Via delle Orchidee, 11,66050 SAN SALVO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.0507718",
    lng: "14.7276215",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020881",
  },
  {
    id: "50844",
    map_id: "1",
    address: "Via Gramsci, 21,65014 LORETO APRUTINO (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.4341699",
    lng: "13.9821659",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020879",
  },
  {
    id: "50843",
    map_id: "1",
    address: "VIA MAZZINI 97,09012 CAPOTERRA (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "39.172109",
    lng: "8.9773272",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020987",
  },
  {
    id: "50842",
    map_id: "1",
    address: "Sp 524 lanciano-fossacesia Ovest, 18,66020 FOSSACESIA (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.2547239",
    lng: "14.5045762",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020880",
  },
  {
    id: "50841",
    map_id: "1",
    address: "Via bardonecchia, 52,10139 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "45.0727492",
    lng: "7.6425687",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021129",
  },
  {
    id: "50838",
    map_id: "1",
    address: "Via Madonna della Croce, 118,76121 BARLETTA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.3128602",
    lng: "16.2709836",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012525",
  },
  {
    id: "50837",
    map_id: "1",
    address: "VIA LUCA GIORDANO 20,80046 SAN GIORGIO A CREMANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8355597",
    lng: "14.3470805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021127",
  },
  {
    id: "50834",
    map_id: "1",
    address: "VIA UMBERTO 64,95013 FIUMEFREDDO DI SICILIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.7920115",
    lng: "15.206942",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021066",
  },
  {
    id: "50832",
    map_id: "1",
    address: "VIA EMANUELE SANSONE, 34,91026 MAZARA DEL VALLO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.6587168",
    lng: "12.5814613",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013283",
  },
  {
    id: "50771",
    map_id: "1",
    address: "VIA MADONNA DEL RIPOSO 57,91011 ALCAMO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.9752506",
    lng: "12.9691874",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020923",
  },
  {
    id: "50768",
    map_id: "1",
    address: "Via G bernabeo, 6,66026 ORTONA (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.3561657",
    lng: "14.4000061",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020878",
  },
  {
    id: "50759",
    map_id: "1",
    address: "Via G. Marconi, 13/B,86029 TRIVENTO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7800999",
    lng: "14.552639",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011561",
  },
  {
    id: "50757",
    map_id: "1",
    address: "VIA CASTELLANA 84-86,31100 TREVISO (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6700192",
    lng: "12.2066738",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020725",
  },
  {
    id: "49714",
    map_id: "1",
    address: "VIA FELICE CASCIONE 87,18100 IMPERIA (IM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.875057",
    lng: "8.0128309",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010280",
  },
  {
    id: "49708",
    map_id: "1",
    address: "VIA ON. FRANCESCO NAPOLITANO 93,80035 NOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9293871",
    lng: "14.5271811",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021088",
  },
  {
    id: "49704",
    map_id: "1",
    address: "VIA GALLIANO, 24,80021 AFRAGOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9198419",
    lng: "14.3103216",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021026",
  },
  {
    id: "49702",
    map_id: "1",
    address: "CORSO TRIESTE E TRENTO 94,70011 ALBEROBELLO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.7863574",
    lng: "17.2387727",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021125",
  },
  {
    id: "49699",
    map_id: "1",
    address: "VIA DELLA VALTIERA,06135 PERUGIA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.0889808",
    lng: "12.4504717",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021106",
  },
  {
    id: "49688",
    map_id: "1",
    address: "Via XXIX Maggio, 167,20025 LEGNANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6030499",
    lng: "8.9073707",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021084",
  },
  {
    id: "49672",
    map_id: "1",
    address: "VIA MARCHIANI 53/A,41026 PAVULLO NEL FRIGNANO (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "44.3356164",
    lng: "10.8330763",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021081",
  },
  {
    id: "49661",
    map_id: "1",
    address: "VIA DE PRETIS 35/37,70015 NOCI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "40.796765",
    lng: "17.12297",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021110",
  },
  {
    id: "49660",
    map_id: "1",
    address: "VIA DON MOROSINI, 8,80046 SAN GIORGIO A CREMANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8315717",
    lng: "14.3394362",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021090",
  },
  {
    id: "49656",
    map_id: "1",
    address: "VIA ROMA 16B/,00060 FORMELLO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.0805837",
    lng: "12.3990845",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020890",
  },
  {
    id: "49597",
    map_id: "1",
    address: "VIA ROMA 433,94100 ENNA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.5668953",
    lng: "14.280564",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021126",
  },
  {
    id: "49596",
    map_id: "1",
    address: "VIA VEZZANO 10/16,70022 ALTAMURA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8341365",
    lng: "16.5586051",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021102",
  },
  {
    id: "49578",
    map_id: "1",
    address: "VIA ROMA 32,54038 MONTIGNOSO (MS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "44.0153275",
    lng: "10.1688014",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021124",
  },
  {
    id: "49574",
    map_id: "1",
    address: "VIA ARETINA, 162,50014 FIESOLE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.7682182",
    lng: "11.2926845",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021120",
  },
  {
    id: "49569",
    map_id: "1",
    address: "Via San Pier Damiani, 44,00119 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7319574",
    lng: "12.2735357",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021042",
  },
  {
    id: "49567",
    map_id: "1",
    address: "VIA NAPOLI 8,86038 PETACCIATO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.010803",
    lng: "14.86034",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021085",
  },
  {
    id: "49566",
    map_id: "1",
    address: "VIA PALMANOVA 9,33050 BICINICCO (UD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.9313791",
    lng: "13.2506077",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021080",
  },
  {
    id: "49564",
    map_id: "1",
    address: "VIA INDIPENDENZA 15,96018 PACHINO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "36.7173872",
    lng: "15.0888622",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020903",
  },
  {
    id: "49535",
    map_id: "1",
    address: "VIA A. GHISLERI MERCATINO GHISLERI, SNC,80144 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9046174",
    lng: "14.2391107",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021031",
  },
  {
    id: "49532",
    map_id: "1",
    address: "VIA QUINTINO SELLA, 185,70122 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1204686",
    lng: "16.8644841",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020989",
  },
  {
    id: "49512",
    map_id: "1",
    address: "VIA DELL'OMO 50,00155 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE - ENEL ENERGIA",
    lat: "41.8911359",
    lng: "12.6087472",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020978",
  },
  {
    id: "49510",
    map_id: "1",
    address: "VIA PIEMONTE, 62,70026 MODUGNO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.1181411",
    lng: "16.7885295",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011294",
  },
  {
    id: "49481",
    map_id: "1",
    address: "VIA GIOSUE' CARDUCCI 2/C,83040 CHIUSANO DI SAN DOMENICO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9339944",
    lng: "14.9163472",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021095",
  },
  {
    id: "49479",
    map_id: "1",
    address: "VIALE ISONZO, 374,88100 CATANZARO (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.8487346",
    lng: "16.6067376",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020860",
  },
  {
    id: "49476",
    map_id: "1",
    address: "VIA SAN SEBASTIANO, 43,70022 ALTAMURA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8213221",
    lng: "16.5498919",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021036",
  },
  {
    id: "49452",
    map_id: "1",
    address: "VIA DAVERIO 17,21100 VARESE (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.8153238",
    lng: "8.8114312",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021003",
  },
  {
    id: "49450",
    map_id: "1",
    address: "VIA ANGELO INGANNI 103,20147 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4572456",
    lng: "9.1221155",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021043",
  },
  {
    id: "49449",
    map_id: "1",
    address: "VIA ISONZO 36,80034 MARIGLIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9304572",
    lng: "14.4539978",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021047",
  },
  {
    id: "49422",
    map_id: "1",
    address: "PIAZZA MICHELANGELO, 7,25088 TOSCOLANO MADERNO (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6545248",
    lng: "10.6138029",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020752",
  },
  {
    id: "49417",
    map_id: "1",
    address: "VIA GIUSEPPE LUCANTONI, 19,06023 GUALDO TADINO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.2322186",
    lng: "12.7812594",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021104",
  },
  {
    id: "49409",
    map_id: "1",
    address: "Via Napoli, 72,03039 SORA (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.7166769",
    lng: "13.6102485",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021101",
  },
  {
    id: "49406",
    map_id: "1",
    address: "Via Racane, 2,73040 ALLISTE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE - ENEL ENERGIA",
    lat: "39.9488383",
    lng: "18.0890302",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020971",
  },
  {
    id: "49403",
    map_id: "1",
    address: "VIA GIUSTINIANI BANDINI SNC,62032 CAMERINO (MC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.1445781",
    lng: "13.0742903",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010003",
  },
  {
    id: "49372",
    map_id: "1",
    address: "VIA GERMANIA 40,92024 CANICATTI' (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.3527063",
    lng: "13.8399009",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021092",
  },
  {
    id: "49327",
    map_id: "1",
    address: "VIA FRANCESCO DE SANCTIS 8,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9019561",
    lng: "14.2815901",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021103",
  },
  {
    id: "49326",
    map_id: "1",
    address: "VIA ROMA 177,73021 CALIMERA (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.2472914",
    lng: "18.2723314",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021096",
  },
  {
    id: "49325",
    map_id: "1",
    address: "Via F. Bottazzi, 36G,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8482674",
    lng: "14.1947102",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021051",
  },
  {
    id: "49324",
    map_id: "1",
    address: "Via Ferrarecce, 135,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.0664012",
    lng: "14.3401903",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021094",
  },
  {
    id: "49317",
    map_id: "1",
    address: "VIA CESARE MARIA TONDINI 4,26900 LODI (LO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "45.3190178",
    lng: "9.4917639",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021052",
  },
  {
    id: "49314",
    map_id: "1",
    address: "VIA RIVIERASCA, 197,24033 CALUSCO D'ADDA (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.6901423",
    lng: "9.4794497",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020863",
  },
  {
    id: "49312",
    map_id: "1",
    address: "CORSO SAN VITO, 47,95030 MASCALUCIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.5738052",
    lng: "15.0540062",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021058",
  },
  {
    id: "49311",
    map_id: "1",
    address: "VIA PIEMONTE 18,70026 MODUGNO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.1181823",
    lng: "16.7898692",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021082",
  },
  {
    id: "49283",
    map_id: "1",
    address: "Via G Matteotti, 14,95025 ACI SANT'ANTONIO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6017956",
    lng: "15.1229443",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021009",
  },
  {
    id: "49261",
    map_id: "1",
    address: "VIA ROMA 1/I,42020 QUATTRO CASTELLA (RE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.6355198",
    lng: "10.4726651",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004513",
  },
  {
    id: "49248",
    map_id: "1",
    address: "Via G. Mazzini, 55/C,46043 CASTIGLIONE DELLE STIVIERE (MN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.3840638",
    lng: "10.4922439",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021064",
  },
  {
    id: "49245",
    map_id: "1",
    address: "CORSO REPUBBLICA 56A,09040 VILLASALTO (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.4937387",
    lng: "9.3901524",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020590",
  },
  {
    id: "49243",
    map_id: "1",
    address: "Via Salvo D'Acquisto, 22,31030 CARBONERA (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6798846",
    lng: "12.2962114",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010701",
  },
  {
    id: "49240",
    map_id: "1",
    address: "VIA ROMA 18/01,40050 LOIANO (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "44.2692545",
    lng: "11.322669",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020906",
  },
  {
    id: "49239",
    map_id: "1",
    address: "VIA PALMI 10,89025 ROSARNO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.4865466",
    lng: "15.9759321",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021083",
  },
  {
    id: "49215",
    map_id: "1",
    address: "VIALE BONOMI 26,03043 CASSINO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.4849005",
    lng: "13.8301774",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020849",
  },
  {
    id: "49212",
    map_id: "1",
    address: "VIA GIOSUE' CARDUCCI 450,24127 BERGAMO (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6894223",
    lng: "9.6474407",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021039",
  },
  {
    id: "49210",
    map_id: "1",
    address: "VIA BRUNO SEROTINI 3,01030 VASANELLO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4153012",
    lng: "12.3487212",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020931",
  },
  {
    id: "49165",
    map_id: "1",
    address: "VIA SALARA 221/N,00015 MONTEROTONDO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.0583719",
    lng: "12.5839805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020581",
  },
  {
    id: "49159",
    map_id: "1",
    address: "VIA BONSIGNORE 40,91022 CASTELVETRANO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.677684",
    lng: "12.7935653",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021024",
  },
  {
    id: "49158",
    map_id: "1",
    address: "VIA FURA 93,25125 BRESCIA (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5265659",
    lng: "10.1889798",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020737",
  },
  {
    id: "49135",
    map_id: "1",
    address: "VIA REPUBBLICA 28,25017 LONATO DEL GARDA (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.4609709",
    lng: "10.4855642",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012685",
  },
  {
    id: "49131",
    map_id: "1",
    address: "VIA DULCIA 1/A,26100 CREMONA (CR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.1387208",
    lng: "10.0127477",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021069",
  },
  {
    id: "49107",
    map_id: "1",
    address: "VIA VITTORIO VENETO 1,80029 SANT'ANTIMO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9416965",
    lng: "14.2373574",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020601",
  },
  {
    id: "49106",
    map_id: "1",
    address: "VIA ROMANA 201,55012 CAPANNORI (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8422346",
    lng: "10.5726219",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020891",
  },
  {
    id: "49105",
    map_id: "1",
    address: "VIA MARCHESELLA 80,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9230781",
    lng: "14.1868585",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021025",
  },
  {
    id: "49102",
    map_id: "1",
    address: "VIA ADRIATICA 87,06135 PERUGIA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.0876621",
    lng: "12.430972",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020716",
  },
  {
    id: "49085",
    map_id: "1",
    address: "vIA NAZIONALE, 43/45,89048 SIDERNO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.2803592",
    lng: "16.3100247",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021038",
  },
  {
    id: "49081",
    map_id: "1",
    address: "VIA MOZZILLO 11/13,80035 NOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9255783",
    lng: "14.529256",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021057",
  },
  {
    id: "49065",
    map_id: "1",
    address: "VIA MANCONE 60,81031 AVERSA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9689407",
    lng: "14.1991308",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009540",
  },
  {
    id: "49064",
    map_id: "1",
    address: "VIA DIVISIONE ACQUI, 10,16166 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.3862197",
    lng: "9.01313",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021034",
  },
  {
    id: "49046",
    map_id: "1",
    address: "VIA MONGIBELLO 43/45,95032 BELPASSO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.5372739",
    lng: "14.9835964",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011542",
  },
  {
    id: "49045",
    map_id: "1",
    address: "PIAZZA GIOVANNI 23 SNC,00060 MAZZANO ROMANO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "42.2045465",
    lng: "12.3999054",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012152",
  },
  {
    id: "49036",
    map_id: "1",
    address: "VIA LA STAZZA, 63/A,03011 ALATRI (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7397362",
    lng: "13.3415601",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020802",
  },
  {
    id: "49029",
    map_id: "1",
    address: "VIA ALTINO, 11/13,00183 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.8793022",
    lng: "12.5095755",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020967",
  },
  {
    id: "49005",
    map_id: "1",
    address: "VIA BRUSICCO 17,24039 SOTTO IL MONTE GIOVANNI XXIII (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "45.7067822",
    lng: "9.49369",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021060",
  },
  {
    id: "49001",
    map_id: "1",
    address: "VIA CESARE BATTISTI 21,20854 VEDANO AL LAMBRO (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6045862",
    lng: "9.2681956",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021059",
  },
  {
    id: "48988",
    map_id: "1",
    address: "CORSO UMBERTO I 16,92016 RIBERA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5023469",
    lng: "13.2650489",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021006",
  },
  {
    id: "48976",
    map_id: "1",
    address: "VIA GARIBALDI 24,27011 BELGIOIOSO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.1612941",
    lng: "9.314483",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020886",
  },
  {
    id: "48970",
    map_id: "1",
    address: "VIA DI SAN VITTORINO 306,00132 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9138463",
    lng: "12.7893141",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021014",
  },
  {
    id: "48933",
    map_id: "1",
    address: "VIA ADDIS ABEBA 4,73056 TAURISANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.9544653",
    lng: "18.2097885",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021013",
  },
  {
    id: "48932",
    map_id: "1",
    address: "VIA GAETANO CASATI, 3/L,20900 MONZA (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.5743898",
    lng: "9.2695948",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021048",
  },
  {
    id: "48917",
    map_id: "1",
    address: "VIA FILIPPO CORRIDONI, 5,95121 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.5100155",
    lng: "15.0873081",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020904",
  },
  {
    id: "48916",
    map_id: "1",
    address: "PIAZZA CORTE DEI SANTI 51,03040 SAN VITTORE DEL LAZIO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "41.4935963",
    lng: "13.8276524",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020990",
  },
  {
    id: "48915",
    map_id: "1",
    address: "VIA FABIO SEVERO 46,34127 TRIESTE (TS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.656688",
    lng: "13.7847512",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013288",
  },
  {
    id: "48914",
    map_id: "1",
    address: "VIA GIUSEPPE GARIBALDI 16,00058 SANTA MARINELLA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "42.033011",
    lng: "11.847632",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012092",
  },
  {
    id: "48913",
    map_id: "1",
    address: "Piazza Ferralasco,32,09017 SANT'ANTIOCO (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.0673551",
    lng: "8.4581666",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021017",
  },
  {
    id: "48912",
    map_id: "1",
    address: "VIA PIAVE 25/3,60019 SENIGALLIA (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.7167282",
    lng: "13.213186",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006574",
  },
  {
    id: "48911",
    map_id: "1",
    address: "VIA GEROLAMO CARDANO 118,00146 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE - ENEL ENERGIA",
    lat: "41.8630187",
    lng: "12.4657932",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020995",
  },
  {
    id: "48849",
    map_id: "1",
    address: "PIAZZA UMBERTO I, 16,80020 FRATTAMINORE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9572599",
    lng: "14.2703968",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020746",
  },
  {
    id: "48847",
    map_id: "1",
    address: "VIA ALESSANDRO MAGGINI, 51,60127 ANCONA (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.597243",
    lng: "13.5030431",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021028",
  },
  {
    id: "48842",
    map_id: "1",
    address: "Via Del pino, 6- fraz ponte pattoli,06134 PERUGIA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.1748716",
    lng: "12.4298996",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021020",
  },
  {
    id: "48836",
    map_id: "1",
    address: "VIA PORTA PALERMO, 115,92015 RAFFADALI (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.4087497",
    lng: "13.5310618",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021019",
  },
  {
    id: "48805",
    map_id: "1",
    address: "VIALE REGINA MARGHERIATA 2,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.4871717",
    lng: "14.0622898",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021045",
  },
  {
    id: "48804",
    map_id: "1",
    address: "VIA PALERMO, 66,00055 LADISPOLI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.9498862",
    lng: "12.0793312",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013464",
  },
  {
    id: "48797",
    map_id: "1",
    address: "Strada Flaminia, Km 69,05030 OTRICOLI (TR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4208036",
    lng: "12.4746698",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020726",
  },
  {
    id: "48783",
    map_id: "1",
    address: "VIA GRAZIA DELEDDA 38,08020 GALTELLI (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.383053",
    lng: "9.6098474",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020861",
  },
  {
    id: "48780",
    map_id: "1",
    address: "VIA G. DI VITTORIO SNC,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "39.0839116",
    lng: "17.1144008",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021018",
  },
  {
    id: "48713",
    map_id: "1",
    address: "VIA MENEGO 458B,37020 DOLCE' (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5510103",
    lng: "10.8271075",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021001",
  },
  {
    id: "48712",
    map_id: "1",
    address: "VIA PASCOLI SNC,64023 MOSCIANO SANT'ANGELO (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "42.7242625",
    lng: "13.912339",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021065",
  },
  {
    id: "48704",
    map_id: "1",
    address: "CORSO GARIBALDI 227,82100 BENEVENTO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.1321012",
    lng: "14.7735756",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021046",
  },
  {
    id: "48675",
    map_id: "1",
    address: "VIA GUGLIELMO MARCONI, 40,24033 CALUSCO D'ADDA (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.6910247",
    lng: "9.4763931",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021015",
  },
  {
    id: "48674",
    map_id: "1",
    address: "CORSO SOLFERINO 5R,16122 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.41334",
    lng: "8.94257",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020910",
  },
  {
    id: "48673",
    map_id: "1",
    address: "VIA MARIO GORI 183,93015 NISCEMI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.1468915",
    lng: "14.3927465",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020966",
  },
  {
    id: "48670",
    map_id: "1",
    address: "VIA PALMA 63,92027 LICATA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.1085222",
    lng: "13.9304303",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021011",
  },
  {
    id: "48637",
    map_id: "1",
    address: "VIA ACHILLE GRANDI 55,81055 SANTA MARIA CAPUA VETERE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.074766",
    lng: "14.25317",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020918",
  },
  {
    id: "48633",
    map_id: "1",
    address: "VIA PIANODARDINE 120,83042 ATRIPALDA (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "40.9295255",
    lng: "14.826975",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004828",
  },
  {
    id: "48586",
    map_id: "1",
    address: "VIA MONGIBELLO 101,95032 BELPASSO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5402794",
    lng: "14.9836057",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020901",
  },
  {
    id: "48537",
    map_id: "1",
    address: "PZT SAN ROCCO 7,25035 OSPITALETTO (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5542414",
    lng: "10.0729599",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009802",
  },
  {
    id: "48513",
    map_id: "1",
    address: "Via Armando Ciliberti, 39,70023 GIOIA DEL COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "40.7960504",
    lng: "16.9191304",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021007",
  },
  {
    id: "48508",
    map_id: "1",
    address: "VIA DI VITTORIO 1,40066 PIEVE DI CENTO (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.7117239",
    lng: "11.3124772",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020926",
  },
  {
    id: "48507",
    map_id: "1",
    address: "VIA DI LEUCA, 331/D,73100 LECCE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "40.3375611",
    lng: "18.1777597",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020685",
  },
  {
    id: "48501",
    map_id: "1",
    address: "Corso San Giovanni a teduccio, 422,80146 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8342158",
    lng: "14.3057875",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020963",
  },
  {
    id: "48464",
    map_id: "1",
    address: "Via luigi barbieri, 148,27058 VOGHERA (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.9758779",
    lng: "9.0000252",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020999",
  },
  {
    id: "48463",
    map_id: "1",
    address: "VIA DELLA REPUBBLICA 1,70025 GRUMO APPULA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0122084",
    lng: "16.7122306",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020911",
  },
  {
    id: "48461",
    map_id: "1",
    address: "VIA MONTEVERGINE, 6,80127 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8471163",
    lng: "14.2016963",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020976",
  },
  {
    id: "48446",
    map_id: "1",
    address: "VIA N. PICCINNI 206A,70122 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "41.1249089",
    lng: "16.8637985",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020992",
  },
  {
    id: "48436",
    map_id: "1",
    address: "VIA MONVISO, 64,20024 GARBAGNATE MILANESE (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5666951",
    lng: "9.0703745",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020932",
  },
  {
    id: "48429",
    map_id: "1",
    address: "PIAZZA GARIBALDI, 7,70123 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1261374",
    lng: "16.8609641",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020948",
  },
  {
    id: "48398",
    map_id: "1",
    address: "VIA MILITE IGNOTO 189/A,76123 ANDRIA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.2271942",
    lng: "16.3100545",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021005",
  },
  {
    id: "48382",
    map_id: "1",
    address: "VIA NUOVA, 88,83025 MONTORO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8983773",
    lng: "14.8249567",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011463",
  },
  {
    id: "48371",
    map_id: "1",
    address: "VIA LUIGI ROSSI 53,71017 TORREMAGGIORE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.6901354",
    lng: "15.2877148",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020954",
  },
  {
    id: "48365",
    map_id: "1",
    address: "VIALE GIUSEPPE GIUSTI, 107,58100 GROSSETO (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.7752774",
    lng: "11.1021482",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020996",
  },
  {
    id: "48359",
    map_id: "1",
    address: "VIA ROMA 47,37012 BUSSOLENGO (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4752916",
    lng: "10.8449746",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020451",
  },
  {
    id: "48358",
    map_id: "1",
    address: "VIA ROMA 129,75028 TURSI (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.2463589",
    lng: "16.4677795",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002549",
  },
  {
    id: "48354",
    map_id: "1",
    address: "Via Natta, 31,22100 COMO (CO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.808578",
    lng: "9.083094",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020975",
  },
  {
    id: "48347",
    map_id: "1",
    address: "VIA GENERALE ENRICO COSENZ 33,80142 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8475787",
    lng: "14.2726588",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020844",
  },
  {
    id: "48345",
    map_id: "1",
    address:
      "PIAZZA TONI MERLIN, 42- FRAZ. TRICHIANA,32026 BORGO VALBELLUNA (BL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "46.0737805",
    lng: "12.1332843",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011897",
  },
  {
    id: "48341",
    map_id: "1",
    address: "VIA ANTONIO FRATTI 82,55049 VIAREGGIO (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8681337",
    lng: "10.2499467",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020983",
  },
  {
    id: "48286",
    map_id: "1",
    address: "Via Ruggero Leoncavallo, 2 int12,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.0632668",
    lng: "17.111751",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020994",
  },
  {
    id: "48274",
    map_id: "1",
    address: "Via Tibula, snC,07028 SANTA TERESA GALLURA (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.2400225",
    lng: "9.184887",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021004",
  },
  {
    id: "48266",
    map_id: "1",
    address: "CORSO EUROPA 29,07039 VALLEDORIA (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9289544",
    lng: "8.8273329",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021008",
  },
  {
    id: "48259",
    map_id: "1",
    address: "PIAZZA VITTORIO EMANUELE II, 6,24044 DALMINE (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.6387322",
    lng: "9.5914632",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020930",
  },
  {
    id: "48258",
    map_id: "1",
    address: "VIA XX SETTEMBRE 45/B,37129 VERONA (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.4397066",
    lng: "11.0083429",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020929",
  },
  {
    id: "48257",
    map_id: "1",
    address: "VIA ANAGNINA 287,00118 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.8205814",
    lng: "12.6110591",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020832",
  },
  {
    id: "48256",
    map_id: "1",
    address: "VIA DON BOSCO 63,18019 VALLECROSIA (IM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.7880707",
    lng: "7.6451976",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020884",
  },
  {
    id: "48238",
    map_id: "1",
    address: "VIA ANGELO VOLPICELLI, 56/58,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9164677",
    lng: "14.262464",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020986",
  },
  {
    id: "48234",
    map_id: "1",
    address: "VIA DELLE MEDAGLIE D'ORO 108/110,87100 COSENZA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.3038851",
    lng: "16.2536378",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020919",
  },
  {
    id: "48229",
    map_id: "1",
    address: "VIA IPPOCASTANI 8,26100 CREMONA (CR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.1333531",
    lng: "10.0377944",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011315",
  },
  {
    id: "48172",
    map_id: "1",
    address: "VIA MARCONI 91-93,09045 QUARTU SANT'ELENA (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.2425579",
    lng: "9.1725202",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020824",
  },
  {
    id: "48169",
    map_id: "1",
    address: "VIA ANTONIO STOPPANI 24,20129 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4746537",
    lng: "9.2142644",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020854",
  },
  {
    id: "48164",
    map_id: "1",
    address: "VIA CHIESA DELLA SALUTE 17/E,10147 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.0942726",
    lng: "7.6783199",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011888",
  },
  {
    id: "48159",
    map_id: "1",
    address: "VIA PIETA' , 63,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "39.0706913",
    lng: "17.0256699",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020946",
  },
  {
    id: "48157",
    map_id: "1",
    address: "VIA DELLA PODESTÀ 114,50125 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.7375196",
    lng: "11.2256599",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-021002",
  },
  {
    id: "48155",
    map_id: "1",
    address: "VIA DEL CONCILIO 18,65121 PESCARA (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "42.465943",
    lng: "14.2149825",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013294",
  },
  {
    id: "48152",
    map_id: "1",
    address: "VIA DOMENICO FIASELLA 48,16121 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.4041494",
    lng: "8.9415322",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020587",
  },
  {
    id: "48149",
    map_id: "1",
    address: "PIAZZALE LEONARDO DA VINCI 1,50036 SESTO FIORENTINO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.843312",
    lng: "11.2463352",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020418",
  },
  {
    id: "48146",
    map_id: "1",
    address: "VIA MAMELI ANGOLO VIA GALLURA, 36,07026 OLBIA (OT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9215438",
    lng: "9.494228",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001135",
  },
  {
    id: "48143",
    map_id: "1",
    address: "VIA DEL PIANETA VENERE, 139,00144 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8182493",
    lng: "12.447306",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020980",
  },
  {
    id: "48063",
    map_id: "1",
    address: "PIAZZA ING .GIUSEPPE PANVINI, 1,94100 ENNA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.5570354",
    lng: "14.2888883",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012618",
  },
  {
    id: "48057",
    map_id: "1",
    address: "VIA RISORGIMENTO 46,88814 MELISSA (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.3037148",
    lng: "17.1090803",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012019",
  },
  {
    id: "48048",
    map_id: "1",
    address: "VIA G. DI VITTORIO 102,41058 VIGNOLA (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.4816669",
    lng: "11.0028253",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012773",
  },
  {
    id: "48044",
    map_id: "1",
    address: "VIA G.MARCONI C. COMM. MARCONI PIU SNC,87036 RENDE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.353216",
    lng: "16.2346149",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020960",
  },
  {
    id: "48021",
    map_id: "1",
    address: "VIA G. MARCONI 6/8,00146 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8690549",
    lng: "12.4676557",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011118",
  },
  {
    id: "47990",
    map_id: "1",
    address: "V.LE EUROPA 12,02037 POGGIO MOIANO (RI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "42.2110082",
    lng: "12.8225691",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020868",
  },
  {
    id: "47986",
    map_id: "1",
    address: "VIA VIRGILIO 7,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.0161901",
    lng: "12.5200111",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020920",
  },
  {
    id: "47983",
    map_id: "1",
    address: "VIA TORRAZZO 66,88100 CATANZARO (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.8217773",
    lng: "16.6146079",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020951",
  },
  {
    id: "47972",
    map_id: "1",
    address: "VIA CIRCONVALLAZIONE 55,27043 BRONI (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.0661895",
    lng: "9.2604183",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020961",
  },
  {
    id: "47963",
    map_id: "1",
    address: "VIA ITALICA, 26,65127 PESCARA (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4601523",
    lng: "14.217137",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020927",
  },
  {
    id: "47949",
    map_id: "1",
    address: "VIA ANTONIO RUSSO 9,84132 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.6593985",
    lng: "14.8117823",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020955",
  },
  {
    id: "47941",
    map_id: "1",
    address: "VIA GLI ORTI 1,56034 CASCIANA TERME LARI (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.7083836",
    lng: "10.7770608",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011093",
  },
  {
    id: "47939",
    map_id: "1",
    address: "PIAZZA RETTORE EVOLA 14,90041 BALESTRATE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "38.0503378",
    lng: "13.0056435",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020898",
  },
  {
    id: "47923",
    map_id: "1",
    address: "VIA NAPOLI 32/34,90133 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1177605",
    lng: "13.3619291",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020823",
  },
  {
    id: "47821",
    map_id: "1",
    address: "VIA ILARIA ALPI, 1B,33082 AZZANO DECIMO (PN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.8919202",
    lng: "12.6974212",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020882",
  },
  {
    id: "47820",
    map_id: "1",
    address: "VIA GUGLIELMO MARCONI 30,25128 BRESCIA (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5538902",
    lng: "10.2263719",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020940",
  },
  {
    id: "47818",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI 44,70016 NOICATTARO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.033092",
    lng: "16.993392",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020944",
  },
  {
    id: "47814",
    map_id: "1",
    address: "VIALE DELLA REPUBBLICA, 95,87055 SAN GIOVANNI IN FIORE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.2573242",
    lng: "16.6921296",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012736",
  },
  {
    id: "47813",
    map_id: "1",
    address: "CORSO INDIPENDENZA 23,10086 RIVAROLO CANAVESE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.3329345",
    lng: "7.7223496",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020662",
  },
  {
    id: "47808",
    map_id: "1",
    address: "VIA COL DI LANA 7/A,00015 MONTEROTONDO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.0627159",
    lng: "12.5904759",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012011",
  },
  {
    id: "47806",
    map_id: "1",
    address: "PIAZZA COTA 19C,80063 PIANO DI SORRENTO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6332419",
    lng: "14.4109055",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012371",
  },
  {
    id: "47805",
    map_id: "1",
    address: "Corso Aldo Moro, 317/319,81055 SANTA MARIA CAPUA VETERE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.0831567",
    lng: "14.2489665",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020799",
  },
  {
    id: "47755",
    map_id: "1",
    address: "VIA XXIX MAGGIO 108E,20025 LEGNANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "45.599605",
    lng: "8.909347",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020942",
  },
  {
    id: "47723",
    map_id: "1",
    address: "LIVORNESE DI SOTTO, 12,51013 CHIESINA UZZANESE (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "43.8336128",
    lng: "10.7220287",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020957",
  },
  {
    id: "47645",
    map_id: "1",
    address: "VIA ROMA 82,20010 PREGNANA MILANESE (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.5164601",
    lng: "9.0075904",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020922",
  },
  {
    id: "47641",
    map_id: "1",
    address: "VIA UMBERTO I 13,82015 DURAZZANO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0638726",
    lng: "14.4463251",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020909",
  },
  {
    id: "47637",
    map_id: "1",
    address: "VIA SANT'AGOSTINO 41,07041 ALGHERO (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.557607",
    lng: "8.3213726",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020943",
  },
  {
    id: "47631",
    map_id: "1",
    address: "VIA SALARIA VECCHIA 16,02037 POGGIO MOIANO (RI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.2090489",
    lng: "12.8239142",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020925",
  },
  {
    id: "47622",
    map_id: "1",
    address: "VIA ABRUZZI 22,63074 SAN BENEDETTO DEL TRONTO (AP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.9456968",
    lng: "13.8841708",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009282",
  },
  {
    id: "47621",
    map_id: "1",
    address: "Via XX Settembre, 30,71010 SAN PAOLO DI CIVITATE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.7395444",
    lng: "15.2608238",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020950",
  },
  {
    id: "47616",
    map_id: "1",
    address: "VIA BORIS GIULIANO 7,94100 ENNA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5526895",
    lng: "14.2993201",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020818",
  },
  {
    id: "47610",
    map_id: "1",
    address: "VIA STAMPERIA 8,10066 TORRE PELLICE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "44.8190143",
    lng: "7.2233572",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020970",
  },
  {
    id: "47608",
    map_id: "1",
    address: "VIA ROMA 211,97010 MODICA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.87029",
    lng: "14.76201",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-00946",
  },
  {
    id: "47605",
    map_id: "1",
    address: "VIA SCANDERBERG, 17,83030 GRECI (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2494898",
    lng: "15.169326",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020913",
  },
  {
    id: "47604",
    map_id: "1",
    address: "VIA PORTA RONCA 53,20017 RHO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.527565",
    lng: "9.0361978",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020651",
  },
  {
    id: "47603",
    map_id: "1",
    address: "VIA G. AMENDOLA 14717,83034 CASALBORE (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2337104",
    lng: "15.0055136",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020869",
  },
  {
    id: "47601",
    map_id: "1",
    address: "VIA SAN NULLO 32/33,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8940809",
    lng: "14.1006781",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010480",
  },
  {
    id: "47567",
    map_id: "1",
    address: "VIA SPARTACO LAVAGNIN 42/44,51031 AGLIANA (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.9031421",
    lng: "11.0112708",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020907",
  },
  {
    id: "47563",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 73,70028 SANNICANDRO DI BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "41.1263975",
    lng: "16.8698707",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020914",
  },
  {
    id: "47561",
    map_id: "1",
    address: "VIA BONASTRO 61,52046 LUCIGNANO (AR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.2710211",
    lng: "11.7419957",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020968",
  },
  {
    id: "47558",
    map_id: "1",
    address: "VIA CIALDINI 19,96018 PACHINO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.714535",
    lng: "15.0907023",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020921",
  },
  {
    id: "47556",
    map_id: "1",
    address: "VIA LUIGI DE CAROLIS 4,71014 SAN MARCO IN LAMIS (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.7108267",
    lng: "15.6407432",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020851",
  },
  {
    id: "47553",
    map_id: "1",
    address: "VIA ROMA 124,33050 CASTIONS DI STRADA (UD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.9075385",
    lng: "13.186791",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020937",
  },
  {
    id: "47552",
    map_id: "1",
    address: "VIALE MADRID 36,03100 FROSINONE (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6449111",
    lng: "13.327386",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020827",
  },
  {
    id: "47547",
    map_id: "1",
    address: "VIA BADEA 42,89843 SANT'ONOFRIO (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.6969853",
    lng: "16.148195",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020936",
  },
  {
    id: "47545",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 11,00010 MARCELLINA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "42.0249483",
    lng: "12.8006531",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005760",
  },
  {
    id: "47540",
    map_id: "1",
    address: "VIALE EUGENIO IV 139,01019 VETRALLA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.3210727",
    lng: "12.0576808",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020719",
  },
  {
    id: "47529",
    map_id: "1",
    address: "VIA CROCE 109,80036 PALMA CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8676183",
    lng: "14.5529297",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020949",
  },
  {
    id: "47518",
    map_id: "1",
    address: "VIA PIGNA 101,80013 CASALNUOVO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.912359",
    lng: "14.3554073",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012021",
  },
  {
    id: "47457",
    map_id: "1",
    address: "VIA GUGLIELMO MARCONI, 79,80036 PALMA CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8649173",
    lng: "14.554154",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002789",
  },
  {
    id: "47453",
    map_id: "1",
    address:
      "VIA MATTEOTTI FRAZIONE DONORATICO 105,57022 CASTAGNETO CARDUCCI (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.163569",
    lng: "10.5654929",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020454",
  },
  {
    id: "47451",
    map_id: "1",
    address: "VIA MONTEVIDEO 5/R,16128 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.4025013",
    lng: "8.9546015",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020496",
  },
  {
    id: "47449",
    map_id: "1",
    address: "VIA FAUSTO GULLO- FRAZIONE PIAZZA 30,87100 COSENZA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.296008",
    lng: "16.336597",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020833",
  },
  {
    id: "47367",
    map_id: "1",
    address: "VIA LOUIS PASTEUR, 16E,42121 REGGIO NELL'EMILIA (RE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.6804303",
    lng: "10.6843157",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020758",
  },
  {
    id: "47366",
    map_id: "1",
    address: "VIA AULO ATTILIO CAIATINO 7,81013 PIANA DI MONTE VERNA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.1780887",
    lng: "14.3651344",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020871",
  },
  {
    id: "47364",
    map_id: "1",
    address: "VIA DOMENICO CAPITELLI 22,80134 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8470503",
    lng: "14.2511028",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013170",
  },
  {
    id: "47361",
    map_id: "1",
    address: "VIA VALLE 14,21037 LAVENA PONTE TRESA (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "45.9661961",
    lng: "8.8542205",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020941",
  },
  {
    id: "47360",
    map_id: "1",
    address: "VIA CAMILLO ROSALBA, 49 D/7,70124 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.0968296",
    lng: "16.855033",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020934",
  },
  {
    id: "47358",
    map_id: "1",
    address: "VIA MICHELANGELO BUONARROTI 47,83035 GROTTAMINARDA (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.0717843",
    lng: "15.0566614",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020924",
  },
  {
    id: "47357",
    map_id: "1",
    address: "VIA ROMA, 122,75028 TURSI (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.2464543",
    lng: "16.4678296",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020899",
  },
  {
    id: "47356",
    map_id: "1",
    address: "VIA FELICE GROSSI GONDI 18,00162 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9199849",
    lng: "12.5218931",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012882",
  },
  {
    id: "47355",
    map_id: "1",
    address: "VIA BARI, 13/15,03043 CASSINO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "41.4911102",
    lng: "13.8333625",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020938",
  },
  {
    id: "47336",
    map_id: "1",
    address: "VIA DON ANTONIO MARELLI, 4,21052 BUSTO ARSIZIO (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.5987406",
    lng: "8.8438456",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020889",
  },
  {
    id: "47332",
    map_id: "1",
    address: "VIA S.MARIA A CUBITO 224,80019 QUALIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9201275",
    lng: "14.1526938",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020843",
  },
  {
    id: "47331",
    map_id: "1",
    address: "PIAZZA MUNICIPIO 6,81010 GIOIA SANNITICA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2997938",
    lng: "14.444878",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001498",
  },
  {
    id: "47330",
    map_id: "1",
    address: "VIA S.MARIA ALFONSO DE LIGUORI, 9,80141 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8617907",
    lng: "14.2678075",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005924",
  },
  {
    id: "47259",
    map_id: "1",
    address: "PIAZZA S. GIOVANNI DI DIO, 10/12,00152 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.874459",
    lng: "12.446484",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020945",
  },
  {
    id: "47254",
    map_id: "1",
    address: "CORSO ITALIA 222,80010 QUARTO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8782801",
    lng: "14.1355954",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001893",
  },
  {
    id: "47253",
    map_id: "1",
    address: "VIA ROMA 10,22070 GUANZATE (CO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.7256355",
    lng: "9.0137364",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010442",
  },
  {
    id: "47252",
    map_id: "1",
    address: "CORSO E.DE NICOLA 11,80021 AFRAGOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9202156",
    lng: "14.3080474",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020939",
  },
  {
    id: "47235",
    map_id: "1",
    address: "VIA AVIA CAIROLI 10,01100 VITERBO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4194471",
    lng: "12.1042376",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020713",
  },
  {
    id: "47229",
    map_id: "1",
    address: "VIA EMPOLITANA 113,00019 TIVOLI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.9564819",
    lng: "12.8038518",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009800",
  },
  {
    id: "47226",
    map_id: "1",
    address: "VIA PECHINO 13,89020 MELICUCCO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.4351781",
    lng: "16.0553128",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020933",
  },
  {
    id: "47225",
    map_id: "1",
    address: "VIA ROMA 31/33,89029 TAURIANOVA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.354472",
    lng: "16.014563",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020947",
  },
  {
    id: "47215",
    map_id: "1",
    address: "VIA GIOSUE BORSI 2,20143 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4476326",
    lng: "9.1766228",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020865",
  },
  {
    id: "47213",
    map_id: "1",
    address: "VIA BRUNO BUOZZI 13B,95045 MISTERBIANCO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5195299",
    lng: "15.0024724",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020864",
  },
  {
    id: "47169",
    map_id: "1",
    address: "VIA ROMA 50,66050 SAN SALVO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.0473077",
    lng: "14.7307215",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020874",
  },
  {
    id: "47164",
    map_id: "1",
    address: "VIA DON PASQUINO BORGHI 8,42028 POVIGLIO (RE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.8412917",
    lng: "10.5421622",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020797",
  },
  {
    id: "47159",
    map_id: "1",
    address: "VIA TOMMASO TRAETTA 55,70032 BITONTO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.1066254",
    lng: "16.6948786",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003543",
  },
  {
    id: "47154",
    map_id: "1",
    address: "PIAZZA MUNICIPIO 25,80044 OTTAVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8494114",
    lng: "14.4781221",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020171",
  },
  {
    id: "47143",
    map_id: "1",
    address: "VIA PROVINCIALE 389,90046 MONREALE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0474",
    lng: "13.2272436",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009167",
  },
  {
    id: "47114",
    map_id: "1",
    address: "VIA PIO X 61,84043 AGROPOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.34746",
    lng: "14.9925515",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001849",
  },
  {
    id: "47086",
    map_id: "1",
    address: "PIAZZA DEL QUADRARETTO 31,00174 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8631005",
    lng: "12.5475411",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020810",
  },
  {
    id: "47073",
    map_id: "1",
    address: "I VICO VITTORIO VENETO 1,80059 TORRE DEL GRECO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7900245",
    lng: "14.367303",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001817",
  },
  {
    id: "47071",
    map_id: "1",
    address: "VIA ROMA 62,10070 SAN FRANCESCO AL CAMPO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE - ENEL ENERGIA",
    lat: "45.2254897",
    lng: "7.6561228",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020800",
  },
  {
    id: "47069",
    map_id: "1",
    address: "VIA A.R. MIGLIETTA, 10,73020 CAVALLINO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "40.3128747",
    lng: "18.2000132",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020859",
  },
  {
    id: "47067",
    map_id: "1",
    address: "VIA COGHE, 90,09032 ASSEMINI (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.283205",
    lng: "9.0002701",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020862",
  },
  {
    id: "47033",
    map_id: "1",
    address: "VIA CONTE AGOSTINO PEPOLI 51,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "38.0201561",
    lng: "12.5366148",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020866",
  },
  {
    id: "47032",
    map_id: "1",
    address: "PIAZZA G. MATTEOTTI 9,45025 FRATTA POLESINE (RO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0301963",
    lng: "11.641936",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020857",
  },
  {
    id: "47030",
    map_id: "1",
    address: "VIA DELLE VIGNE NUOVE 22,00139 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9460655",
    lng: "12.5378923",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020839",
  },
  {
    id: "47028",
    map_id: "1",
    address: "VIA ANTONIO ANNARUMMA 94,83100 AVELLINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9280562",
    lng: "14.7956281",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010398",
  },
  {
    id: "46989",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 188,95033 BIANCAVILLA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6421202",
    lng: "14.8684341",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009208",
  },
  {
    id: "46966",
    map_id: "1",
    address: "VIA ORAZIO 1,92100 AGRIGENTO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.3105136",
    lng: "13.5837315",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020877",
  },
  {
    id: "46961",
    map_id: "1",
    address: "PIAZZA XX SETTEMBRE 32,70033 CORATO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.1571992",
    lng: "16.4099449",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020872",
  },
  {
    id: "46959",
    map_id: "1",
    address: "VIA MATTEO AVALLONE 20/22,84129 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6616982",
    lng: "14.7973667",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020846",
  },
  {
    id: "46954",
    map_id: "1",
    address: "VIA CONSOLINI 47/A,85100 POTENZA (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.657428",
    lng: "15.8006377",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020778",
  },
  {
    id: "46951",
    map_id: "1",
    address: "VIA CARLO TODINI 34,00012 GUIDONIA MONTECELIO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9436359",
    lng: "12.6499255",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020447",
  },
  {
    id: "46948",
    map_id: "1",
    address: "Corso Gianturco 96,85021 AVIGLIANO (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.731197",
    lng: "15.716995",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012846",
  },
  {
    id: "46937",
    map_id: "1",
    address: "VIA ETNA 34/35,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9278124",
    lng: "14.2927103",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020845",
  },
  {
    id: "46934",
    map_id: "1",
    address: "VIA RESISTENZA PARTIGIANA 29,97015 MODICA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.8391604",
    lng: "14.768696",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020478",
  },
  {
    id: "46922",
    map_id: "1",
    address: "VIA QUATTRO NOVEMBRE 33,80147 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8473393",
    lng: "14.2026889",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020801",
  },
  {
    id: "46917",
    map_id: "1",
    address: "PIAZZA UMBERTO I 3/5,03032 ARCE (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.5877348",
    lng: "13.5742999",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020538",
  },
  {
    id: "46916",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI 2,28100 NOVARA (NO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.447813",
    lng: "8.6318658",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020809",
  },
  {
    id: "46909",
    map_id: "1",
    address: "VIA CALAMATTIA 1,09121 CAGLIARI (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.2376868",
    lng: "9.1246332",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020637",
  },
  {
    id: "46882",
    map_id: "1",
    address: "VIA MARCIANO, 2/4,80035 NOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.927418",
    lng: "14.530243",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010163",
  },
  {
    id: "46881",
    map_id: "1",
    address: "VIA FEZZAN 11,70022 ALTAMURA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8259173",
    lng: "16.5466391",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020574",
  },
  {
    id: "46869",
    map_id: "1",
    address: "VIA MONASTIR 24/D,,10127 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.0154493",
    lng: "7.6544349",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020837",
  },
  {
    id: "46868",
    map_id: "1",
    address: "VIA ROMA 252,07026 OLBIA (OT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9142421",
    lng: "9.5002234",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020814",
  },
  {
    id: "46864",
    map_id: "1",
    address: "VIA SALARIA 1462/A,00138 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.0079079",
    lng: "12.5192154",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020501",
  },
  {
    id: "46863",
    map_id: "1",
    address: "VIA VARESE 40,21013 GALLARATE (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6803468",
    lng: "8.7998476",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020794",
  },
  {
    id: "46862",
    map_id: "1",
    address: "VIALE SPAGNA 25/27,76125 TRANI (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.2743914",
    lng: "16.4069899",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020805",
  },
  {
    id: "46861",
    map_id: "1",
    address: "VIA GIUSEPPE CLEMENTI 8,00033 CAVE (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8211442",
    lng: "12.9331428",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020553",
  },
  {
    id: "46858",
    map_id: "1",
    address: "VIA NAZIONALE SS 113 , 48,98070 CAPRI LEONE (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0677619",
    lng: "14.6346338",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020310",
  },
  {
    id: "46857",
    map_id: "1",
    address: "VIALE FIRENZE 50,85100 POTENZA (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.647963",
    lng: "15.7971416",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006805",
  },
  {
    id: "46855",
    map_id: "1",
    address: "VIA LONGARONE 27/29,75025 POLICORO (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.2096756",
    lng: "16.6752865",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020521",
  },
  {
    id: "46854",
    map_id: "1",
    address: "PIAZZA ROSSELLI SNC,58100 GROSSETO (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.7637058",
    lng: "11.1124207",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006600",
  },
  {
    id: "46852",
    map_id: "1",
    address: "VIA CADORNA 72,24048 TREVIOLO (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.6777873",
    lng: "9.628856",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009954",
  },
  {
    id: "46850",
    map_id: "1",
    address: "VIA M. TAFURO 7,73100 LECCE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3490487",
    lng: "18.1827495",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020852",
  },
  {
    id: "46736",
    map_id: "1",
    address: "VIA MARIA CARTA SNC,09037 SAN GAVINO MONREALE (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.5568434",
    lng: "8.796494",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020475",
  },
  {
    id: "46735",
    map_id: "1",
    address: "VIA AUGERO, 19/A,10038 VEROLENGO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.1941468",
    lng: "7.9650622",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020850",
  },
  {
    id: "46725",
    map_id: "1",
    address: "VIA DI MEARINO SNC,50066 REGGELLO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.6730375",
    lng: "11.5264118",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010204",
  },
  {
    id: "46723",
    map_id: "1",
    address: "VIA ANGELO MARIA RICCI 105,02100 RIETI (RI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4221987",
    lng: "12.863239",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020821",
  },
  {
    id: "46721",
    map_id: "1",
    address: "VIA SALVATORE CARNEVALE, 31/35,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.4890653",
    lng: "14.0504319",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020721",
  },
  {
    id: "46704",
    map_id: "1",
    address: "PIAZZA VALSESIA SNC,89018 VILLA SAN GIOVANNI (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.2191526",
    lng: "15.6364502",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020848",
  },
  {
    id: "46702",
    map_id: "1",
    address: "VIA SAN MARTINO 221,00015 MONTEROTONDO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.0817891",
    lng: "12.6096689",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020524",
  },
  {
    id: "46666",
    map_id: "1",
    address: "VIA DELLA CHIESA 29,41051 CASTELNUOVO RANGONE (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.5723198",
    lng: "10.9116734",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020520",
  },
  {
    id: "46598",
    map_id: "1",
    address: "VIA GIOVANNI FALCONE 12/14,81030 SUCCIVO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9695101",
    lng: "14.2524911",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020840",
  },
  {
    id: "46596",
    map_id: "1",
    address: "PIAZZA GAVAZZI, 1,38057 PERGINE VALSUGANA (TN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "46.060768",
    lng: "11.2374226",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020588",
  },
  {
    id: "46595",
    map_id: "1",
    address: "CORSO INDIPENDENZA 6,97011 ACATE (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0259641",
    lng: "14.4963834",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012628",
  },
  {
    id: "46592",
    map_id: "1",
    address: "VIALE CARDUCCI 279/A,57121 LIVORNO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.5541696",
    lng: "10.3318964",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009832",
  },
  {
    id: "46582",
    map_id: "1",
    address: "VIA G. MARTUCCI 14/16,80121 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8353367",
    lng: "14.2314054",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001867",
  },
  {
    id: "45524",
    map_id: "1",
    address: "VIA SACCO E VANZETTI 18,56025 PONTEDERA (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.663933",
    lng: "10.6284377",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011549",
  },
  {
    id: "45522",
    map_id: "1",
    address: "VIA VENETO 14,36015 SCHIO (VI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.7205452",
    lng: "11.3881667",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020486",
  },
  {
    id: "45521",
    map_id: "1",
    address: "VIA PASQUALE FUIANI 8,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4618639",
    lng: "15.5398452",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020749",
  },
  {
    id: "45520",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 66,71010 LESINA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.8626098",
    lng: "15.3535368",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020831",
  },
  {
    id: "45514",
    map_id: "1",
    address: "VIA ATTILIO FRIGGERI, 82,00136 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9213474",
    lng: "12.4403642",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020828",
  },
  {
    id: "45513",
    map_id: "1",
    address: "VIA DELLE MAGNOLIE 64,96100 SIRACUSA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9758592",
    lng: "15.1995162",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003186",
  },
  {
    id: "45510",
    map_id: "1",
    address: "VIA BOCCACCIO 29/R,50133 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7883602",
    lng: "11.2702964",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020838",
  },
  {
    id: "45509",
    map_id: "1",
    address: "VIA F.LLI CHIODI 33/B,25081 BEDIZZOLE (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.511696",
    lng: "10.4082893",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020591",
  },
  {
    id: "45505",
    map_id: "1",
    address: "VIA CONTRADA SANT'ELIA SNC,82030 SAN LORENZELLO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2652453",
    lng: "14.5370192",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020624",
  },
  {
    id: "45504",
    map_id: "1",
    address: "VIA TARANTO 13,75100 MATERA (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6776415",
    lng: "16.5758095",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011482",
  },
  {
    id: "45500",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI 5,91018 SALEMI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.8255991",
    lng: "12.804312",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020819",
  },
  {
    id: "45497",
    map_id: "1",
    address: "VIA V. MONTI, 2,21047 SARONNO (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.628855",
    lng: "9.0332632",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020767",
  },
  {
    id: "45493",
    map_id: "1",
    address: "VIA CASSIO PARMIENSE 1/C,43123 PARMA (PR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.7943457",
    lng: "10.3511977",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020600",
  },
  {
    id: "45452",
    map_id: "1",
    address: "VIA BARATTA 129,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5664211",
    lng: "14.9081387",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009661",
  },
  {
    id: "45433",
    map_id: "1",
    address: "CORSO GIULIO CESARE 95/A,10154 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0903621",
    lng: "7.6922434",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020670",
  },
  {
    id: "45431",
    map_id: "1",
    address: "VIA DEI GERANI, 55,00155 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.8862701",
    lng: "12.5684932",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020678",
  },
  {
    id: "45419",
    map_id: "1",
    address: "VIA ABATE MINICHINI, 16,80137 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8671259",
    lng: "14.2655214",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009786",
  },
  {
    id: "45417",
    map_id: "1",
    address: "STR TANGENZIALE NORD 8,46100 MANTOVA (MN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.1707162",
    lng: "10.8242075",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010273",
  },
  {
    id: "45416",
    map_id: "1",
    address: "VIA EMILIO LUSSU 57/A,07046 PORTO TORRES (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.83433",
    lng: "8.41322",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003803",
  },
  {
    id: "45415",
    map_id: "1",
    address: "VIA CATANZARO 47/49,00161 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.9127002",
    lng: "12.5177973",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020786",
  },
  {
    id: "45414",
    map_id: "1",
    address: "VIA PORTONE VADILLO 29,03045 ESPERIA (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4134417",
    lng: "13.6500971",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020765",
  },
  {
    id: "45413",
    map_id: "1",
    address: "VIA NAZIONALE 35,08020 GALTELLI (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3882685",
    lng: "9.615671",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020376",
  },
  {
    id: "45412",
    map_id: "1",
    address: "VIA IV NOVEMBRE 10,95030 SAN PIETRO CLARENZA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5562436",
    lng: "15.0226754",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020479",
  },
  {
    id: "45411",
    map_id: "1",
    address: "VIA BENIAMINO UBALDI SNC,06024 GUBBIO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.3472881",
    lng: "12.5724642",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020750",
  },
  {
    id: "45406",
    map_id: "1",
    address: "VIA CARLO ALBERTO DALLA CHIESA 3/5,87064 CORIGLIANO-ROSSANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.6299396",
    lng: "16.5152335",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020627",
  },
  {
    id: "45405",
    map_id: "1",
    address: "VIA FARINELLI FRAZIONE INTRA 2,28921 VERBANIA (VB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.9438346",
    lng: "8.561588",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020449",
  },
  {
    id: "45391",
    map_id: "1",
    address: "PIAZZA PETER FRANZ SCHUBERT 3/5/11,70017 PUTIGNANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "40.8502076",
    lng: "17.1294135",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020807",
  },
  {
    id: "45375",
    map_id: "1",
    address: "VIA CASTELVETERE 14,89040 CAULONIA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.3450922",
    lng: "16.4674608",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001444",
  },
  {
    id: "45306",
    map_id: "1",
    address: "VIA NAZIONALE 91,83031 ARIANO IRPINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1561163",
    lng: "15.0907462",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001806",
  },
  {
    id: "45303",
    map_id: "1",
    address: "VIA SAN BIAGIO 36,97013 COMISO (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "36.9481654",
    lng: "14.6035317",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020834",
  },
  {
    id: "45299",
    map_id: "1",
    address: "PIAZZA MARCONI 4,20834 NOVA MILANESE (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5891433",
    lng: "9.200621",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020328",
  },
  {
    id: "45296",
    map_id: "1",
    address: "VIA FLAMINIA, 54,61034 FOSSOMBRONE (PU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.6943204",
    lng: "12.8226474",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020804",
  },
  {
    id: "45295",
    map_id: "1",
    address: "VIA DELLA LIBERTA' 17,51031 AGLIANA (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "43.9042075",
    lng: "11.0070434",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-012341",
  },
  {
    id: "45294",
    map_id: "1",
    address: "VIA ROMA 364,53014 MONTERONI D'ARBIA (SI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "43.2290768",
    lng: "11.4227694",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020644",
  },
  {
    id: "45293",
    map_id: "1",
    address: "CORSO UMBERTO I 662,81033 CASAL DI PRINCIPE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.0080197",
    lng: "14.1139232",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020835",
  },
  {
    id: "45289",
    map_id: "1",
    address: "VIA BERENGARIO 12,37010 CAVAION VERONESE (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5369477",
    lng: "10.7676744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020674",
  },
  {
    id: "45282",
    map_id: "1",
    address: "Corso Vittorio Emanuele 153,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.0163296",
    lng: "12.5043174",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020820",
  },
  {
    id: "45278",
    map_id: "1",
    address: "VIA CLAUDIO MONTEVERDI 1,50144 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7844005",
    lng: "11.2370153",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020699",
  },
  {
    id: "45276",
    map_id: "1",
    address: "VIA ELEONORA 85,09099 URAS (OR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.6979676",
    lng: "8.701291",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012790",
  },
  {
    id: "45271",
    map_id: "1",
    address: "VIA CAVALIERI DI VITTORIO VENETO 28,67051 AVEZZANO (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "42.0421659",
    lng: "13.4224282",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020450",
  },
  {
    id: "45238",
    map_id: "1",
    address: "VIA MADONNA DELLA VIA 117,95041 CALTAGIRONE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.2254414",
    lng: "14.5241829",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009896",
  },
  {
    id: "45132",
    map_id: "1",
    address: "C.SO GARIBALDI 37,97018 SCICLI (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.7911478",
    lng: "14.7048113",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008523",
  },
  {
    id: "45127",
    map_id: "1",
    address: "VIALE DEI PROMONTORI 3-5,00122 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7399204",
    lng: "12.2848528",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008720",
  },
  {
    id: "45079",
    map_id: "1",
    address: "VIA EVEREST ANG. VIA PITAGORA SNC,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.0764814",
    lng: "14.2247089",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009912",
  },
  {
    id: "45002",
    map_id: "1",
    address: "CORSO CAMPANO 25,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.928663",
    lng: "14.2079902",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009562",
  },
  {
    id: "45000",
    map_id: "1",
    address: "VIA CASTEL LENTINI 173,96010 PRIOLO GARGALLO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.1578131",
    lng: "15.1848786",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009077",
  },
  {
    id: "44979",
    map_id: "1",
    address: "CORSO DELLA RESISTENZA 39,80011 ACERRA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9440518",
    lng: "14.3688291",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009531",
  },
  {
    id: "44926",
    map_id: "1",
    address: "VIA DELLA REGIONE 2,95040 CAMPOROTONDO ETNEO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5639673",
    lng: "15.0019132",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009749",
  },
  {
    id: "44877",
    map_id: "1",
    address: "VIA MAESTRI DEL LAVORO, 4,33170 PORDENONE (PN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.966654",
    lng: "12.6951319",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011001",
  },
  {
    id: "44874",
    map_id: "1",
    address: "VIA GIOSUE' CARDUCCI 6/E,20060 MEDIGLIA (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4278991",
    lng: "9.3643772",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020621",
  },
  {
    id: "44850",
    map_id: "1",
    address: "VIA POSIDONIA 233,84128 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.6662036",
    lng: "14.7920688",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020775",
  },
  {
    id: "44843",
    map_id: "1",
    address: "CORSO GARIBALDI  377,94012 BARRAFRANCA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.3781886",
    lng: "14.2027119",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008697",
  },
  {
    id: "44805",
    map_id: "1",
    address: "VIA DEL CASAL LUMBROSO 135/C,00166 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.8721227",
    lng: "12.3647315",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020822",
  },
  {
    id: "44803",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 143,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.0644701",
    lng: "14.2550701",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009513",
  },
  {
    id: "44797",
    map_id: "1",
    address: "CORSO LEONARDO DA VINCI 413,80040 TERZIGNO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8035325",
    lng: "14.502229",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003826",
  },
  {
    id: "44796",
    map_id: "1",
    address: "Via Vittorio Veneto, 96,84013 CAVA DE' TIRRENI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.7047646",
    lng: "14.706343",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020715",
  },
  {
    id: "44795",
    map_id: "1",
    address: "VIA BARI, 44/B,70014 CONVERSANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9717493",
    lng: "17.1110639",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020689",
  },
  {
    id: "44793",
    map_id: "1",
    address: "VIA STELVIO 329/B,23020 MONTAGNA IN VALTELLINA (SO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "46.1700874",
    lng: "9.9022079",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011050",
  },
  {
    id: "44792",
    map_id: "1",
    address: "Via Fabio Filzi, 3,20011 CORBETTA (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4710728",
    lng: "8.9174613",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004622",
  },
  {
    id: "44790",
    map_id: "1",
    address: "VIA MANZONI 3H,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "36.9557836",
    lng: "14.5261113",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009029",
  },
  {
    id: "44788",
    map_id: "1",
    address: "VIA FRATELLI CAPILUPI 1,88050 PENTONE (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9862671",
    lng: "16.5830966",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020791",
  },
  {
    id: "44785",
    map_id: "1",
    address: "VIA SAN MARCO 1,05030 POLINO (TR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "45.4735039",
    lng: "9.1875719",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020796",
  },
  {
    id: "44783",
    map_id: "1",
    address: "VIA REGIONE ANNUNZIATA 3,14050 MOASCA (AT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.760248",
    lng: "8.2749272",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020630",
  },
  {
    id: "44763",
    map_id: "1",
    address: "CORSO EMANUELE II 5,85022 BARILE (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9465641",
    lng: "15.6811213",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020780",
  },
  {
    id: "44761",
    map_id: "1",
    address: "VIA FRANCESCO COCCO ORTU 6/8,00139 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.9581159",
    lng: "12.5222293",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020660",
  },
  {
    id: "44748",
    map_id: "1",
    address: "C.SO GARIBALDI 149/A,80055 PORTICI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8166696",
    lng: "14.3326894",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001815",
  },
  {
    id: "44738",
    map_id: "1",
    address: "VIA GIUSEPPE UNGARETTI, 17/A,84090 MONTECORVINO PUGLIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6300497",
    lng: "14.9107992",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020676",
  },
  {
    id: "44736",
    map_id: "1",
    address: "VIALE DEGLI ANGIONI 11,88100 CATANZARO (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.885354",
    lng: "16.6031535",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020733",
  },
  {
    id: "44707",
    map_id: "1",
    address: "VIA MONTINI, 14,33170 PORDENONE (PN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.9506193",
    lng: "12.6681897",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010110",
  },
  {
    id: "44701",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 590,90039 VILLABATE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0756012",
    lng: "13.4496302",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009188",
  },
  {
    id: "44697",
    map_id: "1",
    address: "VIA A. SCARLATTI 211,80127 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8431735",
    lng: "14.2264565",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008548",
  },
  {
    id: "44693",
    map_id: "1",
    address: "VIA MAZZINI 91,88060 MONTEPAONE (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.7313416",
    lng: "16.5375693",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003426",
  },
  {
    id: "44684",
    map_id: "1",
    address: "VIA TRIESTE 28,91028 PARTANNA (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.7290119",
    lng: "12.893558",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009712",
  },
  {
    id: "44683",
    map_id: "1",
    address: "VIA CAVOUR 2,10024 MONCALIERI (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "44.9987293",
    lng: "7.6800329",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020811",
  },
  {
    id: "44681",
    map_id: "1",
    address: "VIA MENZA 1,84020 SAN GREGORIO MAGNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.6559227",
    lng: "15.4025778",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020813",
  },
  {
    id: "44674",
    map_id: "1",
    address: "PIAZZA G. FORNASA 1,06055 MARSCIANO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.9123138",
    lng: "12.3363281",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020500",
  },
  {
    id: "44672",
    map_id: "1",
    address: "VIA RAFFAELE BOSCO 835,80069 VICO EQUENSE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6356304",
    lng: "14.4359862",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020773",
  },
  {
    id: "44669",
    map_id: "1",
    address: "Via Napoleone Colajanni, 102,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.4853865",
    lng: "14.0588834",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020731",
  },
  {
    id: "44668",
    map_id: "1",
    address: "VIA GIUSEPPE AMMENDOLA 68/12,80047 SAN GIUSEPPE VESUVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8360979",
    lng: "14.5067589",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020658",
  },
  {
    id: "44666",
    map_id: "1",
    address: "VIA ONOFRIO DI GIORDANO 52,84013 CAVA DE' TIRRENI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6991582",
    lng: "14.7118745",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020808",
  },
  {
    id: "44662",
    map_id: "1",
    address: "CORSO SECONDIGLIANO 89,80144 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "40.883981",
    lng: "14.2696993",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020798",
  },
  {
    id: "44659",
    map_id: "1",
    address: "VIA AQUILEIA 65,80143 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8618412",
    lng: "14.2778192",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009252",
  },
  {
    id: "44657",
    map_id: "1",
    address: "CORSO SAN VITO 109,95030 MASCALUCIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.574555",
    lng: "15.0565767",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020396",
  },
  {
    id: "44644",
    map_id: "1",
    address: "VIA SANTA MARGHERITA SNC,20121 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4661801",
    lng: "9.1886222",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020743",
  },
  {
    id: "44636",
    map_id: "1",
    address: "VIA TEVERE 26,84098 PONTECAGNANO FAIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6470907",
    lng: "14.8721868",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020781",
  },
  {
    id: "44619",
    map_id: "1",
    address: "Via Enrico Fermi, 26,89048 SIDERNO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.274252",
    lng: "16.3046478",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001402",
  },
  {
    id: "44588",
    map_id: "1",
    address: "VIA A. DE CURTIS 13,80078 POZZUOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8763605",
    lng: "14.0955177",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020527",
  },
  {
    id: "44587",
    map_id: "1",
    address: "SANTA CROCE, 146/I,30135 VENEZIA (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4317721",
    lng: "12.34988",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020706",
  },
  {
    id: "44577",
    map_id: "1",
    address: "VIA  A. BERNEZZO 115/B,10146 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.0712764",
    lng: "7.665274",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011551",
  },
  {
    id: "44573",
    map_id: "1",
    address: "VIALE RISORGIMENTO 84,64046 MONTORIO AL VOMANO (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.582327",
    lng: "13.6368931",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020766",
  },
  {
    id: "44568",
    map_id: "1",
    address: "VIA KENNEDY 3,87010 TERRANOVA DA SIBARI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "39.6577933",
    lng: "16.3408175",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020777",
  },
  {
    id: "44558",
    map_id: "1",
    address: "VIA CASILINA SUD, 48,03027 RIPI (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.550398",
    lng: "13.418674",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020790",
  },
  {
    id: "44557",
    map_id: "1",
    address: "VIA MOLINETTO 52/A,31030 BREDA DI PIAVE (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.73",
    lng: "12.3916",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020782",
  },
  {
    id: "44555",
    map_id: "1",
    address: "VIA COMUNALE SAN FRANCESCO  78,00061 ANGUILLARA SABAZIA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.085932",
    lng: "12.2739969",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006594",
  },
  {
    id: "44539",
    map_id: "1",
    address: "VIA ROMA 4,93010 SERRADIFALCO (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "37.456805",
    lng: "13.8826657",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009498",
  },
  {
    id: "44522",
    map_id: "1",
    address: "VIA PAESE VECCHIO 26,89842 SAN CALOGERO (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "38.574717",
    lng: "16.0252351",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020776",
  },
  {
    id: "44501",
    map_id: "1",
    address: "VIA MUZIO SCEVOLA 6,76012 CANOSA DI PUGLIA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2223277",
    lng: "16.0668882",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020672",
  },
  {
    id: "44476",
    map_id: "1",
    address: "VIA PANEBIANCO 424,87100 COSENZA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.3126609",
    lng: "16.2469051",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020753",
  },
  {
    id: "44474",
    map_id: "1",
    address: "Via Nazionale Appia 302,81020 CASAPULLA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0740009",
    lng: "14.2884694",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003829",
  },
  {
    id: "44469",
    map_id: "1",
    address: "VIALE MICHELANGELO 2,50019 SESTO FIORENTINO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8367516",
    lng: "11.1957916",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020738",
  },
  {
    id: "44466",
    map_id: "1",
    address: "VIALE LEONARDO DA VINCI 335,90135 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.1273831",
    lng: "13.3235199",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020468",
  },
  {
    id: "44465",
    map_id: "1",
    address: "VIA CHIANCHITTA 106,98035 GIARDINI-NAXOS (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.8246946",
    lng: "15.2559417",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012603",
  },
  {
    id: "44391",
    map_id: "1",
    address: "VIA TRENTO 24/30,84016 PAGANI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7446853",
    lng: "14.6227406",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020548",
  },
  {
    id: "44378",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 62,70027 PALO DEL COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.058217",
    lng: "16.705977",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020655",
  },
  {
    id: "44368",
    map_id: "1",
    address: "VIA SAN NICOLAO 7,10060 AIRASCA (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "45.467299",
    lng: "9.1773116",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020785",
  },
  {
    id: "44366",
    map_id: "1",
    address: "VIA S.ANGELO 8,50013 CAMPI BISENZIO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8133759",
    lng: "11.0797058",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020618",
  },
  {
    id: "44339",
    map_id: "1",
    address: "VIALE GIUSEPPE MAZZINI 53,03100 FROSINONE (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6373487",
    lng: "13.3495127",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020788",
  },
  {
    id: "44336",
    map_id: "1",
    address: "VIA MONTEGRAPPA 1,46020 PEGOGNAGA (MN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.9954433",
    lng: "10.859355",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020589",
  },
  {
    id: "44321",
    map_id: "1",
    address: "VIA NAPOLI, 57,70121 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.1268746",
    lng: "16.8614891",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020772",
  },
  {
    id: "44316",
    map_id: "1",
    address: "VIALE CANDURA STEFANO 18G,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "37.4778349",
    lng: "14.0527526",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009560",
  },
  {
    id: "44311",
    map_id: "1",
    address: "VIA MANZONI, 14,87046 MONTALTO UFFUGO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "39.4305097",
    lng: "16.2294852",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020783",
  },
  {
    id: "44308",
    map_id: "1",
    address: "VIA MARCONI, 135,98051 BARCELLONA POZZO DI GOTTO (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.1477684",
    lng: "15.2142405",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020643",
  },
  {
    id: "44307",
    map_id: "1",
    address: "VIA LO PORTO 30,92014 PORTO EMPEDOCLE (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.2898094",
    lng: "13.5259965",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020526",
  },
  {
    id: "44306",
    map_id: "1",
    address: "CORSO ITALIA 2,71010 LESINA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.8626029",
    lng: "15.3531084",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020787",
  },
  {
    id: "44298",
    map_id: "1",
    address: "VIALE DI VILLA PAMPHILI, 198/200,00152 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8769958",
    lng: "12.4574513",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020714",
  },
  {
    id: "44267",
    map_id: "1",
    address: "VIA FRATELLI CERVI LOTTO TB,80144 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9067134",
    lng: "14.2405721",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020558",
  },
  {
    id: "44266",
    map_id: "1",
    address: "CORSO DEI MILLE 308,90121 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1071486",
    lng: "13.3729005",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020736",
  },
  {
    id: "44259",
    map_id: "1",
    address: "Provinciale del Virginio, snc,50025 MONTESPERTOLI (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.656712",
    lng: "11.0888419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012788",
  },
  {
    id: "44247",
    map_id: "1",
    address: "VIA J. F. KENNEDY 77,73019 TREPUZZI (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "40.4033656",
    lng: "18.0742923",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020771",
  },
  {
    id: "44246",
    map_id: "1",
    address: "VIA UMBERTO, 529,98035 GIARDINI-NAXOS (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.8506046",
    lng: "15.2803754",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020768",
  },
  {
    id: "44235",
    map_id: "1",
    address: "VIA SAMPERI 312A,93015 NISCEMI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.1461712",
    lng: "14.3943314",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020759",
  },
  {
    id: "44231",
    map_id: "1",
    address: "VIA CANALETTO, 75,41030 SAN PROSPERO (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.7897028",
    lng: "11.0228726",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020703",
  },
  {
    id: "44216",
    map_id: "1",
    address: "VIA FRATELLI ROSSELLI 166,84096 MONTECORVINO ROVELLA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.6546281",
    lng: "14.9803296",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020732",
  },
  {
    id: "44210",
    map_id: "1",
    address: "P.ZZA F. GAGLIARDI 1,84033 MONTESANO SULLA MARCELLANA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.2764614",
    lng: "15.7022342",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008741",
  },
  {
    id: "44209",
    map_id: "1",
    address: "VIA CORSO E. DE NICOLA, 88,80040 TERZIGNO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8141017",
    lng: "14.4937846",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020606",
  },
  {
    id: "44206",
    map_id: "1",
    address: "VIA FILOCOMO 38,95121 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "37.5191273",
    lng: "15.0822472",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020563",
  },
  {
    id: "44165",
    map_id: "1",
    address: "VIALE XXV APRILE 89/91,00034 COLLEFERRO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7252115",
    lng: "13.0082427",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020556",
  },
  {
    id: "44143",
    map_id: "1",
    address: "VIA ALDROVANDI 6,41012 CARPI (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.7801178",
    lng: "10.8842688",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012464",
  },
  {
    id: "44142",
    map_id: "1",
    address: "VIA LIBERTÀ 3,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.5681766",
    lng: "14.905362",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020757",
  },
  {
    id: "44141",
    map_id: "1",
    address: "VIA PIO 8,24062 COSTA VOLPINO (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.8287309",
    lng: "10.1082066",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020744",
  },
  {
    id: "44127",
    map_id: "1",
    address:
      "Via Nazionale, 97 - Policastro Bussentino,84067 SANTA MARINA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.0740172",
    lng: "15.5193876",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001877",
  },
  {
    id: "44126",
    map_id: "1",
    address: "VIA LO PORTO 165,84018 SCAFATI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7774813",
    lng: "14.5578026",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020371",
  },
  {
    id: "44120",
    map_id: "1",
    address: "VIA FILIPPO NICOLAI 84/86,00136 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.9164315",
    lng: "12.440887",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020748",
  },
  {
    id: "44098",
    map_id: "1",
    address: "VIA GIUSEPPE DENARO 51,91022 CASTELVETRANO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6753381",
    lng: "12.791455",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013028",
  },
  {
    id: "44097",
    map_id: "1",
    address: "VIA LARGO LEONARDO DA VINCI 12,96100 SIRACUSA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.0840594",
    lng: "15.2849841",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020628",
  },
  {
    id: "44086",
    map_id: "1",
    address: "VIA XXV APRILE 36,21020 BUGUGGIATE (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.7804216",
    lng: "8.8098671",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020546",
  },
  {
    id: "44085",
    map_id: "1",
    address: "VIA CREA 10,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "39.082045",
    lng: "17.1235897",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020742",
  },
  {
    id: "44076",
    map_id: "1",
    address: "VIA EMANUELE DI BARTOLO 21,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.0735203",
    lng: "17.1288212",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020717",
  },
  {
    id: "44069",
    map_id: "1",
    address: "VIA PONTIDA, 6D,63074 SAN BENEDETTO DEL TRONTO (AP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.9001282",
    lng: "13.8970207",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020698",
  },
  {
    id: "44067",
    map_id: "1",
    address: "VIA CORRER, 32,33080 PORCIA (PN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.9575827",
    lng: "12.6220831",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020646",
  },
  {
    id: "44065",
    map_id: "1",
    address: "PIAZZA CASTELLO 5,82034 GUARDIA SANFRAMONDI (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2557788",
    lng: "14.5964477",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009382",
  },
  {
    id: "44063",
    map_id: "1",
    address: "VIA SAN FRANCESCO, 28,55045 PIETRASANTA (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.9610863",
    lng: "10.2267402",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020740",
  },
  {
    id: "44061",
    map_id: "1",
    address: "Corso della Repubblica, 296,04012 CISTERNA DI LATINA (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.5884135",
    lng: "12.8297453",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020723",
  },
  {
    id: "44060",
    map_id: "1",
    address: "VIA LIBERTA' 19,90010 LASCARI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "38.0005451",
    lng: "13.9401969",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020761",
  },
  {
    id: "44057",
    map_id: "1",
    address: "VIA UFENTE 18,00199 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9231271",
    lng: "12.510786",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020747",
  },
  {
    id: "44055",
    map_id: "1",
    address: "CORSO GIULIO CESARE 177,10155 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.097979",
    lng: "7.6972749",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012378",
  },
  {
    id: "44015",
    map_id: "1",
    address: "VIA PASCOLI SNC,20038 BUSTO GAROLFO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5519638",
    lng: "8.8888788",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020741",
  },
  {
    id: "44012",
    map_id: "1",
    address: "VIA DE CHIRICO 12,27029 VIGEVANO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.3072644",
    lng: "8.8676116",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020734",
  },
  {
    id: "44010",
    map_id: "1",
    address: "VIA DELL'OLMO 13,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0219652",
    lng: "12.5340379",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020649",
  },
  {
    id: "44009",
    map_id: "1",
    address: "CORSO ALCIDE DE GASPERI 165,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7063558",
    lng: "14.4823583",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020648",
  },
  {
    id: "44008",
    map_id: "1",
    address: "Via Apollo XI, 15,71011 APRICENA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7853717",
    lng: "15.4474927",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004001",
  },
  {
    id: "44007",
    map_id: "1",
    address: "VIA VENEZIA 49,65121 PESCARA (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.4672971",
    lng: "14.2147195",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003430",
  },
  {
    id: "44006",
    map_id: "1",
    address: "VIA CALTANISSETTA 80 PIANO SOPRA,93014 MUSSOMELI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.578659",
    lng: "13.7576326",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008503",
  },
  {
    id: "44005",
    map_id: "1",
    address: "VIA G. NATALE SNC,87062 CARIATI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.4901811",
    lng: "16.9591109",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020705",
  },
  {
    id: "44004",
    map_id: "1",
    address: "VIA VOLPICELLI 13/15,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9165068",
    lng: "14.2652951",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020438",
  },
  {
    id: "43993",
    map_id: "1",
    address: "VIA PASTRENGO 16,71017 TORREMAGGIORE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.688618",
    lng: "15.292137",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003427",
  },
  {
    id: "43982",
    map_id: "1",
    address: "VIA GENERALE ARMANDO DIAZ 41,84122 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6778093",
    lng: "14.7680115",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020686",
  },
  {
    id: "43981",
    map_id: "1",
    address: "VIA ROLANDO 15/A,07100 SASSARI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7221265",
    lng: "8.5564396",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020673",
  },
  {
    id: "43978",
    map_id: "1",
    address: "VIA NICOLARDI 145,80131 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8716576",
    lng: "14.2352724",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020557",
  },
  {
    id: "43976",
    map_id: "1",
    address: "VIA LUCERA 67,71038 PIETRAMONTECORVINO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.5412969",
    lng: "15.1324557",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020675",
  },
  {
    id: "43966",
    map_id: "1",
    address: "VIA ITALO BALBO 78,04020 ITRI (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2898673",
    lng: "13.5259062",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003226",
  },
  {
    id: "43940",
    map_id: "1",
    address: "CORSO ACCURSIO MIRAGLIA 112/A,92019 SCIACCA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5129224",
    lng: "13.0702418",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004074",
  },
  {
    id: "43931",
    map_id: "1",
    address: "VIA DEI GERANI, SNC,08020 SAN TEODORO (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7708084",
    lng: "9.6697592",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006396",
  },
  {
    id: "43929",
    map_id: "1",
    address: "VIALE ARGINE DE MORERI 52/B,34073 GRADO (GO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6818284",
    lng: "13.404908",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020564",
  },
  {
    id: "43920",
    map_id: "1",
    address: "VIA GIARDINELLO 6,88050 SELLIA MARINA (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "38.9064883",
    lng: "16.7408906",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008346",
  },
  {
    id: "43909",
    map_id: "1",
    address: "VIA PROV.LE EX S.S.10 10,46012 BOZZOLO (MN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.2264406",
    lng: "11.7289649",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020602",
  },
  {
    id: "43906",
    map_id: "1",
    address: "VIA SANTA LIBERATA 40,21053 CASTELLANZA (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6168236",
    lng: "8.9088185",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020443",
  },
  {
    id: "43874",
    map_id: "1",
    address: "VIA PORCHEDDU 21,07100 SASSARI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7172344",
    lng: "8.5720823",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001916",
  },
  {
    id: "43866",
    map_id: "1",
    address: "VIA CANONICO DE LUCA 152,84040 CELLE DI BULGHERIA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.09535",
    lng: "15.40769",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011743",
  },
  {
    id: "43860",
    map_id: "1",
    address: "VIA F. SPIRITO 37,84095 GIFFONI VALLE PIANA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7133251",
    lng: "14.9399139",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020505",
  },
  {
    id: "43849",
    map_id: "1",
    address: "VIA ANTONIO SILVANI 12,00139 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.9503218",
    lng: "12.5181999",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013081",
  },
  {
    id: "43847",
    map_id: "1",
    address: "VIA CAGLIARI 17,07010 NULE (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.4627557",
    lng: "9.1909879",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009694",
  },
  {
    id: "43802",
    map_id: "1",
    address: "VIA GABRIELE JUDICA 15,96010 PALAZZOLO ACREIDE (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0616977",
    lng: "14.9059891",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009817",
  },
  {
    id: "43782",
    map_id: "1",
    address: "Vicolo del mercato 4/6,94014 NICOSIA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.747596",
    lng: "14.398172",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-002026",
  },
  {
    id: "43774",
    map_id: "1",
    address: "VIA SAN ROCCO 170,81030 CASTEL VOLTURNO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0272425",
    lng: "13.9466832",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020491",
  },
  {
    id: "43720",
    map_id: "1",
    address: "VIALE PUPINI 3,40017 SAN GIOVANNI IN PERSICETO (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.6391117",
    lng: "11.1854347",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020722",
  },
  {
    id: "43716",
    map_id: "1",
    address: "VIALE ITALIA 36,89013 GIOIA TAURO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.4213223",
    lng: "15.8945914",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020735",
  },
  {
    id: "43680",
    map_id: "1",
    address: "VIA MADONNA DELLE GRAZIE 20,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.660233",
    lng: "14.834978",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008600",
  },
  {
    id: "43626",
    map_id: "1",
    address: "VIA FLAMINA VECCHIA 142,06049 SPOLETO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.7466265",
    lng: "12.7436477",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020684",
  },
  {
    id: "43623",
    map_id: "1",
    address: "VIA VERONA 21,84091 BATTIPAGLIA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6122935",
    lng: "14.9795735",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020724",
  },
  {
    id: "43611",
    map_id: "1",
    address: "CORSO MEDITERRANEO 82,87029 SCALEA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.8111841",
    lng: "15.7897335",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020652",
  },
  {
    id: "43608",
    map_id: "1",
    address: "VIA CAMOZZI, 7,00195 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.9121826",
    lng: "12.4585277",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020665",
  },
  {
    id: "43605",
    map_id: "1",
    address: "Via Torcicoda, 37/A,50142 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.776618",
    lng: "11.2156993",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012774",
  },
  {
    id: "43585",
    map_id: "1",
    address: "Via Nazionale, 165,89134 REGGIO CALABRIA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.0453534",
    lng: "15.6557941",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020728",
  },
  {
    id: "43583",
    map_id: "1",
    address: "VIALE REGIONE SICILIANA, 37,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0174749",
    lng: "12.5377354",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020650",
  },
  {
    id: "43582",
    map_id: "1",
    address: "VIA PASSARELLA 13,30027 SAN DONA' DI PIAVE (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5968322",
    lng: "12.6084367",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020547",
  },
  {
    id: "43570",
    map_id: "1",
    address: "VIALE KENNEDY 163,93017 SAN CATALDO (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.4877231",
    lng: "13.9963977",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020730",
  },
  {
    id: "43567",
    map_id: "1",
    address: "VIALE REDI 53/B,57122 LIVORNO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.5409725",
    lng: "10.3209871",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020711",
  },
  {
    id: "43566",
    map_id: "1",
    address: "VIA XXV APRILE 164,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.0811508",
    lng: "17.1193295",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020463",
  },
  {
    id: "43565",
    map_id: "1",
    address: "VIA DE SENA 260,80035 NOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9308794",
    lng: "14.5280286",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010378",
  },
  {
    id: "43564",
    map_id: "1",
    address: "VIA SASSARI 78,07044 ITTIRI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.5957517",
    lng: "8.5662429",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020692",
  },
  {
    id: "43559",
    map_id: "1",
    address: "Via Catania, 493,98124 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1688477",
    lng: "15.5418961",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020551",
  },
  {
    id: "43557",
    map_id: "1",
    address: "VIA CONTE PIROMALLO 18,80040 SAN SEBASTIANO AL VESUVIO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8448333",
    lng: "14.3708508",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020633",
  },
  {
    id: "43542",
    map_id: "1",
    address: "VIA DIOCLEZIANO 432,80124 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.8176111",
    lng: "14.1774269",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020645",
  },
  {
    id: "43500",
    map_id: "1",
    address: "VIA DANTE 25,92020 SAN GIOVANNI GEMINI (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.630029",
    lng: "13.6407658",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004087",
  },
  {
    id: "43427",
    map_id: "1",
    address: "VIA MONSIGNOR RAIMONDO TORE 75,08039 TONARA (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.0239358",
    lng: "9.1701577",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020694",
  },
  {
    id: "43425",
    map_id: "1",
    address: "VIA REPUBBLICA 149,09039 VILLACIDRO (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.4587621",
    lng: "8.7415468",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009242",
  },
  {
    id: "43421",
    map_id: "1",
    address: "V.LE DELLA LIBERTA 258,87075 TREBISACCE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.8727173",
    lng: "16.5374051",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008351",
  },
  {
    id: "43420",
    map_id: "1",
    address: "VIA ISTONIA 115,66050 SAN SALVO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "42.0541815",
    lng: "14.7331513",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010912",
  },
  {
    id: "43419",
    map_id: "1",
    address: "LOC. CAPOLUOGO 75,11020 FONTAINEMORE (AO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6484961",
    lng: "7.8596623",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010789",
  },
  {
    id: "43415",
    map_id: "1",
    address: "VIA LIGURIA 37,09013 CARBONIA (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.173568",
    lng: "8.520635",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001911",
  },
  {
    id: "43408",
    map_id: "1",
    address: "VIA A. DE GASPERI 53,76011 BISCEGLIE (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.2377231",
    lng: "16.5001306",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020657",
  },
  {
    id: "43400",
    map_id: "1",
    address: "VIA SAN VITO 75,72021 FRANCAVILLA FONTANA (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.5343494",
    lng: "17.5872322",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020604",
  },
  {
    id: "43389",
    map_id: "1",
    address: "VIA DEI MONTI DI S.PAOLO 41/F,00126 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7882829",
    lng: "12.3577975",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020654",
  },
  {
    id: "43387",
    map_id: "1",
    address: "STRADA STATALE GELSO BIANCO SNC,95121 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.4719355",
    lng: "15.0484577",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007546",
  },
  {
    id: "43385",
    map_id: "1",
    address: "VIA SANDRO BOTTICELLI 36,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8583455",
    lng: "14.1686905",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020707",
  },
  {
    id: "43382",
    map_id: "1",
    address: "CORSO ITALIA, 75-77,80039 SAVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9121056",
    lng: "14.5107493",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020661",
  },
  {
    id: "43360",
    map_id: "1",
    address: "VI A MATTEOTTI 41,95040 SAN CONO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.2900184",
    lng: "14.3666663",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008727",
  },
  {
    id: "43359",
    map_id: "1",
    address: "VIA CRISTOFORO COLOMBO 19,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.0769548",
    lng: "17.1314322",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020595",
  },
  {
    id: "43353",
    map_id: "1",
    address: "VIA MADDALENA 2,98040 TORREGROTTA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1919364",
    lng: "15.3550805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020586",
  },
  {
    id: "43350",
    map_id: "1",
    address: "GESMUNDO FABRIZIO 28,70038 TERLIZZI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.129464",
    lng: "16.5440164",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020700",
  },
  {
    id: "43349",
    map_id: "1",
    address: "VIA CERIARA 22,04018 SEZZE (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.4826226",
    lng: "13.1107755",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020709",
  },
  {
    id: "43348",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI 8,81040 CURTI (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0768065",
    lng: "14.2744241",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020594",
  },
  {
    id: "43347",
    map_id: "1",
    address: "PIAZZETTA DON GANDOSSI  8,24046 OSIO SOTTO (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6207077",
    lng: "9.5998076",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008338",
  },
  {
    id: "43346",
    map_id: "1",
    address: "VIALE VERDI 88/D,23807 MERATE (LC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6938705",
    lng: "9.4155626",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020585",
  },
  {
    id: "43345",
    map_id: "1",
    address: "VIA SAN GIOVANNI BOSCO 25,71043 MANFREDONIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6323616",
    lng: "15.9228715",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020625",
  },
  {
    id: "43340",
    map_id: "1",
    address: "VIA ROMA 72,88054 SERSALE (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "39.0116386",
    lng: "16.7275873",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020568",
  },
  {
    id: "43338",
    map_id: "1",
    address: "PIAZZALE D'ANNUNZIO SNC,33100 UDINE (UD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "46.057472",
    lng: "13.244547",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010113",
  },
  {
    id: "43335",
    map_id: "1",
    address: "CORSO DEL POPOLO 190,45100 ROVIGO (RO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0697452",
    lng: "11.7899984",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020690",
  },
  {
    id: "43330",
    map_id: "1",
    address: "VIA G. LA PIRA 10,35030 SELVAZZANO DENTRO (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.393919",
    lng: "11.7798113",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009121",
  },
  {
    id: "43325",
    map_id: "1",
    address: "VIA ENRICO DE NICOLA 41/43,80010 QUARTO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8775192",
    lng: "14.1441781",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020687",
  },
  {
    id: "43320",
    map_id: "1",
    address: "VIA DEL QUADRIFOGLIO 19,95030 TREMESTIERI ETNEO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5707698",
    lng: "15.081949",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020696",
  },
  {
    id: "43281",
    map_id: "1",
    address: "VIA DEL CANALETTO 121,19124 LA SPEZIA (SP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.1153605",
    lng: "9.8452305",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008401",
  },
  {
    id: "43242",
    map_id: "1",
    address: "VIA SAN GAVINO MONREALE, 6, 6,09039 VILLACIDRO (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.4632956",
    lng: "8.7417152",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020671",
  },
  {
    id: "43236",
    map_id: "1",
    address: "VIA VINCENZO MADONIA, 71,90121 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.098997",
    lng: "13.3563571",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020583",
  },
  {
    id: "43230",
    map_id: "1",
    address: "CORSO UMBERTO I, 453,80034 MARIGLIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "40.924309",
    lng: "14.459644",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020682",
  },
  {
    id: "43224",
    map_id: "1",
    address: "VIA VINCENZO MODUGNO, 4, 4,70032 BITONTO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.1099326",
    lng: "16.6831401",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011590",
  },
  {
    id: "43222",
    map_id: "1",
    address: "VIALE ITALIA 31,57023 CECINA (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "43.3087585",
    lng: "10.5173717",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011221",
  },
  {
    id: "43220",
    map_id: "1",
    address: "VIA FIUME 13,74025 GINOSA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4288584",
    lng: "16.8856825",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009570",
  },
  {
    id: "43213",
    map_id: "1",
    address: "VIA GIACOMO MATTEOTTI 40,13836 COSSATO (BI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5735493",
    lng: "8.1788758",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020549",
  },
  {
    id: "43206",
    map_id: "1",
    address: "PIAZZA DELLA REPUBBLICA 6/D,10093 COLLEGNO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.077874",
    lng: "7.573713",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020653",
  },
  {
    id: "43205",
    map_id: "1",
    address: "PIAZZA VOLTA 7,27029 VIGEVANO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.3149793",
    lng: "8.852988",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020605",
  },
  {
    id: "43203",
    map_id: "1",
    address: "VIA EMILIA CENTRO 207,41121 MODENA (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.6466816",
    lng: "10.9261135",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020639",
  },
  {
    id: "43201",
    map_id: "1",
    address: "VIA OBERDAN 187/R,16167 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.3840718",
    lng: "9.0361817",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010227",
  },
  {
    id: "43174",
    map_id: "1",
    address: "PIAZZA DELLA VITTORIA 5,17014 CAIRO MONTENOTTE (SV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "44.3987098",
    lng: "8.2746126",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020509",
  },
  {
    id: "43164",
    map_id: "1",
    address: "VIA ROSSANO 68,80023 CAIVANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9568899",
    lng: "14.3004929",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008759",
  },
  {
    id: "43155",
    map_id: "1",
    address: "VIA VITTIME CIVILI 19,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.4610006",
    lng: "15.539606",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012431",
  },
  {
    id: "43147",
    map_id: "1",
    address: "VIA CIRCUNVALLAZIONE 28,81038 TRENTOLA-DUCENTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.97281",
    lng: "14.17789",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008758",
  },
  {
    id: "43107",
    map_id: "1",
    address: "Via Paolo Vasta, 64,95024 ACIREALE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6162365",
    lng: "15.1653418",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020579",
  },
  {
    id: "43103",
    map_id: "1",
    address: "Via Dante Danni, 9,10138 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.0698219",
    lng: "7.6540062",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020608",
  },
  {
    id: "43076",
    map_id: "1",
    address: "VIA MARANGIO I SNC,04023 FORMIA (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.255357",
    lng: "13.6043739",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009079",
  },
  {
    id: "43056",
    map_id: "1",
    address: "VIALE DELLA RESISTENZA 79G,97013 COMISO (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.954012",
    lng: "14.6103594",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020592",
  },
  {
    id: "43033",
    map_id: "1",
    address: "VIA GIOVANNI XXIII 22,71019 VIESTE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.883382",
    lng: "16.1748664",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003525",
  },
  {
    id: "43029",
    map_id: "1",
    address: "CORSO UMBERTO I 297/301,90010 FICARAZZI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0885745",
    lng: "13.4646296",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009047",
  },
  {
    id: "43028",
    map_id: "1",
    address: "VLE GUGLIELMO OBERDAN 444,47521 CESENA (FC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.1400927",
    lng: "12.2540431",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009811",
  },
  {
    id: "43025",
    map_id: "1",
    address: "PIAZZA A. DE GASPERI 7,20060 CASSINA DE'PECCHI (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5200781",
    lng: "9.3642684",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-002213",
  },
  {
    id: "43023",
    map_id: "1",
    address: "Via Risorgimento,41,88041 DECOLLATURA (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "39.0522807",
    lng: "16.3468998",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020667",
  },
  {
    id: "43021",
    map_id: "1",
    address: "CORSO NOVARA 71,27029 VIGEVANO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.3232363",
    lng: "8.8453739",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020504",
  },
  {
    id: "43013",
    map_id: "1",
    address: "VIALE STAZIONE 11,32014 PONTE NELLE ALPI (BL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "46.1821786",
    lng: "12.2804127",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004215",
  },
  {
    id: "42959",
    map_id: "1",
    address: "VIA OGLIO 68,46010 MARCARIA (MN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.111509",
    lng: "10.5697187",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020609",
  },
  {
    id: "42958",
    map_id: "1",
    address: "VIA MESTRE 20/A,70022 ALTAMURA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8256988",
    lng: "16.5566493",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001512",
  },
  {
    id: "42957",
    map_id: "1",
    address: "VIA DELLE PINETE 34,56033 CAPANNOLI (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.5702913",
    lng: "10.6665787",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012163",
  },
  {
    id: "42923",
    map_id: "1",
    address: "RIVIERA DI CHIAIA 45,80121 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "40.8324875",
    lng: "14.2254591",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020477",
  },
  {
    id: "42905",
    map_id: "1",
    address: "VIA BENEDETTO CROCE 14/16,87046 MONTALTO UFFUGO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.4050736",
    lng: "16.2398263",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010203",
  },
  {
    id: "42897",
    map_id: "1",
    address: "GALLERIA STELLA 7,39100 BOLZANO-BOZEN (BZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "46.4999378",
    lng: "11.3560005",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020611",
  },
  {
    id: "42884",
    map_id: "1",
    address: "VIA DELLA QUERCIA 3,95030 NICOLOSI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6143119",
    lng: "15.0316025",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008569",
  },
  {
    id: "42877",
    map_id: "1",
    address: "CORSO UMBERTO I 6,88842 CUTRO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.0344924",
    lng: "16.9882583",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009839",
  },
  {
    id: "42871",
    map_id: "1",
    address: "VIA MONACO 26C,09045 QUARTU SANT'ELENA (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.2354111",
    lng: "9.2073972",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001919",
  },
  {
    id: "42867",
    map_id: "1",
    address: "PIAZZA UMBERTO I 14,89014 OPPIDO MAMERTINA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.2885178",
    lng: "15.9846299",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020613",
  },
  {
    id: "42862",
    map_id: "1",
    address: "VIA FRANCESCA VERGINE DEI PIN 1236,51015 MONSUMMANO TERME (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "43.855383",
    lng: "10.822518",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011844",
  },
  {
    id: "42860",
    map_id: "1",
    address: "VIALE MONZA 325,20126 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.5182465",
    lng: "9.2252001",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020332",
  },
  {
    id: "42858",
    map_id: "1",
    address: "Via Sapri, 83,20156 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.5048715",
    lng: "9.128635",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003719",
  },
  {
    id: "42804",
    map_id: "1",
    address: "VIA GIUSEPPE GARIBALDI 140,40061 MINERBIO (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.6282712",
    lng: "11.4931556",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012543",
  },
  {
    id: "42798",
    map_id: "1",
    address: "VIA ROMA 148,74014 LATERZA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.6292",
    lng: "16.7984815",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009230",
  },
  {
    id: "42792",
    map_id: "1",
    address: "VIA CABOTO 15/A,16039 SESTRI LEVANTE (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "44.2620077",
    lng: "9.4221135",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020614",
  },
  {
    id: "42783",
    map_id: "1",
    address: "VIA SALEMI ODDO 94,90018 TERMINI IMERESE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.9846988",
    lng: "13.7009537",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020571",
  },
  {
    id: "42781",
    map_id: "1",
    address: "VIA EMANUELE FILIBERTO, 7,04024 GAETA (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2131746",
    lng: "13.5718855",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009080",
  },
  {
    id: "42759",
    map_id: "1",
    address: "VIA S.GIUSEPPE LAVORATORE,81030 PARETE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9548473",
    lng: "14.1666669",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001865",
  },
  {
    id: "42756",
    map_id: "1",
    address: "VIA GIUSEPPE VERDI 18/20,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.934671",
    lng: "14.200879",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020607",
  },
  {
    id: "42707",
    map_id: "1",
    address: "VIA VENETO 3,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.4918939",
    lng: "14.0498225",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020555",
  },
  {
    id: "42671",
    map_id: "1",
    address: "VIA BATTISTI 13,25018 MONTICHIARI (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4126884",
    lng: "10.3931083",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012174",
  },
  {
    id: "42667",
    map_id: "1",
    address: "VIA COLLI DELLA SERPENTARA 41,00139 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9649966",
    lng: "12.5247433",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020565",
  },
  {
    id: "42626",
    map_id: "1",
    address: "VIA CANALE 14,80069 VICO EQUENSE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6624164",
    lng: "14.4269812",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020610",
  },
  {
    id: "42623",
    map_id: "1",
    address: "VIALE NITTA 3/M,95121 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4852391",
    lng: "15.0577126",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009400",
  },
  {
    id: "42620",
    map_id: "1",
    address: "VIA PERIPOLI 87,89030 CONDOFURI (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.9277343",
    lng: "15.8693104",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003406",
  },
  {
    id: "42617",
    map_id: "1",
    address: "VIA PASQUALE GRIMALDI, 2,81030 LUSCIANO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.963519",
    lng: "14.1900343",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020466",
  },
  {
    id: "42614",
    map_id: "1",
    address: "VIA GARCIA LORCA 25,81030 SUCCIVO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9694183",
    lng: "14.2556252",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009991",
  },
  {
    id: "42613",
    map_id: "1",
    address: "VIA LEONA 24,25018 MONTICHIARI (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.4979685",
    lng: "11.6198237",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020400",
  },
  {
    id: "42604",
    map_id: "1",
    address: "VIA SAN BIAGIO 4,97013 COMISO (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "36.9462603",
    lng: "14.604976",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009247",
  },
  {
    id: "42602",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI 44,08100 NUORO (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.3186492",
    lng: "9.3342548",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008552",
  },
  {
    id: "42598",
    map_id: "1",
    address: "VIA NICOLA FABRIZI 58,10143 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.0806867",
    lng: "7.6487101",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-013089",
  },
  {
    id: "42592",
    map_id: "1",
    address: "VIA ROMA 156,89012 DELIANUOVA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.2394946",
    lng: "15.9189206",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001441",
  },
  {
    id: "42585",
    map_id: "1",
    address: "VIA PESCIATINA 406,55100 LUCCA (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8513868",
    lng: "10.5338084",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011185",
  },
  {
    id: "42584",
    map_id: "1",
    address: "VIA DIAZ 6,50066 REGGELLO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7324649",
    lng: "11.5009701",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009621",
  },
  {
    id: "42549",
    map_id: "1",
    address: "VIA FRANCESCO SOLLIMA 52,95040 RADDUSA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.4742382",
    lng: "14.5328374",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009735",
  },
  {
    id: "42528",
    map_id: "1",
    address: "VIA F. FILZI 5/11,88046 LAMEZIA TERME (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9742435",
    lng: "16.315419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009971",
  },
  {
    id: "42525",
    map_id: "1",
    address: "VIA GIANBATTISTA IODICE 5,81051 SAN TAMMARO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.075814",
    lng: "14.2321293",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009880",
  },
  {
    id: "42513",
    map_id: "1",
    address: "VIa Strasburgo, 41/43,57023 CECINA (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.3056353",
    lng: "10.5024359",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020320",
  },
  {
    id: "42501",
    map_id: "1",
    address: "VIA FERRUCCI 154,96018 PACHINO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.7151468",
    lng: "15.0947313",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009677",
  },
  {
    id: "42486",
    map_id: "1",
    address: "VIA MAGGIORE GALLIANO 86/88,73049 RUFFANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.9879829",
    lng: "18.256085",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009904",
  },
  {
    id: "42485",
    map_id: "1",
    address: "PIAZZA SAN FRANCESCO 9,70021 ACQUAVIVA DELLE FONTI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.891732",
    lng: "16.843827",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009139",
  },
  {
    id: "42481",
    map_id: "1",
    address: "VIA NAZIONALE S. BIAGIO 254,98050 TERME VIGLIATORE (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.1281828",
    lng: "15.1418732",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008169",
  },
  {
    id: "42475",
    map_id: "1",
    address: "VIA DE PRETIS 37,95042 GRAMMICHELE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2124064",
    lng: "14.6414127",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009803",
  },
  {
    id: "42474",
    map_id: "1",
    address: "VIA SANTA MARIA SANITA' 4,73049 RUFFANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.986013",
    lng: "18.2447349",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020560",
  },
  {
    id: "42469",
    map_id: "1",
    address: "VIA CESARE BATTISTI 3,89020 MAROPATI (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.4479595",
    lng: "16.1011857",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009857",
  },
  {
    id: "42465",
    map_id: "1",
    address: "VIA APIRO 56,00137 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "41.9836485",
    lng: "12.5130397",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020582",
  },
  {
    id: "42461",
    map_id: "1",
    address: "LARGO PAPA GIOVANNI XXIII 1/5,00048 NETTUNO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.456566",
    lng: "12.6734355",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003217",
  },
  {
    id: "42460",
    map_id: "1",
    address: "VIA CALDA TERME, 12,85043 LATRONICO (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.0887338",
    lng: "15.9804918",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009702",
  },
  {
    id: "42450",
    map_id: "1",
    address: "VIA UMBERTO 202,95129 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.51226",
    lng: "15.09348",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009931",
  },
  {
    id: "42447",
    map_id: "1",
    address: "VIA GARIBALDI, 191/C,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9490526",
    lng: "14.5358735",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-002035",
  },
  {
    id: "42444",
    map_id: "1",
    address: "VIALE TRIESTE 14,81054 SAN PRISCO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0843109",
    lng: "14.2749149",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009681",
  },
  {
    id: "42440",
    map_id: "1",
    address: "VIA MARCONI, 1,86043 CASACALENDA (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.561032",
    lng: "14.6596121",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009823",
  },
  {
    id: "42428",
    map_id: "1",
    address: "VIA F. COLELLI 9/11,88046 LAMEZIA TERME (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.969003",
    lng: "16.32149",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009979",
  },
  {
    id: "42425",
    map_id: "1",
    address: "VIA ETNEA 91/A,96013 LENTINI (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2940183",
    lng: "15.0016528",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009750",
  },
  {
    id: "42413",
    map_id: "1",
    address: "VIA LEGNAGO, 7,93019 SOMMATINO (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.3343193",
    lng: "13.9917616",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009722",
  },
  {
    id: "42408",
    map_id: "1",
    address: "CORSO GARIBALDI 37,96012 AVOLA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9089086",
    lng: "15.1351015",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008253",
  },
  {
    id: "42391",
    map_id: "1",
    address: "VIA FRANCESCO CORSONELLO 5,87100 COSENZA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "39.2912344",
    lng: "16.2477373",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011779",
  },
  {
    id: "42388",
    map_id: "1",
    address: "VIA DELLE FATE 42,95035 MALETTO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.8290523",
    lng: "14.8679515",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009883",
  },
  {
    id: "42385",
    map_id: "1",
    address: "VIA BRIGATA SASSARI 3/5,07041 ALGHERO (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.5603695",
    lng: "8.3197578",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020554",
  },
  {
    id: "42383",
    map_id: "1",
    address: "VIA MICHELE LA SPINA 44/46,95024 ACIREALE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.617762",
    lng: "15.160051",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012264",
  },
  {
    id: "42381",
    map_id: "1",
    address: "VIA ROMA 42,80078 POZZUOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8267795",
    lng: "14.1196572",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020493",
  },
  {
    id: "42380",
    map_id: "1",
    address: "VIA VOGHERA 20,27039 SANNAZZARO DE' BURGONDI (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "45.0958133",
    lng: "8.9072755",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020487",
  },
  {
    id: "42377",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI, 8,97015 MODICA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.8436736",
    lng: "14.7661779",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009787",
  },
  {
    id: "42376",
    map_id: "1",
    address: "VIA VENEZIA 424,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0798973",
    lng: "14.2347116",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009265",
  },
  {
    id: "42375",
    map_id: "1",
    address: "VIA ROMA 187,85050 TITO (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.36795",
    lng: "17.1243661",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009757",
  },
  {
    id: "42373",
    map_id: "1",
    address: "CORSO UMBERTO I, 35,90044 CARINI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.1325355",
    lng: "13.181783",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009724",
  },
  {
    id: "42372",
    map_id: "1",
    address: "VIA G. GALILEI 23/25,87036 RENDE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.3521694",
    lng: "16.2426997",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008730",
  },
  {
    id: "42370",
    map_id: "1",
    address: "PIAZZA GARIBALDI 5,20096 PIOLTELLO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4872054",
    lng: "9.3296495",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009899",
  },
  {
    id: "42368",
    map_id: "1",
    address: "VIA DELL'EPOMEO 520,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8475429",
    lng: "14.1917578",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009948",
  },
  {
    id: "42362",
    map_id: "1",
    address: "VIA FILIPPO CORRIDONI 1/B,95129 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5099",
    lng: "15.0873",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004081",
  },
  {
    id: "42361",
    map_id: "1",
    address: "VIA VECCHIA LAVORATE 169,84087 SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.7909259",
    lng: "14.6484733",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010177",
  },
  {
    id: "42351",
    map_id: "1",
    address: "VIA MARCONI 176,92010 SICULIANA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3353423",
    lng: "13.4217556",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009938",
  },
  {
    id: "42347",
    map_id: "1",
    address: "VIA XX SETTEMBRE 70,70010 TURI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9157272",
    lng: "17.0174914",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009778",
  },
  {
    id: "42345",
    map_id: "1",
    address: "VIA NAZIONALE 260/A,96100 SIRACUSA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9767451",
    lng: "15.1977873",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009730",
  },
  {
    id: "42340",
    map_id: "1",
    address: "VIA MADONNA DEL CALLE 50,04015 PRIVERNO (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.4706578",
    lng: "13.1853775",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008588",
  },
  {
    id: "42337",
    map_id: "1",
    address: "VIA CIALDINI 81,75018 STIGLIANO (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID - ENEL ENERGIA",
    lat: "40.4027748",
    lng: "16.2273241",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003606",
  },
  {
    id: "42335",
    map_id: "1",
    address: "VIA TORINO 168/C,10070 SAN FRANCESCO AL CAMPO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.2392648",
    lng: "7.6537223",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020401",
  },
  {
    id: "42333",
    map_id: "1",
    address: "VIA A. DE GASPERI, 23,80024 CARDITO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9472064",
    lng: "14.2946502",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009813",
  },
  {
    id: "42330",
    map_id: "1",
    address: "VIA TORDIGLIONE 120,83032 BONITO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "41.0997803",
    lng: "15.0022485",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009579",
  },
  {
    id: "42327",
    map_id: "1",
    address: "Via Mazzini, 17/a,51010 MASSA E COZZILE (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8660796",
    lng: "10.7576604",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011924",
  },
  {
    id: "42323",
    map_id: "1",
    address: "VIA GIAMBATTISTA PERGOLESI 74,10154 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.0963247",
    lng: "7.7050661",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008576",
  },
  {
    id: "42316",
    map_id: "1",
    address: "VIA CARDINALE DUSMET 16,95030 NICOLOSI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6130044",
    lng: "15.0260865",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009830",
  },
  {
    id: "42314",
    map_id: "1",
    address:
      "STRADA PROVINCIALE BARI-MODUGNO, KM 8 INTERNO 4,70026 MODUGNO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0948007",
    lng: "16.8004766",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009829",
  },
  {
    id: "42310",
    map_id: "1",
    address: "VIALE EUROPA 64,98030 MOIO ALCANTARA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.9007467",
    lng: "15.0524726",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009736",
  },
  {
    id: "42305",
    map_id: "1",
    address: "Via Conte di Torino 54,93016 RIESI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2818222",
    lng: "14.0830612",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-002031",
  },
  {
    id: "42293",
    map_id: "1",
    address: "VIA DEL LAVORO 2/C,31015 CONEGLIANO (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.8785754",
    lng: "12.3041983",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009992",
  },
  {
    id: "42292",
    map_id: "1",
    address: "VIA MADONNELLE,80147 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.871572",
    lng: "14.3305419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009769",
  },
  {
    id: "42289",
    map_id: "1",
    address: "VIA TONIOLO, 40,95040 MIRABELLA IMBACCARI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.3216079",
    lng: "14.4428085",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009893",
  },
  {
    id: "42284",
    map_id: "1",
    address: "VIA PORTA NAPOLI 92,67031 CASTEL DI SANGRO (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.7805329",
    lng: "14.1062864",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003308",
  },
  {
    id: "42280",
    map_id: "1",
    address: "VIA D'ANNA 3,81030 SANT'ARPINO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9577783",
    lng: "14.2528202",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003813",
  },
  {
    id: "42275",
    map_id: "1",
    address: "VIA ROSSI 132,80040 VOLLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8732636",
    lng: "14.3440158",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003827",
  },
  {
    id: "42271",
    map_id: "1",
    address: "VIA CAMPO DEI FIORI 16,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5746262",
    lng: "14.9103016",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004021",
  },
  {
    id: "42269",
    map_id: "1",
    address: "VIA DARIO FIORE, 78,80021 AFRAGOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9254607",
    lng: "14.3024462",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001850",
  },
  {
    id: "42266",
    map_id: "1",
    address: "Via quattro caselli n. 5/a -Fraz.,75020 PISTICCI (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.3611595",
    lng: "16.6875543",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003610",
  },
  {
    id: "42262",
    map_id: "1",
    address: "VIA TERRACINA 133,80125 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8254147",
    lng: "14.1798764",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009233",
  },
  {
    id: "42259",
    map_id: "1",
    address: "V.LE ITALIA 131,65010 COLLECORVINO (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.4434809",
    lng: "14.0458278",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008499",
  },
  {
    id: "42234",
    map_id: "1",
    address: "CORSO UMBERTO I 140,89822 SERRA SAN BRUNO (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.5758504",
    lng: "16.3292071",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001455",
  },
  {
    id: "42227",
    map_id: "1",
    address: "VIA AGNONE 40,96016 LENTINI (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.2884239",
    lng: "14.9994754",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004062",
  },
  {
    id: "42222",
    map_id: "1",
    address: "CORSO SICILIA 3,95039 TRECASTAGNI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6164531",
    lng: "15.0800085",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009809",
  },
  {
    id: "42220",
    map_id: "1",
    address: "VIA OSTIA 7,87064 CORIGLIANO-ROSSANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.6533839",
    lng: "16.5380641",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020663",
  },
  {
    id: "42218",
    map_id: "1",
    address: "VIA ROMA 118,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.952726",
    lng: "14.5321665",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006038",
  },
  {
    id: "42217",
    map_id: "1",
    address: "VIA ANTONIO TEMPESTA 232,00176 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8855919",
    lng: "12.5406035",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009276",
  },
  {
    id: "42207",
    map_id: "1",
    address: "P.ZZA GIOVANNI PAOLO II 4,84090 GIFFONI SEI CASALI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7193269",
    lng: "14.9038885",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009984",
  },
  {
    id: "42201",
    map_id: "1",
    address: "VIA LUCANIA, 53,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5602632",
    lng: "14.9117994",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008388",
  },
  {
    id: "42199",
    map_id: "1",
    address: "VIA ANTONIO GRAMSCI 118,89020 MELICUCCO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.430714",
    lng: "16.0627768",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003420",
  },
  {
    id: "42192",
    map_id: "1",
    address: "VIA MASOTTO 11,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.660173",
    lng: "14.838724",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009364",
  },
  {
    id: "42187",
    map_id: "1",
    address: "VIA LUIGI ROSSETTI 17,10073 CIRIE' (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.22713",
    lng: "7.59989",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010156",
  },
  {
    id: "42186",
    map_id: "1",
    address: "VIA SALVO D'ACQUISTO, 42,81030 PARETE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9658503",
    lng: "14.1680531",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020441",
  },
  {
    id: "42183",
    map_id: "1",
    address: "VIA SARDEGNA, 13,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5675033",
    lng: "14.9116236",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009835",
  },
  {
    id: "42176",
    map_id: "1",
    address: "Via N.Bixio n.145,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9506534",
    lng: "14.5355144",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009533",
  },
  {
    id: "42172",
    map_id: "1",
    address: "VIA CASA CUOMO PIAZZA 27,80050 PIMONTE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6732758",
    lng: "14.5119318",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003880",
  },
  {
    id: "42163",
    map_id: "1",
    address: "VIA LIBERTA 48/52,20092 CINISELLO BALSAMO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5551085",
    lng: "9.215718",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008616",
  },
  {
    id: "42161",
    map_id: "1",
    address: "VIA SAN GIOVANNI BATTISTA 110-112,95123 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5498358",
    lng: "15.0515552",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009207",
  },
  {
    id: "42159",
    map_id: "1",
    address: "VIALE UNITA' D'ITALIA 18,70020 CASSANO DELLE MURGE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8913499",
    lng: "16.7668907",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009919",
  },
  {
    id: "42158",
    map_id: "1",
    address: "VIA PETRARIZZO 4,88838 MESORACA (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.0817369",
    lng: "16.7870273",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000920",
  },
  {
    id: "42153",
    map_id: "1",
    address: "VIA DI TORREVECCHIA 128,00168 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9335925",
    lng: "12.4192839",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020543",
  },
  {
    id: "42151",
    map_id: "1",
    address: "VIA FINOCCHIARO APRILE 35,90025 LERCARA FRIDDI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.7480376",
    lng: "13.6021561",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009197",
  },
  {
    id: "42149",
    map_id: "1",
    address: "VIA SOTTOTENENTE ARENA 6,90039 VILLABATE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0777501",
    lng: "13.4416037",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009200",
  },
  {
    id: "42146",
    map_id: "1",
    address: "VIA G. MATTEOTTI 222,95045 MISTERBIANCO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5200468",
    lng: "15.0074413",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004026",
  },
  {
    id: "42137",
    map_id: "1",
    address: "VIA PABLO PICASSO 44,50068 RUFINA (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8176615",
    lng: "11.4842048",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011135",
  },
  {
    id: "42136",
    map_id: "1",
    address: "VIA VITTORIO VENETO, 95,89823 FABRIZIA (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.4845698",
    lng: "16.2999399",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009462",
  },
  {
    id: "42134",
    map_id: "1",
    address: "VIA TAUREANA 29,89028 SEMINARA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.3351554",
    lng: "15.8705227",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020570",
  },
  {
    id: "42131",
    map_id: "1",
    address: "VIA SILVIO PATERNOSTRO,43/45,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.0793201",
    lng: "17.127402",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009838",
  },
  {
    id: "42130",
    map_id: "1",
    address: "VIA ACQUABONA 2,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "39.0836802",
    lng: "17.1179946",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020540",
  },
  {
    id: "42128",
    map_id: "1",
    address: "VIA PRINCIPESSA DI PIEMONTE SNC,95048 SCORDIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2907532",
    lng: "14.8382356",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008511",
  },
  {
    id: "42124",
    map_id: "1",
    address: "VIA CAVOUR, 62,90048 SAN GIUSEPPE JATO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.9715681",
    lng: "13.1860795",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009303",
  },
  {
    id: "42120",
    map_id: "1",
    address: "P.ZZA CRISSA, 8,89821 SAN NICOLA DA CRISSA (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.663389",
    lng: "16.284624",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009481",
  },
  {
    id: "42119",
    map_id: "1",
    address: "VIA LEONARDO MAZZELLA LOC. FONDOBOSSO SNC,80077 ISCHIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7276942",
    lng: "13.9467662",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008519",
  },
  {
    id: "42118",
    map_id: "1",
    address: "ETTORE MAJORANA 14,73010 GUAGNANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3974745",
    lng: "17.9484401",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008482",
  },
  {
    id: "42113",
    map_id: "1",
    address: "PIAZZA EUROPA, 1,85046 MARATEA (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.9955675",
    lng: "15.7216853",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009092",
  },
  {
    id: "42110",
    map_id: "1",
    address: "VIA MOLISE 1/3,86021 BOJANO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.4860778",
    lng: "14.4769797",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009734",
  },
  {
    id: "42096",
    map_id: "1",
    address: "VIA GAETANO DE PASQUALI 6C,92027 LICATA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1056786",
    lng: "13.9365656",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012787",
  },
  {
    id: "42095",
    map_id: "1",
    address: "LARGO A. BOITO 9/10,00052 CERVETERI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.9573209",
    lng: "12.1592626",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020536",
  },
  {
    id: "42093",
    map_id: "1",
    address: "VIA S. ANGELO 123,72100 BRINDISI (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6219654",
    lng: "17.9325987",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009923",
  },
  {
    id: "42087",
    map_id: "1",
    address: "VIA FERRARA 2,80143 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8555074",
    lng: "14.2714388",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008518",
  },
  {
    id: "42083",
    map_id: "1",
    address: "VIA SAN VALENTINO 25,84084 SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8068605",
    lng: "14.6161921",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020542",
  },
  {
    id: "42082",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 135,74020 SAN MARZANO DI SAN GIUSEPPE (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.4520899",
    lng: "17.5082943",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020499",
  },
  {
    id: "42081",
    map_id: "1",
    address: "VIA PISTOIESE 128 AB,50013 CAMPI BISENZIO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.796043",
    lng: "11.149067",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008535",
  },
  {
    id: "42079",
    map_id: "1",
    address: "VIA EUROPA 44/46,80040 CERCOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8505447",
    lng: "14.3557866",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003817",
  },
  {
    id: "42076",
    map_id: "1",
    address: "VIA DELLA LIBERTA' 11,66026 ORTONA (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.35151",
    lng: "14.40242",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009952",
  },
  {
    id: "42074",
    map_id: "1",
    address: "VIA MARCONI 28,28845 DOMODOSSOLA (VB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "46.1164273",
    lng: "8.2928856",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009449",
  },
  {
    id: "42072",
    map_id: "1",
    address: "VIA NICOLA SALA 6,82100 BENEVENTO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.126852",
    lng: "14.7910131",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009236",
  },
  {
    id: "42070",
    map_id: "1",
    address: "Via Roma,66,80040 VOLLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.875458",
    lng: "14.338443",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020471",
  },
  {
    id: "42067",
    map_id: "1",
    address: "VIA MAZZINI 1,94019 VALGUARNERA CAROPEPE (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.4968111",
    lng: "14.3902306",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004012",
  },
  {
    id: "42066",
    map_id: "1",
    address: "VIA GIUSEPPE GARIBALDI, 52,04010 SABAUDIA (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2971061",
    lng: "13.0366535",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012483",
  },
  {
    id: "42062",
    map_id: "1",
    address: "VIA ANNUNZIATA 25/27,04021 CASTELFORTE (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.3012155",
    lng: "13.8185504",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008489",
  },
  {
    id: "42059",
    map_id: "1",
    address: "VIA GIULIO ROMANO 6/B,46100 MANTOVA (MN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.1531651",
    lng: "10.7898806",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020465",
  },
  {
    id: "42058",
    map_id: "1",
    address: "PIAZZA E. DE NICOLA 3,80058 TORRE ANNUNZIATA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7587466",
    lng: "14.4437059",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009451",
  },
  {
    id: "42055",
    map_id: "1",
    address: "LOC IL PIANO SNC,57038 RIO NELL'ELBA (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.81333",
    lng: "10.41429",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008492",
  },
  {
    id: "42051",
    map_id: "1",
    address: "VIA LUIGI ROCCO 82,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9127447",
    lng: "14.2726233",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009102",
  },
  {
    id: "42050",
    map_id: "1",
    address: "VIA C.COLOMBO 73,88100 CATANZARO (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.8187628",
    lng: "16.6117697",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004107",
  },
  {
    id: "42049",
    map_id: "1",
    address: "PIAZZA GARIBALDI SNC,74121 TARANTO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.4727995",
    lng: "17.2378385",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020529",
  },
  {
    id: "42045",
    map_id: "1",
    address: "VIA SAN SEBASTIANO 9/B,00067 MORLUPO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.1486372",
    lng: "12.5047517",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009446",
  },
  {
    id: "42040",
    map_id: "1",
    address: "VIA CASSINO 10,89131 REGGIO CALABRIA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0847384",
    lng: "15.6401341",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003416",
  },
  {
    id: "42038",
    map_id: "1",
    address: "VIA SILVIO PELLICO,59,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.570953",
    lng: "14.905145",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009637",
  },
  {
    id: "42024",
    map_id: "1",
    address: "BIVIO SS 118 BOLOGNETTA,90030 BOLOGNETTA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.9699957",
    lng: "13.4542142",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009658",
  },
  {
    id: "42023",
    map_id: "1",
    address: "VIA SANTA CATERINA 6,17019 VARAZZE (SV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.359534",
    lng: "8.5790402",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020510",
  },
  {
    id: "42019",
    map_id: "1",
    address: "VIA DELLE SOLE SNC,82021 APICE (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "41.1178684",
    lng: "14.9147721",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-008510",
  },
  {
    id: "42018",
    map_id: "1",
    address: "VIA ISONZO 10,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0676507",
    lng: "14.3289287",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020515",
  },
  {
    id: "42008",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI 6,01027 MONTEFIASCONE (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "42.5407472",
    lng: "12.0330418",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020541",
  },
  {
    id: "42007",
    map_id: "1",
    address: "VIA MARCONI 1,10034 CHIVASSO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.1876826",
    lng: "7.888111",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009912",
  },
  {
    id: "42006",
    map_id: "1",
    address: "VIA PESSINA 15,20018 SEDRIANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4896871",
    lng: "8.9667605",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005770",
  },
  {
    id: "42003",
    map_id: "1",
    address: "VIALE ITALIA 3,89018 VILLA SAN GIOVANNI (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.2171087",
    lng: "15.6394886",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012770",
  },
  {
    id: "42002",
    map_id: "1",
    address: "VIA STATILIO OTTATO 22,00175 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8549809",
    lng: "12.5668316",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003232",
  },
  {
    id: "41993",
    map_id: "1",
    address: "CORSO GIOVANNI SPANO 109,07017 PLOAGHE (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6644923",
    lng: "8.746708",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020464",
  },
  {
    id: "41992",
    map_id: "1",
    address: "VIA RENATO FUCINI 4/A,00137 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9532468",
    lng: "12.5442596",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020455",
  },
  {
    id: "41987",
    map_id: "1",
    address: "VIA BENEDETTO VARCHI 31/1,52100 AREZZO (AR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.4670313",
    lng: "11.8747313",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020534",
  },
  {
    id: "41985",
    map_id: "1",
    address: "VIA UMBERTO I 76,07014 OZIERI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.586565",
    lng: "9.0015939",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020535",
  },
  {
    id: "41984",
    map_id: "1",
    address: "VIA MONSIGNORE BARONE 2,84085 MERCATO SAN SEVERINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7672464",
    lng: "14.7480424",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020533",
  },
  {
    id: "41979",
    map_id: "1",
    address: "VIALE DEI PLATANI 10,00019 TIVOLI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.9729056",
    lng: "12.8312742",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011753",
  },
  {
    id: "41977",
    map_id: "1",
    address: "PIAZZA INDIPENDENZA 28,20015 PARABIAGO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "45.53919",
    lng: "8.95278",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011065",
  },
  {
    id: "41975",
    map_id: "1",
    address: "VIA CAVALLOTTI 139,20099 SESTO SAN GIOVANNI (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5308035",
    lng: "9.2358165",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008587",
  },
  {
    id: "41974",
    map_id: "1",
    address: "VIA DEL PRATO 5/A,60035 JESI (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.5188982",
    lng: "13.2454072",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008709",
  },
  {
    id: "41972",
    map_id: "1",
    address: "VIA CASTELLAMONTE 31/C,10010 BANCHETTE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.4525482",
    lng: "7.8576606",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010289",
  },
  {
    id: "41971",
    map_id: "1",
    address: "VIA GASPARA STAMPA 67,00137 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9467316",
    lng: "12.5594294",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012056",
  },
  {
    id: "41970",
    map_id: "1",
    address: "VIA CIANO 2M,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4686508",
    lng: "15.542819",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020528",
  },
  {
    id: "41968",
    map_id: "1",
    address: "VIA CESARE BATTISTI FR. TORNIELLA 5.9,58036 ROCCASTRADA (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.0739811",
    lng: "11.153669",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008490",
  },
  {
    id: "41964",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 70,85020 SAN FELE (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8182643",
    lng: "15.545104",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009937",
  },
  {
    id: "41962",
    map_id: "1",
    address: "VIA DELLA FILOSOFIA 1,91026 MAZARA DEL VALLO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6499239",
    lng: "12.6086014",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009550",
  },
  {
    id: "41952",
    map_id: "1",
    address: "VIA REGINA ELENA 8,95040 MOTTA SANT'ANASTASIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.512323",
    lng: "14.9675886",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004067",
  },
  {
    id: "41942",
    map_id: "1",
    address: "VIA SIMONE MARTINI 63,80128 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8504033",
    lng: "14.2230807",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008456",
  },
  {
    id: "41941",
    map_id: "1",
    address: "VIA O. BATTISTELLA 18,31040 NERVESA DELLA BATTAGLIA (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "45.8257788",
    lng: "12.2101729",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008469",
  },
  {
    id: "41935",
    map_id: "1",
    address: "VIA DELLA REPUBBLICA 6,08013 BOSA (OR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.2967191",
    lng: "8.4945611",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005662",
  },
  {
    id: "41934",
    map_id: "1",
    address: "VIA MILANO 42,92014 PORTO EMPEDOCLE (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.2915226",
    lng: "13.5262721",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020437",
  },
  {
    id: "41929",
    map_id: "1",
    address: "VIA GENERALE DIAZ 33,84082 BRACIGLIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8151011",
    lng: "14.7165372",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003850",
  },
  {
    id: "41927",
    map_id: "1",
    address: "VIA CARRERA FONDA 46,89013 GIOIA TAURO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.4304126",
    lng: "15.8877024",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020411",
  },
  {
    id: "41925",
    map_id: "1",
    address: "VIA CARLO CRAVERO 59,10072 CASELLE TORINESE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID - ENEL ENERGIA",
    lat: "45.1790634",
    lng: "7.6462931",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010991",
  },
  {
    id: "41921",
    map_id: "1",
    address: "CORSO ROMA 195,94010 CERAMI (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.8091625",
    lng: "14.5097783",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020512",
  },
  {
    id: "41919",
    map_id: "1",
    address: "VIA CAVALLEGGERI D'AOSTA 123,80121 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8149802",
    lng: "14.1882028",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020522",
  },
  {
    id: "41918",
    map_id: "1",
    address: "VIA GIUSEPPE VERDI 58,10036 SETTIMO TORINESE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.1431294",
    lng: "7.7762493",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011625",
  },
  {
    id: "41917",
    map_id: "1",
    address: "CORSO NOVARA 71,27029 VIGEVANO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.3232363",
    lng: "8.8453739",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020503",
  },
  {
    id: "41915",
    map_id: "1",
    address: "CORSO GIUSEPPE GARIBALDI, 95,86170 ISERNIA (IS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.5960274",
    lng: "14.2328495",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003705",
  },
  {
    id: "41913",
    map_id: "1",
    address: "VIA DELLA LIBERAZIONE 1,54026 MULAZZO (MS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.3215687",
    lng: "9.9059213",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020514",
  },
  {
    id: "41910",
    map_id: "1",
    address: "VIA SANTA LUCIA 3,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.5710473",
    lng: "14.8995797",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009666",
  },
  {
    id: "41900",
    map_id: "1",
    address: "VIA DELL'EPOMEO 482,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8472838",
    lng: "14.1926792",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001888",
  },
  {
    id: "41899",
    map_id: "1",
    address: "VIA NAZIONALE 93,89812 PIZZO (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.7365408",
    lng: "16.1721921",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001438",
  },
  {
    id: "41897",
    map_id: "1",
    address: "VIA MANZONI 92,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.07137",
    lng: "14.233707",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004030",
  },
  {
    id: "41895",
    map_id: "1",
    address: "VIA LUIGI EINAUDI, 28,10051 AVIGLIANA (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.0837982",
    lng: "7.3968165",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000126",
  },
  {
    id: "41893",
    map_id: "1",
    address: "VIA CALABRIA 10,74100 TARANTO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.4619385",
    lng: "17.2570681",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009012",
  },
  {
    id: "41891",
    map_id: "1",
    address: "VIA MILANO 90/F,25126 BRESCIA (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.545252",
    lng: "10.1953822",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020391",
  },
  {
    id: "41890",
    map_id: "1",
    address: "VIA BELVEDERE 25,80036 PALMA CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8684642",
    lng: "14.5556355",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020281",
  },
  {
    id: "41889",
    map_id: "1",
    address: "VIA SANTA MARINA 223,89024 POLISTENA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.4032561",
    lng: "16.0711331",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003403",
  },
  {
    id: "41886",
    map_id: "1",
    address: "VIA PIAVE 2,94015 PIAZZA ARMERINA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3888939",
    lng: "14.3713273",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020539",
  },
  {
    id: "41880",
    map_id: "1",
    address: "VIA ROMA 226,81021 ARIENZO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.0220865",
    lng: "14.4957395",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020513",
  },
  {
    id: "41875",
    map_id: "1",
    address: "VIA ALFONSO ARTIACO 4,80078 POZZUOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.831106",
    lng: "14.1190309",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001886",
  },
  {
    id: "41871",
    map_id: "1",
    address: "VIA ROMA 14,92017 SAMBUCA DI SICILIA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.6480352",
    lng: "13.1110033",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004080",
  },
  {
    id: "41868",
    map_id: "1",
    address: "VIA ADA NEGRI 5/A,00013 FONTE NUOVA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9951206",
    lng: "12.6188628",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020474",
  },
  {
    id: "41865",
    map_id: "1",
    address: "SS SINNICA KM. 5,85044 LAURIA (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.0746824",
    lng: "15.940865",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009552",
  },
  {
    id: "41862",
    map_id: "1",
    address: "VIALE DELLA LIBERTA' 154,73100 LECCE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.358899",
    lng: "18.1918348",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009425",
  },
  {
    id: "41859",
    map_id: "1",
    address: "VIA PER FROSINONE 127/A,03023 CECCANO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.5836521",
    lng: "13.3301457",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008711",
  },
  {
    id: "41857",
    map_id: "1",
    address: "VIA NAZIONALE APPIA 300,81020 CASAPULLA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0739874",
    lng: "14.2885374",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003815",
  },
  {
    id: "41855",
    map_id: "1",
    address: "VIA MUSUMECI 63,95129 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5107605",
    lng: "15.090711",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008691",
  },
  {
    id: "40852",
    map_id: "1",
    address: "CORSO EUROPA 147B,80016 MARANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8982159",
    lng: "14.2006545",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020489",
  },
  {
    id: "40849",
    map_id: "1",
    address: "VIA GIOVANNI DIACONO, 69,80144 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8887566",
    lng: "14.2733549",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003881",
  },
  {
    id: "40840",
    map_id: "1",
    address: "VIA MASI 167,44124 FERRARA (FE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "44.7329653",
    lng: "11.6378612",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020519",
  },
  {
    id: "40839",
    map_id: "1",
    address: "VIA GUGLIELMO MARCONI 11 C/R,50131 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7840361",
    lng: "11.2813819",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010298",
  },
  {
    id: "40837",
    map_id: "1",
    address: "VIA LEMURA 3,88025 MAIDA (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "38.8590163",
    lng: "16.3627436",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008405",
  },
  {
    id: "40834",
    map_id: "1",
    address: "Via Roma, 177,87055 SAN GIOVANNI IN FIORE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.2538905",
    lng: "16.6953744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001407",
  },
  {
    id: "40833",
    map_id: "1",
    address: "VIA GIUSEPPE DI VAGNO 37,71018 VICO DEL GARGANO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID - ENEL ENERGIA",
    lat: "41.8966267",
    lng: "15.9586388",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020452",
  },
  {
    id: "40831",
    map_id: "1",
    address: "VIA STEFANO CORSI 30,80039 SAVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.910816",
    lng: "14.5108118",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001871",
  },
  {
    id: "40829",
    map_id: "1",
    address: "VIA TERRAVECCHIA INFERIORE 60/62,89900 VIBO VALENTIA (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.6770826",
    lng: "16.1032004",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008681",
  },
  {
    id: "40828",
    map_id: "1",
    address: "PIAZZA G. DI VITTORIO 21,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6693162",
    lng: "14.8352369",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008556",
  },
  {
    id: "40827",
    map_id: "1",
    address: "VIA ALFONSO D ARAGONA 37,81031 AVERSA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9788963",
    lng: "14.2067461",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001836",
  },
  {
    id: "40826",
    map_id: "1",
    address: "CORSO MATTEOTTI 70,81030 SAN MARCELLINO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9918213",
    lng: "14.1812416",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001873",
  },
  {
    id: "40819",
    map_id: "1",
    address: "VIA TRIESTE 31,84085 MERCATO SAN SEVERINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.7835608",
    lng: "14.7554236",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008521",
  },
  {
    id: "40817",
    map_id: "1",
    address: "VIA VERDI 2/A,24040 VERDELLINO (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.6042263",
    lng: "9.6153072",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020508",
  },
  {
    id: "40816",
    map_id: "1",
    address: "VIA PUGLIA 18B,75025 POLICORO (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.2065046",
    lng: "16.6704522",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020507",
  },
  {
    id: "40815",
    map_id: "1",
    address: "VIA LIBERTA' 20,20866 CARNATE (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.654188",
    lng: "9.373376",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020511",
  },
  {
    id: "40813",
    map_id: "1",
    address: "VIA A. CADAMOSTO 32/C,41012 CARPI (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.7760228",
    lng: "10.8726866",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008676",
  },
  {
    id: "40806",
    map_id: "1",
    address: "VIA NAZIONALE 216,88060 MONTEPAONE (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.7273634",
    lng: "16.5403378",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009293",
  },
  {
    id: "40804",
    map_id: "1",
    address: "VIA ARENACCIA 284/A,80141 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8675602",
    lng: "14.2686744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001869",
  },
  {
    id: "40802",
    map_id: "1",
    address: "VIA PIETRO MAFFI 95,00168 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9254688",
    lng: "12.4193963",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008467",
  },
  {
    id: "40799",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI 55,65012 CEPAGATTI (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.3663237",
    lng: "14.0776199",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009468",
  },
  {
    id: "40795",
    map_id: "1",
    address: "VIA PIANO DELLA FIERA SNC,87010 SAN SOSTI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.6547503",
    lng: "16.034924",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008465",
  },
  {
    id: "40790",
    map_id: "1",
    address: "VIA SAN LEONARDO 10,80037 SAN GIUSEPPE VESUVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8419831",
    lng: "14.4911852",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009473",
  },
  {
    id: "40785",
    map_id: "1",
    address: "VIA MONTEMARCONE 155/4,66041 ATESSA (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.1349008",
    lng: "14.4307105",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001329",
  },
  {
    id: "40773",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 10,80038 POMIGLIANO D'ARCO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9072454",
    lng: "14.3922693",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001838",
  },
  {
    id: "40772",
    map_id: "1",
    address: "CORSO ITALIA 36,89022 CITTANOVA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.3532338",
    lng: "16.0820142",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001401",
  },
  {
    id: "40769",
    map_id: "1",
    address: "Via Roma 28,24040 BONATE SOPRA (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.6843441",
    lng: "9.5575374",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000226",
  },
  {
    id: "40758",
    map_id: "1",
    address: "VIA G. CASCINO 29/F,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "36.9564211",
    lng: "14.5265005",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020421",
  },
  {
    id: "40756",
    map_id: "1",
    address: "PZA REGINA MARGHERITA 8,82020 REINO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2912795",
    lng: "14.8248731",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007525",
  },
  {
    id: "40755",
    map_id: "1",
    address: "VIA MATTEO RIPA 21,84025 EBOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6179959",
    lng: "15.0556423",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006543",
  },
  {
    id: "40754",
    map_id: "1",
    address: "PIAZZA VITTORIO EMANUELE , 12-14,81033 CASAL DI PRINCIPE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE - ENEL ENERGIA",
    lat: "41.0065176",
    lng: "14.1314365",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012190",
  },
  {
    id: "40753",
    map_id: "1",
    address: "VIA SAN CARLO BORROMEO 7,24040 LEVATE (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6235663",
    lng: "9.6255556",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020473",
  },
  {
    id: "40749",
    map_id: "1",
    address: "VIA RAMMACCA 75,90011 BAGHERIA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0891668",
    lng: "13.5036379",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020482",
  },
  {
    id: "40747",
    map_id: "1",
    address: "VIA GIARDINELLO 41,88050 SELLIA MARINA (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9063745",
    lng: "16.741688",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020081",
  },
  {
    id: "40743",
    map_id: "1",
    address: "VIA MATTEOTTI 1/3,87062 CARIATI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "39.4971835",
    lng: "16.9538752",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020494",
  },
  {
    id: "40741",
    map_id: "1",
    address: "VIA MONSIGNOR GORI, 44,01036 NEPI (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "42.247386",
    lng: "12.3448311",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020498",
  },
  {
    id: "40740",
    map_id: "1",
    address: "VIA FABIO FILZI 39,59100 PRATO (PO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.8858012",
    lng: "11.0881089",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020084",
  },
  {
    id: "40736",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 30,95038 SANTA MARIA DI LICODIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6131653",
    lng: "14.8963927",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009333",
  },
  {
    id: "40735",
    map_id: "1",
    address: "VIA BRODOLOINI 12C,09040 SENORBI' (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.5379476",
    lng: "9.1318229",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006394",
  },
  {
    id: "40734",
    map_id: "1",
    address: "PIAZZA VITORIO EMANUELE II, 5/6,86079 VENAFRO (IS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.4843965",
    lng: "14.0436626",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009825",
  },
  {
    id: "40730",
    map_id: "1",
    address: "VIALE GIULIO CESARE , 25,86035 LARINO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8051448",
    lng: "14.9190096",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003703",
  },
  {
    id: "40729",
    map_id: "1",
    address: "VIA NAPOLI 123,80018 MUGNANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9035149",
    lng: "14.2106943",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008539",
  },
  {
    id: "40727",
    map_id: "1",
    address: "VIA G. LIPPIELLO 35,83022 BAIANO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9543521",
    lng: "14.6122841",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003848",
  },
  {
    id: "40724",
    map_id: "1",
    address: "VIA DELL'UMANITA' 30,82021 APICE (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1215866",
    lng: "14.9103166",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003853",
  },
  {
    id: "40720",
    map_id: "1",
    address: "Corso Ho chi min, 48,97013 COMISO (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9538852",
    lng: "14.6031547",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020424",
  },
  {
    id: "40719",
    map_id: "1",
    address: "VIA ROMA 66,80040 POGGIOMARINO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8003845",
    lng: "14.5415554",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020470",
  },
  {
    id: "40718",
    map_id: "1",
    address: "Via Paterno', 92,95030 RAGALNA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6253355",
    lng: "14.9427209",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009994",
  },
  {
    id: "40717",
    map_id: "1",
    address: "Via Barbara Forleo, 8/10,72021 FRANCAVILLA FONTANA (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5333251",
    lng: "17.5832733",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006733",
  },
  {
    id: "40716",
    map_id: "1",
    address: "VIA DELLA BIBLIOTECA 4,43011 BUSSETO (PR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.9796158",
    lng: "10.0411836",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011747",
  },
  {
    id: "40714",
    map_id: "1",
    address: "PIAZZA ENZO FIORITTO 10/A,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.4675148",
    lng: "15.544686",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012529",
  },
  {
    id: "40705",
    map_id: "1",
    address: "VIA TINTORETTO 41,63066 GROTTAMMARE (AP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.9832435",
    lng: "13.8705869",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009453",
  },
  {
    id: "40703",
    map_id: "1",
    address: "CORSO UMBERTO I, 131-133,81034 MONDRAGONE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1170052",
    lng: "13.8970569",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001846",
  },
  {
    id: "40702",
    map_id: "1",
    address: "VIA FRANCESCO COSSIGA 32,07020 BUDDUSO' (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5770019",
    lng: "8.7255981",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020028",
  },
  {
    id: "40698",
    map_id: "1",
    address: "VIA DELLA CROCE 8,01027 MONTEFIASCONE (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "42.5397004",
    lng: "12.031704",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020461",
  },
  {
    id: "40697",
    map_id: "1",
    address: "VIA XX SETTEMBRE 46,70013 CASTELLANA GROTTE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8859323",
    lng: "17.1668199",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006609",
  },
  {
    id: "40694",
    map_id: "1",
    address: "VIA FABIO 9,95024 ACIREALE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.617443",
    lng: "15.165302",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011353",
  },
  {
    id: "40693",
    map_id: "1",
    address: "VIALE SANTE BIASUZZI 28/31,31038 PAESE (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "45.6716493",
    lng: "12.1759368",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020497",
  },
  {
    id: "40690",
    map_id: "1",
    address: "CORSO MANZONI 94,27100 PAVIA (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.1874337",
    lng: "9.1433993",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020442",
  },
  {
    id: "40689",
    map_id: "1",
    address: "VIA VITTORIO VENETO 130,93010 VILLALBA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6553617",
    lng: "13.8442126",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009927",
  },
  {
    id: "40683",
    map_id: "1",
    address: "VIA G. GARIBALDI 95/97,60035 JESI (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "43.5276414",
    lng: "13.2489031",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003468",
  },
  {
    id: "40679",
    map_id: "1",
    address: "VIALE DELLA LIBERTA' , 38,09036 GUSPINI (VS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.5373169",
    lng: "8.6289958",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001918",
  },
  {
    id: "40667",
    map_id: "1",
    address: "VIA GRAMSCI 43/D,96013 CARLENTINI (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2969354",
    lng: "15.007718",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004897",
  },
  {
    id: "40666",
    map_id: "1",
    address: "VIA FERRARECCE 104,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0659902",
    lng: "14.3386888",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020462",
  },
  {
    id: "40661",
    map_id: "1",
    address: "VIA NINO MARTOGLIO 20,95040 MOTTA SANT'ANASTASIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5327862",
    lng: "14.9867245",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010284",
  },
  {
    id: "40659",
    map_id: "1",
    address: "VIA LA FARINA 104,98125 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.179416",
    lng: "15.5538502",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020214",
  },
  {
    id: "40651",
    map_id: "1",
    address: "VIA FRANCESCO PETRARCA 29,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.7013601",
    lng: "14.4861196",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001811",
  },
  {
    id: "40650",
    map_id: "1",
    address: "VIA C. COLOMBO 25/27,74023 GROTTAGLIE (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5364935",
    lng: "17.4369415",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020099",
  },
  {
    id: "40649",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 134,95046 PALAGONIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.3279825",
    lng: "14.74683",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020395",
  },
  {
    id: "40648",
    map_id: "1",
    address: "VIA CASTELLAMMARE 50,80054 GRAGNANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6932623",
    lng: "14.5098843",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001810",
  },
  {
    id: "40646",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 311,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.5684709",
    lng: "14.9073465",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008622",
  },
  {
    id: "40639",
    map_id: "1",
    address: "VIA CALISPERA, 42,98124 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1495772",
    lng: "15.5309137",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008389",
  },
  {
    id: "40633",
    map_id: "1",
    address: "VIA SALARIA 169,00015 MONTEROTONDO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "42.0636093",
    lng: "12.5914179",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020426",
  },
  {
    id: "40631",
    map_id: "1",
    address: "VLE DEI ROMAGNOLI 768-780,00119 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7605808",
    lng: "12.3034651",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011662",
  },
  {
    id: "40629",
    map_id: "1",
    address: "VIA RAFFAELE MUSONE 238,81025 MARCIANISE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.0371084",
    lng: "14.3080707",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001814",
  },
  {
    id: "40624",
    map_id: "1",
    address: "VIA DARIO LUPO 39,74121 TARANTO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4607651",
    lng: "17.2510672",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001531",
  },
  {
    id: "40622",
    map_id: "1",
    address: "VIA BRIGATE PARTIGIANE 37/B,19020 FOLLO (SP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID - ENEL ENERGIA",
    lat: "44.161234",
    lng: "9.862995",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011473",
  },
  {
    id: "40617",
    map_id: "1",
    address: "VIA MILANO 89/A,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.9524825",
    lng: "14.5281184",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009567",
  },
  {
    id: "40616",
    map_id: "1",
    address: "VIA TERESA MASSELLI 57,71016 SAN SEVERO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6889787",
    lng: "15.3837814",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001289",
  },
  {
    id: "40612",
    map_id: "1",
    address: "PIAZZA RICCHI SNC,09012 CAPOTERRA (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.1447243",
    lng: "8.9694002",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020430",
  },
  {
    id: "40611",
    map_id: "1",
    address: "VIA NAZIONALE 102-104,89010 SCIDO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.2443031",
    lng: "15.9315394",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020351",
  },
  {
    id: "40609",
    map_id: "1",
    address: "VIA FINOCCHIARO APRILE 15R,16129 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.3997904",
    lng: "8.9463195",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000783",
  },
  {
    id: "40607",
    map_id: "1",
    address: "VIA G. MARCONI 33,36015 SCHIO (VI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.712022",
    lng: "11.3615589",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009824",
  },
  {
    id: "40605",
    map_id: "1",
    address: "PIAZZA GIOSUE' CARDUCCI 16,15121 ALESSANDRIA (AL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.9134551",
    lng: "8.610274",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020394",
  },
  {
    id: "40603",
    map_id: "1",
    address: "VIA ROMA 45,00063 CAMPAGNANO DI ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.1365104",
    lng: "12.3778513",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020444",
  },
  {
    id: "40593",
    map_id: "1",
    address: "PIAZZA POLVANI, 4,06049 SPOLETO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.7474885",
    lng: "12.736167",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000402",
  },
  {
    id: "40589",
    map_id: "1",
    address: "VIA SAN FRANCESCO DA PAOLA SNC,88050 SELLIA MARINA (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9016189",
    lng: "16.7468795",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009536",
  },
  {
    id: "40587",
    map_id: "1",
    address: "VIA ALESSANDRO MANZONI 44,00052 CERVETERI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9954751",
    lng: "12.092441",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020488",
  },
  {
    id: "40586",
    map_id: "1",
    address: "PIAZZA MATTEOTTI 2,91022 CASTELVETRANO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.6831955",
    lng: "12.7955426",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020337",
  },
  {
    id: "40585",
    map_id: "1",
    address: "VIA PIANETTE 78,87040 MONTALTO UFFUGO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.430687",
    lng: "16.1935014",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007497",
  },
  {
    id: "40584",
    map_id: "1",
    address: "VIA PADOVA 84,20131 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4928666",
    lng: "9.2257436",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020476",
  },
  {
    id: "40582",
    map_id: "1",
    address: "VIA UMBERTO I 439,98026 NIZZA DI SICILIA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.9931648",
    lng: "15.4121847",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008260",
  },
  {
    id: "40567",
    map_id: "1",
    address: "VIA DI PIANOSCARANO 28,01100 VITERBO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "42.412282",
    lng: "12.1034541",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020386",
  },
  {
    id: "40559",
    map_id: "1",
    address: "VIALE DEGLI EROI 101,01034 FABRICA DI ROMA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.3335921",
    lng: "12.3036484",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011440",
  },
  {
    id: "40558",
    map_id: "1",
    address: "PIAZZA MARTIRI DELLA LIBERTA' 87,02047 POGGIO MIRTETO (RI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.265123",
    lng: "12.685905",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002659",
  },
  {
    id: "40556",
    map_id: "1",
    address: "VIALE CASTRENSE 31/32,00182 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8870153",
    lng: "12.5156488",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020480",
  },
  {
    id: "40554",
    map_id: "1",
    address: "VIA BORGO GARIBALDI 115,05028 PENNA IN TEVERINA (TR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.490792",
    lng: "12.3552917",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020457",
  },
  {
    id: "40545",
    map_id: "1",
    address: "Via Mesagne n. 11,72027 SAN PIETRO VERNOTICO (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.488073",
    lng: "17.9938448",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009873",
  },
  {
    id: "40544",
    map_id: "1",
    address: "VIA ANTONIO GRAMSCI 2,00060 CAPENA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.1408393",
    lng: "12.5407244",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007336",
  },
  {
    id: "40541",
    map_id: "1",
    address: "VIA NAZIONALE 71,09023 MONASTIR (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.3860235",
    lng: "9.0439019",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020433",
  },
  {
    id: "40539",
    map_id: "1",
    address: "CORSO MATTEOTTI 23,01011 CANINO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4671653",
    lng: "11.7504744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001589",
  },
  {
    id: "40537",
    map_id: "1",
    address: "VIA TIZIANO 6,09128 CAGLIARI (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.2222519",
    lng: "9.124236",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010222",
  },
  {
    id: "40533",
    map_id: "1",
    address: "PIAZZA SALVATORE RIZZO 4,96010 MELILLI (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1791699",
    lng: "15.1248721",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009758",
  },
  {
    id: "40532",
    map_id: "1",
    address: "VIA ENRICO DE NICOLA, 104,03043 CASSINO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.4916702",
    lng: "13.8323588",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020440",
  },
  {
    id: "40529",
    map_id: "1",
    address: "via Ruggiero I 5,81041 VITULAZIO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1623808",
    lng: "14.2126623",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001844",
  },
  {
    id: "40527",
    map_id: "1",
    address: "VIA COLOMBO 6,97018 SCICLI (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "36.7912005",
    lng: "14.700892",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009886",
  },
  {
    id: "40526",
    map_id: "1",
    address: "VIA CAPPUCCINI 137,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6602869",
    lng: "14.8378896",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010365",
  },
  {
    id: "40525",
    map_id: "1",
    address: "PIAZZA SAN GIOVANNI DA TRIORA 1/3,00154 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8623153",
    lng: "12.4899481",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020374",
  },
  {
    id: "40524",
    map_id: "1",
    address: "VIA FIRENZE 44,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.0795052",
    lng: "17.1241333",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020467",
  },
  {
    id: "40523",
    map_id: "1",
    address: "VIA NAZARIO SAURO 66,97011 ACATE (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.0240639",
    lng: "14.493832",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006872",
  },
  {
    id: "40522",
    map_id: "1",
    address: "VIA FRATELLI CANTINI 61,43029 TRAVERSETOLO (PR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.6388499",
    lng: "10.3831042",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006599",
  },
  {
    id: "40520",
    map_id: "1",
    address: "VIA PIAVE 55,84016 PAGANI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.744712",
    lng: "14.6141714",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003819",
  },
  {
    id: "40516",
    map_id: "1",
    address: "VIA G. NATOLI 61,98123 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.1842588",
    lng: "15.5576993",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004095",
  },
  {
    id: "40511",
    map_id: "1",
    address: "CORSO UMBERTO 416,95034 BRONTE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.7894984",
    lng: "14.8352998",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009953",
  },
  {
    id: "40509",
    map_id: "1",
    address: "VIA GUSTAVO ROCCELLA 9,90128 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.1020922",
    lng: "13.351796",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020355",
  },
  {
    id: "40506",
    map_id: "1",
    address: "VIA GIOVANNI BOCCOMINI 23,00139 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9654329",
    lng: "12.5200333",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020458",
  },
  {
    id: "40501",
    map_id: "1",
    address: "VIA VITTORIO VENETO 31/33,80011 ACERRA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9480986",
    lng: "14.3766922",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003807",
  },
  {
    id: "40497",
    map_id: "1",
    address: "VIA G. DE CHIRICO 125,87036 RENDE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.3324424",
    lng: "16.2251725",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009661",
  },
  {
    id: "40493",
    map_id: "1",
    address: "VIA VITTORIA 68,84088 SIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID - ENEL ENERGIA",
    lat: "40.7997623",
    lng: "14.695747",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002038",
  },
  {
    id: "40487",
    map_id: "1",
    address: "VIA VITTORIO VENETO 28,71045 ORTA NOVA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.3262359",
    lng: "15.7084263",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020453",
  },
  {
    id: "40484",
    map_id: "1",
    address: "VIA TIPA 12,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0188823",
    lng: "12.5227503",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007196",
  },
  {
    id: "40480",
    map_id: "1",
    address: "VIA GIOVANNI CASTAGNOLI 46,59100 PRATO (PO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8856369",
    lng: "11.0830328",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020428",
  },
  {
    id: "40473",
    map_id: "1",
    address: "VIA LARGO TOSCANINI 5,03100 FROSINONE (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.6284363",
    lng: "13.3314992",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008267",
  },
  {
    id: "40456",
    map_id: "1",
    address: "VIA SAN SALVATORE 78,80069 VICO EQUENSE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6651377",
    lng: "14.4459401",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012036",
  },
  {
    id: "40455",
    map_id: "1",
    address: "STR PROVINCIALE BASSANESE KM 4,200,01030 BASSANO ROMANO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.2176402",
    lng: "12.1734437",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006542",
  },
  {
    id: "40443",
    map_id: "1",
    address: "CORSO UMBERTO I 305,90048 SAN GIUSEPPE JATO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.9691004",
    lng: "13.1818413",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020284",
  },
  {
    id: "40431",
    map_id: "1",
    address: "via Franco Sacchetti, snc,00137 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9499664",
    lng: "12.5465209",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020266",
  },
  {
    id: "40430",
    map_id: "1",
    address: "via Principe Amedeo, 184,00185 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8957829",
    lng: "12.5055494",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020265",
  },
  {
    id: "40429",
    map_id: "1",
    address: "VIA LUCIANO ZUCCOLI 49/A,00137 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9505098",
    lng: "12.5452187",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020264",
  },
  {
    id: "40425",
    map_id: "1",
    address: "VIA PAOLO PARUTA 24,00179 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8756659",
    lng: "12.5173045",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012166",
  },
  {
    id: "40422",
    map_id: "1",
    address: "VIA LUIGI BRIGIOTTI 7,64100 TERAMO (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.6580031",
    lng: "13.7005988",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020408",
  },
  {
    id: "40417",
    map_id: "1",
    address: "VIALE NARDINI 1,01012 CAPRANICA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.2570144",
    lng: "12.1762546",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003185",
  },
  {
    id: "40413",
    map_id: "1",
    address: "C.SO ITALIA 101,84096 PONTECAGNANO FAIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6434397",
    lng: "14.8783895",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008760",
  },
  {
    id: "40404",
    map_id: "1",
    address: "VIA GIOVANNI VERGA 75/B,50135 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "43.778402",
    lng: "11.296639",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020415",
  },
  {
    id: "40401",
    map_id: "1",
    address: "VIA .S. TRICININA 650,66050 TUFILLO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9150451",
    lng: "14.6270741",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020439",
  },
  {
    id: "40400",
    map_id: "1",
    address: "VIALE EUROPA SNC,55012 CAPANNORI (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8948333",
    lng: "10.5408973",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010105",
  },
  {
    id: "40395",
    map_id: "1",
    address: "CORSO MAZZINI 12,57125 LIVORNO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.5444392",
    lng: "10.3128721",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006962",
  },
  {
    id: "40390",
    map_id: "1",
    address: "VIA PASQUALE II 16,00168 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.914144",
    lng: "12.4130517",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020283",
  },
  {
    id: "40376",
    map_id: "1",
    address: "VIA MASCAGNI SNC,58100 GROSSETO (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.7587808",
    lng: "11.1204558",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020448",
  },
  {
    id: "40374",
    map_id: "1",
    address: "Via Emilio Bardini, 44,50053 EMPOLI (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.7231859",
    lng: "10.9509643",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012572",
  },
  {
    id: "40373",
    map_id: "1",
    address: "VIALE SAN FRANCESCO 9,08020 SAN TEODORO (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.7715234",
    lng: "9.6724442",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012358",
  },
  {
    id: "40366",
    map_id: "1",
    address: "VIA D'AVALOS 11,65126 PESCARA (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.4610775",
    lng: "14.2241004",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012374",
  },
  {
    id: "40365",
    map_id: "1",
    address: "VIA CARRAIA 54,50053 EMPOLI (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7126189",
    lng: "10.93958",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020288",
  },
  {
    id: "40358",
    map_id: "1",
    address: "VIA A. PALUMBO 11,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9263016",
    lng: "14.2014229",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008082",
  },
  {
    id: "40356",
    map_id: "1",
    address: "VIALE UMBRIA 35 ANG. VIA L. MURATORI 55,20135 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4514206",
    lng: "9.2127",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008504",
  },
  {
    id: "40350",
    map_id: "1",
    address: "VIA ASCANIO 60,80124 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8175746",
    lng: "14.1638019",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009726",
  },
  {
    id: "40349",
    map_id: "1",
    address: "VIA CONA 27,87059 CASOLE BRUZIO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.2856145",
    lng: "16.3358957",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020446",
  },
  {
    id: "40348",
    map_id: "1",
    address: "VIA GIUSTI 31/B,95040 RAMACCA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3853149",
    lng: "14.6924614",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010134",
  },
  {
    id: "40346",
    map_id: "1",
    address: "VIA R. SERRA 11,47030 SAN MAURO PASCOLI (FC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.1059549",
    lng: "12.4139013",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010016",
  },
  {
    id: "40345",
    map_id: "1",
    address: "VIA NAPOLI 141,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9095114",
    lng: "14.2650673",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002121",
  },
  {
    id: "40339",
    map_id: "1",
    address: "VIA BOSCO 7,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.0803125",
    lng: "14.3317366",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020389",
  },
  {
    id: "40337",
    map_id: "1",
    address: "PIAZZA ALDO MORO 2,83100 AVELLINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.9164317",
    lng: "14.7851434",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020342",
  },
  {
    id: "40335",
    map_id: "1",
    address: "VIA PAOLO BORSELLINO 9/B,80025 CASANDRINO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9345524",
    lng: "14.2460654",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020289",
  },
  {
    id: "40334",
    map_id: "1",
    address: "LARGO MODICA 28-30,90047 PARTINICO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0444528",
    lng: "13.1160074",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000222",
  },
  {
    id: "40333",
    map_id: "1",
    address: "VIA POGGI 18,29122 PIACENZA (PC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.0433236",
    lng: "9.6849226",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020343",
  },
  {
    id: "40318",
    map_id: "1",
    address: "VIALE SAN MARCO 37,34074 MONFALCONE (GO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.8067838",
    lng: "13.5343118",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020378",
  },
  {
    id: "40317",
    map_id: "1",
    address: "VIA SATRICO 26/28/30,00183 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.8770924",
    lng: "12.5071499",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020375",
  },
  {
    id: "40316",
    map_id: "1",
    address: "CORSO PALMIRO TOGLIATTI 7,27029 VIGEVANO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.309261",
    lng: "8.8440349",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006773",
  },
  {
    id: "40315",
    map_id: "1",
    address: "VIA CARAVELLI 30,80058 TORRE ANNUNZIATA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.7557662",
    lng: "14.4329504",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020434",
  },
  {
    id: "40314",
    map_id: "1",
    address: "CORSO DELLA LIBERTA' 95,87040 CASTIGLIONE COSENTINO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.3516545",
    lng: "16.2882876",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009335",
  },
  {
    id: "40306",
    map_id: "1",
    address: "VIA LOMBARDI 7,89024 POLISTENA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "38.4071377",
    lng: "16.0778968",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009485",
  },
  {
    id: "40304",
    map_id: "1",
    address: "VIA GENOVA 12,00055 LADISPOLI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9477141",
    lng: "12.0835768",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010271",
  },
  {
    id: "40294",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI 50,95126 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5222647",
    lng: "15.1129977",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-002045",
  },
  {
    id: "40293",
    map_id: "1",
    address: "VIA CRUCIS 87,80029 SANT'ANTIMO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9446463",
    lng: "14.2307509",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020370",
  },
  {
    id: "40290",
    map_id: "1",
    address: "VIA MARTIRI DELLA LIBERTÀ 7,13836 COSSATO (BI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.5688996",
    lng: "8.1838512",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020358",
  },
  {
    id: "40287",
    map_id: "1",
    address: "PIAZZA DANTE 16,84085 MERCATO SAN SEVERINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7867574",
    lng: "14.7596864",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009256",
  },
  {
    id: "40282",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 40,63078 SPINETOLI (AP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.867517",
    lng: "13.770168",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008522",
  },
  {
    id: "40280",
    map_id: "1",
    address: "VIA BORSELLINO 7/A,80025 CASANDRINO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9346271",
    lng: "14.2460602",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009223",
  },
  {
    id: "40272",
    map_id: "1",
    address: "VIA ROMA 101,83036 MIRABELLA ECLANO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0434184",
    lng: "14.9968975",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007542",
  },
  {
    id: "40271",
    map_id: "1",
    address: "via Galileo Galilei 92,64100 TERAMO (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.6963028",
    lng: "13.7882787",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003301",
  },
  {
    id: "40269",
    map_id: "1",
    address: "VIA EUGENIO CHECCHI 20,00157 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9092319",
    lng: "12.5444224",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002517",
  },
  {
    id: "40261",
    map_id: "1",
    address: "VIA MASTROGIORGIO 79C,00153 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8799231",
    lng: "12.4764614",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008485",
  },
  {
    id: "40258",
    map_id: "1",
    address: "VIA DE GASPERI 84,81055 SANTA MARIA CAPUA VETERE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0838253",
    lng: "14.25715",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003837",
  },
  {
    id: "40256",
    map_id: "1",
    address: "VIA ROMA 182,09040 SETTIMO SAN PIETRO (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "39.2923829",
    lng: "9.1889011",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001920",
  },
  {
    id: "40251",
    map_id: "1",
    address: "PIAZZA GARIBALDI 12,74016 MASSAFRA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5883285",
    lng: "17.1121131",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009383",
  },
  {
    id: "40238",
    map_id: "1",
    address: "VIA ROMA 277,80057 SANT'ANTONIO ABATE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.7237826",
    lng: "14.5453398",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008705",
  },
  {
    id: "40237",
    map_id: "1",
    address: "VIALE VAL DI MAZARA 41,91026 MAZARA DEL VALLO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6507304",
    lng: "12.6039704",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012892",
  },
  {
    id: "40227",
    map_id: "1",
    address: "CORSO S. ALDISIO 369,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.071008",
    lng: "14.237272",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009263",
  },
  {
    id: "40218",
    map_id: "1",
    address: "VIA NAZIONALE DELLE PUGLIE, 25,83021 AVELLA (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9525121",
    lng: "14.6047243",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005447",
  },
  {
    id: "40215",
    map_id: "1",
    address: "VIA VISINALE 4,33055 MUZZANA DEL TURGNANO (UD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.8163406",
    lng: "13.127409",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020042",
  },
  {
    id: "40209",
    map_id: "1",
    address: "VIA DELL'ABBONDANZA, 22,80145 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8908956",
    lng: "14.2313911",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009797",
  },
  {
    id: "40204",
    map_id: "1",
    address: "VIA 4 NOVEMBRE 1,13896 NETRO (BI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "45.5380273",
    lng: "7.9476279",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020344",
  },
  {
    id: "40203",
    map_id: "1",
    address: "VIA DEL TRIONFO 82,52100 AREZZO (AR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.4572086",
    lng: "11.868996",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009641",
  },
  {
    id: "40201",
    map_id: "1",
    address: "VIA DAMIANO CHIESA 51,06034 FOLIGNO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.9471284",
    lng: "12.6985809",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008458",
  },
  {
    id: "40197",
    map_id: "1",
    address: "VIA MATTEOTTI 34/36,84084 FISCIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7622208",
    lng: "14.7923513",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009082",
  },
  {
    id: "40189",
    map_id: "1",
    address: "VIA GIUSEPPE DI VITTORIO 21,03023 CECCANO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.5714383",
    lng: "13.3247335",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020366",
  },
  {
    id: "40179",
    map_id: "1",
    address: "VIA XXV APRILE 130,53034 COLLE DI VAL D'ELSA (SI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "43.4173279",
    lng: "11.1314705",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012858",
  },
  {
    id: "40173",
    map_id: "1",
    address: "PIAZZA MATTEOTTI 15,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "36.9561929",
    lng: "14.5285182",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020425",
  },
  {
    id: "40172",
    map_id: "1",
    address: "VIA CASIMIRO TEJA 4,00157 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9144582",
    lng: "12.5472261",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020429",
  },
  {
    id: "40169",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 81,81030 CASALUCE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0026865",
    lng: "14.1992443",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003818",
  },
  {
    id: "40168",
    map_id: "1",
    address: "VIA G. TOMASI 13,92031 LAMPEDUSA E LINOSA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "35.502582",
    lng: "12.6105322",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009470",
  },
  {
    id: "40167",
    map_id: "1",
    address: "VIA LOMBARDIA 62,74121 TARANTO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4560791",
    lng: "17.2631994",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020432",
  },
  {
    id: "40166",
    map_id: "1",
    address: "VIA SAN VITO 2,85020 TITO (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID - ENEL ENERGIA",
    lat: "40.5847248",
    lng: "15.676716",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005729",
  },
  {
    id: "40164",
    map_id: "1",
    address: "VIA PRINCIPE DI PIEMONTE 48/50,96010 SORTINO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1571806",
    lng: "15.0264502",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009745",
  },
  {
    id: "40162",
    map_id: "1",
    address: "VIA II RETTA LEVANTE 344,95032 BELPASSO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.5908435",
    lng: "14.9783376",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-002003",
  },
  {
    id: "40152",
    map_id: "1",
    address: "VIA VENEZIA 365/367,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0743232",
    lng: "14.2457798",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000847",
  },
  {
    id: "40149",
    map_id: "1",
    address: "PIAZZA TOMMASO NATALE 208,90147 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "38.1881919",
    lng: "13.2879372",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012883",
  },
  {
    id: "40148",
    map_id: "1",
    address: "STRADA STATALE 18 282,89851 JONADI (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID - ENEL ENERGIA",
    lat: "38.6051515",
    lng: "16.0626286",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012909",
  },
  {
    id: "40142",
    map_id: "1",
    address: "VIA DI MORENA 3,00043 CIAMPINO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8035682",
    lng: "12.6037837",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020313",
  },
  {
    id: "40141",
    map_id: "1",
    address: "VIA GIOVANNI XXIII 9,01033 CIVITA CASTELLANA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.2962941",
    lng: "12.4129039",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020307",
  },
  {
    id: "40140",
    map_id: "1",
    address: "REGIONE PALMADULA/VIA ARGENTERIA 42,07100 SASSARI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7487005",
    lng: "8.1877629",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020419",
  },
  {
    id: "40139",
    map_id: "1",
    address: "LOC. SAN FRANCESCO SNC,01011 CANINO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.4701086",
    lng: "11.7546276",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020354",
  },
  {
    id: "40133",
    map_id: "1",
    address: "VIA CARLO MARIA 132,89046 MARINA DI GIOIOSA IONICA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.3018184",
    lng: "16.3329391",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009972",
  },
  {
    id: "40130",
    map_id: "1",
    address: "VIA ROMA 271/A,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9491245",
    lng: "14.5283643",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020422",
  },
  {
    id: "40128",
    map_id: "1",
    address: "Via Santo Spirito n. 36/38,66034 LANCIANO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.2245882",
    lng: "14.3930694",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001321",
  },
  {
    id: "40125",
    map_id: "1",
    address: "VIA CUOMO 36,84092 BELLIZZI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6209475",
    lng: "14.9490988",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008311",
  },
  {
    id: "40124",
    map_id: "1",
    address: "VIA PAOLO BARATTA 91,84091 BATTIPAGLIA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6071967",
    lng: "14.9879316",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001820",
  },
  {
    id: "40122",
    map_id: "1",
    address: "VIA ISPANTO 1,58024 MASSA MARITTIMA (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.0847133",
    lng: "10.9824475",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020319",
  },
  {
    id: "40120",
    map_id: "1",
    address: "VILLAGGIO SANTA CATERINA 76,25035 OSPITALETTO (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.5609231",
    lng: "10.0665888",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012946",
  },
  {
    id: "40117",
    map_id: "1",
    address: "Via Grazia Deledda, 10,74123 MARTINA FRANCA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6094288",
    lng: "17.2306003",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002307",
  },
  {
    id: "40111",
    map_id: "1",
    address: "Via Palma, 63,92027 LICATA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1085222",
    lng: "13.9304303",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020294",
  },
  {
    id: "40107",
    map_id: "1",
    address: "P.ZZA DANTE 23,28062 CAMERI (NO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.5010837",
    lng: "8.6624108",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008312",
  },
  {
    id: "40106",
    map_id: "1",
    address: "CORSO UMBERTO I 13,92020 CASTROFILIPPO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.3477745",
    lng: "13.7498426",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009302",
  },
  {
    id: "40099",
    map_id: "1",
    address: "CORSO SINISGALLI 53,85053 MONTEMURRO (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.298041",
    lng: "15.9921834",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009369",
  },
  {
    id: "40096",
    map_id: "1",
    address: "VIA LAURENTANA 27,00040 ARDEA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.607317",
    lng: "12.5434285",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008453",
  },
  {
    id: "40095",
    map_id: "1",
    address: "VIA PETRARCA 35,35028 PIOVE DI SACCO (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.3050615",
    lng: "12.0347182",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009788",
  },
  {
    id: "40090",
    map_id: "1",
    address: "VIA CENTRALE UMBRA 19,06038 SPELLO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.9856341",
    lng: "12.6744226",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-002412",
  },
  {
    id: "40087",
    map_id: "1",
    address: "VIA MATTEO AVALLONE, 20/22,84129 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6616982",
    lng: "14.7973667",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003809",
  },
  {
    id: "40081",
    map_id: "1",
    address: "VIA SARINO PUGLIESE 7,89013 GIOIA TAURO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.4260335",
    lng: "15.8986106",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001415",
  },
  {
    id: "40073",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI 26,92020 PALMA DI MONTECHIARO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1922035",
    lng: "13.7652934",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020293",
  },
  {
    id: "40070",
    map_id: "1",
    address: "VIA VERONA 16,37045 LEGNAGO (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "45.1859501",
    lng: "11.2789178",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012185",
  },
  {
    id: "40068",
    map_id: "1",
    address: "PIAZZA SAN GIORGIO 7,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.8631426",
    lng: "14.1774457",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008615",
  },
  {
    id: "40064",
    map_id: "1",
    address: "VIA CAIO DUILIO 70,80125 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8272014",
    lng: "14.2066313",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008175",
  },
  {
    id: "40059",
    map_id: "1",
    address: "VIA CUPA SAN PIETRO 17,80147 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8549525",
    lng: "14.3322694",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008542",
  },
  {
    id: "40058",
    map_id: "1",
    address: "VIA MATTEOTTI 2,09010 TRATALIAS (CI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.1053986",
    lng: "8.5762368",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020392",
  },
  {
    id: "40057",
    map_id: "1",
    address: "VIA NASTARI, 27,89011 BAGNARA CALABRA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.2857651",
    lng: "15.802956",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001447",
  },
  {
    id: "40055",
    map_id: "1",
    address: "VIA VITTORIO VENETO 21/B,70018 RUTIGLIANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9540562",
    lng: "17.2956391",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009871",
  },
  {
    id: "40049",
    map_id: "1",
    address: "DELL'EDERA ANGOLO VIA CICLAMINI 61,95033 BIANCAVILLA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.6477782",
    lng: "14.8561103",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020398",
  },
  {
    id: "40046",
    map_id: "1",
    address: "VIA DANIMARCA 191,00071 POMEZIA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6305548",
    lng: "12.4788368",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020362",
  },
  {
    id: "40045",
    map_id: "1",
    address: "PIAZZA UNITA' D'ITALIA 21,72026 SAN PANCRAZIO SALENTINO (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.416801",
    lng: "17.8397977",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008755",
  },
  {
    id: "40042",
    map_id: "1",
    address: "VIALE MARIO GORI 56,93015 NISCEMI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1479273",
    lng: "14.3885798",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020404",
  },
  {
    id: "40038",
    map_id: "1",
    address: "VIA ETNEA 21 B,95030 TREMESTIERI ETNEO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5655487",
    lng: "15.0757661",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009818",
  },
  {
    id: "40034",
    map_id: "1",
    address: "VIA UMBERTO 25,95036 RANDAZZO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.8788955",
    lng: "14.9498805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020405",
  },
  {
    id: "40033",
    map_id: "1",
    address: "VIA ZITO 48,84016 PAGANI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.7405929",
    lng: "14.61",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020324",
  },
  {
    id: "40031",
    map_id: "1",
    address: "VIA DEL BERSAGLIERE 67,90143 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.1461608",
    lng: "13.3435977",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020246",
  },
  {
    id: "40025",
    map_id: "1",
    address: "CORSO BUTERA 297,90011 BAGHERIA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.083498",
    lng: "13.506108",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008549",
  },
  {
    id: "40019",
    map_id: "1",
    address: "VIA TIRONE DI MOCCIA 30,80056 ERCOLANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.8067946",
    lng: "14.3547031",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020388",
  },
  {
    id: "40018",
    map_id: "1",
    address: "VIA NUNZIANTE 29,89010 MOLOCHIO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.3088524",
    lng: "16.0319958",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009454",
  },
  {
    id: "40017",
    map_id: "1",
    address: "VIA BAROZZI 4/A,41058 VIGNOLA (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.4770245",
    lng: "11.0091834",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020242",
  },
  {
    id: "40016",
    map_id: "1",
    address: "VIA BERLINGUER 5,84010 SAN MARZANO SUL SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.7757694",
    lng: "14.5857688",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006785",
  },
  {
    id: "40014",
    map_id: "1",
    address: "VIA GINO GRAZIANI 4,57121 LIVORNO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.5525948",
    lng: "10.3402151",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020211",
  },
  {
    id: "40013",
    map_id: "1",
    address: "VIA B. CELLINI 51,57025 PIOMBINO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.9262617",
    lng: "10.5285679",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006748",
  },
  {
    id: "40012",
    map_id: "1",
    address: "VIA F.LLI ROSSELLI 45,80019 QUALIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9194388",
    lng: "14.1516025",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001876",
  },
  {
    id: "40010",
    map_id: "1",
    address: "CORSO SONNINO 36/A,70121 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1206088",
    lng: "16.8791717",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009450",
  },
  {
    id: "40008",
    map_id: "1",
    address: "VIA NAZIONALE 80,84018 SCAFATI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7485588",
    lng: "14.5625458",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010023",
  },
  {
    id: "40007",
    map_id: "1",
    address: "VIA REGINA ELENA 47/A,74123 TARANTO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4697931",
    lng: "17.2483307",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002306",
  },
  {
    id: "40005",
    map_id: "1",
    address: "VIA G. GARIBALDI 54,01016 TARQUINIA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.2527727",
    lng: "11.7582037",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007339",
  },
  {
    id: "40001",
    map_id: "1",
    address: "VIA G. MARCONI 44,80056 ERCOLANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8044616",
    lng: "14.3554576",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001828",
  },
  {
    id: "40000",
    map_id: "1",
    address: "VIA AVELLINO 13,71010 CAGNANO VARANO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.827757",
    lng: "15.7748661",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003529",
  },
  {
    id: "39999",
    map_id: "1",
    address: "CORSO GARIBALDI 12,89025 ROSARNO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.4897713",
    lng: "15.9743428",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001456",
  },
  {
    id: "39995",
    map_id: "1",
    address: "VIA UGO FOSCOLO, 19,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6696629",
    lng: "14.8316401",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009114",
  },
  {
    id: "39994",
    map_id: "1",
    address: "via Nino Bixio n. 37,80125 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8321864",
    lng: "14.2003046",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001845",
  },
  {
    id: "39993",
    map_id: "1",
    address: "VIA SANTA MARIA LA STELLA 14,95025 ACI SANT'ANTONIO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6259051",
    lng: "15.1308979",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009216",
  },
  {
    id: "39992",
    map_id: "1",
    address: "VIA ARCHIA, 59,96100 SIRACUSA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0713986",
    lng: "15.2838283",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009772",
  },
  {
    id: "39984",
    map_id: "1",
    address: "via Scesa Foresta 28,88064 CHIARAVALLE CENTRALE (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.6814846",
    lng: "16.4092019",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003413",
  },
  {
    id: "39980",
    map_id: "1",
    address: "VIA PIETRO MENGOLI 19,00146 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.863743",
    lng: "12.4705276",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008305",
  },
  {
    id: "39979",
    map_id: "1",
    address: "VIA ARRINGA 92,88837 PETILIA POLICASTRO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.1125702",
    lng: "16.7906521",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008451",
  },
  {
    id: "39976",
    map_id: "1",
    address: "VIA MARIO PAGANO 136,76125 TRANI (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2779655",
    lng: "16.4175413",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008107",
  },
  {
    id: "39973",
    map_id: "1",
    address: "VIA MATERA 47,70029 SANTERAMO IN COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7894634",
    lng: "16.7516308",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008390",
  },
  {
    id: "39972",
    map_id: "1",
    address: "LARGO DI PORTA MARMOREO 8,06024 GUBBIO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.3505832",
    lng: "12.578026",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008325",
  },
  {
    id: "39957",
    map_id: "1",
    address: "VIA PIANO CAPPELLE 290,82100 BENEVENTO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1023391",
    lng: "14.8256797",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020277",
  },
  {
    id: "39956",
    map_id: "1",
    address: "VAI CERASELLE 90,81059 CAIANELLO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.3067135",
    lng: "14.1033433",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020346",
  },
  {
    id: "39954",
    map_id: "1",
    address: "VIA PIAVE 17,80128 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8383706",
    lng: "14.2143105",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020257",
  },
  {
    id: "39944",
    map_id: "1",
    address: "VIA FLAMINIA 12,00196 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9130883",
    lng: "12.4754077",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008437",
  },
  {
    id: "39942",
    map_id: "1",
    address: "VIA ARPINO, 109,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8777034",
    lng: "14.3070785",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009766",
  },
  {
    id: "39941",
    map_id: "1",
    address: "V.LE EUROPA SNC,20062 CASSANO D'ADDA (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5166819",
    lng: "9.5092423",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008363",
  },
  {
    id: "39934",
    map_id: "1",
    address: "VIA NAZIONALE 369,64026 ROSETO DEGLI ABRUZZI (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.6738303",
    lng: "14.0173304",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008340",
  },
  {
    id: "39932",
    map_id: "1",
    address: "VIA CENTAMORE 25,95033 BIANCAVILLA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6476278",
    lng: "14.8615578",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008364",
  },
  {
    id: "39931",
    map_id: "1",
    address: "Via Zara 27,80011 ACERRA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9409846",
    lng: "14.3710505",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001882",
  },
  {
    id: "39930",
    map_id: "1",
    address: "VIALE MARCONI 4,80040 CERCOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8629141",
    lng: "14.3582568",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003874",
  },
  {
    id: "39928",
    map_id: "1",
    address: "VIALE OLIMPICO 83,80070 BACOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7982045",
    lng: "14.0643588",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008032",
  },
  {
    id: "39927",
    map_id: "1",
    address: "VIA CASSIA CURA 215,01019 VETRALLA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "42.3074422",
    lng: "12.0813459",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010437",
  },
  {
    id: "39925",
    map_id: "1",
    address: "VIA REGINA MARGHERITA 35,88822 CASABONA (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.2485082",
    lng: "16.9567687",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012276",
  },
  {
    id: "39924",
    map_id: "1",
    address: "VIA ARMANDO DIAZ 48,81046 GRAZZANISE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "41.089963",
    lng: "14.0985849",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011628",
  },
  {
    id: "39923",
    map_id: "1",
    address: "QUARTA TRAVERSA BOTTEGHELLE 3,80147 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8615827",
    lng: "14.3283147",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003822",
  },
  {
    id: "39922",
    map_id: "1",
    address: "CORSO GARIBALDI 7,87046 MONTALTO UFFUGO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.4061726",
    lng: "16.1575625",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020311",
  },
  {
    id: "39921",
    map_id: "1",
    address: "VIA SAMPERI 149,93015 NISCEMI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.1467058",
    lng: "14.3873665",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020406",
  },
  {
    id: "39920",
    map_id: "1",
    address: "VIA PINTURICCHIO 8,06038 SPELLO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "42.9854481",
    lng: "12.6722464",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020380",
  },
  {
    id: "39919",
    map_id: "1",
    address: "VIA DEL MARE 197,00071 POMEZIA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.6391245",
    lng: "12.4832717",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020349",
  },
  {
    id: "39918",
    map_id: "1",
    address: "VIALE ROMA 22/24,84010 SAN MARZANO SUL SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7766383",
    lng: "14.5843439",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020295",
  },
  {
    id: "39917",
    map_id: "1",
    address: "VIA MARCHE 10,60035 JESI (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "43.5309864",
    lng: "13.2492594",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020403",
  },
  {
    id: "39915",
    map_id: "1",
    address: "VIA FIUME 102 B,95032 BELPASSO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.5981456",
    lng: "14.9813326",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009748",
  },
  {
    id: "39910",
    map_id: "1",
    address: "PIAZZA SANTA TERESA 1,70022 ALTAMURA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.828846",
    lng: "16.5509199",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009440",
  },
  {
    id: "39908",
    map_id: "1",
    address: "VIALE TEOCRITO 103,96100 SIRACUSA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.075352",
    lng: "15.2836928",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008427",
  },
  {
    id: "39904",
    map_id: "1",
    address: "VIA TRAPANI 129,91025 MARSALA (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.8051352",
    lng: "12.4465693",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008419",
  },
  {
    id: "39902",
    map_id: "1",
    address: "VIA VODICE 7/A,05100 TERNI (TR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "42.558036",
    lng: "12.6516122",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008277",
  },
  {
    id: "39899",
    map_id: "1",
    address: "PIAZZA TUTTI I SANTI 13,95036 RANDAZZO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.8745273",
    lng: "14.9470721",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009238",
  },
  {
    id: "39892",
    map_id: "1",
    address: "VIA SALARA, 17,65013 CITTA' SANT'ANGELO (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.5227439",
    lng: "14.1372744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008334",
  },
  {
    id: "39888",
    map_id: "1",
    address: "VIA ARCORA 110, PALAZZO GECOS,80013 CASALNUOVO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9032832",
    lng: "14.3498247",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008756",
  },
  {
    id: "39886",
    map_id: "1",
    address: "C.SO V. EMANUELE 12,95017 PIEDIMONTE ETNEO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.8073035",
    lng: "15.1785647",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008429",
  },
  {
    id: "39871",
    map_id: "1",
    address: "VIA CAMPANA 250/A,80078 POZZUOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.877812",
    lng: "14.1213687",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008288",
  },
  {
    id: "39869",
    map_id: "1",
    address: "VIA TARQUINIA 79,01017 TUSCANIA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.418235",
    lng: "11.8676965",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006590",
  },
  {
    id: "39863",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 162,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0651004",
    lng: "14.2531677",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001704",
  },
  {
    id: "39859",
    map_id: "1",
    address: "VIA ALBANO 94,00179 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.8683719",
    lng: "12.5305916",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007541",
  },
  {
    id: "39849",
    map_id: "1",
    address: "VIA A. MIGLIACCIO 789,88024 GIRIFALCO (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.8279437",
    lng: "16.4390173",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009983",
  },
  {
    id: "39847",
    map_id: "1",
    address: "VIA ARTURO DE ROSA 72/74,80021 AFRAGOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9274103",
    lng: "14.3067008",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003830",
  },
  {
    id: "39846",
    map_id: "1",
    address: "VIA F.LLI BANDIERA 56,87036 RENDE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.3345527",
    lng: "16.2414395",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008155",
  },
  {
    id: "39843",
    map_id: "1",
    address: "via sassari 18,07030 FLORINAS (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.650064",
    lng: "8.665419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008356",
  },
  {
    id: "39835",
    map_id: "1",
    address: "VIA DEI GARIBALDINI, 3,90020 ROCCAPALUMBA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.801063",
    lng: "13.6362941",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001842",
  },
  {
    id: "39824",
    map_id: "1",
    address: "CORSO GENERALE TORELLI 102,71011 APRICENA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7858197",
    lng: "15.4427028",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009243",
  },
  {
    id: "39821",
    map_id: "1",
    address: "PIAZZA MATRICE 7-9,98047 SAPONARA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.192931",
    lng: "15.4342615",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020347",
  },
  {
    id: "39819",
    map_id: "1",
    address: "PARCO DELLE RIMEMBRANZE 36,73010 VEGLIE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.3354231",
    lng: "17.9652668",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020363",
  },
  {
    id: "39818",
    map_id: "1",
    address: "VIA LEINI 99,10155 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0951703",
    lng: "7.6921447",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020321",
  },
  {
    id: "39817",
    map_id: "1",
    address: "VIA CORSO VITTORIO EMANUELE 163,84096 MONTECORVINO ROVELLA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.6770088",
    lng: "14.7653572",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012580",
  },
  {
    id: "39816",
    map_id: "1",
    address: "VIA DELLA CELLULOSA 62 A,00162 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9207473",
    lng: "12.3648359",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020350",
  },
  {
    id: "39814",
    map_id: "1",
    address: "VIA ANGUILLARESE 70/72,00123 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.037904",
    lng: "12.3108097",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011316",
  },
  {
    id: "39813",
    map_id: "1",
    address: "VIA TRIESTE 29-31,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4640686",
    lng: "15.5522867",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020368",
  },
  {
    id: "39810",
    map_id: "1",
    address: "VIA RAFFAELE DA BRESCIA 21,25124 BRESCIA (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.5278997",
    lng: "10.224458",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010010",
  },
  {
    id: "39801",
    map_id: "1",
    address: "VIA GARIBALDI 4,94010 CENTURIPE (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID - ENEL ENERGIA",
    lat: "37.6236142",
    lng: "14.7400238",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008006",
  },
  {
    id: "39799",
    map_id: "1",
    address: "VIA DOGANA 195,87032 AMANTEA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.1322636",
    lng: "16.0729963",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009777",
  },
  {
    id: "39795",
    map_id: "1",
    address: "VIA BARI 201,70010 VALENZANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0466886",
    lng: "16.8840742",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009251",
  },
  {
    id: "39794",
    map_id: "1",
    address: "V.LE A. VESPUCCI 22,64100 TERAMO (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.683011",
    lng: "13.7578133",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008407",
  },
  {
    id: "39790",
    map_id: "1",
    address: "VIA L. CHERUBINI 19,74010 STATTE (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.5654849",
    lng: "17.2058998",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008296",
  },
  {
    id: "39789",
    map_id: "1",
    address: "VIA MONTE GRAPPA 13,80027 FRATTAMAGGIORE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.941829",
    lng: "14.266802",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001835",
  },
  {
    id: "39788",
    map_id: "1",
    address: "P.ZZA MATTEOTTI 4,88051 CROPANI (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9247802",
    lng: "16.8118915",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008447",
  },
  {
    id: "39785",
    map_id: "1",
    address: "VIA G. MARCONI 12,00031 ARTENA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7430693",
    lng: "12.9151705",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009784",
  },
  {
    id: "39784",
    map_id: "1",
    address: "VIA INDIPENDENZA 2,89834 PIZZONI (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.6231544",
    lng: "16.2501288",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009127",
  },
  {
    id: "39775",
    map_id: "1",
    address: "CORSO ITALIA, 74,84012 ANGRI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7382099",
    lng: "14.5749204",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011693",
  },
  {
    id: "39773",
    map_id: "1",
    address: "VIA XXX GENNAIO 31,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.0164",
    lng: "12.5149121",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020353",
  },
  {
    id: "39771",
    map_id: "1",
    address: "VIALE REGINA ELENA, 10,42124 REGGIO NELL'EMILIA (RE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.7044897",
    lng: "10.6261847",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008233",
  },
  {
    id: "39767",
    map_id: "1",
    address: "PIAZZA SAN LUIGI 6,71021 ACCADIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.158836",
    lng: "15.3344851",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020367",
  },
  {
    id: "39763",
    map_id: "1",
    address: "PIAZZA MARTINEZ, 29/B R,16143 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.4077929",
    lng: "8.9573551",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011827",
  },
  {
    id: "39759",
    map_id: "1",
    address: "VIA TRIESTE 106,87046 MONTALTO UFFUGO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.387171",
    lng: "16.2366001",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008335",
  },
  {
    id: "39757",
    map_id: "1",
    address: "Via Prof. G. Crimaldi, 15,80013 CASALNUOVO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9074349",
    lng: "14.3387356",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001879",
  },
  {
    id: "39749",
    map_id: "1",
    address: "VIA DON MINZONI 326,80040 CERCOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8693095",
    lng: "14.3504525",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009435",
  },
  {
    id: "39747",
    map_id: "1",
    address: "VIA RAFFAELE GALIERO 7,80012 CALVIZZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9071775",
    lng: "14.1927059",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006616",
  },
  {
    id: "39745",
    map_id: "1",
    address: "CORSO GIUSEPPE GARIBARLDI,  43,92027 LICATA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.1051969",
    lng: "13.944262",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009399",
  },
  {
    id: "39744",
    map_id: "1",
    address: "VIALE EUROPA 185,90036 MISILMERI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0333079",
    lng: "13.4538144",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008197",
  },
  {
    id: "39738",
    map_id: "1",
    address: "Via Rpsa Jemma, 43,84091 BATTIPAGLIA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.6048698",
    lng: "14.9816383",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020359",
  },
  {
    id: "39727",
    map_id: "1",
    address: "VIA SANTI 149,93010 DELIA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.3575221",
    lng: "13.9273257",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008688",
  },
  {
    id: "39716",
    map_id: "1",
    address: "CIRCUMVALLAZIONE TRIONFALE 53C,00195 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9122362",
    lng: "12.4496766",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009657",
  },
  {
    id: "39699",
    map_id: "1",
    address: "VIA G. SUPINO 23,84014 NOCERA INFERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7455804",
    lng: "14.6447587",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008304",
  },
  {
    id: "39696",
    map_id: "1",
    address: "VIALE DIAZ, 66,94100 ENNA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5660785",
    lng: "14.2696774",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009780",
  },
  {
    id: "39677",
    map_id: "1",
    address: "VIA G. MARIA BOSCO, 176,80011 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0801817",
    lng: "14.3371973",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011566",
  },
  {
    id: "39676",
    map_id: "1",
    address: "VIA SIRIS 10,75025 POLICORO (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.2108404",
    lng: "16.6769363",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009660",
  },
  {
    id: "39674",
    map_id: "1",
    address: "VIA MERCATELLO 116,83025 MONTORO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8382387",
    lng: "14.7852691",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011961",
  },
  {
    id: "39673",
    map_id: "1",
    address: "VIA TANCREDI CARTELLA 15,00159 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9070108",
    lng: "12.5370187",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012775",
  },
  {
    id: "39655",
    map_id: "1",
    address: "VIALE DE GASPERI 255,63076 MONTEPRANDONE (AP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.8973084",
    lng: "13.848973",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008264",
  },
  {
    id: "39641",
    map_id: "1",
    address: "VIA RAFFAELE VIVIANI 12,80010 VILLARICCA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9215773",
    lng: "14.1819116",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009307",
  },
  {
    id: "39640",
    map_id: "1",
    address: "VIALE A. GRASSIA 33,95034 BRONTE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.7847848",
    lng: "14.8376639",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020317",
  },
  {
    id: "38630",
    map_id: "1",
    address: "LGO ITRI 19/20,00177 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8935535",
    lng: "12.5460631",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020240",
  },
  {
    id: "38629",
    map_id: "1",
    address: "CORSO SVIZZERA 60,10143 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0813698",
    lng: "7.6511026",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020279",
  },
  {
    id: "38627",
    map_id: "1",
    address: "PIAZZA REGINA MARGHERITA 53,80040 SAN GENNARO VESUVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8600793",
    lng: "14.5283333",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020339",
  },
  {
    id: "38623",
    map_id: "1",
    address: "Via Napoli, 102,80018 MUGNANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9050255",
    lng: "14.2074178",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012439",
  },
  {
    id: "38622",
    map_id: "1",
    address: "CORSO EUROPA 5,80016 MARANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8987768",
    lng: "14.1966945",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-012046",
  },
  {
    id: "38619",
    map_id: "1",
    address: "PIAZZA MARCONI,84087 SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8117492",
    lng: "14.6191255",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001864",
  },
  {
    id: "38617",
    map_id: "1",
    address: "VIA VITTORIO VENETO ANGOLO VICOLO BARI 5,92020 CAMASTRA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2580748",
    lng: "13.7910009",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008396",
  },
  {
    id: "38616",
    map_id: "1",
    address: "CORSO ITALIA, 8,87032 AMANTEA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "39.0675648",
    lng: "16.0930163",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008226",
  },
  {
    id: "38615",
    map_id: "1",
    address: "VIA CAVOUR 4,91021 CAMPOBELLO DI MAZARA (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6356217",
    lng: "12.7479554",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004014",
  },
  {
    id: "38613",
    map_id: "1",
    address: "VIA GIOVANNI PAOLO II, 25/27,84087 SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7786601",
    lng: "14.5838051",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008244",
  },
  {
    id: "38608",
    map_id: "1",
    address: "VIA NAZIONALE, 227,89831 SORIANELLO (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.7345801",
    lng: "16.1654388",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008271",
  },
  {
    id: "38602",
    map_id: "1",
    address: "VIA BORGESANO 2,90016 COLLESANO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.9220393",
    lng: "13.9376914",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009556",
  },
  {
    id: "38598",
    map_id: "1",
    address: "C.SO ALDO MORO 21,90031 BELMONTE MEZZAGNO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.047982",
    lng: "13.388203",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008259",
  },
  {
    id: "38597",
    map_id: "1",
    address:
      "VIA MADONNA DEI MARTIRI PRESSO STAZ. DI SERVIZIO ESSO,70056 MOLFETTA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2057588",
    lng: "16.5874767",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008268",
  },
  {
    id: "38595",
    map_id: "1",
    address: "VIA CORSO GRAN PRIORATO DI MALTA,10,81043 CAPUA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1066731",
    lng: "14.2131955",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012577",
  },
  {
    id: "38594",
    map_id: "1",
    address: "VIA GIANNUTRI 37,57037 PORTOFERRAIO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "42.8057238",
    lng: "10.3128714",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000813",
  },
  {
    id: "38592",
    map_id: "1",
    address: "VIA PIETRO CASILLI 33,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.899917",
    lng: "14.288999",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003062",
  },
  {
    id: "38587",
    map_id: "1",
    address: "VIA STEFANO DI BLASI 117,94016 PIETRAPERZIA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4172222",
    lng: "14.1360665",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001992",
  },
  {
    id: "38586",
    map_id: "1",
    address: "VIA PRINCIPE DI PIEMONTE 89,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9035113",
    lng: "14.2851673",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001841",
  },
  {
    id: "38576",
    map_id: "1",
    address: "CORSO UMBERTO 222,94013 LEONFORTE (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.6391724",
    lng: "14.3919757",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008232",
  },
  {
    id: "38574",
    map_id: "1",
    address: "VIA G. FAVA 13,96010 PALAZZOLO ACREIDE (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.0668369",
    lng: "14.9095303",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008248",
  },
  {
    id: "38572",
    map_id: "1",
    address: "VIA COMASINA,20030 SENAGO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.5798412",
    lng: "9.145621",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008416",
  },
  {
    id: "38569",
    map_id: "1",
    address: "VIA ROMA 13,82020 CIRCELLO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.3578953",
    lng: "14.8085833",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008241",
  },
  {
    id: "38567",
    map_id: "1",
    address: "CONTRADA GALLITELLO SNC,85100 POTENZA (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6372183",
    lng: "15.7844545",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008632",
  },
  {
    id: "38565",
    map_id: "1",
    address: "VIA CUMANA 34,80125 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8279302",
    lng: "14.2050017",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008170",
  },
  {
    id: "38562",
    map_id: "1",
    address: "VIA NAZIONALE TIBURTINA 189,00019 TIVOLI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.9561717",
    lng: "12.7715083",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008722",
  },
  {
    id: "38558",
    map_id: "1",
    address: "VIA SAN FRANCESCO DI PAOLA , 55,95048 SCORDIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.2935341",
    lng: "14.8487457",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008009",
  },
  {
    id: "38556",
    map_id: "1",
    address: "VIALE KENNEDY 365,80124 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8192654",
    lng: "14.1783604",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008399",
  },
  {
    id: "38555",
    map_id: "1",
    address: "CORSO HO CHI MIN, 30,97013 COMISO (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9523783",
    lng: "14.6031274",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009867",
  },
  {
    id: "38553",
    map_id: "1",
    address: "VIA MONTEGRAPPA, 13,74013 GINOSA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5750456",
    lng: "16.7552929",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008167",
  },
  {
    id: "38548",
    map_id: "1",
    address: "STR STATALE 106,88050 SELLIA MARINA (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.8983117",
    lng: "16.7396412",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020327",
  },
  {
    id: "38547",
    map_id: "1",
    address: "VIAOLE EUROPA 6,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7058014",
    lng: "14.4888231",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020322",
  },
  {
    id: "38546",
    map_id: "1",
    address: "VIA NAZIONALE 180,98040 TORREGROTTA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.2122156",
    lng: "15.3489878",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009098",
  },
  {
    id: "38545",
    map_id: "1",
    address: "VIA DI CASAL SELCE, 383/G 383/G,00166 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9152832",
    lng: "12.3516647",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005625",
  },
  {
    id: "38544",
    map_id: "1",
    address: "VIA ROMA 24,09043 MURAVERA (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.4192132",
    lng: "9.5817486",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002422",
  },
  {
    id: "38543",
    map_id: "1",
    address: "VIA SAN ROCCO 45,81030 CASTEL VOLTURNO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.0331675",
    lng: "13.9415792",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009124",
  },
  {
    id: "38541",
    map_id: "1",
    address: "VIA GAIO MELISSO 34,00175 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8661871",
    lng: "12.5507612",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008179",
  },
  {
    id: "38540",
    map_id: "1",
    address: "P.ZZA S. GIOVANNI BATTISTA 38,95014 GIARRE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.7342091",
    lng: "15.1577813",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008165",
  },
  {
    id: "38538",
    map_id: "1",
    address: "VIA SAFFI, 61,74016 MASSAFRA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5866618",
    lng: "17.116898",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009645",
  },
  {
    id: "38531",
    map_id: "1",
    address: "VIA ALEARDI 74/76,30172 VENEZIA (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.4871133",
    lng: "12.2403987",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020326",
  },
  {
    id: "38527",
    map_id: "1",
    address: "Via Eurialo, 122,00181 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8719151",
    lng: "12.52794",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012896",
  },
  {
    id: "38523",
    map_id: "1",
    address: "LARGO DEI TRECENTO 5,84073 SAPRI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.0739434",
    lng: "15.6224507",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020330",
  },
  {
    id: "38522",
    map_id: "1",
    address: "VIA G. MARCONI 103,95030 GRAVINA DI CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5605286",
    lng: "15.0647031",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004022",
  },
  {
    id: "38518",
    map_id: "1",
    address: "VIA GIROLAMO VITELLI 64,82037 TELESE TERME (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1216863",
    lng: "14.7749822",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008213",
  },
  {
    id: "38516",
    map_id: "1",
    address: "VIALE CADUTI SUL LAVORO SNC,01032 CAPRAROLA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "42.3214174",
    lng: "12.2335923",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008047",
  },
  {
    id: "38515",
    map_id: "1",
    address: "VIA GARIBALDI 25,67059 TRASACCO (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9558366",
    lng: "13.5348556",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001330",
  },
  {
    id: "38512",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 78,80021 AFRAGOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.9227223",
    lng: "14.3032014",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009695",
  },
  {
    id: "38510",
    map_id: "1",
    address: "VIA ETNA, 2,95034 BRONTE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.7902393",
    lng: "14.8392828",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008187",
  },
  {
    id: "38508",
    map_id: "1",
    address: "LUNGOMARE SIRENA 378,64018 TORTORETO (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.7952146",
    lng: "13.9467868",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008037",
  },
  {
    id: "38501",
    map_id: "1",
    address: "VIA ROMA 229,95019 ZAFFERANA ETNEA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.6905319",
    lng: "15.1055647",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009538",
  },
  {
    id: "38499",
    map_id: "1",
    address: "VIA CARDUCCI SNC,62019 RECANATI (MC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.4005692",
    lng: "13.5529836",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009648",
  },
  {
    id: "38498",
    map_id: "1",
    address: "VIA VIGNA, 32,84099 SAN CIPRIANO PICENTINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6996156",
    lng: "14.8700311",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009287",
  },
  {
    id: "38491",
    map_id: "1",
    address: "VIA ROMA 361/363,94010 GAGLIANO CASTELFERRATO (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.707497",
    lng: "14.5348326",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009696",
  },
  {
    id: "38490",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 228,55041 CAMAIORE (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.936639",
    lng: "10.307073",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009860",
  },
  {
    id: "38487",
    map_id: "1",
    address: "P.ZZA PRINCIPE DI PIEMONTE SNC,67048 ROCCA DI MEZZO (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "42.2063615",
    lng: "13.5196227",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008320",
  },
  {
    id: "38486",
    map_id: "1",
    address: "VIALE DELLA REGIONE 104,93013 MAZZARINO (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3009914",
    lng: "14.2141246",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-004011",
  },
  {
    id: "38481",
    map_id: "1",
    address: "LARGO ETTORE DI GIOVANNI 7,96100 SIRACUSA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.0842424",
    lng: "15.2854748",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008409",
  },
  {
    id: "38479",
    map_id: "1",
    address: "VIA ROMA 61/ 61a,00030 COLONNA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.8342111",
    lng: "12.7564537",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008218",
  },
  {
    id: "38476",
    map_id: "1",
    address: "VIA MATTIA PRETI 18,87023 DIAMANTE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.6782101",
    lng: "15.8209294",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008380",
  },
  {
    id: "38473",
    map_id: "1",
    address: "PIAZZA DEL POPOLO, 20/21,83100 AVELLINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9145487",
    lng: "14.79483",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001852",
  },
  {
    id: "38470",
    map_id: "1",
    address: "PIAZZA DELLA MINERVA 21,70027 PALO DEL COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0579459",
    lng: "16.7033802",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009162",
  },
  {
    id: "38467",
    map_id: "1",
    address: "VIA FLORA 11,70023 GIOIA DEL COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7981958",
    lng: "16.9219081",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009271",
  },
  {
    id: "38466",
    map_id: "1",
    address: "VIA DI S. IN CADORNA 57,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.9299708",
    lng: "14.7250828",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020318",
  },
  {
    id: "38463",
    map_id: "1",
    address: "VIA URBANO RELA 67/R,16152 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.4126264",
    lng: "8.8898004",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008250",
  },
  {
    id: "38461",
    map_id: "1",
    address: "VIA G. BESSARIONE 112,91026 MAZARA DEL VALLO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6579766",
    lng: "12.5777343",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000541",
  },
  {
    id: "38459",
    map_id: "1",
    address: "VIA TENENTE LIGNOLA 31,84010 CORBARA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7236774",
    lng: "14.5921102",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008287",
  },
  {
    id: "38458",
    map_id: "1",
    address: "VIA RUGGERO SETTIMO 140,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9484437",
    lng: "14.5340948",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020143",
  },
  {
    id: "38456",
    map_id: "1",
    address: "VIA FOSSA DEL LUPO 83/85,80144 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8901433",
    lng: "14.2662205",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009232",
  },
  {
    id: "38454",
    map_id: "1",
    address: "VIA T. PENDOLA 38 ROSSO,16143 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.4071969",
    lng: "8.9590169",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008339",
  },
  {
    id: "38450",
    map_id: "1",
    address: "VIA ETNEA 305,95030 MASCALUCIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5609574",
    lng: "15.0608315",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009775",
  },
  {
    id: "38443",
    map_id: "1",
    address: "LARGO GUGLIELMO MARCONI, 1,70010 VALENZANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0426582",
    lng: "16.8879599",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009075",
  },
  {
    id: "38438",
    map_id: "1",
    address: "VIA ARTURO LABRIOLA 15/17/19,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0832906",
    lng: "14.3324729",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020180",
  },
  {
    id: "38431",
    map_id: "1",
    address: "VIA DELLA LIBERTA', 8,88811 CIRO' MARINA (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.3684777",
    lng: "17.1305212",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009898",
  },
  {
    id: "38425",
    map_id: "1",
    address: "VIA CALTANISSETTA, 206,93011 BUTERA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.1935632",
    lng: "14.1826244",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008070",
  },
  {
    id: "38420",
    map_id: "1",
    address: "VIA GIANFILIPPO INGRASSIA 100,94017 REGALBUTO (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.6499745",
    lng: "14.6407858",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009224",
  },
  {
    id: "38417",
    map_id: "1",
    address: "P.ZZA BUGLIO, 27,95044 MINEO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.2647854",
    lng: "14.6909383",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009163",
  },
  {
    id: "38416",
    map_id: "1",
    address: "VIA F. PISPICO 9/A,73037 POGGIARDO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.0582869",
    lng: "18.3746784",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009379",
  },
  {
    id: "38414",
    map_id: "1",
    address: "VIA BAINSIZZA 26,74010 STATTE (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5639727",
    lng: "17.2080421",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009089",
  },
  {
    id: "38412",
    map_id: "1",
    address: "VIA ROMA 37,84070 SAN GIOVANNI A PIRO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "40.0504513",
    lng: "15.4496782",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009881",
  },
  {
    id: "38411",
    map_id: "1",
    address: "VIA RIO MAGGIORE SNC,01033 CIVITA CASTELLANA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.2920725",
    lng: "12.4077408",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020236",
  },
  {
    id: "38406",
    map_id: "1",
    address: "VIA NAZIONALE 33,84030 CASALBUONO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.2168659",
    lng: "15.6811635",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009177",
  },
  {
    id: "38405",
    map_id: "1",
    address: "VIA BONSIGNORE 40,91022 CASTELVETRANO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.677684",
    lng: "12.7935653",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009254",
  },
  {
    id: "38404",
    map_id: "1",
    address: "VIA NAPOLI 177,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9088415",
    lng: "14.265115",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009916",
  },
  {
    id: "38402",
    map_id: "1",
    address: "VIA SALVATOR ROSA 9,80020 CASAVATORE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8993171",
    lng: "14.2736134",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009193",
  },
  {
    id: "38400",
    map_id: "1",
    address: "VIALE EUROPA 8/10,92028 NARO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2869283",
    lng: "13.7991657",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009746",
  },
  {
    id: "38399",
    map_id: "1",
    address: "VIA DEL MACELLO 48,80143 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8635316",
    lng: "14.2945102",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008422",
  },
  {
    id: "38398",
    map_id: "1",
    address: "CORSO UMBERTO I 200,80016 MARANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.899931",
    lng: "14.186773",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003870",
  },
  {
    id: "38397",
    map_id: "1",
    address: "VIA DELLA LIBERTA’ 142,84015 NOCERA SUPERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7369381",
    lng: "14.6864447",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009234",
  },
  {
    id: "38386",
    map_id: "1",
    address: "VIA VARIANTE SNC,83036 MIRABELLA ECLANO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0436186",
    lng: "14.9958395",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009044",
  },
  {
    id: "38379",
    map_id: "1",
    address: "VIA MARIO PALERMO, 503,80147 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.86779",
    lng: "14.32478",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009100",
  },
  {
    id: "38376",
    map_id: "1",
    address: "CORSO SAN GIOVANNI 75,80146 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.838253",
    lng: "14.2990254",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008611",
  },
  {
    id: "38366",
    map_id: "1",
    address: "VIA DON ABELE 15,80017 MELITO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.910673",
    lng: "14.2681893",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008602",
  },
  {
    id: "38363",
    map_id: "1",
    address: "VIA CASTELLO BRANDOLA 45,41040 POLINAGO (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.333447",
    lng: "10.7396478",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020316",
  },
  {
    id: "38362",
    map_id: "1",
    address: "VIA DEL TORRACCIO DI TORRENOVA 93/D,00133 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8677908",
    lng: "12.6263809",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020234",
  },
  {
    id: "38360",
    map_id: "1",
    address: "PIAZZA MATTEOTTI 14/15,95018 RIPOSTO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.7267846",
    lng: "15.2066371",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000746",
  },
  {
    id: "38359",
    map_id: "1",
    address: "Via del Corso, 4,83048 MONTELLA (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8421315",
    lng: "15.018411",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001148",
  },
  {
    id: "38357",
    map_id: "1",
    address: "PIAZZA SAN ROCCO 12,80070 BARANO D'ISCHIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7088378",
    lng: "13.9191992",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009005",
  },
  {
    id: "38356",
    map_id: "1",
    address: "VIA REBOCCO 71,19123 LA SPEZIA (SP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.1106077",
    lng: "9.802241",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003396",
  },
  {
    id: "38354",
    map_id: "1",
    address: "VIA TOMMASO CANNIZZARO 259,98152 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.1913204",
    lng: "15.5465124",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011272",
  },
  {
    id: "38350",
    map_id: "1",
    address: "VIA SARDEGNA 41,09032 ASSEMINI (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "39.2881868",
    lng: "9.0101731",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009181",
  },
  {
    id: "38349",
    map_id: "1",
    address: "Corso Vittorio Emanuele, 33,83057 TORELLA DEI LOMBARDI (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9393185",
    lng: "15.1137673",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003400",
  },
  {
    id: "38342",
    map_id: "1",
    address: "VIA MONTE PEGLIA 8,05018 ORVIETO (TR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.7215877",
    lng: "12.135672",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009217",
  },
  {
    id: "38341",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 59,74019 PALAGANO (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0632936",
    lng: "7.6765269",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020174",
  },
  {
    id: "38340",
    map_id: "1",
    address: "CORSO VERCELLI 67/B,10155 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.0902927",
    lng: "7.6887925",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020136",
  },
  {
    id: "38338",
    map_id: "1",
    address: "Via S. Teresa degli Scalzi, 104,80135 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8561391",
    lng: "14.2485981",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010470",
  },
  {
    id: "38337",
    map_id: "1",
    address: "Via Gaetano Nunziante, 36,84087 SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8091608",
    lng: "14.6249199",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006494",
  },
  {
    id: "38331",
    map_id: "1",
    address: "VIA FLAMINIA KM. 189,06023 GUALDO TADINO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.2340833",
    lng: "12.7772368",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009576",
  },
  {
    id: "38330",
    map_id: "1",
    address: "Viale Europa 7,65015 MONTESILVANO (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.5129226",
    lng: "14.1551609",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009301",
  },
  {
    id: "38329",
    map_id: "1",
    address: "VIA FRANCESCO SOLIMENA 111,80129 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8444923",
    lng: "14.2289506",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006728",
  },
  {
    id: "38328",
    map_id: "1",
    address: "VIA PALAZZI 30,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.072918",
    lng: "14.2344218",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009311",
  },
  {
    id: "38326",
    map_id: "1",
    address: "VIA GRAMSCI, 43 G,96013 CARLENTINI (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2969354",
    lng: "15.007718",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008319",
  },
  {
    id: "38324",
    map_id: "1",
    address: "CORSO SALVATORE ALDISIO 208,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.0700161",
    lng: "14.239878",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009221",
  },
  {
    id: "38321",
    map_id: "1",
    address: "Via Giovanni Bessarione 45,91026 MAZARA DEL VALLO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6562322",
    lng: "12.580783",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009078",
  },
  {
    id: "38319",
    map_id: "1",
    address: "LARGO F. LOMONACO 3,75023 MONTALBANO JONICO (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.2908459",
    lng: "16.5653308",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009226",
  },
  {
    id: "38318",
    map_id: "1",
    address: "CONTRADA CALDA, SNC,85043 LATRONICO (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.0882152",
    lng: "15.9772044",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011749",
  },
  {
    id: "38314",
    map_id: "1",
    address: "VIA POMPEI 13,70024 GRAVINA IN PUGLIA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8156224",
    lng: "16.4202582",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001000",
  },
  {
    id: "38312",
    map_id: "1",
    address: "VIA CAVOUR 10,90040 CAPACI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.1716209",
    lng: "13.2403181",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009150",
  },
  {
    id: "38310",
    map_id: "1",
    address: "Via Benedetto Cairoli, 6,83054 SANT'ANGELO DEI LOMBARDI (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.928165",
    lng: "15.177375",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005692",
  },
  {
    id: "38309",
    map_id: "1",
    address: "VIA AGRIGENTO 2,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6637678",
    lng: "14.8260452",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009053",
  },
  {
    id: "38307",
    map_id: "1",
    address: "VIA RODI 2A,72025 SAN DONACI (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4501738",
    lng: "17.9270354",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009641",
  },
  {
    id: "38306",
    map_id: "1",
    address: "VIA DEI PATRIOTI 59,21058 SOLBIATE OLONA (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6532897",
    lng: "8.8837372",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020205",
  },
  {
    id: "38305",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 175,94010 NISSORIA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.6552161",
    lng: "14.4485433",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009165",
  },
  {
    id: "38303",
    map_id: "1",
    address: "C.DA GIARDINELLI SNC,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0663105",
    lng: "14.2472703",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009026",
  },
  {
    id: "38301",
    map_id: "1",
    address: "CORSO LUCILIO 111,81037 SESSA AURUNCA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2361067",
    lng: "13.9328596",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008359",
  },
  {
    id: "38300",
    map_id: "1",
    address: "VIA AURELIO FIERRO 60,84099 SAN CIPRIANO PICENTINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6833768",
    lng: "14.8722636",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003841",
  },
  {
    id: "38299",
    map_id: "1",
    address: "VIA NAZIONALE 221,89040 MONASTERACE (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.4770135",
    lng: "16.5785322",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012620",
  },
  {
    id: "38297",
    map_id: "1",
    address: "Via Rodolfo Morandi, 5,06012 CITTA' DI CASTELLO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "43.4700084",
    lng: "12.2287984",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-011787",
  },
  {
    id: "38296",
    map_id: "1",
    address: "VIA SETTEVALLI 127,06129 PERUGIA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.09724",
    lng: "12.37105",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-011786",
  },
  {
    id: "38294",
    map_id: "1",
    address: "VIA SIBILLA 55,91025 MARSALA (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.799368",
    lng: "12.429972",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020197",
  },
  {
    id: "38292",
    map_id: "1",
    address: "VIA SAMUELE ARDILLO 28,70010 VALENZANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0475373",
    lng: "16.8874914",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009198",
  },
  {
    id: "38288",
    map_id: "1",
    address: "Via Campagnatico, 5 C/O BOX MERCATO COPERTO,00148 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8432346",
    lng: "12.4371634",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012042",
  },
  {
    id: "38286",
    map_id: "1",
    address: "VIA DEI MESTIERI 103,06049 SPOLETO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "42.7402548",
    lng: "12.7079912",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004660",
  },
  {
    id: "38284",
    map_id: "1",
    address: "CORSO MESSINA 110,88900 CROTONE (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.0801458",
    lng: "17.133805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020305",
  },
  {
    id: "38282",
    map_id: "1",
    address: "PZA OTTAVILLA 5/D,00152 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8832408",
    lng: "12.4561053",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004389",
  },
  {
    id: "38280",
    map_id: "1",
    address: "VIA CAMILLO CAVOUR 63/A,07100 SASSARI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.7224097",
    lng: "8.5650667",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008214",
  },
  {
    id: "38279",
    map_id: "1",
    address: "VIALE EUROPA 410,90036 MISILMERI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0306497",
    lng: "13.45",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009024",
  },
  {
    id: "38272",
    map_id: "1",
    address: "VIA MEZZOCOLLE,13,25015 DESENZANO DEL GARDA (BS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "45.4681139",
    lng: "10.5334472",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009841",
  },
  {
    id: "38270",
    map_id: "1",
    address: "VIA NAZIONALE 70,87010 SAN SOSTI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.660198",
    lng: "16.027988",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020312",
  },
  {
    id: "38269",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI 56,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9250483",
    lng: "14.2050242",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020051",
  },
  {
    id: "38268",
    map_id: "1",
    address: "C.SO ACHILLE SPATUZZI 140,03047 SAN GIORGIO A LIRI (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.4057135",
    lng: "13.7634545",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008262",
  },
  {
    id: "38265",
    map_id: "1",
    address: "VIA ANGELA SALA, 37,72028 TORRE SANTA SUSANNA (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4629172",
    lng: "17.7389213",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008220",
  },
  {
    id: "38264",
    map_id: "1",
    address: "VIALE GIOSTRA SNC,98152 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "38.2097984",
    lng: "15.5512644",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009761",
  },
  {
    id: "38259",
    map_id: "1",
    address: "DUCA D'AOSTA 40,64011 ALBA ADRIATICA (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.8319254",
    lng: "13.9232357",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008716",
  },
  {
    id: "38257",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI, 12,06024 GUBBIO (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.3526525",
    lng: "12.5671311",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011788",
  },
  {
    id: "38253",
    map_id: "1",
    address: "PIAZZA VITTORIO VENETO, 1,06124 PERUGIA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "43.1041852",
    lng: "12.376772",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012777",
  },
  {
    id: "38252",
    map_id: "1",
    address: "PIAZZA MATTEOTTI 61,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0754987",
    lng: "14.336614",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008043",
  },
  {
    id: "38247",
    map_id: "1",
    address: "VIA SABATO ROBERTELLI 14,84127 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.6713586",
    lng: "14.7842537",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009013",
  },
  {
    id: "38244",
    map_id: "1",
    address: "VIA CONTE MIRABELLI 109/11,80012 CALVIZZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9068979",
    lng: "14.1887184",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008553",
  },
  {
    id: "38241",
    map_id: "1",
    address: "VIA VALERICO LACCETTI 26,00155 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9091263",
    lng: "12.6131513",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020001",
  },
  {
    id: "38238",
    map_id: "1",
    address: "VIA CARAVAGGIO 9,92016 RIBERA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4352092",
    lng: "13.2372372",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003631",
  },
  {
    id: "38233",
    map_id: "1",
    address: "VIA SOCRATE 9,87041 ACRI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.4888128",
    lng: "16.381203",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020292",
  },
  {
    id: "38231",
    map_id: "1",
    address: "VIA G. IMBRODA 236,80035 NOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9229304",
    lng: "14.5315934",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008452",
  },
  {
    id: "38228",
    map_id: "1",
    address: "VIA UNITA' D'ITALIA, 65,94100 ENNA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.5541943",
    lng: "14.2892305",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008177",
  },
  {
    id: "38225",
    map_id: "1",
    address: "VIA PIAGGE MARINE SNC,04018 SEZZE (LT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.4977329",
    lng: "13.0630233",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009168",
  },
  {
    id: "38223",
    map_id: "1",
    address: "VIA CANNAVINA, SNC,82020 PIETRELCINA (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2010116",
    lng: "14.8474102",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008027",
  },
  {
    id: "38222",
    map_id: "1",
    address: "VIA FABRIZI 75,96010 PRIOLO GARGALLO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.1594",
    lng: "15.18708",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009763",
  },
  {
    id: "38219",
    map_id: "1",
    address: "VIA ROMA 152,80029 SANT'ANTIMO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9349569",
    lng: "14.2352982",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008019",
  },
  {
    id: "38218",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 61,31055 QUINTO DI TREVISO (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6455799",
    lng: "12.1679799",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012392",
  },
  {
    id: "38216",
    map_id: "1",
    address: "VIA SENATORE SAMMARTINO 5,92024 CANICATTI' (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3498604",
    lng: "13.8379598",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009054",
  },
  {
    id: "38210",
    map_id: "1",
    address: "VIA LIBERTA' 22/A,90038 PRIZZI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "37.7209368",
    lng: "13.4378584",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009255",
  },
  {
    id: "38208",
    map_id: "1",
    address: "PIAZZA REGINA ELENA 18,84073 SAPRI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.075012",
    lng: "15.6257931",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009608",
  },
  {
    id: "38205",
    map_id: "1",
    address: "CORSO RISORGIMENTO 63,86170 ISERNIA (IS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.5955356",
    lng: "14.236407",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008084",
  },
  {
    id: "38204",
    map_id: "1",
    address: "VIA MICHELE MITOLO, 17/B 17/B,70124 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.091479",
    lng: "16.860513",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020228",
  },
  {
    id: "38202",
    map_id: "1",
    address: "PIAZZA CONTE FILO DELLA TORRE 14,80030 CIMITILE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9399621",
    lng: "14.5267839",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009518",
  },
  {
    id: "38201",
    map_id: "1",
    address: "VIA PASCOLI 64,03043 CASSINO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.4894397",
    lng: "13.833858",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003274",
  },
  {
    id: "38200",
    map_id: "1",
    address: "VIA SOTTO IL MONTE 2/B,80078 POZZUOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.838321",
    lng: "14.0959695",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020215",
  },
  {
    id: "38197",
    map_id: "1",
    address: "VIA SAMPERI GAETANO 220,93015 NISCEMI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.146553",
    lng: "14.3893346",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008402",
  },
  {
    id: "38195",
    map_id: "1",
    address: "VIA CARDUCCI 150,65010 CAPPELLE SUL TAVO (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "42.4747725",
    lng: "14.1051856",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009499",
  },
  {
    id: "38186",
    map_id: "1",
    address: "VIA SAN ROCCO 171,80016 MARANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8996761",
    lng: "14.174348",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012785",
  },
  {
    id: "38175",
    map_id: "1",
    address: "Viale Nicolò Arnaldi 35,00019 TIVOLI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9585349",
    lng: "12.7943705",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003228",
  },
  {
    id: "38173",
    map_id: "1",
    address: "VIA NAPOLI 201,81059 VAIRANO PATENORA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.3146838",
    lng: "14.1080735",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008025",
  },
  {
    id: "38172",
    map_id: "1",
    address: "VIA ARMANDO DIAZ 44,95040 CAMPOROTONDO ETNEO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5654227",
    lng: "15.0037163",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005205",
  },
  {
    id: "38171",
    map_id: "1",
    address: "VIA ALDO MORO 97,82018 SAN GIORGIO DEL SANNIO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.0665208",
    lng: "14.8555619",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008017",
  },
  {
    id: "38170",
    map_id: "1",
    address: "CORSO DELLA REPUBBLICA 106,03043 CASSINO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4908061",
    lng: "13.8325131",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020123",
  },
  {
    id: "38163",
    map_id: "1",
    address: "VIA GIULIO PALERMO 36,80131 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.861129",
    lng: "14.221335",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003859",
  },
  {
    id: "38160",
    map_id: "1",
    address: "VIA FUCILARI 91,84014 NOCERA INFERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.7421549",
    lng: "14.6443312",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008088",
  },
  {
    id: "38153",
    map_id: "1",
    address: "CORSO ODIERNA 510,92020 PALMA DI MONTECHIARO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1905957",
    lng: "13.7670022",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008016",
  },
  {
    id: "38150",
    map_id: "1",
    address: "VIA A. MORO 267,88821 ROCCA DI NETO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.1805909",
    lng: "17.0105331",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008186",
  },
  {
    id: "38146",
    map_id: "1",
    address: "PIAZZA DELLA REPUBBLICA SNC,86170 ISERNIA (IS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.597406",
    lng: "14.235519",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008015",
  },
  {
    id: "38145",
    map_id: "1",
    address: "VIA ANGELO CAMILLO DE MEIS 66,80147 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.851574",
    lng: "14.3335387",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008051",
  },
  {
    id: "38143",
    map_id: "1",
    address: "VIA A. DIAZ 10,70028 SANNICANDRO DI BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0012227",
    lng: "16.7970565",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008712",
  },
  {
    id: "38142",
    map_id: "1",
    address: "VIA SAN FRANCESCO 52,82018 SAN GIORGIO DEL SANNIO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0624753",
    lng: "14.8518005",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020287",
  },
  {
    id: "38136",
    map_id: "1",
    address: "VIA STAZIONE, 2,81034 MONDRAGONE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "41.1144127",
    lng: "13.9082024",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008024",
  },
  {
    id: "38134",
    map_id: "1",
    address: "VIA PALERMO 41,91100 TRAPANI (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0189428",
    lng: "12.5444131",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012233",
  },
  {
    id: "38131",
    map_id: "1",
    address: "via ROMA 215,81030 TEVEROLA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9983725",
    lng: "14.2079326",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008036",
  },
  {
    id: "38129",
    map_id: "1",
    address: "VIA NAPOLI 18,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9155077",
    lng: "14.2671225",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009553",
  },
  {
    id: "38124",
    map_id: "1",
    address: "VIA GRAMSCI 22/A,87060 CROSIA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.6025913",
    lng: "16.7828235",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005738",
  },
  {
    id: "38123",
    map_id: "1",
    address: "VIA EDMONDO DE AMICIS 51/53,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4959064",
    lng: "14.0523934",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009623",
  },
  {
    id: "38122",
    map_id: "1",
    address: "VIA DELLA LIBERAZIONE 66,20067 TRIBIANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE- SPID",
    lat: "45.4145772",
    lng: "9.375885",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008702",
  },
  {
    id: "38118",
    map_id: "1",
    address: "PIAZZA GUALFREDOTTO DA MILANO 1/R,50126 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7622983",
    lng: "11.2862139",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001524",
  },
  {
    id: "38114",
    map_id: "1",
    address: "VIA G. MODUGNO, 77,70124 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1061567",
    lng: "16.8632313",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009651",
  },
  {
    id: "38112",
    map_id: "1",
    address: "CORSO UMBERTO 97,81033 CASAL DI PRINCIPE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.0062556",
    lng: "14.1309552",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020300",
  },
  {
    id: "38111",
    map_id: "1",
    address: "PIAZZA GOMES SNC,80055 PORTICI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.81965",
    lng: "14.33681",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003771",
  },
  {
    id: "38110",
    map_id: "1",
    address: "VIA DEI PINI SNC,00019 TIVOLI (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9554744",
    lng: "12.8021334",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008068",
  },
  {
    id: "38107",
    map_id: "1",
    address: "VIA CITTÀ DI PRATO 13/15,00146 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8459421",
    lng: "12.4574583",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009318",
  },
  {
    id: "38105",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI 59,56037 PECCIOLI (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "43.5483364",
    lng: "10.7153478",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020304",
  },
  {
    id: "38104",
    map_id: "1",
    address: "CORSO REGINA MARGHERITA 195/F,10144 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0823554",
    lng: "7.6658684",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020178",
  },
  {
    id: "38103",
    map_id: "1",
    address: "Via Sant'Antonio, 30 rosso,50129 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7758745",
    lng: "11.2528012",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020247",
  },
  {
    id: "38097",
    map_id: "1",
    address: "VIA ROCCO COCCHIA, 199/201,84132 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6644223",
    lng: "14.8024669",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008069",
  },
  {
    id: "38095",
    map_id: "1",
    address: "VIA ROMA 70,70021 ACQUAVIVA DELLE FONTI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8938593",
    lng: "16.8421864",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008062",
  },
  {
    id: "38094",
    map_id: "1",
    address: "VIA CROCE MALLONI 47/49,84015 NOCERA SUPERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.748395",
    lng: "14.683395",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009859",
  },
  {
    id: "38093",
    map_id: "1",
    address: "CORSO UMBERTO I 336,73056 TAURISANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.9541957",
    lng: "18.2150631",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009341",
  },
  {
    id: "38092",
    map_id: "1",
    address: "VIA MARCO TULLIO CICERONE 138,03100 FROSINONE (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6488713",
    lng: "13.3418534",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020296",
  },
  {
    id: "38090",
    map_id: "1",
    address: "VIA STRANO 26,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5672922",
    lng: "14.9045151",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008645",
  },
  {
    id: "38089",
    map_id: "1",
    address: "VIA RASA LENDINARA 1,45026 LENDINARA (RO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0817354",
    lng: "11.5648984",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020079",
  },
  {
    id: "38088",
    map_id: "1",
    address: "PIAZZA IV NOVEMBRE 6,83038 MONTEMILETTO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0118986",
    lng: "14.9080818",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002345",
  },
  {
    id: "38087",
    map_id: "1",
    address: "VIALE IV NOVEMBRE 68,94100 ENNA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.5610914",
    lng: "14.2731895",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-002457",
  },
  {
    id: "38085",
    map_id: "1",
    address: "VIA SICULO ORIENTALE 191,95016 MASCALI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.7512939",
    lng: "15.193508",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003654",
  },
  {
    id: "38084",
    map_id: "1",
    address: "VIA ALFREDO PECCHIA 77,80022 ARZANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9140052",
    lng: "14.2689605",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006488",
  },
  {
    id: "38083",
    map_id: "1",
    address: "VIA SPARTACO 27,40138 BOLOGNA (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.4898541",
    lng: "11.3771727",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-008789",
  },
  {
    id: "38082",
    map_id: "1",
    address: "VIALE EINAUDI 85,90146 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "38.1792377",
    lng: "13.3115541",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000543",
  },
  {
    id: "38074",
    map_id: "1",
    address: "PIAZZA UMBERTO I 33,73037 POGGIARDO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.0537927",
    lng: "18.3770934",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009259",
  },
  {
    id: "38065",
    map_id: "1",
    address: "PZA PRINCIPE DI PIEMONTE SNC,81050 CAMIGLIANO (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID - ENEL ENERGIA",
    lat: "41.1815185",
    lng: "14.2120672",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009827",
  },
  {
    id: "38062",
    map_id: "1",
    address: "VIA RICCITELLI 6,64100 TERAMO (TE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.6603992",
    lng: "13.6969072",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008041",
  },
  {
    id: "38061",
    map_id: "1",
    address: "VIA TOGLIATTI SNC,88811 CIRO' MARINA (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.364608",
    lng: "17.1282898",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020253",
  },
  {
    id: "38059",
    map_id: "1",
    address: "VIA GIACOMO MATTEOTTI, 4,93011 BUTERA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.1875413",
    lng: "14.185058",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009113",
  },
  {
    id: "38057",
    map_id: "1",
    address: "VIA FRATELLI ASCOLESE 22,84084 FISCIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.781797",
    lng: "14.8022157",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020250",
  },
  {
    id: "38054",
    map_id: "1",
    address: "VIA CASA ROSA 56,80063 PIANO DI SORRENTO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6345883",
    lng: "14.4119107",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001801",
  },
  {
    id: "38052",
    map_id: "1",
    address: "VIALE DELLA REPUBBLICA 50/52,70016 NOICATTARO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.0374952",
    lng: "16.9899317",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009801",
  },
  {
    id: "38045",
    map_id: "1",
    address: "VIA GAETA 283,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9555635",
    lng: "14.5317775",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001236",
  },
  {
    id: "38042",
    map_id: "1",
    address: "Via Cavallotti n. 21,71037 MONTE SANT'ANGELO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.706306",
    lng: "15.959278",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-003536",
  },
  {
    id: "38041",
    map_id: "1",
    address: "VIA SENESE 291/A,50124 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7370698",
    lng: "11.2247929",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009758",
  },
  {
    id: "38035",
    map_id: "1",
    address: "VIA CARNAZZA 26,95030 TREMESTIERI ETNEO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.540356",
    lng: "15.0927549",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020223",
  },
  {
    id: "38032",
    map_id: "1",
    address: "VIA DELL'INDUSTRIA 1070,58022 FOLLONICA (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.9346494",
    lng: "10.7918827",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020252",
  },
  {
    id: "38031",
    map_id: "1",
    address: "CORSO UMBERTO I 255,90023 CIMINNA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.8985268",
    lng: "13.556967",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000416",
  },
  {
    id: "38030",
    map_id: "1",
    address: "FRAZIONE GLASSIER 1,11010 AYMAVILLES (AO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "45.7001426",
    lng: "7.2393034",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010035",
  },
  {
    id: "38028",
    map_id: "1",
    address: "VIA SILVIO BARATTA 27,84127 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6777226",
    lng: "14.7759805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008089",
  },
  {
    id: "38027",
    map_id: "1",
    address: "CORSO ITALIA 2,95039 TRECASTAGNI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6179293",
    lng: "15.0789375",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001063",
  },
  {
    id: "38024",
    map_id: "1",
    address: "VIA CARMINE CALO' 17,83040 GESUALDO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0078638",
    lng: "15.0717684",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001839",
  },
  {
    id: "38022",
    map_id: "1",
    address: "VIA A. DI SANGIULIANO 55,95030 SANT'AGATA LI BATTIATI (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5550842",
    lng: "15.0745745",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020226",
  },
  {
    id: "38021",
    map_id: "1",
    address: "VIA DANILO STIEPOVICH 125/127,00121 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7396865",
    lng: "12.270046",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020225",
  },
  {
    id: "38019",
    map_id: "1",
    address: "VIA GIUSEPPE PAGANO 3/A,34149 TRIESTE (TS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6268508",
    lng: "13.8052113",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011488",
  },
  {
    id: "38017",
    map_id: "1",
    address: "VIA ORVIETANA 30/A,01024 CASTIGLIONE IN TEVERINA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH - ENEL ENERGIA",
    lat: "42.650432",
    lng: "12.202744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020297",
  },
  {
    id: "38015",
    map_id: "1",
    address: "C.SO XXV APRILE 49,21016 LUINO (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.99919",
    lng: "8.7418161",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008274",
  },
  {
    id: "38009",
    map_id: "1",
    address: "PIAZZA DEI ROSSI 3/R,50125 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.766884",
    lng: "11.2529327",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009932",
  },
  {
    id: "38008",
    map_id: "1",
    address: "VIA DOTT. SALVATORE LA MALFA, 50,94015 PIAZZA ARMERINA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.3863477",
    lng: "14.3690419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-002602",
  },
  {
    id: "38005",
    map_id: "1",
    address: "VIALE GUGLIELMO MARCONI 113/A,85100 POTENZA (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "40.6351106",
    lng: "15.8052261",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020237",
  },
  {
    id: "38002",
    map_id: "1",
    address: "VIA MAZZINI 1,14043 CASTELNUOVO BELBO (AT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.8013101",
    lng: "8.4105728",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020267",
  },
  {
    id: "38001",
    map_id: "1",
    address: "CORSO FILANGIERI 86,80069 VICO EQUENSE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6630094",
    lng: "14.4258283",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010004",
  },
  {
    id: "37995",
    map_id: "1",
    address: "VIA S. DOMENICO 4,67062 MAGLIANO DE' MARSI (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "42.0920608",
    lng: "13.3605472",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009664",
  },
  {
    id: "37992",
    map_id: "1",
    address: "VIA NAZIONALE 126,94018 TROINA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.7837599",
    lng: "14.5953348",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009852",
  },
  {
    id: "37989",
    map_id: "1",
    address: "VIA RISORGIMENTO 34,84062 OLEVANO SUL TUSCIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6558358",
    lng: "15.0224087",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008035",
  },
  {
    id: "37986",
    map_id: "1",
    address: "VIA VIRGILIO 171 B,70019 TRIGGIANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0609598",
    lng: "16.9239684",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009638",
  },
  {
    id: "37985",
    map_id: "1",
    address: "VIA DELLA LIBERTA' 72,72022 LATIANO (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.5530607",
    lng: "17.7260103",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001525",
  },
  {
    id: "37983",
    map_id: "1",
    address: "VIA MATTEO RIPA,84025 EBOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.61827",
    lng: "15.0548946",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008073",
  },
  {
    id: "37982",
    map_id: "1",
    address: "VIA MINCIO 14,05100 TERNI (TR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.5473708",
    lng: "12.6072876",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000182",
  },
  {
    id: "37981",
    map_id: "1",
    address: "VIALE DELLA VITTORIA 15/17,81025 MARCIANISE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0297721",
    lng: "14.2999741",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008045",
  },
  {
    id: "37978",
    map_id: "1",
    address: "VIALE SIRIS 99,75020 NOVA SIRI (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.1315275",
    lng: "16.6371419",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008131",
  },
  {
    id: "37972",
    map_id: "1",
    address: "VIA VOLTURNO 63/65,90138 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1196689",
    lng: "13.3540405",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010889",
  },
  {
    id: "37971",
    map_id: "1",
    address: "VIA CASTEL BELVEDERE 116,80016 MARANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9022523",
    lng: "14.1355511",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005380",
  },
  {
    id: "37970",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 17,95028 VALVERDE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.5788434",
    lng: "15.1242311",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008718",
  },
  {
    id: "37967",
    map_id: "1",
    address: "VIA TESORO, 8,88841 ISOLA DI CAPO RIZZUTO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.9580378",
    lng: "17.0946535",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008152",
  },
  {
    id: "37964",
    map_id: "1",
    address: "PIAZZA VITTORIO VENETO,84073 SAPRI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.0776989",
    lng: "15.6276632",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008072",
  },
  {
    id: "37957",
    map_id: "1",
    address: "C.SO EUROPA 16,80039 SAVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9080482",
    lng: "14.5075764",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008475",
  },
  {
    id: "37956",
    map_id: "1",
    address: "VIA EDOARDO DE FILIPPIS 110,84013 CAVA DE' TIRRENI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.705992",
    lng: "14.709175",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020227",
  },
  {
    id: "37955",
    map_id: "1",
    address: "VIA ANIELLO PALUMBO 106,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9262563",
    lng: "14.1984388",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020077",
  },
  {
    id: "37953",
    map_id: "1",
    address: "VIALE STRASBURGO 444,90146 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1591207",
    lng: "13.3243127",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020275",
  },
  {
    id: "37951",
    map_id: "1",
    address: "VIA DI TORREVECCHIA 128,00168 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9335925",
    lng: "12.4192838",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009108",
  },
  {
    id: "37950",
    map_id: "1",
    address: "VLE XXIV MAGGIO 114,71042 CERIGNOLA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.2714667",
    lng: "15.8946473",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020114",
  },
  {
    id: "37947",
    map_id: "1",
    address: "VIA ODERISI DAGUBBIO 240/242,00146 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.8635411",
    lng: "12.4641722",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008192",
  },
  {
    id: "37945",
    map_id: "1",
    address: "VIALE ANTIUM 6 INT. 29,00042 ANZIO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.4625597",
    lng: "12.6275075",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008455",
  },
  {
    id: "37942",
    map_id: "1",
    address: "CORSO PIETRO ASCOLESE 369,83025 MONTORO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8072386",
    lng: "14.7725686",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006681",
  },
  {
    id: "37941",
    map_id: "1",
    address: "CSO VITTORIO EMANUELE 81,85038 SENISE (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.1436883",
    lng: "16.2886485",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020238",
  },
  {
    id: "37940",
    map_id: "1",
    address: "VIA MUNICIPIO 1/A,72021 FRANCAVILLA FONTANA (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5323672",
    lng: "17.5840782",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004990",
  },
  {
    id: "37932",
    map_id: "1",
    address: "VIALE DEGLI ARTIGIANI, 44/Q,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4721578",
    lng: "15.5570848",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008243",
  },
  {
    id: "37926",
    map_id: "1",
    address: "VIA BENEDETTO GRAVINA 91,90139 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1263143",
    lng: "13.3588066",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008517",
  },
  {
    id: "37925",
    map_id: "1",
    address: "VIA G. LEOPARDI 2,20833 GIUSSANO (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE - ENEL ENERGIA",
    lat: "45.6831461",
    lng: "9.204539",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008563",
  },
  {
    id: "37920",
    map_id: "1",
    address: "VIA FILIPPO NICOLAI 191,01032 CAPRAROLA (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "42.3243192",
    lng: "12.2424577",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-009975",
  },
  {
    id: "37909",
    map_id: "1",
    address: "VIA TIBURTINA 656,00159 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9105102",
    lng: "12.5496577",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020243",
  },
  {
    id: "37907",
    map_id: "1",
    address: "VIA PASSANTI 71,80047 SAN GIUSEPPE VESUVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8265929",
    lng: "14.5044055",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004184",
  },
  {
    id: "37905",
    map_id: "1",
    address: "C.DA ANAMIA S.S. 417 KM 66 +449,95040 RAMACCA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3451077",
    lng: "14.6920139",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020172",
  },
  {
    id: "37902",
    map_id: "1",
    address: "VIA LORETO 21,90010 ALTAVILLA MILICIA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.0432285",
    lng: "13.5514766",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020083",
  },
  {
    id: "37901",
    map_id: "1",
    address: "Via Bachelet, snc,82100 BENEVENTO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1210706",
    lng: "14.8022703",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020276",
  },
  {
    id: "37885",
    map_id: "1",
    address: "VIA G. AMICO VALENTI 27-29,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4961202",
    lng: "14.0589737",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008031",
  },
  {
    id: "37880",
    map_id: "1",
    address: "VIA CALLIPOLI 262,95014 GIARRE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.7258996",
    lng: "15.1844403",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-008023",
  },
  {
    id: "37870",
    map_id: "1",
    address: "VIA NAZIONALE 82,87064 CORIGLIANO-ROSSANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.6279224",
    lng: "16.5138765",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020272",
  },
  {
    id: "37868",
    map_id: "1",
    address: "VIA GARIBALDI 45,09040 MARACALAGONIS (CA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.2853049",
    lng: "9.2304929",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005768",
  },
  {
    id: "37864",
    map_id: "1",
    address: "VIA SAN VITO 153,80056 ERCOLANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8244367",
    lng: "14.3726194",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020249",
  },
  {
    id: "37855",
    map_id: "1",
    address: "VIA DEGLI STADI 99,87100 COSENZA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.3093206",
    lng: "16.2333242",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007499",
  },
  {
    id: "37852",
    map_id: "1",
    address: "VIA LUIGI RIZZO 53,00136 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.9084122",
    lng: "12.4456572",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020030",
  },
  {
    id: "37849",
    map_id: "1",
    address: "VIA CAPPUCCINI 91,73044 GALATONE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.1447854",
    lng: "18.0695683",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020241",
  },
  {
    id: "37839",
    map_id: "1",
    address: "VIA ACQUAVIVA 223,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0618817",
    lng: "14.3331895",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001859",
  },
  {
    id: "37838",
    map_id: "1",
    address: "PIAZZA ORIFICI 4,90014 CASTELDACCIA (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.0527718",
    lng: "13.5292396",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000119",
  },
  {
    id: "37837",
    map_id: "1",
    address: "VIA DONAUDI 12,12037 SALUZZO (CN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.64688",
    lng: "7.48946",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020271",
  },
  {
    id: "37836",
    map_id: "1",
    address: "VIA DEI CHIUSI 3,20060 VIGNATE (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.4965192",
    lng: "9.3741162",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002418",
  },
  {
    id: "37835",
    map_id: "1",
    address: "VIA TRIESTE 4,07041 ALGHERO (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5917979",
    lng: "8.2845297",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011433",
  },
  {
    id: "37833",
    map_id: "1",
    address: "Via Rimembranze n. 57a,89015 PALMI (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "38.3556853",
    lng: "15.8471328",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-001431",
  },
  {
    id: "37814",
    map_id: "1",
    address: "VIA CORNUDA 109,31010 MASER (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.8180082",
    lng: "11.9874293",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010827",
  },
  {
    id: "37787",
    map_id: "1",
    address: "VIA ADUA 46,71013 SAN GIOVANNI ROTONDO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.7054919",
    lng: "15.7315998",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020251",
  },
  {
    id: "37784",
    map_id: "1",
    address: "VLE ALCIDE DE GASPERI 157,95126 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5208075",
    lng: "15.1092018",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002736",
  },
  {
    id: "37781",
    map_id: "1",
    address: "VIALE APPIO CLAUDIO 238,00174 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8527547",
    lng: "12.5618269",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011948",
  },
  {
    id: "37780",
    map_id: "1",
    address: "VIA CARLO LINNEO 328/A,16159 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.4477586",
    lng: "8.9076226",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010020",
  },
  {
    id: "37778",
    map_id: "1",
    address: "VIA GARIBALDI 31,20092 CINISELLO BALSAMO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5561127",
    lng: "9.2116568",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002914",
  },
  {
    id: "37760",
    map_id: "1",
    address: "LARGO SAN FRANCESCO SN,65017 PENNE (PE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.4563089",
    lng: "13.9300109",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020256",
  },
  {
    id: "37759",
    map_id: "1",
    address: "VIA SAN ROCCO 38,66054 VASTO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.1246932",
    lng: "14.7045333",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005910",
  },
  {
    id: "37758",
    map_id: "1",
    address: "VIA G. COSENZA 164,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7018857",
    lng: "14.4914445",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009809",
  },
  {
    id: "37757",
    map_id: "1",
    address: "VIA S. BERNARDETTE 25,98070 CAPRI LEONE (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1095423",
    lng: "14.7155689",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001578",
  },
  {
    id: "37749",
    map_id: "1",
    address: "VIA G.D'ALESSANDRIA 2/4,89900 VIBO VALENTIA (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.6748194",
    lng: "16.1029989",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007187",
  },
  {
    id: "37747",
    map_id: "1",
    address: "VIA COLA DI RIENZO 44,20144 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4551628",
    lng: "9.1563781",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010984",
  },
  {
    id: "37746",
    map_id: "1",
    address: "PIAZZA A. DE GASPERI 4/5,83040 LUOGOSANO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9857579",
    lng: "14.9921008",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020233",
  },
  {
    id: "37744",
    map_id: "1",
    address: "VIA ALESSANDRO LAMARMORA 1/A,13836 COSSATO (BI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.5683178",
    lng: "8.1799171",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012039",
  },
  {
    id: "37743",
    map_id: "1",
    address: "VIA ETTORE CORCIONE 52,81031 AVERSA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9746197",
    lng: "14.21142",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009761",
  },
  {
    id: "37742",
    map_id: "1",
    address: "VIA VENEZIA ANGOLO VIA SETTEFARINE SNC,93012 GELA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0749306",
    lng: "14.2457941",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001791",
  },
  {
    id: "37739",
    map_id: "1",
    address: "VIA DON MINZONI 113,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.704847",
    lng: "14.4880361",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020270",
  },
  {
    id: "37736",
    map_id: "1",
    address: "PZA ANNUNZIATA 8,87010 TERRANOVA DA SIBARI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.6564101",
    lng: "16.3426769",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020248",
  },
  {
    id: "37734",
    map_id: "1",
    address: "VIA B. AVALLONE 23,84013 CAVA DE' TIRRENI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6976812",
    lng: "14.7081605",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010139",
  },
  {
    id: "37731",
    map_id: "1",
    address: "VIA DEL MARE 33,73044 GALATONE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.1469",
    lng: "18.0612322",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020235",
  },
  {
    id: "37729",
    map_id: "1",
    address: "PIAZZA DELLA PACE 12,89129 REGGIO CALABRIA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "38.0905882",
    lng: "15.634236",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020182",
  },
  {
    id: "37726",
    map_id: "1",
    address: "VIA SANTA MARGHERITA 62,84129 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.6647649",
    lng: "14.7970593",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020221",
  },
  {
    id: "37719",
    map_id: "1",
    address: "VIALE DELLA LIBERTA' 148,74015 MARTINA FRANCA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.701577",
    lng: "17.334125",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003705",
  },
  {
    id: "37715",
    map_id: "1",
    address: "VIA NETTUNENSE 19,00075 LANUVIO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.6805403",
    lng: "12.6517868",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020210",
  },
  {
    id: "37713",
    map_id: "1",
    address: "CORSO MATTEOTTI 212,71017 TORREMAGGIORE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.6907413",
    lng: "15.2967744",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007189",
  },
  {
    id: "37712",
    map_id: "1",
    address: "VIA DELLA STAZIONE 36,01030 BASSANO ROMANO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.2199686",
    lng: "12.1896967",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020155",
  },
  {
    id: "37711",
    map_id: "1",
    address: "VIA CAVOUR 35/A,48018 FAENZA (RA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.2849134",
    lng: "11.8770565",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020188",
  },
  {
    id: "37710",
    map_id: "1",
    address: "VIA ARMANDO DIAZ 197,80047 SAN GIUSEPPE VESUVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8404108",
    lng: "14.5064271",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020106",
  },
  {
    id: "37701",
    map_id: "1",
    address: "VIA GIOVANNI BUSCETI 27,89050 SAN ROBERTO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.2113483",
    lng: "15.7361556",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020213",
  },
  {
    id: "37699",
    map_id: "1",
    address: "VIA SAFFI 101,01100 VITERBO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.4158634",
    lng: "12.1065132",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007046",
  },
  {
    id: "37698",
    map_id: "1",
    address: "VIA PEUCETIA 5/A,70126 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1097882",
    lng: "16.8982965",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010076",
  },
  {
    id: "37696",
    map_id: "1",
    address: "VIA ROMA 57,66021 CASALBORDINO (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.1502123",
    lng: "14.5834607",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002689",
  },
  {
    id: "37688",
    map_id: "1",
    address: "VIA VITTORIO VENETO 10,73019 TREPUZZI (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4065546",
    lng: "18.0783096",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009444",
  },
  {
    id: "37685",
    map_id: "1",
    address: "VIA G.LEOPARDI SN,70126 BARI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.087121",
    lng: "16.998855",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010197",
  },
  {
    id: "37681",
    map_id: "1",
    address: "CSO DELLA REPUBBLICA 25,89048 SIDERNO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "38.2683469",
    lng: "16.2974165",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009407",
  },
  {
    id: "37677",
    map_id: "1",
    address: "PIAZZA MATTEOTTI 7,50062 DICOMANO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8906809",
    lng: "11.5249546",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005978",
  },
  {
    id: "37675",
    map_id: "1",
    address: "MADONNA DEL PANTANO 72,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9105031",
    lng: "14.0587578",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020145",
  },
  {
    id: "37670",
    map_id: "1",
    address: "VIA VITO CUSUMANO 31,91028 PARTANNA (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.728584",
    lng: "12.8876664",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012368",
  },
  {
    id: "37644",
    map_id: "1",
    address: "VIA COLONNA 52,95027 SAN GREGORIO DI CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.5659667",
    lng: "15.1119338",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009273",
  },
  {
    id: "37636",
    map_id: "1",
    address: "CORSO TRIESTE 2016,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0719231",
    lng: "14.3347096",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006904",
  },
  {
    id: "37635",
    map_id: "1",
    address: "STRADA PROV. S. MARIA A CUBITO 567,80145 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8948869",
    lng: "14.2156641",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012569",
  },
  {
    id: "37628",
    map_id: "1",
    address: "VIA MARTIRI DEL DISSENSO, 17,81055 SANTA MARIA CAPUA VETERE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.0867813",
    lng: "14.2551627",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011898",
  },
  {
    id: "37625",
    map_id: "1",
    address: "VIA V.EMANUELE 257,96010 CANICATTINI BAGNI (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.0330495",
    lng: "15.0633037",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003196",
  },
  {
    id: "37624",
    map_id: "1",
    address: "VIA MASSIMO D'AZEGLIO 38,71030 MATTINATA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7089243",
    lng: "16.0507232",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003596",
  },
  {
    id: "37616",
    map_id: "1",
    address: "VIA VERDI 32,15067 NOVI LIGURE (AL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.7608049",
    lng: "8.7927778",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000827",
  },
  {
    id: "37615",
    map_id: "1",
    address: "VIA PONTONE 40/A,80050 SANTA MARIA LA CARITA' (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.72053",
    lng: "14.52005",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012353",
  },
  {
    id: "37612",
    map_id: "1",
    address: "VIA NAZIONALE SNC,81010 PRATELLA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4052738",
    lng: "14.1783085",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020217",
  },
  {
    id: "37611",
    map_id: "1",
    address: "VIA PRINCIPE DI PIEMONTE 41,80070 MONTE DI PROCIDA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7985429",
    lng: "14.0556757",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020152",
  },
  {
    id: "37606",
    map_id: "1",
    address: "VIA CARLO PORTA 5/A,21052 BUSTO ARSIZIO (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.6110291",
    lng: "8.848817",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000309",
  },
  {
    id: "37604",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 225,76016 MARGHERITA DI SAVOIA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.3809144",
    lng: "16.1413823",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001663",
  },
  {
    id: "37603",
    map_id: "1",
    address: "CORSO ALDO MORO 43,70010 VALENZANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0431257",
    lng: "16.8830873",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009946",
  },
  {
    id: "37602",
    map_id: "1",
    address: "VIA TRIPOLI 1,74028 SAVA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.4033097",
    lng: "17.5639506",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009756",
  },
  {
    id: "37601",
    map_id: "1",
    address: "VIA GUGLIELMO MARCONI 27,57014 COLLESALVETTI (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.6128835",
    lng: "10.4640751",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020207",
  },
  {
    id: "37600",
    map_id: "1",
    address: "PZA MATTEOTTI 15-17,84096 MONTECORVINO ROVELLA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.694758",
    lng: "14.975542",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020010",
  },
  {
    id: "37599",
    map_id: "1",
    address: "VIA CADUTI DI NASSIRYA 12,84062 OLEVANO SUL TUSCIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6502935",
    lng: "15.0199657",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020202",
  },
  {
    id: "37591",
    map_id: "1",
    address: "VIA S.NICOLO' 383,95045 MISTERBIANCO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5217624",
    lng: "14.998832",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006547",
  },
  {
    id: "37590",
    map_id: "1",
    address: "VIA B. MASTROIACOVO 70,86029 TRIVENTO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7773614",
    lng: "14.5513632",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005637",
  },
  {
    id: "37589",
    map_id: "1",
    address: "VIA NAZIONALE NORD 3,89025 ROSARNO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.4928573",
    lng: "15.9788989",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010041",
  },
  {
    id: "37586",
    map_id: "1",
    address: "VIA GIACOMO MATTEOTTI 83,92028 NARO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.2875563",
    lng: "13.7985107",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006972",
  },
  {
    id: "37585",
    map_id: "1",
    address: "CORSO DEL POPOLO 74,34074 MONFALCONE (GO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.808991",
    lng: "13.5358848",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011939",
  },
  {
    id: "37584",
    map_id: "1",
    address: "VIA DEGLI ORTI SNC,89861 TROPEA (VV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.6754402",
    lng: "15.897946",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003655",
  },
  {
    id: "37583",
    map_id: "1",
    address: "Strada Teverina KM.1.150,01100 VITERBO (VT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.442115",
    lng: "12.1036141",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020212",
  },
  {
    id: "37580",
    map_id: "1",
    address: "VIA DELLA LIBERTA' 53,73045 LEVERANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.2894287",
    lng: "18.0034777",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020062",
  },
  {
    id: "37579",
    map_id: "1",
    address: "VIA NAZIONALE 221/A,40051 MALALBERGO (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.6680265",
    lng: "11.4889298",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009443",
  },
  {
    id: "37576",
    map_id: "1",
    address: "VIA G.LEOPARDI 32,60033 CHIARAVALLE (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.5989883",
    lng: "13.3268976",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003092",
  },
  {
    id: "37573",
    map_id: "1",
    address: "VIA GUSTAVO ORIGLIA 4,84014 NOCERA INFERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7419562",
    lng: "14.6420078",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004698",
  },
  {
    id: "37572",
    map_id: "1",
    address: "VIA DUCA D'AOSTA 28,97011 ACATE (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.0257156",
    lng: "14.4956834",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010086",
  },
  {
    id: "37569",
    map_id: "1",
    address: "Via San Pio X, 14,31037 LORIA (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.7297481",
    lng: "11.8666609",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012564",
  },
  {
    id: "37568",
    map_id: "1",
    address: "VIA DELLA COSTITUENTE 115,71017 TORREMAGGIORE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.6902558",
    lng: "15.2929394",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009331",
  },
  {
    id: "37560",
    map_id: "1",
    address: "VIA BARBERINI 49,76121 BARLETTA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.3135988",
    lng: "16.2750864",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020199",
  },
  {
    id: "37559",
    map_id: "1",
    address: "VIA LEOPARDI 1/B,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.9568309",
    lng: "14.5263875",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EC-000013",
  },
  {
    id: "37558",
    map_id: "1",
    address: "STR DELLA RESSIA 2,10090 GASSINO TORINESE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.1331288",
    lng: "7.8283583",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020194",
  },
  {
    id: "37556",
    map_id: "1",
    address: "VIA SANT'ANGELO 3,73019 TREPUZZI (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4068526",
    lng: "18.0771932",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009365",
  },
  {
    id: "37554",
    map_id: "1",
    address: "VIA DI MEZZO 355,55054 MASSAROSA (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.8933438",
    lng: "10.2948286",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002799",
  },
  {
    id: "37553",
    map_id: "1",
    address: "VIA DEL MULINO 7,61032 FANO (PU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8412948",
    lng: "12.9686063",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004908",
  },
  {
    id: "37548",
    map_id: "1",
    address: "CORSO ITALIA 82,20832 DESIO (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.6221418",
    lng: "9.2087164",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001210",
  },
  {
    id: "37544",
    map_id: "1",
    address: "VIA GALILEO GALILEI 13,92023 CAMPOBELLO DI LICATA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.253347",
    lng: "13.919085",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000066",
  },
  {
    id: "37534",
    map_id: "1",
    address: "PZA MATTEOTTI 1,56035 CASCIANA TERME LARI (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.5660047",
    lng: "10.5925104",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006615",
  },
  {
    id: "37532",
    map_id: "1",
    address: "VIA DANTE DI NANNI 49/F,10138 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.0679637",
    lng: "7.6523685",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012698",
  },
  {
    id: "37527",
    map_id: "1",
    address: "VIA FRATELLI CAIROLI 79,71013 SAN GIOVANNI ROTONDO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7055265",
    lng: "15.7274598",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006528",
  },
  {
    id: "37525",
    map_id: "1",
    address: "VIA STABIA 441,80037 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7152876",
    lng: "14.5338212",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020055",
  },
  {
    id: "37519",
    map_id: "1",
    address: "VIA ROCCO CHINNICI 8,93019 SOMMATINO (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.3347742",
    lng: "14.0005104",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003360",
  },
  {
    id: "37518",
    map_id: "1",
    address: "VIALE MARCONI 90/A,74016 MASSAFRA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5917227",
    lng: "17.1152098",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020173",
  },
  {
    id: "37516",
    map_id: "1",
    address: "VIA GIOACCHINO ROSSINI 18,06132 PERUGIA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.0867928",
    lng: "12.349542",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020224",
  },
  {
    id: "37515",
    map_id: "1",
    address: "VIA NAPOLI 42,03039 SORA (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.717042",
    lng: "13.611083",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020175",
  },
  {
    id: "37506",
    map_id: "1",
    address: "PIAZZA DELLA POSTA 2,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5674653",
    lng: "14.8977196",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010337",
  },
  {
    id: "37505",
    map_id: "1",
    address: "VIA NAZIONALE DELLE PUGLIE 96,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8856471",
    lng: "14.3200447",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011564",
  },
  {
    id: "37504",
    map_id: "1",
    address: "Via G. Salvemini, 38,96017 NOTO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.8866028",
    lng: "15.0741677",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000819",
  },
  {
    id: "37502",
    map_id: "1",
    address: "VIA REGIONE SICILIANA 16,91020 SALAPARUTA (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "37.7565378",
    lng: "13.0066057",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010037",
  },
  {
    id: "37499",
    map_id: "1",
    address: "VIA VARESE 2/D,10155 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.0866427",
    lng: "7.6905559",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020135",
  },
  {
    id: "37495",
    map_id: "1",
    address: "VIA GIUSEPPE LUNGHI 5,06135 PERUGIA (PG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.088362",
    lng: "12.4365955",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020177",
  },
  {
    id: "37489",
    map_id: "1",
    address: "VIALE DELLE RIMEMBRANZE DI LAMBRATE 15,20134 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.4828713",
    lng: "9.2415692",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007347",
  },
  {
    id: "37484",
    map_id: "1",
    address: "VIA GIOACCHINO TOMA 8,82100 BENEVENTO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "41.127705",
    lng: "14.7909772",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010256",
  },
  {
    id: "37482",
    map_id: "1",
    address: "VIA DUCA DEGLI ABRUZZI 15,95037 SAN GIOVANNI LA PUNTA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.5637957",
    lng: "15.0894501",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020201",
  },
  {
    id: "37475",
    map_id: "1",
    address: "VIA DI ROCCA TEDALDA 44,50136 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "43.7676304",
    lng: "11.304241",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020191",
  },
  {
    id: "37471",
    map_id: "1",
    address: "VIA KENNEDY 116,92026 FAVARA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3073096",
    lng: "13.6531212",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010092",
  },
  {
    id: "37469",
    map_id: "1",
    address: "VIA ANTONIO D'AURIA 203,80048 SANT'ANASTASIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8698163",
    lng: "14.396571",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020067",
  },
  {
    id: "37468",
    map_id: "1",
    address: "VIA CARAVAGGIO 9,84043 AGROPOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "40.3537745",
    lng: "14.9974692",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020198",
  },
  {
    id: "37467",
    map_id: "1",
    address: "VIA CRISTOFORO COLOMBO 8,20066 MELZO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4973585",
    lng: "9.4167693",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020204",
  },
  {
    id: "37461",
    map_id: "1",
    address: "VIA MARCO D'AGRATE 41,20864 AGRATE BRIANZA (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.5749684",
    lng: "9.3502845",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006158",
  },
  {
    id: "37450",
    map_id: "1",
    address: "PIAZZA VITTORIO EMANUELE 2,22030 CAGLIO (CO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.8714671",
    lng: "9.2380268",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012547",
  },
  {
    id: "37448",
    map_id: "1",
    address: "CSO L. MUCCI 196,71016 SAN SEVERO (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.6857658",
    lng: "15.3740016",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006747",
  },
  {
    id: "37447",
    map_id: "1",
    address: "VIA STATALE SNC,44047 SANT'AGOSTINO (FE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.7930922",
    lng: "11.3830233",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011812",
  },
  {
    id: "37446",
    map_id: "1",
    address: "VIA NAZIONALE 109/B,84012 ANGRI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.748699",
    lng: "14.566294",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012771",
  },
  {
    id: "37445",
    map_id: "1",
    address: "VIA ALCIDE DE GASPERI 60,84043 AGROPOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3510225",
    lng: "14.9990546",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000364",
  },
  {
    id: "37443",
    map_id: "1",
    address: "VIA FRANCESCO BARBIERI 56/F,40129 BOLOGNA (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.5166013",
    lng: "11.342672",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020192",
  },
  {
    id: "37442",
    map_id: "1",
    address: "VIA DOMENICO LANCIA DI BROLO 157/B,90145 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.127077",
    lng: "13.334161",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020189",
  },
  {
    id: "37441",
    map_id: "1",
    address: "VIA BERNARDO MASSABÒ 5,18038 SANREMO (IM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.81588",
    lng: "7.77404",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000014",
  },
  {
    id: "37440",
    map_id: "1",
    address: "VIA ROMA 5,08030 NURALLAO (SU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.7911679",
    lng: "9.0795276",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010891",
  },
  {
    id: "37420",
    map_id: "1",
    address: "VIA DON MINZONI ANG. VIA VENEZIA SN,07041 ALGHERO (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5696964",
    lng: "8.3213338",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020156",
  },
  {
    id: "37417",
    map_id: "1",
    address: "VIA PALMA 95,92027 LICATA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.1088638",
    lng: "13.9290289",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010434",
  },
  {
    id: "37415",
    map_id: "1",
    address: "VIA MARCONI 54,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9312041",
    lng: "14.1998346",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000659",
  },
  {
    id: "37414",
    map_id: "1",
    address: "PZA SANTA MARIA MAGGIORE 16,88046 LAMEZIA TERME (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.9731061",
    lng: "16.3156853",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000301",
  },
  {
    id: "37411",
    map_id: "1",
    address: "VIA SIMONE MARTINI 66B,80128 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8516062",
    lng: "14.2214609",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012673",
  },
  {
    id: "37408",
    map_id: "1",
    address: "VIA G. MATTEOTTI 26,47030 SAN MAURO PASCOLI (FC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.1705707",
    lng: "12.4353868",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010790",
  },
  {
    id: "37405",
    map_id: "1",
    address: "VIA CATANIA 43,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6630984",
    lng: "14.8272837",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012427",
  },
  {
    id: "37402",
    map_id: "1",
    address: "VIA PO 27,86046 SAN MARTINO IN PENSILIS (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8710474",
    lng: "15.0133029",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000002",
  },
  {
    id: "37390",
    map_id: "1",
    address: "PIAZZA BOVIO 13,70037 RUVO DI PUGLIA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.11464",
    lng: "16.48671",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001292",
  },
  {
    id: "37375",
    map_id: "1",
    address: "VIA FRANCESCO FERRUCCI 110,76123 ANDRIA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2300709",
    lng: "16.2949991",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007049",
  },
  {
    id: "37373",
    map_id: "1",
    address: "VIA R.BOSCO 341,80069 VICO EQUENSE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.6623466",
    lng: "14.4481336",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000011",
  },
  {
    id: "37371",
    map_id: "1",
    address: "VIA ROMA 76,70029 SANTERAMO IN COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7941484",
    lng: "16.7552996",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020103",
  },
  {
    id: "37369",
    map_id: "1",
    address: "VIA ACHILLE GRANDI SNC,53049 TORRITA DI SIENA (SI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.1739056",
    lng: "11.7875268",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004515",
  },
  {
    id: "37368",
    map_id: "1",
    address: "VIA GIOVANNI XXIII 7,61037 MONDOLFO (PU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.768663",
    lng: "13.1375437",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000003",
  },
  {
    id: "37367",
    map_id: "1",
    address: "C.SO UMBERTO 246,90045 CINISI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.159118",
    lng: "13.1049849",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020025",
  },
  {
    id: "37354",
    map_id: "1",
    address: "VIA DELLA CONCORDIA 43,87040 MENDICINO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "39.279009",
    lng: "16.2081864",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011750",
  },
  {
    id: "37353",
    map_id: "1",
    address: "VIA COLONNELLO APROSIO 97,18019 VALLECROSIA (IM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "43.7871408",
    lng: "7.6374989",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005571",
  },
  {
    id: "37352",
    map_id: "1",
    address: "VIA  ROMA 33,82030 LIMATOLA (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1417097",
    lng: "14.3942773",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020072",
  },
  {
    id: "37348",
    map_id: "1",
    address: "VIA ROMA 69,07036 SENNORI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7913604",
    lng: "8.5925213",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012488",
  },
  {
    id: "37347",
    map_id: "1",
    address: "VIA TAVERNE 270,84015 NOCERA SUPERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7453124",
    lng: "14.6769229",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020088",
  },
  {
    id: "37344",
    map_id: "1",
    address: "VIA GASPARE DEL CARRETTO 20/C,37136 VERONA (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4194687",
    lng: "10.9723627",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011309",
  },
  {
    id: "37343",
    map_id: "1",
    address: "VIA VAL ROSSENNA 58,41048 PRIGNANO SULLA SECCHIA (MO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.4316354",
    lng: "10.6534223",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012288",
  },
  {
    id: "37342",
    map_id: "1",
    address: "VIA SAN PIETRO 59,37030 MONTECCHIA DI CROSARA (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4805643",
    lng: "11.2561788",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010339",
  },
  {
    id: "37341",
    map_id: "1",
    address: "VIA PAOLINO GESUGRANDE 49,90041 BALESTRATE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.0501434",
    lng: "13.0074148",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020190",
  },
  {
    id: "37339",
    map_id: "1",
    address: "VIA G. DA PROCIDA SNC,84012 ANGRI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7398624",
    lng: "14.571408",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009746",
  },
  {
    id: "37337",
    map_id: "1",
    address: "VIA TORINO 13,11100 AOSTA (AO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.7370873",
    lng: "7.3248041",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003407",
  },
  {
    id: "37334",
    map_id: "1",
    address: "VIA DANTE 16,20094 CORSICO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.4315836",
    lng: "9.1118124",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005406",
  },
  {
    id: "37331",
    map_id: "1",
    address: "Via Fratelli Cervi, 10,58023 GAVORRANO (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.9390716",
    lng: "10.8887051",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020179",
  },
  {
    id: "37330",
    map_id: "1",
    address: "VIA V. MONTEFUSCO 13/15,84013 CAVA DE' TIRRENI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7026703",
    lng: "14.7072844",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000096",
  },
  {
    id: "37328",
    map_id: "1",
    address: "VIA DEGLI OLMETTI 3 I,00060 FORMELLO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.0508675",
    lng: "12.4020103",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020014",
  },
  {
    id: "37327",
    map_id: "1",
    address: "VIA MILANO 81,96012 AVOLA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9109588",
    lng: "15.1336847",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012484",
  },
  {
    id: "37326",
    map_id: "1",
    address: "PZA GIOVANNI XXIII 18,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6948024",
    lng: "14.4805275",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011539",
  },
  {
    id: "37323",
    map_id: "1",
    address: "CORSO NAZIONALE 131,84018 SCAFATI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7501533",
    lng: "14.5229528",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011853",
  },
  {
    id: "37322",
    map_id: "1",
    address: "VIA COSTANTINOPOLI 105,80049 SOMMA VESUVIANA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8704857",
    lng: "14.4588301",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007177",
  },
  {
    id: "37320",
    map_id: "1",
    address: "CORSO ALESSANDRO BILELLO 58/60,92013 MENFI (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6103389",
    lng: "12.973095",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020054",
  },
  {
    id: "37319",
    map_id: "1",
    address: "VIA MICHELE PIRONTI, 5,84014 NOCERA INFERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.742159",
    lng: "14.6509163",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020053",
  },
  {
    id: "37317",
    map_id: "1",
    address: "VIA GIORDANO UMBERTO 19,74023 GROTTAGLIE (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5322252",
    lng: "17.4367246",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012379",
  },
  {
    id: "37315",
    map_id: "1",
    address: "VIA GIOVANNI PATARI 26,88100 CATANZARO (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9131291",
    lng: "16.5734351",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020070",
  },
  {
    id: "37298",
    map_id: "1",
    address: "LRE ENRICO MATTEI 16/18,71019 VIESTE (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8798446",
    lng: "16.1754835",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011504",
  },
  {
    id: "37297",
    map_id: "1",
    address: "VIA POLVERIERA 22/A,80505 SANTA MARIA LA CARITA' (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7202978",
    lng: "14.513461",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007221",
  },
  {
    id: "37295",
    map_id: "1",
    address: "VIA SPINETTI 137,37050 OPPEANO (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.3272837",
    lng: "11.0884492",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010208",
  },
  {
    id: "37292",
    map_id: "1",
    address: "P.ZZA VITTORIO EMANULE II 10,88100 CAMPOBASSO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.5587912",
    lng: "14.6601925",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020113",
  },
  {
    id: "37283",
    map_id: "1",
    address: "VIA EGIDIO SACCHETTI 12,27029 VIGEVANO (PV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.3141052",
    lng: "8.849027",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000378",
  },
  {
    id: "37282",
    map_id: "1",
    address: "VIA BELLINI 2,95030 MASCALUCIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5732774",
    lng: "15.0535725",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020038",
  },
  {
    id: "37281",
    map_id: "1",
    address: "VIA CASA MANNINI 5,84010 MAIORI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.649443",
    lng: "14.642183",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011497",
  },
  {
    id: "37276",
    map_id: "1",
    address: "VIA G.M. ANGIOY 10,08040 CARDEDU (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.7967666",
    lng: "9.6279247",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005616",
  },
  {
    id: "37265",
    map_id: "1",
    address: "FRAZ.RODIO VIA S.ANTONIO 12,84066 PISCIOTTA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.130423",
    lng: "15.24104",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002697",
  },
  {
    id: "37261",
    map_id: "1",
    address: "VIA PASQUALE BATTISTESSA 33,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0767842",
    lng: "14.3338711",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010688",
  },
  {
    id: "37257",
    map_id: "1",
    address: "VIA PAPA GIOVANNI XXIII 48,87040 CASTROLIBERO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.3084835",
    lng: "16.2156901",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001841",
  },
  {
    id: "37253",
    map_id: "1",
    address: "PZA CAPITANEO 24,70026 MODUGNO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.083432",
    lng: "16.783205",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020130",
  },
  {
    id: "37252",
    map_id: "1",
    address: "VIA FRAULETO 94,80036 PALMA CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8641506",
    lng: "14.5506874",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010211",
  },
  {
    id: "37251",
    map_id: "1",
    address: "VIA LITORANEA 175,84047 CAPACCIO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.389682",
    lng: "15.0018746",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020049",
  },
  {
    id: "37248",
    map_id: "1",
    address: "VIA SILVIO PELLICO 38,74020 LIZZANO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.3919464",
    lng: "17.4472218",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001852",
  },
  {
    id: "37238",
    map_id: "1",
    address: "VIA UDINE 2,33050 MARANO LAGUNARE (UD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.7654713",
    lng: "13.1677468",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010512",
  },
  {
    id: "37237",
    map_id: "1",
    address: "VIA GALLIPOLI 153,73013 GALATINA (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.1708451",
    lng: "18.1629655",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020184",
  },
  {
    id: "37235",
    map_id: "1",
    address: "VIA FORCONE 40/A,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9567753",
    lng: "14.5381932",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001027",
  },
  {
    id: "37234",
    map_id: "1",
    address: "VIA ANNUNZIATELLA 81,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7099884",
    lng: "14.4892996",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001824",
  },
  {
    id: "37232",
    map_id: "1",
    address: "VIA GIORGIO DE FALCO 42,80010 QUARTO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8829367",
    lng: "14.1410848",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000052",
  },
  {
    id: "37231",
    map_id: "1",
    address: "VIA RIVIERA BERICA 651,36100 VICENZA (VI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5052282",
    lng: "11.5698837",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020032",
  },
  {
    id: "37230",
    map_id: "1",
    address: "VIA CEFFATO, 133,84025 EBOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "40.6170293",
    lng: "15.0616438",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012224",
  },
  {
    id: "37226",
    map_id: "1",
    address: "VIA ROMA 27,37041 ALBAREDO D'ADIGE (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.317475",
    lng: "11.27289",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EX-020116",
  },
  {
    id: "37225",
    map_id: "1",
    address: "VIA PALA DI CARRU 155,07100 SASSARI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7475071",
    lng: "8.5212655",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006762",
  },
  {
    id: "37224",
    map_id: "1",
    address: "PZA MASSA 25,17020 CALICE LIGURE (SV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "44.2052082",
    lng: "8.2942019",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011609",
  },
  {
    id: "37222",
    map_id: "1",
    address: "VIA MONTEVERGINE 33,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8457093",
    lng: "14.2023543",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003436",
  },
  {
    id: "37220",
    map_id: "1",
    address: "VIA DELLA CHIESA 15,50058 SIGNA (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.794616",
    lng: "11.1283408",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009345",
  },
  {
    id: "37219",
    map_id: "1",
    address: "VIA TOSCANINI 14,81030 ORTA DI ATELLA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9661587",
    lng: "14.2744934",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020167",
  },
  {
    id: "37218",
    map_id: "1",
    address: "VIA NAZIONALE 150,75100 MATERA (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.675278",
    lng: "16.5965611",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010913",
  },
  {
    id: "37217",
    map_id: "1",
    address: "VIA BRUNO DE FINETTI 257,00134 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7931163",
    lng: "12.4872084",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009388",
  },
  {
    id: "37215",
    map_id: "1",
    address: "VIA CRISTOFORO COLOMBO 64,88046 LAMEZIA TERME (CZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.9692988",
    lng: "16.31412",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005752",
  },
  {
    id: "37214",
    map_id: "1",
    address: "S.P. 250 PER SPIANZZOLA KM 655 SNC,70024 GRAVINA IN PUGLIA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9666569",
    lng: "16.2019396",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020148",
  },
  {
    id: "37213",
    map_id: "1",
    address: "VIA DAMIANO CHIESA 93,84082 BRACIGLIANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8217559",
    lng: "14.7087343",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007374",
  },
  {
    id: "37211",
    map_id: "1",
    address: "VIA SALVO D'ACQUISTO 103,84050 CAPACCIO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4535277",
    lng: "15.0071465",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000270",
  },
  {
    id: "37210",
    map_id: "1",
    address: "VIA FIRENZE 7/C,51031 AGLIANA (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.9177762",
    lng: "10.9941327",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006601",
  },
  {
    id: "37209",
    map_id: "1",
    address: "VIA MILANO 64,95045 MISTERBIANCO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5179676",
    lng: "15.037226",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004772",
  },
  {
    id: "37207",
    map_id: "1",
    address: "VIA BEATO GIACOMO 107/B,70020 BITETTO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "41.0390791",
    lng: "16.7443609",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011817",
  },
  {
    id: "37206",
    map_id: "1",
    address: "VIA CINCINNATO 11,97015 MODICA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.8434515",
    lng: "14.7644456",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001643",
  },
  {
    id: "37205",
    map_id: "1",
    address: "PIAZZA BIAGINI 15,51034 SERRAVALLE PISTOIESE (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8759785",
    lng: "10.9147307",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006626",
  },
  {
    id: "37204",
    map_id: "1",
    address: "VIA OLMO 6/A,37141 VERONA (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "45.4585752",
    lng: "11.0639692",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020154",
  },
  {
    id: "37203",
    map_id: "1",
    address: "VIA ROMA 92B,35010 VIGODARZERE (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.453084",
    lng: "11.8891628",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020089",
  },
  {
    id: "37201",
    map_id: "1",
    address: "VLE MAZZINI 26,75013 FERRANDINA (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.4996648",
    lng: "16.4550951",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001045",
  },
  {
    id: "37198",
    map_id: "1",
    address: "VIA DELLA VILLA DI LIVIA 117,00188 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "42.0014912",
    lng: "12.4923792",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010821",
  },
  {
    id: "37196",
    map_id: "1",
    address: "VIA DEL BOSCO 64/B,95125 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5323313",
    lng: "15.0802935",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ec-000004",
  },
  {
    id: "37195",
    map_id: "1",
    address: "VIA CESARE BATTISTI 22,74014 LATERZA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6291985",
    lng: "16.7996507",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012360",
  },
  {
    id: "37186",
    map_id: "1",
    address: "Via Buonarroti, 31,20900 MONZA (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.5789358",
    lng: "9.2827615",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010022",
  },
  {
    id: "37183",
    map_id: "1",
    address: "VIA VENETO 87,50145 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7932088",
    lng: "11.1783019",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020059",
  },
  {
    id: "37182",
    map_id: "1",
    address: "VIA DEL GIRONE 2B,31045 MOTTA DI LIVENZA (TV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.77584",
    lng: "12.6102399",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001162",
  },
  {
    id: "37181",
    map_id: "1",
    address: "VIA RISORGIMENTO 11/13,84043 AGROPOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.3558389",
    lng: "14.9973375",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010232",
  },
  {
    id: "37179",
    map_id: "1",
    address: "VIA RE GALANTUOMO ,33,73043 COPERTINO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.274002",
    lng: "18.047008",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000360",
  },
  {
    id: "37174",
    map_id: "1",
    address: "VIA GIOSUE' CARDUCCI 27/C,34122 TRIESTE (TS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.6504445",
    lng: "13.7772118",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012080",
  },
  {
    id: "37172",
    map_id: "1",
    address: "PZA ORESTE TOMMASINI 19,00162 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9202164",
    lng: "12.5262627",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020118",
  },
  {
    id: "37162",
    map_id: "1",
    address: "VIA NAZIONALE 46,07025 LURAS (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9366931",
    lng: "9.1750212",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006654",
  },
  {
    id: "37160",
    map_id: "1",
    address: "VIA MARCONI 239,80020 CASAVATORE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8926172",
    lng: "14.278612",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020147",
  },
  {
    id: "37159",
    map_id: "1",
    address: "VIALE VENETO 78,95127 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5202395",
    lng: "15.0967888",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011459",
  },
  {
    id: "37156",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI 73A,87036 RENDE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.3531316",
    lng: "16.2425143",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005877",
  },
  {
    id: "37154",
    map_id: "1",
    address: "VIA LANCIA DI BROLO 100,90145 PALERMO (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1252349",
    lng: "13.3348045",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020168",
  },
  {
    id: "37153",
    map_id: "1",
    address: "VIA ATTILIO BARBARULO 49,84014 NOCERA INFERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7413909",
    lng: "14.6395161",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012293",
  },
  {
    id: "37152",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE III 46,83044 BISACCIA (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0090098",
    lng: "15.3755564",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020162",
  },
  {
    id: "37151",
    map_id: "1",
    address: "VIA CHAMBERY 200,11100 AOSTA (AO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.7329789",
    lng: "7.3034169",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006928",
  },
  {
    id: "37150",
    map_id: "1",
    address: "VIA COLLE DELLE API 108/E,86100 CAMPOBASSO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.5823724",
    lng: "14.6741791",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011016",
  },
  {
    id: "37148",
    map_id: "1",
    address: "VIA MATTEOTTI 18,20098 SAN GIULIANO MILANESE (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.3942641",
    lng: "9.2852381",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003922",
  },
  {
    id: "37136",
    map_id: "1",
    address: "VLE SAN LORENZO 1,82100 BENEVENTO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1336913",
    lng: "14.7698241",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003261",
  },
  {
    id: "37132",
    map_id: "1",
    address: "VIA ROSA AGAZZI 22,56028 SAN MINIATO (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "43.695013",
    lng: "10.8344794",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020022",
  },
  {
    id: "37128",
    map_id: "1",
    address: "VIA ROMA 20,80040 SAN SEBASTIANO AL VESUVIO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8438396",
    lng: "14.3703955",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012248",
  },
  {
    id: "37127",
    map_id: "1",
    address: "STRADA BERARDINELLI, 295,60019 SENIGALLIA (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.7250072",
    lng: "13.184148",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006929",
  },
  {
    id: "37126",
    map_id: "1",
    address: "VIA XX SETTEMBRE 19,95011 CALATABIANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.8223284",
    lng: "15.22836",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000493",
  },
  {
    id: "36125",
    map_id: "1",
    address: "CSO GARIBALDI 131,85021 AVIGLIANO (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7299968",
    lng: "15.7136318",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011752",
  },
  {
    id: "35125",
    map_id: "1",
    address: "VIALE UNGHERIA 24,20121 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.4455203",
    lng: "9.2467569",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020050",
  },
  {
    id: "35124",
    map_id: "1",
    address: "VIA GIUSEPPE MAZZINI 1,90040 ISOLA DELLE FEMMINE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1979429",
    lng: "13.2477603",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002968",
  },
  {
    id: "35123",
    map_id: "1",
    address: "VIALE BRUNO BUOZZI 146/148,50054 FUCECCHIO (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "43.7325111",
    lng: "10.8021978",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020142",
  },
  {
    id: "35122",
    map_id: "1",
    address: "VIA LA MARMORA 45,97019 VITTORIA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.9531567",
    lng: "14.536933",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012557",
  },
  {
    id: "35121",
    map_id: "1",
    address: "PIAZZA SAN LORENZO 41,73023 LIZZANELLO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3036367",
    lng: "18.2225216",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004197",
  },
  {
    id: "35120",
    map_id: "1",
    address:
      "VIA PROVINCIALE DEL CORTICATO SNC - FRAZ SILLA,83029 SASSANO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.3611165",
    lng: "15.5897271",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020056",
  },
  {
    id: "35119",
    map_id: "1",
    address: "C.SO FRANCIA 212,00191 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.945073",
    lng: "12.4695422",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010823",
  },
  {
    id: "35115",
    map_id: "1",
    address: "VIA GIOVANNI MIRANDA,35/37,80131 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8622926",
    lng: "14.2196391",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002511",
  },
  {
    id: "35114",
    map_id: "1",
    address: "VIA MADRE TERESA DI CALCUTTA, 51,87062 SAN MARCO ARGENTANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.6055386",
    lng: "16.207245",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020164",
  },
  {
    id: "35113",
    map_id: "1",
    address: "VIA COMUNALE 246,98148 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1732313",
    lng: "15.5285125",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007043",
  },
  {
    id: "35112",
    map_id: "1",
    address: "VIA CIRCONVALLAZIONE 15,89014 OPPIDO MAMERTINA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.3580503",
    lng: "16.0229215",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020068",
  },
  {
    id: "35111",
    map_id: "1",
    address: "VIA EUROPA ANG. VIA CEFALONIA SNC,20097 SAN DONATO MILANESE (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.41809",
    lng: "9.271513",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011580",
  },
  {
    id: "35110",
    map_id: "1",
    address: "VIA GIUSEPPE MAZZINI 10,81025 MARCIANISE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "41.0359048",
    lng: "14.3037124",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000180",
  },
  {
    id: "35109",
    map_id: "1",
    address: "VIA SAN DOMENICO SAVIO 14,73100 LECCE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3614321",
    lng: "18.180546",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011064",
  },
  {
    id: "35108",
    map_id: "1",
    address: "VIA BATTAGLIA G 23,89128 REGGIO CALABRIA (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1045379",
    lng: "15.6465805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012627",
  },
  {
    id: "35107",
    map_id: "1",
    address: "VIA SANT' OTTAVIO 45,10124 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0706296",
    lng: "7.6964215",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009004",
  },
  {
    id: "35106",
    map_id: "1",
    address: "PZA BENEDETTO XV 4,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9030554",
    lng: "14.293258",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005746",
  },
  {
    id: "35104",
    map_id: "1",
    address: "VIA STELLA, SNC SNC,03030 BROCCOSTELLA (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7018745",
    lng: "13.6441421",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020166",
  },
  {
    id: "35102",
    map_id: "1",
    address: "VIA GIUSEPPE GUIDOBONO 19,19013 DEIVA MARINA (SP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.2244799",
    lng: "9.52053",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020165",
  },
  {
    id: "35079",
    map_id: "1",
    address: "VIA SAN FILIPPO SAN GIUSEPPE 12,95022 ACI CATENA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5883533",
    lng: "15.1419597",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003231",
  },
  {
    id: "35075",
    map_id: "1",
    address: "VIA ROMA ANGOLO VIA PIAVE 38,95037 SAN GIOVANNI LA PUNTA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5763926",
    lng: "15.0770662",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004552",
  },
  {
    id: "35074",
    map_id: "1",
    address: "VIA DEL SUFFRAGIO 10,38122 TRENTO (TN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "46.0717274",
    lng: "11.1248697",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011287",
  },
  {
    id: "35072",
    map_id: "1",
    address: "PIAZZA UMBERTO I, 40,70020 BINETTO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0247673",
    lng: "16.7106063",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020105",
  },
  {
    id: "35071",
    map_id: "1",
    address: "CORSO ITALIA 144,80062 META (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.6392623",
    lng: "14.4187338",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012692",
  },
  {
    id: "35070",
    map_id: "1",
    address: "VIA DOBERDÒ, 2,92027 LICATA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.1065106",
    lng: "13.9461454",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010692",
  },
  {
    id: "35069",
    map_id: "1",
    address: "VIA NUOVA LAVORATE 55,84087 SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.7886899",
    lng: "14.6391081",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005285",
  },
  {
    id: "35068",
    map_id: "1",
    address: "CSO VITTORIO EMANUELE 172,84096 MONTECORVINO ROVELLA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "40.68306",
    lng: "14.980385",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020021",
  },
  {
    id: "35067",
    map_id: "1",
    address: "VIA ANNUNZIATELLA, 3,75100 MATERA (MT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6705366",
    lng: "16.6056408",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020124",
  },
  {
    id: "35065",
    map_id: "1",
    address: "CSO VITTORIO EMANUELE 94,84012 ANGRI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.7434554",
    lng: "14.5748219",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010240",
  },
  {
    id: "35064",
    map_id: "1",
    address: "VIA MAZZINI 26,67051 AVEZZANO (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.0325963",
    lng: "13.4257334",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004056",
  },
  {
    id: "35063",
    map_id: "1",
    address: "VIA MAIORCA 135,56128 PISA (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.6734494",
    lng: "10.2729307",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009781",
  },
  {
    id: "35062",
    map_id: "1",
    address: "VIA SANDRO PERTINI 5,00054 FIUMICINO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.7741459",
    lng: "12.2361178",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020120",
  },
  {
    id: "35060",
    map_id: "1",
    address: "VIA PASQUALE MARTUCCI ZECCA 22,70032 BITONTO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1115937",
    lng: "16.692103",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020151",
  },
  {
    id: "35059",
    map_id: "1",
    address: "VIA GIANTURCO 60,84019 VIETRI SUL MARE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6670305",
    lng: "14.7155914",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011622",
  },
  {
    id: "35053",
    map_id: "1",
    address: "VIA TORINO 40/A,95045 MISTERBIANCO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5194787",
    lng: "15.0378195",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020160",
  },
  {
    id: "35052",
    map_id: "1",
    address: "VIALE VENEZIA 9,32036 SEDICO (BL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "46.10975",
    lng: "12.09592",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012118",
  },
  {
    id: "35050",
    map_id: "1",
    address: "VIA EMILIA 285,40026 IMOLA (BO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.3517942",
    lng: "11.7197906",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005025",
  },
  {
    id: "35046",
    map_id: "1",
    address: "VIA SOTTO BRAIDA, SNC,84037 SANT'ARSENIO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.469893",
    lng: "15.485626",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012303",
  },
  {
    id: "35043",
    map_id: "1",
    address: "PIAZZA VITTORIO VENETO 3,39012 MERANO (BZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "46.6452745",
    lng: "11.180029",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011528",
  },
  {
    id: "35041",
    map_id: "1",
    address: "CSO UMBERTO I 91,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4911033",
    lng: "14.0627576",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-008792",
  },
  {
    id: "35040",
    map_id: "1",
    address: "VIA GIOSUE' CARDUCCI 3,94015 PIAZZA ARMERINA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.3771258",
    lng: "14.3694851",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004641",
  },
  {
    id: "35035",
    map_id: "1",
    address: "VIA MAZZINI 69,84010 SANT'EGIDIO DEL MONTE ALBINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7569991",
    lng: "14.584027",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011545",
  },
  {
    id: "35034",
    map_id: "1",
    address: "VIA TRIONFALE 11472,00135 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9616571",
    lng: "12.409642",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001969",
  },
  {
    id: "35025",
    map_id: "1",
    address: "CENTRO DIREZIONALE IS. E/3,80143 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8579301",
    lng: "14.2839071",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010441",
  },
  {
    id: "35024",
    map_id: "1",
    address: "VIA MARCO POLO 12,02037 POGGIO MOIANO (RI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.2108361",
    lng: "12.8216173",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010069",
  },
  {
    id: "35021",
    map_id: "1",
    address: "CONTRADA CAVONI 33,87040 LUZZI (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.4467808",
    lng: "16.2609349",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011014",
  },
  {
    id: "35020",
    map_id: "1",
    address: "VIALE VICENZA 57/E 57/E,36061 BASSANO DEL GRAPPA (VI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.7628997",
    lng: "11.7152207",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020024",
  },
  {
    id: "35019",
    map_id: "1",
    address: "VIA GAETANO FILANGIERI 145,80069 VICO EQUENSE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "40.6605497",
    lng: "14.4240958",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012515",
  },
  {
    id: "35017",
    map_id: "1",
    address: "VIA VARIANTE SS 115 - 10,97015 MODICA (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "36.836095",
    lng: "14.7754187",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006538",
  },
  {
    id: "35001",
    map_id: "1",
    address: "VIA TOSCO ROMAGNOLA 332,56021 CASCINA (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.677413",
    lng: "10.543376",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005646",
  },
  {
    id: "34999",
    map_id: "1",
    address: "VIA CAVOUR 18,93015 NISCEMI (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.144995",
    lng: "14.3847606",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009179",
  },
  {
    id: "34998",
    map_id: "1",
    address: "VIA MASCAGNI 8,73033 CORSANO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.887985",
    lng: "18.365521",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000197",
  },
  {
    id: "34996",
    map_id: "1",
    address: "VIA DON MICHELE PAESANO 4,84025 EBOLI (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.6143309",
    lng: "15.0524708",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010466",
  },
  {
    id: "34995",
    map_id: "1",
    address: "VIA ROMA 92,08045 LANUSEI (OG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.877103",
    lng: "9.542445",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000382",
  },
  {
    id: "34992",
    map_id: "1",
    address: "VIALE CORRADO IV 4,67100 L'AQUILA (AQ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.3585443",
    lng: "13.3829818",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020101",
  },
  {
    id: "34991",
    map_id: "1",
    address: "VIA ROMA 18,92025 CASTELTERMINI (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5407544",
    lng: "13.6407202",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010267",
  },
  {
    id: "34989",
    map_id: "1",
    address: "VIA PASQUALE VILLARI 1,92020 PALMA DI MONTECHIARO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.1895059",
    lng: "13.7749335",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020122",
  },
  {
    id: "34984",
    map_id: "1",
    address: "VIA DELLA MISERICORDIA, 1/BIS,55054 MASSAROSA (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.8974395",
    lng: "10.3195988",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005800",
  },
  {
    id: "34983",
    map_id: "1",
    address: "CSO EUROPA 3,80027 FRATTAMAGGIORE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9322085",
    lng: "14.2751709",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000800",
  },
  {
    id: "34981",
    map_id: "1",
    address: "PLINIO IL VECCHIO 39,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.6996968",
    lng: "14.4850527",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003860",
  },
  {
    id: "34980",
    map_id: "1",
    address: "CSO GENERALE TORELLI 75,71011 APRICENA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.7858515",
    lng: "15.4430302",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004995",
  },
  {
    id: "34970",
    map_id: "1",
    address: "PZA AMENDOLA 29,84010 SAN MARZANO SUL SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7759126",
    lng: "14.5867641",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006525",
  },
  {
    id: "34968",
    map_id: "1",
    address: "VIA SAN GIORGIO 3/A,70019 TRIGGIANO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.067648",
    lng: "16.933035",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001915",
  },
  {
    id: "34967",
    map_id: "1",
    address: "VIA DELLE CENTO STELLE 20/C,50131 FIRENZE (FI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.782253",
    lng: "11.2894135",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004915",
  },
  {
    id: "34964",
    map_id: "1",
    address: "VIA MISURATA 26,92014 PORTO EMPEDOCLE (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.2908102",
    lng: "13.5265166",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001608",
  },
  {
    id: "34963",
    map_id: "1",
    address: "VIA PRINCIPE UMBERTO 56,94010 CATENANUOVA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.5679109",
    lng: "14.6912482",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002619",
  },
  {
    id: "34960",
    map_id: "1",
    address: "VIA CESARE,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0702769",
    lng: "14.3312518",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020146",
  },
  {
    id: "34950",
    map_id: "1",
    address: "VIALE MICHELANGELO 226,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.4519221",
    lng: "15.5540609",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002670",
  },
  {
    id: "34949",
    map_id: "1",
    address: "VIA R.PUCCI 5D,87020 TORTORA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.9101376",
    lng: "15.7717393",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011038",
  },
  {
    id: "34948",
    map_id: "1",
    address: "VIA FIRENZE 156,95128 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.515774",
    lng: "15.0903431",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011363",
  },
  {
    id: "34945",
    map_id: "1",
    address: "VIA NAZIONALE 213,84034 PADULA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.3463203",
    lng: "15.6249709",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011582",
  },
  {
    id: "34944",
    map_id: "1",
    address: "VIA TOSCANA 97,57124 LIVORNO (LI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.5365963",
    lng: "10.3299078",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007498",
  },
  {
    id: "34943",
    map_id: "1",
    address: "VIA GARIBALDI 151,95029 VIAGRANDE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.6096618",
    lng: "15.0983849",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000546",
  },
  {
    id: "34939",
    map_id: "1",
    address: "VIA MILITELLO SNC,00132 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8629028",
    lng: "12.6832024",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009759",
  },
  {
    id: "34938",
    map_id: "1",
    address: "VIALE EUROPA 60/62,00010 MARCELLINA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.02104",
    lng: "12.804633",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020139",
  },
  {
    id: "34936",
    map_id: "1",
    address: "VIA MAGENTA 24,96010 SOLARINO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.1015012",
    lng: "15.1204251",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020037",
  },
  {
    id: "34932",
    map_id: "1",
    address: "CSO GARIBALDI 188,08026 ORANI (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.2483024",
    lng: "9.1792846",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000265",
  },
  {
    id: "34930",
    map_id: "1",
    address: "VIA RUGGIERO DA TORRECUSO 37,82030 TORRECUSO (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "41.1871207",
    lng: "14.680106",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001913",
  },
  {
    id: "34929",
    map_id: "1",
    address: "VIA ROMA,  130,81020 RECALE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0639677",
    lng: "14.3055565",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020023",
  },
  {
    id: "34928",
    map_id: "1",
    address: "VIA MICHELE COPPINO 107,55049 VIAREGGIO (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8649361",
    lng: "10.2529367",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006685",
  },
  {
    id: "34927",
    map_id: "1",
    address: "VIA RAVIZZA 43,76123 ANDRIA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2350403",
    lng: "16.2836015",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012524",
  },
  {
    id: "34924",
    map_id: "1",
    address: "VIA LUINO 26,21037 LAVENA PONTE TRESA (VA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.9674585",
    lng: "8.8552168",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000329",
  },
  {
    id: "34923",
    map_id: "1",
    address: "VIA CIVITA 8,87070 ROSETO CAPO SPULICO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.985946",
    lng: "16.6048227",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000730",
  },
  {
    id: "34922",
    map_id: "1",
    address: "CORSO DEL TIRRENO 503,87020 SANTA MARIA DEL CEDRO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.7457187",
    lng: "15.8075124",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010364",
  },
  {
    id: "34917",
    map_id: "1",
    address: "VIA DANTE 44,33085 MANIAGO (PN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "46.1685479",
    lng: "12.7055355",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009355",
  },
  {
    id: "34916",
    map_id: "1",
    address: "VIA GARIBALDI 88,89034 BOVALINO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1477853",
    lng: "16.177023",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011550",
  },
  {
    id: "34909",
    map_id: "1",
    address: "VIA POSEIDON 3,84044 ALBANELLA (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4602217",
    lng: "14.9629017",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020098",
  },
  {
    id: "34907",
    map_id: "1",
    address: "VIA MARCONI 23,38065 MORI (TN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.8518402",
    lng: "10.9788599",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020149",
  },
  {
    id: "34897",
    map_id: "1",
    address: "VIA GUALTIERO D'OCRA 38,72023 MESAGNE (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.5566591",
    lng: "17.8099071",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005686",
  },
  {
    id: "34890",
    map_id: "1",
    address: "CORSO UMBERTO I SNC,84019 VIETRI SUL MARE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE - ENEL ENERGIA",
    lat: "40.6719289",
    lng: "14.7284309",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012505",
  },
  {
    id: "34887",
    map_id: "1",
    address: "PIAZZA SAN FRANCESCO D'ASSISI, 29,91022 CASTELVETRANO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "37.6741064",
    lng: "12.7893556",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002091",
  },
  {
    id: "34886",
    map_id: "1",
    address: "VIA FIERA 21,85020 ATELLA (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8805022",
    lng: "15.65313",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001062",
  },
  {
    id: "34885",
    map_id: "1",
    address: "VIA GALLIANO 26,74024 MANDURIA (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.3990605",
    lng: "17.6302618",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001006",
  },
  {
    id: "34883",
    map_id: "1",
    address: "Piazza Marconi 13,80131 AVERSA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9760259",
    lng: "14.2015632",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010469",
  },
  {
    id: "34882",
    map_id: "1",
    address: "VIALE RISORGIMENTO 16,00018 PALOMBARA SABINA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.06938",
    lng: "12.765021",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001161",
  },
  {
    id: "34878",
    map_id: "1",
    address: "VIA LIMA  - SCALA A 28,00198 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.9236165",
    lng: "12.4961534",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000000",
  },
  {
    id: "34877",
    map_id: "1",
    address: "VIALE MARCHE 11/E,73100 LECCE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.3451728",
    lng: "18.1719773",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010061",
  },
  {
    id: "34874",
    map_id: "1",
    address: "VIA GRAVINA 102,70022 ALTAMURA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8286667",
    lng: "16.5441469",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002002",
  },
  {
    id: "34873",
    map_id: "1",
    address: "CORSO RISORGIMENTO 221,86170 ISERNIA (IS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.5972592",
    lng: "14.2416151",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020095",
  },
  {
    id: "34864",
    map_id: "1",
    address: "VIA STRAELLE SAN PIETRO 12/C,35012 CAMPOSAMPIERO (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5619049",
    lng: "11.9384392",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009656",
  },
  {
    id: "34863",
    map_id: "1",
    address: "VIA ROMA 303,80017 MELITO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9219509",
    lng: "14.2306624",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020119",
  },
  {
    id: "34861",
    map_id: "1",
    address: "VIA V. EMANUELE II 63,73024 MAGLIE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.1225565",
    lng: "18.3019668",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000662",
  },
  {
    id: "34860",
    map_id: "1",
    address: "VIA VENTIMIGLIA 100,90049 TERRASINI (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.1530205",
    lng: "13.0838517",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001544",
  },
  {
    id: "34859",
    map_id: "1",
    address: "VIA GIACINTO GIGANTE 186,80016 MARANO DI NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8541866",
    lng: "14.234063",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020075",
  },
  {
    id: "34858",
    map_id: "1",
    address: "VIA MANCHE SNC,88837 PETILIA POLICASTRO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.115246",
    lng: "16.782961",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020082",
  },
  {
    id: "34856",
    map_id: "1",
    address: "VIA MARCO POLO CONTESSE 420/A,98125 MESSINA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "38.1530794",
    lng: "15.5326893",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009760",
  },
  {
    id: "34854",
    map_id: "1",
    address: "VIA GARIGLIANO 58,03043 CASSINO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.4856726",
    lng: "13.8341764",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011247",
  },
  {
    id: "34853",
    map_id: "1",
    address: "VIA CECILIA DE RITTMEYER 5/A,34134 TRIESTE (TS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.6562623",
    lng: "13.7751773",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020104",
  },
  {
    id: "34850",
    map_id: "1",
    address: "VIA CARDITELLO 23,80027 FRATTAMAGGIORE (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9413557",
    lng: "14.2799352",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005619",
  },
  {
    id: "34845",
    map_id: "1",
    address: "VIA ANTONIO FOGAZZARO 40,72017 OSTUNI (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "40.7256512",
    lng: "17.5826558",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020131",
  },
  {
    id: "34843",
    map_id: "1",
    address: "VIA DANTE 46,37032 MONTEFORTE D'ALPONE (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4215637",
    lng: "11.2851316",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006961",
  },
  {
    id: "34842",
    map_id: "1",
    address: "VIA LINCOLN 207,96018 PACHINO (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "36.7135119",
    lng: "15.0856016",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005710",
  },
  {
    id: "34841",
    map_id: "1",
    address: "MURIAGLIO 3/A,10141 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.0631744",
    lng: "7.6499834",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020110",
  },
  {
    id: "34838",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 6,94017 REGALBUTO (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "37.6528096",
    lng: "14.6406304",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002346",
  },
  {
    id: "34836",
    map_id: "1",
    address: "VIA FILIPPO TURATI SNC,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4902409",
    lng: "14.0508952",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020016",
  },
  {
    id: "34834",
    map_id: "1",
    address: "VIA VALDERA 50,56038 PONSACCO (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "43.624851",
    lng: "10.630167",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011803",
  },
  {
    id: "34832",
    map_id: "1",
    address: "CONTRADA BASSIELLO 35/A,83031 ARIANO IRPINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.1840221",
    lng: "15.0512733",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020117",
  },
  {
    id: "34831",
    map_id: "1",
    address: "VIA ALBANIA 62BIS,81047 MACERATA CAMPANIA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.062502",
    lng: "14.288117",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000330",
  },
  {
    id: "34827",
    map_id: "1",
    address: "VIA SANT'ANGELO 53,95031 ADRANO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6709745",
    lng: "14.8366314",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020036",
  },
  {
    id: "34823",
    map_id: "1",
    address: "VIA XXV APRILE 11,26029 SONCINO (CR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.4005966",
    lng: "9.869507",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020127",
  },
  {
    id: "34822",
    map_id: "1",
    address: "P. BORROTZU 44,08026 ORANI (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.2489097",
    lng: "9.17381",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009976",
  },
  {
    id: "34816",
    map_id: "1",
    address: "VIA COLLE DELLA MACCHIA 32,86016 RICCIA (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.4799059",
    lng: "14.8319839",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001691",
  },
  {
    id: "34812",
    map_id: "1",
    address: "VIA NAZIONALE 64,87020 TORTORA (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.9188289",
    lng: "15.7712917",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001941",
  },
  {
    id: "34810",
    map_id: "1",
    address: "CSO V. EMANUELE 13,08023 FONNI (NU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.1187194",
    lng: "9.2538229",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006688",
  },
  {
    id: "34809",
    map_id: "1",
    address: "VIA VAIANI 4,80010 QUARTO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8789767",
    lng: "14.1474685",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011145",
  },
  {
    id: "34808",
    map_id: "1",
    address: "VIA RISORGIMENTO 1,93018 SANTA CATERINA VILLARMOSA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "37.5916616",
    lng: "14.0312937",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003762",
  },
  {
    id: "34807",
    map_id: "1",
    address: "VIA E. GUIDA 127,70024 GRAVINA IN PUGLIA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8189491",
    lng: "16.425152",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001034",
  },
  {
    id: "34806",
    map_id: "1",
    address: "VIA CASELLE 5/A,10071 BORGARO TORINESE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.151996",
    lng: "7.6566915",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020045",
  },
  {
    id: "34804",
    map_id: "1",
    address: "VIALE MAGNA GRECIA 350,74121 TARANTO (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.4536522",
    lng: "17.2629079",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020041",
  },
  {
    id: "34803",
    map_id: "1",
    address: "VIA COCIFISSO 23/A,60041 SASSOFERRATO (AN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.4396709",
    lng: "12.8499108",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006879",
  },
  {
    id: "34801",
    map_id: "1",
    address: "VIA MANDANICI 75,98051 BARCELLONA POZZO DI GOTTO (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.1476774",
    lng: "15.2155223",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020058",
  },
  {
    id: "34799",
    map_id: "1",
    address: "VIA CARLO ROLANDO 2 A R,16151 GENOVA (GE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.4137344",
    lng: "8.8884369",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020035",
  },
  {
    id: "34798",
    map_id: "1",
    address: "VIA MODESTINO MANCINI 8,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.4645417",
    lng: "15.5735889",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006584",
  },
  {
    id: "34795",
    map_id: "1",
    address: "VIA TAVERNOLA 40,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.703344",
    lng: "14.4869509",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010369",
  },
  {
    id: "34783",
    map_id: "1",
    address: "VIA FRANCESCO SAVERIO NITTI 76,85025 MELFI (PZ)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9216621",
    lng: "15.6663771",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002075",
  },
  {
    id: "34781",
    map_id: "1",
    address: "VIA DELLA MARRANELLA 117/119,00176 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8843074",
    lng: "12.5421833",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020061",
  },
  {
    id: "34779",
    map_id: "1",
    address: "VIA IV NOVEMBRE 10,03049 SANT'ELIA FIUMERAPIDO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.5395872",
    lng: "13.8668047",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011390",
  },
  {
    id: "34776",
    map_id: "1",
    address: "VIA ARRINGA 12,88837 PETILIA POLICASTRO (KR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "39.1113461",
    lng: "16.7918409",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020065",
  },
  {
    id: "34775",
    map_id: "1",
    address: "BORGO SAN GIOVANNI 31,30026 PORTOGRUARO (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.7735123",
    lng: "12.8398174",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010269",
  },
  {
    id: "34771",
    map_id: "1",
    address: "VIA ZUNICA 5,92100 AGRIGENTO (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.2992692",
    lng: "13.5566401",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001999",
  },
  {
    id: "34767",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 23,07023 CALANGIANUS (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.9220191",
    lng: "9.1921766",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002401",
  },
  {
    id: "34759",
    map_id: "1",
    address: "VLE G. DI VITTORIO 77,71042 CERIGNOLA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.2634409",
    lng: "15.9026602",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012262",
  },
  {
    id: "34757",
    map_id: "1",
    address: "VIA SAFFI 19-21,56025 PONTEDERA (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.664328",
    lng: "10.634563",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001436",
  },
  {
    id: "34755",
    map_id: "1",
    address: "VIA MONTE CENGIO 4,20138 MILANO (MI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.4319087",
    lng: "9.2438496",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010383",
  },
  {
    id: "34753",
    map_id: "1",
    address: "STRADA CRAVONE 69,95121 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4933563",
    lng: "15.0405581",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011301",
  },
  {
    id: "34751",
    map_id: "1",
    address: "VIA UMBERTO CORICA 34/B,98069 SINAGRA (ME)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "38.0847015",
    lng: "14.8461465",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020026",
  },
  {
    id: "34750",
    map_id: "1",
    address: "VIA MARTIRI DELLA LOGGIA 2,84010 SAN MARZANO SUL SARNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.7752746",
    lng: "14.5899408",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009585",
  },
  {
    id: "34749",
    map_id: "1",
    address: "CORSO CAVOUR 68,19121 LA SPEZIA (SP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.1041629",
    lng: "9.8200546",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020033",
  },
  {
    id: "34748",
    map_id: "1",
    address: "CORSO GALLIANO 77,73048 NARDO' (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.1787388",
    lng: "18.0352843",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010213",
  },
  {
    id: "34742",
    map_id: "1",
    address: "VIA FRANCESCO SPIRITO 10,84099 SAN CIPRIANO PICENTINO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.718731",
    lng: "14.87262",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006497",
  },
  {
    id: "34738",
    map_id: "1",
    address: "VIA LOCATELLI 17,24045 FARA GERA D'ADDA (BG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.5561084",
    lng: "9.5346949",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003268",
  },
  {
    id: "34736",
    map_id: "1",
    address: "VIA OTTAVIANO 166,80040 SAN GENNARO VESUVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8594384",
    lng: "14.5197519",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006726",
  },
  {
    id: "34735",
    map_id: "1",
    address: "VIA GARIBALDI 107,86100 CAMPOBASSO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.5629667",
    lng: "14.660822",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001870",
  },
  {
    id: "34734",
    map_id: "1",
    address: "VIA MONSIGNOR II BOLOGNA 60,86100 CAMPOBASSO (CB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.5509969",
    lng: "14.6625237",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000488",
  },
  {
    id: "34733",
    map_id: "1",
    address: "VIA ALDO MORO 44,81022 CASAGIOVE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0742414",
    lng: "14.3093696",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009787",
  },
  {
    id: "34730",
    map_id: "1",
    address: "VIA TAGLIAMENTO 30,83100 AVELLINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.9169165",
    lng: "14.7850279",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010674",
  },
  {
    id: "34729",
    map_id: "1",
    address: "VIA FAMIGLIETTI 1,80040 VOLLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8763664",
    lng: "14.3455247",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004249",
  },
  {
    id: "34728",
    map_id: "1",
    address: "VLE G. DIVITTORIO 103,71042 CERIGNOLA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.265033",
    lng: "15.903074",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000712",
  },
  {
    id: "34726",
    map_id: "1",
    address: "VIA FUORNI 7,84131 SALERNO (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "40.6495095",
    lng: "14.8489147",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004475",
  },
  {
    id: "34724",
    map_id: "1",
    address: "VIA LOMBARDIA 59,23888 LA VALLETTA BRIANZA (LC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.7296834",
    lng: "9.3801861",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020080",
  },
  {
    id: "34723",
    map_id: "1",
    address: "VIA MAZZINI 37,92010 LAMPEDUSA E LINOSA (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "35.5020866",
    lng: "12.6102407",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011435",
  },
  {
    id: "34720",
    map_id: "1",
    address: "VIA G. GABRIBALDI 267,91014 CASTELLAMMARE DEL GOLFO (TP)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "38.022343",
    lng: "12.881011",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000217",
  },
  {
    id: "34718",
    map_id: "1",
    address: "VIA PALO 13,70020 BITETTO (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.040628",
    lng: "16.7473171",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012122",
  },
  {
    id: "34716",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE,19 -FRAZ VILLASMUNDO,96010 MELILLI (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.2513505",
    lng: "15.0926551",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020039",
  },
  {
    id: "34712",
    map_id: "1",
    address: "VIA NUNZIO LILLO 1,70029 SANTERAMO IN COLLE (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.7972158",
    lng: "16.7565685",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000950",
  },
  {
    id: "34710",
    map_id: "1",
    address: "VIA NAZIONALE 73/A,89064 MONTEBELLO JONICO (RC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.9432611",
    lng: "15.7117004",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004241",
  },
  {
    id: "34706",
    map_id: "1",
    address: "VIA MARE SNC,87074 ROCCA IMPERIALE (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.1003032",
    lng: "16.6122275",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020063",
  },
  {
    id: "34705",
    map_id: "1",
    address: "VIALE EUROPA 160,80053 CASTELLAMMARE DI STABIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.7011315",
    lng: "14.4891975",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002386",
  },
  {
    id: "34704",
    map_id: "1",
    address: "VIALE MAURO MILAZZO 72,95041 CALTAGIRONE (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "37.2342008",
    lng: "14.5162707",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020052",
  },
  {
    id: "34700",
    map_id: "1",
    address: "VIA NUORO SNC,08020 BUDONI (SS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.7037172",
    lng: "9.7014545",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005204",
  },
  {
    id: "34698",
    map_id: "1",
    address: "VIA ROMA 29,35020 MASERA' DI PADOVA (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.3196845",
    lng: "11.8680077",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011053",
  },
  {
    id: "34697",
    map_id: "1",
    address: "VIA MADRE ISABELLA DE ROSIS 52,87067 ROSSANO (CS)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "39.6086729",
    lng: "16.6265686",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005425",
  },
  {
    id: "34693",
    map_id: "1",
    address: "VIA G. LONGO SNC,82020 PADULI (BN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "41.1676235",
    lng: "14.8876679",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020085",
  },
  {
    id: "34690",
    map_id: "1",
    address: "VIA UMBERTO 98,94010 CENTURIPE (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.622352",
    lng: "14.74015",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000069",
  },
  {
    id: "34689",
    map_id: "1",
    address: "CNT RICCIO 92,66026 ORTONA (CH)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.3811208",
    lng: "14.3654217",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002934",
  },
  {
    id: "34688",
    map_id: "1",
    address: "VIALE SAN CONCORDIO 196,55100 LUCCA (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8361699",
    lng: "10.4999675",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005956",
  },
  {
    id: "34687",
    map_id: "1",
    address: "VIA DELLE GEMME 28,95121 CATANIA (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.4926323",
    lng: "15.0441654",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020057",
  },
  {
    id: "34686",
    map_id: "1",
    address: "CSO LIMONE PIEMONTE 58/A,18039 VENTIMIGLIA (IM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "43.8038243",
    lng: "7.5976414",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020034",
  },
  {
    id: "34684",
    map_id: "1",
    address: "VIA PIETRO GOBETTI EDIFICIO 23 SNC,80145 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8958181",
    lng: "14.2407014",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011620",
  },
  {
    id: "34683",
    map_id: "1",
    address: "VIA SAN GIORGIO VECCHIO 85,80046 SAN GIORGIO A CREMANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8323425",
    lng: "14.3470748",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020069",
  },
  {
    id: "34682",
    map_id: "1",
    address: "P.ZZA CAV. VITTORIO VENETO 1,10053 BUSSOLENO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID - ENEL ENERGIA",
    lat: "45.1364475",
    lng: "7.1502915",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020078",
  },
  {
    id: "34673",
    map_id: "1",
    address: "PIAZZA IV NOVEMBRE 14,30020 MARCON (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.5640639",
    lng: "12.2992011",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010362",
  },
  {
    id: "34671",
    map_id: "1",
    address: "VIA BORGO PADOVA 129,35013 CITTADELLA (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.6416684",
    lng: "11.7863861",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011054",
  },
  {
    id: "34668",
    map_id: "1",
    address: "CORSO MANFREDONIA 26,71030 ZAPPONETA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.4566284",
    lng: "15.9568376",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020100",
  },
  {
    id: "34665",
    map_id: "1",
    address: "VIA DEI LECCI 67,00062 BRACCIANO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.1018807",
    lng: "12.1592964",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002113",
  },
  {
    id: "34662",
    map_id: "1",
    address: "VIA ROMA 33,95047 PATERNO' (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.5659891",
    lng: "14.8983936",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002550",
  },
  {
    id: "34660",
    map_id: "1",
    address: "CSO VITTORIO EMANUELE 20,03018 PALIANO (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.8059539",
    lng: "13.0561582",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020060",
  },
  {
    id: "34659",
    map_id: "1",
    address: "VIA GARIBALDI 492,95045 MISTERBIANCO (CT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.5183825",
    lng: "15.0072663",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001144",
  },
  {
    id: "34658",
    map_id: "1",
    address: "VIA PONTE VASAGALLI, SNC,03029 VEROLI (FR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.6611888",
    lng: "13.4254753",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020074",
  },
  {
    id: "34655",
    map_id: "1",
    address: "VIA MARCO FULVIO NOBILIORE 128,00178 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.8619034",
    lng: "12.5670138",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003982",
  },
  {
    id: "34650",
    map_id: "1",
    address: "VIA ANTONIO MANGANO 16,71121 FOGGIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.4589352",
    lng: "15.5435857",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010423",
  },
  {
    id: "34647",
    map_id: "1",
    address: "VIA LIBERTA' 22,93100 CALTANISSETTA (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.4943225",
    lng: "14.0461498",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004932",
  },
  {
    id: "34635",
    map_id: "1",
    address: "VIALE DEI PLATANI 114/F,93017 SAN CATALDO (CL)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.491202",
    lng: "13.986578",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020071",
  },
  {
    id: "34631",
    map_id: "1",
    address: "VIA DI TORREVECCHIA 1021,00168 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.9132254",
    lng: "12.4055082",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010103",
  },
  {
    id: "34628",
    map_id: "1",
    address: "VIA RUSSO 93-95,84015 NOCERA SUPERIORE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.742004",
    lng: "14.678117",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000737",
  },
  {
    id: "34627",
    map_id: "1",
    address: "VIA BARI 39,74023 GROTTAGLIE (TA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.5356335",
    lng: "17.4323301",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005946",
  },
  {
    id: "34613",
    map_id: "1",
    address: "VIA VITTORIO AMEDEO 35,90018 TERMINI IMERESE (PA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "37.9852118",
    lng: "13.694109",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020018",
  },
  {
    id: "34610",
    map_id: "1",
    address: "PZA CESARE TERRANOVA, 10,73052 PARABITA (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.049472",
    lng: "18.1233485",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005322",
  },
  {
    id: "34608",
    map_id: "1",
    address: "VIA SANT'AGATA 75,94100 ENNA (EN)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.570006",
    lng: "14.2744656",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003473",
  },
  {
    id: "34605",
    map_id: "1",
    address: "VAI AUGUSTO RIGHI 4,80125 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8340947",
    lng: "14.1616757",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001003",
  },
  {
    id: "34600",
    map_id: "1",
    address: "VIA PIERO DELLA FRANCESCA 16,05100 TERNI (TR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "42.5644667",
    lng: "12.6435777",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006623",
  },
  {
    id: "34592",
    map_id: "1",
    address: "VIA SIRACUSA 148,96100 SIRACUSA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "37.0927696",
    lng: "15.2100484",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002013",
  },
  {
    id: "34591",
    map_id: "1",
    address: "VIA MARTIRI DI NASSIRYA 96,81043 CAPUA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "41.1083564",
    lng: "14.2309192",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010091",
  },
  {
    id: "34586",
    map_id: "1",
    address: "VIA ALBERT BRUCE SABIN 5,59100 PRATO (PO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.8456412",
    lng: "11.0719828",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020047",
  },
  {
    id: "34581",
    map_id: "1",
    address: "VIA NEMORENSE 152,00199 ROMA (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- VISURE",
    lat: "41.9292831",
    lng: "12.513753",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "EC-000009",
  },
  {
    id: "34580",
    map_id: "1",
    address: "VIA CRISTOFORO COLOMBO 3,96011 AUGUSTA (SR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.232388",
    lng: "15.2192159",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002290",
  },
  {
    id: "34547",
    map_id: "1",
    address: "FRA CASTORO 12,37017 CAVAION VERONESE (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.539726",
    lng: "10.7737857",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020020",
  },
  {
    id: "34536",
    map_id: "1",
    address: "VIA QUERCE 3,80044 OTTAVIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8442612",
    lng: "14.5082586",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012041",
  },
  {
    id: "34534",
    map_id: "1",
    address: "VIA POMINTELLA 97,80049 SOMMA VESUVIANA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.8710542",
    lng: "14.4205234",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000218",
  },
  {
    id: "34530",
    map_id: "1",
    address: "VIA SAN ROCCO 14 bis,10060 NONE (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "44.934481",
    lng: "7.5407891",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012356",
  },
  {
    id: "34528",
    map_id: "1",
    address:
      "TRAVERSA DI VIA SAN PIETRO 10,81055 SANTA MARIA CAPUA VETERE (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0798332",
    lng: "14.2536267",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012086",
  },
  {
    id: "34523",
    map_id: "1",
    address: "VIA CIRCONVALLAZIONE 54,80035 NOLA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "40.9199766",
    lng: "14.5345586",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000247",
  },
  {
    id: "34519",
    map_id: "1",
    address: "PIAZZA CARMINE 6,70025 GRUMO APPULA (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "41.0132884",
    lng: "16.7073954",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011814",
  },
  {
    id: "34506",
    map_id: "1",
    address: "CORSO PESCHIERA 271/B,10141 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.07068",
    lng: "7.63337",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000990",
  },
  {
    id: "34505",
    map_id: "1",
    address: "VIA ROMA 21,71021 ACCADIA (FG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE",
    lat: "41.1583878",
    lng: "15.3300506",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020011",
  },
  {
    id: "34504",
    map_id: "1",
    address: "VIA TOSCO ROMAGNOLA 2573,56021 CASCINA (PI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "43.6918027",
    lng: "10.4604234",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002603",
  },
  {
    id: "34488",
    map_id: "1",
    address: "VIA PISTOIESE 171,59100 PRATO (PO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "43.8856804",
    lng: "11.0857732",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000289",
  },
  {
    id: "34484",
    map_id: "1",
    address: "VIA CAVOUR 62,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9043476",
    lng: "14.2891784",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005527",
  },
  {
    id: "34477",
    map_id: "1",
    address: "VIA ARONA 2,10145 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.0824192",
    lng: "7.6473969",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020013",
  },
  {
    id: "34460",
    map_id: "1",
    address: "VIA NICOLA FERRAMOSCA 124,73024 MAGLIE (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.1243525",
    lng: "18.2930676",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012199",
  },
  {
    id: "34457",
    map_id: "1",
    address: "VIA ANIELLO PALUMBO 55,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9262086",
    lng: "14.2002011",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020012",
  },
  {
    id: "34452",
    map_id: "1",
    address: "CSO VITTORIO EMENUELE II 120,70038 TERLIZZI (BA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.1303939",
    lng: "16.5444793",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006825",
  },
  {
    id: "34434",
    map_id: "1",
    address: "VIA PROV.LE PER ERCHIE 74/A,72028 TORRE SANTA SUSANNA (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.4560514",
    lng: "17.7355604",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002835",
  },
  {
    id: "34433",
    map_id: "1",
    address: "VIA CONCERIA 3,92020 SAN GIOVANNI GEMINI (AG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "37.6293741",
    lng: "13.6419366",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002963",
  },
  {
    id: "34428",
    map_id: "1",
    address: "VIA DI VALLE SCHIOIA  86/C,00042 ANZIO (RM)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "41.5007217",
    lng: "12.6220093",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020029",
  },
  {
    id: "34427",
    map_id: "1",
    address: "VIA CENISCHIA 38/E,10139 TORINO (TO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.0695117",
    lng: "7.6430387",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009980",
  },
  {
    id: "34426",
    map_id: "1",
    address: "CONTRADA LE GRAZIE 26,62029 TOLENTINO (MC)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.1889282",
    lng: "13.2658722",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-003187",
  },
  {
    id: "34424",
    map_id: "1",
    address: "VIA CELLINO 61,72025 SAN DONACI (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.4492867",
    lng: "17.9259671",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020017",
  },
  {
    id: "34423",
    map_id: "1",
    address: "VIA NAZIONALE 10,83024 MONTEFORTE IRPINO (AV)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "40.8900077",
    lng: "14.7146804",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-004052",
  },
  {
    id: "34422",
    map_id: "1",
    address: "VIA COLONNELLO ALIPERTA 13,80049 SOMMA VESUVIANA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.869888",
    lng: "14.4332042",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000663",
  },
  {
    id: "34406",
    map_id: "1",
    address: "PZA XXIV MAGGIO SNC,84028 SERRE (SA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.58192",
    lng: "15.1844657",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-002539",
  },
  {
    id: "34404",
    map_id: "1",
    address: "CSO DEL CARPINE 34,80030 VISCIANO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9241391",
    lng: "14.5841805",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000326",
  },
  {
    id: "34402",
    map_id: "1",
    address: "VLE MICHELANGELO 12,81100 CASERTA (CE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.0811216",
    lng: "14.3439385",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-006597",
  },
  {
    id: "34398",
    map_id: "1",
    address: "VIA R.A.MIGLIETTA 67-67/A,73020 CAVALLINO (LE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.3146683",
    lng: "18.1975746",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-001921",
  },
  {
    id: "34397",
    map_id: "1",
    address: "VIA PAOLO DELLA VALLE 86,80126 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.8470799",
    lng: "14.2050176",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009742",
  },
  {
    id: "34385",
    map_id: "1",
    address: "VIA IRPINIA 25,72100 BRINDISI (BR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.6271265",
    lng: "17.931649",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007243",
  },
  {
    id: "33383",
    map_id: "1",
    address: "VIA DUCA D'AOSTA 69/A,80026 CASORIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "40.9141865",
    lng: "14.3003571",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020000",
  },
  {
    id: "33382",
    map_id: "1",
    address: "VIA ROMA 199,35010 VIGODARZERE (PD)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH",
    lat: "45.457742",
    lng: "11.8824668",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009889",
  },
  {
    id: "33380",
    map_id: "1",
    address: "VIA VOLGA 2,97013 COMISO (RG)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "37.0223944",
    lng: "14.5792722",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012105",
  },
  {
    id: "33378",
    map_id: "1",
    address: "VIA EMILIA 14,58100 GROSSETO (GR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "42.7694264",
    lng: "11.1124405",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020007",
  },
  {
    id: "33377",
    map_id: "1",
    address: "VIA LEONARDO DA VINCI 27,37138 VERONA (VR)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.4377771",
    lng: "10.9715671",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-010142",
  },
  {
    id: "33375",
    map_id: "1",
    address: "VIA BATTAGLIA 9,36075 MONTECCHIO MAGGIORE (VI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- SPID",
    lat: "45.491609",
    lng: "11.4340423",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-012128",
  },
  {
    id: "33374",
    map_id: "1",
    address: "VIA DELLE QUERCE 24,76016 MARGHERITA DI SAVOIA (BT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "41.3647745",
    lng: "16.1519184",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-007540",
  },
  {
    id: "33373",
    map_id: "1",
    address: "VIA DANTE ALIGHIERI 1,20900 MONZA (MB)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "45.5874822",
    lng: "9.2723154",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000687",
  },
  {
    id: "33355",
    map_id: "1",
    address: "VIA MADONNA DELLE GRAZIE, 95,80014 GIUGLIANO IN CAMPANIA (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.9203969",
    lng: "14.2112096",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-020002",
  },
  {
    id: "33352",
    map_id: "1",
    address: "VIA ROMANA OVEST 29,55016 PORCARI (LU)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.8441036",
    lng: "10.617236",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005897",
  },
  {
    id: "33346",
    map_id: "1",
    address: "VIA CUPA PRINCIPE 3,80143 NAPOLI (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "40.8742629",
    lng: "14.3037753",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000670",
  },
  {
    id: "33334",
    map_id: "1",
    address: "VIA G. MATTEOTTI 2/B,53027 SAN QUIRICO D'ORCIA (SI)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24 - ENEL ENERGIA",
    lat: "43.0576978",
    lng: "11.606722",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-000966",
  },
  {
    id: "33327",
    map_id: "1",
    address: "CORSO ALESSANDRO VOLTA 131,80040 TERZIGNO (NA)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- SPID",
    lat: "40.7973101",
    lng: "14.5017136",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011443",
  },
  {
    id: "33307",
    map_id: "1",
    address: "P.ZA MERCATALE 155,59100 PRATO (PO)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24- VISURE- SPID",
    lat: "43.8797379",
    lng: "11.101704",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-009176",
  },
  {
    id: "33299",
    map_id: "1",
    address: "VIA FRANCESCA NORD 666,51015 MONSUMMANO TERME (PT)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "43.8798139",
    lng: "10.8140398",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-005532",
  },
  {
    id: "33291",
    map_id: "1",
    address: "VIA CESARE BATTISTI 69,30016 JESOLO (VE)",
    description:
      "BOLLETTINI - RICARICHE - PROMO130 - RICARICAQUI - RICARICACASH- F24",
    lat: "45.5366281",
    lng: "12.6437182",
    title: "Enel X",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ex-011066",
  },
  {
    id: "32627",
    map_id: "1",
    address: "VIA PROVINCIALE 128,89025 ROSARNO (RC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.4829913",
    lng: "15.9782477",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8748",
  },
  {
    id: "32620",
    map_id: "1",
    address: "VIA GORGOLA SNC,84024 CONTURSI TERME (SA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.6485681",
    lng: "15.2424698",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8703",
  },
  {
    id: "32578",
    map_id: "1",
    address: "C.SO LAURETANO 97,66020 TORINO DI SANGRO (CH)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.1860567",
    lng: "14.5401747",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8737",
  },
  {
    id: "32419",
    map_id: "1",
    address: "VIA LUDOVICO IL MORO 63,27100 PAVIA (PV)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.1945138",
    lng: "9.1579628",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8686",
  },
  {
    id: "32403",
    map_id: "1",
    address: "CIRCONVALLAZIONE NOMENTANA 554,00162 ROMA (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.9107528",
    lng: "12.5288435",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8629",
  },
  {
    id: "32195",
    map_id: "1",
    address: "VIA NETTUNENSE 69,00041 ALBANO LAZIALE (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.6957218",
    lng: "12.6495049",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8707",
  },
  {
    id: "31060",
    map_id: "1",
    address: "PIAZZA PAPA GIOVANNI XXIII 52,83042 ATRIPALDA (AV)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.9219414",
    lng: "14.8360072",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8699",
  },
  {
    id: "31029",
    map_id: "1",
    address: "VIA APROSIO 21E,18039 VENTIMIGLIA (IM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "43.7919772",
    lng: "7.6071339",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8638",
  },
  {
    id: "31026",
    map_id: "1",
    address: "VIA GALENO 32,87064 CORIGLIANO CALABRO (CS)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.6469979",
    lng: "16.5506667",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8620",
  },
  {
    id: "30917",
    map_id: "1",
    address: "PIAZZA PARTIGIANI 17,43044 COLLECCHIO (PR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "44.7523484",
    lng: "10.2184108",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8659",
  },
  {
    id: "30914",
    map_id: "1",
    address: "VIA XX SETTEMBRE 17,13100 VERCELLI (VC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.3223011",
    lng: "8.4192237",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8657",
  },
  {
    id: "30878",
    map_id: "1",
    address: "VIA GRAMSCI 70,43036 FIDENZA (PR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "44.8647392",
    lng: "10.0614902",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8655",
  },
  {
    id: "30823",
    map_id: "1",
    address: "UGO FOSCOLO 2,74019 PALAGIANO (TA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.5791805",
    lng: "17.0410453",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8671",
  },
  {
    id: "30819",
    map_id: "1",
    address: "VIA GALILEO FERRARIS 21/A,13048 SANTHIA' (VC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.3637442",
    lng: "8.1727236",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8653",
  },
  {
    id: "30741",
    map_id: "1",
    address: "VIA PALAZZO 124,04023 FORMIA (LT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.2639415",
    lng: "13.6356836",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8625",
  },
  {
    id: "29638",
    map_id: "1",
    address: "VIA SALVATORE MITIDIERI 21,87014 LAINO BORGO (CS)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.9532572",
    lng: "15.9746583",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8678",
  },
  {
    id: "29593",
    map_id: "1",
    address: "VIA STRADELLA 193,10147 TORINO (TO)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.101403",
    lng: "7.6670572",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8665",
  },
  {
    id: "29556",
    map_id: "1",
    address: "Piazza San Tommaso, 32,03031 AQUINO (FR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.49341",
    lng: "13.7015",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8649",
  },
  {
    id: "29554",
    map_id: "1",
    address: "VIA ATTILIO MICHELUZZI 138-140,80144 NAPOLI (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.9084133",
    lng: "14.2436246",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8547",
  },
  {
    id: "29515",
    map_id: "1",
    address: "VIALE A. DE GASPERI 19,63076 MONTEPRANDONE (AP)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.8988159",
    lng: "13.856775",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8635",
  },
  {
    id: "29443",
    map_id: "1",
    address: "VIA VITTORIO VENETO 79 C,80145 NAPOLI (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.8878975",
    lng: "14.2453115",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8572",
  },
  {
    id: "29427",
    map_id: "1",
    address: "corso della repubblica 302a,04012 CISTERNA DI LATINA (LT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.5882118",
    lng: "12.8297577",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8564",
  },
  {
    id: "28351",
    map_id: "1",
    address: "VIA FRANCESCO PAGLIA 44/A,88100 CATANZARO (CZ)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.9186278",
    lng: "16.5867157",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8573",
  },
  {
    id: "28330",
    map_id: "1",
    address: "VIA BORZOLI, 28 R,16153 GENOVA (GE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "44.4247771",
    lng: "8.8550059",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8613",
  },
  {
    id: "28305",
    map_id: "1",
    address: "VIA GIUSEPPE GARIBALDI 57,88070 BOTRICELLO (CZ)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.934508",
    lng: "16.8567",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8598",
  },
  {
    id: "28293",
    map_id: "1",
    address: "VIA DUOMO 236-240,74123 TARANTO (TA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.4745087",
    lng: "17.2318732",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8534",
  },
  {
    id: "28287",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 327,80030 CASTELLO DI CISTERNA (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.917316",
    lng: "14.4123062",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8589",
  },
  {
    id: "27268",
    map_id: "1",
    address: "VIA XXV APRILE, 7,89047 ROCCELLA JONICA (RC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.3222761",
    lng: "16.4047525",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8586",
  },
  {
    id: "27216",
    map_id: "1",
    address: "VIA DEI CINQUE ARCHI 16,04011 APRILIA (LT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.5374234",
    lng: "12.7174882",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8544",
  },
  {
    id: "27202",
    map_id: "1",
    address: "VIA NICCOLO' FOSCARINI 27,73100 LECCE (LE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.3506494",
    lng: "18.1807649",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8577",
  },
  {
    id: "27051",
    map_id: "1",
    address:
      "VIA I. CALVINO P.SSO C.TRO COMMERCIALE LE PALME,00019 TIVOLI (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.9527132",
    lng: "12.7182947",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8568",
  },
  {
    id: "27032",
    map_id: "1",
    address: "VIA DEGLI STUDI  2,89026 SAN FERDINANDO (RC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.4840942",
    lng: "15.9164066",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8532",
  },
  {
    id: "25829",
    map_id: "1",
    address: "VIA TRIESTE 13,74020 LEPORANO (TA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.3840338",
    lng: "17.3332617",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8526",
  },
  {
    id: "25812",
    map_id: "1",
    address: "VIA JESINA 27 G,60022 CASTELFIDARDO (AN)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "43.4483576",
    lng: "13.5393764",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8500",
  },
  {
    id: "25778",
    map_id: "1",
    address: "PIAZZA S.BARBARA 44,95047 PATERNO' (CT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.5671643",
    lng: "14.8959657",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8496",
  },
  {
    id: "25714",
    map_id: "1",
    address: "VIALE OLEANDRI 59 PINETA MARE,81030 CASTEL VOLTURNO (CE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.9731019",
    lng: "13.9870577",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8531",
  },
  {
    id: "25702",
    map_id: "1",
    address: "VIA C. RICUCCI SNC,87022 CETRARO (CS)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.517292",
    lng: "15.941843",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8520",
  },
  {
    id: "25692",
    map_id: "1",
    address: "VIA DELLE REPUBBLICHE MARINARE 481,80147 NAPOLI (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.8309527",
    lng: "14.323074",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8509",
  },
  {
    id: "25634",
    map_id: "1",
    address: "VIA M. AMARI 5,98076 SANT'AGATA DI MILITELLO (ME)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.0654571",
    lng: "14.6276091",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8487",
  },
  {
    id: "25543",
    map_id: "1",
    address: "PIAZZA MD SENNA 2,27020 BATTUDA (PV)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.2732485",
    lng: "9.077764",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8494",
  },
  {
    id: "24407",
    map_id: "1",
    address: "VIALE DEGLI ASTRONAUTI 22,83038 MONTEMILETTO (AV)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.0106081",
    lng: "14.9074198",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8493",
  },
  {
    id: "24373",
    map_id: "1",
    address: "CORSO MUNAZIO PLANCO 166,03042 ATINA (FR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.6331895",
    lng: "13.7896261",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8474",
  },
  {
    id: "24359",
    map_id: "1",
    address: "VIA S. NULLO 166/C,80014 GIUGLIANO IN CAMPANIA (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.8870651",
    lng: "14.079979",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8462",
  },
  {
    id: "24182",
    map_id: "1",
    address: "VICO VIII PROVINCIALE AGROMONTE MILEO 21,85043 LATRONICO (PZ)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.0581549",
    lng: "16.0303836",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8471",
  },
  {
    id: "21588",
    map_id: "1",
    address: "VIA GARIBALDI 3,36025 NOVENTA VICENTINA (VI)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.2894481",
    lng: "11.5375561",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8449",
  },
  {
    id: "21343",
    map_id: "1",
    address: "VIALE GRIGOLETTI 36/A,33170 PORDENONE (PN)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.9634771",
    lng: "12.6516585",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8413",
  },
  {
    id: "21329",
    map_id: "1",
    address: "V.LE GRIGORETTI 31,33170 PORDENONE (PN)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.9638492",
    lng: "12.6488304",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8412",
  },
  {
    id: "21325",
    map_id: "1",
    address: "VIA COSTANTINOPOLI, 54,74026 PULSANO (TA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.3849478",
    lng: "17.3547455",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8408",
  },
  {
    id: "21300",
    map_id: "1",
    address: "VIA ROMA 4,81030 CASTEL VOLTURNO (CE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.0346794",
    lng: "13.9413685",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8400",
  },
  {
    id: "20212",
    map_id: "1",
    address: "VIA XXV APRILE 2-4,80040 POGGIOMARINO (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.796611",
    lng: "14.5420181",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8371",
  },
  {
    id: "18878",
    map_id: "1",
    address: "VIA ETTORE ROMAGNOLI, 110,93012 GELA (CL)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.0691057",
    lng: "14.2350265",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8352",
  },
  {
    id: "18848",
    map_id: "1",
    address: "CORSO GROSSETO, 366/4,10151 TORINO (TO)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.1069837",
    lng: "7.6460276",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-0127",
  },
  {
    id: "16244",
    map_id: "1",
    address: "VIA MANZONI, 96,70122 BARI (BA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.1225497",
    lng: "16.8611618",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8299",
  },
  {
    id: "16117",
    map_id: "1",
    address: "C.SO FEDERICI 30,84010 CETARA (SA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.6485049",
    lng: "14.7004673",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8303",
  },
  {
    id: "13559",
    map_id: "1",
    address: "VIA SIRIS 148,75025 POLICORO (MT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.204703",
    lng: "16.672858",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8302",
  },
  {
    id: "12013",
    map_id: "1",
    address: "VIA APPIA 1233,,04026 MINTURNO (LT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.2526632",
    lng: "13.724708",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8272",
  },
  {
    id: "12010",
    map_id: "1",
    address: "VIA ANTONIO GRAMSCI, 13,88050 AMARONI (CZ)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.7925148",
    lng: "16.447368",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8270",
  },
  {
    id: "11756",
    map_id: "1",
    address: "VIA MATTIA PRETI SNC,88812 CRUCOLI (KR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.421958",
    lng: "17.005962",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8261",
  },
  {
    id: "11642",
    map_id: "1",
    address: "VIA CASA SAVOIA, 240/C,89135 REGGIO CALABRIA (RC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.1700817",
    lng: "15.6582583",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8249",
  },
  {
    id: "10277",
    map_id: "1",
    address: "P.ZZA S. ATTANASIO 9,87048 SANTA SOFIA D'EPIRO (CS)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.5472893",
    lng: "16.3288945",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8209",
  },
  {
    id: "10196",
    map_id: "1",
    address: "VIA VITTORIO EMANUELE 220,74020 SAN MARZANO DI SAN GIUSEPPE (TA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.45374",
    lng: "17.509048",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8210",
  },
  {
    id: "10182",
    map_id: "1",
    address: "VIA C. ALVARO 60,89013 GIOIA TAURO (RC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.4278396",
    lng: "15.9029494",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8206",
  },
  {
    id: "10020",
    map_id: "1",
    address: "VIA BACHELET 29,88837 PETILIA POLICASTRO (KR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.1397332",
    lng: "16.7540998",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8182",
  },
  {
    id: "9857",
    map_id: "1",
    address: "VIA G.DI VITTORIO PRESSO TERMINAL ROMANO,88900 CROTONE (KR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.084438",
    lng: "17.114945",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8176",
  },
  {
    id: "9717",
    map_id: "1",
    address: "P. ZZA CARLO DI BORBONE 6,80046 SAN GIORGIO A CREMANO (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.829041",
    lng: "14.3338609",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8172",
  },
  {
    id: "9644",
    map_id: "1",
    address: "VIA FAUGLIA SNC,00176 ROMA (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.848307",
    lng: "12.4600448",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8166",
  },
  {
    id: "9375",
    map_id: "1",
    address: "VIA DELLA VITTORIA 34 S,93010 MILENA (CL)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.4721984",
    lng: "13.7341421",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-8157",
  },
  {
    id: "9172",
    map_id: "1",
    address: "VIA PERRUCCHETTI, 9,00159 ROMA (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.8986598",
    lng: "12.5347284",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9942",
  },
  {
    id: "8865",
    map_id: "1",
    address: "VIA MONTEVERGINE137,70018 RUTIGLIANO (BA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.0037833",
    lng: "17.0006338",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9987",
  },
  {
    id: "6718",
    map_id: "1",
    address: "VIA BERTUCCIONI 48 R,16139 GENOVA (GE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "44.4181452",
    lng: "8.955836",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9860",
  },
  {
    id: "6695",
    map_id: "1",
    address: "VIA NAZIONALE 5,76017 SAN FERDINANDO DI PUGLIA (BT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.3028736",
    lng: "16.0676281",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9864",
  },
  {
    id: "5616",
    map_id: "1",
    address: "VIA BELLUNO 21,00161 ROMA (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.9117345",
    lng: "12.5167817",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9881",
  },
  {
    id: "5543",
    map_id: "1",
    address: "CORSO MATTEOTTI 122,71014 SAN MARCO IN LAMIS (FG)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.7131518",
    lng: "15.6358369",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9848",
  },
  {
    id: "5489",
    map_id: "1",
    address: "VIA FRENTANA,37,86036 MONTENERO DI BISACCIA (CB)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.9597961",
    lng: "14.7819758",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9843",
  },
  {
    id: "4359",
    map_id: "1",
    address: "VIA CAVOUR 97,00028 SUBIACO (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.924694",
    lng: "13.0966908",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9771",
  },
  {
    id: "4315",
    map_id: "1",
    address: "VIA ROMA 31,82010 MOIANO (BN)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.0761423",
    lng: "14.5726742",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9668",
  },
  {
    id: "4291",
    map_id: "1",
    address: "VIA NAPOLI 91,63100 ASCOLI PICENO (AP)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.8504447",
    lng: "13.591499",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-2913",
  },
  {
    id: "4282",
    map_id: "1",
    address: "PIAZZA SANT'EFISIO 1,09170 ORISTANO (OR)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.9023452",
    lng: "8.5990241",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9708",
  },
  {
    id: "4197",
    map_id: "1",
    address: "PIAZZA SAN PIETRO,1,81055 SANTA MARIA CAPUA VETERE (CE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.0813794",
    lng: "14.2594268",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-1899",
  },
  {
    id: "4138",
    map_id: "1",
    address: "VIA ROSSETTI 34,80125 NAPOLI (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.8299424",
    lng: "14.2008247",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9589",
  },
  {
    id: "4128",
    map_id: "1",
    address: "VIA RISORGIMENTO 157,97015 MODICA (RG)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "36.8409066",
    lng: "14.7767887",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9568",
  },
  {
    id: "4120",
    map_id: "1",
    address: "VIA SAN ROCCO 3,65017 PENNE (PE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.4611803",
    lng: "13.9219012",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9527",
  },
  {
    id: "4061",
    map_id: "1",
    address: "VIA MADONNA DEL ROSARIO 18,74010 STATTE (TA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.5611146",
    lng: "17.2091248",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9534",
  },
  {
    id: "4047",
    map_id: "1",
    address: "VIA DONIZETTI 2,70018 RUTIGLIANO (BA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.0084959",
    lng: "17.0076231",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9503",
  },
  {
    id: "4019",
    map_id: "1",
    address: "VIA REPUBBLICA 36/A,70015 NOCI (BA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.791181",
    lng: "17.1272213",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9490",
  },
  {
    id: "3966",
    map_id: "1",
    address: "VIA SAN GIOVANNI 29,82037 TELESE TERME (BN)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.2105683",
    lng: "14.5073491",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9452",
  },
  {
    id: "3926",
    map_id: "1",
    address: "VIA NAZARIO SAURO 40,95123 CATANIA (CT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.5107228",
    lng: "15.0608887",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9410",
  },
  {
    id: "3906",
    map_id: "1",
    address: "VIA CICOLANA 67,67062 MAGLIANO DE' MARSI (AQ)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.0919899",
    lng: "13.362776",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9414",
  },
  {
    id: "1729",
    map_id: "1",
    address: "VIA VIVIANI 14,81024 MADDALONI (CE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.0306157",
    lng: "14.3760463",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-3872",
  },
  {
    id: "1714",
    map_id: "1",
    address: "PIAZZA LIBERTA' 11/12,97015 MODICA (RG)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "36.841939",
    lng: "14.7663783",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9206",
  },
  {
    id: "1712",
    map_id: "1",
    address: "VIALE EUROPA 31,88060 SATRIANO (CZ)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.6793449",
    lng: "16.538618",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9286",
  },
  {
    id: "1685",
    map_id: "1",
    address: "CORSO EUROPA 44,07039 VALLEDORIA (SS)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.9289329",
    lng: "8.8254403",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9278",
  },
  {
    id: "1660",
    map_id: "1",
    address: "CORSO VITTORIO EMANUELE 27,84010 MINORI (SA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.650572",
    lng: "14.626651",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9273",
  },
  {
    id: "1622",
    map_id: "1",
    address: "VIALE SICILIA 134-136,93100 CALTANISSETTA (CL)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.4921893",
    lng: "14.0469711",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9248",
  },
  {
    id: "1577",
    map_id: "1",
    address: "VIALE ALDO MORO 2/B,95048 SCORDIA (CT)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.289074",
    lng: "14.8373675",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9214",
  },
  {
    id: "1574",
    map_id: "1",
    address: "PIAZZA UMBERTO I 51,92010 REALMONTE (AG)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.307597",
    lng: "13.462687",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9211",
  },
  {
    id: "1573",
    map_id: "1",
    address: "VIA FONTE REGINA 4,64100 TERAMO (TE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.6600851",
    lng: "13.7139655",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-1308",
  },
  {
    id: "1562",
    map_id: "1",
    address: "VIA SS. FABIANO E SEBASTIANO, 34,35143 PADOVA (PD)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.3968516",
    lng: "11.8377277",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9180",
  },
  {
    id: "1469",
    map_id: "1",
    address: "VIA PASSANTI 211,80047 SAN GIUSEPPE VESUVIANO (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.8228585",
    lng: "14.5039313",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9173",
  },
  {
    id: "1323",
    map_id: "1",
    address: "Via Leuca 221 D,73100 LECCE (LE)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.3398813",
    lng: "18.176684",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9154",
  },
  {
    id: "1184",
    map_id: "1",
    address: "VIA NAPOLI 222,80022 ARZANO (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.9075336",
    lng: "14.2653935",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-3843",
  },
  {
    id: "1146",
    map_id: "1",
    address: "CORSO UMBERTO PRIMO, 435,98035 GIARDINI-NAXOS (ME)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "37.8511539",
    lng: "15.2819222",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-4078",
  },
  {
    id: "955",
    map_id: "1",
    address: "VIALE GIOVANNI SUZZANI, 270,20162 MILANO (MI)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "45.5259828",
    lng: "9.2089648",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-0201",
  },
  {
    id: "921",
    map_id: "1",
    address: "VIA FRANCESCO SAVERIO 3/5,80048 SANT'ANASTASIA (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.8672963",
    lng: "14.4029558",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-3833",
  },
  {
    id: "900",
    map_id: "1",
    address: "VIA CRISANZIO 138,70123 BARI (BA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.1193417",
    lng: "16.8616212",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-3514",
  },
  {
    id: "896",
    map_id: "1",
    address: "VIA G. PORZIO 4 - IS G/8,80143 NAPOLI (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.8575832",
    lng: "14.2780123",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-1894",
  },
  {
    id: "893",
    map_id: "1",
    address: "VIA DELLA REPUBBLICA 34,00060 RIANO (RM)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.1009776",
    lng: "12.5043058",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9088",
  },
  {
    id: "774",
    map_id: "1",
    address: "VIA ISONZO 9,89029 TAURIANOVA (RC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.3526908",
    lng: "16.0139016",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-1434",
  },
  {
    id: "730",
    map_id: "1",
    address: "VIA RAFFAELE AVERSA 107,83042 ATRIPALDA (AV)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.9202373",
    lng: "14.8353956",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9065",
  },
  {
    id: "709",
    map_id: "1",
    address: "VIA LATERZA 7,70125 BARI (BA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "41.1119205",
    lng: "16.8716011",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-1504",
  },
  {
    id: "691",
    map_id: "1",
    address: "VIA G. VOLTATTORNI 69,63074 SAN BENEDETTO DEL TRONTO (AP)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "42.9413608",
    lng: "13.8842907",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-2901",
  },
  {
    id: "683",
    map_id: "1",
    address: "VIA EPOMEO, 2,80070 SERRARA FONTANA (NA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "40.7198697",
    lng: "13.8989208",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-9061",
  },
  {
    id: "557",
    map_id: "1",
    address: "VIALE REGINA MARGHERITA, 12,89023 LAUREANA DI BORRELLO (RC)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.4904389",
    lng: "16.080471",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-3405",
  },
  {
    id: "452",
    map_id: "1",
    address: "VIA MARIANO STABILE 246,90141 PALERMO (PA)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "38.1216048",
    lng: "13.3543936",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-4043",
  },
  {
    id: "87",
    map_id: "1",
    address: "VIA TORRE PISANI 17,87067 ROSSANO (CS)",
    description: "BOLLETTINI - RICARICHE - PROMO130",
    lat: "39.5967806",
    lng: "16.6326194",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-1410",
  },
  {
    id: "39",
    map_id: "1",
    address: "via pascoli SNC,64023 MOSCIANO SANT'ANGELO (TE)",
    description: "BOLLETTINI - RICARICHE - PROMO130 - F24",
    lat: "42.7242625",
    lng: "13.912339",
    title: "QuiPAY",
    infoopen: "0",
    category: "",
    approved: "1",
    retina: "0",
    agency_code: "ag-0000",
  },
];
