import React, { Fragment, Component } from "react";

import Head from "src/components/generics/Head";

import MapWithMarkers from "src/components/ui/MapWithMarkers";

class PageMap extends Component {
  render() {
    return (
      <Fragment>
        <Head
          title="Tutti i servizi di cui hai bisogno, vicino casa tua"
          description="Una rete di agenzie, convenzionate con PayTipper S.p.A e CityPoste Payment S.p.A. (società Gruppo Enel), per offrirti i servizi di cui hai bisogno."
          path="dove-siamo"
          imageName=""
        />
        <MapWithMarkers
          title="Trova il servizio più vicino a te"
          serviceType="promo130"
        />
      </Fragment>
    );
  }
}

export default PageMap;
