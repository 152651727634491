import React, { Fragment, Component } from "react";
import "./style.scss";

class Heading extends Component {
  render() {
    return (
      <Fragment>
        <div
          className={"heading" + (this.props.noBottomPadding ? " pb-0" : "")}
        >
          <div className="center">
            {this.props.title && (
              <h2 className="font-black">{this.props.title}</h2>
            )}
            {this.props.subtitle && (
              <h3 className="font-light">{this.props.subtitle}</h3>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Heading;
