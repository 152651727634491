import React, { Fragment, Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// Structure
// import Header from "./components/ui/Header";
// import Footer from "./components/ui/Footer";

// Pages
// import Home from "./pages/Home";
// import DoveSiamo from "./pages/DoveSiamo";
// import RicaricaQui from "./pages/RicaricaQui";
// import ContiCosti from "./pages/Conti/Costi";
// import NotFound from "./pages/NotFound";

// import AssistenzaFaq from "./pages/Assistenza/Faq";
// import AssistenzaCategoria from "./pages/Assistenza/Categoria";

// import PagamentiOverview from "./pages/Pagamenti/Overview";
// import PagamentiBolletteBollettini from "./pages/Pagamenti/BolletteBollettini";
// import PagamentiMavRav from "./pages/Pagamenti/MavRav";
// import PagamentiFreccia from "./pages/Pagamenti/Freccia";
// import PagamentiPagoPA from "./pages/Pagamenti/PagoPA";
// import PagamentiBolloAuto from "./pages/Pagamenti/BolloAuto";
// import PagamentiMulte from "./pages/Pagamenti/Multe";
// import PagamentiRicaricheTelefoniche from "./pages/Pagamenti/RicaricheTelefoniche";
// import PagamentiTipologie from "./pages/Pagamenti/Tipologie";
// import PagamentiGuida from "./pages/Pagamenti/Guida";
// import PagamentiLista from "./pages/Pagamenti/Lista";
// import FunzionalitaPagamentiOnline from "./pages/Funzionalita/PagamentiOnline";
// import FunzionalitaIBANeMastercard from "./pages/Funzionalita/IBANeMastercard";
// import FunzionalitaGestisciGruppo from "./pages/Funzionalita/GestisciGruppo";
// import FunzionalitaGestisciSpese from "./pages/Funzionalita/GestisciSpese";
// import FunzionalitaGestisciAccount from "./pages/Funzionalita/GestisciAccount";
// import FunzionalitaDividiSpesa from "./pages/Funzionalita/DividiSpesa";
// import FunzionalitaCashBack from "./pages/Funzionalita/CashBack";
// import FunzionalitaApplePay from "./pages/Funzionalita/ApplePay";
// import FunzionalitaGooglePay from "./pages/Funzionalita/GooglePay";
// import FunzionalitaSicurezza from "./pages/Funzionalita/Sicurezza";
// import ChiSiamo from "./pages/ChiSiamo";

import PageMap from "./pages/Map";

// import VieniATrovarciPagoPA from "./pages/DoveSiamo/PagoPA";
// import VieniATrovarciBollettiniPostaliBancari from "./pages/DoveSiamo/BollettiniPostaliBancari";
// import VieniATrovarciOfferteEE from "./pages/DoveSiamo/OfferteEE";
// import VieniATrovarciRicaricheTelefoniche from "./pages/DoveSiamo/RicaricheTelefoniche";
// import VieniATrovarciSpedizioni from "./pages/DoveSiamo/Spedizioni";
// import VieniATrovarciF24 from "./pages/DoveSiamo/F24";
// import VieniATrovarciVisure from "./pages/DoveSiamo/Visure";
// import VieniATrovarciRicaricaInContanti from "./pages/DoveSiamo/RicaricaInContanti";

// import EsercentiHome from "./pages/Esercenti";
// import EsercentiAffiliati from "./pages/Esercenti/Affiliati";
// import EsercentiRichiestaNatale from "./pages/Esercenti/RichiestaNatale";
// import EsercentiRichiestaNataleInviata from "./pages/Esercenti/RichiestaNataleInviata";
// import EsercentiRichiesta from "./pages/Esercenti/Richiesta";
// import EsercentiRichiestaInviata from "./pages/Esercenti/RichiestaInviata";
// import EsercentServiziJuicePass from "./pages/Esercenti/Servizi/JuicePass";
// import EsercentServiziRicaricaInContanti from "./pages/Esercenti/Servizi/RicaricaInContanti";
// import EsercentServiziRicaricaQui from "./pages/Esercenti/Servizi/RicaricaQui";
// import EsercentServiziSpedizioni from "./pages/Esercenti/Servizi/Spedizioni";
// import EsercentServiziSpid from "./pages/Esercenti/Servizi/Spid";
// import EsercentServiziVisure from "./pages/Esercenti/Servizi/Visure";

// import ContiStandard from "./pages/Conti/Standard";
// import ContiFamily from "./pages/Conti/Family";

// import Download from "./pages/Download";

// import CookiePolicy from "./pages/CookiePolicy";
// import FoglioInformativo from "./pages/FoglioInformativo";

// import PercheRegistrarsi from "./pages/PercheRegistrarsi";
// import Registrazione from "./pages/Registrazione";

// import Loyalty from "./pages/Loyalty";

// Style
import "./App.css";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          {/* <Header /> */}
          <Switch>
            {/* <Route exact path="/" component={Home} />

            <Route
              exact
              path="/pagamenti/overview"
              component={PagamentiOverview}
            />
            <Route
              exact
              path="/pagamenti/bollete-bollettini"
              component={PagamentiBolletteBollettini}
            />
            <Route
              exact
              path="/pagamenti/mav-rav"
              component={PagamentiMavRav}
            />
            <Route
              exact
              path="/pagamenti/bolletino-freccia"
              component={PagamentiFreccia}
            />
            <Route
              exact
              path="/pagamenti/pago-pa"
              component={PagamentiPagoPA}
            />
            <Route
              exact
              path="/pagamenti/bollo-auto"
              component={PagamentiBolloAuto}
            />
            <Route exact path="/pagamenti/multe" component={PagamentiMulte} />
            <Route
              exact
              path="/pagamenti/ricariche-telefoniche"
              component={PagamentiRicaricheTelefoniche}
            />
            <Route
              exact
              path="/pagamenti/tipologie"
              component={PagamentiTipologie}
            />
            <Route exact path="/pagamenti/guida" component={PagamentiGuida} />
            <Route exact path="/pagamenti/lista" component={PagamentiLista} /> */}

            {/* <Route exact path="/map" component={PageMap} /> */}

            {
              /* <Route exact path="/vieni-a-trovarci" component={DoveSiamo} />
            <Route
              exact
              path="/vieni-a-trovarci/ricarica-qui"
              component={RicaricaQui}
            />
            <Route
              exact
              path="/vieni-a-trovarci/pago-pa"
              component={VieniATrovarciPagoPA}
            />
            <Route
              exact
              path="/vieni-a-trovarci/bollettini-postali-bancari"
              component={VieniATrovarciBollettiniPostaliBancari}
            />
            <Route
              exact
              path="/vieni-a-trovarci/offerte-enel-energia"
              component={VieniATrovarciOfferteEE}
            />
            <Route
              exact
              path="/vieni-a-trovarci/ricariche-telefoniche-e-tecnologiche"
              component={VieniATrovarciRicaricheTelefoniche}
            />
            <Route
              exact
              path="/vieni-a-trovarci/spedizioni"
              component={VieniATrovarciSpedizioni}
            />
            <Route
              exact
              path="/vieni-a-trovarci/f24"
              component={VieniATrovarciF24}
            />
            <Route
              exact
              path="/vieni-a-trovarci/visure"
              component={VieniATrovarciVisure}
            />
            <Route
              exact
              path="/vieni-a-trovarci/ricarica-in-contanti"
              component={VieniATrovarciRicaricaInContanti}
            />

            <Route exact path="/conti/standard" component={ContiStandard} />
            <Route exact path="/conti/family" component={ContiFamily} />
            <Route exact path="/conti/costi" component={ContiCosti} />

            <Route exact path="/faq" component={AssistenzaFaq} />
            <Route
              exact
              path="/faq/:categoryId"
              component={AssistenzaCategoria}
            />
            <Route
              exact
              path="/faq/:categoryId/:subCategoryId"
              component={AssistenzaCategoria}
            />

            <Route
              exact
              path="/funzionalita/pagamenti-da-app"
              component={FunzionalitaPagamentiOnline}
            />
            <Route
              exact
              path="/funzionalita/gestisci-gruppo"
              component={FunzionalitaGestisciGruppo}
            />
            <Route
              exact
              path="/funzionalita/dividi-spesa-scambia-denaro"
              component={FunzionalitaDividiSpesa}
            />
            <Route
              exact
              path="/funzionalita/gestisci-account"
              component={FunzionalitaGestisciAccount}
            />
            <Route
              exact
              path="/funzionalita/gestisci-spese"
              component={FunzionalitaGestisciSpese}
            />
            <Route
              exact
              path="/carta-mastercard"
              component={FunzionalitaIBANeMastercard}
            />
            <Route
              exact
              path="/funzionalita/cashback-di-stato"
              component={FunzionalitaCashBack}
            />
            <Route
              exact
              path="/funzionalita/apple-pay"
              component={FunzionalitaApplePay}
            />
            <Route
              exact
              path="/funzionalita/google-pay"
              component={FunzionalitaGooglePay}
            />
            <Route
              exact
              path="/funzionalita/sicurezza"
              component={FunzionalitaSicurezza}
            />

            <Route exact path="/conti/costi" component={ContiCosti} />

            <Route exact path="/esercenti" component={EsercentiHome} />
            <Route
              exact
              path="/esercenti/affiliati"
              component={EsercentiAffiliati}
            />

            <Route
              exact
              path="/esercenti/servizi/juice-pass"
              component={EsercentServiziJuicePass}
            />
            <Route
              exact
              path="/esercenti/servizi/ricarica-in-contanti"
              component={EsercentServiziRicaricaInContanti}
            />
            <Route
              exact
              path="/esercenti/servizi/ricarica-qui"
              component={EsercentServiziRicaricaQui}
            />
            <Route
              exact
              path="/esercenti/servizi/spedizioni"
              component={EsercentServiziSpedizioni}
            />
            <Route
              exact
              path="/esercenti/servizi/spid"
              component={EsercentServiziSpid}
            />
            <Route
              exact
              path="/esercenti/servizi/visure"
              component={EsercentServiziVisure}
            />

            <Route
              exact
              path="/esercenti/richiedi-regalo"
              component={EsercentiRichiestaNatale}
            />
            <Route
              exact
              path="/esercenti/richiedi-info"
              component={EsercentiRichiesta}
            />
            <Route
              exact
              path="/esercenti/richiedi-info/:id"
              component={EsercentiRichiesta}
            />
            <Route
              exact
              path="/esercenti/richiesta-inviata/:id"
              component={EsercentiRichiestaInviata}
            />
            <Route
              exact
              path="/esercenti/richiesta-regalo-inviata/"
              component={EsercentiRichiestaNataleInviata}
            />

            <Route exact path="/esercenti/faq" component={AssistenzaFaq} />
            <Route
              exact
              path="/esercenti/faq/:categoryId"
              component={AssistenzaCategoria}
            />
            <Route
              exact
              path="/esercenti/faq/:categoryId/:subCategoryId"
              component={AssistenzaCategoria}
            />

            <Route exact path="/download" component={Download} />

            <Route exact path="/cookie-policy" component={CookiePolicy} />
            <Route
              exact
              path="/foglio-informativo"
              component={FoglioInformativo}
            />

            <Route exact path="/chi-siamo" component={ChiSiamo} />

            <Route
              exact
              path="/perche-registrarsi"
              component={PercheRegistrarsi}
            />

            <Route exact path="/registrazione" component={Registrazione} />
            <Route
              exact
              path="/registrazione/:status"
              component={Registrazione}
            />

            <Route exact path="/loyalty" component={Loyalty} />
            <Route exact path="/loyalty" component={Loyalty} /> */

              <Route component={PageMap} />
            }
          </Switch>
          <Redirect from="*" to="/" />
          {/* <Footer /> */}
        </Router>
      </Fragment>
    );
  }
}

export default App;
