import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Head extends Component {
  render() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
        {this.props.description && (
          <meta name="description" content={this.props.description} />
        )}
        <meta property="og:title" content={this.props.title} />
        {this.props.description && (
          <meta property="og:description" content={this.props.description} />
        )}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={"https://pay.enelx.com" + this.props.path}
        />
        <meta
          property="og:image"
          content={
            this.props.imageName
              ? require("src/images/" + this.props.imageName + ".jpg").default
              : require("src/images/home-intro.jpg").default
          }
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1560" />
        <meta property="og:image:height" content="568" />
        <meta property="og:image:alt" content={this.props.title} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
    );
  }
}

export default Head;
